import React, { ChangeEvent } from 'react';
import { Formik, Form, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../core';
import { SolidButton } from '../../components/buttons-visuals';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';

interface AssignGroupToStationProps {
    group: {
        id: string;
        name: string;
    };
    onSubmit: (values: Core.Models.AssignGroupToStationRequest) => Promise<void>;
    stage: { name: string };
    stations: Core.Models.LeagueLocationStation[];
}

const AssignGroupToStation = ({ group, onSubmit, stage, stations }: AssignGroupToStationProps) => {
    return (
        <Formik
            initialValues={Object.assign({
                groupId: group.id,
                stationId: '',
            })}
            validationSchema={Yup.object().shape({
                groupId: Yup.string().required('Group is required'),
                stationId: Yup.string().optional(),
            })}
            onSubmit={async (
                values: Core.Models.AssignGroupToStationRequest,
                actions: FormikActions<Core.Models.AssignGroupToStationRequest>
            ) => {
                actions.setStatus(undefined);
                try {
                    await onSubmit(values);
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(formProps: FormikProps<Core.Models.AssignGroupToStationRequest>) => (
                <Form className="form">
                    <FormField
                        component="select"
                        label="Station"
                        name="stationId"
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                            formProps.setFieldValue('stationId', e.target.value)
                        }
                        value={formProps.values.stationId}
                    >
                        <option value="">None</option>
                        {stations.map((station: Core.Models.LeagueLocationStation) => (
                            <option key={station.id} value={station.id}>
                                {station.name}
                            </option>
                        ))}
                    </FormField>

                    <p>
                        This will assign every <strong>{group.name}</strong> match in the <strong>{stage.name}</strong>{' '}
                        stage to the selected station.
                    </p>

                    {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                    <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                    <SolidButton
                        as="button"
                        layout="full"
                        onClick={formProps.submitForm}
                        pending={formProps.isSubmitting}
                    >
                        Assign
                    </SolidButton>
                </Form>
            )}
        />
    );
};

export default AssignGroupToStation;

import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

import './Header.scss';

export const Header = ({ className, children, ...rest }: HTMLAttributes<HTMLElement>) => {
    return (
        <header className={classNames(className, 'ds-header')} {...rest}>
            <nav className="ds-header__nav" aria-label="league spot header navigation">
                {children}
            </nav>
        </header>
    );
};

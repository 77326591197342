import { createSelector } from 'reselect';

import { AppState } from '..';
import { UserPermissionService } from '../../services/userPermissionService';

const getPermissionState = ({ permissionState }: AppState) => permissionState;
const getLoginState = ({ loginState }: AppState) => loginState;

export const getUserPermissionService = createSelector(
    [getLoginState, getPermissionState],
    (loginState, permissionState) => new UserPermissionService(loginState, permissionState)
);

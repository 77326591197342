import React, { useState } from 'react';
import { Formik, Form, FormikActions } from 'formik';
import { isNumber, orderBy } from 'lodash';
import pluralize from 'pluralize';
import * as Yup from 'yup';

import * as Core from '../../core';
import { SolidButton } from '../../components/buttons-visuals';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';

interface RescheduleGroupProps {
    includeBracket?: boolean;
    initialValues?: Partial<RescheduleGroupValues>;
    matchWord: string;
    onSubmit: (values: RescheduleGroupValues) => Promise<void>;
    rounds: Core.Models.Round[];
}

export interface RescheduleGroupValues {
    bracket?: Core.Models.Bracket | Core.Models.DropdownSelection.Unselected;
    roundId: string;
    startTimeUtc?: string;
}

const RescheduleGroup = ({
    includeBracket,
    initialValues,
    matchWord,
    onSubmit,
    rounds: initialRounds,
}: RescheduleGroupProps) => {
    const [rounds, setRounds] = useState<Core.Models.Round[]>(initialRounds);

    const schema = Yup.object().shape({
        bracket: Yup.number().test(
            'is-required',
            `Bracket is required`,
            (bracket: Core.Models.Bracket | Core.Models.DropdownSelection.Unselected | undefined) =>
                !includeBracket || (isNumber(bracket) && bracket !== Core.Models.DropdownSelection.Unselected)
        ),
        roundId: Yup.string().required('Round is required'),
        startTimeUtc: Yup.string(),
    });

    return (
        <Formik
            initialValues={Object.assign(
                {
                    bracket: includeBracket ? Core.Models.DropdownSelection.Unselected : undefined,
                    roundId: rounds.length === 1 ? rounds[0].id : '',
                },
                initialValues || {},
                {
                    // this converts startTimeUtc null values to undefined for the datepicker plugin
                    startTimeUtc: !!initialValues?.startTimeUtc ? initialValues.startTimeUtc : undefined,
                }
            )}
            validationSchema={schema}
            onSubmit={async (values: RescheduleGroupValues, actions: FormikActions<RescheduleGroupValues>) => {
                actions.setStatus(undefined);
                try {
                    await onSubmit(values);
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(formProps) => (
                <Form className="form">
                    {includeBracket && (
                        <FormField
                            component="select"
                            description="Bracket"
                            name="bracket"
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                const bracketType = parseInt(e.target.value) as Core.Models.Bracket;
                                const roundsInBracket = initialRounds.filter(
                                    (r: Core.Models.Round) =>
                                        r.matches?.some((m: Core.Models.Match) => m.bracket === bracketType)
                                );
                                setRounds(roundsInBracket);

                                // clear round selection if there are no matches in the round for the specified bracket
                                if (
                                    !!formProps.values.roundId &&
                                    !roundsInBracket
                                        .map((r: Core.Models.Round) => r.id)
                                        .includes(formProps.values.roundId)
                                ) {
                                    formProps.setFieldValue('roundId', '');
                                }
                            }}
                        >
                            <option disabled hidden value={Core.Models.DropdownSelection.Unselected}>
                                --
                            </option>
                            <option value={Core.Models.Bracket.Upper}>Upper</option>
                            <option value={Core.Models.Bracket.Lower}>Lower</option>
                            <option value={Core.Models.Bracket.Finals}>Finals</option>
                        </FormField>
                    )}

                    <FormField
                        component="select"
                        description="Round"
                        disabled={initialRounds.length === 1}
                        name="roundId"
                    >
                        {initialRounds.length > 1 && (
                            <option value="" hidden disabled>
                                --
                            </option>
                        )}
                        {orderBy(rounds, (r: Core.Models.Round) => r.sortOrder).map((round: Core.Models.Round) => (
                            <option key={round.id} value={round.id}>
                                {round.name}
                            </option>
                        ))}
                    </FormField>

                    <FormField component="datetime" name="startTimeUtc" label="Start time" />

                    {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                    <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                    <SolidButton
                        as="button"
                        disabled={formProps.isSubmitting}
                        layout="full"
                        onClick={formProps.submitForm}
                        pending={formProps.isSubmitting}
                        size="medium"
                    >
                        Reschedule {pluralize(matchWord)}
                    </SolidButton>
                </Form>
            )}
        />
    );
};

export default RescheduleGroup;

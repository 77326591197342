import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orderBy } from 'lodash';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import * as Core from '../../../core';
import JoinRound from './joinRound';
import { IconButton, SolidButton } from '../../../components/buttons-visuals';
import Loading from '../../../components/loading';
import { Popover } from '../../../components/overlays';
import { useConfirmModal } from '../../../hooks/confirmModal';
import { useModal } from '../../../hooks/modal';
import { useCanEditSeason, useTimezone, useUserPermissionService } from '../../../hooks/store';
import { RoundService } from '../../../services/roundService';

import './index.scss';

export interface RoundLobbyProps {
    dateResponse: Core.Models.HasResponseDate;
    group: Core.Models.Group;
    round: Core.Models.Round;
    roundGroupParticipants: Core.Models.RoundGroupParticipant[];
    stage: Core.Models.Stage;
}

const RoundLobby = (props: RoundLobbyProps) => {
    const { dateResponse, group, round, roundGroupParticipants, stage } = props;
    const [participantBeingRemoved, setParticipantBeingRemoved] = useState<
        Core.Models.RoundGroupParticipant | undefined
    >(undefined);

    const timezone = useTimezone();
    const userPermissionService = useUserPermissionService();
    const canEditSeason = useCanEditSeason(stage.seasonId);
    const startTime = useMemo(() => moment.tz(round.startTimeUtc, timezone), [round.startTimeUtc, timezone]);
    const checkInTime = useMemo(
        () => moment.tz(startTime, timezone).subtract(stage.settings.attendanceWindowMinutes, 'minutes'),
        [stage, startTime, timezone]
    );

    const getMinutesUntilStart = (startTime: moment.Moment, responseDateUtc: Date) =>
        Math.ceil(startTime.diff(responseDateUtc, 'minute', true));

    const [minutesUntilStart, setMinutesUntilStart] = useState<number>(
        getMinutesUntilStart(startTime, dateResponse.responseDateUtc)
    );

    const participantsUserCanEdit = useMemo(
        () =>
            group.groupParticipants?.filter(
                (gp: Core.Models.GroupParticipant) =>
                    (userPermissionService.hasTeamAccess(Core.Models.PermissionLevel.Edit, gp.teamId) ||
                        canEditSeason) &&
                    !roundGroupParticipants.some(
                        (rgp: Core.Models.RoundGroupParticipant) => rgp.groupParticipantId === gp.id
                    )
            ) || [],
        [canEditSeason, group.groupParticipants, roundGroupParticipants, userPermissionService]
    );

    useEffect(() => {
        if (!round.startTimeUtc) return;

        let secondsElapsed = 0;
        const interval = setInterval(() => {
            const updatedDate = moment
                .tz(dateResponse.responseDateUtc, timezone)
                .add(secondsElapsed++, 'seconds')
                .toDate();
            const newMinutesUntilStart = getMinutesUntilStart(startTime, updatedDate);
            setMinutesUntilStart(newMinutesUntilStart);
        }, 1000);

        return () => clearInterval(interval);
    }, [dateResponse, round, startTime, timezone]);

    const onJoinRound = async (groupParticipantId: string) => {
        await RoundService.join({ roundId: round.id, groupParticipantId });
        closeJoinRound();
    };

    const [joinRoundModal, openJoinRound, closeJoinRound] = useModal(
        () => 'Join Round',
        () => <JoinRound onSubmit={onJoinRound} groupParticipants={participantsUserCanEdit} />
    );

    const [removeFromRoundModal, openRemoveFromRound, closeRemoveFromRound] = useConfirmModal(
        () => 'Are you sure?',
        () => (
            <p>
                Are you sure you want to remove{' '}
                <strong>{participantBeingRemoved!.groupParticipant?.name || 'Deleted team'}</strong> from {round.name}?
            </p>
        ),
        async () => {
            await RoundService.deleteRoundGroupParticipant(participantBeingRemoved!.id);
            closeRemoveFromRound();
        }
    );

    return (
        <div className="round-lobby">
            {!round.startTimeUtc ? (
                <p className="round-lobby__header">This round has not been scheduled. Please check back later.</p>
            ) : stage.settings.attendanceWindowMinutes &&
              minutesUntilStart <= stage.settings.attendanceWindowMinutes ? (
                <>
                    {minutesUntilStart <= 0 ? (
                        <div className="round-lobby__ready-section">
                            <p className="round-lobby__header">Waiting for the host to start {round.name}.</p>
                            <Loading />
                        </div>
                    ) : (
                        <p className="round-lobby__header">
                            {round.name} begins in {minutesUntilStart} minute{minutesUntilStart !== 1 && 's'}. (
                            {startTime.format(Core.Constants.MATCH_DATE_TIME_FORMAT)})
                        </p>
                    )}
                    {participantsUserCanEdit.length > 0 && (
                        <div>
                            <SolidButton as="button" className="mb4x" onClick={openJoinRound}>
                                Join {round.name}
                            </SolidButton>
                            {joinRoundModal}
                        </div>
                    )}
                    {roundGroupParticipants.length > 0 && (
                        <>
                            <p className="round-lobby__sub-header">Participating in this round:</p>
                            <ul className="round-lobby__participant-list">
                                {orderBy(
                                    roundGroupParticipants,
                                    (rgp: Core.Models.RoundGroupParticipant) => rgp.joinedTimeUtc
                                ).map((rgp: Core.Models.RoundGroupParticipant) => {
                                    const name = rgp.groupParticipant?.name || 'Deleted team';
                                    const joinTime = moment
                                        .tz(rgp.joinedTimeUtc, timezone)
                                        .format(Core.Constants.MATCH_TIME_FORMAT);
                                    return (
                                        <li className="round-lobby__participant" key={rgp.id}>
                                            <div className="round-lobby__participant-name">
                                                <FontAwesomeIcon
                                                    icon={['fas', 'check']}
                                                    className="color-success mr"
                                                    title={`Joined at ${joinTime}`}
                                                />
                                                {!!rgp.groupParticipant ? (
                                                    <Link to={`/teams/${rgp.groupParticipant.teamId}`}>{name}</Link>
                                                ) : (
                                                    <span>{name}</span>
                                                )}
                                            </div>
                                            {canEditSeason && (
                                                <div className="disp-flex">
                                                    <Popover
                                                        button={
                                                            <IconButton
                                                                as="button"
                                                                buttonLabel="More info"
                                                                buttonSize="small"
                                                                className="ml mr"
                                                            >
                                                                <FontAwesomeIcon icon={['fas', 'circle-info']} />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <div className="pt2x pb2x weight-400">
                                                            Added to <strong>{round.name}</strong> at{' '}
                                                            <strong>{joinTime}</strong>
                                                            {!!rgp.joinedUser && (
                                                                <>
                                                                    {' '}
                                                                    by{' '}
                                                                    <Link to={`/users/${rgp.joinedUser.id}`}>
                                                                        {Core.Identity.renderMemberName(rgp.joinedUser)}
                                                                    </Link>
                                                                </>
                                                            )}
                                                        </div>
                                                    </Popover>
                                                    <IconButton
                                                        as="button"
                                                        buttonLabel={`Remove ${name} from ${round.name}`}
                                                        buttonSize="small"
                                                        className="ml mr"
                                                        onClick={() => {
                                                            setParticipantBeingRemoved(rgp);
                                                            openRemoveFromRound();
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={['fas', 'trash']} />
                                                    </IconButton>
                                                </div>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </>
                    )}
                    {removeFromRoundModal}
                </>
            ) : (
                <>
                    <p className="round-lobby__header">
                        <span>
                            This round is scheduled to begin on{' '}
                            {startTime.format(Core.Constants.MATCH_DATE_TIME_FORMAT)}.
                        </span>
                        <span>Check in begins at {checkInTime.format(Core.Constants.MATCH_TIME_FORMAT)}.</span>
                    </p>
                </>
            )}
        </div>
    );
};

export default RoundLobby;

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import useClickOutside from '../../hooks/clickOutside';
import { IconButton } from '../buttons-visuals';
import Navigation from '../navigation';
import { Dropdown } from '../navigationComponents';

const Hamburger = () => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const dropdownRef = React.useRef<HTMLDivElement>(null);

    useClickOutside(dropdownRef, () => {
        if (isOpen) {
            setIsOpen(false);
        }
    });

    return (
        <nav
            aria-label="main menu"
            className={classNames('header__burger-button', isOpen && 'active')}
            ref={dropdownRef}
        >
            <Dropdown
                button={
                    <IconButton
                        as="button"
                        buttonLabel="Navigation menu button"
                        buttonSize="large"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <FontAwesomeIcon icon={['fas', 'bars']} />
                    </IconButton>
                }
                open={isOpen}
                onClose={() => setIsOpen(false)}
                position="left"
            >
                <Navigation toggleActive={() => setIsOpen(!isOpen)} />
            </Dropdown>
        </nav>
    );
};

export default Hamburger;

import React, { useMemo } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { orderBy } from 'lodash';
import * as Yup from 'yup';

import * as Core from '../../../core';
import { SolidButton } from '../../../components/buttons-visuals';
import FormField from '../../../components/formField';
import InfoMessage from '../../../components/infoMessage';

interface JoinRoundProps {
    groupParticipants: Core.Models.GroupParticipant[];
    onSubmit: (groupParticipantId: string) => Promise<void>;
}

interface JoinRoundValues {
    groupParticipantId: string;
}

const schema = Yup.object().shape({
    groupParticipantId: Yup.string().required('A team selection is required'),
});

const JoinRound = (props: JoinRoundProps): JSX.Element => {
    const { onSubmit, groupParticipants } = props;
    const canAddMultiple = groupParticipants.length > 1;
    const orderedGroupParticipants = useMemo(
        () =>
            orderBy(
                groupParticipants.filter((gp: Core.Models.GroupParticipant) => gp.isParticipating),
                (gp: Core.Models.GroupParticipant) => gp.name
            ),
        [groupParticipants]
    );

    return (
        <Formik
            initialValues={Object.assign({
                groupParticipantId: canAddMultiple ? '' : groupParticipants[0].id,
            } as Partial<JoinRoundValues>)}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
                actions.setStatus(undefined);
                try {
                    await onSubmit(values.groupParticipantId);
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                } finally {
                    actions.setSubmitting(false);
                }
            }}
            render={(formProps: FormikProps<JoinRoundValues>) => {
                return (
                    <Form className="form">
                        <fieldset className="form-group">
                            <FormField component="select" name="groupParticipantId" label="Select a team">
                                <option value="" hidden disabled>
                                    --
                                </option>
                                {orderedGroupParticipants.map((gp: Core.Models.GroupParticipant) => (
                                    <option key={gp.id} value={gp.id}>
                                        {gp.name}
                                    </option>
                                ))}
                            </FormField>
                        </fieldset>

                        <p>This will add the selected team to the upcoming round.</p>

                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                        <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                        <SolidButton
                            as="button"
                            layout="full"
                            onClick={formProps.submitForm}
                            pending={formProps.isSubmitting}
                        >
                            Add team
                        </SolidButton>
                    </Form>
                );
            }}
        />
    );
};

export default JoinRound;

import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import * as Core from '../../core';
import GetLogoColors from './getLogoColors';
import LeagueImageUploader from './leagueImageUploader';
import { IconButton } from '../../components/buttons-visuals';
import { ContentContainer } from '../../components/containers';
import LiteLimitationMessage from '../../components/liteLimitationMessage';
import { getTextColor } from '../../components/themeContainer';
import { useIsLite, useLeague } from '../../hooks/store';
import { getLeague } from '../../store/league/actions';

const LeagueImages = () => {
    const dispatch = useDispatch();
    const league = useLeague();
    const isLite = useIsLite();

    const reloadLeague = useCallback(() => dispatch(getLeague(false)), [dispatch]);

    return (
        <>
            {isLite && (
                <LiteLimitationMessage
                    className="fit-content m-auto mb2x"
                    message={`${Core.Constants.PLANS.LITE.NAME} instances may only upload a banner image.`}
                />
            )}

            <div className="settings-page__images">
                <div className="settings-page__images__column mb2x">
                    <LeagueImageUploader
                        contentType={Core.Constants.CONTENT_TYPE_PNG}
                        header="League logo"
                        imageType={Core.Models.LeagueImageType.Logo}
                        reloadData={reloadLeague}
                        text="Upload logo"
                        url={league?.logoUrl}
                    />
                    {!!league?.logoUrl && (
                        <ContentContainer
                            className="settings-page__images__logo-colors"
                            shade={Core.Models.Shades.Dark40}
                        >
                            <h3 className="heading-3">Logo colors</h3>
                            <div className="disp-flex flex-dir-row flex-wrap align-center">
                                {!!league?.logoColors && league.logoColors.length > 0 ? (
                                    league.logoColors.map((color: string) => (
                                        <div
                                            className="settings-page__images__logo-colors__color mt mr mb"
                                            key={color}
                                            style={{ backgroundColor: color }}
                                            title={color}
                                        >
                                            <IconButton
                                                as="button"
                                                buttonLabel={`Copy ${color}`}
                                                buttonSize="medium"
                                                className={classNames('copy-button', getTextColor(color))}
                                                onClick={() => Core.Utils.copyToClipboard(color)}
                                            >
                                                <FontAwesomeIcon icon={['fas', 'copy']} />
                                            </IconButton>
                                        </div>
                                    ))
                                ) : (
                                    <GetLogoColors />
                                )}
                            </div>
                            <p className="mt2x">
                                These colors are generated from the league logo you uploaded and can be used to
                                customize the look of your site on the theme settings page.
                            </p>
                        </ContentContainer>
                    )}
                </div>
                <div className="settings-page__images__column">
                    <LeagueImageUploader
                        contentType={Core.Constants.CONTENT_TYPE_PNG}
                        header="League header logo"
                        imageType={Core.Models.LeagueImageType.HeaderLogo}
                        reloadData={reloadLeague}
                        text="Upload header logo"
                        url={league?.headerLogoUrl}
                    />
                    <LeagueImageUploader
                        contentType={Core.Constants.CONTENT_TYPE_JPG}
                        header="League banner image"
                        imageType={Core.Models.LeagueImageType.Banner}
                        reloadData={reloadLeague}
                        text="Upload banner image"
                        url={league?.bannerUrl}
                    />
                    <LeagueImageUploader
                        aspectRatio={1}
                        contentType={Core.Constants.CONTENT_TYPE_PNG}
                        header="League browser tab icon (favicon)"
                        imageType={Core.Models.LeagueImageType.Favicon}
                        reloadData={reloadLeague}
                        text="Upload square favicon"
                        url={league?.faviconUrl}
                        width={32}
                    />
                </div>
            </div>
        </>
    );
};

export default LeagueImages;

import * as React from 'react';

import * as Core from '../core';

// wraps a promise function to add a try/catch and a isExecuting and error values for display
export function usePromiseOperation(
    operation: () => Promise<void>
): [boolean, () => Promise<void>, string | undefined] {
    const [isExecuting, setIsExecuting] = React.useState(false);
    const [error, setError] = React.useState<string | undefined>(undefined);

    return [
        isExecuting,
        React.useCallback(async () => {
            setIsExecuting(true);
            setError(undefined);
            try {
                await operation();
            } catch (e) {
                const message = Core.API.getErrorMessage(e);
                setError(message);
            }
            setIsExecuting(false);
        }, [operation, setIsExecuting, setError]),
        error,
    ];
}

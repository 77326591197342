import React, { useEffect, useState } from 'react';

import * as Core from '../../core';
import StandingsBySeason from '../../components/standingsBySeason';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { LeagueService } from '../../services/leagueService';

interface LeagueStandingsProps extends WithLoadingProps {}

const LeagueStandings = ({ setError, setIsLoading }: LeagueStandingsProps) => {
    const [standings, setStandings] = useState<Core.Models.RelevantGroupMergedStandingsResult | undefined>(undefined);

    useEffect(() => {
        (async () => {
            try {
                const response = await LeagueService.getStandings();
                setStandings(response);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    if (!standings) return <></>;
    return (
        <div className="organization-standings">
            <div className="organization-standings__header">
                <h4 className="heading-2">Current Standings</h4>
            </div>
            <StandingsBySeason standings={standings} externalLinks />
        </div>
    );
};

export default withLoading(LeagueStandings, {
    errorDisplayPageProps: { fullPage: true },
    loadingProps: { blockItem: true },
});

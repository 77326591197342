import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikActions } from 'formik';
import { orderBy } from 'lodash';
import * as Yup from 'yup';

import * as Core from '../../core';
import { Button } from '../../components/button';
import ErrorMessage from '../../components/errorMessage';
import FormField from '../../components/formField';
import Loading from '../../components/loading';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { SeasonService } from '../../services/seasonService';

import './index.scss';

interface SeasonRosterExportProps extends WithLoadingProps {
    onSubmit: (value: string) => void;
    seasonAlternateName: string;
}

export interface SeasonRosterExportValues {
    seasonId: string;
}

const SeasonRosterExport = (props: SeasonRosterExportProps): JSX.Element => {
    const { onSubmit, seasonAlternateName, setError, setIsLoading } = props;
    const [seasons, setSeasons] = useState<Core.Models.Season[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const response = await SeasonService.getAllSeasonCategories();
                const orderedSeasons = orderBy(
                    response.flatMap((sc: Core.Models.SeasonCategory) => sc.seasons),
                    (s: Core.Models.Season) => s.name
                );
                setSeasons(orderedSeasons);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [setError, setIsLoading]);

    return (
        <Formik
            initialValues={Object.assign({
                seasonId: '',
            })}
            validationSchema={Yup.object().shape({
                seasonId: Yup.string().required(`Please select ${seasonAlternateName.toLowerCase()}`),
            })}
            onSubmit={async (values: SeasonRosterExportValues, actions: FormikActions<SeasonRosterExportValues>) => {
                actions.setStatus(undefined);
                try {
                    await onSubmit(values.seasonId);
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(formProps) => (
                <Form className="form">
                    <fieldset className="form-group">
                        <FormField component="select" name="seasonId" description={seasonAlternateName}>
                            <option hidden disabled value="">
                                Select {seasonAlternateName.toLowerCase()}
                            </option>
                            {seasons.map((season: Core.Models.Season) => (
                                <option key={season.id} value={season.id}>
                                    {season.name}
                                </option>
                            ))}
                        </FormField>
                    </fieldset>

                    {formProps.status && <ErrorMessage error={formProps.status} />}
                    <ErrorMessage error={formProps.errors} filter={formProps.touched} />

                    <fieldset className="form-group form-group--undecorated">
                        {formProps.isSubmitting && <Loading buttonLoader />}
                        <Button onClick={formProps.submitForm} disabled={formProps.isSubmitting} wide round>
                            Submit
                        </Button>
                    </fieldset>
                </Form>
            )}
        />
    );
};

export default withLoading(SeasonRosterExport, { loadingProps: { blockItem: true } });

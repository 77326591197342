import * as Core from '../core';

export abstract class GroupService {
    public static async edit(command: Core.Models.EditGroupCommand): Promise<Core.Models.Group> {
        const route = Core.API.ServerRoute.forAction('groups');
        return await Core.API.patch(route, command);
    }

    public static async reschedule(command: Core.Models.RescheduleRoundGroupCommand): Promise<Core.Models.Group> {
        const route = Core.API.ServerRoute.forAction('groups', 'reschedule');
        return await Core.API.patch(route, command);
    }

    public static async assignToStation(request: Core.Models.AssignGroupToStationRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('groups', 'assignToStation');
        return await Core.API.post(route, request);
    }
}

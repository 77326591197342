import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import animateScrollTo from 'animated-scroll-to';
import classNames from 'classnames';
import { pick, some } from 'lodash';
import pluralize from 'pluralize';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import AddTeamToStage from './addTeamToStage';
import AnnounceToStage, { AnnouncementParticipant } from './announceToStage';
import Announcements from './announcements';
import EligibleStageParticipants from './eligibleStageParticipants';
import StageGroups from './groups';
import StageRounds from './rounds';
import StageParticipants from './stageParticipants';
import { StageStandings } from './standings';
import { SolidButton, StatusIndicator, TertiaryButton } from '../../components/buttons-visuals';
import CompleteStageButton from '../../components/completeStageButton';
import { ContentContainer } from '../../components/containers';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { withLoadDataDefaultConfig } from '../../components/loadData';
import Modal from '../../components/modal';
import { useConfirmModal } from '../../hooks/confirmModal';
import { useModal } from '../../hooks/modal';
import { useCanEditSeason, useUserPermissionService } from '../../hooks/store';
import { SeasonService } from '../../services/seasonService';
import { StageService } from '../../services/stageService';
import TiebreakerService from '../../services/tiebreakerService';

import './stage.scss';

interface SeasonStageProps {
    externalLinks: boolean;
    id: string;
    locations: Core.Models.LeagueLocation[];
    onDelete: () => void;
    reloadData: (showLoading: boolean) => Promise<void>;
    reloadSeason: () => Promise<void>;
    season: Core.Models.Season;
    stage: Core.Models.Stage;
}

const SeasonStage: React.FunctionComponent<SeasonStageProps> = (props) => {
    const { externalLinks, id, locations, onDelete, reloadData, reloadSeason, season, stage } = props;

    const canEditSeason = useCanEditSeason(season.id);
    const userPermissionService = useUserPermissionService();

    const reloadStage = useCallback(async () => {
        await Promise.all([reloadData(false), reloadSeason()]);
        return;
    }, [reloadData, reloadSeason]);

    const [deleteNode, startDelete, closeDelete] = useConfirmModal(
        () => 'Are you sure?',
        () => <p>Are you sure you want to delete this stage?</p>,
        async () => {
            await StageService.delete(stage.id);
            onDelete();
            await reloadSeason();
            closeDelete();
        }
    );

    const [openStageCheckinNode, startOpenStageCheckin, closeOpenStageCheckin] = useConfirmModal(
        () => 'Are you sure?',
        () => (
            <p>
                Are you sure you want to open checkin for this stage? All potential participants will receive
                notification(s).
            </p>
        ),
        async () => {
            await StageService.openCheckin({ stageId: stage.id });
            await reloadStage();
            closeOpenStageCheckin();
        }
    );

    const [resetNode, startReset, closeReset] = useConfirmModal(
        () => 'Are you sure?',
        () => (
            <p>
                Are you sure you want to reset this stage? <strong>ALL PROGRESS WILL BE LOST!</strong>
            </p>
        ),
        async () => {
            await StageService.reset(stage.id);
            await reloadStage();
            closeReset();
        }
    );

    const [autoTiebreakNode, startAutoTiebreak, closeAutoTiebreak] = useConfirmModal(
        () => 'Apply auto-tiebreakers?',
        () => (
            <>
                <p>
                    Are you sure you would like the apply automatic tiebreakers? The system will automatically break
                    ties based on:
                </p>
                <ol className="auto-tiebreak-determinants">
                    <li>Strength of schedule</li>
                    <li>Strength of opponents' schedules</li>
                    <li>Weight of losses (based on round)</li>
                </ol>
            </>
        ),
        async () => {
            await StageService.autoBreakTies({ stageId: stage.id });
            await reloadStage();
            closeAutoTiebreak();
        }
    );

    const [addTeamNode, openAddTeamNode] = useModal(
        () => `Add Late Team to ${stage.name}`,
        (closeCompleteModal) => (
            <AddTeamToStage
                onSubmit={async (values: { groupId: string; teamId: string }) => {
                    await SeasonService.addLateParticipant({
                        groupId: values.groupId,
                        seasonId: stage.seasonId,
                        stageId: stage.id,
                        teamId: values.teamId,
                    });
                    await reloadData(true);
                    closeCompleteModal();
                }}
                season={season}
                stage={stage}
            />
        )
    );

    const matchWord = useMemo(() => Core.Competition.getMatchWord(season.game.eventType), [season.game.eventType]);

    // this only checks that the stage is in a state where it _could_ be completable. it does not check for specific
    // cases such as pending tiebreakers. it is up to the child control `CompleteStageButton` to evaluate a more
    // detailed state and present the button
    const showCompleteStageControl = canEditSeason && stage.currentState === Core.Models.StageState.InProgress;

    const seasonHasStarted = season.currentState === Core.Models.CompetitionState.InProgress;

    const canResetStage = useMemo(
        () =>
            canEditSeason &&
            [Core.Models.StageState.CheckingIn, Core.Models.StageState.InProgress].includes(stage.currentState),
        [canEditSeason, stage.currentState]
    );

    // #1050: https://dev.azure.com/legacyesportsna/Platform/_workitems/edit/1050
    //  Add "Single Elimination" entry here.
    const isEliminationStage = stage.stageTypeId === Core.Models.StageTypeId.DoubleElimination;

    const hasPrecedingIncompleteStage = some(
        season.stages,
        (s) => s.sortOrder < stage.sortOrder && s.currentState < Core.Models.StageState.IsComplete
    );

    const needsToAddParticipants =
        stage.settings.inputParticipantSource === Core.Models.InputParticipantSourceType.ManualList &&
        stage.participants.length <= 0 &&
        season.participants.length > 0;

    const canStartStage = seasonHasStarted && !hasPrecedingIncompleteStage && !needsToAddParticipants;

    const canAutoTiebreak = React.useMemo(() => {
        const autoTiebreakStageTypeIds = [Core.Models.StageTypeId.AutoMatcher, Core.Models.StageTypeId.Swiss];
        return (
            canEditSeason &&
            stage.currentState === Core.Models.StageState.InProgress &&
            autoTiebreakStageTypeIds.includes(stage.stageTypeId) &&
            !stage.appliedAutoTiebreakers &&
            stage.rounds.every((r: Core.Models.Round) => r.currentState >= Core.Models.CompetitionState.IsComplete) &&
            stage.groups.some((g: Core.Models.Group) =>
                TiebreakerService.groupParticipantNeedsTiebreak(g.groupParticipants!)
            )
        );
    }, [
        canEditSeason,
        stage.appliedAutoTiebreakers,
        stage.currentState,
        stage.groups,
        stage.rounds,
        stage.stageTypeId,
    ]);

    const canAddLateTeam = useMemo(() => {
        const addLateTeamStageTypeIds = [
            Core.Models.StageTypeId.AutoMatcher,
            Core.Models.StageTypeId.Leaderboard,
            Core.Models.StageTypeId.LeaderboardAutoMatcher,
            Core.Models.StageTypeId.Swiss,
        ];

        return (
            canEditSeason &&
            season.currentState > Core.Models.CompetitionState.NotStarted &&
            stage.currentState === Core.Models.StageState.InProgress &&
            addLateTeamStageTypeIds.includes(stage.stageTypeId)
        );
    }, [canEditSeason, season.currentState, stage.currentState, stage.stageTypeId]);

    const canEditAnyEligibleParticipants = useMemo(
        () =>
            some(stage.eligibleParticipants, (esp) =>
                userPermissionService.hasTeamAccess(Core.Models.PermissionLevel.Edit, esp.participant.teamId)
            ),
        [stage.eligibleParticipants, userPermissionService]
    );

    const [isAnnouncingToStage, setIsAnnouncingToStage] = useState<boolean>(false);
    const [participantsForAnnouncement, setParticipantsForAnnouncement] = useState<Array<AnnouncementParticipant>>([]);

    useEffect(() => {
        (async () => {
            if (stage.participants.length > 0) {
                setParticipantsForAnnouncement(stage.participants);
                return;
            }

            if (!canEditSeason) return; // only users who can edit the season can run `prepare`
            const groups = await StageService.prepare(stage.id);
            const participants = groups
                .flatMap(
                    (g: Core.Models.Group) =>
                        g.groupParticipants?.map((gp: Core.Models.GroupParticipant) => ({
                            participantId: gp.participantId,
                            name: gp.name,
                        }))
                )
                .filter((p) => !!p)
                .map((p) => p!);
            setParticipantsForAnnouncement(participants);
        })();
    }, [stage.participants]);

    const startSeasonControl = useMemo(() => {
        const openCheckin =
            stage.settings.requireStageCheckIn && stage.currentState < Core.Models.StageState.CheckingIn;

        if (!seasonHasStarted)
            return <StatusIndicator color="warning">Lock in teams before starting stage</StatusIndicator>;
        if (hasPrecedingIncompleteStage)
            return <StatusIndicator color="warning">Complete earlier stages before starting this one</StatusIndicator>;
        if (needsToAddParticipants)
            return <StatusIndicator color="warning">Manually add participants before starting stage</StatusIndicator>;

        const sharedProps = {
            className: classNames(canStartStage && 'attention'),
            disabled: !canStartStage,
            title: canStartStage ? undefined : `Can't start stage yet`,
        };

        if (openCheckin)
            return (
                <SolidButton as="button" size="small" onClick={startOpenStageCheckin} {...sharedProps}>
                    <FontAwesomeIcon icon={['fas', 'clipboard-list']} className="mr" size="sm" />
                    Open {stage.name} checkin
                </SolidButton>
            );

        return (
            <SolidButton as="link" size="small" to={`/stages/${id}/start`} {...sharedProps}>
                <FontAwesomeIcon icon={['fas', 'play']} className="mr" size="sm" />
                Start {stage.name}
            </SolidButton>
        );
    }, [
        canStartStage,
        hasPrecedingIncompleteStage,
        id,
        needsToAddParticipants,
        seasonHasStarted,
        stage.currentState,
        stage.name,
        stage.settings.requireStageCheckIn,
        startOpenStageCheckin,
    ]);

    const stageControls = useMemo(
        () =>
            [
                ...(showCompleteStageControl // `showCompleteStageControl` is evaluated with `canEditSeason`
                    ? [
                          <CompleteStageButton
                              matchWord={matchWord}
                              reloadStage={reloadStage}
                              showTiebreakerWarning={true}
                              stage={stage}
                          />,
                      ]
                    : []),
                ...(stage.currentState <= Core.Models.StageState.CheckingIn && canEditSeason
                    ? [startSeasonControl, openStageCheckinNode]
                    : []),
                ...(stage.currentState === Core.Models.StageState.CheckingIn && canEditAnyEligibleParticipants
                    ? [
                          <TertiaryButton
                              as="button"
                              className="attention"
                              onClick={() => {
                                  const participantsElement = document.getElementById('eligible-participants');
                                  if (!!participantsElement) animateScrollTo(participantsElement, { speed: 250 });
                              }}
                          >
                              <FontAwesomeIcon icon={['fas', 'clipboard-check']} className="mr" size="sm" />
                              Check in now!
                          </TertiaryButton>,
                      ]
                    : []),
                ...(canAutoTiebreak // `canAutoTiebreak` is evaluated with `canEditSeason`
                    ? [
                          <TertiaryButton as="button" onClick={startAutoTiebreak}>
                              <FontAwesomeIcon icon={['fas', 'ranking-star']} className="mr" size="sm" />
                              Apply Auto-Tiebreaker
                          </TertiaryButton>,
                          autoTiebreakNode,
                      ]
                    : []),
                ...(canAddLateTeam
                    ? [
                          <TertiaryButton as="button" onClick={openAddTeamNode}>
                              <FontAwesomeIcon icon={['fas', 'user-plus']} className="mr" size="sm" />
                              Add late team
                          </TertiaryButton>,
                          addTeamNode,
                      ]
                    : []),
                ...(canEditSeason && participantsForAnnouncement.length > 0
                    ? [
                          <TertiaryButton as="button" onClick={() => setIsAnnouncingToStage(true)}>
                              <FontAwesomeIcon icon={['fas', 'message']} className="mr" size="sm" />
                              Make announcement
                          </TertiaryButton>,
                          isAnnouncingToStage && (
                              <Modal
                                  onClose={() => setIsAnnouncingToStage(false)}
                                  title={`Announcement to all ${stage.name} participants`}
                              >
                                  <AnnounceToStage
                                      onSubmit={async (values: Core.Models.CreateStageAnnouncementRequest) => {
                                          await StageService.announceToStage(values);
                                          toast.success(
                                              `Sent announcement to ${values.participantIds.length} ${pluralize(
                                                  'participant',
                                                  values.participantIds.length
                                              )}${values.includeManagers ? ' and their manager(s)' : ''}.`
                                          );
                                          await reloadStage();
                                          setIsAnnouncingToStage(false);
                                      }}
                                      participants={participantsForAnnouncement}
                                      stageId={stage.id}
                                  />
                              </Modal>
                          ),
                      ]
                    : []),
                ...(canResetStage // `canResetStage` is evaluated with `canEditSeason`
                    ? [
                          <TertiaryButton as="button" onClick={startReset}>
                              <FontAwesomeIcon icon={['fas', 'arrow-rotate-left']} className="mr" size="sm" />
                              Reset {stage.name}
                          </TertiaryButton>,
                          resetNode,
                      ]
                    : []),
                ...(stage.currentState <= Core.Models.StageState.NotStarted && canEditSeason
                    ? [
                          <TertiaryButton as="link" to={`/stages/${id}/edit`}>
                              <FontAwesomeIcon icon={['fas', 'pen']} className="mr" size="sm" />
                              Edit {stage.name}
                          </TertiaryButton>,
                      ]
                    : []),
                ...(stage.currentState === Core.Models.StageState.NotStarted &&
                canEditSeason &&
                stage.settings.inputParticipantSource === Core.Models.InputParticipantSourceType.ManualList
                    ? [
                          <TertiaryButton
                              as="link"
                              className={classNames(needsToAddParticipants && 'attention')}
                              to={`/stages/${id}/participants`}
                              disabled={season.participants.length <= 0}
                          >
                              <FontAwesomeIcon icon={['fas', 'pen']} className="mr" size="sm" />
                              Edit {stage.name} participants
                          </TertiaryButton>,
                      ]
                    : []),
                ...(stage.currentState === Core.Models.StageState.NotStarted && canEditSeason
                    ? [
                          <TertiaryButton as="button" onClick={startDelete}>
                              <FontAwesomeIcon icon={['fas', 'trash']} className="mr" size="sm" />
                              Delete {stage.name}
                          </TertiaryButton>,
                          deleteNode,
                      ]
                    : []),
            ] as JSX.Element[],
        [
            addTeamNode,
            autoTiebreakNode,
            canAddLateTeam,
            canAutoTiebreak,
            canEditAnyEligibleParticipants,
            canEditSeason,
            canResetStage,
            deleteNode,
            id,
            matchWord,
            needsToAddParticipants,
            openAddTeamNode,
            openStageCheckinNode,
            reloadStage,
            resetNode,
            season.participants.length,
            showCompleteStageControl,
            stage,
            startAutoTiebreak,
            startDelete,
            startReset,
            startSeasonControl,
        ]
    );

    return (
        <div className="stage">
            {stageControls.length > 0 && (
                <div className="stage__controls">
                    {stageControls.map((control: JSX.Element, index: number) => (
                        <React.Fragment key={index}>{control}</React.Fragment>
                    ))}
                </div>
            )}

            <Announcements announcements={stage.announcements} {...{ canEditSeason, reloadStage }} />

            {stage.currentState !== Core.Models.StageState.CheckingIn && (
                <ContentContainer
                    className="stage__groups mb4x"
                    shade={Core.Models.Shades.Dark40}
                    radius={Core.Models.RadiusSizes.Large}
                >
                    {isEliminationStage ? (
                        <StageGroups
                            tennisStyle={season.tennisStyle}
                            {...{
                                locations,
                                matchWord,
                                stage,
                            }}
                        />
                    ) : (
                        <StageRounds
                            {...{
                                locations,
                                reloadStage,
                                season,
                                stage,
                            }}
                        />
                    )}
                </ContentContainer>
            )}

            {stage.currentState >= Core.Models.StageState.InProgress && (
                <ContentContainer
                    className="stage__standings mb4x"
                    shade={Core.Models.Shades.Dark40}
                    radius={Core.Models.RadiusSizes.Large}
                >
                    <StageStandings
                        externalLinks={externalLinks}
                        matchWord={matchWord}
                        reloadStage={reloadStage}
                        stage={stage}
                    />
                </ContentContainer>
            )}
            {stage.currentState === Core.Models.StageState.CheckingIn ? (
                <ContentContainer
                    className="stage__participants mb4x"
                    shade={Core.Models.Shades.Dark40}
                    radius={Core.Models.RadiusSizes.Large}
                >
                    <EligibleStageParticipants
                        reloadStage={reloadStage}
                        seasonParticipants={season.participants}
                        stage={stage}
                    />
                </ContentContainer>
            ) : (
                <ContentContainer
                    className="stage__participants mb4x"
                    shade={Core.Models.Shades.Dark40}
                    radius={Core.Models.RadiusSizes.Large}
                >
                    <StageParticipants reloadSeason={reloadSeason} season={season} stage={stage} />
                </ContentContainer>
            )}
        </div>
    );
};

export default withLoadDataDefaultConfig(
    errorLoadingWrapperHOC<SeasonStageProps>(SeasonStage),
    (props: SeasonStageProps) => pick(props, 'id'),
    async (props) => {
        const stage = await StageService.getById(props.id);
        return { stage };
    }
);

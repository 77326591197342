import React, { AnchorHTMLAttributes, useMemo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import * as Core from '../../core';
import genericLeagueIcon from '../../assets/images/logo.svg';
import { useIsPlatform, useLeague } from '../../hooks/store';

const HeaderLogo = ({ className }: AnchorHTMLAttributes<HTMLAnchorElement>) => {
    const isPlatform = useIsPlatform();
    const league = useLeague();

    const logoUrl = useMemo(() => league?.headerLogoUrl ?? league?.logoUrl ?? genericLeagueIcon, [league]);
    const name = useMemo(() => league?.name ?? Core.Constants.Company, [league]);

    return (
        <Link to={isPlatform ? '/' : '/league'} className={classNames('header-logo', className)}>
            <img src={logoUrl} alt={name} />
        </Link>
    );
};

export default HeaderLogo;

import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import pluralize from 'pluralize';

import * as Core from '../../core';
import { BaseButton, IconButton } from '../../components/buttons-visuals';
import ConfirmModal from '../../components/confirmModal';
import { Filter } from '../../components/inputs';
import { MediaObject } from '../../components/mediaObject';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import useApiPagination from '../../hooks/apiPagination';
import { useLeague, useOrganizationTerm, useUserPermissionService } from '../../hooks/store';
import { OrganizationService } from '../../services/organizationService';

interface OrganizationListProps extends WithLoadingProps {
    filter: string;
}

const OrganizationList = (props: OrganizationListProps): JSX.Element => {
    const { filter, setError, setIsLoading } = props;

    const league = useLeague();
    const organizationTerm = useOrganizationTerm();
    const userPermissionService = useUserPermissionService();

    const getPaginatedOrganizationsAsync = useCallback(
        (
            page: number,
            pageSize: number,
            search: string
        ): Promise<Core.Models.PaginatedResult<Core.Models.Organization>> => {
            return OrganizationService.getAll({ page, pageSize, search });
        },
        []
    );

    const { paginatedResults, Paginator, setSearch, updatePaginatedResults } = useApiPagination(
        getPaginatedOrganizationsAsync,
        setError,
        setIsLoading,
        Core.Constants.PAGINATION.ORGANIZATIONS_PER_PAGE
    );

    useEffect(() => {
        setSearch(filter);
    }, [filter, setSearch]);

    const canRemoveOrganization = userPermissionService.hasLeagueAccess(Core.Models.PermissionLevel.Edit, league?.id);

    const [orgToRemove, setOrgToRemove] = useState<Core.Models.Organization | undefined>(undefined);
    const removeOrganization = async () => {
        if (!orgToRemove) return;
        if (!paginatedResults) return;

        await OrganizationService.deleteOrganization(orgToRemove.id);
        setOrgToRemove(undefined);

        updatePaginatedResults([
            ...paginatedResults.results.filter(
                (organization: Core.Models.Organization) => organization.id !== orgToRemove.id
            ),
        ]);
    };

    if (!league) return <></>;
    if (!paginatedResults) return <></>;

    if (paginatedResults.results.length <= 0)
        return (
            <div className="league-page__organizations__no-items">No {pluralize(organizationTerm.toLowerCase())}</div>
        );

    return (
        <>
            <div className="league-page__organization-container">
                {paginatedResults.results.map((organization: Core.Models.Organization) => {
                    return (
                        <div key={organization.id} className="league-page__organization">
                            <BaseButton as="link" to={`/organizations/${organization.id}`} className="full-width">
                                <MediaObject
                                    avatarBackground={true}
                                    className={classNames('league-page__organization-media', {
                                        'league-page__organization-media--has-menu': canRemoveOrganization,
                                    })}
                                    fallback="organization"
                                    imageSize="large"
                                    imageUrl={organization.logoUrl}
                                >
                                    <p className="text-large mb0">{organization.name}</p>
                                    <p className="text-small mb0">
                                        {OrganizationService.getLocationString(organization)}
                                    </p>
                                </MediaObject>
                            </BaseButton>
                            {canRemoveOrganization && (
                                <IconButton
                                    as="button"
                                    buttonLabel="Remove"
                                    buttonSize="small"
                                    className="league-page__organization__remove"
                                    onClick={() => setOrgToRemove(organization)}
                                >
                                    <FontAwesomeIcon icon={['fas', 'trash']} />
                                </IconButton>
                            )}
                        </div>
                    );
                })}
                {orgToRemove && (
                    <ConfirmModal
                        onCancel={() => setOrgToRemove(undefined)}
                        onConfirm={() => removeOrganization()}
                        title="Are you sure"
                    >
                        <p>
                            Are you sure you want to remove <em>{orgToRemove.name}</em> from this league?
                        </p>
                    </ConfirmModal>
                )}
            </div>
            {Paginator}
        </>
    );
};

const OrganizationListWithLoading = withLoading(OrganizationList, { loadingProps: { blockItem: true } });

const LeagueOrganizations = (): JSX.Element => {
    const [filter, setFilter] = useState<string>('');
    const organizationTerm = useOrganizationTerm();

    return (
        <div className="league-page__organizations">
            <div className="league-page__organizations-header">
                <h4 className="league-page__organizations-title">Participating {pluralize(organizationTerm)}</h4>
                <Filter
                    className="league-page__organizations-filter"
                    debounceMs={Core.Constants.FORM_DEBOUNCE_TIME_MS}
                    label={`Find your ${organizationTerm.toLowerCase()}`}
                    setValue={setFilter}
                    value={filter}
                />
            </div>
            <OrganizationListWithLoading {...{ filter }} />
        </div>
    );
};

export default LeagueOrganizations;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';

import * as Core from '../../../core';
import MatchUser from './matchUser';
import { useLeague, useUserPermissionService } from '../../../hooks/store';

import './index.scss';

interface MatchUserListProps {
    canEdit: boolean;
    full: boolean;
    game: {
        featureSet: Core.Models.FeatureSet;
        gameHandleSource?: Core.Models.GameHandleSource;
        name: string;
        rankings: { [key: string]: number };
        rankingTerm: string;
        rankingType: Core.Models.RankingType;
        resultFileExtensions?: string[];
    };
    handleListClick: (userId: string, participantId: string, checked: boolean) => void;
    hideCheckinSelector: boolean;
    participant: Core.Models.MatchParticipant;
    requireGameRanks: boolean;
    requireHandleForCheckin: boolean;
    roster: { [key: string]: boolean };
    seasonId?: string;
}

function getIsCaptain(member: Partial<Core.Models.MatchParticipantUser>) {
    return member.teamRoleId === Core.Models.TeamRoleId.Captain;
}

export const sortMembers = [
    (mu: Core.Models.MatchParticipantUser) => mu.sortOrder,
    (mu: Core.Models.MatchParticipantUser) => mu.isEligible === false,
    (mu: Core.Models.MatchParticipantUser) => !getIsCaptain(mu),
    (mu: Core.Models.MatchParticipantUser) => (Core.Identity.renderMemberName(mu) || 'zzzzzz').toLowerCase(),
];

export const chessSortMembers = [
    (mu: Core.Models.MatchParticipantUser) => mu.sortOrder,
    (mu: Core.Models.MatchParticipantUser) => mu.isEligible === false,
    (mu: Core.Models.MatchParticipantUser) => -+(mu.gameRanking?.value || '-1'), // elo desc
    (mu: Core.Models.MatchParticipantUser) => !getIsCaptain(mu),
    (mu: Core.Models.MatchParticipantUser) => (Core.Identity.renderMemberName(mu) || 'zzzzzz').toLowerCase(),
];

const MatchUsersList = (props: MatchUserListProps) => {
    const { canEdit, full, game, participant, requireGameRanks, requireHandleForCheckin, roster, seasonId } = props;
    const { hasCheckedIn, users } = participant;
    const userPermissionService = useUserPermissionService();
    const league = useLeague();
    const handleUserClick = (userId: string, checked: boolean) => {
        props.handleListClick(userId, props.participant.participantId, checked);
    };

    const canEditSeason = userPermissionService.canEditSeason(seasonId, league?.id);
    const coaches = users.filter(
        (user: Core.Models.MatchParticipantUser) => user.teamRoleId === Core.Models.TeamRoleId.Coach
    );
    const players = users.filter(
        (user: Core.Models.MatchParticipantUser) => user.teamRoleId !== Core.Models.TeamRoleId.Coach
    );

    return (
        <>
            <div className="match-users">
                {sortBy(players, game.featureSet === Core.Models.FeatureSet.Chess ? chessSortMembers : sortMembers).map(
                    (user: Core.Models.MatchParticipantUser, index: number) => (
                        <MatchUser
                            canEdit={canEdit}
                            checkedIn={hasCheckedIn}
                            clickable={
                                (canEditSeason || (user.competitionReadyPendingItems?.length ?? 0) <= 0) &&
                                !hasCheckedIn &&
                                !user.anonymous &&
                                user.isEligible &&
                                !user.isUnverifiedAccount &&
                                canEdit &&
                                (!full || (user.userId ? roster[user.userId] : false)) &&
                                (canEditSeason || !requireHandleForCheckin || !!user.gameHandle?.handle) &&
                                (game.featureSet !== Core.Models.FeatureSet.Hearthstone ||
                                    !!participant.hearthstoneDecks?.length)
                            }
                            game={game}
                            handleUserClick={handleUserClick}
                            key={index}
                            requireGameRanks={requireGameRanks}
                            requireHandleForCheckin={requireHandleForCheckin}
                            selected={!!user.userId ? roster[user.userId] : false}
                            showCheckbox={!props.hideCheckinSelector}
                            user={user}
                        />
                    )
                )}
            </div>
            {coaches.length > 0 && (
                <div className="match-coach mt3x">
                    <h5 className="heading-5">
                        <FontAwesomeIcon icon={['fas', 'bullhorn']} size="1x" className="mr" />
                        Coaches
                    </h5>
                    <div>
                        {coaches.map((coach: Core.Models.MatchParticipantUser, index: number) => (
                            <MatchUser game={game} hideRequirements key={index} user={coach} />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default MatchUsersList;

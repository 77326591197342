import React, { useRef, HTMLAttributes } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import useClickOutside from '../../hooks/clickOutside';
import { IconButton } from '../buttons-visuals';

import './index.scss';

export interface ModalProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
    className?: string;
    onClose?: () => void;
    title?: React.ReactNode; // TODO: change to string and remove here and omit
}

const Modal = (props: ModalProps) => {
    const { children, className, onClose, title } = props;
    const modalRef = useRef<HTMLDivElement>(null);

    useClickOutside(modalRef, () => {
        if (onClose) onClose();
    });

    return (
        <div className="modal-host">
            <div className={classNames(className, 'modal-container')} ref={modalRef} role="dialog">
                <div className="modal-container__title">{title}</div>
                {onClose && (
                    <IconButton
                        as="button"
                        buttonLabel="Close dialog"
                        buttonSize="small"
                        className="modal-container__close-button"
                        onClick={onClose}
                        tabIndex={0}
                    >
                        <FontAwesomeIcon icon={['fas', 'xmark']} />
                    </IconButton>
                )}
                {children}
            </div>
        </div>
    );
};

export default Modal;

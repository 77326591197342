import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import ConfirmModal from '../../../components/confirmModal';
import { MediaObject } from '../../../components/mediaObject';
import Menu from '../../../components/menu';
import withLoading, { WithLoadingProps } from '../../../components/withLoading';
import useApiPagination from '../../../hooks/apiPagination';
import { useAlternateSeasonName } from '../../../hooks/store';
import { SeasonService } from '../../../services/seasonService';

export interface FreeAgentListProps extends WithLoadingProps {
    filter: string;
    season: Core.Models.Season;
}

const FreeAgentList = (props: FreeAgentListProps) => {
    const { filter, season, setError, setIsLoading } = props;
    const seasonAlternateName = useAlternateSeasonName();
    const [freeAgentBeingRemoved, setFreeAgentBeingRemoved] = useState<Core.Models.SeasonFreeAgent | undefined>(
        undefined
    );

    const getPaginatedSeasonFreeAgentsAsync = useCallback(
        (
            page: number,
            pageSize: number,
            search: string
        ): Promise<Core.Models.PaginatedResult<Core.Models.SeasonFreeAgent>> => {
            return SeasonService.getSeasonFreeAgents(season.id, { page, pageSize, search });
        },
        [season.id]
    );

    const { paginatedResults, Paginator, setSearch, updatePaginatedResults } = useApiPagination(
        getPaginatedSeasonFreeAgentsAsync,
        setError,
        setIsLoading,
        Core.Constants.PAGINATION.FREE_AGENTS_PER_PAGE
    );

    useEffect(() => setSearch(filter), [filter, setSearch]);

    const removeFreeAgent = useCallback(async () => {
        if (!freeAgentBeingRemoved) return;
        if (!paginatedResults) return;

        await SeasonService.leaveAsFreeAgent(freeAgentBeingRemoved!.id);

        updatePaginatedResults([
            ...paginatedResults.results.filter(
                (freeAgent: Core.Models.SeasonFreeAgent) => freeAgent.id !== freeAgentBeingRemoved.id
            ),
        ]);

        setFreeAgentBeingRemoved(undefined);

        toast.success('Successfully removed free agent');
    }, [freeAgentBeingRemoved, paginatedResults, updatePaginatedResults]);

    if (!paginatedResults) return <></>;

    return (
        <div>
            {paginatedResults.results.length === 0 ? (
                <>
                    There are no active free agents in this {seasonAlternateName.toLowerCase()}
                    {!filter ? '' : ' that match the provided search term'}.
                </>
            ) : (
                <div className="free-agents-list">
                    {paginatedResults.results.map((fa: Core.Models.SeasonFreeAgent) => {
                        const user = fa.user!;
                        return (
                            <MediaObject
                                className={'free-agents__user'}
                                fallback="user"
                                imageSize="large"
                                imageUrl={user.avatarUrl}
                                isUnder13={user.isUnder13}
                                key={fa.id}
                            >
                                <Link to={`/users/${user.userId}`}>{Core.Identity.renderMemberName(user)}</Link>
                                <Menu className="free-agents__menu">
                                    <button
                                        onClick={() => setFreeAgentBeingRemoved(fa)}
                                        disabled={!!freeAgentBeingRemoved}
                                    >
                                        Remove
                                    </button>
                                </Menu>
                            </MediaObject>
                        );
                    })}
                    {!!freeAgentBeingRemoved && (
                        <ConfirmModal
                            onCancel={() => setFreeAgentBeingRemoved(undefined)}
                            onConfirm={removeFreeAgent}
                            title="Remove free agent"
                        >
                            <p>
                                Are you sure you want to remove{' '}
                                <strong>
                                    {freeAgentBeingRemoved.user!.firstName}
                                    {freeAgentBeingRemoved.user!.lastName}
                                </strong>{' '}
                                as a free agent?
                            </p>
                        </ConfirmModal>
                    )}
                </div>
            )}
            {Paginator}
        </div>
    );
};

export default withLoading(FreeAgentList, {
    loadingProps: { blockItem: true },
});

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { IconButtonProps } from './IconButton.types';
import { BaseButton } from '../baseButton';

import './IconButton.scss';

const IconButton = ({ buttonLabel, buttonSize, children, className, disabled, pending, ...rest }: IconButtonProps) => {
    const iconButtonClasses = classNames(className, 'ds-icon-button', `ds-icon-button--${buttonSize}`, {
        'ds-icon-button--disabled': disabled,
    });

    return (
        <BaseButton aria-label={buttonLabel} className={iconButtonClasses} title={buttonLabel} {...rest}>
            {pending ? <FontAwesomeIcon icon={['fas', 'spinner']} size="1x" className="fa-spin-pulse" /> : children}
        </BaseButton>
    );
};

export default IconButton;

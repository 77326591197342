import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FieldProps } from 'formik';

import * as Core from '../../../core';
import FormField from '../../formField';
import { TextInput } from '../textinput';

import './CreatePassword.scss';

interface CreatePasswordProps {
    passwordDescription?: string;
    confirmPasswordDescription?: string;
    noFlyout?: boolean;
}

interface CreatePasswordState {
    upperCase?: boolean;
    lowerCase?: boolean;
    numberCompliance?: boolean;
    minLength?: boolean;
    focused?: boolean;
}

export default class CreatePassword extends React.PureComponent<CreatePasswordProps, CreatePasswordState> {
    constructor(props: CreatePasswordProps) {
        super(props);
        this.state = {};
    }
    ref = React.createRef<HTMLDivElement>();
    inputRef = React.createRef<HTMLInputElement>();
    passwordValidation = async (value: string) => {
        const upperCase = /[A-Z]/g;
        const lowerCase = /[a-z]/g;
        const number = /[0-9]/g;
        this.setState({
            upperCase: !!value.match(upperCase),
            lowerCase: !!value.match(lowerCase),
            numberCompliance: !!value.match(number),
            minLength: value.length >= Core.Constants.PASSWORD_MIN_LENGTH,
        });
    };

    onFocus = () => {
        this.setState({ focused: true }, () => {
            const element = this.ref.current;
            if (element) {
                // element.scrollIntoView({ block: 'nearest'}) would work, but not supported on IE11 or current Edge
                const { top, bottom } = element.getBoundingClientRect();
                if (top < 0) {
                    element.scrollIntoView(true);
                } else if (document.documentElement.clientHeight < bottom) {
                    element.scrollIntoView(false);
                }
            }
        });
    };

    renderCustomInput = ({ field }: FieldProps) => {
        const { onBlur } = field;
        const realOnBlur = (e: React.FocusEvent) => {
            if (onBlur) {
                onBlur(e);
            }
            this.setState({ focused: false });
        };
        return (
            <TextInput
                {...field}
                id="password"
                label={this.props.passwordDescription || 'Password'}
                placeholder={this.props.passwordDescription || 'Password'}
                type="password"
                onFocus={this.onFocus}
                onBlur={realOnBlur}
                error={
                    this.inputRef.current?.value.length
                        ? !this.state.upperCase ||
                          !this.state.lowerCase ||
                          !this.state.numberCompliance ||
                          !this.state.minLength
                        : false
                }
                ref={this.inputRef}
            />
        );
    };
    renderSuccess = (success: boolean, message: string) => (
        <p className="disp-flex align-center mb0">
            <FontAwesomeIcon
                icon={['fas', 'circle-check']}
                className={classNames('mr', success ? 'color-success' : 'color-gray')}
            />
            {message}
        </p>
    );

    render() {
        return (
            // form-group triggers spacing, form-group--undecorated keeps the border from showing up
            <div ref={this.ref} className="create-password form-group form-group--undecorated">
                <fieldset className="form-group">
                    <FormField
                        name="password"
                        type="password"
                        component={this.renderCustomInput}
                        validate={(value: string) => this.passwordValidation(value)}
                    />
                    <FormField
                        type="password"
                        name="passwordConfirmation"
                        label={this.props.confirmPasswordDescription || 'Password (repeat)'}
                    />
                </fieldset>
                <div
                    className={classNames('create-password__dynamic-errors', {
                        focused: this.state.focused,
                        'create-password__dynamic-errors--no-flyout': this.props.noFlyout,
                    })}
                >
                    <p>Password must contain:</p>
                    {this.renderSuccess(!!this.state.upperCase, 'Capital letter')}
                    {this.renderSuccess(!!this.state.lowerCase, 'Lowercase letter')}
                    {this.renderSuccess(!!this.state.numberCompliance, 'Number')}
                    {this.renderSuccess(!!this.state.minLength, 'At least 8 characters')}
                </div>
            </div>
        );
    }
}

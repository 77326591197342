import React from 'react';
import classNames from 'classnames';
import { orderBy } from 'lodash';
import { Link } from 'react-router-dom';

import * as Core from '../../core';
import { useBracket } from './hooks';
import { BracketRound } from './types';
import ParticipantLink from '../participantLink';

import './index.scss';

interface BracketProps {
    bracket: Core.Models.Bracket;
    groupMatches: Core.Models.Match[];
    stageRounds: Core.Models.Round[];
}

const Bracket = ({ bracket, groupMatches, stageRounds }: BracketProps) => {
    const { bracketRounds, hideLinkSubBracket, isMatchInBracket, isMyParticipant, isSubBracket, winner } = useBracket(
        bracket,
        groupMatches.filter((m: Core.Models.Match) => m.bracket === bracket),
        stageRounds
    );
    return (
        <div
            className={classNames(
                'elimination-bracket',
                isSubBracket ? 'elimination-bracket__sub-bracket' : 'elimination-bracket__main-bracket',
                `elimination-bracket--rounds-${bracketRounds.length}`
            )}
        >
            {bracketRounds.map((round: BracketRound, index: number) => {
                // rounds are numbered oddly for CSS-generation reasons - className for the final round of competition is '1', one before it is '2', etc.
                const roundNumber = bracketRounds.length - index;
                return (
                    <React.Fragment key={roundNumber}>
                        <span
                            className={classNames(
                                'elimination-bracket__round-header',
                                `elimination-bracket__round-${roundNumber}__header`
                            )}
                        >
                            {round.name}
                        </span>
                        {round.matches.map((match, matchNumber) => {
                            if (!match || match.matchParticipants.length <= 1)
                                return <React.Fragment key={matchNumber} />;

                            return (
                                <div
                                    className={classNames(
                                        'elimination-bracket__match',
                                        `elimination-bracket__round-${roundNumber}__match-${matchNumber}`,
                                        `elimination-bracket__${isSubBracket ? 'sub' : 'main'}-bracket`,
                                        {
                                            'elimination-bracket__match--has-previous': match.matchParticipants.some(
                                                (mp: Core.Models.MatchParticipant) =>
                                                    !!mp.previousMatchId && isMatchInBracket(mp.previousMatchId)
                                            ),
                                            'elimination-bracket__sub-bracket--hide': hideLinkSubBracket(roundNumber),
                                        }
                                    )}
                                    key={matchNumber}
                                >
                                    <span className="elimination-bracket__match-number">{match.numberInBracket}</span>
                                    <Link
                                        className="elimination-bracket__match-click-target"
                                        to={`/matches/${match.id}`}
                                    >
                                        {orderBy(
                                            match.matchParticipants,
                                            (mp: Core.Models.MatchParticipant) => mp.sortOrder
                                        ).map((mp: Core.Models.MatchParticipant) => {
                                            if (!mp.teamId) {
                                                let previousMatch = groupMatches.find(
                                                    (m: Core.Models.Match) => m.id === mp.previousMatchId
                                                );

                                                if (!!previousMatch && previousMatch.matchParticipants.length <= 1) {
                                                    // if previous match was a bye, automatically pull forward the "Loser of N" label
                                                    previousMatch = groupMatches.find(
                                                        (m: Core.Models.Match) =>
                                                            m.id ===
                                                            previousMatch?.matchParticipants[0]?.previousMatchId
                                                    );
                                                }

                                                if (
                                                    !!previousMatch?.numberInBracket &&
                                                    previousMatch.bracket !== match.bracket
                                                ) {
                                                    // Prevents doubling up of "Winner of" label.
                                                    const otherParticipantPreviousMatchId =
                                                        match.matchParticipants.find(
                                                            (omp: Core.Models.MatchParticipant) => omp.id !== mp.id
                                                        )?.previousMatchId;
                                                    const showWinnerLabel =
                                                        bracket === Core.Models.Bracket.Finals &&
                                                        (previousMatch.id !== otherParticipantPreviousMatchId ||
                                                            mp.sortOrder <= 1);
                                                    return (
                                                        <span
                                                            className="elimination-bracket__previous-match-link"
                                                            key={mp.id}
                                                        >
                                                            <span className="elimination-bracket__participant-name">
                                                                {showWinnerLabel ? 'Winner' : 'Loser'} of{' '}
                                                                {previousMatch.numberInBracket}
                                                            </span>
                                                        </span>
                                                    );
                                                }

                                                // not actually a team yet, just a placeholder
                                                return (
                                                    <span className="elimination-bracket__participant" key={mp.id} />
                                                );
                                            }

                                            return (
                                                <span
                                                    className={classNames('elimination-bracket__participant', {
                                                        'elimination-bracket__participant--winner': mp.isWinner,
                                                        'elimination-bracket__participant--my-participant':
                                                            isMyParticipant && isMyParticipant(mp),
                                                    })}
                                                    key={mp.id}
                                                >
                                                    <span
                                                        className="elimination-bracket__participant-name"
                                                        title={mp.name}
                                                    >
                                                        {`${mp.groupInputRank}. ${mp.name}`}
                                                    </span>
                                                    {match.currentState >= Core.Models.MatchState.InProgress && (
                                                        <span className="elimination-bracket__participant-score">
                                                            {mp.score}
                                                        </span>
                                                    )}
                                                </span>
                                            );
                                        })}
                                    </Link>
                                </div>
                            );
                        })}
                    </React.Fragment>
                );
            })}
            <span className={`elimination-bracket__round-header elimination-bracket__round-0__header`}>
                {bracket === Core.Models.Bracket.Finals && 'Grand Finals '}
                {bracket === Core.Models.Bracket.Upper && 'Upper '}
                {bracket === Core.Models.Bracket.Lower && 'Lower '}
                Winner
            </span>
            <div
                className={classNames(
                    'elimination-bracket__match elimination-bracket__round-0__match-0',
                    'elimination-bracket__match--has-previous',
                    `elimination-bracket__${isSubBracket ? 'sub' : 'main'}-bracket`
                )}
            >
                {winner && winner.name ? (
                    <ParticipantLink className="elimination-bracket__match-click-target" participant={winner} unstyled>
                        <span
                            className={classNames(
                                'elimination-bracket__participant elimination-bracket__participant--winner',
                                {
                                    'elimination-bracket__participant--my-participant':
                                        isMyParticipant && isMyParticipant(winner),
                                }
                            )}
                        >
                            <span className="elimination-bracket__participant-name" title={winner.name}>
                                {`${winner.groupInputRank}. ${winner.name}`}
                            </span>
                        </span>
                    </ParticipantLink>
                ) : (
                    <div className="elimination-bracket__match-click-target">
                        <span className="elimination-bracket__participant">
                            <span className="elimination-bracket__participant-name">Winner</span>
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Bracket;

import React from 'react';

import AddLobbyInstructions from './addLobbyInstructions';
import { SolidButton } from '../../../components/buttons-visuals';
import Markdown from '../../../components/markdown';
import { useModal } from '../../../hooks/modal';

import './index.scss';

interface MatchLobbyInstructionsProps {
    canEditLobbyInstructions: boolean;
    lobbyInstructions?: string;
    matchId: string;
    matchWord: string;
    reloadData: (showLoading: boolean) => Promise<void>;
}

const MatchLobbyInstructions = (props: MatchLobbyInstructionsProps): JSX.Element => {
    const { canEditLobbyInstructions, lobbyInstructions, matchId, matchWord, reloadData } = props;

    const [addLobbyInstructionsModal, openAddLobbyInstructionsModal] = useModal(
        () => 'Add lobby instructions',
        (closeAddLobbyInstructionsModal) => (
            <AddLobbyInstructions
                {...{ lobbyInstructions, matchId, matchWord, reloadData }}
                onClose={closeAddLobbyInstructionsModal}
            />
        )
    );

    if (!lobbyInstructions && !canEditLobbyInstructions) return <></>;
    return (
        <div className="match-page__lobby-instructions">
            {!!lobbyInstructions && (
                <>
                    <h6>{matchWord} lobby instructions</h6>
                    <Markdown source={lobbyInstructions} />
                </>
            )}

            {canEditLobbyInstructions && (
                <>
                    <SolidButton as="button" onClick={openAddLobbyInstructionsModal}>
                        Add lobby instructions
                    </SolidButton>
                    {addLobbyInstructionsModal}
                </>
            )}
        </div>
    );
};

export default MatchLobbyInstructions;

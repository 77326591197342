import React from 'react';
import classNames from 'classnames';

import * as Core from '../../core';
import { ContentContainer } from '../../components/containers';

import './matchSchedule.scss';

interface MatchScheduleTypes extends React.InputHTMLAttributes<HTMLDivElement> {
    controls?: React.ReactNode;
    title: string;
}
const MatchSchedule = ({ children, className, controls, title, ...rest }: MatchScheduleTypes) => {
    return (
        <ContentContainer
            className={classNames(className, 'match-schedule p0')}
            shade={Core.Models.Shades.Dark20}
            {...rest}
        >
            <div className="match-schedule__header">
                <div className="match-schedule__header__controls">{controls}</div>
                <h3 className="heading-3 mb0 match-schedule__header__title">{title}</h3>
            </div>
            <div className="match-schedule__content">{children}</div>
        </ContentContainer>
    );
};

export default MatchSchedule;

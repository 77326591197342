import React, { ChangeEvent, MouseEventHandler, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import { MatchParticipantService } from '../../../services/matchParticipantService';
import { Select } from '../../inputs/select';

import './index.scss';

const NEW_STATION_ID = 'NEW_STATION_ID';

interface MatchParticipantStationProps {
    canEditSeason: boolean;
    competitionStyle: Core.Models.CompetitionStyle;
    currentState: Core.Models.MatchState;
    location?: Core.Models.LeagueLocation;
    onStationSelect?: (id: string) => void;
    participant: Core.Models.MatchParticipant;
}

const MatchParticipantStation = (props: MatchParticipantStationProps) => {
    const { canEditSeason, currentState, location, onStationSelect, participant } = props;
    const { id, name } = participant;

    const [selectedStationId, setSelectedStationId] = useState<string>(participant.stationId ?? '');
    const [stationIsLoading, setStationIsLoading] = useState<boolean>(false);

    useEffect(() => setSelectedStationId(participant.stationId ?? ''), [participant.stationId]);

    const currentStation = useMemo(
        () => location?.stations.find((s: Core.Models.LeagueLocationStation) => s.id === selectedStationId),
        [location?.stations, selectedStationId]
    );

    const stationIsDeleted = useMemo(
        () => canEditSeason && !!currentStation?.deleted && currentState < Core.Models.MatchState.IsComplete,
        [canEditSeason, currentState, currentStation?.deleted]
    );

    if (!selectedStationId && (!location || !canEditSeason || currentState >= Core.Models.MatchState.IsComplete))
        return <></>;
    return (
        <div
            className={classNames('match-station', {
                'match-station--error': stationIsDeleted,
                'match-station--loading': stationIsLoading,
            })}
            title={stationIsDeleted ? `${currentStation?.name} has been deleted.` : undefined}
        >
            {canEditSeason && currentState < Core.Models.MatchState.IsComplete ? (
                <Select
                    className="match-station__station-list mb0"
                    containerClassName="match-station__station-list"
                    disabled={stationIsLoading || location?.deleted}
                    label="Station"
                    onClick={((e) => e.preventDefault()) as MouseEventHandler<HTMLSelectElement>}
                    onChange={async (e: ChangeEvent<HTMLSelectElement>) => {
                        if (e.target.value === NEW_STATION_ID) {
                            onStationSelect?.(participant.id);
                        } else {
                            setStationIsLoading(true);
                            try {
                                const newStationId = e.target.value;
                                await MatchParticipantService.assignStation({
                                    matchParticipantId: id,
                                    stationId: newStationId || undefined,
                                });
                                setSelectedStationId(newStationId);
                                toast.success(`Successfully updated ${name}'s station`);
                            } catch (e) {
                                const message = Core.API.getErrorMessage(e);
                                toast.error(message);
                            } finally {
                                setStationIsLoading(false);
                            }
                        }
                    }}
                    value={selectedStationId}
                >
                    <option value="">--</option>
                    {location?.stations.map((s: Core.Models.LeagueLocationStation) => (
                        <option disabled={s.deleted} hidden={s.deleted} key={s.id} value={s.id}>
                            {s.name}
                        </option>
                    ))}
                    <option value={NEW_STATION_ID}>+ Add</option>
                </Select>
            ) : (
                <div className="match-station__station-name">{currentStation?.name}</div>
            )}
        </div>
    );
};

export default MatchParticipantStation;

import React, { useEffect } from 'react';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import { isUndefined } from 'lodash';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import { Button } from '../../components/button';
import FormField from '../../components/formField';
import Loading from '../../components/loading';
import { OrganizationService } from '../../services/organizationService';
import ErrorMessage from '../errorMessage';

interface ResendInvitesProps {
    onComplete: () => void;
    organizationId: string;
}

export interface ResendInvitesFormValues {
    inviteTokens: string[];
}

const ResendInvites = (props: ResendInvitesProps) => {
    const { onComplete, organizationId } = props;

    const [members, setMembers] = React.useState<Core.Models.UnregisteredOrganizationMember[] | undefined>(undefined);

    useEffect(() => {
        (async () => {
            try {
                const data = await OrganizationService.getUnregisteredMembers(organizationId);
                setMembers(data);
            } catch (e) {
                toast.error('Error loading unregistered members');
            }
        })();
    }, [organizationId, setMembers]);

    if (isUndefined(members)) return <></>;
    return (
        <div className="resend-invites">
            <Formik
                initialValues={{
                    inviteTokens: members.map((m: Core.Models.UnregisteredOrganizationMember) => m.userInviteToken),
                }}
                onSubmit={async (values: ResendInvitesFormValues, actions: FormikActions<ResendInvitesFormValues>) => {
                    try {
                        await OrganizationService.resendUserInvites({
                            organizationId: organizationId,
                            userInviteTokens: values.inviteTokens,
                        });
                        toast.success('Successfully resent invites');
                        onComplete();
                    } catch (error) {
                        const message = Core.API.getErrorMessage(error);
                        actions.setStatus(message);
                    } finally {
                        actions.setSubmitting(false);
                    }
                }}
                render={(formProps: FormikProps<ResendInvitesFormValues>) => (
                    <Form>
                        <fieldset className="form-group form-group--undecorated">
                            {members.length > 0 ? (
                                members.map((user: Core.Models.UnregisteredOrganizationMember) => (
                                    <FormField
                                        checked={formProps.values.inviteTokens.some(
                                            (token: string) => token === user.userInviteToken
                                        )}
                                        description={user.email}
                                        key={user.userInviteToken}
                                        label={user.email}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            let existingTokens = [...formProps.values.inviteTokens];
                                            const selectedToken = event.target.value;
                                            if (existingTokens.some((token: string) => token === selectedToken)) {
                                                existingTokens = existingTokens.filter(
                                                    (token: string) => token !== selectedToken
                                                );
                                            } else {
                                                existingTokens = [...existingTokens, selectedToken];
                                            }
                                            formProps.setFieldValue('inviteTokens', existingTokens);
                                        }}
                                        name={`${user.userInviteToken}`}
                                        type="checkbox"
                                        value={user.userInviteToken}
                                    />
                                ))
                            ) : (
                                <p>No invites to resend</p>
                            )}
                        </fieldset>

                        {formProps.status && <ErrorMessage error={formProps.status} />}
                        <ErrorMessage error={formProps.errors} filter={formProps.touched} />

                        <fieldset className="form-group form-group--undecorated">
                            {formProps.isSubmitting && <Loading buttonLoader />}
                            <Button
                                onClick={formProps.submitForm}
                                disabled={formProps.isSubmitting || formProps.values.inviteTokens.length === 0}
                                wide
                                round
                            >
                                Resend Invites
                            </Button>
                        </fieldset>
                    </Form>
                )}
            />
        </div>
    );
};

export default ResendInvites;

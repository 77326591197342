import * as React from 'react';

import Markdown from '..';

import './index.scss';

interface MarkdownPreviewProps {
    fieldName: string;
    source?: string;
}

const MarkdownPreview = (props: MarkdownPreviewProps): JSX.Element => {
    const { fieldName, source } = props;

    if (!source) return <></>;
    return (
        <div className="text-large mb2x pl2x pr2x">
            <p className="markdown-preview__title">{fieldName} (preview):</p>
            <fieldset className="form-group">
                <Markdown {...{ source }} />
            </fieldset>
        </div>
    );
};

export default MarkdownPreview;

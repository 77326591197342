import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isUndefined, orderBy, sortBy } from 'lodash';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import { getCheckedRosterUsers } from './matchParticipants';
import { chessSortMembers, sortMembers } from './matchUserList/matchUserList';
import ConfirmModal from '../../components/confirmModal';
import DraggableList from '../../components/draggableList';
import { MatchService } from '../../services/matchService';

interface CheckInModalProps {
    canReorder: boolean;
    gameFeatureSet: Core.Models.FeatureSet;
    matchId: string;
    matchParticipants: Core.Models.MatchParticipant[];
    onCancel: () => void;
    onCheckedIn: () => Promise<void>;
    rosters: { [key: string]: { [key: string]: boolean } };
}

const CheckInModal = ({
    canReorder,
    gameFeatureSet,
    matchId,
    matchParticipants,
    onCancel,
    onCheckedIn,
    rosters,
}: CheckInModalProps): JSX.Element => {
    const [bottomTeamUsers, setBottomTeamUsers] = useState<Core.Models.MatchParticipantUser[]>(
        getInitialRoster(rosters, matchParticipants[1], gameFeatureSet)
    );
    const [topTeamUsers, setTopTeamUsers] = useState<Core.Models.MatchParticipantUser[]>(
        getInitialRoster(rosters, matchParticipants[0], gameFeatureSet)
    );

    const confirmCheckIn = useCallback(async () => {
        try {
            for (const [index, users] of [topTeamUsers, bottomTeamUsers].entries()) {
                if (users?.length > 0) {
                    const userIds = users.map((user: Core.Models.MatchParticipantUser) => user.userId!);
                    await MatchService.checkIn({
                        matchId,
                        participantId: matchParticipants[index].participantId,
                        userIds,
                    });
                }
            }

            await onCheckedIn();
        } catch (e) {
            const message = Core.API.getErrorMessage(e);
            toast.error(`There was an issue checking in: ${message}`);
        }
    }, [bottomTeamUsers, matchId, matchParticipants, onCheckedIn, rosters, topTeamUsers]);

    if (isUndefined(matchParticipants)) return <></>;
    return (
        <ConfirmModal
            onCancel={onCancel}
            onConfirm={confirmCheckIn}
            title="Please confirm your roster to complete check-in"
        >
            {canReorder && (
                <p>
                    Click and drag to reorder your roster below before confirming. Matchups will be created in order of
                    check-in position.
                </p>
            )}
            <div className="confirm-roster__lists mb2x">
                {renderRoster(matchParticipants[0], topTeamUsers, setTopTeamUsers, canReorder)}
                {renderRoster(matchParticipants[1], bottomTeamUsers, setBottomTeamUsers, canReorder)}
            </div>
        </ConfirmModal>
    );
};

const getInitialRoster = (
    rosters: { [key: string]: { [key: string]: boolean } },
    matchParticipant: Core.Models.MatchParticipant,
    gameFeatureSet: Core.Models.FeatureSet
): Core.Models.MatchParticipantUser[] =>
    orderBy(
        getCheckedRosterUsers(
            sortBy(
                matchParticipant.users,
                gameFeatureSet === Core.Models.FeatureSet.Chess ? chessSortMembers : sortMembers
            ),
            rosters[matchParticipant.participantId]
        ),
        ['sortOrder'],
        ['asc']
    );

const renderRoster = (
    matchParticipant: Core.Models.MatchParticipant,
    users: Core.Models.MatchParticipantUser[],
    setUsers: (value: React.SetStateAction<Core.Models.MatchParticipantUser[]>) => void,
    canReorder: boolean
): JSX.Element => {
    if (matchParticipant.hasCheckedIn) return <></>;
    if (users.length <= 0) return <></>;

    return (
        <ul>
            <DraggableList
                disabled={!canReorder}
                getId={(user: Core.Models.MatchParticipantUser) => user.userId!}
                items={users}
                renderItem={(user: Core.Models.MatchParticipantUser) => {
                    return (
                        <li className="mb2x">
                            {canReorder && <FontAwesomeIcon className="color-gray mr" icon="grip-vertical" />}
                            {Core.Identity.renderMemberName(user)}
                        </li>
                    );
                }}
                setItems={(items: Core.Models.MatchParticipantUser[]) => setUsers([...items])}
            />
        </ul>
    );
};

export default CheckInModal;

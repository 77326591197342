import React from 'react';
import classNames from 'classnames';
import AuthCode from 'react-auth-code-input';

import './index.scss';

interface AuthCodeInputProps {
    autoFocus?: boolean;
    isError?: boolean;
    setValue: (value: string) => void;
}

const AuthCodeInput = (props: AuthCodeInputProps) => {
    const { autoFocus = false, isError, setValue } = props;

    return (
        <>
            <p className="ds-text-field__message ds-text-field__message--helpText mb2x">Authenticator code</p>
            <AuthCode
                autoFocus={autoFocus}
                allowedCharacters="numeric"
                containerClassName="auth-code-input-container mb2x"
                inputClassName={classNames('ds-text-field__wrapper', {
                    'ds-text-field__wrapper--error': isError,
                })}
                length={6}
                onChange={(value: string) => setValue(value)}
            />
        </>
    );
};

export default AuthCodeInput;

import moment from 'moment-timezone';

import * as Core from '../core';

export abstract class TimeService {
    // translates serverDate to a client-clock-based date using response
    public static getAdjustedTime(
        response: Core.Models.HasResponseDate,
        serverDate: moment.MomentInput,
        timezone: string
    ) {
        const { receivedDateUtc, responseDateUtc } = response;
        return moment.tz(serverDate, timezone).add(moment(receivedDateUtc, timezone).diff(responseDateUtc, 's'), 's');
    }

    public static getUserTimezone = (): string | undefined => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone?.replaceAll(' ', '_'); // replace 'America/New York' with 'America/New_York'

        if (!Core.Constants.SUPPORTED_TIMEZONES.includes(timezone)) return undefined;
        return timezone;
    };
}

import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';

import * as Core from '../../core';
import { useIsPlatform, useLeague } from '../../hooks/store';
import { IHasChildren } from '../../layouts/types';

interface IHeaderTags extends IHasChildren {}

const HeaderTags = ({ children }: IHeaderTags) => {
    const isPlatform = useIsPlatform();
    const league = useLeague();

    const description = useMemo(() => `Welcome to ${league?.name}`, [league?.name]);
    const favicon = useMemo(
        () => league?.faviconUrl ?? `${process.env.PUBLIC_URL}/leaguespot-32x32.png`,
        [league?.faviconUrl]
    );
    const title = useMemo(
        () => (!!isPlatform ? `${Core.Constants.Company} Platform` : league?.name),
        [isPlatform, league?.name]
    );

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <link type="image/x-icon" rel="shortcut icon" href={favicon} />
                {!isPlatform && !!league && <meta name="description" content={description} />}
                {!isPlatform && !!league && <meta property="og:description" content={description} />}
                {!isPlatform && !!league && <meta property="og:image:alt" content={league.name} />}
                {!isPlatform && !!league && <meta property="og:image" content={league.logoUrl} />}
            </Helmet>
            {children}
        </>
    );
};

export default HeaderTags;

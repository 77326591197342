import React, { useEffect, useRef, useState } from 'react';
import { isUndefined } from 'lodash';

import * as Core from '../../../../core';
import InfoMessage from '../../../../components/infoMessage';
import { Select } from '../../../../components/inputs';
import { MatchService } from '../../../../services/matchService';

interface AccountSelectorProps {
    matchId: string;
    matchParticipantUserIds?: string[];
    onAccountSelected: (account: Account) => void;
}

export interface Account {
    id: string;
    username: string;
}

const AccountSelector = ({ matchId, matchParticipantUserIds, onAccountSelected }: AccountSelectorProps) => {
    const [error, setError] = useState<string | undefined>(undefined);
    const [oAuthAccounts, setOAuthAccounts] = useState<Core.Models.MatchParticipantOAuthAccount[] | undefined>(
        undefined
    );

    const selector = useRef<HTMLSelectElement>(null);

    useEffect(() => {
        (async () => {
            setError(undefined);

            try {
                const data = (await MatchService.getMatchOAuthAccounts(matchId)).filter(
                    (account: Core.Models.MatchParticipantOAuthAccount) => {
                        if (!matchParticipantUserIds || matchParticipantUserIds.length === 0) return true;
                        return matchParticipantUserIds.includes(account.matchParticipantUserId);
                    }
                );

                setOAuthAccounts(data);

                // if there are accounts, select the first one
                if (data.length > 0) {
                    onAccountSelected({ id: data[0].providerUserId, username: data[0].providerUsername });
                    if (!!selector.current)
                        selector.current.value = `${data[0].providerUserId}/${data[0].providerUsername}`;
                }
            } catch (err) {
                setError('There was an issue loading accounts. Please reopen this modal to try again.');
            }
        })();
    }, [matchId]);

    return (
        <>
            <InfoMessage
                message={`This title supports automatic scoring by choosing a match from one of the players' match history. Select an account below to automatically score.`}
                type="info"
            />
            <Select
                errorMessage={error}
                id="accountId"
                label="Select an account"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const accountParts = e.target.value.split('/');
                    onAccountSelected({ id: accountParts[0], username: accountParts[1] });
                }}
                ref={selector}
            >
                {isUndefined(oAuthAccounts) && !error ? (
                    <option disabled value="">
                        Loading accounts...
                    </option>
                ) : (
                    !!oAuthAccounts &&
                    oAuthAccounts.map((account: Core.Models.MatchParticipantOAuthAccount) => (
                        <option
                            key={account.providerUserId}
                            value={`${account.providerUserId}/${account.providerUsername}`}
                        >
                            {account.teamName}: {account.providerUsername}
                        </option>
                    ))
                )}
                <option value="">Set score manually</option>
            </Select>
        </>
    );
};

export default AccountSelector;

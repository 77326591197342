import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orderBy } from 'lodash';

import * as Core from '../../core';
import { useConfirmModal } from '../../hooks/confirmModal';
import { useCanEditSeason } from '../../hooks/store';
import { StageService } from '../../services/stageService';
import { SolidButton } from '../buttons-visuals';
import ConfirmCompleteStage from '../confirmCompleteStage';
import GroupEliminationBracket from '../groupEliminationBracket';
import withLoading, { WithLoadingProps } from '../withLoading';

import './index.scss';

interface StageEliminationBracketProps extends WithLoadingProps {
    externalLinks: boolean;
    isMyParticipant?: (participant: { teamId?: string }) => boolean;
    matchWord: string;
    reloadStage: () => Promise<void>;
    stage: Core.Models.Stage;
}

const StageEliminationBracket = ({
    externalLinks,
    stage,
    isMyParticipant,
    reloadStage,
    setError,
    setIsLoading,
}: StageEliminationBracketProps) => {
    const [groups, setGroups] = useState<Core.Models.Group[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const response = await StageService.getGroupsWithMatches(stage.id);
                setGroups(response);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    const [completeStageNode, openCompleteStage] = useConfirmModal(
        () => `Complete Stage`,
        () => <ConfirmCompleteStage stage={stage} />,
        async (closeModal) => {
            await StageService.complete(stage.id);
            await reloadStage();
            closeModal();
        }
    );

    const canEditSeason = useCanEditSeason(stage.seasonId);
    // #1050: https://dev.azure.com/legacyesportsna/Platform/_workitems/edit/1050
    //  Add "Single Elimination" entry here.
    const isStageTypeElimination = stage.stageTypeId === Core.Models.StageTypeId.DoubleElimination;
    const showApproveStage =
        (isStageTypeElimination
            ? groups.every(
                  (g: Core.Models.Group) =>
                      g.matches &&
                      g.matches.every((m: Core.Models.Match) => m.currentState >= Core.Models.MatchState.IsComplete)
              )
            : stage.rounds.every((i) => i.currentState === Core.Models.CompetitionState.IsComplete)) &&
        stage.currentState === Core.Models.StageState.InProgress &&
        canEditSeason;

    const showGroupName = useMemo(() => groups.length > 1, [groups]);

    return (
        <div className="stage-elimination-bracket">
            <div className="stage-elimination-bracket__header">
                <h3 className="stage-elimination-bracket__header-text">Rankings - {stage.name}</h3>
                <div className="stage-elimination-bracket__header-buttons">
                    {showApproveStage && (
                        <SolidButton as="button" size="small" onClick={openCompleteStage}>
                            <FontAwesomeIcon icon={['fas', 'check']} className="mr" />
                            Complete Stage
                        </SolidButton>
                    )}
                </div>
            </div>
            {completeStageNode}
            <div className="stage-elimination-bracket__groups">
                {orderBy(groups, (g: Core.Models.Group) => g.sortOrder).map((group: Core.Models.Group) => {
                    return (
                        <div className="stage-elimination-bracket__group" key={group.id}>
                            <GroupEliminationBracket
                                {...{ stage, group, showGroupName, isMyParticipant, externalLinks }}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default withLoading(StageEliminationBracket);

import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { orderBy } from 'lodash';
import pluralize from 'pluralize';
import Select, { SelectOption } from 'react-select';
import * as Yup from 'yup';

import * as Core from '../../core';
import { SolidButton } from '../../components/buttons-visuals';
import InfoMessage from '../../components/infoMessage';
import LiteLimitationMessage from '../../components/liteLimitationMessage';
import Loading from '../../components/loading';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useAlternateSeasonName, useIsLite } from '../../hooks/store';
import { SeasonService } from '../../services/seasonService';

interface AddTeamToStageProps extends WithLoadingProps {
    onSubmit: (values: { groupId: string; teamId: string }) => Promise<void>;
    season: Core.Models.Season;
    stage: Core.Models.Stage;
}

interface AddTeamToStageValues {
    groupId: string;
    teamId: string;
}

const schema = Yup.object().shape({
    groupId: Yup.string().required('Group selection is required'),
    teamId: Yup.string().required('Team selection is required'),
});

const AddTeamToStage = (props: AddTeamToStageProps): JSX.Element => {
    const { onSubmit, season, setError, setIsLoading, stage } = props;
    const [eligibleTeams, setEligibleTeams] = useState<Core.Models.SeasonEligibleTeam[]>([]);
    const seasonAlternateName = useAlternateSeasonName();
    const matchWord = useMemo(
        () => Core.Competition.getMatchWord(season.game.eventType, { lowercase: true }),
        [season.game.eventType]
    );
    const isLite = useIsLite();
    const maxLiteParticipants = useMemo(
        () => Math.ceil(Core.Constants.PLANS.LITE.LIMITATIONS.MAX_PLAYERS_PER_SEASON / season.game.minimumSeats),
        [season.game.minimumSeats]
    );
    const numParticipants = useMemo(
        () => season.participants.filter((p: Core.Models.Participant) => p.isParticipating).length,
        [season.participants]
    );
    const isLiteInstanceFull = useMemo(
        () => isLite && numParticipants >= maxLiteParticipants,
        [isLite, maxLiteParticipants, numParticipants]
    );

    useEffect(() => {
        (async () => {
            try {
                const response = await SeasonService.getSeasonEligibleTeams(season.id);
                const filteredResponse = response
                    .filter((t: Core.Models.SeasonEligibleTeam) => !t.isParticipatingInThisSeason)
                    .filter((t: Core.Models.SeasonEligibleTeam) => !t.isParticipatingInAnotherSeason);
                setEligibleTeams(filteredResponse);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [season.id, setError, setIsLoading]);

    const groupOptions: SelectOption[] = useMemo(
        () =>
            orderBy(stage.groups, (group: Core.Models.Group) => group.sortOrder).map((group: Core.Models.Group) => ({
                label: group.name,
                value: group.id,
            })),
        [stage.groups]
    );

    const teamOptions: SelectOption[] = useMemo(
        () =>
            orderBy(eligibleTeams, (team: Core.Models.SeasonEligibleTeam) => team.name).map(
                (team: Core.Models.SeasonEligibleTeam) => ({
                    label: team.name,
                    value: team.teamId,
                })
            ),
        [eligibleTeams]
    );

    if (isLiteInstanceFull) {
        return (
            <LiteLimitationMessage
                message={`There are already ${numParticipants} teams in this ${seasonAlternateName.toLowerCase()}. ${
                    Core.Constants.PLANS.LITE.NAME
                } only allows a maximum of ${
                    Core.Constants.PLANS.LITE.LIMITATIONS.MAX_PLAYERS_PER_SEASON
                } players per ${seasonAlternateName} (${maxLiteParticipants} teams for ${season.game.name}).`}
            />
        );
    }

    return (
        <Formik
            initialValues={Object.assign({
                /** anything not specified here won't show an error message after an attempted submit */
                groupId: stage.groups.length === 1 ? stage.groups[0].id : '',
                teamId: '',
            } as Partial<AddTeamToStageValues>)}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
                actions.setStatus(undefined);
                try {
                    await onSubmit({ ...values });
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(formProps: FormikProps<AddTeamToStageValues>) => {
                const groups = groupOptions.filter((group: SelectOption) => formProps.values.groupId !== group.value);
                const teams = teamOptions.filter((team: SelectOption) => formProps.values.teamId !== team.value);
                return (
                    <Form className="form">
                        <fieldset className="form-group mb2x">
                            <Select
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(selectedOption: SelectOption | null) => {
                                    if (!!selectedOption) formProps.setFieldValue('teamId', selectedOption.value);
                                }}
                                options={teams}
                                name="teamId"
                                placeholder="Select team"
                                styles={{ menu: (provided: any) => ({ ...provided, zIndex: 2 }) }}
                            />
                        </fieldset>
                        {stage.groups.length !== 1 && (
                            <fieldset className="form-group">
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(selectedOption: SelectOption | null) => {
                                        if (!!selectedOption) formProps.setFieldValue('groupId', selectedOption.value);
                                    }}
                                    options={groups}
                                    name="groupId"
                                    placeholder="Select group"
                                    styles={{ menu: (provided: any) => ({ ...provided, zIndex: 2 }) }}
                                />
                            </fieldset>
                        )}
                        <p className="add-team__info">
                            <span>This will add the selected team to this in-progress stage.</span>{' '}
                            {stage.stageTypeId === Core.Models.StageTypeId.Leaderboard ? (
                                <span>
                                    This team will be added to {stage.settings.matchesPerRound} existing, unstarted{' '}
                                    {pluralize(matchWord, stage.settings.matchesPerRound)} per round.
                                </span>
                            ) : stage.stageTypeId === Core.Models.StageTypeId.LeaderboardAutoMatcher ? (
                                <span>Beginning next round, they will be able to check-in to each round.</span>
                            ) : (
                                <span>
                                    Either progress to the next round to schedule their first {matchWord}, or add a{' '}
                                    {matchWord} to this round manually for them.
                                </span>
                            )}
                        </p>
                        {stage.stageTypeId === Core.Models.StageTypeId.Swiss && (
                            <InfoMessage
                                message="Adding a team to a Swiss system stage in progress may affect the competitive integrity of the bracket."
                                type="info"
                            />
                        )}
                        {!!season.payable && (
                            <InfoMessage
                                message={`This ${seasonAlternateName.toLowerCase()} has an entry fee. Adding a team here will override payment for this team and assume that a transaction has occurred outside of the system.`}
                                type="info"
                            />
                        )}
                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                        <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                        <fieldset className="form-group form-group--undecorated">
                            {formProps.isSubmitting && <Loading buttonLoader />}
                            <SolidButton
                                as="button"
                                disabled={formProps.isSubmitting}
                                layout="full"
                                onClick={formProps.submitForm}
                                size="medium"
                            >
                                Add Team
                            </SolidButton>
                        </fieldset>
                    </Form>
                );
            }}
        />
    );
};

export default withLoading(AddTeamToStage, {
    loadingProps: { blockItem: true },
});

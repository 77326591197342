import React, { useState } from 'react';
import classNames from 'classnames';

import * as Core from '../../core';
import { HollowButton, SolidButton } from '../buttons-visuals';
import InfoMessage from '../infoMessage';
import Modal, { ModalProps } from '../modal';

import './index.scss';

export interface ConfirmModalProps extends Pick<ModalProps, Exclude<keyof ModalProps, 'onClose'>> {
    cancelText?: string;
    confirmText?: string;
    onCancel: () => void;
    onConfirm: () => Promise<any>;
    type?: 'destructive' | 'additive';
}

const ConfirmModal = ({
    cancelText,
    children,
    className,
    confirmText,
    onConfirm,
    onCancel,
    type = 'destructive',
    ...rest
}: ConfirmModalProps) => {
    const [isConfirming, setIsConfirming] = useState(false);
    const [confirmError, setConfirmError] = useState<string | undefined>(undefined);

    const confirm = async () => {
        try {
            setIsConfirming(true);
            await onConfirm();
        } catch (error) {
            setConfirmError(Core.API.getErrorMessage(error));
        } finally {
            setIsConfirming(false);
        }
    };

    return (
        <Modal className={classNames(className, 'confirm-modal-container')} onClose={onCancel} {...rest}>
            {children}
            {confirmError && <InfoMessage message={confirmError} type="error" />}
            <div className="grid-columns grid-columns--2 grid-columns--for-large">
                <SolidButton
                    as="button"
                    color={'destructive' === type ? 'destructive' : 'primary'}
                    layout="full"
                    onClick={confirm}
                    pending={isConfirming}
                    size="large"
                >
                    {confirmText || 'Confirm'}
                </SolidButton>
                <HollowButton
                    as="button"
                    color="secondary"
                    disabled={isConfirming}
                    layout="full"
                    onClick={onCancel}
                    size="large"
                >
                    {cancelText || 'Cancel'}
                </HollowButton>
            </div>
        </Modal>
    );
};

export default ConfirmModal;

import React from 'react';
import classNames from 'classnames';
import { fromPairs, orderBy, toPairs } from 'lodash';

import './index.scss';

interface InfoMessageProps {
    className?: string;
    // if supplied and error is an object, a value from error will only be used if there is a truthy value for the same key in filter
    // this is very useful with Formik, passing `error={props.error} filter={props.touched}` to only show errors for touched fields
    filter?: any;
    // a string error message, or an object of errors, like what formik provides as `props.error`.
    layoutInline?: boolean;
    message: React.ReactNode | string | any;
    // if supplied, properties identified here will be first - note, this does *not* use array syntax, ie. pass `products.0.id`, not `products[0].id`.
    order?: string[];
    type: 'error' | 'info' | 'warning' | 'success';
}

// like _.toPairs, but recurses, giving a flat list of [ key, value ].  Ie. `{ a: { b: [ 'c' ]}}` will return `[['a.b.0', 'c' ]]`.
// it will recurse through everything but strings, bools, and react elements.  Ie. this is designed for use with `props.error` and `props.touched` from formik
const flattenToDeepPairs = (value: any): [string, any][] => {
    if (typeof value === 'string' || value === true || value === false || React.isValidElement(value)) {
        return [['', value]];
    }
    return (toPairs(value) as [string, any]).flatMap((i) =>
        flattenToDeepPairs(i[1]).map((ii: any) => [i[0] + (ii[0] ? '.' + ii[0] : ''), ii[1]])
    ) as [string, any][];
};

const InfoMessage = (props: InfoMessageProps): JSX.Element => {
    const { className, filter, layoutInline, message, order, type } = props;

    // build the flattened list of errors to consider showing
    let flatError = flattenToDeepPairs(message);

    // if we have a filter, flatten it too and use it to filter the errors
    if (filter) {
        const flatFilter = flattenToDeepPairs(filter);
        const flatFilterIndex = fromPairs(flatFilter);
        flatError = flatError.filter((i) => !!flatFilterIndex[i[0]]);
    }

    // if we have a sort order specified, use it to sort the list
    if (order) {
        // flip keys/values so we can look up the error key and get the sort order
        const orderIndex = fromPairs(toPairs(order).map((i) => [i[1], i[0]]));
        // order the errors by the values in the 'order' array
        flatError = orderBy(flatError, [(i) => (orderIndex[i[0]] === undefined ? 999999 : orderIndex[i[0]])]);
    }

    // the list is filtered and sorted, so we don't need the keys anymore - just keep the values and render them in-order
    const messages = flatError.map((i) => i[1]);
    if (!messages.length) return <></>;

    return (
        <div className={classNames(className, 'info-message', type, { 'fit-content': layoutInline })}>
            {messages.map((error, ix) => (
                <div className="info-message__item" key={ix}>
                    {error}
                </div>
            ))}
        </div>
    );
};

export default InfoMessage;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { orderBy } from 'lodash';
import { RouteComponentProps } from 'react-router';

import * as Core from '../../core';
import TeamDetails from './details';
import TeamMatchSchedule from './matchSchedule';
import { ContentContainer } from '../../components/containers';
import FulfillmentsList from '../../components/payments/fulfillmentsList';
import TeamMembers from '../../components/teamMembers';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useUserPermissionService } from '../../hooks/store';
import { TeamService } from '../../services/teamService';

import './index.scss';

interface TeamPageRouteProps {
    id: string;
}

interface TeamPageProps extends RouteComponentProps<TeamPageRouteProps>, WithLoadingProps {}

const TeamPage = ({
    match: {
        params: { id },
    },
    setError,
    setIsLoading,
}: TeamPageProps) => {
    const [matches, setMatches] = useState<Core.Models.MatchHistory[]>([]);
    const [team, setTeam] = useState<Core.Models.Team | undefined>(undefined);
    const userPermissionService = useUserPermissionService();
    const canEdit = userPermissionService.hasTeamAccess(Core.Models.PermissionLevel.Edit, team);

    const load = useCallback(async () => {
        try {
            const [teamResponse, matchesResponse] = await Promise.all([
                TeamService.getById(id),
                TeamService.getMatches(id),
            ]);
            setMatches(matchesResponse);
            setTeam(teamResponse);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [setError, setIsLoading]);

    useEffect(() => {
        (async () => {
            await load();
        })();
    }, [load]);

    const upcomingMatches = useMemo(
        () =>
            orderBy(
                matches.filter(
                    (m: Core.Models.MatchHistory) => m.match.currentState < Core.Models.MatchState.InProgress
                ),
                (m: Core.Models.MatchHistory) => m.match.startTimeUtc,
                'asc'
            ),
        [matches]
    );
    const previousMatches = useMemo(
        () =>
            orderBy(
                matches.filter(
                    (m: Core.Models.MatchHistory) => m.match.currentState >= Core.Models.MatchState.InProgress
                ),
                (m: Core.Models.MatchHistory) => m.match.startTimeUtc,
                'desc'
            ),
        [matches]
    );

    if (!team) return <></>;
    return (
        <div className="global-container pt4x pb4x">
            <TeamDetails team={team} reloadData={load} />
            <ContentContainer className="mb4x" shade={Core.Models.Shades.Dark40}>
                <h4 className="heading-2">Team Members</h4>
                <TeamMembers team={team} canAdd={canEdit} canEdit={canEdit} />
            </ContentContainer>
            <TeamMatchSchedule headerText="Upcoming Events" matches={upcomingMatches} team={team} />
            <TeamMatchSchedule headerText="Event History" matches={previousMatches} team={team} />
            {canEdit && (
                <ContentContainer className="mb4x" shade={Core.Models.Shades.Dark40}>
                    <FulfillmentsList
                        fulfillments={team.fulfillments}
                        reloadData={load}
                        leagueDesignationId={team.designationId}
                        teamId={team.id}
                    />
                </ContentContainer>
            )}
        </div>
    );
};

export default withLoading(TeamPage, {
    errorDisplayPageProps: { fullPage: true },
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

import React, { useCallback, useEffect, useState } from 'react';
import { uniqBy } from 'lodash';

import * as Core from '../../core';
import Comment from './comment';
import useApiPagination from '../../hooks/apiPagination';
import { PostService } from '../../services/postService';
import { HollowButton } from '../buttons-visuals';
import Loading from '../loading';
import withLoading, { WithLoadingProps } from '../withLoading';

interface CommentsProps extends WithLoadingProps {
    comments: Core.Models.Comment[];
    numberOfComments: number;
    postId: string;
    setComments: (comments: Core.Models.Comment[]) => void;
    setNumberOfComments: (numberOfOComments: number) => void;
}

const Comments = (props: CommentsProps) => {
    const { comments, numberOfComments, postId, setComments, setError, setIsLoading, setNumberOfComments } = props;
    const [isLoadingComments, setIsLoadingComments] = useState<boolean>(false);

    const getPaginatedCommentsAsync = useCallback(
        (page: number, pageSize: number, search: string): Promise<Core.Models.PaginatedResult<Core.Models.Comment>> => {
            return PostService.getComments(postId, {
                page,
                pageSize,
                search,
            });
        },
        [postId]
    );

    // todo: consider using new useApiIncrementalLoader (if so, may be able to remove new showLoading param from methods inside useApiPagination)
    const { incrementPage, paginatedResults, selectPage } = useApiPagination(
        getPaginatedCommentsAsync,
        setError,
        setIsLoading,
        Core.Constants.PAGINATION.POST_COMMENTS_PER_PAGE
    );

    useEffect(() => {
        if (!!paginatedResults?.results)
            setComments(uniqBy([...comments, ...paginatedResults.results], (c: Core.Models.Comment) => c.id));
    }, [paginatedResults]);

    if (!paginatedResults || comments.length <= 0) return <></>;
    return (
        <>
            {comments.map((comment: Core.Models.Comment) => (
                <Comment
                    comment={comment}
                    key={comment.id}
                    onDelete={(id: string) => {
                        setComments(comments.filter((c: Core.Models.Comment) => c.id !== id));
                        setNumberOfComments(numberOfComments - 1);

                        if (comments.length == 1) {
                            // if deleting the last visible comment, reload
                            selectPage(1);
                        }
                    }}
                />
            ))}
            {isLoadingComments ? (
                <Loading blockItem />
            ) : (
                numberOfComments > comments.length && (
                    <HollowButton
                        as="button"
                        onClick={async () => {
                            setIsLoadingComments(true);
                            await incrementPage(1, false);
                            setIsLoadingComments(false);
                        }}
                        size="medium"
                    >
                        View more
                    </HollowButton>
                )
            )}
        </>
    );
};

export default withLoading(Comments, {
    loadingProps: { blockItem: true },
});

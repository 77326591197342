import * as React from 'react';
import classNames from 'classnames';

import * as Core from '../../core';
import EsrbRating from '../../components/esrbRating';
import Menu from '../menu';

import './index.scss';

interface GameCardProps {
    esrbRating?: Core.Models.EsrbRating;
    gameName: string;
    imageUrl?: string;
    isSelected?: boolean;
    isSelecting?: boolean;
    menuOptions?: React.ReactNode;
    size: 'small' | 'medium';
}

const GameCard = ({
    esrbRating = Core.Models.EsrbRating.None,
    gameName,
    imageUrl,
    isSelected,
    isSelecting,
    menuOptions,
    size,
}: GameCardProps) => {
    return (
        <div
            className={classNames(`game-card ${size}`, {
                selecting: isSelecting,
                selected: isSelected,
            })}
        >
            {!!menuOptions && <Menu className="game-card__menu">{menuOptions}</Menu>}
            <div className="game-card__image">
                <img src={imageUrl} alt={gameName} />
                {esrbRating !== Core.Models.EsrbRating.None && (
                    <div className="game-card__image__esrb-rating">
                        <EsrbRating rating={esrbRating ?? Core.Models.EsrbRating.None} />
                    </div>
                )}
            </div>
            <div className="game-card__body">
                <div className="game-card__game-name">{gameName}</div>
            </div>
        </div>
    );
};

export default GameCard;

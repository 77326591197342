import * as React from 'react';
import { useSelector } from 'react-redux';

import * as Core from '../../core';
import { StageTypeHandlerService } from '../../services/stageTypeHandlerService';
import { getIsMyParticipant } from '../../store/selectors/myParticipant';

interface StageStandingsProps {
    externalLinks: boolean;
    matchWord: string;
    reloadStage: () => Promise<void>;
    stage: Core.Models.Stage;
}

export const StageStandings: React.FunctionComponent<StageStandingsProps> = (props: StageStandingsProps) => {
    const isMyParticipant = useSelector(getIsMyParticipant);
    const handler = StageTypeHandlerService.getHandlers(props.stage.stageTypeId);
    return (
        <div id="rankings" className="standings">
            {React.createElement(handler.standingsDisplayComponent, {
                ...props,
                isMyParticipant,
            })}
        </div>
    );
};

import React from 'react';
import classNames from 'classnames';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import * as Core from '../core';
import { IHasChildren } from './types';
import AcceptCookies from '../components/acceptCookies';
import BirthdateReminder from '../components/birthdateReminder';
import DiscordActivator from '../components/discordActivator';
import Footer from '../components/footer';
import Header from '../components/header';
import NextMatch from '../components/nextMatch';
import VerifyEmailReminder from '../components/verifyEmailReminder';
import { useLeague } from '../hooks/store';
import { AuthenticationService } from '../services/authenticationService';

import './mainLayout.scss';

interface MainLayoutProps extends IHasChildren, RouteComponentProps<void> {}

const MainLayout = (props: MainLayoutProps): JSX.Element => {
    const { children, location: pathname } = props;
    const league = useLeague();
    const isCustomLanding =
        !!league?.landingPageFilePath &&
        (pathname.pathname === '/home' || (pathname.pathname === '/' && !AuthenticationService.isAuthenticated()));

    return (
        <>
            <div id="portal" />
            <div className="app-container">
                <VerifyEmailReminder />
                <BirthdateReminder />
                <DiscordActivator />
                <NextMatch />
                <div className="app">
                    {Core.Configuration.enableBuildNumberInUI && (
                        <div className="buildVersion">{Core.Configuration.version}</div>
                    )}
                    <Header />
                    <main
                        className={classNames('main-content', {
                            'main-content--custom-landing': isCustomLanding,
                        })}
                    >
                        {children}
                    </main>
                    <Footer className="main-content__footer" />
                </div>
                <AcceptCookies />
            </div>
        </>
    );
};

export default withRouter(MainLayout);

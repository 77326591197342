import * as Core from '../core';

export abstract class ParticipantService {
    public static async setParticipantIsParticipating(
        command: Core.Models.ParticipantIsParticipatingCommand
    ): Promise<void> {
        const route = Core.API.ServerRoute.forAction('participants', 'isParticipating');
        return await Core.API.post(route, command);
    }

    public static async submitHearthstoneDecks(command: Core.Models.SubmitHearthstoneDecksRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('participants', 'hearthstoneDecks');
        await Core.API.post(route, command);
    }

    public static async deleteHearthstoneDecks(participantId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('participants', `hearthstoneDecks/${participantId}`);
        await Core.API.delete(route);
    }
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { differenceBy, orderBy } from 'lodash';
import pluralize from 'pluralize';

import * as Core from '../../core';
import AddGame from './addGame';
import { AddButton } from '../../components/buttons-visuals';
import GameCard from '../../components/gameCard';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useConfirmModal } from '../../hooks/confirmModal';
import { useModal } from '../../hooks/modal';
import { useAlternateSeasonName, useLeague } from '../../hooks/store';
import { GameService } from '../../services/gameService';
import { LeagueService } from '../../services/leagueService';

interface ApprovedGamesProps extends WithLoadingProps {}

const ApprovedGames = (props: ApprovedGamesProps) => {
    const { setError, setIsLoading } = props;
    const [games, setGames] = useState<Core.Models.Game[]>([]);
    const [leagueGames, setLeagueGames] = useState<Core.Models.Game[]>([]);
    const [gameBeingDeleted, setGameBeingDeleted] = useState<Core.Models.Game | undefined>();
    const league = useLeague();
    const seasonAlternateName = useAlternateSeasonName({ lowercase: true });
    const netGames = useMemo(() => differenceBy(games, leagueGames, 'id'), [games, leagueGames]);

    const load = useCallback(async () => {
        try {
            const [gamesResponse, leagueGamesResponse] = await Promise.all([
                GameService.getAll(),
                LeagueService.getLeagueApprovedGames(),
            ]);
            setGames(gamesResponse);
            setLeagueGames(leagueGamesResponse);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [setError, setIsLoading]);

    useEffect(() => {
        (async () => load())();
    }, [load]);

    // adding game
    const [addGameModal, openAddGame, closeAddGame] = useModal(
        () => 'Add Game',
        () => (
            <AddGame
                games={netGames}
                onComplete={async () => {
                    await load();
                    closeAddGame();
                }}
            />
        ),
        { className: 'league-settings-page__add-games-modal' }
    );

    const [deleteLeagueGameModal, openDeleteLeagueGameModal, closeDeleteLeagueGameModal] = useConfirmModal(
        () => 'Remove Game from League',
        () => (
            <>
                <p className="mb2x">
                    Are you sure you want to remove <strong>{gameBeingDeleted!.name}</strong> from {league!.name}? This
                    game will no longer show up when creating teams, {pluralize(seasonAlternateName)}, or in the game
                    interests section on user profiles.
                </p>
                <p>
                    Existing teams and {pluralize(seasonAlternateName)} associated with this game will not be deleted.
                </p>
            </>
        ),
        async () => {
            await LeagueService.deleteLeagueApprovedGame(gameBeingDeleted!.id);
            await load();
            closeDeleteLeagueGameModal();
            setGameBeingDeleted(undefined);
        }
    );

    return (
        <section className="global-container">
            <AddButton
                as="button"
                buttonSize="large"
                buttonStyle="solid"
                className="mb2x m-auto"
                layoutInline
                onClick={openAddGame}
            >
                Add Game
            </AddButton>

            <div className="settings-page__games-container">
                {leagueGames.length > 0 ? (
                    <>
                        {orderBy(leagueGames, (game: Core.Models.Game) => game.name).map((game: Core.Models.Game) => {
                            return (
                                <GameCard
                                    esrbRating={game.esrbRating}
                                    gameName={game.name}
                                    imageUrl={game.cardUrl}
                                    key={game.id}
                                    menuOptions={
                                        <button
                                            onClick={() => {
                                                setGameBeingDeleted(game);
                                                openDeleteLeagueGameModal();
                                            }}
                                        >
                                            Delete
                                        </button>
                                    }
                                    size="medium"
                                />
                            );
                        })}
                        <AddButton
                            as="button"
                            buttonSize="large"
                            buttonStyle="hollow"
                            className="settings-page__games-container__add-button"
                            layoutInline
                            onClick={openAddGame}
                        >
                            Add Game
                        </AddButton>
                    </>
                ) : (
                    <p>No games found for this league. Please add one.</p>
                )}
            </div>
            {addGameModal}
            {deleteLeagueGameModal}
        </section>
    );
};

export default withLoading(ApprovedGames, { loadingProps: { blockItem: true } });

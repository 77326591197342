import * as Core from '../core';

export abstract class RoundService {
    public static async getMatches(roundId: string): Promise<Core.Models.MatchesResponse> {
        const route = Core.API.ServerRoute.forAction('rounds', `${roundId}/matches`);
        const [matches, responseDateUtc] = await Core.API.getWithResponseDate<Core.Models.Match[]>(route);
        return { matches, responseDateUtc, receivedDateUtc: new Date() };
    }

    public static async edit(command: Core.Models.EditRoundCommand): Promise<Core.Models.Round> {
        const route = Core.API.ServerRoute.forAction('rounds');
        return await Core.API.patch(route, command);
    }

    public static async start(command: Core.Models.StartRoundCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('rounds', 'start');
        return await Core.API.post(route, command);
    }

    public static async complete(id: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('rounds', `${id}/complete`);
        return await Core.API.post(route, {});
    }

    public static async create(command: Core.Models.CreateRoundCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('rounds');
        return await Core.API.post(route, command);
    }

    public static async join(command: Core.Models.JoinRoundCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`rounds/${command.roundId}/join`);
        return await Core.API.post(route, command);
    }

    public static async deleteRoundGroupParticipant(roundGroupParticipantId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`rounds/roundGroupParticipants/${roundGroupParticipantId}`);
        return await Core.API.delete(route);
    }

    public static async getMatchStreamStatusByRound(
        roundId: string
    ): Promise<{ [key: string]: Core.Models.StreamStatus }> {
        const route = Core.API.ServerRoute.forAction(`rounds/${roundId}/match-stream-status`);
        return await Core.API.getAnonymous(route);
    }
}

import React, { ChangeEvent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TitleScoringProps } from '.';
import InfoMessage from '../../../../components/infoMessage';
import { TextInput } from '../../../../components/inputs';

const ApexLegendsScoring = ({ setAutoScoringData }: TitleScoringProps): JSX.Element => {
    const [autoScoringCode, setAutoScoringCode] = useState<string>('');
    const [matchIndex, setMatchIndex] = useState<number>(1);

    useEffect(() => {
        setAutoScoringData(!!autoScoringCode ? JSON.stringify({ autoScoringCode, matchIndex }) : '');
    }, [autoScoringCode, matchIndex, setAutoScoringData]);

    return (
        <>
            <fieldset className="form-group auto-scoring-data">
                <TextInput
                    id="autoScoringCode"
                    label="Private lobby stats token (optional)"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setAutoScoringCode(e.target.value)}
                    type="text"
                    value={autoScoringCode}
                />
                {!!autoScoringCode && (
                    <TextInput
                        label="Match no."
                        id="matchIndex"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setMatchIndex(+e.target.value)}
                        placeholder="Match number"
                        type="number"
                        value={matchIndex}
                    />
                )}
            </fieldset>
            {!autoScoringCode && (
                <InfoMessage
                    message={
                        <span>
                            <FontAwesomeIcon icon={['fas', 'lock']} size="1x" className="mr" />
                            Automatic scoring using stats tokens is only available if you have access to EA private
                            servers
                        </span>
                    }
                    type="info"
                />
            )}
            {!!autoScoringCode && (
                <InfoMessage
                    message="Stats tokens can contain multiple matches, so also make sure to set a match number. This is a numeric value, starting at one (1), that signifies which usage of this token that should be evaluated"
                    type="info"
                />
            )}
        </>
    );
};

export default ApexLegendsScoring;

import React from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../core';
import InfoMessage from '../../components/infoMessage';
import { useProfile } from '../../hooks/store';
import { PostService } from '../../services/postService';
import { Avatar, SolidButton } from '../buttons-visuals';
import FormField from '../formField';

import './index.scss';

interface CreateCommentProps {
    onComplete: (comment: Core.Models.Comment) => void;
    postId: string;
}

export interface CreateCommentValues {
    content: string;
    postId: string;
}

const schema = Yup.object().shape({
    content: Yup.string()
        .max(
            Core.Constants.SOCIAL_POST_MAX_LENGTH,
            `Comment length is too long. (${Core.Constants.SOCIAL_POST_MAX_LENGTH} character maximum)`
        )
        .required(),
    postId: Yup.string().required(),
});

const CreateComment = (props: CreateCommentProps): JSX.Element => {
    const { onComplete, postId } = props;
    const profile = useProfile();

    if (!profile) return <></>;
    return (
        <Formik
            initialValues={Object.assign({
                content: '',
                postId,
            } as Partial<CreateCommentValues>)}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
                actions.setStatus(undefined);
                try {
                    const comment = await PostService.createComment(values);
                    onComplete(comment);
                    actions.resetForm();
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(formProps: FormikProps<CreateCommentValues>) => {
                return (
                    <Form className="form">
                        <input type="hidden" name="postId" />
                        <div className="disp-flex full-width">
                            <Avatar
                                fallback="user"
                                isUnder13={profile.isUnder13}
                                size="small"
                                src={profile.avatarUrl}
                            />
                            <FormField
                                aria-describedby={
                                    formProps.values.content.length > 0
                                        ? `${formProps.values.content.length} / ${Core.Constants.SOCIAL_POST_MAX_LENGTH}`
                                        : undefined
                                }
                                className="flex-grow"
                                component="textarea"
                                label="Add a comment"
                                name="content"
                                rows={1}
                            />
                        </div>

                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                        {formProps.values.content.length > 0 && (
                            <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />
                        )}

                        {formProps.values.content.length > 0 && (
                            <SolidButton
                                as="button"
                                onClick={formProps.submitForm}
                                pending={formProps.isSubmitting}
                                size="medium"
                            >
                                Post
                            </SolidButton>
                        )}
                    </Form>
                );
            }}
        />
    );
};

export default CreateComment;

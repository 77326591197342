import * as React from 'react';

import * as Core from '../../core';
import CustomRegistrationFormField from './customRegistrationFormField';

interface CustomRegistrationProps {
    customRegistrationFields?: Core.Models.CustomRegistrationFieldSubmissionResponse[];
}

const CustomRegistrationFormFields = (props?: CustomRegistrationProps) => {
    if (!props || !props.customRegistrationFields) return null;

    const { customRegistrationFields } = props;
    return (
        <>
            {customRegistrationFields.map((x: Core.Models.CustomRegistrationFieldSubmissionResponse, idx: number) => (
                <CustomRegistrationFormField
                    key={idx}
                    fieldArrayName="customRegistrationFields"
                    fieldSubmissionResponse={x}
                    index={idx}
                />
            ))}
        </>
    );
};

export default CustomRegistrationFormFields;

import React, { useState } from 'react';
import { orderBy } from 'lodash';
import { Link } from 'react-router-dom';

import * as Core from '../../../core';
import UpdateChatAbility from './updateChatAbility';
import Menu from '../../../components/menu';
import Modal from '../../../components/modal';

import './index.scss';

interface ThreadParticipantListProps {
    canEdit: boolean;
    participants: Core.Models.ChatThreadParticipant[];
    seasonId?: string;
}

const ThreadParticipantList = (props: ThreadParticipantListProps): JSX.Element => {
    const { canEdit, participants, seasonId } = props;
    const [blockingFromChat, setBlockingFromChat] = useState<Core.Models.ChatThreadParticipant | undefined>(undefined);

    return (
        <div className="thread-participant-list">
            <div className="thread-participant-list__header">Participants</div>
            {participants.length === 0 ? (
                <div className="thread-participant-list__empty">No participants to display</div>
            ) : (
                orderBy(participants, ({ isProgrammatic, name }: Core.Models.ChatThreadParticipant) => [
                    isProgrammatic,
                    name,
                ]).map((participant: Core.Models.ChatThreadParticipant) => (
                    <div className="thread-participant-list__participant" key={participant.userId}>
                        {participant.isProgrammatic ? (
                            participant.name
                        ) : (
                            <>
                                <Link to={`/users/${participant.userId}`}>{participant.name}</Link>
                                {canEdit && (
                                    <Menu>
                                        <button onClick={() => setBlockingFromChat(participant)}>
                                            Update Chat Ability
                                        </button>
                                    </Menu>
                                )}
                            </>
                        )}
                    </div>
                ))
            )}
            {!!blockingFromChat && (
                <Modal onClose={() => setBlockingFromChat(undefined)} title="Update user chat ability">
                    <UpdateChatAbility
                        onClose={() => setBlockingFromChat(undefined)}
                        participant={blockingFromChat}
                        seasonId={seasonId}
                    />
                </Modal>
            )}
        </div>
    );
};

export default ThreadParticipantList;

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { orderBy } from 'lodash';

import * as Core from '../../core';
import { usePromiseOperation } from '../../hooks/promiseOperation';
import { StageService } from '../../services/stageService';
import { Button } from '../button';
import DraggableList from '../draggableList';
import ErrorMessage from '../errorMessage';
import Loading from '../loading';

import './resolveTie.scss';

interface ResolveTieProps {
    onClose: () => void;
    reloadData: () => Promise<void>;
    stageId: string;
    tiedGroupParticipants: Core.Models.GroupParticipant[];
}

function getSeasonParticipantIds(groupParticipants: Core.Models.GroupParticipant[]) {
    return orderBy(groupParticipants, [
        (gp: Core.Models.GroupParticipant) => gp.outputRank,
        (gp: Core.Models.GroupParticipant) => -(gp.winsByForfeit + gp.winsByPlay),
        (gp: Core.Models.GroupParticipant) => gp.lossesByForfeit + gp.lossesByPlay,
        (gp: Core.Models.GroupParticipant) => gp.inputRank,
    ]).map((gp: Core.Models.GroupParticipant) => gp.id);
}

const ResolveTie = (props: ResolveTieProps) => {
    const { onClose, reloadData, stageId, tiedGroupParticipants } = props;

    const [ids, setIds] = useState(getSeasonParticipantIds(props.tiedGroupParticipants));
    useEffect(() => {
        setIds(getSeasonParticipantIds(props.tiedGroupParticipants));
    }, [props.tiedGroupParticipants, setIds]);

    const [isSubmitting, submit, error] = usePromiseOperation(async () => {
        await StageService.manuallyBreakTies({
            stageId: stageId,
            groupParticipantIds: ids,
        });
        await reloadData();
        onClose();
    });

    if (tiedGroupParticipants.length === 0) {
        return <ErrorMessage error={'Cannot resolve tie between 0 participants'} />;
    }

    const tiedPlace = tiedGroupParticipants[0].outputRank;

    return (
        <div className="resolve-tie">
            <p className="resolve-tie__place">{Core.Number.ordinal(tiedPlace)} place</p>
            <p className="resolve-tie__instructions">Click to drag and re-arrange order.</p>

            <div className="resolve-tie__participants">
                <DraggableList
                    className="resolve-tie__draggable-list"
                    items={ids}
                    setItems={setIds}
                    getId={(id: string) => id}
                    disabled={isSubmitting}
                    renderItem={(id: string, index: number) => {
                        const p = tiedGroupParticipants.find((gp: Core.Models.GroupParticipant) => gp.id === id)!;
                        return (
                            <div
                                className={classNames('resolve-tie__participant', {
                                    'resolve-tie__participant--even': index % 2 === 1,
                                    'resolve-tie__participant--odd': index % 2 === 0,
                                })}
                            >
                                <div className="resolve-tie__participant__handle" aria-hidden="true" />
                                <span className="resolve-tie__participant-rank">{index + tiedPlace}.</span>
                                <span className="resolve-tie__participant-name">{p.name}</span>
                            </div>
                        );
                    }}
                />
            </div>

            {error && <ErrorMessage error={error} />}

            {isSubmitting && <Loading buttonLoader />}

            <div className="resolve-tie__buttons">
                <Button disabled={isSubmitting} onClick={submit}>
                    Save Results
                </Button>
                <Button outline disabled={isSubmitting} onClick={onClose}>
                    Go Back
                </Button>
            </div>
        </div>
    );
};

export default ResolveTie;

import React, { useState } from 'react';
import { toast } from 'react-toastify';

import * as Core from '../../../../core';
import { Button } from '../../../../components/button';
import InfoMessage from '../../../../components/infoMessage';
import { CopyTextField } from '../../../../components/inputs';
import Loading from '../../../../components/loading';
import { UserService } from '../../../../services/userService';

interface GeneratePasswordResetProps {
    userId: string;
}

const GeneratePasswordReset = (props: GeneratePasswordResetProps): JSX.Element => {
    const { userId } = props;
    const [error, setError] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [passwordResetLink, setPasswordResetLink] = useState<string | undefined>(undefined);

    const generatePasswordResetLink = async (): Promise<void> => {
        setError(undefined);
        setIsLoading(true);

        try {
            const response = await UserService.generatePasswordResetLink(userId);
            setPasswordResetLink(response);
            toast.success('Generated password reset link successfully');
        } catch (err) {
            const message = Core.API.getErrorMessage(err);
            setError(message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="generate-password-reset">
            <fieldset className="form-group">
                <CopyTextField id="passwordResetLink" label="Password Reset Link" value={passwordResetLink} />
            </fieldset>
            <InfoMessage
                message={
                    "Sharing this link is a major security loophole, as it allows anyone with the link to set a password and access this user's account."
                }
                type="info"
            />

            {!!error && <InfoMessage message={error} type="error" />}
            {isLoading && <Loading buttonLoader />}
            <Button onClick={generatePasswordResetLink} disabled={isLoading} wide round outline>
                Generate reset link
            </Button>
        </div>
    );
};

export default GeneratePasswordReset;

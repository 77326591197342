import React, { useMemo } from 'react';
import { map } from 'lodash';

import { CopyTextField } from '../../components/inputs';
import { useLeagueConfiguration, useOrganizationTerm } from '../../hooks/store';

const OrganizationInviteUrl = () => {
    const organizationTerm = useOrganizationTerm();
    const leagueConfiguration = useLeagueConfiguration();

    const createOrganizationUrls = useMemo(() => {
        if (!leagueConfiguration) return [];
        return map(leagueConfiguration.inviteCodes, (code: string) => {
            return `${leagueConfiguration.domain}/organizations/create/${code}`;
        });
    }, [leagueConfiguration]);

    return (
        <section className="settings-page__invite-url global-container-centered">
            <p className="text-center">
                Sending this link to {organizationTerm.toLowerCase()} managers will allow them to create their{' '}
                {organizationTerm.toLowerCase()} in this league.
            </p>
            {createOrganizationUrls.map((url: string) => (
                <CopyTextField id="organizationInviteLink" label="Invite Link" value={url} />
            ))}
        </section>
    );
};

export default OrganizationInviteUrl;

import React from 'react';
import { Formik, Form, FormikProps, FormikActions } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../core';
import { SolidButton } from '../../components/buttons-visuals';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';
import { FieldSet } from '../../components/inputs';
import { MultiSelector } from '../../components/inputs/multiSelector';

interface AnnounceToStageProps {
    onSubmit: (values: Core.Models.CreateStageAnnouncementRequest) => Promise<void>;
    participants: AnnouncementParticipant[];
    stageId: string;
}

export interface AnnouncementParticipant {
    name: string;
    participantId: string;
}

const schema = Yup.object().shape({
    includeManagers: Yup.boolean(),
    message: Yup.string().required('Message is required').max(1_000, 'Message must be less than 1,000 characters'),
    participantIds: Yup.array().of(Yup.string()).required('Please choose at least one recipient'),
    stageId: Yup.string().required('Stage is required'),
});

const AnnounceToStage = ({ onSubmit, participants, stageId }: AnnounceToStageProps): JSX.Element => (
    <Formik<Core.Models.CreateStageAnnouncementRequest>
        initialValues={Object.assign({
            /** anything not specified here won't show an error message after an attempted submit */
            includeManagers: false,
            message: '',
            participantIds: participants.map((participant) => participant.participantId),
            stageId,
        })}
        validationSchema={schema}
        onSubmit={async (
            values: Core.Models.CreateStageAnnouncementRequest,
            actions: FormikActions<Core.Models.CreateStageAnnouncementRequest>
        ) => {
            actions.setStatus(undefined);
            try {
                await onSubmit(values);
            } catch (e) {
                const message = Core.API.getErrorMessage(e);
                actions.setStatus(message);
            }
            actions.setSubmitting(false);
        }}
        render={(formProps: FormikProps<Core.Models.CreateStageAnnouncementRequest>) => (
            <Form className="form">
                {participants.length > 0 ? (
                    <>
                        <FormField
                            component="textarea"
                            label="Message"
                            limit={1_000}
                            name="message"
                            placeholder="Message"
                            rows={5}
                        />
                        <MultiSelector
                            allIds={participants.map(
                                (participant: AnnouncementParticipant) => participant.participantId
                            )}
                            selectedIds={formProps.values.participantIds}
                            setSelectedIds={(participantIds: string[]) => {
                                formProps.setFieldValue('participantIds', participantIds);
                            }}
                        />
                        {participants.map((participant: AnnouncementParticipant) => (
                            <FormField
                                checked={formProps.values.participantIds.some(
                                    (participantId: string) => participantId === participant.participantId
                                )}
                                description={participant.name}
                                key={participant.participantId}
                                label={participant.name}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    let currentParticipantIds = [...formProps.values.participantIds];
                                    const participantId = event.target.value;
                                    if (
                                        currentParticipantIds.some(
                                            (participantId: string) => participantId === participant.participantId
                                        )
                                    ) {
                                        currentParticipantIds = currentParticipantIds.filter(
                                            (participantId: string) => participantId !== participant.participantId
                                        );
                                    } else {
                                        currentParticipantIds = [...currentParticipantIds, participantId];
                                    }
                                    formProps.setFieldValue('participantIds', currentParticipantIds);
                                }}
                                name={participant.participantId}
                                type="checkbox"
                                value={participant.participantId}
                            />
                        ))}
                        <hr />
                        <FormField name="includeManagers" type="checkbox" label="Include managers" />
                    </>
                ) : (
                    <p>No available participants</p>
                )}

                {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                <FieldSet>
                    <SolidButton
                        as="button"
                        disabled={participants.length <= 0}
                        layout="full"
                        onClick={formProps.submitForm}
                        pending={formProps.isSubmitting}
                    >
                        Send announcement
                    </SolidButton>
                </FieldSet>
            </Form>
        )}
    />
);

export default AnnounceToStage;

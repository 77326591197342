import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Formik, FormikActions, Form, FormikProps } from 'formik';
import indefinite from 'indefinite';
import { isUndefined, orderBy } from 'lodash';
import pluralize from 'pluralize';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../core';
import { IconButton, SolidButton } from '../../components/buttons-visuals';
import { ContentContainer } from '../../components/containers';
import Expando from '../../components/expando';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';
import JsonDebug from '../../components/jsonDebug';
import MarkdownPreview from '../../components/markdown/markdownPreview';
import { Popover } from '../../components/overlays';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useAlternateSeasonName } from '../../hooks/store';
import { LeagueService } from '../../services/leagueService';
import { SeasonService } from '../../services/seasonService';

import './form.scss';

const NEW_LOCATION_ID = 'NEW_LOCATION_ID';

interface EditStageFormProps extends WithLoadingProps {
    isCreate?: boolean;
    onSubmit: (values: EditStageFormSubmitValues) => Promise<void>;
    seasonId: string;
    stage?: Core.Models.BasicStage;
}

export interface EditStageFormSubmitValues
    extends Omit<EditStageFormValues, 'locationName' | 'numberOfStations' | 'usePointsBasedScoring'> {
    usePointsBasedScoring: boolean;
}

export interface EditStageFormValues {
    allowMatchGameTies: boolean;
    allowMatchTies: boolean;
    allowRescheduleRequests: boolean;
    attemptPointsBasedScoringTiebreaker: boolean;
    attendanceWindowMinutes?: number;
    autoForfeit: boolean;
    autoForfeitWindowMinutes?: number;
    autoStartRounds?: boolean;
    bestOf: number;
    dayOfWeek?: Core.Models.DayOfWeek;
    has3rdPlaceMatch: boolean;
    id?: string;
    inputParticipantSource: Core.Models.InputParticipantSourceType;
    locationId?: string;
    locationName?: string;
    matchesPerRound?: number;
    minutesAfterMidnight?: number;
    name?: string;
    numberOfGroups: number;
    numberOfInputParticipants: number | null;
    numberOfRounds?: number;
    numberOfStations?: number;
    preScheduledDescription?: string;
    previousStageInputSourceId: string;
    randomizeLeaderboardParticipants?: boolean;
    requireResultScreenshots: boolean;
    requireStageCheckIn: boolean;
    rescheduleCutoffDayOfWeek?: number;
    rescheduleCutoffMinutesAfterMidnight?: number;
    rosterLockHours?: number;
    rosterLocksEnabled: boolean;
    stageTypeId: Core.Models.StageTypeId;
    standingsPointsForBye: number;
    standingsPointsForGameTie: number;
    standingsPointsForGameWin: number;
    standingsPointsForMatchLossByForfeit: number;
    standingsPointsForMatchLossByPlay: number;
    standingsPointsForMatchTie: number;
    standingsPointsForMatchWinByForfeit: number;
    standingsPointsForMatchWinByPlay: number;
    timingMode: Core.Models.StageTimingMode;
    usePointsBasedScoring: string; // using `string` due to `Select` component
    useSimplifiedCheckin: boolean;
}

const getInitialBestOf = (season: Core.Models.Season) => {
    if (season.game.featureSet === Core.Models.FeatureSet.Hearthstone) return 5;
    if (season.tennisStyle) return season.game.minimumSeats * season.tennisStyleSets!;
    return 1;
};

const EditStageForm = (props: EditStageFormProps): JSX.Element => {
    const { isCreate, onSubmit, seasonId, setError, setIsLoading, stage } = props;
    const [season, setSeason] = useState<Core.Models.Season | undefined>(undefined);
    const [selectableLocations, setSelectableLocations] = useState<Core.Models.LeagueLocation[]>([]);
    const [showSimplifiedCheckinInfo, setShowSimplifiedCheckinInfo] = useState<boolean>(false);
    const isLeaderboardDefault = season?.game.competitionStyle === Core.Models.CompetitionStyle.Leaderboard;
    const isHearthstone = season?.game.featureSet === Core.Models.FeatureSet.Hearthstone;
    const extensions = season?.game.resultFileExtensions?.join(', ');
    const seasonAlternateName = useAlternateSeasonName();
    const inProgress = useMemo(() => !!stage && stage.currentState > Core.Models.StageState.NotStarted, [stage]);
    const matchWord = useMemo(() => Core.Competition.getMatchWord(season?.game.eventType), [season]);
    const teamWord = useMemo(() => ((season?.game.minimumSeats ?? 0) > 1 ? 'team' : 'player'), [season]);
    const randomizeLeaderboardParticipantsDefault = useMemo(() => {
        if (season?.game.competitionStyle !== Core.Models.CompetitionStyle.Leaderboard) return undefined;
        return season?.game.eventType === Core.Models.EventType.Competition;
    }, [season?.game]);
    const seasonStages = useMemo(() => {
        if (!season) return [];
        const stages = orderBy(season.stages, (s: Core.Models.Stage) => s.sortOrder);
        return stages.filter((s: Core.Models.Stage) => s.id !== stage?.id);
    }, [season, stage?.id]);

    useEffect(() => {
        (async () => {
            try {
                const response = await SeasonService.getById(seasonId);
                setSeason(response);

                const { locations } = await LeagueService.getLeagueLocationsWithStations();
                setSelectableLocations(locations.filter((l: Core.Models.LeagueLocation) => !l.deleted));
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [seasonId, setError, setIsLoading]);

    // Determines StartTimeMode. Value is an int  when it comes straight from the back end.
    const isAutoScheduled = useCallback((formValue: number | string): boolean => {
        return formValue.toString() === Core.Models.StageTimingMode.AutoScheduled.toString() || formValue === 1;
    }, []);

    const getNewLocationId = useCallback(async (name: string, numberOfStations?: number) => {
        try {
            const { location } = await LeagueService.createLeagueLocation({
                name,
                numberOfStations,
            });
            return location.id;
        } catch (e) {
            const message = Core.API.getErrorMessage(e);
            toast.error(message);
        }
    }, []);

    if (!season) return <></>;
    return (
        <Formik<EditStageFormValues>
            initialValues={Object.assign(
                {
                    /** anything not specified here won't show an error message after an attempted submit */
                    allowMatchGameTies: season.game.supportsTies, // if the game supports ties, allow by default
                    allowMatchTies: false,
                    allowRescheduleRequests: true,
                    attemptPointsBasedScoringTiebreaker: false,
                    attendanceWindowMinutes: 60,
                    autoForfeit: !!stage?.autoForfeitWindowMinutes,
                    autoForfeitWindowMinutes: 15,
                    autoStartRounds: undefined,
                    bestOf: getInitialBestOf(season),
                    dayOfWeek: Core.Models.DayOfWeek.Sunday,
                    has3rdPlaceMatch: false,
                    inputParticipantSource: Core.Models.InputParticipantSourceType.AllSeasonParticipants,
                    locationId: '',
                    locationName: '',
                    matchesPerRound: isLeaderboardDefault ? 1 : undefined,
                    minutesAfterMidnight: 1020, // 5pm browser time
                    name: '',
                    numberOfGroups: 1,
                    numberOfInputParticipants: season.participants.length || null, // if > 0 (0 is falsy), set. else, set to null
                    numberOfRounds: '',
                    numberOfStations: '',
                    preScheduledDescription: '',
                    previousStageInputSourceId: '',
                    randomizeLeaderboardParticipants: randomizeLeaderboardParticipantsDefault,
                    requireResultScreenshots: !isLeaderboardDefault,
                    requireStageCheckIn: false,
                    rescheduleCutoffDayOfWeek: Core.Models.DayOfWeek.Sunday,
                    rescheduleCutoffMinutesAfterMidnight: 1020, // 5pm browser time
                    rosterLockHours: null,
                    rosterLocksEnabled: !!stage?.rosterLockHours,
                    stageTypeId: '' as Core.Models.StageTypeId,
                    standingsPointsForBye: 1,
                    standingsPointsForGameTie: 0,
                    standingsPointsForGameWin: 0,
                    standingsPointsForMatchLossByForfeit: 0,
                    standingsPointsForMatchLossByPlay: 0,
                    standingsPointsForMatchTie: 0,
                    standingsPointsForMatchWinByForfeit: 1,
                    standingsPointsForMatchWinByPlay: 1,
                    timingMode: Core.Models.StageTimingMode.ManuallyStarted,
                    useSimplifiedCheckin: false,
                },
                stage,
                {
                    usePointsBasedScoring: isUndefined(stage?.usePointsBasedScoring)
                        ? +(isLeaderboardDefault || season.tennisStyle)
                        : (+stage!.usePointsBasedScoring).toString(),
                }
            )}
            validationSchema={Yup.object().shape({
                allowRescheduleRequests: Yup.boolean(),
                name: Yup.string().required('Name is required'),
                requireResultScreenshots: Yup.boolean(),
                rosterLockHours: Yup.number()
                    .nullable()
                    .positive('Number of hours for roster lock must be positive')
                    .when('rosterLocksEnabled', {
                        is: true,
                        then: Yup.number().required('Number of hours for roster lock is required'),
                    }),
                rosterLocksEnabled: Yup.boolean(),
                useSimplifiedCheckin: Yup.boolean(),
                ...(!inProgress && {
                    allowMatchGameTies: Yup.boolean(),
                    allowMatchTies: Yup.boolean(),
                    attemptPointsBasedScoringTiebreaker: Yup.boolean(),
                    attendanceWindowMinutes: Yup.number()
                        .nullable()
                        .notRequired()
                        .positive()
                        .when('stageTypeId', {
                            is: (stageTypeId: Core.Models.StageTypeId) =>
                                [
                                    Core.Models.StageTypeId.AutoMatcher,
                                    Core.Models.StageTypeId.LeaderboardAutoMatcher,
                                ].includes(stageTypeId),
                            then: Yup.number().required('Attendance window is required'),
                        }),
                    autoForfeit: Yup.boolean(),
                    autoForfeitWindowMinutes: Yup.number()
                        .nullable()
                        .positive()
                        .when('autoForfeit', {
                            is: true,
                            then: Yup.number().required('Forfeit Window is required'),
                        }),
                    autoStartRounds: Yup.boolean().nullable(),
                    bestOf: Yup.number()
                        .positive(`Number of games per ${matchWord.toLowerCase()} must be positive`)
                        .required(`Number of games per ${matchWord.toLowerCase()} is required`)
                        .test(
                            'is-valid-hearthstone',
                            'Best of must be 5 for Hearthstone stages',
                            (bestOf: number | undefined) => {
                                return !isHearthstone || bestOf === 5;
                            }
                        ),
                    dayOfWeek: Yup.number()
                        .nullable()
                        .notRequired()
                        .when('timingMode', {
                            is: Core.Models.StageTimingMode.AutoScheduled,
                            then: Yup.number().required('Day of week is required'),
                        }),
                    has3rdPlaceMatch: Yup.boolean(),
                    id: Yup.string().nullable().notRequired(),
                    inputParticipantSource: Yup.string().required('Input participant source is required'),
                    locationId: Yup.string().nullable(),
                    locationName: Yup.string()
                        .nullable()
                        .when('locationId', {
                            is: NEW_LOCATION_ID,
                            then: Yup.string().required('Location name is required'),
                        }),
                    matchesPerRound: Yup.number()
                        .nullable()
                        .positive()
                        .when('stageTypeId', {
                            is: (stageTypeId: Core.Models.StageTypeId) =>
                                [
                                    Core.Models.StageTypeId.Leaderboard,
                                    Core.Models.StageTypeId.LeaderboardAutoMatcher,
                                ].includes(stageTypeId),
                            then: Yup.number().required(`${pluralize(matchWord)} per round is required`),
                        }),
                    minutesAfterMidnight: Yup.string()
                        .nullable()
                        .notRequired()
                        .when('timingMode', {
                            is: Core.Models.StageTimingMode.AutoScheduled,
                            then: Yup.string().required('Time is required'),
                        }),
                    numberOfGroups: Yup.number().positive().required('Number of groups is required'),
                    numberOfInputParticipants: Yup.number()
                        .nullable()
                        .positive('Number of input participants must be positive')
                        .test({
                            name: 'max',
                            exclusive: false,
                            message: `Double Elimination only supports groups with ${Core.Constants.DOUBLE_ELIM_GROUP_MAX_LENGTH} participants or fewer`,
                            test: (
                                value: EditStageFormValues['numberOfInputParticipants'] | undefined,
                                context: Yup.TestContext
                            ) => {
                                if (
                                    (context.parent as EditStageFormValues).stageTypeId !==
                                    Core.Models.StageTypeId.DoubleElimination
                                )
                                    return true;

                                const numberOfGroups = (context.parent as EditStageFormValues).numberOfGroups;
                                return (value ?? 0) <= numberOfGroups * Core.Constants.DOUBLE_ELIM_GROUP_MAX_LENGTH;
                            },
                        }),
                    numberOfRounds: Yup.number()
                        .nullable()
                        .positive()
                        .when('stageTypeId', {
                            is: (stageTypeId: Core.Models.StageTypeId) =>
                                [
                                    Core.Models.StageTypeId.AutoMatcher,
                                    Core.Models.StageTypeId.Leaderboard,
                                    Core.Models.StageTypeId.LeaderboardAutoMatcher,
                                    Core.Models.StageTypeId.Swiss,
                                ].includes(stageTypeId),
                            then: Yup.number().required('Number of rounds is required'),
                        }),
                    numberOfStations: Yup.number()
                        .nullable()
                        .when('locationId', {
                            is: NEW_LOCATION_ID,
                            then: Yup.number()
                                .min(1, 'Number of stations must be 1 or more.')
                                .max(100, 'Number of stations must be 100 or fewer.'),
                        }),
                    preScheduledDescription: Yup.string().nullable(),
                    previousStageInputSourceId: Yup.string()
                        .nullable()
                        .when('inputParticipantSource', {
                            is: Core.Models.InputParticipantSourceType.FromPreviousStage,
                            then: Yup.string().required('Prior stage is required'),
                        }),
                    randomizeLeaderboardParticipants: Yup.boolean().nullable().notRequired(),
                    requireStageCheckIn: Yup.boolean(),
                    rescheduleCutoffDayOfWeek: Yup.number().nullable().notRequired(),
                    rescheduleCutoffMinutesAfterMidnight: Yup.string()
                        .nullable()
                        .notRequired()
                        .when('rescheduleCutoffDayOfWeek', (rescheduleCutoffDayOfWeek: Core.Models.DayOfWeek) => {
                            if (!!rescheduleCutoffDayOfWeek) {
                                return Yup.string().required('Reschedule cutoff must have a time of day');
                            }
                            return Yup.string().nullable().notRequired();
                        }),
                    stageTypeId: Yup.string().required('Stage Type is required'),
                    standingsPointsForBye: Yup.number().required('Standings points for bye is required'),
                    standingsPointsForGameTie: Yup.number().required('Standings points for game tie is required'),
                    standingsPointsForGameWin: Yup.number().required('Standings points for game win is required'),
                    standingsPointsForMatchLossByForfeit: Yup.number().required(
                        'Standings points for match loss by forfeit is required'
                    ),
                    standingsPointsForMatchLossByPlay: Yup.number().required(
                        'Standings points for match loss by play is required'
                    ),
                    standingsPointsForMatchTie: Yup.number().required('Standings points for match tie is required'),
                    standingsPointsForMatchWinByForfeit: Yup.number().required(
                        'Standings points for match win by forfeit is required'
                    ),
                    standingsPointsForMatchWinByPlay: Yup.number().required(
                        'Standings points for match win by play is required'
                    ),
                    timingMode: Yup.number().required('Timing mode is required'),
                    usePointsBasedScoring: Yup.string().oneOf(['0', '1']),
                }),
            })}
            onSubmit={async (
                { locationId, locationName, numberOfStations, ...values }: EditStageFormValues,
                actions: FormikActions<EditStageFormValues>
            ) => {
                const preventRescheduleStageTypeIds = [
                    Core.Models.StageTypeId.AutoMatcher,
                    Core.Models.StageTypeId.Leaderboard,
                    Core.Models.StageTypeId.LeaderboardAutoMatcher,
                ];

                actions.setStatus(undefined);
                const payload = {
                    ...values,
                    allowRescheduleRequests:
                        !preventRescheduleStageTypeIds.includes(values.stageTypeId) && values.allowRescheduleRequests,
                    autoForfeitWindowMinutes: values.autoForfeit ? values.autoForfeitWindowMinutes : null,
                    rosterLockHours: values.rosterLocksEnabled ? values.rosterLockHours : null,
                    usePointsBasedScoring: +values.usePointsBasedScoring === 1,
                    locationId:
                        !!locationId && locationId === NEW_LOCATION_ID && !!locationName
                            ? await getNewLocationId(locationName, numberOfStations)
                            : locationId,
                    ...(values.timingMode === Core.Models.StageTimingMode.ManuallyStarted
                        ? {
                              dayOfWeek: null,
                              minutesAfterMidnight: null,
                          }
                        : {}),
                    ...(!values.allowRescheduleRequests || (values.rescheduleCutoffDayOfWeek ?? -1) < 0
                        ? {
                              rescheduleCutoffDayOfWeek: null,
                              rescheduleCutoffMinutesAfterMidnight: null,
                          }
                        : {}),
                } as EditStageFormSubmitValues;
                try {
                    await onSubmit(payload);
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(formProps: FormikProps<EditStageFormValues>) => {
                const stageTypeId = formProps.values.stageTypeId.toString();
                const autoMatcherStageTypeId = Core.Models.StageTypeId.AutoMatcher.toString();
                const doubleElimStageTypeId = Core.Models.StageTypeId.DoubleElimination.toString();
                const leaderboardAutoMatcherStageTypeId = Core.Models.StageTypeId.LeaderboardAutoMatcher.toString();
                const singleElimStageTypeId = Core.Models.StageTypeId.SingleElimination.toString();

                // stage type bools to conditionally show things later - reduces the number of inline evaluations and improves performance/legibility
                const isAutoMatcher = [autoMatcherStageTypeId, leaderboardAutoMatcherStageTypeId].includes(stageTypeId);
                const isDoubleElim = stageTypeId === doubleElimStageTypeId;
                const isLeaderboard = season.game.competitionStyle === Core.Models.CompetitionStyle.Leaderboard;
                const isSingleElim = stageTypeId === singleElimStageTypeId;
                const isSingleRoundRobin = stageTypeId === Core.Models.StageTypeId.RoundRobin.toString();
                const isSwiss = stageTypeId === Core.Models.StageTypeId.Swiss.toString();

                const showBestOf = !isLeaderboard && !season.tennisStyle;
                const showNumberOfGroups = !isLeaderboard;

                return (
                    <>
                        <ContentContainer shade={Core.Models.Shades.Dark40} className="global-container-centered">
                            <Form>
                                <fieldset className="form-group">
                                    <FormField type="text" name="name" description="Stage name" />
                                </fieldset>
                                {!inProgress && (
                                    <>
                                        <fieldset className="form-group">
                                            <FormField
                                                className="form-field--touched"
                                                component="select"
                                                name="stageTypeId"
                                                label="Select a stage type"
                                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                    const selectedStageTypeId = event.target
                                                        .value as Core.Models.StageTypeId;
                                                    const selectedAutoMatcher = [
                                                        autoMatcherStageTypeId,
                                                        leaderboardAutoMatcherStageTypeId,
                                                    ].includes(selectedStageTypeId);

                                                    if (!selectedAutoMatcher) {
                                                        formProps.setFieldValue('autoStartRounds', undefined);
                                                    }

                                                    if (selectedStageTypeId === doubleElimStageTypeId) {
                                                        formProps.setFieldValue('allowMatchTies', false);
                                                        formProps.setFieldValue('standingsPointsForBye', 1);
                                                        formProps.setFieldValue('standingsPointsForGameTie', 0);
                                                        formProps.setFieldValue('standingsPointsForGameWin', 0);
                                                        formProps.setFieldValue(
                                                            'standingsPointsForMatchLossByForfeit',
                                                            0
                                                        );
                                                        formProps.setFieldValue('standingsPointsForMatchLossByPlay', 0);
                                                        formProps.setFieldValue('standingsPointsForMatchTie', 0);
                                                        formProps.setFieldValue(
                                                            'standingsPointsForMatchWinByForfeit',
                                                            1
                                                        );
                                                        formProps.setFieldValue('standingsPointsForMatchWinByPlay', 1);
                                                    } else if (selectedAutoMatcher) {
                                                        formProps.setFieldValue('requireStageCheckIn', false);
                                                    } else if (selectedStageTypeId === singleElimStageTypeId) {
                                                        formProps.setFieldValue('allowMatchTies', false);
                                                        formProps.setFieldValue('standingsPointsForBye', 1);
                                                        formProps.setFieldValue('standingsPointsForGameTie', 0);
                                                        formProps.setFieldValue('standingsPointsForGameWin', 0);
                                                        formProps.setFieldValue(
                                                            'standingsPointsForMatchLossByForfeit',
                                                            0
                                                        );
                                                        formProps.setFieldValue('standingsPointsForMatchLossByPlay', 0);
                                                        formProps.setFieldValue('standingsPointsForMatchTie', 0);
                                                        formProps.setFieldValue(
                                                            'standingsPointsForMatchWinByForfeit',
                                                            1
                                                        );
                                                        formProps.setFieldValue('standingsPointsForMatchWinByPlay', 1);
                                                    }
                                                }}
                                            >
                                                <option value="" hidden disabled>
                                                    --
                                                </option>
                                                {season.game.competitionStyle ===
                                                    Core.Models.CompetitionStyle.HeadToHead && (
                                                    <>
                                                        <option value={Core.Models.StageTypeId.Swiss}>Swiss</option>
                                                        <option value={Core.Models.StageTypeId.RoundRobin}>
                                                            Round Robin
                                                        </option>
                                                        <option value={Core.Models.StageTypeId.DoubleRoundRobin}>
                                                            Double Round Robin
                                                        </option>
                                                        <option value={Core.Models.StageTypeId.SingleElimination}>
                                                            Single Elimination
                                                        </option>
                                                        <option value={Core.Models.StageTypeId.DoubleElimination}>
                                                            Double Elimination
                                                        </option>
                                                        <option value={Core.Models.StageTypeId.AutoMatcher}>
                                                            AutoMatcher (alpha)
                                                        </option>
                                                    </>
                                                )}
                                                {season.game.competitionStyle ===
                                                    Core.Models.CompetitionStyle.Leaderboard && (
                                                    <>
                                                        <option value={Core.Models.StageTypeId.Leaderboard}>
                                                            Leaderboard
                                                        </option>
                                                        <option value={Core.Models.StageTypeId.LeaderboardAutoMatcher}>
                                                            Leaderboard AutoMatcher
                                                        </option>
                                                    </>
                                                )}
                                            </FormField>
                                            {(isAutoMatcher || isLeaderboard || isSingleRoundRobin || isSwiss) && (
                                                <FormField
                                                    type="number"
                                                    name="numberOfRounds"
                                                    description={`Number of rounds${
                                                        isSingleRoundRobin
                                                            ? ' (Leave blank for a full round robin)'
                                                            : ''
                                                    }`}
                                                />
                                            )}
                                            {isAutoMatcher && (
                                                <FormField
                                                    type="number"
                                                    name="attendanceWindowMinutes"
                                                    description={`Attendance window (in minutes)`}
                                                />
                                            )}
                                            {isLeaderboard && (
                                                <FormField
                                                    type="number"
                                                    name="matchesPerRound"
                                                    description={`${pluralize(matchWord)} per round`}
                                                />
                                            )}
                                        </fieldset>
                                        {isSingleRoundRobin && (
                                            <>
                                                <p className="edit-form__tip-label">
                                                    Note: A full round robin {seasonAlternateName.toLowerCase()} has a
                                                    number of rounds equal to the amount of teams minus 1. If you enter
                                                    a number that is above this limit, we will automatically adjust to
                                                    that maximum when scheduling rounds.
                                                </p>
                                                <p className="edit-form__tip-label">
                                                    If you'd like teams to play each other more than once, consider a
                                                    double round robin instead.
                                                </p>
                                            </>
                                        )}
                                        {isSingleElim && (
                                            <FormField
                                                type="checkbox"
                                                name="has3rdPlaceMatch"
                                                description={`Hold a 3rd-place ${matchWord.toLowerCase()}`}
                                            />
                                        )}
                                        {!isAutoMatcher && (
                                            <FormField
                                                description="Require teams to check in to stage to participate"
                                                name="requireStageCheckIn"
                                                type="checkbox"
                                            />
                                        )}
                                        {isAutoMatcher && (
                                            <FormField
                                                type="checkbox"
                                                name="autoStartRounds"
                                                description="Start rounds automatically"
                                            />
                                        )}
                                        {isLeaderboard &&
                                            !isAutoMatcher &&
                                            !isUndefined(season.game.maximumLobbySize) &&
                                            (!season.maxParticipants ||
                                                season.maxParticipants >
                                                    season.game.maximumLobbySize / season.game.minimumSeats) && (
                                                <FormField
                                                    type="checkbox"
                                                    name="randomizeLeaderboardParticipants"
                                                    description={
                                                        season.game.eventType === Core.Models.EventType.Competition
                                                            ? `Randomize ${pluralize(
                                                                  teamWord
                                                              )} in each lobby between rounds`
                                                            : 'Randomize students in each classroom between rounds'
                                                    }
                                                />
                                            )}

                                        <fieldset className="form-group">
                                            <FormField
                                                component="select"
                                                name="timingMode"
                                                label="Select a timing mode"
                                            >
                                                <option value={Core.Models.StageTimingMode.AutoScheduled}>
                                                    {`${
                                                        isDoubleElim
                                                            ? 'Auto-schedule first round'
                                                            : 'Recurring once per week'
                                                    }`}{' '}
                                                    (default)
                                                </option>
                                                <option value={Core.Models.StageTimingMode.ManuallyStarted}>
                                                    I will manually schedule rounds and{' '}
                                                    {pluralize(matchWord.toLowerCase())}
                                                </option>
                                            </FormField>
                                            {isAutoScheduled(formProps.values.timingMode) && (
                                                <>
                                                    <FormField
                                                        className="form-field--split"
                                                        component="select"
                                                        description="Day of week"
                                                        name="dayOfWeek"
                                                    >
                                                        <option value={Core.Models.DayOfWeek.Sunday}>Sunday</option>
                                                        <option value={Core.Models.DayOfWeek.Monday}>Monday</option>
                                                        <option value={Core.Models.DayOfWeek.Tuesday}>Tuesday</option>
                                                        <option value={Core.Models.DayOfWeek.Wednesday}>
                                                            Wednesday
                                                        </option>
                                                        <option value={Core.Models.DayOfWeek.Thursday}>Thursday</option>
                                                        <option value={Core.Models.DayOfWeek.Friday}>Friday</option>
                                                        <option value={Core.Models.DayOfWeek.Saturday}>Saturday</option>
                                                    </FormField>
                                                    <FormField
                                                        className="form-field--split"
                                                        component="time"
                                                        defaultValue={formProps.values.minutesAfterMidnight ?? 1020}
                                                        label="Start time"
                                                        name="minutesAfterMidnight"
                                                    />
                                                </>
                                            )}
                                        </fieldset>

                                        <fieldset className="form-group">
                                            <FormField
                                                component="textarea"
                                                name="preScheduledDescription"
                                                aria-describedby="Description displayed before stage is started. This field allows markdown formatting."
                                                label="Description"
                                            />
                                        </fieldset>

                                        <MarkdownPreview
                                            fieldName="Pre-scheduled description"
                                            source={formProps.values.preScheduledDescription}
                                        />

                                        {(showBestOf || showNumberOfGroups) && (
                                            <fieldset className="form-group">
                                                {showBestOf && (
                                                    <FormField
                                                        disabled={isHearthstone}
                                                        type="number"
                                                        name="bestOf"
                                                        description={`Games per ${matchWord.toLowerCase()}`}
                                                        className={classNames({
                                                            'form-field--split': showNumberOfGroups,
                                                        })}
                                                    />
                                                )}
                                                {showNumberOfGroups && (
                                                    <FormField
                                                        type="number"
                                                        name="numberOfGroups"
                                                        description="Number of groups"
                                                        className={classNames({ 'form-field--split': showBestOf })}
                                                    />
                                                )}
                                            </fieldset>
                                        )}

                                        {!isLeaderboard && (
                                            <>
                                                <FormField
                                                    description="Allow match ties"
                                                    disabled={isDoubleElim || isSingleElim}
                                                    name="allowMatchTies"
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (!event.target.checked) {
                                                            formProps.setFieldValue('standingsPointsForMatchTie', 0);
                                                        }
                                                    }}
                                                    type="checkbox"
                                                />
                                                {season.game.supportsTies && (
                                                    <FormField
                                                        description="Allow match game ties"
                                                        name="allowMatchGameTies"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            if (!event.target.checked) {
                                                                formProps.setFieldValue('standingsPointsForGameTie', 0);
                                                            }
                                                        }}
                                                        type="checkbox"
                                                    />
                                                )}
                                            </>
                                        )}

                                        {!isLeaderboard && (
                                            <>
                                                <fieldset className="form-group">
                                                    <FormField
                                                        aria-describedby={`How will ${pluralize(
                                                            matchWord.toLowerCase()
                                                        )} be scored?`}
                                                        component="select"
                                                        disabled={season.tennisStyle}
                                                        label="Select a scoring methodology"
                                                        name="usePointsBasedScoring"
                                                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                            const usePointsBasedScoring = !!+event.target.value;
                                                            if (!usePointsBasedScoring)
                                                                formProps.setFieldValue(
                                                                    'attemptPointsBasedScoringTiebreaker',
                                                                    false
                                                                );
                                                        }}
                                                        value={formProps.values.usePointsBasedScoring}
                                                    >
                                                        <option value="0">
                                                            {formProps.values.bestOf === 1
                                                                ? 'Single game winner'
                                                                : `Best of ${formProps.values.bestOf} ${pluralize(
                                                                      'game',
                                                                      formProps.values.bestOf
                                                                  )}`}
                                                        </option>
                                                        <option value="1">
                                                            Points-based score of {formProps.values.bestOf}{' '}
                                                            {pluralize('game', formProps.values.bestOf)}
                                                        </option>
                                                    </FormField>
                                                    {!!+formProps.values.usePointsBasedScoring && (
                                                        <div className="disp-flex">
                                                            <FormField
                                                                description={`Attempt to break ${matchWord.toLowerCase()} tie after all games are played`}
                                                                name="attemptPointsBasedScoringTiebreaker"
                                                                type="checkbox"
                                                            />
                                                            <Popover
                                                                button={
                                                                    <IconButton
                                                                        as="button"
                                                                        buttonLabel="More info"
                                                                        buttonSize="small"
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={['fas', 'circle-info']}
                                                                        />
                                                                    </IconButton>
                                                                }
                                                            >
                                                                <p className="p text-small">
                                                                    If a {matchWord.toLowerCase()} is tied after all
                                                                    games are played, attempt to break the tie by using
                                                                    number of games won. For example, in a best of 3, if
                                                                    the score is 2-2, but the home team got more points
                                                                    in 2 out of 3 games, the home team would be declared
                                                                    winner.
                                                                </p>
                                                            </Popover>
                                                        </div>
                                                    )}
                                                </fieldset>

                                                {!(isSingleElim || isDoubleElim) && (
                                                    <Expando className="edit-form__expando" title="Standings scoring">
                                                        <fieldset className="form-group">
                                                            <FormField
                                                                className="form-field--split"
                                                                description="Standings points for match win by forfeit"
                                                                disabled={isSingleElim || isDoubleElim}
                                                                name="standingsPointsForMatchWinByForfeit"
                                                                label={`Points for match win by forfeit (default 1)`}
                                                                type="number"
                                                            />
                                                            <FormField
                                                                className="form-field--split"
                                                                description="Standings points for match win by play"
                                                                disabled={isSingleElim || isDoubleElim}
                                                                name="standingsPointsForMatchWinByPlay"
                                                                label={`Points for match win by play (default 1)`}
                                                                type="number"
                                                            />
                                                            <FormField
                                                                className="form-field--split"
                                                                description="Standings points for match tie"
                                                                disabled={
                                                                    !formProps.values.allowMatchTies ||
                                                                    isSingleElim ||
                                                                    isDoubleElim
                                                                }
                                                                name="standingsPointsForMatchTie"
                                                                label={`Points for match tie (default 0)`}
                                                                type="number"
                                                            />
                                                            <FormField
                                                                className="form-field--split"
                                                                description="Standings points for match loss by forfeit"
                                                                disabled={isSingleElim || isDoubleElim}
                                                                name="standingsPointsForMatchLossByForfeit"
                                                                label={`Points for match loss by forfeit (default 0)`}
                                                                type="number"
                                                            />
                                                            <FormField
                                                                className="form-field--split"
                                                                description="Standings points for match loss by play"
                                                                disabled={isSingleElim || isDoubleElim}
                                                                name="standingsPointsForMatchLossByPlay"
                                                                label={`Points for match loss by play (default 0)`}
                                                                type="number"
                                                            />
                                                            <FormField
                                                                description="Standings points for bye"
                                                                disabled={isSingleElim || isDoubleElim}
                                                                name="standingsPointsForBye"
                                                                label={`Points for bye (default 1)`}
                                                                type="number"
                                                            />
                                                            <FormField
                                                                className="form-field--split"
                                                                description="Standings points for game win"
                                                                name="standingsPointsForGameWin"
                                                                label={`Points for game win (default 0)`}
                                                                type="number"
                                                            />
                                                            {season.game.supportsTies && (
                                                                <FormField
                                                                    className="form-field--split"
                                                                    description="Standings points for game tie"
                                                                    disabled={!formProps.values.allowMatchGameTies}
                                                                    name="standingsPointsForGameTie"
                                                                    label={`Points for game tie (default 0)`}
                                                                    type="number"
                                                                />
                                                            )}
                                                        </fieldset>
                                                    </Expando>
                                                )}
                                            </>
                                        )}

                                        <fieldset className="form-group">
                                            <FormField
                                                component="select"
                                                name="inputParticipantSource"
                                                aria-describedby="How will you select which teams participate?"
                                                label="Select an input participant source"
                                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                    const participantValue =
                                                        event.target.value ===
                                                        Core.Models.InputParticipantSourceType.AllSeasonParticipants.toString()
                                                            ? null
                                                            : '';
                                                    formProps.setFieldValue(
                                                        'numberOfInputParticipants',
                                                        participantValue
                                                    );
                                                }}
                                            >
                                                <option value="" hidden disabled>
                                                    --
                                                </option>
                                                <option
                                                    value={Core.Models.InputParticipantSourceType.AllSeasonParticipants}
                                                >
                                                    We will use all teams participating in this{' '}
                                                    {seasonAlternateName.toLowerCase()}
                                                </option>
                                                {seasonStages.length > 0 && (
                                                    <option
                                                        value={Core.Models.InputParticipantSourceType.FromPreviousStage}
                                                    >
                                                        We will select the top teams from an earlier stage
                                                    </option>
                                                )}
                                                <option value={Core.Models.InputParticipantSourceType.ManualList}>
                                                    We will manually select teams later
                                                </option>
                                            </FormField>
                                            {formProps.values.inputParticipantSource.toString() ===
                                                Core.Models.InputParticipantSourceType.FromPreviousStage.toString() && (
                                                <FormField
                                                    component="select"
                                                    aria-describedby="Prior stage participant source"
                                                    label="Select a prior stage"
                                                    name="previousStageInputSourceId"
                                                >
                                                    <option value="" hidden disabled>
                                                        --
                                                    </option>
                                                    {seasonStages.map((stage: Core.Models.Stage) => (
                                                        <option key={stage.id} value={stage.id}>
                                                            {stage.name}
                                                        </option>
                                                    ))}
                                                </FormField>
                                            )}
                                            {formProps.values.inputParticipantSource.toString() !==
                                                Core.Models.InputParticipantSourceType.AllSeasonParticipants.toString() && (
                                                <FormField
                                                    type="number"
                                                    name="numberOfInputParticipants"
                                                    description="Number of participants (leave blank to include all)"
                                                />
                                            )}
                                        </fieldset>

                                        {!isLeaderboard && (
                                            <div className="disp-flex">
                                                <FormField
                                                    description="Enable auto-forfeits"
                                                    disabled={formProps.values.useSimplifiedCheckin}
                                                    name="autoForfeit"
                                                    type="checkbox"
                                                />
                                                {formProps.values.useSimplifiedCheckin && (
                                                    <Popover
                                                        button={
                                                            <IconButton
                                                                as="button"
                                                                buttonLabel="More info"
                                                                buttonSize="small"
                                                            >
                                                                <FontAwesomeIcon icon={['fas', 'circle-info']} />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <p className="p">
                                                            Auto-forfeit cannot be enabled when using simplified
                                                            check-in.
                                                        </p>
                                                    </Popover>
                                                )}
                                            </div>
                                        )}
                                        {formProps.values.autoForfeit && (
                                            <fieldset className="form-group">
                                                <FormField
                                                    type="number"
                                                    name="autoForfeitWindowMinutes"
                                                    description="Auto-forfeit grace period in minutes"
                                                />
                                                {(isDoubleElim || isSingleElim) && (
                                                    <InfoMessage
                                                        message={
                                                            <>
                                                                <p className="edit-form__tip-label">
                                                                    Auto-forfeit will <i>not</i> be applied to{' '}
                                                                    {indefinite(
                                                                        Core.Competition.getStageTypeName(
                                                                            formProps.values.stageTypeId
                                                                        )
                                                                    )}{' '}
                                                                    {matchWord.toLowerCase()} when:
                                                                </p>
                                                                <ul className="edit-form__tip-label edit-form__tip-label__list">
                                                                    <li>
                                                                        previous {pluralize(matchWord.toLowerCase())}{' '}
                                                                        are still ongoing
                                                                    </li>
                                                                    <li>
                                                                        <i>neither</i> participant has checked in
                                                                    </li>
                                                                </ul>
                                                            </>
                                                        }
                                                        type="info"
                                                    />
                                                )}
                                            </fieldset>
                                        )}
                                    </>
                                )}

                                {!isAutoMatcher &&
                                    !isLeaderboard && ( // AutoMatcher and Leaderboard matches cannot be rescheduled
                                        <>
                                            <FormField
                                                description="Allow reschedule requests"
                                                name="allowRescheduleRequests"
                                                type="checkbox"
                                            />
                                            {!inProgress &&
                                                formProps.values.allowRescheduleRequests &&
                                                isAutoScheduled(formProps.values.timingMode) && (
                                                    <>
                                                        <h3>Reschedule cutoff</h3>
                                                        <fieldset className="form-group">
                                                            <FormField
                                                                className={classNames('form-field', {
                                                                    'form-field--split':
                                                                        (formProps.values.rescheduleCutoffDayOfWeek ??
                                                                            -1) >= 0,
                                                                })}
                                                                component="select"
                                                                label="Day of week"
                                                                name="rescheduleCutoffDayOfWeek"
                                                            >
                                                                <option value="" hidden disabled>
                                                                    --
                                                                </option>
                                                                <option value={-1}>None</option>
                                                                <option value={Core.Models.DayOfWeek.Sunday}>
                                                                    Sunday
                                                                </option>
                                                                <option value={Core.Models.DayOfWeek.Monday}>
                                                                    Monday
                                                                </option>
                                                                <option value={Core.Models.DayOfWeek.Tuesday}>
                                                                    Tuesday
                                                                </option>
                                                                <option value={Core.Models.DayOfWeek.Wednesday}>
                                                                    Wednesday
                                                                </option>
                                                                <option value={Core.Models.DayOfWeek.Thursday}>
                                                                    Thursday
                                                                </option>
                                                                <option value={Core.Models.DayOfWeek.Friday}>
                                                                    Friday
                                                                </option>
                                                                <option value={Core.Models.DayOfWeek.Saturday}>
                                                                    Saturday
                                                                </option>
                                                            </FormField>
                                                            {(formProps.values.rescheduleCutoffDayOfWeek ?? -1) >=
                                                                0 && (
                                                                <FormField
                                                                    className="form-field--split"
                                                                    component="time"
                                                                    defaultValue={
                                                                        formProps.values
                                                                            .rescheduleCutoffMinutesAfterMidnight ??
                                                                        1020
                                                                    }
                                                                    label="Time of day"
                                                                    name="rescheduleCutoffMinutesAfterMidnight"
                                                                />
                                                            )}
                                                        </fieldset>
                                                    </>
                                                )}
                                        </>
                                    )}

                                <FormField
                                    description={`Lock each team's roster prior to each ${matchWord.toLowerCase()}`}
                                    name="rosterLocksEnabled"
                                    type="checkbox"
                                />
                                {formProps.values.rosterLocksEnabled && (
                                    <fieldset className="form-group">
                                        <FormField
                                            type="text"
                                            name="rosterLockHours"
                                            description={`Hours before ${matchWord.toLowerCase()} to lock rosters`}
                                            placeholder={`Hours before ${matchWord.toLowerCase()} to lock rosters`}
                                        />
                                    </fieldset>
                                )}

                                {!isLeaderboard && (
                                    <>
                                        {!season.tennisStyle && (
                                            <div className="disp-flex">
                                                <FormField
                                                    description="Use simplified check-in"
                                                    disabled={formProps.values.autoForfeit && !isCreate}
                                                    name="useSimplifiedCheckin"
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        formProps.setFieldValue(
                                                            'useSimplifiedCheckin',
                                                            event.target.checked
                                                        );

                                                        if (event.target.checked) {
                                                            if (formProps.values.autoForfeit)
                                                                toast.info(
                                                                    'Auto-forfeit has been turned off because simplified check-in was enabled.'
                                                                );
                                                            formProps.setFieldValue('autoForfeit', false);
                                                        }
                                                    }}
                                                    type="checkbox"
                                                />
                                                <div>
                                                    <IconButton
                                                        as="button"
                                                        buttonLabel="More info"
                                                        buttonSize="small"
                                                        onClick={() =>
                                                            setShowSimplifiedCheckinInfo(!showSimplifiedCheckinInfo)
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={['fas', 'circle-info']} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        )}
                                        {showSimplifiedCheckinInfo && (
                                            <div className="p">
                                                <p>
                                                    Simplified check-in allows a captain on either team to check in
                                                    their own team or both teams without needing to select any players.
                                                    All check-in requirements (e.g., in-game names) will be skipped and
                                                    all rostered players will be added to the match automatically. Users
                                                    can check into matches at any time.
                                                </p>

                                                <p className="weight-700">
                                                    Simplified check-in and auto-forfeits cannot be enabled at the same
                                                    time.
                                                </p>

                                                <p>
                                                    This setting is typically used for live events where match play is
                                                    faster paced and cellular or wifi connectivity is inconsistent.
                                                </p>
                                            </div>
                                        )}
                                    </>
                                )}

                                {!isLeaderboard && (
                                    <FormField
                                        description={`Require screenshots ${
                                            !!extensions ? 'and/or ' + extensions + ' files ' : ''
                                        } for score submissions`}
                                        name="requireResultScreenshots"
                                        type="checkbox"
                                    />
                                )}

                                <fieldset className="form-group">
                                    <FormField
                                        className="form-field--touched"
                                        component="select"
                                        name="locationId"
                                        label="Stage Location"
                                    >
                                        <option value="">Remote</option>
                                        {selectableLocations.map((l: Core.Models.LeagueLocation) => (
                                            <option key={l.id} value={l.id}>
                                                {l.name}
                                            </option>
                                        ))}
                                        <option value={NEW_LOCATION_ID}>Create New Location</option>
                                    </FormField>
                                    {!!formProps.values.locationId &&
                                        formProps.values.locationId === NEW_LOCATION_ID && (
                                            <>
                                                <FormField
                                                    description="Location Name"
                                                    name="locationName"
                                                    placeholder="Enter Location Name"
                                                    type="text"
                                                />
                                                <FormField
                                                    type="number"
                                                    name="numberOfStations"
                                                    description="# of Stations (optional)"
                                                />
                                            </>
                                        )}
                                </fieldset>

                                {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                                <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                                <fieldset className="form-group form-group--undecorated">
                                    <SolidButton
                                        as="button"
                                        className="full-width"
                                        disabled={formProps.isSubmitting}
                                        onClick={formProps.submitForm}
                                        pending={formProps.isSubmitting}
                                        size="large"
                                    >
                                        {isCreate ? 'Create stage' : 'Save stage'}
                                    </SolidButton>
                                </fieldset>
                            </Form>
                        </ContentContainer>
                        <div style={{ margin: '1.6rem auto', width: 'fit-content' }}>
                            <JsonDebug data={formProps} />
                        </div>
                    </>
                );
            }}
        />
    );
};

export default withLoading(EditStageForm, {
    errorDisplayPageProps: { fullPage: true },
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

import * as axios from 'axios';
import { values } from 'lodash';

import { AppError } from '../../models';
import { RequestConfig } from './types';

export const instance = axios.default;

export const deleteMethod = async <TResult>(url: string, config?: RequestConfig): Promise<TResult> => (await instance.delete(url, config)).data;

export const get = async <TResult>(url: string, config?: RequestConfig): Promise<TResult> => (await instance.get(url, config)).data

export const getAnonymous = async <TResult>(url: string, config?: RequestConfig): Promise<TResult> => (
	await instance.get(
		url,
		Object.assign({}, config || {}, {
			sendAuthentication: false,
		}) as any
	)
).data;

export const getWithResponseDate = async <TResult>(url: string, config?: RequestConfig): Promise<[TResult, Date]> => {
	const response = await instance.get(url, config);
	const data = response.data as TResult;
	if (!response.headers.date) {
		console.warn("Wasn't able to get Date header - maybe an issue with CORS?");
	}
	return [data, (response.headers.date && new Date(response.headers.date)) || new Date()];
}

export const patch = async <TResult>(url: string, payload: any, config?: RequestConfig): Promise<TResult> => (await instance.patch(url, payload, config)).data;

export const patchAnonymous = async <TResult>(url: string, payload: any, config?: RequestConfig): Promise<TResult> => (
	await instance.patch(
		url,
		payload,
		Object.assign({}, config || {}, {
			sendAuthentication: false,
		}) as any
	)
).data;

export const patchWithResponseDate = async <TResult>(url: string, payload: any, config?: RequestConfig): Promise<[TResult, Date]> => {
	const response = await instance.patch(url, payload, config);
	const data = response.data as TResult;
	const date = response.headers.Date || response.headers.date;
	if (!date) {
		console.warn("Wasn't able to get Date header - maybe an issue with CORS?");
	}
	return [data, date || new Date()];
}

export const post = async <TResult>(url: string, payload: any, config?: RequestConfig): Promise<TResult> => (await instance.post(url, payload, config)).data;

export const postAnonymous = async <TResult>(url: string, payload: any, config?: RequestConfig): Promise<TResult> => (
	await instance.post(
		url,
		payload,
		Object.assign({}, config || {}, {
			sendAuthentication: false,
		}) as any
	)
).data;

export const postWithResponseDate = async <TResult>(url: string, payload: any, config?: RequestConfig): Promise<[TResult, Date]> => {
	const response = await instance.post(url, payload, config);
	const data = response.data as TResult;
	const date = response.headers.Date || response.headers.date;
	if (!date) {
		console.warn("Wasn't able to get Date header - maybe an issue with CORS?");
	}
	return [data, date || new Date()];
}

export const put = async <TResult>(url: string, payload: any, config?: RequestConfig): Promise<TResult> => (await instance.put(url, payload, config)).data;

const getRateLimitRetryMessage = (dateString: string, retryAfterDateString?: string) => {
	if (!retryAfterDateString) return '';

	const retryAfter = new Date(retryAfterDateString);
	const date = new Date(dateString);
	const retryAfterSec = Math.ceil((retryAfter.getTime() - date.getTime()) / 1000);
	return `Please try again in ${retryAfterSec} seconds.`;
}

export const getErrorMessage = (error: AppError): string => {
	if (error?.response?.status === 429) {
		return `You're doing that too fast! ${getRateLimitRetryMessage(error.response.headers.date, error.response.headers['retry-after'])}`.trimEnd();
	}

	if (
		[400, 401, 404, 409].includes(error?.response?.status ?? -1) &&
		!!error.response?.data
	) {
		if (typeof error.response.data === 'string')
			return error.response.data;

		const { errors, title } = error.response.data;

		if (!!errors) {
			const message = values(errors).join(', ');

			if (!!message)
				return message;
		}

		if (!!title)
			return title;
	}

	return error?.message ?? 'Something went wrong.';
}

export const getStatus = (error: AppError): number | undefined => error?.response?.status;

import React, { useCallback, useMemo, useState } from 'react';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import { chain, differenceBy } from 'lodash';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import { Button } from '../../components/button';
import ConfirmModal from '../../components/confirmModal';
import ErrorMessage from '../../components/errorMessage';
import FormField from '../../components/formField';
import Loading from '../../components/loading';
import { useCanEditSeason, useLeague, useOrganizationTerm, useUserPermissionService } from '../../hooks/store';
import { StageService } from '../../services/stageService';

import './eligibleStageParticipants.scss';

interface EligibleStageParticipantsProps {
    reloadStage: () => Promise<void>;
    seasonParticipants: Core.Models.Participant[];
    stage: {
        eligibleParticipants: Core.Models.EligibleStageParticipant[];
        id: string;
        seasonId: string;
    };
}

const EligibleStageParticipants = (props: EligibleStageParticipantsProps): JSX.Element => {
    const { reloadStage, seasonParticipants, stage } = props;

    const canEditSeason = useCanEditSeason(stage.seasonId);
    const league = useLeague();
    const organizationTerm = useOrganizationTerm();
    const userPermissionService = useUserPermissionService();

    const [eligibleParticipantToDelete, setEligibleParticipantToDelete] = useState<
        Core.Models.EligibleStageParticipant | undefined
    >(undefined);

    const onCheckIn = useCallback(
        async (eligibleStageParticipantId: string, checkedIn: boolean) => {
            try {
                await StageService.checkIn({ checkedIn, eligibleStageParticipantId });
                toast.success('Successfully checked in');
                await reloadStage();
            } catch {
                toast.error('Unable to check in.  Please try again.');
            }
        },
        [reloadStage]
    );

    const onCreateEligibleParticipant = useCallback(
        async (
            values: Core.Models.CreateEligibleParticipantCommand,
            actions: FormikActions<Core.Models.CreateEligibleParticipantCommand>
        ) => {
            try {
                await StageService.createEligibleParticipant(values);
                toast.success('Created participant');
                await reloadStage();
            } catch {
                toast.error('Unable to create participant.  Please try again.');
            } finally {
                actions.setSubmitting(false);
            }
        },
        [reloadStage]
    );

    const onDeleteEligibleParticipant = useCallback(async () => {
        if (!eligibleParticipantToDelete) return;

        try {
            await StageService.deleteEligibleParticipant(eligibleParticipantToDelete.id);
            await reloadStage();
            toast.success('Deleted participant');
            setEligibleParticipantToDelete(undefined);
        } catch {
            toast.error('Unable to delete participant.  Please try again.');
        }
    }, [eligibleParticipantToDelete, reloadStage]);

    const buildCheckInControls = useCallback(
        (eligibleParticipantId: string, checkedIn: boolean, canEditTeam: boolean) => {
            if (!checkedIn && canEditTeam)
                return <Button onClick={() => onCheckIn(eligibleParticipantId, true)}>Check in</Button>;

            if (checkedIn && canEditSeason)
                return <Button onClick={() => onCheckIn(eligibleParticipantId, false)}>Undo checkin</Button>;
            if (checkedIn) return <span>Checked in</span>;

            return <></>;
        },
        [canEditSeason, onCheckIn]
    );

    const remainingParticipants = useMemo(() => {
        return differenceBy(
            seasonParticipants,
            stage.eligibleParticipants.map((p) => p.participant),
            'id'
        );
    }, [seasonParticipants, stage.eligibleParticipants]);

    if (!league) return <></>;
    return (
        <div className="eligible-participants p2x" id="eligible-participants">
            <div className="eligible-participants__header">
                <h3>Participants</h3>
            </div>
            {stage.eligibleParticipants.length > 0 ? (
                <div className="eligible-participants__items">
                    <div className="eligible-participants__items__header">
                        <div>{organizationTerm}</div>
                        <div>Team</div>
                        <div>Status</div>
                    </div>
                    {chain(stage.eligibleParticipants)
                        .orderBy(
                            [
                                (eligible: Core.Models.EligibleStageParticipant) =>
                                    userPermissionService.hasTeamAccess(
                                        Core.Models.PermissionLevel.Edit,
                                        eligible.participant.teamId
                                    ),
                                (eligible: Core.Models.EligibleStageParticipant) =>
                                    eligible.participant?.name?.toLowerCase(),
                            ],
                            ['desc', 'asc']
                        )
                        .map((eligible: Core.Models.EligibleStageParticipant) => {
                            const canEditTeam = userPermissionService.hasTeamAccess(
                                Core.Models.PermissionLevel.Edit,
                                eligible.participant.teamId
                            );

                            return (
                                <div className="eligible-participants__items__item" key={eligible.id}>
                                    <div>{eligible.participant.organizationName}</div>
                                    <div>
                                        <Link to={`/teams/${eligible.participant.teamId}`}>
                                            {eligible.participant?.name || <em>Deleted team</em>}
                                        </Link>
                                    </div>
                                    <div className="eligible-participants__items__item__controls">
                                        {buildCheckInControls(eligible.id, eligible.checkedIn, canEditTeam)}
                                        {canEditSeason && (
                                            <Button
                                                onClick={() => setEligibleParticipantToDelete(eligible)}
                                                styleType={Core.Models.StyleType.Secondary}
                                            >
                                                Delete
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                        .value()}
                </div>
            ) : (
                <div className="eligible-participants__items__empty">
                    Stage participants have not been locked in yet. Please check back soon.
                </div>
            )}

            {canEditSeason && remainingParticipants.length > 0 && (
                <div className="eligible-participants__add">
                    <Formik<Core.Models.CreateEligibleParticipantCommand>
                        initialValues={{
                            seasonParticipantId: '',
                            stageId: stage.id,
                        }}
                        onSubmit={onCreateEligibleParticipant}
                        render={(formProps: FormikProps<Core.Models.CreateEligibleParticipantCommand>) => (
                            <Form>
                                <fieldset className="form-group">
                                    <div className="form-field form-field--component-select">
                                        <FormField
                                            component="select"
                                            description="Choose a participant"
                                            name="seasonParticipantId"
                                        >
                                            <option hidden disabled value="">
                                                Add a participant
                                            </option>
                                            {remainingParticipants.map((p: Core.Models.Participant) => (
                                                <option key={p.id} value={p.id}>
                                                    {p.name}
                                                </option>
                                            ))}
                                        </FormField>
                                        <input type="hidden" name="stageId" />
                                    </div>
                                </fieldset>

                                {formProps.status && <ErrorMessage error={formProps.status} />}
                                <ErrorMessage error={formProps.errors} filter={formProps.touched} />

                                <fieldset className="form-group form-group--undecorated">
                                    {formProps.isSubmitting && <Loading buttonLoader />}
                                    <Button
                                        disabled={formProps.values.seasonParticipantId === '' || formProps.isSubmitting}
                                        onClick={formProps.submitForm}
                                        round
                                        styleType={Core.Models.StyleType.Primary}
                                        wide
                                    >
                                        Add participant
                                    </Button>
                                </fieldset>
                            </Form>
                        )}
                    />
                </div>
            )}

            {!!eligibleParticipantToDelete && (
                <ConfirmModal
                    confirmText="Yes"
                    onCancel={() => setEligibleParticipantToDelete(undefined)}
                    onConfirm={onDeleteEligibleParticipant}
                    title="Are you sure?"
                >
                    <p>
                        Are you sure you want to delete <em>{eligibleParticipantToDelete.participant.name}</em>?
                    </p>
                </ConfirmModal>
            )}
        </div>
    );
};

export default EligibleStageParticipants;

import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { SolidButton, TextButton } from '../../components/buttons-visuals';
import { TextInput } from '../../components/inputs';
import Modal from '../../components/modal';
import { UserService } from '../../services/userService';
import { logout } from '../../store/login/actions';

interface DeleteAccountProps {
    name: string;
    userId: string;
    username: string;
}

const DeleteAccount = ({ name, userId, username }: DeleteAccountProps): JSX.Element => {
    const dispatch = useDispatch();

    const [confirmUsername, setConfirmUsername] = useState<string>('');
    const [deleteUser, setDeleteUser] = useState<boolean>(false);
    const [isDeletingUser, setIsDeletingUser] = useState<boolean>(false);

    const onDeleteUser = useCallback(async () => {
        setIsDeletingUser(true);
        try {
            await UserService.deleteUser(userId);
            toast.success('Account deleted successfully.');
            dispatch(logout(window.location.origin));
        } catch {
            toast.error('Unable to delete account.');
        } finally {
            setIsDeletingUser(false);
        }
    }, [userId]);

    return (
        <div className="delete-account">
            <TextButton onClick={() => setDeleteUser(true)}>Delete account</TextButton>

            {deleteUser && (
                <Modal onClose={() => setDeleteUser(false)} title={`Delete ${name}?`}>
                    <p>
                        This will permanently delete the account for <strong>{name}</strong>. This action cannot be
                        undone!
                    </p>
                    <p>You will no longer be able to log into this account, and it will be removed from all leagues.</p>
                    <p>
                        Type in the username <strong>{username}</strong> to delete account:
                    </p>
                    <TextInput
                        label="Username"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmUsername(e.target.value)}
                        placeholder="Username"
                    />

                    <SolidButton
                        as="button"
                        className="mt4x"
                        color="destructive"
                        disabled={!confirmUsername || confirmUsername.toLowerCase() !== username.toLowerCase()}
                        layout="full"
                        onClick={onDeleteUser}
                        pending={isDeletingUser}
                    >
                        <FontAwesomeIcon className="mr" icon={['fas', 'trash']} />
                        Delete forever
                    </SolidButton>
                </Modal>
            )}
        </div>
    );
};

export default DeleteAccount;

import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../../core';
import { Button } from '../../../components/button';
import ErrorMessage from '../../../components/errorMessage';
import FormField from '../../../components/formField';
import Loading from '../../../components/loading';
import Modal from '../../../components/modal';
import { ParticipantService } from '../../../services/participantService';

interface SubmitDecksModalProps {
    onClose: () => void;
    participant: Core.Models.Participant;
    reloadSeason: () => Promise<void>;
}

export interface SubmitDecksModalValues {
    deck1: string;
    deck2: string;
    deck3: string;
    deck4: string;
}

const base64Regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
const getDeckSchema = (deckName: string) =>
    Yup.string()
        .required(`${deckName} is required`)
        .test(
            'is-valid',
            `${deckName} format is invalid`,
            (deckCode: string | undefined) => !deckCode || base64Regex.test(deckCode)
        );

const schema = Yup.object().shape({
    deck1: getDeckSchema('Deck 1'),
    deck2: getDeckSchema('Deck 2'),
    deck3: getDeckSchema('Deck 3'),
    deck4: getDeckSchema('Deck 4'),
});

const SubmitDecksModal = (props: SubmitDecksModalProps): JSX.Element => {
    const { onClose, participant, reloadSeason } = props;

    return (
        <Modal onClose={onClose} title={`Submit Deckstrings for ${participant.name}`}>
            <Formik
                initialValues={Object.assign({
                    deck1: '',
                    deck2: '',
                    deck3: '',
                    deck4: '',
                })}
                validationSchema={schema}
                onSubmit={async (values, actions) => {
                    actions.setStatus(undefined);
                    try {
                        const { deck1, deck2, deck3, deck4 } = values;
                        await ParticipantService.submitHearthstoneDecks({
                            decks: [deck1, deck2, deck3, deck4],
                            participantId: participant.id,
                        });
                        await reloadSeason();
                        onClose();
                    } catch (e) {
                        const message = Core.API.getErrorMessage(e);
                        actions.setStatus(message);
                    }
                    actions.setSubmitting(false);
                }}
                render={(formProps) => (
                    <Form className="form">
                        <fieldset className="form-group">
                            <FormField component="text" type="text" name="deck1" description="Deckstring 1" />
                            <FormField component="text" type="text" name="deck2" description="Deckstring 2" />
                            <FormField component="text" type="text" name="deck3" description="Deckstring 3" />
                            <FormField component="text" type="text" name="deck4" description="Deckstring 4" />
                        </fieldset>

                        {formProps.status && <ErrorMessage error={formProps.status} />}
                        <ErrorMessage error={formProps.errors} filter={formProps.touched} />

                        <fieldset className="form-group form-group--undecorated">
                            {formProps.isSubmitting && <Loading buttonLoader />}
                            <Button onClick={formProps.submitForm} disabled={formProps.isSubmitting} wide round>
                                Submit
                            </Button>
                        </fieldset>
                    </Form>
                )}
            />
        </Modal>
    );
};

export default SubmitDecksModal;

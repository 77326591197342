import React from 'react';
import classNames from 'classnames';
import { orderBy, slice, take } from 'lodash';

import * as Core from '../../core';
import { IHasChildren } from '../../layouts/types';
import { LinkButton } from '../button';
import GroupHeader from '../groupHeader';
import ParticipantLink from '../participantLink';

import './index.scss';

const pageSize = 10;

interface SimpleGroupStandingsHeaderProps extends IHasChildren {
    className?: string;
    displayTies?: boolean;
}

const SimpleGroupStandingsHeader = (props: SimpleGroupStandingsHeaderProps): JSX.Element => {
    const { children, className, displayTies } = props;
    return (
        <div className={classNames('simple-group-standings__header', className)}>
            <div className="simple-group-standings__header-label">{children}</div>
            <div className="simple-group-standings__header-wins">Wins</div>
            <div className="simple-group-standings__header-record-separator">-</div>
            <div className="simple-group-standings__header-losses">Losses</div>
            {displayTies && (
                <>
                    <div className="simple-group-standings__header-record-separator--secondary">-</div>
                    <div className="simple-group-standings__header-ties">Ties</div>
                </>
            )}
        </div>
    );
};

const sortOrder: ((gp: Core.Models.GroupParticipant) => any)[] = [
    (gp: Core.Models.GroupParticipant) => gp.outputRank,
    (gp: Core.Models.GroupParticipant) => gp.stageInputRank,
];

/*
This is the group standings component we'll use for showing standings on org/team/user pages
*/
interface SimpleGroupStandingsProps extends IHasChildren {
    externalLinks: boolean;
    group: Core.Models.Group;
    isMyParticipant?: (participant: { teamId?: string }) => boolean;
    participantIdsToAlwaysShow?: string[];
    participantIdsToHighlight?: string[];
    showGroupName?: boolean;
    showStageName?: boolean;
    stage: Core.Models.Stage;
}

const SimpleGroupStandings = (props: SimpleGroupStandingsProps) => {
    const { externalLinks, stage, group, participantIdsToAlwaysShow, participantIdsToHighlight, isMyParticipant } =
        props;
    const participants = orderBy(group.groupParticipants, sortOrder);

    const alwaysShow = participants.filter(
        (gp: Core.Models.GroupParticipant) =>
            participantIdsToAlwaysShow &&
            participantIdsToAlwaysShow.some((participantId: string) => gp.participantId === participantId)
    );
    const notAlwaysShow = participants.filter(
        (gp: Core.Models.GroupParticipant) =>
            !participantIdsToAlwaysShow ||
            !participantIdsToAlwaysShow.some((participantId: string) => gp.participantId === participantId)
    );
    const toShow = orderBy(take(alwaysShow.concat(notAlwaysShow), pageSize), sortOrder);

    const renderItem = (className: string | undefined, gp: Core.Models.GroupParticipant) => {
        return (
            <SimpleGroupStandingsItem
                className={className}
                displayTies={stage.settings.allowMatchTies}
                key={gp.id}
                groupParticipant={gp}
                highlight={
                    participantIdsToHighlight &&
                    participantIdsToHighlight.some((participantId: string) => participantId === gp.participantId)
                }
                myParticipant={isMyParticipant && isMyParticipant(gp)}
            />
        );
    };
    const showAll = (
        <div className="simple-group-standings__participants-show-all">
            <LinkButton
                wide
                styleType={Core.Models.StyleType.OutlineOnly}
                to={`/seasons/${stage.seasonId}/${stage.id}`}
                target={externalLinks ? '_blank' : '_self'}
                rel={externalLinks ? 'noopener noreferrer' : undefined}
            >
                View All
            </LinkButton>
        </div>
    );

    const col1Count = Math.ceil(toShow.length / 2);
    return (
        <div className="simple-group-standings">
            <div className="simple-group-standings-column simple-group-standings--column-1">
                <SimpleGroupStandingsHeader displayTies={stage.settings.allowMatchTies}>
                    <GroupHeader className="simple-group-standings__name" {...props} />
                </SimpleGroupStandingsHeader>
                <div className="simple-group-standings__participants">
                    {take(toShow, col1Count).map(renderItem.bind(null, undefined))}
                    {slice(toShow, col1Count).map(
                        renderItem.bind(null, 'simple-group-standings__participant--column-2')
                    )}
                </div>
                {showAll}
            </div>
            <div className="simple-group-standings-column simple-group-standings--column-2">
                <SimpleGroupStandingsHeader
                    className="simple-group-standings__header--secondary"
                    displayTies={stage.settings.allowMatchTies}
                />
                <div className="simple-group-standings__participants">
                    {slice(toShow, col1Count).map(renderItem.bind(null, undefined))}
                </div>
                {showAll}
            </div>
        </div>
    );
};

interface SimpleGroupStandingsItemProps {
    className?: string;
    displayTies?: boolean;
    groupParticipant: Core.Models.GroupParticipant;
    highlight?: boolean;
    myParticipant?: boolean;
}

const SimpleGroupStandingsItem = (props: SimpleGroupStandingsItemProps) => {
    const { className, displayTies, groupParticipant, highlight, myParticipant } = props;

    return (
        <div
            className={classNames('simple-group-standings__participant', className, {
                'simple-group-standings__participant--highlight': highlight,
                'simple-group-standings__participant--my-participant': myParticipant,
            })}
        >
            <div className="simple-group-standings__participant-rank">
                {groupParticipant.outputRank || groupParticipant.stageInputRank}.
            </div>
            <div className="simple-group-standings__participant-name" title={groupParticipant.name}>
                <ParticipantLink participant={groupParticipant}>{groupParticipant.name}</ParticipantLink>
            </div>
            <div className="simple-group-standings__participant-wins">
                {groupParticipant.winsByForfeit + groupParticipant.winsByPlay || 0}
            </div>
            <div className="simple-group-standings__participant-losses">
                {groupParticipant.lossesByForfeit + groupParticipant.lossesByPlay || 0}
            </div>
            {displayTies && (
                <div className="simple-group-standings__participant-ties">{groupParticipant.ties || 0}</div>
            )}
        </div>
    );
};

export default SimpleGroupStandings;

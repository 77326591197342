import * as Redux from 'redux';

import * as Core from './../../core';
import {
    PermissionState,
    PermissionActionTypes,
    PERMISSIONS_LOADING,
    PERMISSIONS_SUCCESS,
    PERMISSIONS_FAILURE,
} from './types';
import { LOGIN_CLEAR_PRELOADED_DATA } from '../login/types';

const initialState: PermissionState = {
    isLoading: false,
};

const permissionReducer: Redux.Reducer<PermissionState> = (
    state = initialState,
    action: PermissionActionTypes
): PermissionState => {
    switch (action.type) {
        case LOGIN_CLEAR_PRELOADED_DATA:
            return initialState;
        case PERMISSIONS_LOADING:
            return {
                ...state,
                isLoading: true,
                loadingError: undefined,
            };
        case PERMISSIONS_SUCCESS:
            const { payload } = action;
            return {
                ...state,
                isLoading: false,
                permissions: Core.Access.configurePermissions(payload),
                loadingError: undefined,
            };
        case PERMISSIONS_FAILURE:
            return {
                ...state,
                isLoading: false,
                permissions: undefined,
                loadingError: action.payload,
            };
        default:
            return state;
    }
};

export default permissionReducer;

import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as Core from '../../../core';
import OrganizationUser from './organizationUser';
import withLoading, { WithLoadingProps } from '../../../components/withLoading';
import useApiPagination from '../../../hooks/apiPagination';
import { useOrganizationTerm } from '../../../hooks/store';
import { OrganizationService } from '../../../services/organizationService';
import { getPermissions } from '../../../store/permissions/actions';

interface MembersListProps extends WithLoadingProps {
    canEdit: boolean;
    canEditLeague: boolean;
    canViewEmailVerificationLink: boolean;
    canViewPasswordResetLink: boolean;
    chatFeatureEnabled: boolean;
    filter: string;
    managerRoleChanged: () => void;
    organization: Core.Models.Organization;
    shouldShowInviteButtons: boolean;
    userId?: string;
}
const MembersList = (props: MembersListProps): JSX.Element => {
    const {
        canEdit,
        canEditLeague,
        canViewEmailVerificationLink,
        canViewPasswordResetLink,
        chatFeatureEnabled,
        filter,
        managerRoleChanged,
        organization,
        setError,
        setIsLoading,
        shouldShowInviteButtons,
        userId,
    } = props;

    const dispatch = useDispatch();
    const organizationTerm = useOrganizationTerm({ lowercase: true });

    const getPaginatedMembersAsync = useCallback(
        (
            page: number,
            pageSize: number,
            search: string
        ): Promise<Core.Models.PaginatedResult<Core.Models.OrganizationMember>> => {
            return OrganizationService.getMembers(organization.id, Core.Models.OrganizationRoleId.Member, {
                page,
                pageSize,
                search,
            });
        },
        [organization]
    );

    const { paginatedResults, Paginator, selectPage, setSearch, updatePaginatedResults } = useApiPagination(
        getPaginatedMembersAsync,
        setError,
        setIsLoading,
        Core.Constants.PAGINATION.USERS_PER_PAGE
    );

    useEffect(() => setSearch(filter), [filter, setSearch]);

    const removed = useCallback(
        (user: Core.Models.OrganizationMember) => {
            if (!paginatedResults) return;

            if (userId === user.userId) {
                dispatch(getPermissions()); // fire-and-forget
            }
            updatePaginatedResults([
                ...paginatedResults.results.filter((i) => i.userEntityRoleId !== user.userEntityRoleId),
            ]);
        },
        [dispatch, paginatedResults, updatePaginatedResults, userId]
    );

    const chatDisabledChanged = useCallback(
        (user: Core.Models.OrganizationMember, chatDisabled: boolean) => {
            if (!paginatedResults) return;

            updatePaginatedResults([
                ...paginatedResults.results.map((i) => {
                    if (i.userEntityRoleId !== user.userEntityRoleId) {
                        return i;
                    }
                    return { ...i, chatDisabled };
                }),
            ]);
        },
        [paginatedResults, updatePaginatedResults]
    );

    const isEligibleChanged = useCallback(
        (user: Core.Models.OrganizationMember, isEligible: boolean) => {
            if (!paginatedResults) return;

            updatePaginatedResults([
                ...paginatedResults.results.map((i) => {
                    if (i.userEntityRoleId !== user.userEntityRoleId) {
                        return i;
                    }
                    return { ...i, isEligible };
                }),
            ]);
        },
        [paginatedResults, updatePaginatedResults]
    );

    const roleChanged = useCallback(() => {
        if (!paginatedResults) return;

        dispatch(getPermissions()); // fire-and-forget
        selectPage(1); // reload members
        managerRoleChanged(); // tell parent that it needs to reload managers
    }, [dispatch, managerRoleChanged, paginatedResults, selectPage]);

    if (!paginatedResults) return <></>;
    return (
        <>
            <div className="organization-users__list__members">
                {paginatedResults.results.length === 0 ? (
                    <>
                        There are no members of this {organizationTerm}
                        {!filter ? '' : ' that match the provided search term'}.
                    </>
                ) : (
                    paginatedResults.results.map((member: Core.Models.OrganizationMember, index: number) => (
                        <OrganizationUser
                            allowRemove={shouldShowInviteButtons}
                            canEdit={canEdit}
                            canResendInviteLink={canEdit}
                            canViewEmailVerificationLink={canViewEmailVerificationLink}
                            canViewInviteLink={canEditLeague}
                            canViewPasswordResetLink={canViewPasswordResetLink}
                            chatDisabledChanged={chatDisabledChanged.bind(null, member)}
                            chatFeatureEnabled={chatFeatureEnabled}
                            isEligibleChanged={isEligibleChanged.bind(null, member)}
                            key={index}
                            organizationId={organization.id}
                            removed={removed.bind(null, member)}
                            roleChanged={roleChanged}
                            user={member}
                        />
                    ))
                )}
            </div>
            {Paginator}
        </>
    );
};

export default withLoading(MembersList, { loadingProps: { blockItem: true } });

import * as React from 'react';

import * as Core from '../../core';
import { useHasAnyLeagueMembershipRole, useLeague } from '../../hooks/store';
import { LinkButton } from '../button';

import './index.scss';

interface RegistrationHeaderProps {
    formInstructions?: React.ReactNode;
    league?: Core.Models.League;
    message?: React.ReactNode;
    showLoginPrompt?: boolean;
    welcome?: React.ReactNode;
}

const RegistrationHeader: React.FunctionComponent<RegistrationHeaderProps> = (props) => {
    const { formInstructions, message, showLoginPrompt, welcome } = props;
    const league = useLeague();
    const isLeagueMember = useHasAnyLeagueMembershipRole(Core.Models.PermissionLevel.ListSpecific);
    const showContinueButton = !!league && isLeagueMember;

    return (
        <>
            {!!league?.logoUrl && (
                <div className="registration-header__logo">
                    <img src={league.logoUrl} alt={league.name} />
                </div>
            )}
            {welcome && <h2 className="registration-header__welcome">{welcome}</h2>}
            {message && (
                <div className="registration-header__welcome-message">
                    {message}
                    {showLoginPrompt && !showContinueButton && (
                        <div className="registration-header__button-container">
                            <LinkButton to="/login" semiRound>
                                Already a member? Log in here.
                            </LinkButton>
                        </div>
                    )}
                </div>
            )}
            {showContinueButton && (
                <div className="registration-header__button-container">
                    <LinkButton to="/" semiRound>
                        You are already a member of {league!.name}.<br />
                        Click here to continue.
                    </LinkButton>
                </div>
            )}
            {formInstructions && <p className="registration-header__form-instructions">{formInstructions}</p>}
        </>
    );
};

export default RegistrationHeader;

import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../core';
import { ModifyLocationStationFormProps, ModifyLocationStationFormValues } from './types';
import { Button } from '../../components/button';
import FormField from '../formField';
import InfoMessage from '../infoMessage';
import Loading from '../loading';

import './styles.scss';

export const ModifyLocationStationForm = ({ onDelete, onSubmit, values }: ModifyLocationStationFormProps) => {
    return (
        <Formik<ModifyLocationStationFormValues>
            initialValues={Object.assign(
                {
                    description: '',
                    name: '',
                    systemName: '',
                },
                values
            )}
            onSubmit={onSubmit}
            render={({
                errors,
                isSubmitting,
                status,
                submitForm,
                touched,
            }: FormikProps<ModifyLocationStationFormValues>) => (
                <Form className="modify-location-station__modal-form">
                    <fieldset className="form-group">
                        <FormField description="Station Name" name="name" placeholder="Station Name" type="text" />
                        <FormField
                            description="System Name"
                            name="systemName"
                            placeholder="System Name (optional)"
                            type="text"
                        />
                        <FormField
                            component="textarea"
                            description="Additional Details"
                            name="description"
                            placeholder="Additional Details (optional)"
                        />
                    </fieldset>

                    {status && <InfoMessage message={status} type="error" />}
                    <InfoMessage filter={touched} message={errors} type="error" />

                    <fieldset className="form-group form-group--undecorated">
                        {isSubmitting && <Loading buttonLoader />}
                        <Button className="mb" disabled={isSubmitting} onClick={submitForm} wide>
                            Save
                        </Button>
                        {onDelete && (
                            <Button
                                disabled={isSubmitting}
                                onClick={() => onDelete()}
                                styleType={Core.Models.StyleType.Secondary}
                                wide
                            >
                                Remove Station
                            </Button>
                        )}
                    </fieldset>
                </Form>
            )}
            validationSchema={Yup.object().shape({
                description: Yup.string()
                    .max(Core.Constants.MARKDOWN_MAX_LENGTH, 'Station description is too long.')
                    .nullable(),
                name: Yup.string()
                    .max(Core.Constants.NAME_MAX_LENGTH, 'Station name is too long.')
                    .required('Station name is required.'),
                systemName: Yup.string().max(Core.Constants.NAME_MAX_LENGTH, 'System name is too long.').nullable(),
            })}
        />
    );
};

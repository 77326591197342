// Note: borders for the form styles are somewhat brittle and new forms that are added to the project
// should be confirmed to look right, specifically regarding top and left borders and radiuses.
// Styles may need to be updated as more form variations are introduced

import * as React from 'react';
import classNames from 'classnames';
import { FormikContext, Field, FieldConfig, GenericFieldHTMLAttributes, getIn, connect } from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import ReCAPTCHA from 'react-google-recaptcha';

import * as Core from '../../core';
import CurrencyInput, { MaskOptions } from './currencyInput';
import { useTimezone } from '../../hooks/store';
import { Checkbox, Select, TextArea, TextInput } from '../inputs';

import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

export interface FormFieldProps extends FieldConfig {
    contextualProps?: any;
    description?: string;
    error?: boolean;
    instructions?: React.ReactNode;
    label?: React.ReactNode;
    limit?: number;
    success?: boolean;
}

export interface FormFieldPropsWithContext extends FormFieldProps {
    formik: FormikContext<any>;
}

export class FormField extends React.Component<FormFieldPropsWithContext & GenericFieldHTMLAttributes> {
    public render() {
        const { className, description, error, formik, instructions, label, name, placeholder, success, ...rest } =
            this.props;

        const inputLabel = label || description;
        const isErrored = getIn(formik.errors, name) && getIn(formik.touched, name);
        const isTouched = getIn(formik.touched, name);

        return (
            <div
                className={classNames(
                    'form-field',
                    rest.type && `form-field--type-${rest.type}`,
                    typeof rest.component === 'string' && `form-field--component-${rest.component}`,
                    className,
                    {
                        error: isErrored || error,
                        success: success && !isErrored,
                        'form-field--touched': isTouched,
                        'form-field--disabled': rest.disabled,
                    }
                )}
            >
                {instructions}
                <Field
                    error={isErrored || error}
                    id={name}
                    name={name}
                    label={inputLabel}
                    placeholder={placeholder}
                    {...rest}
                    component={this.getComponent()}
                />
            </div>
        );
    }
    private getComponent = () => {
        if (this.props.component && typeof this.props.component !== 'string') {
            return this.props.component;
        }
        switch (this.props.component) {
            case 'currency':
                return CurrencyFormField;
            case 'date':
                return DateFormField;
            case 'datetime':
                return DateTimeFormField;
            case 'recaptcha':
                return RecaptchaFormField;
            case 'select':
                return SelectFormField;
            case 'textarea':
                return TextAreaFormField;
            case 'time':
                return TimeFormField;
            default:
                switch (this.props.type) {
                    case 'checkbox':
                        return CheckboxFormField;
                    default:
                        return InputFormField;
                }
        }
    };
}

interface CurrencyFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    field: React.InputHTMLAttributes<HTMLInputElement>;
    maskOptions?: MaskOptions;
}

// TODO: this isn't quite complete because FormField doesn't allow a passthrough of the `maskOptions` yet
// but at the moment it doesn't matter since we're using USD only and therefore the defaults are sufficient
const CurrencyFormField = (props: CurrencyFormFieldProps): JSX.Element => {
    const { field, maskOptions, onChange, placeholder } = props;
    const { name, onBlur, value } = field;

    return (
        <CurrencyInput
            inputMode="numeric"
            maskOptions={maskOptions}
            type="text"
            {...{ name, onBlur, onChange, placeholder, value }}
        />
    );
};

interface TimeFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    defaultValue?: number;
    field: React.InputHTMLAttributes<HTMLInputElement>;
    label: string;
}

class TimeFormField extends React.Component<TimeFormFieldProps> {
    public render() {
        const {
            defaultValue,
            field: { onChange, onBlur, name, value },
            label,
        } = this.props;
        const duration = moment.duration((value as number | undefined) ?? defaultValue ?? 0, 'minute');
        const selected = moment().startOf('day').add(duration).toDate();
        return (
            <ReactDatePicker
                ariaDescribedBy={this.props['aria-describedby']}
                customInput={<TextInput label={label} />}
                dateFormat="hh:mm aa"
                name={name}
                onBlur={onBlur}
                onChange={(date: Date) => {
                    if (onChange) {
                        const newValue = !!date ? moment(date).diff(moment(date).startOf('day'), 'minute') : undefined;
                        (onChange as any)({ target: { name, value: newValue } });
                    }
                }}
                placeholderText={this.props.placeholder}
                selected={selected}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
            />
        );
    }
}

interface DateTimeFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    field: React.InputHTMLAttributes<HTMLInputElement>;
    label: string;
}

const DateTimeFormField: React.FunctionComponent<DateTimeFormFieldProps> = (props) => {
    const { label } = props;
    const { onChange, onBlur, name, value } = props.field;
    const timezone = useTimezone();

    const date = React.useMemo(
        () => (!!value ? Core.Time.dateToDatePicker(value.toString(), timezone) : undefined),
        [value, timezone]
    );
    return (
        <ReactDatePicker
            ariaDescribedBy={props['aria-describedby']}
            customInput={<TextInput label={label} />}
            dateFormat={`${Core.Time.getReactDatePickerFormat()}  hh:mm aa`}
            name={name}
            onBlur={onBlur}
            onChange={(date: Date) => {
                if (onChange) {
                    const newValue = date ? Core.Time.dateFromDatePicker(date, timezone) : undefined;
                    (onChange as any)({ target: { name, value: newValue } });
                }
            }}
            placeholderText={props.placeholder}
            selected={value ? date : undefined}
            showTimeSelect
            timeIntervals={15}
        />
    );
};

export interface DateFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    contextualProps?: { disabled?: boolean; hidePicker?: boolean };
    field: React.InputHTMLAttributes<HTMLInputElement>;
    label: string;
}

const DateFormField: React.FunctionComponent<DateFormFieldProps> = (props) => {
    const {
        contextualProps,
        field: { name, onBlur, onChange, value },
        label,
    } = props;
    const timezone = useTimezone();

    const date = React.useMemo(
        () => (!!value ? Core.Time.dateToDatePicker(value.toString(), timezone) : undefined),
        [value, timezone]
    );

    return (
        <ReactDatePicker
            ariaDescribedBy={props['aria-describedby']}
            customInput={<TextInput label={label} placeholder={props.placeholder} />}
            id="ds-date-picker"
            dateFormat={[`${Core.Time.getReactDatePickerFormat()}`, `${Core.Time.getReactDatePickerSlashFormat()}`]}
            disabled={!!contextualProps?.disabled}
            name={name}
            onBlur={onBlur}
            onChange={(date: Date) => {
                if (onChange) {
                    const newValue = date ? Core.Time.dateFromDatePicker(date, timezone) : undefined;
                    (onChange as any)({ target: { name, value: newValue } });
                }
            }}
            open={!!contextualProps?.hidePicker ? false : undefined}
            selected={!!value ? date : undefined}
        />
    );
};

function customInputComponentBuilder<P extends React.InputHTMLAttributes<THtmlElement>, THtmlElement>(
    Component: React.JSXElementConstructor<P>
) {
    interface InputFormFieldProps {
        field: React.InputHTMLAttributes<THtmlElement>;
    }

    return class InputFormField extends React.Component<InputFormFieldProps & P> {
        render() {
            const { field, ...rest } = this.props;
            return (
                <Component
                    {...(rest as unknown as P)}
                    {...field}
                    {...(field && field.onChange && rest.onChange && { onChange: this.onChange })}
                />
            );
        }
        private onChange = (e: React.ChangeEvent<THtmlElement>) => {
            const { field, ...rest } = this.props;
            field.onChange!(e);
            rest.onChange!(e);
        };
    };
}

class CheckboxInput extends React.Component<
    React.InputHTMLAttributes<HTMLInputElement> & { form: FormikContext<any> }
> {
    public render() {
        // TODO standardize using checked or value anytime this input is used
        const checked =
            typeof this.props.checked !== 'undefined'
                ? this.props.checked
                : !!get(this.props.form.values, this.props.name || this.props.id!);

        return (
            <Checkbox
                {...this.props}
                checked={checked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (!!this.props.onChange) this.props.onChange(e);
                    this.props.form.setFieldValue(this.props.name || this.props.id!, e.target.checked);
                }}
            />
        );
    }
}

class RecaptchaFormField extends React.Component<
    React.InputHTMLAttributes<HTMLInputElement> & { field: React.InputHTMLAttributes<HTMLInputElement> }
> {
    public render() {
        const { onChange, name } = this.props.field;
        return (
            <div className="recaptcha-container">
                <ReCAPTCHA
                    onChange={(value: string | null) => (onChange as any)?.({ target: { name, value } })}
                    onExpired={() => (onChange as any)?.({ target: { name, value: '' } })}
                    sitekey={Core.Configuration.recaptchaKey}
                    theme="dark"
                />
            </div>
        );
    }
}

const InputFormField = customInputComponentBuilder(TextInput);
const CheckboxFormField = customInputComponentBuilder(CheckboxInput);
const SelectFormField = customInputComponentBuilder(Select);
const TextAreaFormField = customInputComponentBuilder(TextArea);

export default connect<FormFieldProps & GenericFieldHTMLAttributes, FormFieldPropsWithContext>(FormField);

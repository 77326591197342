import React, { ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { IHasChildren } from '../../layouts/types';

import './index.scss';

interface ExpandoProps extends IHasChildren {
    className?: string;
    title: ReactNode;
}

const Expando = ({ children, className, title }: ExpandoProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <div className={classNames('expando', className)}>
            <button className="expando__button" onClick={() => setIsOpen(!isOpen)} type="button">
                {title}{' '}
                {isOpen ? (
                    <FontAwesomeIcon icon={['fas', 'caret-up']} className="ml" />
                ) : (
                    <FontAwesomeIcon icon={['fas', 'caret-up']} flip="vertical" className="ml" />
                )}
            </button>
            {isOpen && <div>{children}</div>}
        </div>
    );
};

export default Expando;

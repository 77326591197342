import React from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { BaseButton, BaseButtonTypes } from '../baseButton';
import './AddButton.scss';

interface AddButtonProps {
    buttonStyle: 'hollow' | 'solid' | 'transparent';
    buttonSize?: 'xlarge' | 'large' | 'medium' | 'small';
    iconSize?: FontAwesomeIconProps['size'];
    layoutInline?: boolean;
}

const AddButton = (props: AddButtonProps & BaseButtonTypes) => {
    const { children, className, buttonSize, buttonStyle, iconSize, layoutInline, ...rest } = props;

    const addButtonClasses = classNames(
        className,
        'ds-add-button',
        `ds-add-button--${buttonStyle}`,
        `ds-add-button--${buttonSize}`,
        {
            'ds-add-button--disabled': props.as === 'button' && props.disabled,
            'ds-add-button--inline': layoutInline,
        }
    );

    return (
        <BaseButton className={addButtonClasses} {...rest}>
            <FontAwesomeIcon className="color-success" icon={['fas', 'circle-plus']} size={iconSize} />
            {children}
        </BaseButton>
    );
};

export default AddButton;

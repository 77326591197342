import * as Core from '../core';

export abstract class StageService {
    public static async getById(stageId: string): Promise<Core.Models.Stage> {
        const route = Core.API.ServerRoute.forAction('stages', stageId);
        return await Core.API.get(route);
    }

    public static async getBasic(id: string): Promise<Core.Models.BasicStage> {
        const route = Core.API.ServerRoute.forAction('stages', `${id}/edit`);
        return await Core.API.get(route);
    }

    public static async create(command: Core.Models.CreateStageCommand): Promise<Core.Models.Stage> {
        const route = Core.API.ServerRoute.forAction('stages');
        return await Core.API.post(route, command);
    }

    public static async edit(command: Core.Models.EditStageCommand): Promise<Core.Models.Stage> {
        const route = Core.API.ServerRoute.forAction('stages');
        return await Core.API.patch(route, command);
    }

    public static async getParticipants(stageId: string): Promise<Core.Models.StageParticipant[]> {
        const route = Core.API.ServerRoute.forAction('stages', `${stageId}/participants`);
        return await Core.API.get(route);
    }

    public static async setParticipants(command: Core.Models.SetStageParticipantsCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('stages', 'participants');
        return await Core.API.put(route, command);
    }

    public static async prepare(id: string): Promise<Core.Models.Group[]> {
        const route = Core.API.ServerRoute.forAction('stages', 'prepare');
        return await Core.API.post(route, { id });
    }

    public static async start(command: Core.Models.StartStageCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('stages', 'start');
        return await Core.API.post(route, command);
    }

    public static async openCheckin(command: Core.Models.OpenStageCheckinCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('stages', 'open-checkin');
        return await Core.API.post(route, command);
    }

    public static async checkIn(command: Core.Models.StageCheckinCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('stages', 'check-in');
        return await Core.API.patch(route, command);
    }

    public static async createEligibleParticipant(
        command: Core.Models.CreateEligibleParticipantCommand
    ): Promise<void> {
        const route = Core.API.ServerRoute.forAction('stages', 'eligible-participants');
        return await Core.API.post(route, command);
    }

    public static async deleteEligibleParticipant(eligibleStageParticipantId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('stages', `eligible-participants/${eligibleStageParticipantId}`);
        return await Core.API.delete(route);
    }

    public static async canComplete(id: string): Promise<Core.Models.CanCompleteStageResponse> {
        const route = Core.API.ServerRoute.forAction('stages', `${id}/canComplete`);
        return await Core.API.get(route);
    }

    public static async complete(id: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('stages', `${id}/complete`);
        return await Core.API.post(route, {});
    }

    public static async delete(id: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('stages', id);
        return await Core.API.delete(route);
    }

    public static async reset(stageId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('stages', `reset`);
        return await Core.API.patch(route, { stageId });
    }

    public static async getStandings(id: string): Promise<Core.Models.Group[]> {
        const route = Core.API.ServerRoute.forAction('stages', `${id}/standings`);
        return await Core.API.get(route);
    }

    public static async getGroupsWithMatches(stageId: string): Promise<Core.Models.Group[]> {
        const route = Core.API.ServerRoute.forAction('stages', `${stageId}/groupsWithMatches`);
        return await Core.API.get(route);
    }

    public static async manuallyBreakTies(command: Core.Models.ManuallyBreakTiesCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('stages', 'manuallybreakTies');
        return await Core.API.post(route, command);
    }

    public static async autoBreakTies(command: Core.Models.AutoBreakTiesCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('stages', 'autoBreakTies');
        return await Core.API.post(route, command);
    }

    public static async announceToStage(request: Core.Models.CreateStageAnnouncementRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('stages', 'announcements');
        await Core.API.post(route, request);
    }

    public static async editAnnouncement(request: Core.Models.EditStageAnnouncementRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('stages', 'announcements');
        await Core.API.patch(route, request);
    }

    public static async deleteAnnouncement(stageAnnouncementId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('stages', `announcements/${stageAnnouncementId}`);
        await Core.API.delete(route);
    }
}

import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';

import * as Core from '../../core';
import GameDetails from './details';
import Loading from '../../components/loading';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { GameService } from '../../services/gameService';

interface GamePageRouteProps {
    id: string;
}

interface GamePageProps extends WithLoadingProps, RouteComponentProps<GamePageRouteProps> {}

const GamePage = (props: GamePageProps) => {
    const {
        match: {
            params: { id },
        },
        setError,
        setIsLoading,
    } = props;
    const [game, setGame] = useState<Core.Models.Game | undefined>(undefined);

    const load = useCallback(async () => {
        try {
            const response = await GameService.get(id);
            setGame(response);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [setError, setIsLoading]);

    useEffect(() => {
        (async () => {
            await load();
        })();
    }, [load]);

    if (!game) return <Loading blockItem />;
    return (
        <div className="page game-details">
            <GameDetails game={game} />
        </div>
    );
};

export default withLoading(GamePage, {
    errorDisplayPageProps: { fullPage: false },
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

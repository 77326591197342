import * as React from 'react';
import classNames from 'classnames';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import * as Core from '../../core';
import SeasonCard from '../../components/seasonCard';

interface EditLeagueSeasonCategoryProps {
    seasonCategoryId: string;
    seasons: Core.Models.Season[];
}

const EditLeagueSeasonCategory: React.FunctionComponent<EditLeagueSeasonCategoryProps> = (props) => {
    const { seasons, seasonCategoryId } = props;

    return (
        <div className="settings-page__season-ordering__category__seasons">
            <Droppable droppableId={seasonCategoryId.toString()}>
                {(providedOuter, snapshotOuter) => (
                    <div
                        ref={providedOuter.innerRef}
                        className={classNames('droppable', snapshotOuter.isDraggingOver && 'hover')}
                    >
                        {seasons.map((season, six) => (
                            <Draggable key={season.id} draggableId={season.id} index={six}>
                                {(providedInner, snapshotInner) => (
                                    <div
                                        ref={providedInner.innerRef}
                                        {...providedInner.draggableProps}
                                        {...providedInner.dragHandleProps}
                                    >
                                        <SeasonCard key={season.id} season={season} />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {providedOuter.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default EditLeagueSeasonCategory;

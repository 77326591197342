import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import * as Core from '../core';

const useApiIncrementalLoader = <TResult,>(
    itemsPerRequest: number,
    loadResultsAsync: (
        incrementalItems: number,
        startTimeUtc?: Date
    ) => Promise<Core.Models.IncrementalResult<TResult>>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const [incrementalResults, setIncrementalResults] = useState<TResult[]>([]);
    const [lastResultTimeUtc, setLastResultTimeUtc] = useState<Date | undefined>(undefined);
    const [remaining, setRemaining] = useState<number>(0);

    const load = useCallback(
        async (itemCount: number, startTimeUtc?: Date) => {
            try {
                setIsLoading(true);

                const data = await loadResultsAsync(itemCount, startTimeUtc);

                setIncrementalResults((currentResults) => [...currentResults, ...data.results]);
                setLastResultTimeUtc(data.lastResultTimeUtc);
                setRemaining(data.remaining);
            } catch (error) {
                toast.error('There was an issue loading data');
            } finally {
                setIsLoading(false);
            }
        },
        [loadResultsAsync, setIncrementalResults, setIsLoading, setLastResultTimeUtc, setRemaining]
    );

    // initial load
    useEffect(() => {
        (async () => {
            await load(itemsPerRequest);
        })();
    }, [itemsPerRequest, load]);

    const loadIncremental = useCallback(async (): Promise<void> => {
        if (!remaining) return;

        await load(itemsPerRequest, lastResultTimeUtc);
    }, [incrementalResults, itemsPerRequest, load, remaining]);

    return {
        incrementalResults,
        loadIncremental,
        remaining,
        setIncrementalResults,
    };
};

export default useApiIncrementalLoader;

import React from 'react';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../core';
import FormField from '../../components/formField';
import { LeagueService } from '../../services/leagueService';
import { SolidButton } from '../buttons-visuals';
import InfoMessage from '../infoMessage';

interface AddHelpfulLinkContainerProps {
    onComplete: () => Promise<void>;
}

interface AddHelpfulLinkFormValues {
    disablePublic: boolean;
    name: string;
    url: string;
}

const schema = Yup.object().shape({
    disablePublic: Yup.boolean(),
    name: Yup.string().required('Name is required').max(Core.Constants.NAME_MAX_LENGTH),
    url: Yup.string()
        .required('Url is required')
        .url('URL field must be a valid URL (including https:// or http:// before)')
        .max(Core.Constants.URL_MAX_LENGTH),
});

const AddHelpfulLinkContainer = ({ onComplete }: AddHelpfulLinkContainerProps) => {
    const onSubmit = async (values: AddHelpfulLinkFormValues, actions: FormikActions<AddHelpfulLinkFormValues>) => {
        try {
            await LeagueService.addLeagueHelpfulLink(values);
            await onComplete();
        } catch (error) {
            const message = Core.API.getErrorMessage(error);
            actions.setStatus(message);
        } finally {
            actions.setSubmitting(false);
        }
    };

    return (
        <div className="add-helpful-link">
            <Formik
                initialValues={{ disablePublic: false, name: '', url: '' }}
                onSubmit={onSubmit}
                render={(props: FormikProps<AddHelpfulLinkFormValues>) => (
                    <Form>
                        <FormField type="text" name="name" description="Link name" placeholder="Link name" />
                        <FormField type="url" name="url" description="Link URL" placeholder="Link URL" />
                        <FormField type="checkbox" name="disablePublic" description="League members only" />

                        {props.status && <InfoMessage message={props.status} type="error" />}
                        <InfoMessage filter={props.touched} message={props.errors} type="error" />

                        <SolidButton
                            as="button"
                            layout="full"
                            onClick={props.submitForm}
                            pending={props.isSubmitting}
                            size="medium"
                        >
                            Add helpful link
                        </SolidButton>
                    </Form>
                )}
                validationSchema={schema}
            />
        </div>
    );
};

export default AddHelpfulLinkContainer;

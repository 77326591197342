import { RefObject } from 'react';

const getOverflowPositions = (overflowRef: Element) => {
    const overflowParent = document.querySelector('.main-content');
    if (overflowRef && overflowParent) {
        const overflowParentBounds = overflowParent.getBoundingClientRect();
        return {
            top: overflowParentBounds.top,
            right: overflowParentBounds.right,
            bottom: overflowParentBounds.bottom,
            left: overflowParentBounds.left,
        };
    }
    return {
        top: 0,
        right: window.innerWidth,
        bottom: window.innerHeight,
        left: 0,
    };
};

export const setOverflowPosition = (bubbleRef: RefObject<any>, defaultPlacement: string) => {
    if (bubbleRef.current) {
        const bubblePositions = bubbleRef.current?.firstElementChild?.getBoundingClientRect();
        const { top, right, bottom, left } = getOverflowPositions(bubbleRef.current);

        if (bubblePositions) {
            const isOverflowTop = bubblePositions.top < top;
            const isOverflowRight = bubblePositions.right > right;
            const isOverflowBottom = bubblePositions.bottom > bottom;
            const isOverflowLeft = bubblePositions.left < left;

            const hPlacement = isOverflowLeft ? 'left' : isOverflowRight ? 'right' : 'center';
            const vPlacement = isOverflowTop ? 'bottom' : isOverflowBottom ? 'top' : defaultPlacement;

            return {
                hPlacement: hPlacement,
                vPlacement: vPlacement,
                isPositioned: true,
            };
        }
    }
    return;
};

import { ConsentState } from '../store/consent/types';

const LOCAL_STORAGE_CONSENT_PREFERENCES = 'consent_preferences';

export default class ConsentService {
    public static getConsent(): ConsentState | undefined {
        const config = localStorage.getItem(LOCAL_STORAGE_CONSENT_PREFERENCES);
        if (!!config) {
            return JSON.parse(config);
        }
        return undefined;
    }

    public static setConsent(preferences: ConsentState) {
        localStorage.setItem(LOCAL_STORAGE_CONSENT_PREFERENCES, JSON.stringify({ ...preferences }));
    }
}

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import { SolidButton } from '../../components/buttons-visuals';
import Markdown from '../../components/markdown';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useLeague } from '../../hooks/store';
import { AuthenticationService } from '../../services/authenticationService';
import history from '../../services/history';
import { UserService } from '../../services/userService';
import { preloadData } from '../../store/login/actions';

interface VerifyEmailRouteProps {
    token: string;
}

interface VerifyEmailProps extends RouteComponentProps<VerifyEmailRouteProps>, WithLoadingProps {}

const VerifyEmail = ({
    match: {
        params: { token },
    },
    setError,
    setIsLoading,
}: VerifyEmailProps): JSX.Element => {
    const [isFulfilled, setIsFulfilled] = useState<boolean | undefined>(undefined);
    const [isParentToken, setIsParentToken] = useState<boolean | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const league = useLeague();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            try {
                const response = await UserService.getVerificationToken({ token });
                setIsFulfilled(response.isFulfilled);
                setIsParentToken(response.isParentToken);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    const redirect = useCallback(() => {
        isParentToken ? history.push(`/`) : history.push(`/login`);
    }, [isParentToken]);

    const submit = useCallback(async () => {
        try {
            setIsSubmitting(true);
            await UserService.verifyEmail({ token });

            isParentToken
                ? toast.success('Parent consent received!')
                : toast.success('Email address was successfully verified!');

            if (AuthenticationService.isAuthenticated()) {
                dispatch(preloadData()); // reload roles/profile
            }

            redirect();
        } catch (err) {
            isParentToken
                ? toast.error('There was an issue submitting your consent. Please try again.')
                : toast.error('There was an issue verifying your email. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    }, [dispatch, isParentToken, redirect, token]);

    useEffect(() => {
        (async () => {
            if (isFulfilled === undefined || isParentToken === undefined) return;
            if (isFulfilled) return redirect();
            if (!isParentToken) await submit();
        })();
    }, [isFulfilled, isParentToken, redirect, submit]);

    const defaultU13Terms = `Your child registered to use the ${Core.Constants.Company} website and provided your information as their parent or legal guardian.  ${Core.Constants.Company} is an interactive platform for players to participate in esports tournaments.  In compliance with the Children's Online Privacy Protection Act (COPPA), parents (or legal guardians) of children under 13 years of age must consent to collections, uses and disclosures of the personal information of their children collected by ${Core.Constants.Company} and its affiliates on the ${Core.Constants.Company} websites.  Your permission is required for the collection, use, or disclosure of your child's personal information due to their age. We will not grant your child access to the ${Core.Constants.Company} website unless you provide ${Core.Constants.Company} with consent.  You may request to opt out or have your child's information deleted at any time upon request.  Further information pertaining to the collection, use and storage of data can be found within ${Core.Constants.Company}'s [Terms of Use](${Core.Constants.TERMS_OF_SERVICE_URL}) and [Privacy Policy](${Core.Constants.PRIVACY_POLICY_URL}). By pressing the button below, you give your child permission to fully participate and interact with the ${Core.Constants.Company} website and confirm that you agree to comply with the ${Core.Constants.Company} website [Terms of Use](${Core.Constants.TERMS_OF_SERVICE_URL}) and [Privacy Policy](${Core.Constants.PRIVACY_POLICY_URL}).`;

    if (isFulfilled === undefined || isParentToken === undefined) return <></>;
    if (!isParentToken) return <></>;
    return (
        <div className="text-center p2x mt4x">
            <h4>Under 13 Consent Terms</h4>
            <Markdown className="text-justify" source={league?.u13ConsentTerms || defaultU13Terms} />
            <SolidButton as="button" className="mt2x" onClick={submit} pending={isSubmitting}>
                Submit Consent
            </SolidButton>
        </div>
    );
};

export default withLoading(VerifyEmail, {
    errorDisplayPageProps: { fullPage: true },
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

import * as Core from './../core';
import { AuthenticationService } from './authenticationService';
import { RegistrationFormValues } from '../components/registrationForm';
import { CreateOrganizationFormValues } from '../pages/createOrganization';
import { InviteTokenFormValues } from '../pages/inviteToken';
import { JoinOrganizationFormValues } from '../pages/joinOrganization';
import { JoinSeasonFormValues } from '../pages/joinSeason';
import AnalyticsService from './analyticsService';

export abstract class UserService {
    public static async login(loginUserCommand: Core.Models.LoginUserCommand): Promise<Core.Models.LoginResponse> {
        const route = Core.API.ServerRoute.forAction('users', Core.API.getLeagueId() ? 'login' : 'platformLogin');
        return await Core.API.postAnonymous(route, loginUserCommand, Core.API.ApiVersion.v2, {
            globalErrorHandling: false,
        });
    }

    public static async validateEmail(
        email: string,
        organizationId?: string
    ): Promise<Core.Models.IdentifierValidationResult> {
        const route = Core.API.ServerRoute.forAction('users', 'validateEmail');
        return await Core.API.getAnonymous(route, undefined, {
            email,
            organizationId,
        });
    }

    public static async validateUsername(
        action: Core.Models.RegistrationAction,
        username: string,
        organizationId?: string
    ): Promise<Core.Models.IdentifierValidationResult> {
        const route = Core.API.ServerRoute.forAction('users', 'validateUsername');
        return await Core.API.getAnonymous(route, undefined, {
            action,
            organizationId,
            username,
        });
    }

    public static logout(): void {
        AuthenticationService.clearToken();
    }

    public static async getMyRoles(): Promise<Core.Models.AssignedRoles> {
        const route = Core.API.ServerRoute.forAction('users', 'me/roles');
        return await Core.API.get(route);
    }

    public static async getMyPlatformRoles(): Promise<Core.Models.AssignedRoles> {
        const route = Core.API.ServerRoute.forAction('users', 'platform/me/roles');
        return await Core.API.get(route);
    }

    public static async join(request: InviteTokenFormValues): Promise<Core.Models.RegistrationResponse> {
        const route = Core.API.ServerRoute.forAction('users', 'join');
        AnalyticsService.register();
        return await Core.API.postAnonymous(route, request);
    }

    public static async joinPlatform(request: RegistrationFormValues): Promise<Core.Models.RegistrationResponse> {
        const route = Core.API.ServerRoute.forAction('users', 'join-platform');
        AnalyticsService.register();
        return await Core.API.postAnonymous(route, request);
    }

    public static async joinOrganization(
        request: JoinOrganizationFormValues
    ): Promise<Core.Models.RegistrationResponse> {
        const route = Core.API.ServerRoute.forAction('users', 'join/organization');
        AnalyticsService.register();
        return await Core.API.postAnonymous(route, request);
    }

    public static async joinSeason(request: JoinSeasonFormValues): Promise<Core.Models.RegistrationResponse> {
        const route = Core.API.ServerRoute.forAction('users', 'join/season');
        AnalyticsService.register();
        return await Core.API.post(route, request); // posting as authenticated user in case user is currently logged in
    }

    public static async createOrganization(
        request: CreateOrganizationFormValues
    ): Promise<Core.Models.RegistrationResponse> {
        const route = Core.API.ServerRoute.forAction('users', 'organization');
        AnalyticsService.register();
        return await Core.API.postAnonymous(route, request);
    }

    public static async verifyEmail(values: Core.Models.VerifyUserEmailCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('users', `verifyEmail`);
        return await Core.API.postAnonymous(route, values);
    }

    public static async getCompetitionReadyStatus(): Promise<Core.Models.GetCompetitionReadyStatusResponse> {
        const route = Core.API.ServerRoute.forAction('users', `competitionReadyStatus`);
        return await Core.API.get(route);
    }

    public static async getVerificationToken(
        values: Core.Models.VerifyUserEmailCommand
    ): Promise<Core.Models.VerifyUserEmailResponse> {
        const route = Core.API.ServerRoute.forAction('users', `verificationToken/${values.token}`);
        return await Core.API.getAnonymous(route);
    }

    public static async resendVerificationEmail(values: Core.Models.ResendVerifyUserEmailCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('users', `resendVerificationEmail`);
        return await Core.API.postAnonymous(route, values);
    }

    public static async validateJoin(code: string): Promise<Core.Models.ValidateTokenResponse> {
        const route = Core.API.ServerRoute.forAction('users', `join/validate/${code}`);
        return await Core.API.getAnonymous(route);
    }

    public static async forgotPassword(username: string) {
        const route = Core.API.ServerRoute.forAction('users', 'forgotPassword');
        return await Core.API.patchAnonymous(route, { username });
    }

    public static async forgotUsername(email: string) {
        const route = Core.API.ServerRoute.forAction('users', 'forgotUsername');
        return await Core.API.patchAnonymous(route, { email });
    }

    public static async getProfile(id: string): Promise<Core.Models.UserProfile> {
        const route = Core.API.ServerRoute.forAction('users', id);
        return await Core.API.get(route);
    }

    public static async getPlatformProfile(id: string): Promise<Core.Models.UserProfile> {
        const route = Core.API.ServerRoute.forAction('users', `platform/${id}`);
        return await Core.API.get(route);
    }

    public static async getMyProfile(): Promise<Core.Models.MyUserProfile> {
        const route = Core.API.ServerRoute.forAction('users', 'me');
        return await Core.API.get(route);
    }

    public static async getMyPlatformProfile(): Promise<Core.Models.MyUserProfile> {
        const route = Core.API.ServerRoute.forAction('users', 'platform/me');
        return await Core.API.get(route);
    }

    public static async editProfile(values: Core.Models.EditUserProfileCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('users');
        return await Core.API.patch(route, values);
    }

    public static async addGameHandle(command: Core.Models.AddGameHandleCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('users', 'gamehandle');
        return await Core.API.post(route, command);
    }

    public static async deleteGameHandle(userGameHandleId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('users', `gamehandle/${userGameHandleId}`);
        return await Core.API.delete(route);
    }

    public static async addGameRanking(command: Core.Models.AddGameRankingCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('users', 'gameranking');
        return await Core.API.post(route, command);
    }

    public static async editGameRanking(request: Core.Models.EditGameRankingRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('users', `gameranking`);
        return await Core.API.patch(route, request);
    }

    public static async deleteGameRanking(userGameMetadataId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('users', `gameranking/${userGameMetadataId}`);
        return await Core.API.delete(route);
    }

    public static async uploadAvatar(values: Core.Models.UploadLogoCommand): Promise<string> {
        const route = Core.API.ServerRoute.forAction('users', 'avatar');
        return await Core.API.post(route, values);
    }

    public static async resetPassword(values: Core.Models.ResetPasswordValues) {
        const route = Core.API.ServerRoute.forAction('users', 'resetPassword');
        return await Core.API.patchAnonymous(route, values);
    }

    public static async getTeams(id: string): Promise<Core.Models.Team[]> {
        const route = Core.API.ServerRoute.forAction('users', `${id}/teams`);
        return await Core.API.get(route);
    }

    public static async changePassword(command: Core.Models.ChangePasswordCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('users', 'changePassword');
        return await Core.API.patch(route, command);
    }

    public static async getMatches(id: string, type: Core.Models.UserMatchListType): Promise<Core.Models.UserMatches> {
        const route = Core.API.ServerRoute.forAction('users', `${id}/matches/${type}`);
        return await Core.API.get(route);
    }

    public static async generatePasswordResetLink(userId: string): Promise<string> {
        const route = Core.API.ServerRoute.forAction('users', `resetLink`);
        return await Core.API.post(route, { userId });
    }

    public static async getMyDiscordStatus(): Promise<Core.Models.MyDiscordStatus> {
        const route = Core.API.ServerRoute.forAction('users', `me/discordStatus`);
        return await Core.API.get(route);
    }

    public static async updateCompetitionPreferences(options: {
        hidePublicGameProfiles?: boolean;
        showDiscordCompetingStatus?: boolean;
        userId: string;
    }): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`users`, `competitionPreferences`);
        return await Core.API.patch(route, options);
    }

    public static async updateNotificationPreferences(options: {
        sendDiscordReminders?: boolean;
        sendEmailReminders?: boolean;
        userId: string;
    }): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`users`, `notificationPreferences`);
        return await Core.API.patch(route, options);
    }

    public static async updateGameInterests(values: Core.Models.UpdateUserGameInterestsCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`users`, `gameInterests`);
        return await Core.API.patch(route, values);
    }

    public static async addBirthdate(values: Core.Models.AddBirthdateCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`users`, `birthdate`);
        return await Core.API.post(route, values);
    }

    public static async setChatAbility(values: Core.Models.SetChatAbilityRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`users`, `chat`);
        return await Core.API.post(route, values);
    }

    public static async getUserChatStatus(
        userId: string
    ): Promise<{ hasOrganizationRole: boolean; isEnabled: boolean }> {
        const route = Core.API.ServerRoute.forAction(`users`, `${userId}/chat`);
        return await Core.API.get(route);
    }

    public static async dismissNotifier(payload: { userId: string }): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`users`, `dismissNotifier`);
        return await Core.API.patch(route, payload);
    }

    public static async getUserJwt(request: { userId: string }): Promise<string> {
        const route = Core.API.ServerRoute.forAction(`users`, `impersonate`);
        return await Core.API.post(route, request);
    }

    public static async getIdentityVerificationDetails(
        userId: string
    ): Promise<{ submission: Core.Models.IdentityVerificationSubmissionDetails }> {
        const route = Core.API.ServerRoute.forAction(`users`, `identityVerificationDetails`);
        return await Core.API.get(route, undefined, { userId });
    }

    public static async getIdentityVerificationsSummary(
        request: Core.Models.GetIdentityVerificationSubmissionsSummaryRequest
    ): Promise<Core.Models.GetIdentityVerificationSubmissionsSummaryResponse> {
        const route = Core.API.ServerRoute.forAction(`users`, `identityVerificationsSummary`);
        return await Core.API.get(route, undefined, request);
    }

    public static async submitIdentityVerification(request: {
        imageString: string;
        notes: string;
        userId: string;
    }): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`users`, `identityVerification`);
        return await Core.API.post(route, request);
    }

    public static async updateUserIdentityVerification(
        request: Core.Models.UpdateUserIdentityVerificationSubmissionRequest
    ): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`users`, `identityVerification`);
        return await Core.API.patch(route, request);
    }

    public static async disableUserMfa(request: Core.Models.DisableUserMfaRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`users`, `mfa/disableMfa`);
        return await Core.API.post(route, request);
    }

    public static async enableUserMfa(
        request: Core.Models.EnableUserMfaRequest
    ): Promise<Core.Models.EnableUserMfaResponse> {
        const route = Core.API.ServerRoute.forAction(`users`, `mfa/enableMfa`);
        return await Core.API.post(route, request);
    }

    public static async generateOtpLink(): Promise<Core.Models.GenerateOtpLinkResponse> {
        const route = Core.API.ServerRoute.forAction(`users`, `mfa/generateOtpLink`);
        return await Core.API.post(route, undefined);
    }

    public static async getUsers(
        paginatedOptions: Core.Models.PaginatedOptions
    ): Promise<Core.Models.PaginatedResult<Core.Models.UserSearch.Result>> {
        const route = Core.API.ServerRoute.forAction('users', 'search');
        return await Core.API.get(route, Core.API.ApiVersion.v1, paginatedOptions, {
            headers: { 'X-Cross-League': true },
        });
    }

    public static async deleteUser(userId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('users', userId);
        return await Core.API.delete(route);
    }
}

import React, { useMemo } from 'react';
import classNames from 'classnames';
import { isNumber, orderBy, take } from 'lodash';

import * as Core from '../../../core';
import CompetitionGroupItem from './competitionGroupItem';
import { CompetitionMatchupProps } from '..';
import { useCanEditSeason } from '../../../hooks/store';
import { AddButton, HollowButton } from '../../buttons-visuals';

const NUM_PARTICIPANTS_TO_SHOW_PREVIEW = 5;

export const CompetitionGroup = (props: CompetitionMatchupProps): JSX.Element => {
    const {
        canEdit,
        className,
        enableHighlight,
        isMyParticipant,
        isPreview,
        location,
        match,
        matchParticipants,
        onAdd,
        onForfeit,
        onRemove,
        onStationSelect,
    } = props;

    const canEditSeason = useCanEditSeason(match.season.id);

    const showScore = useMemo(
        () => matchParticipants.length > 1 && match.currentState >= Core.Models.MatchState.InProgress,
        [match, matchParticipants]
    );
    const isFull = useMemo(
        () => isNumber(match.game?.maximumLobbySize) && matchParticipants.length >= match.game?.maximumLobbySize!,
        [match.game?.maximumLobbySize, matchParticipants.length]
    );
    const orderedMatchParticipants = useMemo(() => {
        const ordered = orderBy(
            matchParticipants,
            [
                (mp: Core.Models.MatchParticipant) => mp.isParticipating,
                (mp: Core.Models.MatchParticipant) => !mp.hasForfeited,
                (mp: Core.Models.MatchParticipant) => isNumber(mp.score),
                (mp: Core.Models.MatchParticipant) => mp.score,
                (mp: Core.Models.MatchParticipant) => mp.sortOrder,
            ],
            ['desc', 'desc', 'desc', 'desc']
        );
        if (isPreview) return take(ordered, NUM_PARTICIPANTS_TO_SHOW_PREVIEW);
        return ordered;
    }, [isPreview, matchParticipants]);

    const showViewAll = useMemo(
        () => isPreview && matchParticipants.length > NUM_PARTICIPANTS_TO_SHOW_PREVIEW,
        [isPreview, matchParticipants.length]
    );

    const showAddMoreTeams = useMemo(() => {
        if (!canEditSeason) return false;
        if (isPreview) return false;
        if (isFull) return false;
        if (match.currentState >= Core.Models.MatchState.InProgress) return false;
        return true;
    }, [canEditSeason, isFull, isPreview, match.currentState]);

    return (
        <div className="competition-group-wrapper full-width">
            <div className={classNames('competition-group', className)}>
                {orderedMatchParticipants.map((participant: Core.Models.MatchParticipant) => (
                    <CompetitionGroupItem
                        key={participant.id}
                        {...{
                            canEdit: !!canEdit,
                            canEditSeason,
                            enableHighlight,
                            game: match.game,
                            isMyParticipant,
                            isPreview: !!isPreview,
                            location,
                            matchCurrentState: match.currentState,
                            onForfeit,
                            onRemove,
                            onStationSelect,
                            participant,
                            scoringType: match.scoringType,
                            showScore,
                        }}
                    />
                ))}
            </div>
            {(showAddMoreTeams || showViewAll) && (
                <div className="disp-flex align-center justify-center flex-gap mt2x">
                    {showViewAll && (
                        <HollowButton
                            as="link"
                            className="competition-group__view-all"
                            color="secondary"
                            size="small"
                            to={`/matches/${match.id}`}
                        >
                            View all
                        </HollowButton>
                    )}
                    {showAddMoreTeams && (
                        <AddButton
                            as="button"
                            buttonStyle="solid"
                            buttonSize="small"
                            className="competition-group__item add-team-item mb4x"
                            layoutInline
                            onClick={onAdd}
                        >
                            Add more teams
                        </AddButton>
                    )}
                </div>
            )}
        </div>
    );
};

export default CompetitionGroup;

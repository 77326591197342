import * as React from 'react';
import * as _ from 'lodash';

import * as Core from '../../core';
import AnalyticsService from '../../services/analyticsService';
import { errorHandlerHOC } from '../error/errorHandlerHOC';

interface DefaultLoadingProps {
    isLoading: boolean;
}
interface DefaultLoadingErrorProps {
    loadingError?: Core.Models.AppError;
}
interface DefaultReloadDataProps {
    reloadData?: () => Promise<void>;
}

/**
 * @deprecated Use `withLoading` instead
 * Wraps a component to provide dynamic data-load functionality.  This uses isLoading, loadingError, and reloadData for the status, error value, and reload callback respectively.  Use the withLoadData to supply them yourself.
 * @param WrappedComponent The component to wrap
 * @param createDataLoadProps Given the component properties, returns the parts of it used to load data (ie. if these change, data will be reloaded)
 * @param loadData Given the return value of createDataLoadProps, returns a promise to the loaded data (the properties of the object will be pass as-is to the wrapped component)
 */
export function withLoadDataDefaultConfig<
    TComponentProps extends TDataResultProps | DefaultLoadingProps | DefaultLoadingErrorProps | DefaultReloadDataProps,
    TDataLoadProps,
    TDataResultProps,
>(
    WrappedComponent: React.ComponentType<TComponentProps>,
    createDataLoadProps: (props: TComponentProps) => TDataLoadProps,
    loadData: (dataLoadProps: TDataLoadProps, props: TComponentProps, reload: boolean) => Promise<TDataResultProps>
) {
    return withLoadData(
        WrappedComponent,
        createDataLoadProps,
        loadData,
        (isLoading: boolean) => {
            return { isLoading } as DefaultLoadingProps;
        },
        (loadingError?: Core.Models.AppError) => {
            return { loadingError } as DefaultLoadingErrorProps;
        },
        (reloadData: () => Promise<void>) => {
            return { reloadData } as DefaultReloadDataProps;
        }
    );
}

/**
 * Wraps a component to provide dynamic data-load functionality.
 * @param WrappedComponent The component to wrap
 * @param createDataLoadProps Given the component properties, returns the parts of it used to load data (ie. if these change, data will be reloaded)
 * @param loadData Given the return value of createDataLoadProps, returns a promise to the loaded data (the properties of the object will be pass as-is to the wrapped component)
 * @param createLoadingProps Given the true/false value for whether a load is in progress, returns the object to be merged to props passed to WrappedComponent.
 * @param createLoadingErrorProps Given the error resulting from a load, returns the object to be merged to props passed to WrappedComponent.
 * @param createReloadDataProps Given the reloadData callback, returns the object to be merged to props passed to WrappedComponent.
 */
export default function withLoadData<
    TComponentProps extends TDataResultProps | TLoadingProps | TLoadingErrorProps | TReloadDataProps,
    TDataLoadProps,
    TDataResultProps,
    TLoadingProps,
    TLoadingErrorProps,
    TReloadDataProps,
>(
    WrappedComponent: React.ComponentType<TComponentProps>,
    createDataLoadProps: (props: TComponentProps) => TDataLoadProps,
    loadData: (dataLoadProps: TDataLoadProps, props: TComponentProps, reload: boolean) => Promise<TDataResultProps>,
    createLoadingProps: (isLoading: boolean) => TLoadingProps,
    createLoadingErrorProps: (error?: Core.Models.AppError) => TLoadingErrorProps,
    createReloadDataProps: (callback: () => Promise<void>) => TReloadDataProps
) {
    class WithLoadData extends React.Component<TComponentProps, any> {
        constructor(props: TComponentProps) {
            super(props);
            // start with no data, no error, but loading 'in progress' (even though it won't really start until componentDidMount - this helps with loading indicators)
            this.state = Object.assign(
                { __data: undefined, __loadingValue: undefined },
                createLoadingProps(true),
                createLoadingErrorProps(undefined),
                createReloadDataProps(this.reloadData)
            );
        }
        public componentDidMount() {
            this.reloadData();
        }
        public componentDidUpdate(prevProps: TComponentProps) {
            if (this.props && prevProps) {
                const dataProps = createDataLoadProps(this.props);
                const prevDataProps = createDataLoadProps(prevProps);
                if (!_.isEqual(dataProps, prevDataProps)) {
                    this.fetchData(dataProps);
                }
            }
        }
        public render() {
            const { __data, ...rest } = this.state;
            return <WrappedComponent {...this.props} {...rest} {...(__data || {})} />;
        }
        private reloadData = async (showLoading: boolean = true, reload: boolean = false) => {
            await this.fetchData(createDataLoadProps(this.props), showLoading, reload);
        };
        private fetchData = (dataLoadProps: TDataLoadProps, showLoading: boolean = true, reload: boolean = false) => {
            const loadingData = Math.random();
            return new Promise((resolve, reject) => {
                this.setState(
                    {
                        __loadingValue: loadingData,
                        ...(showLoading ? { __data: undefined } : {}),
                        ...createLoadingProps(showLoading),
                        ...createLoadingErrorProps(undefined),
                    },
                    async () => {
                        try {
                            const data = await loadData(dataLoadProps, this.props, reload);
                            if (this.state.__loadingValue !== loadingData) {
                                resolve(undefined);
                                return;
                            }
                            this.setState(
                                Object.assign(
                                    { __data: data },
                                    createLoadingProps(false),
                                    createLoadingErrorProps(undefined)
                                ),
                                () => resolve(undefined)
                            );
                        } catch (e) {
                            AnalyticsService.exception(e);
                            console.error(e);
                            this.setState(
                                Object.assign(
                                    { __data: undefined },
                                    createLoadingProps(false),
                                    createLoadingErrorProps(e)
                                ),
                                () => reject(e)
                            );
                        }
                    }
                );
            });
        };
    }
    return errorHandlerHOC(WithLoadData as any) as React.ComponentType<
        Pick<
            TComponentProps,
            Exclude<
                keyof TComponentProps,
                keyof TDataResultProps | keyof TLoadingProps | keyof TLoadingErrorProps | keyof TReloadDataProps
            >
        >
    >;
}

import React, { useState } from 'react';

import * as Core from '../../../core';
import FreeAgentList from './freeAgentList';
import { Filter } from '../../../components/inputs';

import './index.scss';

export interface SeasonFreeAgentsProps {
    canEditSeason: boolean;
    season: Core.Models.Season;
}

const SeasonFreeAgents = ({ canEditSeason, season }: SeasonFreeAgentsProps) => {
    const [filter, setFilter] = useState<string>('');

    if (!canEditSeason) return <></>;
    if (!season.allowFreeAgents) return <></>;
    if (season.currentState > Core.Models.CompetitionState.NotStarted) return <></>;
    return (
        <div className="season-free-agents">
            <h2 className="mb2x">Free Agents</h2>
            <Filter
                className="fit-content"
                debounceMs={Core.Constants.FORM_DEBOUNCE_TIME_MS}
                label="Find a free agent"
                setValue={setFilter}
                value={filter}
            />
            <FreeAgentList filter={filter} season={season} />
        </div>
    );
};

export default SeasonFreeAgents;

import React from 'react';
import { Link } from 'react-router-dom';

import * as Core from '../../core';
import { ReactComponent as NewEmailIcon } from '../../assets/images/icons/new-email.svg';
import useIsFeatureGroup from '../../hooks/isFeatureGroup';
import { useLeague } from '../../hooks/store';
import galaxyRacerImg from '../../pages/customLandings/hergalaxy/assets/galaxyracer.png';
import herGalaxyImg from '../../pages/customLandings/hergalaxy/assets/hergalaxy.png';
import heroImg from '../../pages/customLandings/hergalaxy/assets/hero-alternate.png';
import verificationCenterImg from '../../pages/customLandings/hergalaxy/assets/verification-center.png';
import verificationLeftImg from '../../pages/customLandings/hergalaxy/assets/verification-left.png';
import verificationRightImg from '../../pages/customLandings/hergalaxy/assets/verification-right.png';

import './index.scss';

const EmailVerificationSentPage = () => {
    const isHerGalaxy = useIsFeatureGroup([Core.Models.FeatureGroup.HerGalaxy]);
    const league = useLeague();

    if (isHerGalaxy) {
        return (
            <div className="verification-sent-gxr">
                <div className="verification-sent-gxr__left-column">
                    <img src={heroImg} alt="" className="verification-sent-gxr__hero-img" />
                    <div className="verification-sent-gxr__sponsor-images">
                        <img src={galaxyRacerImg} alt="" className="verification-sent-gxr__galaxyracer-img" />
                        <img src={herGalaxyImg} alt="" className="verification-sent-gxr__hergalaxy-img" />
                    </div>
                </div>
                <div className="verification-sent-gxr__right-column">
                    <div className="verification-sent-gxr__hero">
                        <img src={verificationLeftImg} alt="" className="verification-sent-gxr__hero__img" />
                        <img src={verificationCenterImg} alt="" className="verification-sent-gxr__hero__img center" />
                        <img src={verificationRightImg} alt="" className="verification-sent-gxr__hero__img" />
                    </div>
                    <div className="verification-sent-gxr__text">
                        <h2>
                            <strong>We've sent you a verification email.</strong>
                        </h2>
                        <h3>Please check your inbox and follow the instructions provided.</h3>
                    </div>
                    <div className="verification-sent-gxr__text">
                        <p>
                            <strong>Join the HER Galaxy Discord server now!</strong>
                        </p>
                    </div>
                    {!!league?.discordUrl && (
                        <div className="verification-sent-gxr__text">
                            <a
                                className="link-button"
                                href={league?.discordUrl}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <i className="fa-brands fa-discord"></i>Join the Discord
                            </a>
                        </div>
                    )}
                    <div className="verification-sent-gxr__text">
                        <p className="verification-sent-gxr__text">
                            Then,{' '}
                            <strong>
                                <Link to={'/league'}>check out your new community!</Link>
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="verification-sent">
            <div className="verification-sent__box">
                <NewEmailIcon />
                <p>We've sent you a verification email.</p>
                <p>Please check your inbox and follow the instructions provided.</p>
            </div>
        </div>
    );
};

export default EmailVerificationSentPage;

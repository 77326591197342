import React, { ChangeEvent, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProps } from 'formik';
import { debounce } from 'lodash';
import * as QueryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';

import * as Core from '../../core';
import { IconButton } from '../../components/buttons-visuals';
import FormField from '../../components/formField';
import { Checkbox } from '../../components/inputs';
import { Popover } from '../../components/overlays';
import RegistrationForm, { RegistrationFormValues } from '../../components/registrationForm';
import RegistrationHeader from '../../components/registrationHeader';
import { useUserPermissionService } from '../../hooks/store';
import { LeagueService } from '../../services/leagueService';

interface CreateLeaguePageProps extends RouteComponentProps {}

export interface CreateLeagueFormValues extends RegistrationFormValues {
    autoGenerationOptions?: Core.Models.LeagueAutoGenerationOptions;
    edition: Core.Models.LeagueEdition;
    isDemo: boolean;
    name: string;
    plan: Core.Models.LeaguePlan | Core.Models.DropdownSelection.Unselected;
    recaptcha?: string;
    subdomain: string;
    timezone: string;
}

const PLAN_QUERY_PARAM_NAME = 'plan';

const CreateLeaguePage = ({ location }: CreateLeaguePageProps): JSX.Element => {
    const [subdomain, setSubdomain] = useState<string>('');
    const { getIsAdmin } = useUserPermissionService();
    const isAdmin = getIsAdmin();
    const plan = useMemo(() => {
        const planId = QueryString.parse(location.search)[PLAN_QUERY_PARAM_NAME] as string;
        const { LITE, ENTERPRISE } = Core.Constants.PLANS;

        if (!isAdmin) return Core.Models.LeaguePlan.Lite; // auto-set LS Lite for non-admins
        if (!!planId && ![LITE.ID, ENTERPRISE.ID].includes(planId)) return Core.Models.DropdownSelection.Unselected; // ensure plan is a valid option, otherwise set to empty

        switch (planId) {
            case LITE.ID:
                return Core.Models.LeaguePlan.Lite;
            case ENTERPRISE.ID:
                return Core.Models.LeaguePlan.Enterprise;
            default:
                return Core.Models.DropdownSelection.Unselected;
        }
    }, [isAdmin, location.search]);

    const checkSubdomain = useMemo(
        () =>
            debounce(async (sub?: string) => {
                if (!sub) return false;
                if (sub === subdomain) return true;
                const isValid = await LeagueService.getIsValidSubdomain(sub);
                setSubdomain(sub);
                return isValid;
            }, Core.Constants.FORM_DEBOUNCE_TIME_MS),
        [subdomain]
    );

    return (
        <div>
            <RegistrationForm<CreateLeagueFormValues>
                acceptEmail={true}
                header={
                    <RegistrationHeader
                        welcome={`Welcome to ${Core.Constants.Company}`}
                        message={`Create your league today by filling in the information below.`}
                        formInstructions="All fields are required."
                    />
                }
                initialValues={{
                    autoGenerationOptions: undefined,
                    edition: Core.Models.LeagueEdition.Club,
                    isDemo: false,
                    name: '',
                    plan,
                    recaptcha: '',
                    subdomain: '',
                    timezone: '',
                }}
                register={async (values: CreateLeagueFormValues) => {
                    return await LeagueService.createLeague(values);
                }}
                registrationAction={Core.Models.RegistrationAction.CreateLeague}
                renderAfterCustomFields={() => {
                    if (isAdmin) return <></>;
                    return <FormField component="recaptcha" name="recaptcha" />;
                }}
                renderBeforeCustomFields={(formProps: FormikProps<CreateLeagueFormValues>) => {
                    return {
                        canProceed: formProps.values.plan !== Core.Models.DropdownSelection.Unselected,
                        component: (
                            <div className="disp-flex align-center mb2x">
                                <FormField
                                    className="create-league__league flex-grow"
                                    component="select"
                                    description="Program"
                                    name="plan"
                                >
                                    <option value={Core.Models.DropdownSelection.Unselected} hidden disabled>
                                        --
                                    </option>
                                    <option value={Core.Models.LeaguePlan.Lite}>
                                        {Core.Constants.PLANS.LITE.NAME}
                                    </option>
                                    {isAdmin && (
                                        <option value={Core.Models.LeaguePlan.Enterprise}>
                                            {Core.Constants.PLANS.ENTERPRISE.NAME}
                                        </option>
                                    )}
                                </FormField>
                                <Popover
                                    button={
                                        <IconButton
                                            as="button"
                                            buttonLabel="More info"
                                            buttonSize="small"
                                            className="mb2x ml"
                                        >
                                            <FontAwesomeIcon icon={['fas', 'circle-question']} />
                                        </IconButton>
                                    }
                                >
                                    For detailed information about each plan, please visit{' '}
                                    <a href={Core.Constants.PRICING_URL} rel="noopener noreferrer" target="_blank">
                                        {Core.Constants.PRICING_URL}
                                    </a>
                                    .
                                </Popover>
                            </div>
                        ),
                    };
                }}
                renderMiddleCustomFields={(formProps: FormikProps<CreateLeagueFormValues>) => {
                    const isLite = formProps.values.plan === Core.Models.LeaguePlan.Lite;
                    const isEnterprise = formProps.values.plan === Core.Models.LeaguePlan.Enterprise;
                    return (
                        <fieldset className="form-group">
                            <FormField description="League name" name="name" type="text" />
                            {!isLite && (
                                <div className="disp-flex align-center">
                                    <FormField
                                        className="flex-grow"
                                        label="League subdomain"
                                        name="subdomain"
                                        type="text"
                                    />
                                    <div className="mb ml">.leaguespot.gg</div>
                                </div>
                            )}
                            <FormField
                                className="create-league__league"
                                component="select"
                                label="Timezone"
                                name="timezone"
                            >
                                <option key={-1} value={''} hidden disabled>
                                    --
                                </option>
                                {Core.Constants.SUPPORTED_TIMEZONES.map((tz: string, ix: number) => {
                                    return (
                                        <option key={ix} value={tz}>
                                            {tz}
                                        </option>
                                    );
                                })}
                            </FormField>
                            {(isEnterprise || isAdmin) && (
                                <div className="disp-flex align-center">
                                    <FormField
                                        className="flex-grow"
                                        component="select"
                                        description="League edition"
                                        name="edition"
                                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                            if (
                                                +e.target.value === Core.Models.LeagueEdition.Club &&
                                                !!formProps.values.autoGenerationOptions
                                            ) {
                                                formProps.setFieldValue('autoGenerationOptions', {
                                                    ...formProps.values.autoGenerationOptions,
                                                    organizations: 1,
                                                });
                                            }
                                            formProps.setFieldValue('edition', +e.target.value);
                                        }}
                                    >
                                        {Object.keys(Core.Models.LeagueEdition)
                                            .filter(
                                                (edition: string) =>
                                                    typeof Core.Models.LeagueEdition[edition as any] === 'number'
                                            )
                                            .map((edition: string) => ({
                                                key: +Core.Models.LeagueEdition[edition as any],
                                                value: edition,
                                            }))
                                            .map((edition: { key: number; value: string }) => (
                                                <option key={edition.key} value={edition.key}>
                                                    {edition.value}
                                                </option>
                                            ))}
                                    </FormField>
                                    <Popover
                                        button={
                                            <IconButton
                                                as="button"
                                                buttonLabel="More info"
                                                buttonSize="small"
                                                className="mb2x ml"
                                            >
                                                <FontAwesomeIcon icon={['fas', 'circle-question']} />
                                            </IconButton>
                                        }
                                    >
                                        <ul>
                                            <li className="mb2x">
                                                An <strong>association</strong> league is comprised of a network of{' '}
                                                <span className="text-italic">many</span> organizations/schools, each
                                                with their own members and teams.
                                            </li>
                                            <li>
                                                A <strong>club</strong> league consists of a{' '}
                                                <span className="text-italic">single</span> organization that all
                                                members and teams within the league are a part of.
                                            </li>
                                        </ul>
                                    </Popover>
                                </div>
                            )}
                            {isAdmin && (
                                <>
                                    <Checkbox
                                        checked={formProps.values.isDemo}
                                        label="Create this league as a demo"
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            formProps.setFieldValue('isDemo', e.target.checked);

                                            // if not a demo, clear auto gen options
                                            if (!e.target.checked) {
                                                formProps.setFieldValue('autoGenerationOptions', undefined);
                                            }
                                        }}
                                    />
                                    {formProps.values.isDemo && (
                                        <>
                                            <Checkbox
                                                label="Automatically generate organizations and users"
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    // create `autoGenerationOptions` with default values, or clear it
                                                    formProps.setFieldValue(
                                                        'autoGenerationOptions',
                                                        e.target.checked
                                                            ? ({
                                                                  organizations:
                                                                      formProps.values.edition ===
                                                                      Core.Models.LeagueEdition.Club
                                                                          ? 1
                                                                          : 8,
                                                                  membersPerOrganization:
                                                                      formProps.values.edition ===
                                                                      Core.Models.LeagueEdition.Club
                                                                          ? 24
                                                                          : 16,
                                                              } as Core.Models.LeagueAutoGenerationOptions)
                                                            : undefined
                                                    );
                                                }}
                                                checked={!!formProps.values.autoGenerationOptions}
                                            />
                                            {!!formProps.values.autoGenerationOptions && (
                                                <>
                                                    <FormField
                                                        description="Number of organizations"
                                                        name="autoGenerationOptions.organizations"
                                                        type="number"
                                                    />
                                                    <FormField
                                                        description="Members per organization"
                                                        name="autoGenerationOptions.membersPerOrganization"
                                                        type="number"
                                                    />
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </fieldset>
                    );
                }}
                schema={{
                    edition: Yup.number().required('League edition is required').min(0, 'League edition is required'),
                    isDemo: Yup.boolean().required(),
                    name: Yup.string().required('League name is required'),
                    plan: Yup.number().required('League plan is required').min(0, 'League plan is required'),
                    recaptcha: Yup.string()
                        .test(
                            'is-required',
                            `reCAPTCHA is required`,
                            (recaptcha: string | undefined) => isAdmin || !!recaptcha
                        )
                        .nullable(),
                    subdomain: Yup.string()
                        .min(4, 'Subdomain must be at least 4 characters')
                        .test(
                            'subdomain-is-valid',
                            'Subdomain is not available',
                            async (sub: string | undefined, context) => {
                                const isLite = context.parent.plan === Core.Models.LeaguePlan.Lite;
                                if (isLite) return true;
                                return !!(await checkSubdomain(sub));
                            }
                        )
                        .test(
                            'subdomain-is-required',
                            'League subdomain is required',
                            async (sub: string | undefined, context) => {
                                const isLite = context.parent.plan === Core.Models.LeaguePlan.Lite;
                                if (!sub) return isLite;
                                return true;
                            }
                        )
                        .nullable(),
                    timezone: Yup.string().required('League timezone is required'),
                }}
                submitText="Create League"
            />
        </div>
    );
};

export default CreateLeaguePage;

import * as Core from '../core';

export abstract class OAuthService {
    public static async linkGenericOAuthProvider(
        command: Core.Models.LinkOAuthAccountCommand
    ): Promise<Core.Models.ApiResponse> {
        const route = Core.API.ServerRoute.forAction('oauth', 'link/generic');
        return await Core.API.post(route, command);
    }
    public static async verifyGenericOAuthProvider(
        command: Core.Models.VerifyLinkOAuthAccountCommand
    ): Promise<Core.Models.ApiResponse> {
        const route = Core.API.ServerRoute.forAction('oauth', 'link/verify');
        return await Core.API.post(route, command);
    }
    public static async getDiscordUserAndGuilds(
        token?: string | undefined
    ): Promise<Core.Models.ApiObjectResponse<Core.Models.DiscordUser>> {
        let action = 'me';
        if (token) action += `?token=${token}`;
        const route = Core.API.ServerRoute.forAction('discord', action);
        return await Core.API.get(route);
    }
    public static async unlinkAccount(command: Core.Models.UnlinkOAuthAccountCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('oauth', 'unlink');
        return await Core.API.post(route, command);
    }
    public static async getOAuthProvider(oAuthProviderId: string): Promise<Core.Models.OAuthProvider> {
        const route = Core.API.ServerRoute.forAction('oauth', `provider/${oAuthProviderId}`);
        return await Core.API.getAnonymous(route);
    }
}

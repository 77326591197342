import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import { fromPairs, orderBy } from 'lodash';
import { RouteComponentProps, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import * as Yup from 'yup';

import * as Core from '../../core';
import { AddButton, Avatar, HollowButton, SolidButton } from '../../components/buttons-visuals';
import ConfirmModal from '../../components/confirmModal';
import DraggableList from '../../components/draggableList';
import ErrorMessage from '../../components/errorMessage';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';
import { Checkbox } from '../../components/inputs';
import Modal from '../../components/modal';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { usePromiseOperation } from '../../hooks/promiseOperation';
import { useAlternateSeasonName } from '../../hooks/store';
import { SeasonService } from '../../services/seasonService';
import { StageService } from '../../services/stageService';

import './index.scss';

interface EditStageParticipantRouteProps {
    id: string;
}

interface EditStageParticipantProps extends RouteComponentProps<EditStageParticipantRouteProps>, WithLoadingProps {
    stage: Core.Models.BasicStage;
    seasonParticipants: Core.Models.Participant[];
    stageParticipants: Core.Models.StageParticipant[];
}

function getSeasonParticipantIds(stageParticipants: Core.Models.StageParticipant[]) {
    return orderBy(stageParticipants, (sp: Core.Models.StageParticipant) => sp.inputRank).map(
        (sp: Core.Models.StageParticipant) => sp.participantId
    );
}

const EditStageParticipant = ({
    match: {
        params: { id },
    },
    setError,
    setIsLoading,
}: EditStageParticipantProps) => {
    const [stage, setStage] = useState<Core.Models.BasicStage | undefined>(undefined);
    const [seasonParticipants, setSeasonParticipants] = useState<Core.Models.Participant[]>([]);
    const [stageParticipants, setStageParticipants] = useState<Core.Models.StageParticipant[]>([]);
    const [ids, setIds] = useState<string[]>(getSeasonParticipantIds(stageParticipants));
    useEffect(() => setIds(getSeasonParticipantIds(stageParticipants)), [stageParticipants, setIds]);
    const seasonAlternateName = useAlternateSeasonName();

    useEffect(() => {
        (async () => {
            try {
                const stageResponse = await StageService.getBasic(id);
                const [seasonParticipants, stageParticipants] = await Promise.all([
                    SeasonService.getParticipants(stageResponse.seasonId),
                    StageService.getParticipants(id),
                ]);
                setStage(stageResponse);
                setSeasonParticipants(seasonParticipants.filter((p: Core.Models.Participant) => p.isParticipating));
                setStageParticipants(
                    stageParticipants.filter((sp: Core.Models.StageParticipant) => sp.isParticipating)
                );
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    const participants = useMemo(
        () => fromPairs(seasonParticipants.map((p: Core.Models.Participant) => [p.id, p])),
        [seasonParticipants]
    );

    const [toRemove, setToRemove] = useState<string[]>([]);
    const [removing, setRemoving] = useState<boolean>(false);
    const remove = async () => {
        setIds(ids.filter((i: string) => !toRemove.some((ii: string) => ii === i)));
        setToRemove([]);
        setRemoving(false);
    };
    const removeCheckedChanged = (id: string, e: ChangeEvent<HTMLInputElement>) => {
        const filtered = toRemove.filter((i) => i !== id);
        if (e.target.checked) {
            setToRemove([...filtered, id]);
        } else {
            setToRemove(filtered);
        }
    };

    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [addCompleteTimer, setAddCompleteTimer] = useState<NodeJS.Timeout | undefined>(undefined);
    const closeAdding = useCallback(() => {
        setIsAdding(false);
        if (addCompleteTimer) {
            clearTimeout(addCompleteTimer);
        }
        setAddCompleteTimer(undefined);
    }, [setIsAdding, addCompleteTimer, setAddCompleteTimer]);

    const [isAddingAll, setIsAddingAll] = useState<boolean>(false);
    const addAll = async () => {
        setIds(seasonParticipants.map((participant: any) => participant.id));
        setIsAddingAll(false);
    };

    const [submitComplete, setSubmitComplete] = useState<boolean>(false);
    const [isSubmitting, submit, submitError] = usePromiseOperation(
        useCallback(async () => {
            await StageService.setParticipants({
                id: stage!.id,
                participantIds: ids,
            });
            setSubmitComplete(true);
        }, [stage?.id, ids, setSubmitComplete])
    );

    if (!stage) return <></>;

    if (submitComplete) {
        return <Redirect to={`/seasons/${stage.seasonId}/${stage.id}`} />;
    }

    if (stage.inputParticipantSource !== Core.Models.InputParticipantSourceType.ManualList) {
        return (
            <div className="page edit-stage-participants-page">
                <div className="edit-stage-participants">
                    <h2 className="edit-stage-participants__page-header">Edit participants for {stage.name}</h2>
                    <p className="edit-stage-participants__instructions">
                        You cannot edit the participants of a stage not in manual mode - you must{' '}
                        <Link className="edit-stage-participants__edit-stage-link" to={`/stages/${stage.id}/edit`}>
                            edit the stage
                        </Link>{' '}
                        to switch to a manual participant list first.
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="global-container edit-stage-participants pt4x">
            <h2 className="heading-2">Edit participants for {stage.name}</h2>
            <div className="edit-stage-participants__page-sub-header">
                <p>Drag the participants to change their rank.</p>
                <div className="edit-stage-participants__page-sub-header__buttons">
                    <AddButton
                        as="button"
                        buttonSize="small"
                        buttonStyle="solid"
                        disabled={isSubmitting}
                        onClick={() => setIsAdding(true)}
                    >
                        Add one participant
                    </AddButton>
                    <AddButton
                        as="button"
                        buttonSize="small"
                        buttonStyle="solid"
                        disabled={isSubmitting}
                        onClick={() => setIsAddingAll(true)}
                    >
                        Add all participants
                    </AddButton>
                </div>
            </div>
            <StickyContainer className="mb2x">
                <Sticky>
                    {({ style }) => (
                        <div
                            className="edit-stage-participants__grid edit-stage-participants__grid--header"
                            style={style}
                        >
                            <div className="edit-stage-participants__grid__rank">Rank</div>
                            <div className="edit-stage-participants__grid__participant">Participant</div>
                            <div className="edit-stage-participants__grid__remove">
                                {toRemove.length > 0 ? (
                                    <HollowButton
                                        as="button"
                                        color="destructive"
                                        onClick={() => setRemoving(true)}
                                        size="small"
                                    >
                                        Remove
                                    </HollowButton>
                                ) : (
                                    'Remove'
                                )}
                            </div>
                        </div>
                    )}
                </Sticky>
                <DraggableList
                    items={ids}
                    setItems={setIds}
                    getId={(i) => i}
                    disabled={isSubmitting}
                    renderItem={(id, ix) => {
                        const p = participants[id];
                        const checkId = 'check-' + id;
                        return (
                            <div className="edit-stage-participants__grid edit-stage-participants__grid--item">
                                <div className="edit-stage-participants__grid__rank disp-flex align-center">
                                    <div className="edit-stage-participants__grid__rank__handle" aria-hidden="true" />
                                    <div className="ml2x">{ix + 1}</div>
                                </div>
                                <div className="edit-stage-participants__grid__participant disp-flex align-center flex-gap">
                                    <Avatar
                                        className="edit-stage-participants__grid__avatar"
                                        fallback="team"
                                        size="xsmall"
                                        src={p.avatarUrl || p.organizationLogoUrl}
                                    />
                                    {p.name}
                                </div>
                                <div className="edit-stage-participants__grid__remove">
                                    <label className="edit-stage-participants__grid__remove__label" htmlFor={checkId}>
                                        Remove
                                    </label>
                                    <Checkbox
                                        className="edit-stage-participants__grid__remove__checkbox mb0"
                                        id={checkId}
                                        checked={toRemove.indexOf(id) >= 0}
                                        onChange={removeCheckedChanged.bind(null, id)}
                                    />
                                </div>
                            </div>
                        );
                    }}
                />
            </StickyContainer>
            {submitError && <InfoMessage type="error" message={submitError} />}
            <SolidButton as="button" className="mb2x" onClick={submit} pending={isSubmitting} size="medium">
                Save participants and order
            </SolidButton>
            {!!removing && (
                <ConfirmModal onCancel={() => setRemoving(false)} onConfirm={remove} title="Are you sure?">
                    <p>
                        Are you sure you want to remove <strong>{toRemove.length}</strong> selected participants?
                    </p>
                </ConfirmModal>
            )}
            {isAdding && (
                <Modal onClose={closeAdding} title="Add participant">
                    <Formik
                        initialValues={{ id: '' }}
                        validationSchema={Yup.object().shape({
                            id: Yup.string().required('Participant is required'),
                        })}
                        onSubmit={(values, actions) => {
                            setIds([...ids, values.id]);
                            actions.setSubmitting(false);
                            setAddCompleteTimer(setTimeout(closeAdding, 2000));
                        }}
                        render={(formProps) => {
                            if (addCompleteTimer) {
                                const newParticipant = seasonParticipants.find((i) => i.id === formProps.values.id);
                                if (newParticipant) {
                                    return <p>{newParticipant.name} has been added to the end of the list.</p>;
                                }
                            }
                            const remainingParticipants = orderBy(
                                seasonParticipants.filter((i) => !ids.some((ii) => ii === i.id)),
                                (i) => i.name
                            );
                            if (remainingParticipants.length === 0) {
                                return (
                                    <p>
                                        All the {seasonAlternateName.toLowerCase()} participants are already on this
                                        stage - add more to the {seasonAlternateName.toLowerCase()} first.
                                    </p>
                                );
                            }
                            return (
                                <Form className="form">
                                    <FormField name="id" label="Select a participant" component="select">
                                        <option value="" hidden disabled>
                                            --
                                        </option>
                                        {remainingParticipants.map((i, ix) => (
                                            <option value={i.id}>{i.name}</option>
                                        ))}
                                    </FormField>
                                    <ErrorMessage error={formProps.errors} filter={formProps.touched} />
                                    <SolidButton
                                        as="button"
                                        size="large"
                                        onClick={formProps.submitForm}
                                        disabled={formProps.isSubmitting}
                                        layout="full"
                                    >
                                        Add participant
                                    </SolidButton>
                                </Form>
                            );
                        }}
                    />
                </Modal>
            )}
            {isAddingAll && (
                <ConfirmModal
                    onCancel={() => setIsAddingAll(false)}
                    onConfirm={addAll}
                    type="additive"
                    title="Are you sure?"
                >
                    <p>Do you want to add all available participants?</p>
                </ConfirmModal>
            )}
        </div>
    );
};

export default withLoading(EditStageParticipant, {
    errorDisplayPageProps: { fullPage: true },
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

import React from 'react';

import * as Core from '../../core';
import RegistrationForm, { RegistrationFormValues } from '../../components/registrationForm';
import RegistrationHeader from '../../components/registrationHeader';
import { UserService } from '../../services/userService';

export interface RegisterFormValues extends RegistrationFormValues {}

const RegisterPage = (): JSX.Element => {
    return (
        <div>
            <RegistrationForm<RegisterFormValues>
                acceptEmail
                header={
                    <RegistrationHeader
                        formInstructions="All fields are required."
                        message="Register for LeagueSpot!"
                        showLoginPrompt
                        welcome="Welcome to LeagueSpot"
                    />
                }
                initialValues={{}}
                register={async (values: RegisterFormValues) => await UserService.joinPlatform(values)}
                registrationAction={Core.Models.RegistrationAction.None}
                schema={{}}
                submitText="Join LeagueSpot"
            />
        </div>
    );
};

export default RegisterPage;

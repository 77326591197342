import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import pluralize from 'pluralize';

import * as Core from '../../../core';
import { TextButton, TextLink } from '../../../components/buttons-visuals';
import withLoading, { WithLoadingProps } from '../../../components/withLoading';
import { useCanEditSeason, useHasLeagueAccess } from '../../../hooks/store';
import { StageService } from '../../../services/stageService';
import EditStageForm from '../../editStage/form';

interface StageSettingsModalProps extends WithLoadingProps {
    game: Core.Models.Game;
    reloadSeason: () => Promise<void>;
    stageId: string;
}

const StageSettingsModal = (props: StageSettingsModalProps): JSX.Element => {
    const { game, reloadSeason, setError, setIsLoading, stageId } = props;
    const [stage, setStage] = useState<Core.Models.BasicStage | undefined>(undefined);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const canEditSeason = useCanEditSeason(stage?.seasonId);
    const canElevatedEditLeague = useHasLeagueAccess(Core.Models.PermissionLevel.ElevatedEdit);
    const stageTypeName = useMemo(() => Core.Competition.getStageTypeName(stage?.stageTypeId), [stage?.stageTypeId]);
    const matchWord = useMemo(
        () => Core.Competition.getMatchWord(game.eventType, { lowercase: true }),
        [game.eventType]
    );
    const canEditStage = useMemo(
        () => canEditSeason && !!stage && stage.currentState < Core.Models.StageState.IsComplete,
        [canEditSeason, stage]
    );

    const isAutoMatcher = useMemo(() => {
        const stageTypeIds = [Core.Models.StageTypeId.AutoMatcher, Core.Models.StageTypeId.LeaderboardAutoMatcher];
        return !!stage && stageTypeIds.includes(stage.stageTypeId);
    }, [stage]);
    const isLeaderboard = useMemo(() => {
        const stageTypeIds = [Core.Models.StageTypeId.Leaderboard, Core.Models.StageTypeId.LeaderboardAutoMatcher];
        return !!stage && stageTypeIds.includes(stage.stageTypeId);
    }, [stage]);

    const load = useCallback(async () => {
        try {
            const response = await StageService.getBasic(stageId);
            setStage(response);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [setError, setIsLoading, stageId]);

    useEffect(() => {
        (async () => load())();
    }, [load]);

    if (!stage) return <></>;

    const {
        allowMatchGameTies,
        allowMatchTies,
        allowRescheduleRequests,
        attemptPointsBasedScoringTiebreaker,
        attendanceWindowMinutes,
        autoForfeitWindowMinutes,
        autoStartRounds,
        bestOf,
        currentState,
        id,
        numberOfGroups,
        randomizeLeaderboardParticipants,
        requireResultScreenshots,
        requireStageCheckIn,
        rosterLockHours,
        seasonId,
        usePointsBasedScoring,
        useSimplifiedCheckin,
    } = stage;

    return (
        <div className="stage-settings-modal">
            {isEditing ? (
                <EditStageForm
                    onSubmit={async (values) => {
                        await StageService.edit({
                            ...values,
                            id,
                        });
                        await Promise.all([reloadSeason(), load()]);
                        setIsEditing(false);
                    }}
                    seasonId={seasonId}
                    stage={stage}
                />
            ) : (
                <>
                    <dl className={classNames({ mb4x: canEditStage })}>
                        {canElevatedEditLeague && (
                            <>
                                <dt>Id</dt>
                                <dd>{id}</dd>
                            </>
                        )}
                        <>
                            <dt>Auto forfeits</dt>
                            <dd>
                                {!!autoForfeitWindowMinutes
                                    ? `Yes${
                                          canEditSeason
                                              ? ` (${autoForfeitWindowMinutes} ${pluralize(
                                                    'minute',
                                                    autoForfeitWindowMinutes
                                                )} after ${matchWord} start time)`
                                              : ''
                                      }`
                                    : 'No'}
                            </dd>
                        </>
                        {isAutoMatcher && (
                            <>
                                <dt>Auto start rounds</dt>
                                <dd>{!!autoStartRounds ? 'Yes' : 'No'}</dd>
                            </>
                        )}
                        <>
                            <dt>Best of</dt>
                            <dd>{bestOf}</dd>
                        </>
                        <>
                            <dt>Match game ties</dt>
                            <dd>{!!allowMatchGameTies ? 'Yes' : 'No'}</dd>
                        </>
                        <>
                            <dt>Match ties</dt>
                            <dd>{!!allowMatchTies ? 'Yes' : 'No'}</dd>
                        </>
                        {canEditSeason && (
                            <>
                                <dt>Number of groups</dt>
                                <dd>{numberOfGroups}</dd>
                            </>
                        )}
                        {canEditSeason && isLeaderboard && !isAutoMatcher && (
                            <>
                                <dt>Randomize leaderboard participants</dt>
                                <dd>{!!randomizeLeaderboardParticipants ? 'Yes' : 'No'}</dd>
                            </>
                        )}
                        <>
                            <dt>Require result screenshots</dt>
                            <dd>{!!requireResultScreenshots ? 'Yes' : 'No'}</dd>
                        </>
                        <>
                            <dt>Require stage check-in</dt>
                            <dd>{!!requireStageCheckIn ? 'Yes' : 'No'}</dd>
                        </>
                        <>
                            <dt>Reschedule requests</dt>
                            <dd>{!!allowRescheduleRequests ? 'Yes' : 'No'}</dd>
                        </>
                        <>
                            <dt>Roster locks</dt>
                            <dd>
                                {!!rosterLockHours
                                    ? `Yes${
                                          canEditSeason
                                              ? ` (${rosterLockHours} ${pluralize(
                                                    'hour',
                                                    rosterLockHours
                                                )} before ${matchWord} time)`
                                              : ''
                                      }`
                                    : 'No'}
                            </dd>
                        </>
                        {canEditSeason && isAutoMatcher && (
                            <>
                                <dt>Round check-in window</dt>
                                <dd>{attendanceWindowMinutes} minutes</dd>
                            </>
                        )}
                        <>
                            <dt>Scoring methodology</dt>
                            <dd>
                                {!!usePointsBasedScoring
                                    ? 'Points-Based Scoring'
                                    : `Best of ${bestOf} ${pluralize('Game', bestOf)}`}
                            </dd>
                        </>
                        {!!usePointsBasedScoring && !isLeaderboard && (
                            <>
                                <dt>Points-based scoring tiebreaker</dt>
                                <dd>{attemptPointsBasedScoringTiebreaker ? 'Yes' : 'No'}</dd>
                            </>
                        )}
                        <>
                            <dt>Simplified check-in</dt>
                            <dd>{!!useSimplifiedCheckin ? 'Yes' : 'No'}</dd>
                        </>
                        <>
                            <dt>Type</dt>
                            <dd>{stageTypeName}</dd>
                        </>
                    </dl>
                    {!!canEditStage && (
                        <div className="stage-settings-modal__edit-button-container">
                            {currentState > Core.Models.StageState.NotStarted ? (
                                <TextButton onClick={() => setIsEditing(true)}>Edit settings</TextButton>
                            ) : (
                                <TextLink to={`/stages/${id}/edit`}>Edit settings</TextLink>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default withLoading(StageSettingsModal, { loadingProps: { blockItem: true } });

import * as Redux from 'redux';

import { LeagueState, LeagueActionTypes, LEAGUE_LOADING, LEAGUE_SUCCESS, LEAGUE_FAILURE } from './types';

const initialState: LeagueState = {
    isLoading: false,
};

const leagueReducer: Redux.Reducer<LeagueState> = (state = initialState, action: LeagueActionTypes): LeagueState => {
    switch (action.type) {
        case LEAGUE_LOADING:
            return {
                ...state,
                isLoading: true,
                loadingError: undefined,
            };
        case LEAGUE_SUCCESS:
            const { payload } = action;
            return {
                ...state,
                isLoading: false,
                league: payload,
                loadingError: undefined,
            };
        case LEAGUE_FAILURE:
            return {
                ...state,
                isLoading: false,
                league: undefined,
                loadingError: action.payload,
            };
        default:
            return state;
    }
};

export default leagueReducer;

import * as Yup from 'yup';

import { BaseCore } from '@leaguespot/platform-js';

export function password(fieldName?: string) {
    return optionalPassword(fieldName).required(`${fieldName} is required.`);
}

export function optionalPassword(fieldName?: string) {
    const name = fieldName || 'Password';
    return Yup.string()
        .min(8, `${name} must be at least 8 characters.`)
        .matches(/[A-Z]/, `${name} must have at least one uppercase character.`)
        .matches(/[a-z]/, `${name} must have at least one lowercase character.`)
        .matches(/[0-9]/, `${name} must have at least one number.`);
}

export function isUsername(username: string | undefined) {
    return (
        !!username &&
        username.length <= BaseCore.Constants.USERNAME_MAX_LENGTH &&
        username.length >= BaseCore.Constants.USERNAME_MIN_LENGTH &&
        /^[A-Za-z0-9-._@+]*$/.test(username) // alphanumeric, plus -._@+
    );
}

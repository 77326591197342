import React from 'react';
import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

import './TextLink.scss';

const TextLink = ({ children, className, ...rest }: LinkProps) => {
    const linkClasses = classNames(className, 'ds-text-link');

    return (
        <Link className={linkClasses} {...rest}>
            {children}
        </Link>
    );
};

export default TextLink;

import * as Core from '../core';

export abstract class GameService {
    public static async get(id: string): Promise<Core.Models.Game> {
        const route = Core.API.ServerRoute.forAction('games', id);
        return await Core.API.get(route);
    }

    public static async getAll(): Promise<Core.Models.Game[]> {
        const route = Core.API.ServerRoute.forAction('games', '');
        return await Core.API.get(route);
    }

    public static async create(command: Core.Models.CreateGameRequest): Promise<Core.Models.Game> {
        const route = Core.API.ServerRoute.forAction('games', '');
        return await Core.API.post(route, command);
    }

    public static async edit(command: Core.Models.EditGameRequest): Promise<Core.Models.Game> {
        const route = Core.API.ServerRoute.forAction('games', '');
        return await Core.API.patch(route, command);
    }
}

import React from 'react';

import { IHasChildren } from './types';

interface EmptyLayoutProps extends IHasChildren {}

const EmptyLayout = (props: EmptyLayoutProps) => {
    const { children } = props;

    return (
        <>
            <div id="portal" />
            {children}
        </>
    );
};

export default EmptyLayout;

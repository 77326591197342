import * as React from 'react';

import * as Core from '../core';
import GroupEliminationBracket from '../components/groupEliminationBracket';
import SimpleGroupStandings from '../components/simpleGroupStandings';
import StageEliminationBracket from '../components/stageEliminationBracket';
import StageStandings from '../components/stageStandings';

export interface StageStandingsDisplayProps {
    externalLinks: boolean;
    isMyParticipant?: (participant: { teamId?: string }) => boolean;
    matchWord: string;
    reloadStage: () => Promise<void>;
    stage: Core.Models.Stage;
}

export interface GroupStandingsDisplayProps {
    matches?: Core.Models.Match[];
    stage: Core.Models.Stage;
    group: Core.Models.Group;
    showGroupName?: boolean;
    showStageName?: boolean;
    participantIdsToAlwaysShow?: string[];
    participantIdsToHighlight?: string[];
    isMyParticipant?: (participant: { teamId?: string }) => boolean;
    externalLinks: boolean;
}

export interface StageTypeHandlers {
    groupStandingsDisplayComponent: React.ComponentType<GroupStandingsDisplayProps>;
    // not sure if we'd pass the stage ID or some representation of the results from the server
    standingsDisplayComponent: React.ComponentType<StageStandingsDisplayProps>;
}

export abstract class StageTypeHandlerService {
    public static getHandlers(stageTypeId: Core.Models.StageTypeId): StageTypeHandlers {
        const bracketTypes = [Core.Models.StageTypeId.DoubleElimination, Core.Models.StageTypeId.SingleElimination];
        const standingsTypes = [
            Core.Models.StageTypeId.AutoMatcher,
            Core.Models.StageTypeId.DoubleRoundRobin,
            Core.Models.StageTypeId.Leaderboard,
            Core.Models.StageTypeId.LeaderboardAutoMatcher,
            Core.Models.StageTypeId.RoundRobin,
            Core.Models.StageTypeId.Swiss,
        ];

        if (bracketTypes.includes(stageTypeId)) {
            return {
                groupStandingsDisplayComponent: GroupEliminationBracket,
                standingsDisplayComponent: StageEliminationBracket,
            };
        } else if (standingsTypes.includes(stageTypeId)) {
            return {
                groupStandingsDisplayComponent: SimpleGroupStandings,
                standingsDisplayComponent: StageStandings,
            };
        } else {
            throw new Error(`Invalid stage type: ${stageTypeId}`);
        }
    }
}

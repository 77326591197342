import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Core from '../../core';
import { IconButton } from '../../components/buttons-visuals';
import { useIsAdmin } from '../../hooks/store';

interface GameDetailsProps {
    game: Core.Models.Game;
}

const GameDetails = ({ game }: GameDetailsProps) => {
    const isAdmin = useIsAdmin();

    return (
        <div className="page__details">
            <h2 className="page__text page__text--primary">{game.name}</h2>
            {isAdmin && (
                <IconButton
                    as="link"
                    buttonLabel="Edit Game"
                    buttonSize="medium"
                    className="ml"
                    to={`/games/${game.id}/edit`}
                >
                    <FontAwesomeIcon icon={['fas', 'pen']} />
                </IconButton>
            )}
        </div>
    );
};

export default GameDetails;

import { orderBy } from 'lodash';
import moment from 'moment';

import * as Core from '../core';
import { TimeService } from './timeService';

const OneHour = 60 * 60 * 1000;
const FiveMinutes = 5 * 60 * 1000;

export abstract class MatchService {
    public static async checkIn(command: Core.Models.MatchCheckinRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'checkin');
        return await Core.API.post(route, command);
    }
    public static async simplifiedCheckin(request: Core.Models.SimplifiedMatchCheckinRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'checkin/simplified');
        return await Core.API.post(route, request);
    }
    public static async undoCheckIn(command: Core.Models.UndoMatchCheckInCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'checkin/undo');
        return await Core.API.post(route, command);
    }
    public static async startMatch(command: { matchId: string }): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'start');
        return await Core.API.post(route, command);
    }
    public static async unstartMatch(command: { matchId: string }): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'unstart');
        return await Core.API.post(route, command);
    }
    public static async getMatchById(matchId: string): Promise<Core.Models.MatchResponse> {
        const route = Core.API.ServerRoute.forAction(`matches/${matchId}`);
        const [match, responseDateUtc] = await Core.API.getWithResponseDate<Core.Models.MatchDetails>(
            route,
            Core.API.ApiVersion.v2
        );
        return { match, responseDateUtc, receivedDateUtc: new Date() };
    }
    public static async getMatchParticipants(matchId: string): Promise<Core.Models.MatchParticipant[]> {
        const route = Core.API.ServerRoute.forAction(`matches/${matchId}/participants`);
        return Core.API.get<Core.Models.MatchParticipant[]>(route);
    }
    public static async getMatchGames(matchId: string): Promise<Core.Models.MatchGame[]> {
        const route = Core.API.ServerRoute.forAction(`matches/${matchId}/games`);
        return Core.API.get<Core.Models.MatchGame[]>(route);
    }
    public static async getMatchRescheduleRequest(matchId: string): Promise<Core.Models.RescheduleRequest> {
        const route = Core.API.ServerRoute.forAction(`matches/${matchId}/reschedule-request`);
        return Core.API.get<Core.Models.RescheduleRequest>(route);
    }
    public static async getMyMatchesToday(): Promise<Core.Models.MatchesTodayResponse> {
        const route = Core.API.ServerRoute.forAction('matches', 'today');

        const [matches, responseDateUtc] = await Core.API.getWithResponseDate<Core.Models.TodaysMatch[]>(route);
        return {
            matches,
            receivedDateUtc: new Date(),
            responseDateUtc,
        };
    }
    public static getMyMatchesTodayDelay(response: Core.Models.MatchesTodayResponse, timezone: string): number {
        const nonCompleted = orderBy(
            response.matches
                .filter((match) => match.startTimeUtc)
                .filter((tm: Core.Models.TodaysMatch) => tm.currentState < Core.Models.MatchState.IsComplete),
            (i) => moment(i.startTimeUtc).toDate()
        );
        if (nonCompleted.length === 0) {
            return OneHour;
        }

        // future times are positive
        const adjusted = TimeService.getAdjustedTime(response, nonCompleted[0].startTimeUtc, timezone);
        const diff = adjusted.diff(moment());
        if (diff >= OneHour) {
            return OneHour;
        }
        return FiveMinutes;
    }
    public static async getMyRescheduleRequestedMatches(): Promise<Core.Models.Match[]> {
        const route = Core.API.ServerRoute.forAction('matches/rescheduleRequested');
        return await Core.API.get(route);
    }
    public static async submitMatchGameResult(command: Core.Models.SubmitMatchGameResultCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'gameResults');
        await Core.API.post(route, command);
    }
    public static async rescoreMatchGameResult(command: Core.Models.RescoreMatchGameResultRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'gameResults/rescore');
        await Core.API.post(route, command);
    }
    public static async submitMatchResult(command: Core.Models.SubmitMatchResultCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'results');
        await Core.API.post(route, command);
    }
    public static async create(command: Core.Models.CreateMatchCommand): Promise<Core.Models.Match> {
        const route = Core.API.ServerRoute.forAction('matches');
        return await Core.API.post(route, command);
    }
    public static async edit(command: Core.Models.EditMatchCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches');
        await Core.API.patch(route, command);
    }
    public static async delete(matchId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', matchId);
        return await Core.API.delete(route);
    }
    public static async disputeMatch(command: Core.Models.DisputeMatchCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'dispute');
        await Core.API.post(route, command);
    }
    public static async forfeitMatch(command: Core.Models.ForfeitMatchCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'forfeit');
        await Core.API.post(route, command);
    }
    public static async unforfeitMatch(command: Core.Models.ForfeitMatchCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'forfeit/undo');
        await Core.API.post(route, command);
    }
    public static async forfeitMatchAll(command: Core.Models.ForfeitMatchAllCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'forfeit/all');
        await Core.API.post(route, command);
    }
    public static async updateHostNotes(command: Core.Models.UpdateHostNotesCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'notes');
        await Core.API.patch(route, command);
    }
    public static async rejectReschedules(command: Core.Models.RejectReschedulesCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'reject-reschedules');
        await Core.API.post(route, command);
    }
    public static async createRescheduleRequest(command: Core.Models.CreateRescheduleRequestCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'rescheduleRequests');
        await Core.API.post(route, command);
    }
    public static async voteOnRescheduleRequest(command: Core.Models.VoteOnRescheduleRequestCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'rescheduleRequests/vote');
        await Core.API.post(route, command);
    }
    public static async addMatchLobbyInstructions(
        command: Core.Models.AddMatchLobbyInstructionsCommand
    ): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'lobby-instructions');
        await Core.API.post(route, command);
    }
    public static async addMatchLink(command: Core.Models.AddMatchLinkCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'links');
        await Core.API.post(route, command);
    }
    public static async deleteMatchLink(matchLinkId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', `links/${matchLinkId}`);
        await Core.API.delete(route);
    }
    public static async getMatchStreams(matchId: string): Promise<Core.Models.Stream[]> {
        const route = Core.API.ServerRoute.forAction('matches', `streams/${matchId}`);
        return await Core.API.getAnonymous(route);
    }

    public static async createMatchStream(request: Core.Models.CreateMatchStreamRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', `streams`);
        return await Core.API.post(route, request);
    }

    public static async editMatchStream(request: Core.Models.EditStreamRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', `streams`);
        return await Core.API.patch(route, request);
    }

    public static async updateEnableStream(request: Core.Models.EnableStreamRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`matches`, `streams/enable`);
        return await Core.API.patch(route, request);
    }

    public static async deleteLeagueStream(streamId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', `streams/${streamId}`);
        return await Core.API.delete(route);
    }

    public static async getTwitchMatchStreams(matchId: string): Promise<Core.Models.TwitchStream[]> {
        const route = Core.API.ServerRoute.forAction('matches', `streams/video/${matchId}`);
        return await Core.API.getAnonymous(route);
    }
    public static async banOpponentDeck(command: Core.Models.BanOpponentDeckRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'hearthstoneDecks/ban');
        await Core.API.post(route, command);
    }
    public static async createMatchGameMetadata(command: Core.Models.CreateMatchGameMetadataRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'matchGameMetadata');
        await Core.API.post(route, command);
    }
    public static async editMatchGameMetadata(command: Core.Models.EditMatchGameMetadataRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matches', 'matchGameMetadata');
        await Core.API.patch(route, command);
    }
    public static async getEligibleGroupParticipants(matchId: string): Promise<Core.Models.GroupParticipant[]> {
        const route = Core.API.ServerRoute.forAction(`matches/${matchId}/eligibleGroupParticipants`);
        return await Core.API.get(route);
    }
    public static async getMatchOAuthAccounts(matchId: string): Promise<Core.Models.MatchParticipantOAuthAccount[]> {
        const route = Core.API.ServerRoute.forAction(`matches/${matchId}/oAuthAccounts`);
        return await Core.API.get(route);
    }
}

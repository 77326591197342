import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import { debounce, range } from 'lodash';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../core';
import { useLeague, useTimezone } from '../../hooks/store';
import history from '../../services/history';
import { LeagueService } from '../../services/leagueService';
import { TimeService } from '../../services/timeService';
import { UserService } from '../../services/userService';
import { login } from '../../store/login/actions';
import { SolidButton } from '../buttons-visuals';
import { ContentContainer } from '../containers';
import { ForgotPasswordLink } from '../forgotPasswordLink';
import FormField from '../formField';
import CustomRegistrationFormFields from '../formField/customRegistrationFormFields';
import TermsFormFields, { buildTermsFieldsValidationSchema } from '../formField/termsFormFields';
import GameInterestList from '../gameInterestList';
import InfoMessage from '../infoMessage';
import { CreatePassword } from '../inputs';
import Loading from '../loading';
import LoginButton from '../loginButton';
import ReviewPayable from '../payments/reviewPayable';

import './index.scss';

interface RegistrationFormProps<TRegistrationFormValues> {
    acceptEmail: boolean;
    allowPayLater?: (formProps: FormikProps<TRegistrationFormValues>) => boolean;
    allowPayment?: (formProps: FormikProps<TRegistrationFormValues>) => boolean;
    header?: JSX.Element;
    initialValues: Partial<TRegistrationFormValues>;
    leagueFee?: Core.Models.Payable;
    onValidatedUsername?: (validationResult: Core.Models.IdentifierValidationResult | undefined) => void;
    organizationId?: string;
    register: (values: TRegistrationFormValues) => Promise<Core.Models.RegistrationResponse>;
    registrationAction: Core.Models.RegistrationAction;
    renderAfterCustomFields?: (formProps: FormikProps<TRegistrationFormValues>) => JSX.Element;
    renderBeforeCustomFields?: (formProps: FormikProps<TRegistrationFormValues>) => {
        canProceed: boolean;
        component: JSX.Element;
    };
    renderMiddleCustomFields?: (formProps: FormikProps<TRegistrationFormValues>) => JSX.Element;
    schema: { [key in keyof Partial<TRegistrationFormValues>]: Yup.AnySchema };
    schemaDependentFields?: [keyof Partial<TRegistrationFormValues>, keyof Partial<TRegistrationFormValues>];
    setHasOrganizationRole?: (hasOrganizationRole: boolean) => void;
    submitText: string;
}

export interface RegistrationFormValues {
    agreedToLegalTerms: boolean;
    agreedToLicenseAgreementAtRegistration: boolean;
    birthdate?: string;
    customRegistrationFields: Core.Models.CustomRegistrationFieldSubmissionRequest[];
    email?: string;
    extendExpiration: boolean;
    firstName: string;
    lastName: string;
    parentEmail?: string;
    password: string;
    registrationFulfillment?: {
        payableId: string;
        paymentGatewayId: Core.Models.PaymentGateway;
        paymentMethodId: string;
    };
    preferredTimezone?: string;
    username: string;
}

interface RegistrationFormData {
    customRegistrationFields: Core.Models.CustomRegistrationField[];
    leagueGames: Core.Models.Game[];
}

const RegistrationForm = <TRegistrationFormValues extends RegistrationFormValues>({
    acceptEmail,
    allowPayLater,
    allowPayment,
    header,
    initialValues,
    leagueFee,
    onValidatedUsername,
    organizationId,
    register,
    registrationAction,
    renderAfterCustomFields,
    renderBeforeCustomFields,
    renderMiddleCustomFields,
    schema,
    schemaDependentFields,
    setHasOrganizationRole,
    submitText,
}: RegistrationFormProps<TRegistrationFormValues>): JSX.Element => {
    const dispatch = useDispatch();
    const league = useLeague();
    const timezone = useTimezone();

    const [emailValidationResult, setEmailValidationResult] = useState<
        Core.Models.IdentifierValidationResult | undefined
    >(acceptEmail ? undefined : { canProceed: true, isInUse: false });
    const [formData, setFormData] = useState<RegistrationFormData | undefined>(undefined);
    const [gameInterests, setGameInterests] = useState<string[]>([]);
    const [usernameValidationResult, setUsernameValidationResult] = useState<
        Core.Models.IdentifierValidationResult | undefined
    >(undefined);

    useEffect(() => {
        (async () => {
            const [customRegistrationFields, leagueGames] = await Promise.all([
                LeagueService.getLeagueCustomRegistrationFields(Core.Models.CustomRegistrationFieldEntityType.User),
                !!organizationId ? LeagueService.getLeagueApprovedGames() : [],
            ]);

            setFormData({
                customRegistrationFields,
                leagueGames,
            });
        })();
    }, [organizationId]);

    const buildDefaultSchema = useCallback(() => {
        return Yup.object().shape(
            {
                // username is always here
                username: Yup.string()
                    .min(
                        Core.Constants.USERNAME_MIN_LENGTH,
                        `Username must be ${Core.Constants.USERNAME_MIN_LENGTH} characters or more`
                    )
                    .max(
                        Core.Constants.USERNAME_MAX_LENGTH,
                        `Username must be ${Core.Constants.USERNAME_MAX_LENGTH} characters or fewer`
                    )
                    .required('Username is required')
                    .test(
                        'valid-characters',
                        'Usernames may only contain letters, numbers, and -._@+',
                        (username: string | undefined) => !!username && Core.Validation.isUsername(username)
                    ),
                ...(!!usernameValidationResult?.isInUse
                    ? {
                          password: Yup.string().required('Password is required'),
                      }
                    : {
                          birthdate: Yup.string()
                              .required('Date of birth is required')
                              .test(
                                  'is-valid',
                                  'Please enter a valid date of birth',
                                  (birthdate: string | undefined) => {
                                      if (!birthdate) return false;
                                      const { birthdateIsValid } = Core.Time.checkBirthdateStringUnder13(birthdate);
                                      return birthdateIsValid;
                                  }
                              )
                              .test(
                                  'can-join',
                                  'This league does not allow users under 13',
                                  (birthdate: string | undefined) => {
                                      if (!birthdate) return false;
                                      const { birthdateIsValid, isUnder13 } =
                                          Core.Time.checkBirthdateStringUnder13(birthdate);
                                      if (birthdateIsValid && isUnder13 && !league?.allowU13) return false;
                                      return true;
                                  }
                              ),
                          ...(!!acceptEmail && {
                              email: Yup.string()
                                  .required('Email is required')
                                  .email('Email must be formatted like an email address'),
                          }),
                          firstName: Yup.string()
                              .min(2, 'First name must be at least two characters')
                              .max(
                                  Core.Constants.NAME_MAX_LENGTH,
                                  `First name must be ${Core.Constants.NAME_MAX_LENGTH} characters or fewer`
                              )
                              .required('First name is required'),
                          lastName: Yup.string()
                              .min(2, 'Last name must be at least two characters')
                              .max(
                                  Core.Constants.NAME_MAX_LENGTH,
                                  `Last name must be ${Core.Constants.NAME_MAX_LENGTH} characters or fewer`
                              )
                              .required('Last name is required'),
                          parentEmail: Yup.string().when('birthdate', {
                              is: Core.Time.userIsUnder13,
                              then: Yup.string()
                                  .required('Users under the age of 13 must include parent email')
                                  .email('Parent email must be formatted like an email address'),
                              otherwise: Yup.string().notRequired(),
                          }),
                          password: Core.Validation.password('Password'),
                          passwordConfirmation: Yup.string()
                              .required('Password Confirmation is required')
                              .oneOf([Yup.ref('password')], 'Passwords must match'),
                          registrationFulfillment: Yup.object().notRequired().nullable(), // this is not required by default. overridable by the calling form's schema
                          preferredTimezone: Yup.string().notRequired(),
                          ...buildTermsFieldsValidationSchema(league),
                      }),
                ...schema,
            },
            !!schemaDependentFields ? [schemaDependentFields as [string, string]] : undefined
        );
    }, [acceptEmail, league, schema, schemaDependentFields, usernameValidationResult]);

    const checkEmail = useCallback(
        async (email: string): Promise<Core.Models.IdentifierValidationResult> => {
            const isValid = /.+@.+\..+/.test(email);
            if (!isValid)
                return {
                    canProceed: false,
                    error: 'Email format is invalid',
                    isInUse: false,
                };

            return await UserService.validateEmail(email, organizationId);
        },
        [organizationId]
    );

    const checkUsername = useCallback(
        async (username: string): Promise<Core.Models.IdentifierValidationResult> => {
            const isValid = !!username && Core.Validation.isUsername(username);
            if (!isValid)
                return {
                    canProceed: false,
                    error: 'Username format is invalid',
                    isInUse: false,
                };
            return await UserService.validateUsername(registrationAction, username, organizationId);
        },
        [organizationId, registrationAction]
    );

    const updateUsername = useMemo(
        () =>
            debounce(async (username: string) => {
                try {
                    const response = await checkUsername(username);
                    setUsernameValidationResult(response);
                    onValidatedUsername?.(response);
                    if (!!setHasOrganizationRole) setHasOrganizationRole(!!response.hasOrganizationRole);
                } catch (error) {
                    toast.error('There was a problem validating your username');
                    setUsernameValidationResult(undefined);
                    onValidatedUsername?.(undefined);
                }
            }, Core.Constants.FORM_DEBOUNCE_TIME_MS),
        [checkUsername, setHasOrganizationRole]
    );

    const updateEmail = useMemo(
        () =>
            debounce(async (email: string) => {
                try {
                    const response = await checkEmail(email);
                    setEmailValidationResult(response);
                } catch (error) {
                    toast.error('There was a problem validating your email');
                }
            }, Core.Constants.FORM_DEBOUNCE_TIME_MS),
        [checkEmail]
    );

    const debounceRegister = useMemo(
        () =>
            debounce(
                async (values: TRegistrationFormValues) => await register(values),
                Core.Constants.FORM_DEBOUNCE_TIME_MS,
                { leading: true, trailing: false }
            ),
        [register]
    );

    const onSubmit = useCallback(
        async (values: TRegistrationFormValues, formikActions: FormikActions<TRegistrationFormValues>) => {
            formikActions.setStatus(undefined);

            const validationErrorMessages: string[] = [];
            try {
                // Since formik only is passing us values, map them back up with the id and names of the fields
                if (!!formData?.customRegistrationFields) {
                    if (values.customRegistrationFields.length !== formData.customRegistrationFields.length)
                        throw new Error('Custom registration fields passed in and collected are not of same length');

                    for (let i = 0; i < values.customRegistrationFields.length; i++) {
                        values.customRegistrationFields[i].id = formData.customRegistrationFields[i].id;
                        values.customRegistrationFields[i].name = formData.customRegistrationFields[i].name;
                    }
                }

                const payload = {
                    ...values,
                    birthdate: !!values.birthdate
                        ? moment.tz(values.birthdate, timezone).format(Core.Time.getFormat())
                        : undefined,
                    email: !!values.email ? values.email : undefined,
                    firstName: !!values.firstName ? values.firstName : undefined,
                    gameInterests,
                    lastName: !!values.lastName ? values.lastName : undefined,
                    parentEmail: Core.Time.userIsUnder13(values.birthdate) ? values.parentEmail : undefined,
                    passwordConfirmation: undefined,
                };

                const response = await debounceRegister(payload);

                if (!response.succeeded) {
                    validationErrorMessages.push(...response.messages);
                    throw new Error('Validation failed!');
                }

                if (registrationAction === Core.Models.RegistrationAction.CreateLeague) {
                    if (!!response.redirectUrl) {
                        window.location.href = response.redirectUrl;
                    } else {
                        toast.success(`Successfully created new league`);
                    }
                } else {
                    dispatch(
                        login({
                            extendExpiration: values.extendExpiration,
                            username: values.username,
                            password: values.password,
                        })
                    );

                    if (response.sentVerificationEmail) {
                        history.push('/email-verification-sent');
                    } else if (!!response.redirectUrl) {
                        history.push(response.redirectUrl);
                    }
                }
            } catch (err) {
                if (validationErrorMessages.length > 0) {
                    formikActions.setStatus(
                        <>
                            <div>Please fix the following errors to proceed:</div>
                            {validationErrorMessages.map((message: string, index: number) => (
                                <React.Fragment key={index}>
                                    <br />
                                    <div>- {message}</div>
                                </React.Fragment>
                            ))}
                        </>
                    );
                } else {
                    const message = Core.API.getErrorMessage(err);
                    formikActions.setStatus(message);
                }
            } finally {
                formikActions.setSubmitting(false);
            }
        },
        [formData?.customRegistrationFields, timezone, gameInterests, debounceRegister, registrationAction, dispatch]
    );

    const renderUsernameNotice = useCallback(() => {
        if (!!usernameValidationResult?.error) {
            return (
                <div>
                    <InfoMessage message={usernameValidationResult.error} type="error" />

                    {usernameValidationResult.isInUse && (
                        <LoginButton returnurl={!!organizationId ? `/organizations/${organizationId}` : '/league'}>
                            Log in to continue
                        </LoginButton>
                    )}
                </div>
            );
        }

        return (
            <p className="text-xsmall text-italic mb4x">
                {!usernameValidationResult?.isInUse ? (
                    <>We recommend choosing a username that doesn't include your name or otherwise identify you.</>
                ) : (
                    <>
                        <strong>This username is taken.</strong> If this is your account, please proceed. Otherwise,
                        please try another username.
                    </>
                )}
            </p>
        );
    }, [organizationId, usernameValidationResult]);

    if (!formData) return <></>;

    return (
        <Formik<TRegistrationFormValues>
            initialValues={Object.assign(
                {
                    agreedToLegalTerms: false,
                    agreedToLicenseAgreementAtRegistration: false,
                    birthdate: '',
                    customRegistrationFields: range(
                        0,
                        formData.customRegistrationFields.length
                    ).map<Core.Models.CustomRegistrationFieldSubmissionRequest>(() => ({
                        id: '',
                        name: '',
                        value: '',
                    })),
                    email: '',
                    extendExpiration: false,
                    firstName: '',
                    lastName: '',
                    parentEmail: '',
                    password: '',
                    passwordConfirmation: '',
                    registrationFulfillment: undefined,
                    preferredTimezone: TimeService.getUserTimezone(),
                    username: '',
                } as unknown as TRegistrationFormValues,
                initialValues
            )}
            validationSchema={buildDefaultSchema()}
            onSubmit={onSubmit}
            render={(formProps: FormikProps<TRegistrationFormValues>) => {
                const { birthdateIsValid, isUnder13 } = Core.Time.checkBirthdateStringUnder13(
                    formProps.values.birthdate
                );

                return (
                    <Form className="form mb4x">
                        {header}

                        {renderBeforeCustomFields?.(formProps).component}

                        {(!renderBeforeCustomFields || renderBeforeCustomFields(formProps).canProceed) && (
                            <>
                                {!usernameValidationResult?.isInUse && (
                                    <InfoMessage
                                        message={
                                            <span className="existing-account-note">
                                                If you have used {Core.Constants.Company} before, use your existing
                                                username. Otherwise, enter your preferred username below.{' '}
                                                <Link target="_blank" to="/forgotUsername" rel="noopener noreferrer">
                                                    Click here if you've forgotten your username
                                                </Link>
                                                .
                                            </span>
                                        }
                                        type="info"
                                    />
                                )}
                                <fieldset className="form-group">
                                    <FormField
                                        description="Username"
                                        name="username"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            updateUsername(e.target.value)
                                        }
                                        placeholder="Username"
                                        type="text"
                                    />
                                </fieldset>
                                {renderUsernameNotice()}

                                {!!usernameValidationResult?.canProceed && (
                                    <ContentContainer shade={Core.Models.Shades.Dark40}>
                                        {usernameValidationResult.isInUse ? (
                                            <p className="user-exists-message">Welcome back!</p>
                                        ) : (
                                            <p className="user-exists-message">Create your account</p>
                                        )}

                                        {renderMiddleCustomFields?.(formProps)}

                                        {!usernameValidationResult?.isInUse && (
                                            <div className="form-group">
                                                <fieldset className="form-group form-group--undecorated">
                                                    {acceptEmail && (
                                                        <FormField
                                                            description="Email"
                                                            name="email"
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                updateEmail(e.target.value)
                                                            }
                                                            placeholder="Email"
                                                            type="email"
                                                        />
                                                    )}

                                                    {(!emailValidationResult || emailValidationResult.canProceed) && (
                                                        <>
                                                            <FormField
                                                                className="form-field--split"
                                                                description="First name"
                                                                name="firstName"
                                                                type="text"
                                                            />
                                                            <FormField
                                                                className="form-field--split"
                                                                description="Last name"
                                                                name="lastName"
                                                                type="text"
                                                            />
                                                            <FormField
                                                                component="date"
                                                                contextualProps={{ hidePicker: true }}
                                                                label={`Date of birth (${Core.Time.getFormat()})`}
                                                                name="birthdate"
                                                            />
                                                            {birthdateIsValid && isUnder13 && (
                                                                <>
                                                                    <FormField
                                                                        description="Parent email"
                                                                        name="parentEmail"
                                                                        placeholder="Parent email address"
                                                                        type="email"
                                                                    />
                                                                    <p className="m2x">
                                                                        Please read our{' '}
                                                                        <a
                                                                            href={
                                                                                Core.Constants
                                                                                    .CHILDRENS_PRIVACY_POLICY_URL
                                                                            }
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            Children's Privacy Policy
                                                                        </a>{' '}
                                                                        with your parent(s) and/or legal guardian(s) and
                                                                        ask them questions about what you do not
                                                                        understand.
                                                                    </p>
                                                                </>
                                                            )}
                                                            <input type="hidden" name="preferredTimezone" />
                                                        </>
                                                    )}
                                                </fieldset>
                                            </div>
                                        )}

                                        {!emailValidationResult?.canProceed && !!emailValidationResult?.error && (
                                            <InfoMessage message={emailValidationResult.error} type="error" />
                                        )}

                                        {!usernameValidationResult?.isInUse ? (
                                            <CreatePassword />
                                        ) : (
                                            <>
                                                <FormField type="password" name="password" description="Password" />
                                                <ForgotPasswordLink />
                                            </>
                                        )}

                                        {!!leagueFee && allowPayment?.(formProps) && (
                                            <ReviewPayable
                                                canDeferPayment={allowPayLater?.(formProps)}
                                                etherialMode
                                                hasFulfilledPayable={!!usernameValidationResult?.hasFulfilledPayable}
                                                onPaymentMethodSelected={(paymentMethodId: string) =>
                                                    formProps.setFieldValue(
                                                        'registrationFulfillment',
                                                        !!paymentMethodId
                                                            ? {
                                                                  payableId: leagueFee.id,
                                                                  paymentGatewayId: Core.Models.PaymentGateway.Stripe,
                                                                  paymentMethodId,
                                                              }
                                                            : undefined
                                                    )
                                                }
                                                payable={leagueFee}
                                            />
                                        )}

                                        {formData.customRegistrationFields.length > 0 && (
                                            <fieldset className="form-group">
                                                <CustomRegistrationFormFields
                                                    customRegistrationFields={formData.customRegistrationFields.map(
                                                        (field: Core.Models.CustomRegistrationField) =>
                                                            new Core.Models.CustomRegistrationFieldSubmissionResponse(
                                                                field,
                                                                ''
                                                            )
                                                    )}
                                                />
                                            </fieldset>
                                        )}

                                        {!usernameValidationResult?.isInUse &&
                                            !!organizationId &&
                                            formData.leagueGames.length > 0 && (
                                                <fieldset
                                                    id="leagueGames"
                                                    className="form-group form-group--undecorated"
                                                >
                                                    <GameInterestList
                                                        leagueGames={formData.leagueGames}
                                                        selectedGames={gameInterests}
                                                        setSelectedGames={(selectedGameInterests: string[]) =>
                                                            setGameInterests([...selectedGameInterests])
                                                        }
                                                    />
                                                </fieldset>
                                            )}

                                        {renderAfterCustomFields?.(formProps)}

                                        {!usernameValidationResult?.isInUse && (
                                            <fieldset className="form-group form-group--undecorated">
                                                <TermsFormFields />
                                            </fieldset>
                                        )}

                                        {![
                                            Core.Models.RegistrationAction.CreateLeague,
                                            Core.Models.RegistrationAction.CreateOrganization,
                                        ].includes(registrationAction) && (
                                            <FormField
                                                type="checkbox"
                                                name="extendExpiration"
                                                description="Keep me logged in for 30 days"
                                            />
                                        )}

                                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                                        <InfoMessage
                                            filter={formProps.touched}
                                            message={formProps.errors}
                                            type="error"
                                        />

                                        <fieldset className="form-group form-group--undecorated">
                                            {formProps.isSubmitting && <Loading buttonLoader />}
                                            <SolidButton
                                                as="button"
                                                disabled={formProps.isSubmitting}
                                                layout="full"
                                                onClick={formProps.submitForm}
                                                size="medium"
                                                type="submit"
                                            >
                                                {submitText}
                                            </SolidButton>
                                        </fieldset>
                                    </ContentContainer>
                                )}
                            </>
                        )}
                    </Form>
                );
            }}
        />
    );
};

export default RegistrationForm;

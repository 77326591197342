import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { isNumber, some } from 'lodash';

import * as Core from '../../../core';
import { HollowButton, TextButton, TextLink } from '../../../components/buttons-visuals';
import { useModal } from '../../../hooks/modal';
import { useAlternateSeasonName } from '../../../hooks/store';
import StartSeason from '../startSeason';

import './index.scss';

interface SeasonWizardProps {
    className?: string;
    reloadData: (showLoading: boolean) => Promise<void>;
    season: Core.Models.Season;
    show: 'recommedations' | 'steps';
}

const SeasonWizard: React.FunctionComponent<SeasonWizardProps> = (props) => {
    const { className, reloadData, season, show } = props;
    const seasonAlternateName = useAlternateSeasonName();

    const hasParticipants = season.participants.length > 0;
    const hasActiveFreeAgents = season.freeAgents?.some((fa: Core.Models.SeasonFreeAgent) => fa.isActive);
    const seasonIsFull =
        isNumber(season.maxParticipants) &&
        season.participants.filter((p: Core.Models.Participant) => p.isParticipating).length >= season.maxParticipants;

    const hasStages = season.stages.length > 0;
    const hasStartedStages = some(season.stages, (stage) => stage.currentState > Core.Models.StageState.NotStarted);
    const seasonHasStarted = season.currentState > Core.Models.CompetitionState.NotStarted;
    const seasonHasCompleted = season.currentState === Core.Models.CompetitionState.IsComplete;

    const [startSeasonNode, openStartSeason, closeStartSeason] = useModal(
        () => `Start ${seasonAlternateName}`,
        () => (
            <StartSeason
                onCancel={() => closeStartSeason()}
                onConfirm={async () => {
                    await reloadData(false);
                    closeStartSeason();
                }}
                season={season}
            />
        )
    );

    const loadRecommendation = () => {
        if (!hasStages)
            return (
                <div className={classNames(className, 'season-wizard-recommedations__item pt pb')}>
                    <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className="mr" />
                    Your {seasonAlternateName.toLowerCase()} isn't ready to start just yet. Let's add a stage to your{' '}
                    {seasonAlternateName.toLowerCase()}.
                    <HollowButton as="link" color="secondary" size="small" to={`/seasons/${season.id}/createStage`}>
                        Add a stage
                    </HollowButton>
                </div>
            );
        if (!hasParticipants)
            return (
                <div className={classNames(className, 'season-wizard-recommedations__item pt pb')}>
                    <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className="mr" />
                    Your {seasonAlternateName.toLowerCase()} isn't ready to start just yet. Let's add a some teams to
                    your {seasonAlternateName.toLowerCase()}.
                    <HollowButton
                        as="link"
                        color="secondary"
                        size="small"
                        to={`/seasons/${season.id}/editParticipants`}
                    >
                        Add some teams
                    </HollowButton>
                </div>
            );
        if (season.allowFreeAgents && hasActiveFreeAgents && !seasonIsFull)
            return (
                <div className={classNames(className, 'season-wizard-recommedations__item pt pb')}>
                    <FontAwesomeIcon icon={['fas', 'circle-info']} className="mr" />
                    Your {seasonAlternateName.toLowerCase()} isn't full yet. You can still add free agents to teams in
                    your {seasonAlternateName.toLowerCase()}.
                    <HollowButton
                        as="link"
                        color="secondary"
                        size="small"
                        to={`/seasons/${season.id}/assignFreeAgents`}
                    >
                        Assign free agents to teams
                    </HollowButton>
                </div>
            );
        if (!seasonHasStarted)
            return (
                <div className={classNames(className, 'season-wizard-recommedations__item pt pb')}>
                    <FontAwesomeIcon className="mr" icon={['fas', 'exclamation-triangle']} />
                    When you're satisfied with the number of participating teams, lock in teams to advance.
                    <HollowButton as="button" color="secondary" onClick={openStartSeason} size="small">
                        Lock in teams
                    </HollowButton>
                </div>
            );

        return <></>;
    };

    return (
        <>
            {!seasonHasCompleted && (
                <>
                    {show === 'steps' && (
                        <div className={classNames(className, 'season-wizard season-wizard--progress')}>
                            <div className="disp-flex align-center flex-flow-wrap text-small">
                                <span className="season-wizard__step color-gray">
                                    <FontAwesomeIcon className="color-success mr-half" icon={['fas', 'check']} />
                                    Create {seasonAlternateName.toLowerCase()}
                                    {/* this is always checked because it only shows on a created season */}
                                </span>

                                <span className="season-wizard__step">
                                    {hasStages ? (
                                        <span className="color-gray">
                                            <FontAwesomeIcon
                                                className="color-success mr-half"
                                                icon={['fas', 'check']}
                                            />
                                            Add first stage
                                        </span>
                                    ) : (
                                        <TextLink to={`/seasons/${season.id}/createStage`} className="text-small">
                                            <FontAwesomeIcon className="color-gray mr-half" icon={['fas', 'check']} />
                                            Add first stage
                                        </TextLink>
                                    )}
                                </span>

                                <span className="season-wizard__step">
                                    {seasonHasStarted ? (
                                        <span className="color-gray">
                                            <FontAwesomeIcon
                                                className="color-success mr-half"
                                                icon={['fas', 'check']}
                                            />
                                            Add teams
                                        </span>
                                    ) : (
                                        <TextLink to={`/seasons/${season.id}/editParticipants`} className="text-small">
                                            <FontAwesomeIcon
                                                className={classNames(
                                                    'mr-half',
                                                    hasParticipants ? 'color-success' : 'color-gray'
                                                )}
                                                icon={['fas', 'check']}
                                            />
                                            Add teams
                                        </TextLink>
                                    )}
                                </span>
                                {season.allowFreeAgents && (
                                    <span className="season-wizard__step">
                                        {seasonHasStarted ? (
                                            <span className="color-gray">
                                                <FontAwesomeIcon
                                                    className="color-success mr-half"
                                                    icon={['fas', 'check']}
                                                />
                                                Assign free agents
                                            </span>
                                        ) : (
                                            <TextLink
                                                className="text-small"
                                                to={`/seasons/${season.id}/assignFreeAgents`}
                                            >
                                                <FontAwesomeIcon
                                                    className={classNames(
                                                        'mr-half',
                                                        !hasActiveFreeAgents || seasonIsFull
                                                            ? 'color-success'
                                                            : 'color-gray'
                                                    )}
                                                    icon={['fas', 'check']}
                                                />
                                                Assign free agents
                                            </TextLink>
                                        )}
                                    </span>
                                )}

                                <span className="season-wizard__step">
                                    {seasonHasStarted ? (
                                        <span className="color-gray">
                                            <FontAwesomeIcon
                                                className="color-success mr-half"
                                                icon={['fas', 'check']}
                                            />
                                            Lock in teams
                                        </span>
                                    ) : (
                                        <TextButton onClick={openStartSeason} className="text-small">
                                            <FontAwesomeIcon className="color-gray mr-half" icon={['fas', 'check']} />
                                            Lock in teams
                                        </TextButton>
                                    )}
                                </span>

                                <span className="season-wizard__step">
                                    <span className={classNames({ 'color-gray': hasStartedStages })}>
                                        <FontAwesomeIcon
                                            className={classNames(
                                                'mr-half',
                                                hasStartedStages ? 'color-success' : 'color-gray'
                                            )}
                                            icon={['fas', 'check']}
                                        />
                                        Start first stage
                                    </span>
                                </span>
                            </div>
                        </div>
                    )}
                    {show === 'recommedations' && (
                        <div
                            className={classNames(
                                'season-wizard-recommedations',
                                season.allowFreeAgents && hasActiveFreeAgents && !seasonIsFull
                                    ? 'background-primary-light-40'
                                    : 'background-warning'
                            )}
                        >
                            <div className="global-container">{loadRecommendation()}</div>
                        </div>
                    )}
                    {startSeasonNode}
                </>
            )}
        </>
    );
};

export default SeasonWizard;

import React, { useMemo } from 'react';
import hexRgb from 'hex-rgb';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import * as Core from '../../core';
import ThemeVariables from './themeVariables';
import { IHasChildren } from '../../layouts/types';
import NotFoundPage from '../../pages/notFound';
import { AppState } from '../../store';
import ErrorDisplayPage from '../error/errorDisplayPage';
import Loading from '../loading';

export interface ThemeContainerProps extends IHasChildren {}

export const genericThemeColors: Core.Models.ThemeColors = {
    accent1: '#a04ce0',
    accent2: '#59cbd0',
    backgroundColor: '#464f61',
    error: '#c32d50',
    primaryButtonTextColor: '#272b35',
    primaryColor: '#e89f00',
    primaryTextColor: '#dbdde1',
    secondaryButtonTextColor: '#ffffff',
    secondaryColor: '#0d70f5',
    success: '#46ba80',
};

export const useThemeColors = () => {
    const leagueColors = useSelector(({ leagueState }: AppState) => leagueState.league?.league?.colors);
    return {
        ...genericThemeColors,
        ...(!!leagueColors && { ...leagueColors }),
    };
};

export const getTextColor = (rgbaString: string) => {
    try {
        const rgba = hexRgb(rgbaString);
        return rgba.red * 0.299 + rgba.green * 0.587 + rgba.blue * 0.114 > 160 ? 'color-black' : 'color-white';
    } catch (e) {
        // conversion failed (bad rgba), default to white
        return 'color-white';
    }
};

const ThemeContainer = (props: ThemeContainerProps): JSX.Element => {
    const { isLoading, loadingError } = useSelector(({ leagueState }: AppState) => leagueState);
    const themeColors = useThemeColors();

    const children = useMemo(() => {
        if (loadingError) {
            const errorCode = Core.API.getStatus(loadingError);
            if (errorCode === 404) {
                return (
                    <NotFoundPage
                        message={
                            <>
                                Sorry, the league you were looking for doesn't exist or an error has occurred. Go back,
                                or head over to <a href={Core.Constants.WEBSITE_URL}>{Core.Constants.WEBSITE_URL}</a> to
                                learn more about {Core.Constants.Company}.
                            </>
                        }
                        title="League Not Found"
                    />
                );
            }

            const message = Core.API.getErrorMessage(loadingError);
            return <ErrorDisplayPage fullPage message={message} />;
        }

        if (isLoading) {
            return <Loading blockItem />;
        }

        return props.children;
    }, [isLoading, loadingError, props.children]);

    return (
        <>
            <ThemeProvider theme={themeColors}>{children}</ThemeProvider>
            <ThemeVariables themeColors={themeColors} />
        </>
    );
};

export default ThemeContainer;

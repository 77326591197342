import * as React from 'react';

import ErrorDisplayPage, { ErrorDisplayPageProps } from './errorDisplayPage';
import AnalyticsService from '../../services/analyticsService';

export interface ErrorHandlerProps {
    errorComponent?: React.ComponentClass;
    render: () => JSX.Element;
    errorDisplayPageOptions?: ErrorDisplayPageProps;
}

interface ErrorHandlerState {
    hasRenderError: boolean;
}

export default class ErrorHandler extends React.Component<ErrorHandlerProps, ErrorHandlerState> {
    constructor(props: ErrorHandlerProps) {
        super(props);
        this.state = { hasRenderError: false };
    }
    public static getDerivedStateFromError(e: Error) {
        AnalyticsService.exception(e);
        console.error(e);
        return { hasRenderError: true };
    }
    public render() {
        const { hasRenderError } = this.state;
        const { errorComponent: ErrorComponent, render } = this.props;

        if (hasRenderError) {
            if (ErrorComponent) {
                return <ErrorComponent />;
            } else {
                return <ErrorDisplayPage {...this.props.errorDisplayPageOptions} />;
            }
        }

        return render();
    }
}

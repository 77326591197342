import React, { useEffect, useState } from 'react';
import pluralize from 'pluralize';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import { Button } from '../../../components/button';
import Loading from '../../../components/loading';
import withLoading, { WithLoadingProps } from '../../../components/withLoading';
import { useOrganizationTerm } from '../../../hooks/store';
import { UserService } from '../../../services/userService';

interface UpdateChatAbilityProps extends WithLoadingProps {
    onClose: () => void;
    participant: Core.Models.ChatThreadParticipant;
    seasonId?: string;
}

interface UserChatStatus {
    hasOrganizationRole: boolean;
    isEnabled: boolean;
}

const UpdateChatAbility = (props: UpdateChatAbilityProps) => {
    const { onClose, participant, seasonId, setError, setIsLoading } = props;

    const [userChatStatus, setUserChatStatus] = useState<UserChatStatus | undefined>(undefined);
    const [isConfirming, setIsConfirming] = useState<boolean>(false);

    const organizationTerm = useOrganizationTerm({ lowercase: true });

    useEffect(() => {
        (async () => {
            try {
                const response = await UserService.getUserChatStatus(participant.userId);
                setUserChatStatus(response);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [participant.userId, setError, setIsLoading]);

    const onSubmit = async () => {
        setIsConfirming(true);
        try {
            await UserService.setChatAbility({
                chatDisabled: userChatStatus!.isEnabled,
                userId: participant.userId,
                seasonId,
            });
            onClose();
        } catch (e) {
            toast.error(
                `There was an issue updating this user's ability to chat.  Please try again here or on the user's ${organizationTerm} page.`
            );
        } finally {
            setIsConfirming(false);
        }
    };

    if (!userChatStatus) return <></>;
    if (!userChatStatus.hasOrganizationRole)
        return (
            <>
                <p>
                    This user does not have a role on any {pluralize(organizationTerm)} in this league. A user must have
                    a role to update their chat ability.
                </p>
                <div className="thread-participant-list__close-button">
                    <Button onClick={onClose} styleType={Core.Models.StyleType.Secondary}>
                        Close
                    </Button>
                </div>
            </>
        );

    return (
        <>
            <p className="thread-participant-list__modal-text">
                Are you sure you would like to{' '}
                {userChatStatus.isEnabled ? (
                    <>
                        block <strong>{participant.name}</strong> from using
                    </>
                ) : (
                    <>
                        allow <strong>{participant.name}</strong> to use
                    </>
                )}{' '}
                chat?
            </p>
            <p>You can {userChatStatus.isEnabled ? 'un' : 're-'}block them at any time.</p>
            {isConfirming && <Loading buttonLoader />}
            <div className="confirm-modal__buttons">
                <Button disabled={isConfirming} onClick={onSubmit} outline styleType={Core.Models.StyleType.Secondary}>
                    Confirm
                </Button>
                <Button disabled={isConfirming} onClick={onClose} styleType={Core.Models.StyleType.Secondary}>
                    Cancel
                </Button>
            </div>
        </>
    );
};

export default withLoading(UpdateChatAbility, {
    loadingProps: { blockItem: true },
});

import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';

import { OrganizationRoleId, RegistrationAction, ValidateTokenResponse } from '@leaguespot/platform-js/src/models';
import ErrorDisplayPage from '../../components/error/errorDisplayPage';
import RegistrationForm, { RegistrationFormValues } from '../../components/registrationForm';
import RegistrationHeader from '../../components/registrationHeader';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useLeague, useLeagueConfiguration, useOrganizationTerm } from '../../hooks/store';
import { UserService } from '../../services/userService';

interface InviteTokenPageProps extends WithLoadingProps, RouteComponentProps<{ inviteCode: string }> {}

export interface InviteTokenFormValues extends RegistrationFormValues {
    gameInterests?: string[];
    inviteCode: string;
}

const InviteTokenPage = (props: InviteTokenPageProps): JSX.Element => {
    const {
        match: {
            params: { inviteCode },
        },
        setError,
        setIsLoading,
    } = props;

    const league = useLeague();
    const organizationTerm = useOrganizationTerm();
    const leagueConfiguration = useLeagueConfiguration();

    const [validateInviteCodeResponse, setValidateJoinCodeResponse] = useState<ValidateTokenResponse | undefined>(
        undefined
    );

    useEffect(() => {
        (async () => {
            try {
                const response = await UserService.validateJoin(inviteCode);
                setValidateJoinCodeResponse(response);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [inviteCode, setError, setIsLoading]);

    if (!validateInviteCodeResponse) return <></>;

    if (!!validateInviteCodeResponse && !validateInviteCodeResponse.isValid) {
        return (
            <ErrorDisplayPage
                fullPage
                message={`${validateInviteCodeResponse.invalidReason} Please contact your league host and ask to be re-invited`}
                title="Error: Invalid Invite Code"
            />
        );
    }

    if (!league) return <></>;
    return (
        <div>
            <RegistrationForm<InviteTokenFormValues>
                acceptEmail={false}
                allowPayLater={() => true}
                allowPayment={() => validateInviteCodeResponse?.roleId === OrganizationRoleId.Member}
                initialValues={{
                    inviteCode,
                }}
                header={
                    <RegistrationHeader
                        formInstructions="All fields are required."
                        message={`Participate in ${
                            !!validateInviteCodeResponse.organization
                                ? `${validateInviteCodeResponse.organization.name}'s esports team`
                                : `${league.name}'s official esports league`
                        }! Complete the form below to join.`}
                        showLoginPrompt
                        welcome={`Welcome to ${league.name}`}
                    />
                }
                leagueFee={leagueConfiguration?.leagueFee}
                organizationId={validateInviteCodeResponse.organization?.id}
                register={async (values: InviteTokenFormValues) => await UserService.join(values)}
                registrationAction={
                    !!validateInviteCodeResponse.organization?.id
                        ? RegistrationAction.JoinOrganizationViaInvite
                        : RegistrationAction.JoinLeagueViaInvite
                }
                schema={{
                    inviteCode: Yup.string().required('Invite code is required'),
                }}
                submitText={`Join ${!!validateInviteCodeResponse.organization ? organizationTerm : 'League'}`}
            />
        </div>
    );
};

export default withLoading(InviteTokenPage, {
    errorDisplayPageProps: { fullPage: false },
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

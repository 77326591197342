import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import * as Core from '../../core';
import { useIsPlatform } from '../../hooks/store';
import { AuthenticationService } from '../../services/authenticationService';
import { finalizeLogin, logout } from '../../store/login/actions';

const Authenticator = () => {
    const auth = useRef<HTMLIFrameElement>(null);
    const dispatch = useDispatch();
    const isPlatform = useIsPlatform();

    // set up auth window
    useEffect(() => {
        if (!!auth?.current) AuthenticationService.setAuthWindow(auth.current);
    }, [auth]);

    const handleMessage = useCallback(
        (event: MessageEvent<{ command: string; message: any; type: string }>) => {
            if (event.data.type !== 'auth') return;

            const token = event.data.message;
            switch (event.data.command) {
                case 'setJwt':
                    const userId = AuthenticationService.getUserId();
                    AuthenticationService.setToken(token.value, false); // this method only takes JWT string

                    // if the user wasn't authenticated, or if the user ID has changed, reload all the things
                    if (!userId || userId !== AuthenticationService.getUserId()) dispatch(finalizeLogin(token.value));
                    break;
                case 'clearJwt':
                    if (AuthenticationService.isAuthenticated()) {
                        dispatch(logout());
                    }
                    break;
                default:
                    break;
            }
        },
        [auth, dispatch]
    );

    // listen to embedded iframe
    useEffect(() => {
        window.addEventListener('message', handleMessage);

        return () => window.removeEventListener('message', handleMessage);
    }, [handleMessage]);

    if (isPlatform) return <></>; // no reason to load this on Platform since Platform is the SSoT for auth anyway
    return (
        <iframe
            loading="eager"
            ref={auth}
            sandbox="allow-same-origin allow-scripts"
            src={new URL('/auth.html', Core.Configuration.platformUrl).toString()}
            style={{ display: 'none' }}
        />
    );
};

export default Authenticator;

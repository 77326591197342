import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { isUndefined } from 'lodash';
import moment from 'moment-timezone';

import * as Core from '../../../../core';
import { TitleScoringProps } from '.';
import AccountSelector, { Account } from './accountSelector';
import { Image } from '../../../../components/image';
import InfoMessage from '../../../../components/infoMessage';
import Loading from '../../../../components/loading';
import { useTimezone } from '../../../../hooks/store';
import IntegrationService from '../../../../services/integrationService';

interface ValorantScoringProps extends TitleScoringProps {
    matchId: string;
}

const ValorantScoring = ({ matchId, setAutoScoringData }: ValorantScoringProps): JSX.Element => {
    const [puuid, setPuuid] = useState<string | undefined>(undefined);

    // clear auto scoring data if account isn't selected
    useEffect(() => {
        if (!puuid) setAutoScoringData(undefined);
    }, [puuid, setAutoScoringData]);

    return (
        <>
            <AccountSelector {...{ matchId, onAccountSelected: (account: Account) => setPuuid(account.id) }} />

            {puuid && <ValorantMatchFinder {...{ matchId, puuid, setAutoScoringData }} />}
        </>
    );
};

interface ValorantMatchFinderProps {
    matchId: string;
    puuid: string;
    setAutoScoringData: (data: string | undefined) => void;
}

const ValorantMatchFinder = ({ matchId, puuid, setAutoScoringData }: ValorantMatchFinderProps): JSX.Element => {
    const timezone = useTimezone();

    const [error, setError] = useState<string | undefined>(undefined);
    const [match, setMatch] = useState<Core.Models.ValorantMatchSummary | undefined>(undefined);
    const [matches, setMatches] = useState<Core.Models.ValorantMatchHistory[] | undefined>(undefined);
    const [selectedMatchId, setSelectedMatchId] = useState<string | undefined>();

    useEffect(() => {
        (async () => {
            setError(undefined);
            setMatches(undefined);
            setSelectedMatchId(undefined);

            try {
                const data = await IntegrationService.getValorantMatchHistory(matchId, puuid);
                setMatches(data);
            } catch (err) {
                setError('There was an issue loading matches. Please reopen this modal to try again.');
            }
        })();
    }, [matchId, puuid]);

    useEffect(() => {
        (async () => {
            setAutoScoringData(undefined);
            setError(undefined);
            setMatch(undefined);

            if (!selectedMatchId) return;

            try {
                const data = await IntegrationService.getValorantMatchSummary(matchId, selectedMatchId);
                setMatch(data);
                setAutoScoringData(selectedMatchId);
            } catch (err) {
                setError('There was an issue loading match. Please reopen this modal to try again.');
            }
        })();
    }, [matchId, selectedMatchId, setAutoScoringData]);

    return (
        <>
            <fieldset className="form-group match-list mb2x">
                {isUndefined(matches) ? (
                    <div className="p2x">
                        <p>Loading matches...</p>
                        <Loading />
                    </div>
                ) : (
                    <>
                        {matches.length <= 0 ? (
                            <div className="p2x">No matches found</div>
                        ) : (
                            <>
                                {matches.map((match: Core.Models.ValorantMatchHistory) => (
                                    <button
                                        className={classNames(
                                            'match-list__item p2x selectable disp-flex align-center full-width',
                                            match.matchId === selectedMatchId && 'selected'
                                        )}
                                        key={match.matchId}
                                        onClick={() => setSelectedMatchId(match.matchId)}
                                    >
                                        <div>
                                            <img
                                                className="mr2x"
                                                src="https://cdn.leaguespot.gg/static/media/titles/valorant/valorant.png"
                                                alt=""
                                                height="40"
                                                width="40"
                                            />
                                        </div>
                                        <div>
                                            {moment
                                                .tz(match.gameStartTimeUtc, timezone)
                                                .format(Core.Time.getFormat(true))}
                                        </div>
                                    </button>
                                ))}
                            </>
                        )}
                    </>
                )}
            </fieldset>

            {!!selectedMatchId && (
                <fieldset className="form-group match-summary mb2x">
                    <div className="p2x">
                        {!match ? (
                            <>
                                <p>Loading match...</p>
                                <Loading />
                            </>
                        ) : (
                            <>
                                <h3 className="heading-3">{match.map}</h3>
                                <p>
                                    <span className="weight-700">Start time:</span>{' '}
                                    {moment.tz(match.gameStartTimeUtc, timezone).format(Core.Time.getFormat(true))}
                                </p>
                                <p>
                                    <span className="weight-700">Duration:</span>{' '}
                                    {moment(match.durationInSeconds * 1000).format('mm:ss')}
                                </p>
                                <hr />
                                {match.teams.map((team: Core.Models.ValorantMatchSummaryTeam) => (
                                    <div className="mb3x" key={team.name}>
                                        <p className="mb">
                                            <span className="weight-700">Score:</span> {team.points}
                                            <span className={classNames('weight-700 icon ml', getWTLClass(team))}>
                                                {renderWTL(team)}
                                            </span>
                                        </p>
                                        {team.players.map((player: Core.Models.ValorantMatchSummaryPlayer) => (
                                            <div className="disp-flex align-center mb" key={player.username}>
                                                <div>
                                                    <Image
                                                        alt={player.agentName}
                                                        className="mr2x"
                                                        fallbackSrc="https://cdn.leaguespot.gg/static/media/titles/valorant/valorant.png"
                                                        height="40"
                                                        src={player.agentIconUrl}
                                                        width="40"
                                                    />
                                                </div>
                                                <div className="disp-flex flex-dir-column text-left">
                                                    <p className="mb0">
                                                        <span className="weight-700">{player.username}</span>#
                                                        {player.tagLine}
                                                    </p>
                                                    <p className="mb0">
                                                        {player.agentName} - {player.kills}/{player.deaths}/
                                                        {player.assists}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </fieldset>
            )}

            {!!error && <InfoMessage message={error} type="error" />}
        </>
    );
};

const getWTLClass = (team: Core.Models.ValorantMatchSummaryTeam) => {
    if (team.tied) return 'color-black';
    return team.winner ? 'color-success' : 'color-error';
};

const renderWTL = (team: Core.Models.ValorantMatchSummaryTeam) => {
    if (team.tied) return 'D';
    return team.winner ? 'W' : 'L';
};

export default ValorantScoring;

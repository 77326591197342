import * as Core from '../core';

export abstract class OrganizationService {
    public static async getOrganizationsForInviteCode(
        inviteCode: string
    ): Promise<Core.Models.AvailableOrganization[]> {
        const route = Core.API.ServerRoute.forAction('organizations', `available/${inviteCode}`);
        return await Core.API.getAnonymous(route);
    }

    public static async getJoinableOrganizations(): Promise<Core.Models.JoinableOrganization[]> {
        const route = Core.API.ServerRoute.forAction('organizations/joinable');
        return await Core.API.get(route);
    }

    public static async editOrganization(
        values: Core.Models.EditOrganizationCommand
    ): Promise<Core.Models.Organization> {
        const route = Core.API.ServerRoute.forAction('organizations');
        return await Core.API.patch(route, values);
    }

    public static async deleteOrganization(organizationId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('organizations', organizationId);
        return await Core.API.delete(route);
    }

    public static async uploadLogo(values: Core.Models.UploadLogoCommand): Promise<string> {
        const route = Core.API.ServerRoute.forAction('organizations', 'logo');
        return await Core.API.post(route, values);
    }

    public static async inviteUsers(command: Core.Models.InviteToOrganizationCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('organizations', 'users/invite');
        return await Core.API.post(route, command);
    }

    public static async resendUserInvites(command: Core.Models.ResendUserInvitesCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('organizations', 'users/invite');
        return await Core.API.patch(route, command);
    }

    public static getMembers(
        id: string,
        roleId: string,
        paginatedOptions: Core.Models.PaginatedOptions
    ): Promise<Core.Models.PaginatedResult<Core.Models.OrganizationMember>> {
        const route = Core.API.ServerRoute.forAction('organizations', `${id}/members/${roleId}`);
        return Core.API.get(route, Core.API.ApiVersion.v2, paginatedOptions);
    }

    public static async getAll(
        paginatedOptions: Core.Models.PaginatedOptions
    ): Promise<Core.Models.PaginatedResult<Core.Models.Organization>> {
        const route = Core.API.ServerRoute.forAction('organizations');
        return Core.API.get(route, Core.API.ApiVersion.v2, paginatedOptions);
    }

    public static async getById(id: string): Promise<Core.Models.Organization> {
        const route = Core.API.ServerRoute.forAction('organizations', id);
        return await Core.API.get(route);
    }

    public static getLocationString(organization: Core.Models.Organization) {
        const location = [organization.city, organization.state].filter((i) => i).join(', ');
        return location;
    }

    public static async deleteUserOrganizationRole(userOrganizationRoleId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('organizations', `roles/${userOrganizationRoleId}`);
        return await Core.API.delete(route);
    }

    public static async setChatDisabled(command: Core.Models.OrganizationUserSetChatDisabledCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('organizations', 'setChatDisabled');
        return await Core.API.post(route, command);
    }

    public static async setIsEligible(command: Core.Models.OrganizationUserSetIsEligibleCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('organizations', 'setIsEligible');
        return await Core.API.post(route, command);
    }

    public static async getStandings(id: string): Promise<Core.Models.RelevantGroupMergedStandingsResult> {
        const route = Core.API.ServerRoute.forAction('organizations', `${id}/teamStandings`);
        const response = await Core.API.get<Core.Models.RelevantGroupStandingsResult>(route);
        return {
            participantIds: response.participantIds,
            groups: [...response.rankedGroups, ...response.eliminationGroups],
        };
    }

    public static async getJoinOrganizationCode(organizationId: string): Promise<string> {
        const route = Core.API.ServerRoute.forAction('organizations', `joincodes/${organizationId}`);
        return await Core.API.get(route);
    }

    public static async createJoinOrganizationCode(
        command: Core.Models.CreateJoinOrganizationCodeCommand
    ): Promise<string> {
        const route = Core.API.ServerRoute.forAction('organizations', `joincodes`);
        return await Core.API.post(route, command);
    }

    public static async validateJoinCode(joinCode: string): Promise<Core.Models.ValidateJoinCodeResponse> {
        const route = Core.API.ServerRoute.forAction('organizations', `join/validate/${joinCode}`);
        return await Core.API.getAnonymous(route);
    }

    public static async getEligibleMembersToAdd(
        organizationId: string,
        teamId: string
    ): Promise<Core.Models.OrganizationMember[]> {
        const route = Core.API.ServerRoute.forAction(
            'organizations',
            `${organizationId}/${teamId}/eligibleMembersToAdd`
        );
        return await Core.API.get(route);
    }

    public static async changeUserOrganizationRole(
        command: Core.Models.ChangeUserOrganizationRoleCommand
    ): Promise<void> {
        const route = Core.API.ServerRoute.forAction('organizations', 'roles');
        return await Core.API.patch(route, command);
    }

    public static async getUnregisteredMembers(
        organizationId: string
    ): Promise<Core.Models.UnregisteredOrganizationMember[]> {
        const route = Core.API.ServerRoute.forAction('organizations', `unregistered/${organizationId}`);
        return await Core.API.get(route);
    }

    public static getOrganizationGames(organizationId: string): Promise<Core.Models.OrganizationGame[]> {
        const route = Core.API.ServerRoute.forAction('organizations', `${organizationId}/teamsSummary`);
        return Core.API.get(route);
    }

    public static getTeamsByOrganizationAndGame(
        organizationId: string,
        gameId: string,
        paginatedOptions: Core.Models.PaginatedOptions
    ): Promise<Core.Models.PaginatedResult<Core.Models.Team>> {
        const route = Core.API.ServerRoute.forAction('organizations', `${organizationId}/teams/${gameId}`);
        return Core.API.get(route, Core.API.ApiVersion.v2, paginatedOptions);
    }

    public static getUpcomingMatches(
        organizationId: string,
        paginatedOptions: Core.Models.PaginatedOptions
    ): Promise<Core.Models.PaginatedResult<Core.Models.Match>> {
        const route = Core.API.ServerRoute.forAction('organizations', `${organizationId}/matches`);
        return Core.API.get(route, Core.API.ApiVersion.v1, paginatedOptions);
    }
}

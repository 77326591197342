import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import * as Core from '../../../core';
import { Button } from '../../../components/button';
import InfoMessage from '../../../components/infoMessage';
import LiteLimitationMessage from '../../../components/liteLimitationMessage';
import { useAlternateSeasonName, useIsLite, useOrganizationTerm } from '../../../hooks/store';
import history from '../../../services/history';
import { SeasonService } from '../../../services/seasonService';

import './index.scss';

interface SeasonParticipantsProps {
    season: Core.Models.Season;
    seasonTeams: Core.Models.SeasonEligibleTeam[];
    showControls: boolean;
}

const SeasonParticipants = (props: SeasonParticipantsProps) => {
    const { season, seasonTeams, showControls } = props;

    const [eligibleTeams, setEligibleTeams] = useState<Core.Models.SeasonEligibleTeam[]>(seasonTeams);
    const [error, setError] = useState<string | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => setEligibleTeams(seasonTeams), [seasonTeams]);

    const seasonAlternateName = useAlternateSeasonName({ lowercase: true });
    const organizationTerm = useOrganizationTerm();

    const validEligibleTeams = useMemo(
        () => eligibleTeams.filter((t: Core.Models.SeasonEligibleTeam) => !t.isParticipatingInAnotherSeason),
        [eligibleTeams]
    );

    const numTeamsInThisSeason = useMemo(
        () => eligibleTeams.filter((t: Core.Models.SeasonEligibleTeam) => t.isParticipatingInThisSeason).length,
        [eligibleTeams]
    );

    const isLite = useIsLite();
    const maxParticipants = useMemo(() => {
        if (isLite)
            return Math.ceil(Core.Constants.PLANS.LITE.LIMITATIONS.MAX_PLAYERS_PER_SEASON / season.game.minimumSeats);
        return undefined;
    }, [isLite, season.game.minimumSeats]);

    const handleSaveClick = async () => {
        if (!showControls) return;

        setIsSubmitting(true);

        const teamIds = new Set<string>(
            validEligibleTeams
                .filter((t: Core.Models.SeasonEligibleTeam) => t.isParticipatingInThisSeason)
                .map((t: Core.Models.SeasonEligibleTeam) => t.teamId)
        );

        try {
            await SeasonService.setSeasonTeams({ seasonId: season.id, teamIds: Array.from(teamIds) });
            history.push(`/seasons/${season.id}`);
        } catch (error) {
            const message = Core.API.getErrorMessage(error);
            setError(message);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleTeamClick = (teamId: string) => {
        if (!showControls) return;

        const index = eligibleTeams.findIndex((t: Core.Models.SeasonEligibleTeam) => t.teamId === teamId);
        if (index < 0) return; // not found. not sure how this would happen but may as well check

        // ignore teams that are participating in another season already. they cannot be selected
        if (eligibleTeams[index].isParticipatingInAnotherSeason) return;

        // ignore teams if the max participants amount has been reached and this team isn't participating in this season
        if (
            !!maxParticipants &&
            numTeamsInThisSeason >= maxParticipants &&
            !eligibleTeams[index].isParticipatingInThisSeason
        )
            return;

        // toggle participating value for this season
        eligibleTeams[index].isParticipatingInThisSeason = !eligibleTeams[index].isParticipatingInThisSeason;

        setEligibleTeams([...eligibleTeams]); // spread to create a new array. this triggers rerender
    };

    const setAllParticipatingValue = (participating: boolean): void => {
        validEligibleTeams.forEach(
            (t: Core.Models.SeasonEligibleTeam) => (t.isParticipatingInThisSeason = participating)
        );

        setEligibleTeams([...eligibleTeams]);
    };

    const renderHeaderStatusIcon = (): JSX.Element => {
        if (!showControls) return <></>;

        const anySelected = eligibleTeams.some((t: Core.Models.SeasonEligibleTeam) => t.isParticipatingInThisSeason);
        if (!anySelected)
            return <FontAwesomeIcon icon={['fas', 'square']} onClick={() => setAllParticipatingValue(true)} />;

        const allSelected = validEligibleTeams.every(
            (t: Core.Models.SeasonEligibleTeam) => t.isParticipatingInThisSeason
        );
        if (allSelected)
            return <FontAwesomeIcon icon={['fas', 'square-check']} onClick={() => setAllParticipatingValue(false)} />;

        return <FontAwesomeIcon icon={['fas', 'square-minus']} onClick={() => setAllParticipatingValue(false)} />;
    };

    const renderStatusIcon = (team: Core.Models.SeasonEligibleTeam) => {
        if (team.isParticipatingInAnotherSeason) return <FontAwesomeIcon icon={['fas', 'circle-xmark']} />;
        if (team.isParticipatingInThisSeason) return <FontAwesomeIcon icon={['fas', 'square-check']} />;
        if (!!maxParticipants && numTeamsInThisSeason >= maxParticipants)
            return <FontAwesomeIcon icon={['fas', 'circle-xmark']} />;

        return <FontAwesomeIcon icon={['fas', 'square']} />;
    };

    return (
        <div>
            {isLite && (
                <LiteLimitationMessage
                    message={`${Core.Constants.PLANS.LITE.NAME} only allows a maximum of ${Core.Constants.PLANS.LITE.LIMITATIONS.MAX_PLAYERS_PER_SEASON} players per ${seasonAlternateName} (${maxParticipants} teams for ${season.game.name}).`}
                />
            )}
            {showControls && (
                <div className="season-participants__controls">
                    <Button
                        onClick={() => history.push(`/seasons/${season.id}`)}
                        styleType={Core.Models.StyleType.Secondary}
                        disabled={isSubmitting}
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleSaveClick} disabled={isSubmitting}>
                        Save
                    </Button>
                    <InfoMessage className="mt2x" message={error} type="error" />
                </div>
            )}
            <div className="season-participants__items">
                <div className="season-participants__items__num-selected">
                    {numTeamsInThisSeason}/{validEligibleTeams.length} selected
                </div>
                <div className="season-participants__items__header">
                    <div>{renderHeaderStatusIcon()}</div>
                    <div>{organizationTerm}</div>
                    <div>Team</div>
                    <div>Captains</div>
                    <div>Roster size</div>
                </div>
                {eligibleTeams.length <= 0 ? (
                    <p className="season-participants__items__empty">
                        There aren't any teams in this league who are playing {season.game.name} yet
                    </p>
                ) : (
                    eligibleTeams.map((team: Core.Models.SeasonEligibleTeam) => (
                        <div
                            key={team.teamId}
                            className={classNames(
                                'season-participants__items__item',
                                !showControls && 'disabled',
                                team.isParticipatingInAnotherSeason && 'unavailable'
                            )}
                            onClick={() => handleTeamClick(team.teamId)}
                        >
                            <div>{renderStatusIcon(team)}</div>
                            <div>{team.organizationName}</div>
                            <div>
                                <Link to={`/teams/${team.teamId}`} target="blank">
                                    {team.name}
                                </Link>
                                {team.isParticipatingInAnotherSeason && (
                                    <p>
                                        <em>Participating in another {seasonAlternateName}</em>
                                    </p>
                                )}
                                {team.hasNotEnoughPlayers && (
                                    <p>
                                        <em>Does not have enough players</em>
                                    </p>
                                )}
                            </div>
                            <div>
                                {team.captains.map((captain: Core.Models.Captain, index: number) => (
                                    <div key={index}>{Core.Identity.renderMemberName(captain)}</div>
                                ))}
                            </div>
                            <div>
                                {team.rosterNumerator} / {team.rosterDenominator}
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default SeasonParticipants;

import * as React from 'react';

import ChangePasswordForm, { ChangePasswordFormValues } from './form';
import { TextButton } from '../../../components/buttons-visuals';
import Modal from '../../../components/modal';
import { UserService } from '../../../services/userService';

const ChangePassword: React.FunctionComponent = () => {
    const [isChangingPassword, setIsChangingPassword] = React.useState(false);
    const onSubmit = async (values: ChangePasswordFormValues) => {
        await UserService.changePassword({
            currentPassword: values.currentPassword,
            newPassword: values.password,
        });
        setIsChangingPassword(false);
    };

    return (
        <div className="change-password">
            <TextButton onClick={() => setIsChangingPassword(true)}>Change Password</TextButton>
            {isChangingPassword && (
                <Modal onClose={() => setIsChangingPassword(false)} title="Change Password">
                    <ChangePasswordForm onSubmit={onSubmit} />
                </Modal>
            )}
        </div>
    );
};

export default ChangePassword;

import React from 'react';
import classNames from 'classnames';

import './Checkbox.scss';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: React.ReactNode;
}

// input type="checkbox" with our special formatting
const Checkbox = ({ className, id, label, ...rest }: CheckboxProps) => {
    return (
        <div className={classNames(className, 'ds-checkbox')}>
            <input className="ds-checkbox__input" type="checkbox" {...rest} />
            <div
                className={classNames('ds-checkbox__choice choice-style', {
                    'ds-checkbox__choice--checked': rest.checked,
                })}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};

export default Checkbox;

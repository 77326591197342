import * as React from 'react';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';

import * as Core from '../../../core';
import { SolidButton } from '../../../components/buttons-visuals';
import InfoMessage from '../../../components/infoMessage';
import { CreatePassword } from '../../../components/inputs';
import RegistrationHeader from '../../../components/registrationHeader';
import { UserService } from '../../../services/userService';

interface ResetPasswordRouteProps {
    code: string;
    userId: string;
}

interface ResetPasswordProps extends RouteComponentProps<ResetPasswordRouteProps> {}

interface ResetPasswordFormValues {
    password: string;
    passwordConfirmation: string;
}

const passwordSchema = Yup.object().shape({
    password: Core.Validation.password('Password'),
    passwordConfirmation: Yup.string()
        .required('Password Confirmation is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const ResetPassword = (props: ResetPasswordProps) => {
    const [success, setSuccess] = React.useState<boolean>(false);

    const onSubmit = async (values: ResetPasswordFormValues, actions: FormikActions<ResetPasswordFormValues>) => {
        try {
            await UserService.resetPassword({
                newPassword: values.password,
                code: props.match.params.code,
                userId: props.match.params.userId,
            });
            setSuccess(true);
        } catch (error) {
            const message = Core.API.getErrorMessage(error);
            actions.setStatus(message);
        } finally {
            actions.setSubmitting(false);
        }
    };

    if (success)
        return (
            <RegistrationHeader
                message={
                    <>
                        <p className="mb2x">You can now use your new password to log into your account.</p>
                        <SolidButton as="link" to="/login">
                            Login
                        </SolidButton>
                    </>
                }
            />
        );

    return (
        <div className="global-container-centered">
            <Formik
                initialValues={{ password: '', passwordConfirmation: '' }}
                onSubmit={onSubmit}
                render={(formProps: FormikProps<ResetPasswordFormValues>) => (
                    <Form className="form">
                        <RegistrationHeader message="Please enter and confirm your new password." />
                        <CreatePassword />

                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                        <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                        <SolidButton
                            as="button"
                            layout="full"
                            onClick={formProps.submitForm}
                            pending={formProps.isSubmitting}
                        >
                            Save my new password
                        </SolidButton>
                    </Form>
                )}
                validationSchema={passwordSchema}
            />
        </div>
    );
};

export default ResetPassword;

import * as ReduxThunk from 'redux-thunk';

import { STARTUP_SUCCESS } from './types';
import { AppAction, AppState } from '..';
import { AuthenticationService } from '../../services/authenticationService';
import ConsentService from '../../services/consentService';
import CookiesService from '../../services/cookiesService';
import { consentSuccessAction } from '../consent/actions';
import { cookiesSuccessAction } from '../cookies/actions';
import { getLeague } from '../league/actions';
import { loginClearPreloadedData, loginSuccess, preloadData } from '../login/actions';
import { TokenResult } from '../login/types';

export const startupSuccessAction = (isPlatform: boolean) => {
    return {
        type: STARTUP_SUCCESS,
        payload: isPlatform,
    };
};

export const startup =
    (isPlatform: boolean): ReduxThunk.ThunkAction<void, AppState, null, AppAction> =>
    async (dispatch, getState) => {
        // run this first thing since all it does is set `isPlatform` and is synchronous
        dispatch(startupSuccessAction(isPlatform));

        // fetch the league (if not platform)
        const { startupState } = getState();
        if (!startupState.isPlatform) {
            await dispatch(getLeague());
        }

        // set existing cookies preferences
        const cookies = CookiesService.getCookies();
        if (!!cookies) {
            dispatch(cookiesSuccessAction(cookies));
        }

        // set existing consent preferences
        const consent = ConsentService.getConsent();
        if (!!consent) {
            dispatch(consentSuccessAction(consent));
        }

        // reload authenticated version of data if auth token is present
        if (AuthenticationService.isAuthenticated()) {
            const token = AuthenticationService.getToken();
            if (token) {
                dispatch(loginClearPreloadedData());
                dispatch(loginSuccess({ token } as TokenResult));
                dispatch(preloadData());
            }
        }
    };

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Core from '../../core';
import { SearchService } from '../../services/searchService';
import { TertiaryButton } from '../buttons-visuals';
import ErrorMessage from '../errorMessage';
import { withLoadDataDefaultConfig } from '../loadData';
import Loading from '../loading';

interface SearchResultProps {
    search?: string;
    searchResults?: Core.Models.SearchResult[];
    clearAndCloseSearch: () => void;
    isLoading: boolean;
    loadingError?: Core.Models.AppError;
}

function getLink(item: Core.Models.SearchResult) {
    switch (item.type) {
        case 'Organization':
            return `/organizations/${item.id}`;
        case 'Season':
            return `/seasons/${item.id}`;
        case 'Team':
            return `/teams/${item.id}`;
        case 'User':
            return `/users/${item.id}`;
    }
    return '/';
}

export const SearchResults: React.FunctionComponent<SearchResultProps> = (props) => {
    const { isLoading, loadingError } = props;

    if (isLoading) {
        return <Loading />;
    }

    if (loadingError) {
        return (
            <div className="site-search__results site-search__results--error">
                <ErrorMessage error={Core.API.getErrorMessage(loadingError)} />
            </div>
        );
    }

    if (!props.searchResults) {
        return <></>;
    }

    return (
        <div className="site-search__results">
            <div className="site-search__results-help-text">
                {props.search && <span>Results for "{props.search}"</span>}
                <TertiaryButton as="button" className="site-search__clear-button" onClick={props.clearAndCloseSearch}>
                    Clear Search
                    <FontAwesomeIcon icon={['fas', 'xmark']} size="xl" className="ml" />
                </TertiaryButton>
            </div>
            {props.searchResults.length === 0 ? (
                <div className="site-search__result">No results</div>
            ) : (
                <ul className="site-search__results-list">
                    {props.searchResults.map((i, ix) => (
                        <li key={ix} className="site-search__result">
                            <TertiaryButton
                                as="link"
                                className="site-search__result-link"
                                onClick={props.clearAndCloseSearch}
                                to={getLink(i)}
                            >
                                {i.entityName
                                    ? i.entityName
                                    : Core.Identity.renderMemberName({
                                          firstName: i.userFirstName,
                                          lastName: i.userLastName,
                                          pronouns: i.userPronouns,
                                      })}
                            </TertiaryButton>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

// search result is a separate component partly to let us leverage what withLoadDataDefaultConfig does for us re: loading, error, and duplicate-load handling
export default withLoadDataDefaultConfig(
    SearchResults,
    (props) => {
        return { search: props.search };
    },
    async (props) => {
        if (!props.search) {
            return [];
        }
        return { searchResults: await SearchService.search(props.search) };
    }
);

import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import { SolidButton } from '../../../components/buttons-visuals';
import { ToolTip } from '../../../components/overlays';
import { useConfirmModal } from '../../../hooks/confirmModal';
import PaymentGatewayService from '../../../services/paymentGatewayService';

const FulfillmentRow = (props: {
    canRefund: boolean;
    fulfillment: Core.Models.PayableFulfillment;
    reloadData: (showLoading: boolean) => Promise<void>;
    setFulfillments: Dispatch<SetStateAction<Core.Models.PayableFulfillment[]>>;
}): JSX.Element => {
    const { canRefund, fulfillment, reloadData, setFulfillments } = props;

    const [isRefunding, setIsRefunding] = useState<boolean>(false);

    const entityName = useMemo(
        () => (fulfillment.entityType === Core.Models.PayableFulfillmentEntityType.Season ? 'team' : 'user'),
        [fulfillment.entityType]
    );

    const [refundFulfillmentModal, openRefundFulfillmentModal, closeRefundFulfillmentModal] = useConfirmModal(
        () => `Refund entry fee`,
        () => {
            if (!isRefunding) return <></>;
            if (!fulfillment.payable) return <></>;

            return (
                <>
                    <p>
                        Are you sure you want to refund <strong>{fulfillment.payable.name}</strong> for{' '}
                        <strong>{Core.Currency.convertNumberToCurrency(fulfillment.amount)}</strong>?
                    </p>
                    <p>
                        Note: this operation does not automatically remove this {entityName} from competition, though
                        they will not be able to participate until this payment is completed.
                    </p>
                </>
            );
        },
        async () => {
            if (!isRefunding) return;

            try {
                await PaymentGatewayService.refundPayableFulfillment(fulfillment.id);

                // update state
                setFulfillments((currentFulfillments: Core.Models.PayableFulfillment[]) => {
                    const fulfillmentIndex = currentFulfillments.findIndex(
                        (f: Core.Models.PayableFulfillment) => f.id === fulfillment.id
                    );
                    if (fulfillmentIndex < 0) return currentFulfillments;

                    currentFulfillments[fulfillmentIndex].refunded = true;
                    return [...currentFulfillments];
                });

                setIsRefunding(false);
                closeRefundFulfillmentModal();
                await reloadData(false);
            } catch (e) {
                const message = Core.API.getErrorMessage(e);
                toast.error(`Something went wrong while refunding this entry fee: ${message}`);
            }
        }
    );

    if (!fulfillment.payable) return <></>;

    return (
        <div className="fulfillments-list__items__item">
            <div>{moment(fulfillment.fulfilledDateUtc).format('MMM DD, YYYY hh:mm a')}</div>
            <div>{fulfillment.payable.name}</div>
            <div>
                {!!fulfillment.teamId && <Link to={`/teams/${fulfillment.teamId}`}>{fulfillment.teamName}</Link>}
                {!!fulfillment.userId && <Link to={`/users/${fulfillment.userId}`}>{fulfillment.userName}</Link>}
            </div>
            <div className={classNames(fulfillment.refunded && 'strikethrough')}>
                {fulfillment.overridden && (
                    <ToolTip
                        trigger={
                            <FontAwesomeIcon className="mr" icon={['fas', 'shuffle']} size="1x" title="Overridden" />
                        }
                    >
                        <>
                            <p className="text-left">
                                This fee was overridden, meaning that it was set as having been paid even though a
                                payment was not made from within this platform.
                            </p>
                            <p className="text-left">
                                This would typically happen when the fee was paid in another way, such as with a digital
                                wallet or a cash transaction.
                            </p>
                        </>
                    </ToolTip>
                )}
                {Core.Currency.convertNumberToCurrency(fulfillment.amount)}
            </div>
            <div>
                {fulfillment.refunded ? (
                    <span>Refunded</span>
                ) : (
                    canRefund && (
                        <>
                            <SolidButton
                                as="button"
                                color="destructive"
                                onClick={() => {
                                    setIsRefunding(true);
                                    openRefundFulfillmentModal();
                                }}
                            >
                                Refund
                            </SolidButton>
                            {refundFulfillmentModal}
                        </>
                    )
                )}
            </div>
        </div>
    );
};

export default FulfillmentRow;

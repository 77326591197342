import React, { useCallback } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { useConsent } from '../../hooks/store';
import ConsentService from '../../services/consentService';
import { consentSuccessAction } from '../../store/consent/actions';
import { ConsentState } from '../../store/consent/types';
import { Button } from '../button';
import { ToggleSwitch } from '../inputs';
import Loading from '../loading';
import Modal from '../modal';

interface TwitchSettingsModalProps {
    onClose: () => void;
}

const TwitchSettingsModal = (props: TwitchSettingsModalProps): JSX.Element => {
    const { onClose } = props;
    const consent = useConsent();
    const dispatch = useDispatch();

    const setConsent = useCallback(
        (preferences: ConsentState) => {
            const config = { ...preferences, lastUpdatedUtc: new Date().getTime() };
            dispatch(consentSuccessAction(config));
            ConsentService.setConsent(config);
        },
        [dispatch]
    );

    return (
        <Modal onClose={onClose} title={`LeagueView Settings`}>
            <Formik
                initialValues={Object.assign({
                    twitch: !!consent?.twitch,
                })}
                validationSchema={Yup.object().shape({
                    twitch: Yup.boolean(),
                })}
                onSubmit={async (values, actions) => {
                    actions.setStatus(undefined);
                    setConsent(values);
                    onClose();
                    actions.setSubmitting(false);
                }}
                render={(formProps: FormikProps<ConsentState>) => (
                    <Form className="form">
                        <p className="cookie_settings__text">
                            LeagueView works by embedding Twitch streams in your browser. Twitch in turn loads cookies
                            into your browser in order to track your identity and viewing behavior. By providing your
                            consent you agree to allow these cookies to be stored in your browser in order to use
                            LeagueView.
                        </p>
                        <p className="cookie_settings__text">
                            For more information on cookies that Twitch uses, please see review their{' '}
                            <a
                                href="https://www.twitch.tv/p/en/legal/cookie-notice/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Cookie Notice
                            </a>
                            .
                        </p>
                        <fieldset className="form-group form-group--undecorated">
                            <div className="cookie_settings__setting">
                                <div className="cookie_settings__setting_name">
                                    <label className="cookie_settings__setting_name_label">Enable LeagueView</label>
                                </div>
                                <ToggleSwitch
                                    onToggleSwitch={(value: boolean) =>
                                        Promise.resolve(formProps.setFieldValue('twitch', value))
                                    }
                                    value={!!formProps.values.twitch}
                                />
                            </div>
                        </fieldset>

                        <fieldset className="form-group form-group--undecorated">
                            {formProps.isSubmitting && <Loading buttonLoader />}
                            <Button onClick={formProps.submitForm} disabled={formProps.isSubmitting} wide round>
                                Submit
                            </Button>
                        </fieldset>
                    </Form>
                )}
            />
        </Modal>
    );
};

export default TwitchSettingsModal;

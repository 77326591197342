import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import * as Core from '../../core';
import SeasonCard from '../../components/seasonCard';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useAlternateSeasonName } from '../../hooks/store';
import { SeasonService } from '../../services/seasonService';

interface MySeasonsProps extends WithLoadingProps {
    seasonJoinedEvent: object;
}

const MySeasons = (props: MySeasonsProps) => {
    const { setError, setIsLoading, seasonJoinedEvent } = props;

    const seasonAlternateName = useAlternateSeasonName();

    const [seasons, setSeasons] = useState<Core.Models.Season[]>([]);

    const load = useCallback(async () => {
        try {
            const response = await SeasonService.getMySeasons();
            setSeasons(response);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [setError, setIsLoading]);

    useEffect(() => {
        (async () => {
            await load();
        })();
    }, [load, seasonJoinedEvent]);

    return (
        <div className="my-seasons">
            <h2>
                My <span className="theme__text__alternate">{seasonAlternateName}s</span>
            </h2>
            {seasons.length > 0 ? (
                <div className="league-page__seasons__season-container">
                    {seasons.map((season: Core.Models.Season) => (
                        <SeasonCard key={season.id} season={season} showImage showSeasonOperator />
                    ))}
                </div>
            ) : (
                <p>
                    Looks like you aren't a part of any {seasonAlternateName.toLowerCase()}s yet. Check out some of the
                    suggested {seasonAlternateName.toLowerCase()}s below, or head over to your{' '}
                    <Link to={'/league'}>league home</Link> to discover upcoming {seasonAlternateName.toLowerCase()}s.
                </p>
            )}
        </div>
    );
};

export default withLoading(MySeasons, {
    loadingProps: { blockItem: true },
});

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FormikProps, getIn } from 'formik';
import { isNumber, orderBy } from 'lodash';
import moment from 'moment-timezone';
import { RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import Select, { SelectOption } from 'react-select';
import * as Yup from 'yup';

import * as Core from '../../core';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';
import Markdown from '../../components/markdown';
import RegistrationForm, { RegistrationFormValues } from '../../components/registrationForm';
import RegistrationHeader from '../../components/registrationHeader';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import {
    useAlternateSeasonName,
    useLeague,
    useLeagueConfiguration,
    useOrganizationTerm,
    useTimezone,
} from '../../hooks/store';
import { SeasonService } from '../../services/seasonService';
import { UserService } from '../../services/userService';

import './index.scss';

interface JoinSeasonPageRouteProps {
    seasonId: string;
}

enum JoinSeasonPreference {
    CreateTeam = 0,
    JoinTeam = 1,
    FreeAgent = 2,
}

type JoinSeasonPreferenceSelection = Core.Models.DropdownSelection | JoinSeasonPreference;
// eslint-disable-next-line
const JoinSeasonPreferenceSelection = { ...Core.Models.DropdownSelection, ...JoinSeasonPreference };

export interface JoinSeasonFormValues extends RegistrationFormValues {
    agreedToSeasonTerms: boolean;
    gameRanking: string;
    joinSeasonPreference: JoinSeasonPreferenceSelection;
    organizationId: string;
    seasonId: string;
    teamJoinCode: string;
    teamName: string;
}

interface JoinSeasonPageProps extends RouteComponentProps<JoinSeasonPageRouteProps>, WithLoadingProps {}

const joinSeasonPreferences: number[] = Object.values(JoinSeasonPreferenceSelection)
    .filter((value: number | string) => isNumber(value))
    .map((value: number | string) => +value);

const JoinSeasonPage = (props: JoinSeasonPageProps) => {
    const {
        match: {
            params: { seasonId },
        },
        setError,
        setIsLoading,
    } = props;

    const league = useLeague();
    const timezone = useTimezone();
    const seasonAlternateName = useAlternateSeasonName();
    const organizationTerm = useOrganizationTerm();
    const membersCanCreateTeams = useMemo(() => !!league?.membersCanCreateTeams, [league?.membersCanCreateTeams]);
    const leagueConfiguration = useLeagueConfiguration();

    const [season, setSeason] = useState<Core.Models.Season | undefined>(undefined);
    const [organizations, setOrganizations] = useState<Core.Models.BasicOrganization[]>([]);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | undefined>(undefined);
    const [hasOrganizationRole, setHasOrganizationRole] = useState<boolean>(false);
    const [usernameValidationResult, setUsernameValidationResult] = useState<
        Core.Models.IdentifierValidationResult | undefined
    >(undefined);

    const load = useCallback(async () => {
        try {
            const response = await SeasonService.getJoinSeasonDetails(seasonId);
            setSeason(response.season);
            setOrganizations(orderBy(response.organizations, (o: Core.Models.BasicOrganization) => o.name));

            // if there's only one organization, go ahead and select it
            if (response.organizations.length === 1) setSelectedOrganizationId(response.organizations[0].id);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [seasonId, setError, setIsLoading]);

    useEffect(() => {
        (async () => {
            await load();
        })();
    }, [load]);

    const options: SelectOption[] = useMemo(
        () => organizations.map((o: Core.Models.BasicOrganization) => ({ label: o.name, value: o.id })),
        [organizations]
    );

    const isFull = useMemo(
        () =>
            !!season &&
            isNumber(season.maxParticipants) &&
            season.participants.filter((p: Core.Models.Participant) => p.isParticipating).length >=
                season.maxParticipants,
        [season]
    );

    const hasGameRankings = useMemo(() => Core.Competition.hasRankings(season?.game), [season?.game]);
    const gameRankings = useMemo(() => Core.Competition.getRankings(season?.game), [season?.game]);

    const ageRange = useMemo(() => {
        if (!season?.ageMin && !season?.ageMax) return 'All ages';

        if (!!season?.ageMin && !season?.ageMax) return `You must be age ${season.ageMin} or over`;

        if (!season?.ageMin && !!season?.ageMax) return `You must be age ${season.ageMax} or under`;

        return `You must be between age ${season.ageMin} and ${season.ageMax} to participate`;
    }, [season]);

    if (!league) return <></>;
    if (!season) return <></>;
    return (
        <div className="mb8x global-container-centered">
            <RegistrationForm<JoinSeasonFormValues>
                acceptEmail={true}
                allowPayment={(formProps: FormikProps<JoinSeasonFormValues>) =>
                    +formProps.values.joinSeasonPreference !== JoinSeasonPreferenceSelection.Unselected
                }
                allowPayLater={(formProps: FormikProps<JoinSeasonFormValues>) =>
                    +formProps.values.joinSeasonPreference === JoinSeasonPreferenceSelection.FreeAgent
                }
                initialValues={{
                    agreedToSeasonTerms: false,
                    gameRanking: '',
                    joinSeasonPreference:
                        season.game.minimumSeats === 1
                            ? JoinSeasonPreferenceSelection.CreateTeam
                            : JoinSeasonPreferenceSelection.Unselected,
                    organizationId: selectedOrganizationId ?? '',
                    seasonId: season.id,
                    teamJoinCode: '',
                    teamName: '',
                }}
                header={
                    <RegistrationHeader
                        formInstructions="All fields are required."
                        message={
                            <>
                                {!!season.game.cardUrl && (
                                    <img
                                        className="season-registration__season-game-card"
                                        src={season.game.cardUrl}
                                        alt={season.game.name}
                                    />
                                )}
                                <div className="season-registration__season-info">
                                    <h4>
                                        Use this form to register for
                                        <br />
                                        <NavLink className="highlighted" to={`/seasons/${season.id}`}>
                                            {season.name}
                                        </NavLink>
                                    </h4>
                                    <ul>
                                        <li>
                                            You will participate in{' '}
                                            <span className="highlighted">{season.game.name}</span>
                                        </li>
                                        {!!season.startDate && (
                                            <li>
                                                {seasonAlternateName} begins{' '}
                                                <span className="highlighted">
                                                    {moment
                                                        .tz(season.startDate, timezone)
                                                        .format(Core.Time.getFormat())}
                                                </span>
                                            </li>
                                        )}
                                        <li>
                                            <span className="highlighted">
                                                {Core.Models.SKILL_LEVEL_STRINGS[season.skillLevel]}
                                            </span>
                                            &nbsp;&nbsp;skill level
                                            {season.skillLevel <= Core.Models.SkillLevel.All && 's'}
                                        </li>
                                        <li>{ageRange}</li>
                                    </ul>
                                </div>
                            </>
                        }
                        welcome={`Welcome to ${league.name}`}
                    />
                }
                leagueFee={leagueConfiguration?.leagueFee}
                onValidatedUsername={(validationResult: Core.Models.IdentifierValidationResult | undefined) =>
                    setUsernameValidationResult(validationResult)
                }
                organizationId={selectedOrganizationId}
                register={async (values: JoinSeasonFormValues) => await UserService.joinSeason(values)}
                registrationAction={Core.Models.RegistrationAction.JoinSeason}
                renderMiddleCustomFields={(formProps: FormikProps<JoinSeasonFormValues>) => (
                    <div className="season-registration__custom-fields">
                        {!hasOrganizationRole && (
                            <>
                                {league.edition !== Core.Models.LeagueEdition.Club && ( // only show the org dropdown if Association
                                    <fieldset className="form-group">
                                        <Select
                                            className={classNames('basic-multi-select dark', {
                                                error:
                                                    getIn(formProps.errors, 'organizationId') &&
                                                    getIn(formProps.touched, 'organizationId'),
                                            })}
                                            classNamePrefix="select"
                                            defaultValue={
                                                !!selectedOrganizationId
                                                    ? options.find(
                                                          (o: SelectOption) => o.value === selectedOrganizationId
                                                      )
                                                    : undefined
                                            }
                                            onBlur={() => formProps.setFieldTouched('organizationId', true)}
                                            onChange={(selectedOption: SelectOption | null) => {
                                                const organization = organizations.find(
                                                    (o: Core.Models.BasicOrganization) => o.id === selectedOption?.value
                                                );
                                                formProps.setFieldValue(
                                                    'organizationId',
                                                    !!organization ? organization.id : ''
                                                );
                                                setSelectedOrganizationId(organization?.id);
                                            }}
                                            options={options}
                                            name="organizationId"
                                            placeholder={`Select ${organizationTerm.toLowerCase()}`}
                                            styles={{ menu: (provided: any) => ({ ...provided, zIndex: 2 }) }}
                                        />
                                    </fieldset>
                                )}
                                {(!!formProps.touched.organizationId ||
                                    league.edition === Core.Models.LeagueEdition.Club) && (
                                    <InfoMessage
                                        className="mt"
                                        message={formProps.errors.organizationId}
                                        type="error"
                                    />
                                )}
                            </>
                        )}
                        {season.game.minimumSeats > 1 && (
                            <FormField
                                component="select"
                                label={`How would you like to join this ${seasonAlternateName.toLowerCase()}?`}
                                name="joinSeasonPreference"
                            >
                                <option value={JoinSeasonPreferenceSelection.Unselected} hidden disabled>
                                    --
                                </option>
                                {membersCanCreateTeams && !isFull && (
                                    <option value={JoinSeasonPreferenceSelection.CreateTeam}>
                                        Create a team and join as captain
                                    </option>
                                )}
                                <option value={JoinSeasonPreferenceSelection.JoinTeam}>Join an existing team</option>
                                {season.allowFreeAgents && (
                                    <option value={JoinSeasonPreferenceSelection.FreeAgent}>
                                        Join as a free agent
                                    </option>
                                )}
                            </FormField>
                        )}
                        {+formProps.values.joinSeasonPreference === JoinSeasonPreferenceSelection.JoinTeam && (
                            <fieldset className="form-group">
                                <FormField type="text" name="teamJoinCode" description="Team Join Code" />
                            </fieldset>
                        )}
                        {+formProps.values.joinSeasonPreference === JoinSeasonPreferenceSelection.CreateTeam &&
                            season.game.minimumSeats > 1 && (
                                <fieldset className="form-group">
                                    <FormField description="Team name" name="teamName" type="text" />
                                </fieldset>
                            )}
                        {!!season.requireGameRanks && hasGameRankings && (
                            <fieldset className="form-group">
                                {season.game.rankingType === Core.Models.RankingType.Numeric ? (
                                    <FormField description={season.game.rankingTerm} name="gameRanking" type="number" />
                                ) : (
                                    <FormField
                                        component="select"
                                        label={`${season.game.name} ${season.game.rankingTerm}`}
                                        name="gameRanking"
                                    >
                                        <option value="" hidden disabled>
                                            --
                                        </option>
                                        {gameRankings.map((ranking: Core.Models.Ranking) => (
                                            <option value={ranking.key} key={ranking.key}>
                                                {ranking.key}
                                            </option>
                                        ))}
                                    </FormField>
                                )}
                            </fieldset>
                        )}
                        {!!season.seasonTerms && (
                            <div className="set-team-season__terms">
                                <h4>{seasonAlternateName} Terms</h4>
                                <Markdown source={season.seasonTerms} />
                                <fieldset className="form-group form-group--undecorated">
                                    <FormField
                                        type="checkbox"
                                        name="agreedToSeasonTerms"
                                        description={`I agree to the terms of this ${seasonAlternateName.toLowerCase()}.`}
                                    />
                                </fieldset>
                            </div>
                        )}
                        <input type="hidden" name="seasonId" />
                    </div>
                )}
                schema={{
                    agreedToSeasonTerms: Yup.boolean().test(
                        'is-valid',
                        `Your consent to this ${seasonAlternateName}'s terms is required.`,
                        (value: boolean | undefined) => {
                            if (!!season.seasonTerms) return !!value;
                            return true;
                        }
                    ),
                    gameRanking: Yup.string().test(
                        'is-required',
                        `${season.game.name} ${season.game.rankingTerm} is required`,
                        (gameRanking: string | undefined) =>
                            !(!!season.requireGameRanks && hasGameRankings) || !!gameRanking
                    ),
                    joinSeasonPreference: Yup.number()
                        .required('Join preference is required')
                        .oneOf([...joinSeasonPreferences], 'Join preference is required'),
                    organizationId: Yup.string()
                        .test(
                            'is-required',
                            `${organizationTerm} is required`,
                            (organizationId: string | undefined) => hasOrganizationRole || !!organizationId
                        )
                        .test(
                            'is-valid',
                            `The selected ${organizationTerm.toLowerCase()} has not enabled public registration. Please reach out to your ${organizationTerm.toLowerCase()}'s manager to be invited.`,
                            (organizationId: string | undefined) => {
                                if (hasOrganizationRole) return true;
                                if (!organizationId) return false;
                                var organization = organizations.find(
                                    (o: Core.Models.BasicOrganization) => o.id === organizationId
                                );
                                return !!organization?.showPublicJoinUrl;
                            }
                        ),
                    registrationFulfillment: Yup.object().when(['joinSeasonPreference'], {
                        is: (joinSeasonPreference: JoinSeasonPreferenceSelection) =>
                            !!leagueConfiguration?.leagueFee &&
                            !usernameValidationResult?.hasFulfilledPayable &&
                            [JoinSeasonPreferenceSelection.CreateTeam, JoinSeasonPreferenceSelection.JoinTeam].includes(
                                +joinSeasonPreference
                            ),
                        then: Yup.object().required(
                            'You must provide a payment method when creating or joining a team.'
                        ),
                    }),
                    seasonId: Yup.string().required('Season ID is required'),
                    teamJoinCode: Yup.string().when('joinSeasonPreference', {
                        is: JoinSeasonPreferenceSelection.JoinTeam,
                        then: Yup.string().required('Team join code is required when joining an existing team'),
                        otherwise: Yup.string().nullable().notRequired(),
                    }),
                    teamName: Yup.string().when('joinSeasonPreference', {
                        is: JoinSeasonPreferenceSelection.CreateTeam,
                        then:
                            season.game.minimumSeats > 1
                                ? Yup.string().required('Team name is required when creating a new team')
                                : Yup.string().nullable().notRequired(),
                        otherwise: Yup.string().nullable().notRequired(),
                    }),
                }}
                setHasOrganizationRole={setHasOrganizationRole}
                submitText={`Join ${seasonAlternateName}`}
            />
        </div>
    );
};

export default withLoading(JoinSeasonPage, {
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

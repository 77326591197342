import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';

import * as Core from '../../core';
import ErrorDisplayPage from '../../components/error/errorDisplayPage';
import RegistrationForm, { RegistrationFormValues } from '../../components/registrationForm';
import RegistrationHeader from '../../components/registrationHeader';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useLeague, useLeagueConfiguration, useOrganizationTerm } from '../../hooks/store';
import { OrganizationService } from '../../services/organizationService';
import { UserService } from '../../services/userService';

interface JoinOrganizationPageProps extends WithLoadingProps, RouteComponentProps<{ joinCode: string }> {}

export interface JoinOrganizationFormValues extends RegistrationFormValues {
    gameInterests?: string[];
    joinCode: string;
}

const JoinOrganizationPage = (props: JoinOrganizationPageProps): JSX.Element => {
    const {
        match: {
            params: { joinCode },
        },
        setError,
        setIsLoading,
    } = props;

    const league = useLeague();
    const organizationTerm = useOrganizationTerm({ lowercase: true });
    const leagueConfiguration = useLeagueConfiguration();

    const [validateJoinCodeResponse, setValidateJoinCodeResponse] = useState<
        Core.Models.ValidateJoinCodeResponse | undefined
    >(undefined);

    useEffect(() => {
        (async () => {
            try {
                const response = await OrganizationService.validateJoinCode(joinCode);
                setValidateJoinCodeResponse(response);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [joinCode, setError, setIsLoading]);

    if (!validateJoinCodeResponse) return <></>;

    if (!!validateJoinCodeResponse && !validateJoinCodeResponse.isValid) {
        return (
            <ErrorDisplayPage
                fullPage
                message={`${validateJoinCodeResponse.invalidReason} Please contact your ${organizationTerm} for a valid link.`}
                title="Error: Invalid Join Code"
            />
        );
    }

    if (!league) return <></>;
    return (
        <div>
            <RegistrationForm<JoinOrganizationFormValues>
                acceptEmail={true}
                allowPayment={() => true}
                allowPayLater={() => true}
                initialValues={{
                    joinCode,
                }}
                header={
                    <RegistrationHeader
                        formInstructions="All fields are required."
                        message={
                            !!validateJoinCodeResponse?.organization
                                ? `Participate in ${validateJoinCodeResponse.organization.name}'s esports team!`
                                : `Participate in ${league.name}'s official esports league!`
                        }
                        welcome={`Welcome to ${league.name}`}
                    />
                }
                leagueFee={leagueConfiguration?.leagueFee}
                organizationId={validateJoinCodeResponse.organization.id}
                register={async (values: JoinOrganizationFormValues) => await UserService.joinOrganization(values)}
                registrationAction={Core.Models.RegistrationAction.JoinOrganizationViaJoinCode}
                schema={{
                    joinCode: Yup.string().required('Join code is required'),
                }}
                submitText={`Join ${league.organizationTerm}`}
            />
        </div>
    );
};

export default withLoading(JoinOrganizationPage, {
    errorDisplayPageProps: { fullPage: false },
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

import React, { useCallback } from 'react';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import AddBirthdate from './addBirthdate';
import { useModal } from '../../hooks/modal';
import { useHasAnyLeagueMembershipRole, useLeague, useProfile } from '../../hooks/store';
import { UserService } from '../../services/userService';
import { store } from '../../store';
import { loadProfile } from '../../store/profile/actions';
import { Button } from '../button';

import './index.scss';

const BirthdateReminder = () => {
    const league = useLeague();
    const profile = useProfile();
    const isLeagueMember = useHasAnyLeagueMembershipRole(Core.Models.PermissionLevel.ListSpecific);

    const reloadProfile = useCallback(() => {
        store.dispatch(loadProfile() as any);
    }, []);

    const onSubmit = async (birthdate: string) => {
        try {
            await UserService.addBirthdate({ birthdate });
            toast.success('Successfully added birthdate');
            reloadProfile();
            closeAddBirthdate();
        } catch (e) {
            toast.error('There was an issue adding birthdate.  Please try again.');
        }
    };

    const [addBirthdateModal, openAddBirthdate, closeAddBirthdate] = useModal(
        () => 'Add Birthdate',
        () => <AddBirthdate onSubmit={onSubmit} />
    );

    if (!league || !league.enableSeasonAgeRestrictions || !isLeagueMember) return <></>;
    if (!profile || !!profile?.age) return <></>;

    return (
        <div className="birthdate-reminder">
            <span className="birthdate-reminder__text">Your birthdate is missing from your profile.</span>
            <Button
                className="birthdate-reminder__button"
                onClick={openAddBirthdate}
                styleType={Core.Models.StyleType.Secondary}
            >
                Add Birthdate
            </Button>
            {addBirthdateModal}
        </div>
    );
};

export default BirthdateReminder;

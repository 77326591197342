import { orderBy } from 'lodash';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import * as Core from '../core';
import { AppState } from '../store';
import { ConsentState } from '../store/consent/types';
import { CookiesState } from '../store/cookies/types';
import { LoginState } from '../store/login/types';
import { getUserPermissionService } from '../store/permissions/selector';

export function useUserPermissionService() {
    return useSelector(getUserPermissionService);
}

// Overrides initial state of userPermissionService.isLoading() without having to making changes to `isLoading` default values.
export const useIsPermissionsLoading = () => useSelector(({ permissionState }: AppState) => permissionState.isLoading);

export function useLeague() {
    return useSelector(({ leagueState }: AppState) => leagueState.league?.league);
}

export const useAlternateSeasonName = (options?: { lowercase?: boolean }) =>
    useSelector(({ leagueState }: AppState) => {
        const alternateSeasonName = leagueState.league?.league?.alternateSeasonName ?? 'Season';
        if (!!options?.lowercase) return alternateSeasonName.toLowerCase();
        return alternateSeasonName;
    });

export const useOrganizationTerm = (options?: { lowercase?: boolean }) =>
    useSelector(({ leagueState }: AppState) => {
        const organizationTerm = leagueState.league?.league?.organizationTerm ?? 'Organization';
        if (!!options?.lowercase) return organizationTerm.toLowerCase();
        return organizationTerm;
    });

export function useHelpfulLinks() {
    return useSelector(({ leagueState }: AppState) =>
        orderBy(leagueState.league?.helpfulLinks || [], (hl: Core.Models.HelpfulLink) => hl.sortOrder)
    );
}

export function useLeagueConfiguration() {
    return useSelector(({ leagueState }: AppState) => leagueState.league);
}

export function useChatDisabled() {
    return useSelector(
        ({ profileState }: AppState) =>
            !!profileState?.profile?.chatDisabled || !!profileState?.profile?.isUnverifiedAccount
    );
}

export function useTimezone() {
    return useSelector(
        ({ leagueState, profileState }: AppState) =>
            profileState.profile?.preferredTimezone || leagueState.league?.league?.timezone || moment.tz.guess()
    );
}

export const useIsAdmin = () => useUserPermissionService().getIsAdmin();

export function useHasLeagueAccess(permissionLevel: Core.Models.PermissionLevel) {
    const userPermissionService = useUserPermissionService();
    const league = useLeague();
    return userPermissionService.hasLeagueAccess(permissionLevel, league?.id);
}

export function useCanAccessAnyOrganization(permissionLevel: Core.Models.PermissionLevel) {
    const userPermissionService = useUserPermissionService();
    return userPermissionService.hasAnyOrganizationAccess(permissionLevel);
}

export function useCanEditSeason(seasonId: string | undefined) {
    const userPermissionService = useUserPermissionService();
    const league = useLeague();
    return userPermissionService.canEditSeason(seasonId, league?.id);
}

export function useHasAnyLeagueMembershipRole(permissionLevel: Core.Models.PermissionLevel) {
    const userPermissionService = useUserPermissionService();
    const league = useLeague();
    if (userPermissionService.getIsAdmin()) return true;
    if (userPermissionService.hasLeagueAccess(permissionLevel, league?.id)) return true;
    return userPermissionService.hasAnyOrganizationAccess(permissionLevel);
}

export const useLoginState = (): LoginState => useSelector(({ loginState }: AppState) => loginState);

export const useProfile = (): Core.Models.MyUserProfile | undefined =>
    useSelector(({ profileState }: AppState) => profileState.profile);

export const useIsPlatform = (): boolean => useSelector(({ startupState }: AppState) => startupState.isPlatform);

export const useConsent = (): ConsentState => useSelector(({ consentState }: AppState) => consentState);

export const useCookies = (): CookiesState => useSelector(({ cookiesState }: AppState) => cookiesState);

export function useIsLite() {
    const league = useLeague();
    const userPermissionService = useUserPermissionService();
    const canElevatedEditLeague = userPermissionService.hasLeagueAccess(
        Core.Models.PermissionLevel.ElevatedEdit,
        league
    );
    return league?.plan === Core.Models.LeaguePlan.Lite && !canElevatedEditLeague;
}

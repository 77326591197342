import React, { useCallback, useMemo, useState } from 'react';
import { Redirect } from 'react-router';

import * as Core from '../../core';
import MySeasons from './mySeasons';
import MyTeams from './myTeams';
import MyUpcomingMatches from './myUpcomingMatches';
import OrganizationUpcomingMatches from './organizationUpcomingMatches';
import SuggestedSeasons from './suggestedSeasons';
import DismissibleNotifier from '../../components/dismissibleNotifier';
import Loading from '../../components/loading';
import { useLeague, useProfile, useUserPermissionService } from '../../hooks/store';
import { AuthenticationService } from '../../services/authenticationService';
import { UserPermissionService } from '../../services/userPermissionService';

import './index.scss';

export const canAccessDashboard = ({
    league,
    profile,
    userPermissionService,
}: {
    league: Core.Models.League;
    profile: Core.Models.MyUserProfile;
    userPermissionService: UserPermissionService;
}) => {
    // and must be part of an organization
    if (!profile.organizationId) return false;

    const canEditAnyOrg = userPermissionService.hasAnyOrganizationAccess(Core.Models.PermissionLevel.ElevatedEdit);
    if (canEditAnyOrg && league?.edition === Core.Models.LeagueEdition.Club) return false; // don't show dashboard to club managers

    return true;
};

const Dashboard = () => {
    const isAuthenticated = AuthenticationService.isAuthenticated();
    const userPermissionService = useUserPermissionService();
    const league = useLeague();
    const profile = useProfile();

    const canEditAnyOrg = useMemo(
        () => userPermissionService.hasAnyOrganizationAccess(Core.Models.PermissionLevel.ElevatedEdit),
        []
    );

    if (!isAuthenticated) return <Redirect to="/league" />;
    if (!league || !profile) return <Loading blockItem />;
    if (!canAccessDashboard({ league, profile, userPermissionService })) return <Redirect to="/league" />;

    return (
        <div className="page dashboard-page">
            <DismissibleNotifier />
            <h2 className="generic-page__title">
                My <span className="theme__text__alternate">Dashboard</span>
            </h2>
            {canEditAnyOrg ? <ManagerDashboard /> : <PlayerDashboard />}
        </div>
    );
};

const ManagerDashboard = () => <OrganizationUpcomingMatches />;

const PlayerDashboard = () => {
    const [seasonJoinedEvent, setSeasonJoinedEvent] = useState<object>({});
    const onJoinSeason = useCallback(() => setSeasonJoinedEvent({}), []);

    return (
        <>
            <MyUpcomingMatches />
            <MySeasons {...{ seasonJoinedEvent }} />
            <MyTeams />
            <SuggestedSeasons {...{ onJoinSeason }} />
        </>
    );
};

export default Dashboard;

import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { IHasChildren } from '../../layouts/types';

interface ScrollToTopOnNavigateProps extends IHasChildren, RouteComponentProps<void> {}

// based on https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md#scroll-to-top
const ScrollToTopOnNavigate = (props: ScrollToTopOnNavigateProps): JSX.Element => {
    const { children, location: pathname } = props;

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return <>{children || null}</>;
};

export default withRouter(ScrollToTopOnNavigate);

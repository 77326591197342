import * as Core from './../core';
import { LoginState } from '../store/login/types';
import { PermissionState } from '../store/permissions/types';

export class UserPermissionService {
    // eslint-disable-next-line
	constructor(private loginState: LoginState, private permissionState: PermissionState) {}

    public isLoading = () => {
        return this.loginState.isLoading || this.permissionState.isLoading;
    };

    public loadingError = () => {
        return this.loginState.error || this.permissionState.loadingError;
    };

    public getIsAdmin = (): boolean => {
        const { decodedToken } = this.loginState;
        return typeof decodedToken !== 'undefined' && decodedToken.is_admin;
    };

    public getUserId = (): string | undefined => {
        const { decodedToken } = this.loginState;
        if (!decodedToken) return undefined;
        return decodedToken.sub;
    };

    private getLeagueRole = (idOrObject?: Core.Models.League | string | undefined): Core.Models.AssignedRole => {
        if (!idOrObject) {
            return Core.Access.anon;
        }
        const id = typeof idOrObject === 'string' ? idOrObject : idOrObject.id;
        if (this.isLoading()) {
            return Core.Access.anon;
        }
        const { decodedToken } = this.loginState;
        const permissions = this.permissionState.permissions && this.permissionState.permissions.leagues[id];
        return Core.Access.mergePermissions(
            permissions || Core.Access.anon,
            decodedToken?.is_admin ? Core.Access.globalAdminAccess : Core.Access.anon
        );
    };

    public getOrganizationRole = (
        idOrObject?: Core.Models.Organization | string | undefined
    ): Core.Models.AssignedRole => {
        if (!idOrObject) {
            return Core.Access.anon;
        }
        const id = typeof idOrObject === 'string' ? idOrObject : idOrObject.id;
        if (this.isLoading()) {
            return Core.Access.anon;
        }
        const { decodedToken } = this.loginState;
        const permissions = this.permissionState.permissions && this.permissionState.permissions.organizations[id];
        return Core.Access.mergePermissions(
            permissions || Core.Access.anon,
            decodedToken && decodedToken!.is_admin ? Core.Access.globalAdminAccess : Core.Access.anon
        );
    };

    public getTeamRole = (idOrObject?: Core.Models.Team | string | undefined): Core.Models.AssignedRole => {
        if (!idOrObject) {
            return Core.Access.anon;
        }
        const id = typeof idOrObject === 'string' ? idOrObject : idOrObject.id;
        if (this.isLoading()) {
            return Core.Access.anon;
        }
        const { decodedToken } = this.loginState;
        const permissions = this.permissionState.permissions && this.permissionState.permissions.teams[id];
        return Core.Access.mergePermissions(
            permissions || Core.Access.anon,
            decodedToken && decodedToken!.is_admin ? Core.Access.globalAdminAccess : Core.Access.anon
        );
    };

    public hasLeagueAccess = (
        permissionLevel: Core.Models.PermissionLevel,
        idOrObject: Core.Models.League | string | undefined
    ): boolean => {
        const leagueAccess = this.getLeagueRole(idOrObject).leaguePermissions;
        return leagueAccess >= permissionLevel;
    };

    public hasOrganizationAccess = (
        permissionLevel: Core.Models.PermissionLevel,
        idOrObject: Core.Models.Organization | string | undefined
    ): boolean => {
        const organizationAccess = this.getOrganizationRole(idOrObject).organizationPermissions;
        return organizationAccess >= permissionLevel;
    };

    public hasAnyOrganizationAccess = (permissionLevel: Core.Models.PermissionLevel): boolean => {
        if (this.isLoading()) {
            return false;
        }
        const { decodedToken } = this.loginState;
        const permissions =
            this.permissionState.permissions &&
            Object.values(this.permissionState.permissions.organizations).filter(
                (ar: Core.Models.AssignedRole) => ar.organizationPermissions >= permissionLevel
            ).length > 0;

        return decodedToken?.is_admin || permissions || false;
    };

    public hasTeamAccess = (
        permissionLevel: Core.Models.PermissionLevel,
        idOrObject: Core.Models.Team | string | undefined
    ): boolean => {
        const teamAccess = this.getTeamRole(idOrObject).teamPermissions;
        return teamAccess >= permissionLevel;
    };

    public hasUserAccess = (permissionLevel: Core.Models.PermissionLevel, id: string | undefined): boolean => {
        let userAccess = Core.Models.PermissionLevel.Anonymous;
        const token = this.loginState.decodedToken;
        if (!!id && !this.isLoading() && (token?.is_admin || token?.sub === id)) {
            userAccess = Core.Models.PermissionLevel.ElevatedEdit;
        }
        return userAccess >= permissionLevel;
    };

    public canEditSeason = (seasonId: string | undefined, leagueId: string | undefined): boolean => {
        if (!seasonId) return false;
        const { leaguePermissions, seasonIds } = this.getLeagueRole(leagueId);
        return (
            this.getIsAdmin() ||
            leaguePermissions >= Core.Models.PermissionLevel.Edit ||
            (leaguePermissions >= Core.Models.PermissionLevel.Contribute && seasonIds.includes(seasonId))
        );
    };
}

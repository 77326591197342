import * as Core from '../core';

export abstract class ExportService {
    public static async roster(): Promise<Core.Models.File> {
        const route = Core.API.ServerRoute.forAction('export', 'roster');
        return Core.API.getFile(route);
    }
    public static async seasonRoster(seasonId: string): Promise<Core.Models.File> {
        const route = Core.API.ServerRoute.forAction('export', `seasonRoster/${seasonId}`);
        return Core.API.getFile(route);
    }
    public static async teams(): Promise<Core.Models.File> {
        const route = Core.API.ServerRoute.forAction('export', 'teams');
        return Core.API.getFile(route);
    }

    // credit: https://dev.to/nombrekeff/download-file-from-blob-21ho
    public static downloadBlob = (blob: any, name: string): void => {
        // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');

        // Set link's href to point to the Blob URL
        link.href = blobUrl;
        link.download = name;

        // Append link to the body
        document.body.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            })
        );

        // Remove link from body
        document.body.removeChild(link);
    };
}

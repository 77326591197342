import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// the above lines must be the first ones in this file - https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill#internet-explorer-11

import React from 'react';
import ReactDOM from 'react-dom';

import * as Core from './core';
import App from './app';
import AppContainer from './appContainer';
import PlatformApp from './platformApp';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import { startup } from './store/startup/actions';

import './index.scss';

const platformUrl = Core.Configuration.platformUrl?.replace(/\/+$/, ''); // remove trailing slash if exists
const isPlatform = !!platformUrl && window.location.href.startsWith(platformUrl);

// not waiting for the league load to complete
store.dispatch(startup(!!isPlatform) as any);
ReactDOM.render(
    <AppContainer>{!!isPlatform ? <PlatformApp /> : <App />}</AppContainer>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

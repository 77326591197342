import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';

import * as Core from '../../core';
import EditStageForm from './form';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import history from '../../services/history';
import { StageService } from '../../services/stageService';

interface EditStagePageRouteProps {
    id: string;
}

interface EditStagePageProps extends RouteComponentProps<EditStagePageRouteProps>, WithLoadingProps {}

const EditStagePage = (props: EditStagePageProps) => {
    const {
        match: {
            params: { id },
        },
        setError,
        setIsLoading,
    } = props;

    const [stage, setStage] = useState<Core.Models.BasicStage | undefined>(undefined);

    useEffect(() => {
        (async () => {
            try {
                const response = await StageService.getBasic(id);
                setStage(response);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [id, setError, setIsLoading]);

    if (!stage) return <></>;
    return (
        <div className="page pt8x">
            <h2 className="heading-2 form__header text-center">Edit {stage.name}</h2>
            <EditStageForm
                onSubmit={async ({ id, ...values }) => {
                    await StageService.edit({
                        id: id!,
                        ...values,
                    });
                    history.push(`/seasons/${stage.seasonId}/${id!}`);
                }}
                seasonId={stage.seasonId}
                stage={stage}
            />
        </div>
    );
};

export default withLoading(EditStagePage, {
    errorDisplayPageProps: { fullPage: true },
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chess } from 'chess.js';
import classNames from 'classnames';
import { Chessboard } from 'react-chessboard';

import * as Core from '../../../core';
import { BaseButton, HollowButton, SolidButton } from '../../../components/buttons-visuals';
import { ContentContainer } from '../../../components/containers';
import Expando from '../../../components/expando';
import { getWTLClass, renderWTL } from '../submitMatchGameResult/autoScoring/chess';

import './ChessMatchStats.scss';

interface ChessMatchStatsProps {
    className?: string;
    detailedView?: boolean;
    match: Core.Models.ChessMatchSummary;
}

const ChessMatchStats = ({ className, detailedView, match }: ChessMatchStatsProps) => {
    const [boardOrientation, setBoardOrientation] = useState<'white' | 'black'>('white');
    const [move, setMove] = useState<number>(0);
    const [view3d, setView3d] = useState<boolean>(false);

    const fenArray = useMemo(() => {
        if (!match.pgn) return [];

        const chessJS = new Chess();

        // formatting match.pgn into a string for loadPgn
        const pgnString = match.pgn
            .map((move: string[], key: number) => {
                const moveNumber = key + 1;
                const whiteMove = move[0];
                const blackMove = move[1] ?? '';
                return `${moveNumber}. ${whiteMove} ${blackMove}`;
            })
            .join(' ');

        // loads the formatted moves into chessJS
        chessJS.loadPgn(pgnString);
        const history = chessJS.history();
        chessJS.reset();

        // creates fen for each time a piece is moved in the match
        const startPos = chessJS.fen();
        const fens = history.map((historyMove: string) => {
            chessJS.move(historyMove);
            return chessJS.fen();
        });

        return [startPos, ...fens];
    }, [match.pgn]);

    // match controls
    const resetBoard = () => setMove(0);
    const previousMove = () => setMove(move - 1);
    const nextMove = () => setMove(move + 1);
    const endResult = () => setMove(fenArray.length - 1);

    const threeDPieces = useMemo(() => {
        const pieces = [
            { piece: 'wP', pieceHeight: 1 },
            { piece: 'wN', pieceHeight: 1.2 },
            { piece: 'wB', pieceHeight: 1.2 },
            { piece: 'wR', pieceHeight: 1.2 },
            { piece: 'wQ', pieceHeight: 1.5 },
            { piece: 'wK', pieceHeight: 1.6 },
            { piece: 'bP', pieceHeight: 1 },
            { piece: 'bN', pieceHeight: 1.2 },
            { piece: 'bB', pieceHeight: 1.2 },
            { piece: 'bR', pieceHeight: 1.2 },
            { piece: 'bQ', pieceHeight: 1.5 },
            { piece: 'bK', pieceHeight: 1.6 },
        ];

        return pieces.reduce(
            (pieceComponents, { piece, pieceHeight }) => {
                const PieceComponent: React.FC<{ squareWidth: number }> = ({ squareWidth }) => (
                    <div
                        style={{
                            width: squareWidth,
                            height: squareWidth,
                            position: 'relative',
                            pointerEvents: 'none',
                        }}
                    >
                        <img
                            src={`https://cdn.leaguespot.gg/static/media/titles/chess/3d/${piece}.webp`}
                            width={squareWidth}
                            height={pieceHeight * squareWidth}
                            style={{
                                position: 'absolute',
                                bottom: `${0.02 * squareWidth}rem`,
                                objectFit: piece[1] === 'K' ? 'contain' : 'cover',
                            }}
                        />
                    </div>
                );

                return { ...pieceComponents, [piece]: PieceComponent };
            },
            {} as Record<string, React.FC<{ squareWidth: number }>>
        );
    }, []);

    useEffect(() => {
        const newMove = !!match.pgn
            ? match.pgn.reduce(
                  (acc: number, moveArr: string[]) => acc + moveArr.filter((move: string) => !!move).length,
                  0
              )
            : 0;
        setMove(newMove);
    }, [match.pgn]);

    return (
        <div className={classNames(className, 'chess-match-stats', { 'chess-match-stats--detailed': detailedView })}>
            <div className="m2x">
                <BaseButton
                    as="a"
                    className="chess-match-stats__player"
                    href={`https://www.chess.com/member/${match.white.username}`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <div className="chess-match-stats__player__color background-white-dark">
                        <FontAwesomeIcon icon={['fas', 'chess-pawn']} className="icon color-white" size="lg" />
                    </div>
                    {match.white.username}
                    <span className="ml text-small">({match.white.rating})</span>
                    <span
                        className={classNames(
                            'chess-match-stats__player__result',
                            getWTLClass(match.white.won, match.draw)
                        )}
                    >
                        {renderWTL(match.white.won, match.draw)}
                    </span>
                </BaseButton>
                <BaseButton
                    as="a"
                    className="chess-match-stats__player"
                    href={`https://www.chess.com/member/${match.black.username}`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <div className="chess-match-stats__player__color background-black-light">
                        <FontAwesomeIcon icon={['fas', 'chess-pawn']} className="icon color-black" size="lg" />
                    </div>
                    {match.black.username}
                    <span className="ml text-small">({match.black.rating})</span>
                    <span
                        className={classNames(
                            'chess-match-stats__player__result',
                            getWTLClass(match.black.won, match.draw)
                        )}
                    >
                        {renderWTL(match.black.won, match.draw)}
                    </span>
                </BaseButton>
            </div>
            {detailedView ? (
                <ContentContainer
                    className="chess-match-stats__board-container ml2x mr2x"
                    shade={Core.Models.Shades.Dark20}
                >
                    <div className="chess-match-stats__board">
                        <Chessboard
                            arePiecesDraggable={false}
                            boardOrientation={boardOrientation}
                            {...(view3d
                                ? {
                                      customBoardStyle: {
                                          transform: 'rotateX(27.5deg)',
                                          transformOrigin: 'center',
                                          transitionDuration: '0.8s',
                                          transitionProperty: 'all',
                                          borderRadius: '.8rem',
                                          boxShadow: 'rgba(0, 0, 0, 0.5) .2rem 2.4rem 2.4rem .8rem',
                                          marginTop: '-2rem',
                                      },
                                      customPieces: threeDPieces,
                                      customLightSquareStyle: {
                                          backgroundColor: '#e0c094',
                                          backgroundImage:
                                              'url("https://cdn.leaguespot.gg/static/media/titles/chess/3d/wood-pattern.png")',
                                          backgroundSize: 'cover',
                                      },
                                      customDarkSquareStyle: {
                                          backgroundColor: '#865745',
                                          backgroundImage:
                                              'url("https://cdn.leaguespot.gg/static/media/titles/chess/3d/wood-pattern.png")',
                                          backgroundSize: 'cover',
                                      },
                                  }
                                : {
                                      customBoardStyle: {
                                          transform: 'rotateX(0deg)',
                                          transitionDuration: '0.8s',
                                          transitionProperty: 'all',
                                          marginTop: '0',
                                      },
                                  })}
                            position={fenArray[move]}
                        />
                    </div>
                    <div className="disp-flex justify-center mb2x ml4x mr4x mt4x">
                        <HollowButton
                            as="button"
                            className="disp-flex flex-dir-column mr"
                            color="secondary"
                            size="small"
                            onClick={() => setView3d(!view3d)}
                        >
                            <FontAwesomeIcon icon={['fas', view3d ? 'square' : 'cube']} size="1x" />
                            <span className="heading-5">{view3d ? '2D' : '3D'}</span>
                        </HollowButton>
                        <SolidButton
                            as="button"
                            className="mr"
                            disabled={move === 0}
                            color="secondary"
                            size="small"
                            onClick={resetBoard}
                        >
                            <FontAwesomeIcon icon={['fas', 'backward-step']} size={'3x'} />
                        </SolidButton>
                        <SolidButton
                            as="button"
                            className="mr"
                            color="secondary"
                            disabled={move === 0}
                            size="small"
                            onClick={previousMove}
                        >
                            <FontAwesomeIcon icon={['fas', 'caret-left']} size={'3x'} />
                        </SolidButton>
                        <SolidButton
                            as="button"
                            className="mr"
                            color="secondary"
                            disabled={move === fenArray.length - 1}
                            size="small"
                            onClick={nextMove}
                        >
                            <FontAwesomeIcon icon={['fas', 'caret-right']} size={'3x'} />
                        </SolidButton>
                        <SolidButton
                            as="button"
                            className="mr"
                            color="secondary"
                            disabled={move === fenArray.length - 1}
                            size="small"
                            onClick={endResult}
                        >
                            <FontAwesomeIcon icon={['fas', 'forward-step']} size={'3x'} />
                        </SolidButton>
                        <HollowButton
                            as="button"
                            className="disp-flex flex-dir-column mr"
                            color="secondary"
                            size="small"
                            onClick={() => setBoardOrientation(boardOrientation === 'black' ? 'white' : 'black')}
                        >
                            <FontAwesomeIcon icon={['fas', 'rotate']} size="2x" />
                        </HollowButton>
                    </div>
                </ContentContainer>
            ) : (
                <Chessboard arePiecesDraggable={false} position={match.fen} />
            )}
            <div className="m2x">
                {!!match.pgn &&
                    (detailedView ? (
                        <Expando className="chess-match-stats__expando" title="See move list">
                            <div className="chess-match-stats__pgn-grid text-small mt2x">
                                <div className="chess-match-stats__pgn-grid__row mb">
                                    <div /> {/* empty column 1 header */}
                                    <div className="text-center weight-700 truncate-single-line">
                                        {match.white.username}
                                    </div>
                                    <div className="text-center weight-700 truncate-single-line">
                                        {match.black.username}
                                    </div>
                                </div>
                                {match.pgn.map((moves: string[], moveIndex: number) => (
                                    <div className="chess-match-stats__pgn-grid__row" key={moveIndex}>
                                        <div className="ml">{moveIndex + 1}</div>
                                        <button
                                            className="chess-match-stats__pgn-grid__row__item"
                                            onClick={() => setMove(moveIndex * 2 + 1)}
                                        >
                                            {moves[0]}
                                        </button>
                                        <button
                                            className="chess-match-stats__pgn-grid__row__item"
                                            onClick={() => setMove(moveIndex * 2 + 2)}
                                        >
                                            {moves[1]}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </Expando>
                    ) : (
                        match.pgn.map((moves: string[], moveIndex: number) => (
                            <React.Fragment key={moveIndex}>
                                <span className="text-small">
                                    {' '}
                                    {moveIndex + 1}.{' '}
                                    <span className="weight-700">
                                        {moves[0]} {moves[1]}
                                    </span>
                                </span>
                            </React.Fragment>
                        ))
                    ))}
            </div>
        </div>
    );
};

export default ChessMatchStats;

import React, { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { sortBy } from 'lodash';

import * as Core from '../../../core';
import { ContentContainer } from '../../../components/containers';

import './MatchStats.scss';

interface ValorantMatchStatsProps {
    matchParticipantMapping: Record<string, StatsTeam>;
    scoringParsed: Core.Models.ValorantParsedGameResult.Result;
}

interface StatsTeam {
    avatarUrl?: string;
    id: string;
    name: string;
    organizationIconUrl: string;
}

const ValorantMatchStats = ({ scoringParsed, matchParticipantMapping }: ValorantMatchStatsProps) => {
    const renderStatsHighlight = useCallback(
        (kills: number, deaths: number, assists: number) => (
            <ContentContainer
                className="match-stats-item__participant__row-stats-highlight"
                overflowHidden
                radius={Core.Models.RadiusSizes.Small}
            >
                <div className="transparent-black p-half">
                    <span className="heading-3 disp-inline-block color-white">{kills}</span>
                    <span className="mr ml">/</span>
                    <span className="heading-3 disp-inline-block color-white">{deaths}</span>
                    <span className="mr ml">/</span>
                    <span className="heading-3 disp-inline-block color-white">{assists}</span>
                </div>
                <div className="p-half">KDA</div>
            </ContentContainer>
        ),
        []
    );

    const renderRounds = useMemo(
        () => (
            <div className="match-stats-item__rounds">
                <div className="match-stats-item__rounds-scroll-teams">
                    <div className="disp-flex align-center">
                        {!!scoringParsed.teams[0].matchParticipantId &&
                        !!matchParticipantMapping[scoringParsed.teams[0].matchParticipantId] ? (
                            <img
                                className="match-stats-item__rounds__team-avatar"
                                src={
                                    matchParticipantMapping[scoringParsed.teams[0].matchParticipantId].avatarUrl ??
                                    matchParticipantMapping[scoringParsed.teams[0].matchParticipantId]
                                        .organizationIconUrl
                                }
                                alt=""
                            />
                        ) : (
                            <div
                                className={classNames(
                                    'match-stats-item__rounds__team-avatar',
                                    'match-stats-item__rounds__team-avatar--default-red'
                                )}
                            >
                                <FontAwesomeIcon icon={['fas', 'people-group']} size="xs" />
                            </div>
                        )}
                        <span className="text-small">{scoringParsed.teams[0].points}</span>
                    </div>
                    <div className="disp-flex align-center">
                        {!!scoringParsed.teams[1].matchParticipantId &&
                        !!matchParticipantMapping[scoringParsed.teams[1].matchParticipantId] ? (
                            <img
                                className="match-stats-item__rounds__team-avatar"
                                src={
                                    matchParticipantMapping[scoringParsed.teams[1].matchParticipantId].avatarUrl ??
                                    matchParticipantMapping[scoringParsed.teams[1].matchParticipantId]
                                        .organizationIconUrl
                                }
                                alt=""
                            />
                        ) : (
                            <div
                                className={classNames(
                                    'match-stats-item__rounds__team-avatar',
                                    'match-stats-item__rounds__team-avatar--default-blue'
                                )}
                            >
                                <FontAwesomeIcon icon={['fas', 'people-group']} size="xs" />
                            </div>
                        )}
                        <span className="text-small">{scoringParsed.teams[1].points}</span>
                    </div>
                </div>
                <div className="match-stats-item__rounds-scroll-wrap">
                    {scoringParsed.rounds.map((round: Core.Models.ValorantParsedGameResult.Round, key: number) => {
                        const redWin = round.winningTeam === 'Red';

                        return (
                            <div className="match-stats-item__rounds-matchup" key={key}>
                                <div className="color-gray text-xsmall">{key + 1}</div>
                                <div
                                    className={classNames(
                                        'match-stats-item__rounds-matchup-item',
                                        getRoundResultClass('Red', round.winningTeam, round.attackingTeam)
                                    )}
                                >
                                    {redWin && (
                                        <img
                                            className="full-width"
                                            src={round.resultIconUrl}
                                            alt=""
                                            title={round.result}
                                        />
                                    )}
                                </div>
                                <div
                                    className={classNames(
                                        'match-stats-item__rounds-matchup-item',
                                        getRoundResultClass('Blue', round.winningTeam, round.attackingTeam)
                                    )}
                                >
                                    {!redWin && (
                                        <img
                                            className="full-width"
                                            src={round.resultIconUrl}
                                            alt=""
                                            title={round.result}
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        ),
        [matchParticipantMapping, scoringParsed.rounds, scoringParsed.teams]
    );

    const teamsStats = useMemo(
        () =>
            scoringParsed.teams.map((team: Core.Models.ValorantParsedGameResult.Team, index: number) => {
                const mvp = sortBy(team.participants, (p) => p.damage).reverse()[0];
                const mappedTeam = matchParticipantMapping[team.matchParticipantId ?? ''];

                return (
                    <ContentContainer
                        className="match-stats-item__participant"
                        key={team.id}
                        shade={Core.Models.Shades.Dark20}
                    >
                        <div className="match-stats-item__participant__team">
                            <div className="match-stats-item__participant__team-info">
                                {!!(mappedTeam?.avatarUrl ?? mappedTeam?.organizationIconUrl) ? (
                                    <img
                                        className="match-stats-item__participant__team-avatar"
                                        src={mappedTeam.avatarUrl ?? mappedTeam.organizationIconUrl}
                                        alt=""
                                    />
                                ) : (
                                    <div
                                        className={classNames(
                                            'match-stats-item__participant__team-avatar',
                                            'match-stats-item__participant__team-avatar--default',
                                            `match-stats-item__participant__team-avatar--${team.name.toLowerCase()}`
                                        )}
                                    >
                                        <FontAwesomeIcon icon={['fas', 'people-group']} size="1x" />
                                    </div>
                                )}
                                <div className="match-stats-item__participant__team-name">
                                    <div className="truncate-single-line weight-700">{mappedTeam?.name}</div>
                                    <div>
                                        <span className="color-gray mr">DPR:</span>
                                        {Math.round(team.damage / team.roundsPlayed).toLocaleString()}
                                    </div>
                                </div>
                            </div>
                            <div className="match-stats-item__participant__team-points">
                                <div
                                    className={classNames(
                                        'heading-3',
                                        team.winner && `color-${team.name === 'Red' ? 'secondary-light-20' : 'primary'}`
                                    )}
                                >
                                    {team.points}
                                </div>
                                Points
                            </div>
                            <div
                                className={classNames(
                                    'match-stats-item__participant__team-mvp text-small',
                                    `match-stats-item__participant__team-mvp--${team.name.toLowerCase()}`
                                )}
                            >
                                <img src={mvp.agent.imageUrl} alt="" title={`${mvp.username}#${mvp.tagLine}`} />
                                MVP
                            </div>
                            <div className="match-stats-item__participant__team-highlights">
                                {renderStatsHighlight(team.kills, team.deaths, team.assists)}
                            </div>
                        </div>

                        {team.participants.map((participant: Core.Models.ValorantParsedGameResult.Participant) => (
                            <div className="match-stats-item__participant__row" key={participant.id}>
                                <div className="match-stats-item__participant__row-player-stats">
                                    <div className="match-stats-item__participant__row-player">
                                        <img
                                            alt=""
                                            className={classNames(
                                                'match-stats-item__participant__row-image',
                                                `match-stats-item__participant__row-image--mvp-${team.name.toLowerCase()}`
                                            )}
                                            src={participant.agent.imageUrl}
                                            title={participant.agent.name}
                                        />
                                        <div className="disp-flex flex-dir-column">
                                            <span className="weight-700">
                                                {participant.username}
                                                <span className="text-small weight-400 text-italic">
                                                    #{participant.tagLine}
                                                </span>
                                                {mvp.id === participant.id && (
                                                    <FontAwesomeIcon
                                                        className={classNames(
                                                            'ml',
                                                            `color-${team.name === 'Red' ? 'secondary' : 'primary'}`
                                                        )}
                                                        icon={['fas', 'trophy']}
                                                        size="xs"
                                                    />
                                                )}
                                            </span>
                                            <span className="color-gray text-small">
                                                Agent: {participant.agent.name}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="match-stats-item__participant__row-stats">
                                        <div>
                                            <span className="color-gray mr" title="Average combat score">
                                                ACS:
                                            </span>
                                            {participant.roundsPlayed > 0
                                                ? Math.round(
                                                      participant.score / participant.roundsPlayed
                                                  ).toLocaleString()
                                                : 0}
                                        </div>
                                        <div>
                                            <span className="color-gray mr" title="Headshot percentage">
                                                HSP:
                                            </span>
                                            {participant.totalShots > 0
                                                ? Math.round((participant.headshots / participant.totalShots) * 100)
                                                : 0}
                                            %
                                        </div>
                                        <div>
                                            <span className="color-gray mr" title="Damage per round">
                                                DPR:
                                            </span>
                                            {participant.roundsPlayed > 0
                                                ? Math.round(
                                                      participant.damage / participant.roundsPlayed
                                                  ).toLocaleString()
                                                : 0}
                                        </div>
                                        <div>
                                            <span className="color-gray mr" title="Multi kills">
                                                MK:
                                            </span>
                                            {participant.multi2Kills}
                                        </div>
                                        <div>
                                            <span className="color-gray mr" title="First kills">
                                                FK:
                                            </span>
                                            {participant.firstKills}
                                        </div>
                                        <div>
                                            <span className="color-gray mr" title="First deaths">
                                                FD:
                                            </span>
                                            {participant.firstDeaths}
                                        </div>
                                    </div>
                                </div>
                                {renderStatsHighlight(participant.kills, participant.deaths, participant.assists)}
                            </div>
                        ))}
                    </ContentContainer>
                );
            }),
        [matchParticipantMapping, scoringParsed.teams, renderStatsHighlight]
    );

    return (
        <div className="match-stats-item valorant-stats">
            <div className="text-center">
                <span className="color-gray mr">Map:</span>
                <span className="weight-700">{scoringParsed.map}</span>
            </div>
            {renderRounds}
            {teamsStats}
        </div>
    );
};

const getRoundResultClass = (side: string, winner: string, attacker: string) => {
    if (side !== winner) return 'background-dark-20';
    return winner === attacker ? 'attack' : 'defend';
};

export default ValorantMatchStats;

import React, { useCallback, useEffect, useState } from 'react';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import QRCode from 'react-qr-code';
import * as Yup from 'yup';

import * as Core from '../../../core';
import { SolidButton } from '../../../components/buttons-visuals';
import InfoMessage from '../../../components/infoMessage';
import { CopyTextField } from '../../../components/inputs';
import MfaCodeInput from '../../../components/inputs/authCodeInput';
import Loading from '../../../components/loading';
import Modal from '../../../components/modal';
import { UserService } from '../../../services/userService';

interface EnableMfaModalProps {
    onClose: () => void;
    reloadData: () => Promise<void>;
}

const EnableMfaModal = ({ onClose, reloadData }: EnableMfaModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true); // loading by default
    const [otpLink, setOtpLink] = useState<string | undefined>(undefined);
    const [secret, setSecret] = useState<string | undefined>(undefined);

    // generate authenticator QR code when modal is loaded
    useEffect(() => {
        (async () => {
            try {
                const response = await UserService.generateOtpLink();
                setOtpLink(response.otpLink);
                setSecret(response.secret);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    const enableMfa = useCallback(
        async (values: Core.Models.EnableUserMfaRequest, actions: FormikActions<Core.Models.EnableUserMfaRequest>) => {
            actions.setStatus(undefined);

            try {
                const response = await UserService.enableUserMfa(values);
                if (response.success) {
                    onClose();
                    reloadData();
                } else {
                    actions.setStatus('This code did not work. Please try again.');
                }
            } catch (e) {
                actions.setStatus('Something went wrong. Please try again later.');
            } finally {
                actions.setSubmitting(false);
            }
        },
        [onClose, reloadData]
    );

    return (
        <Modal onClose={onClose} title="Configure two-factor authentication">
            {isLoading ? (
                <Loading />
            ) : (
                <div>
                    {!otpLink ? (
                        <InfoMessage message="Unable to generate QR code. Please try again." type="error" />
                    ) : (
                        <div className="disp-flex flex-dir-column align-center">
                            <p className="mb2x">
                                Please scan this QR code with an authentication app such as{' '}
                                <a
                                    href="https://www.microsoft.com/en-us/security/mobile-authenticator-app"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Microsoft Authenticator
                                </a>{' '}
                                or{' '}
                                <a
                                    href="https://support.google.com/accounts/answer/1066447?hl=en&ref_topic=2954345"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Google Authenticator
                                </a>
                                :
                            </p>
                            <div className="mb2x">
                                <QRCode value={otpLink} />
                            </div>
                            {!!secret && (
                                <>
                                    <p className="mb2x">
                                        Trouble with the QR code? Copy the secret below and paste it into your favorite
                                        authenticator:
                                    </p>
                                    <fieldset className="form-group mb2x full-width">
                                        <CopyTextField id="mfaSecret" label="MFA Secret" value={secret} />
                                    </fieldset>
                                </>
                            )}

                            <p className="mb2x">
                                Once this app has been added to an authenticator, please enter a generated code to
                                enable two-factor authentication:
                            </p>
                            <Formik<Core.Models.EnableUserMfaRequest>
                                initialValues={{
                                    otpCode: '',
                                }}
                                onSubmit={enableMfa}
                                render={(formProps: FormikProps<Core.Models.EnableUserMfaRequest>) => (
                                    <Form className="full-width">
                                        <MfaCodeInput
                                            isError={!!formProps.errors.otpCode}
                                            setValue={(value: string) => formProps.setFieldValue('otpCode', value)}
                                        />

                                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                                        <InfoMessage
                                            filter={formProps.touched}
                                            message={formProps.errors}
                                            type="error"
                                        />

                                        <fieldset className="form-group form-group--undecorated">
                                            {formProps.isSubmitting && <Loading buttonLoader />}
                                            <SolidButton
                                                as="button"
                                                className="full-width"
                                                disabled={formProps.isSubmitting}
                                                onClick={formProps.submitForm}
                                                size="medium"
                                            >
                                                Enable two-factor authentication
                                            </SolidButton>
                                        </fieldset>
                                    </Form>
                                )}
                                validationSchema={Yup.object().shape({
                                    otpCode: Yup.string().required('Authenticator code is required'),
                                })}
                            />
                        </div>
                    )}
                </div>
            )}
        </Modal>
    );
};

export default EnableMfaModal;

import { each, isNumber } from 'lodash';
import moment from 'moment';

import { BaseCore } from '@leaguespot/platform-js';

export function timeAdjust(
    serverValue: moment.Moment | Date,
    serverReference: moment.Moment | Date,
    clientReference: moment.Moment | Date
) {
    return moment(serverValue)
        .clone()
        .add(moment(clientReference).diff(moment(serverReference)));
}

export type TimerUpdateCallback = (now: Date) => void;
var timerUpdateCallbacks: TimerUpdateCallback[] = [];
var timerUpdateInterval: NodeJS.Timer | undefined;

export function registerForTimerUpdate(callback: TimerUpdateCallback) {
    if (timerUpdateCallbacks.length === 0) {
        if (timerUpdateInterval) {
            clearInterval(timerUpdateInterval);
        }
        timerUpdateInterval = setInterval(function () {
            const now = new Date();
            each(timerUpdateCallbacks, (i) => i(now));
        }, 1000);
    }
    timerUpdateCallbacks = timerUpdateCallbacks.concat([callback]);
    callback(new Date());
    return function () {
        timerUpdateCallbacks = timerUpdateCallbacks.filter((i) => i !== callback);
        if (timerUpdateCallbacks.length === 0 && timerUpdateInterval) {
            clearInterval(timerUpdateInterval);
            timerUpdateInterval = undefined;
        }
    };
}

export const checkBirthdateStringUnder13 = (birthdate: string | undefined) => {
    const parsed = moment(birthdate);
    const birthdateIsValid = parsed.isValid() && parsed > moment('1900-01-01') && parsed < moment();
    const age = birthdateIsValid ? moment().diff(parsed, 'years') : undefined;
    const isUnder13 = isNumber(age) && age < 13;

    return {
        age,
        birthdateIsValid,
        isUnder13,
    };
};

// given a date, interpret it in the target timezone, and build a `Date` with the same values for the browser's local timezone. (ie. what you need when passing a UTC date into a datepicker)
export function dateToDatePicker(date: moment.MomentInput, targetTimezone: string) {
    const result = new Date(moment.tz(date, targetTimezone).format('YYYY-MM-DDTHH:mm:ss'));
    return dateIsValid(result) ? result : new Date();
}

// given a date, interpret it in the target timezone, and get the UTC string for it. (ie. what you need after picking a date in a date picker)
export function dateFromDatePicker(date: moment.MomentInput, targetTimezone: string) {
    const result = moment.tz(moment(date).format('YYYY-MM-DD HH:mm:ss'), targetTimezone).toISOString();
    return result;
}

export const userIsUnder13 = (birthdate: string | undefined) => {
    if (!birthdate) return false;
    const { birthdateIsValid, isUnder13 } = checkBirthdateStringUnder13(birthdate);
    return birthdateIsValid && isUnder13;
};

export const dateIsValid = (date: Date) => {
    return date instanceof Date && !isNaN(date.valueOf());
};

export const getFormat = (includeTime: boolean = false) =>
    includeTime ? BaseCore.Constants.TIMESTAMP_FORMAT : BaseCore.Constants.DATE_FORMAT;

export const getReactDatePickerFormat = () => {
    // React datepicker dateFormat property is case sensitive and Month must be uppercase.
    const lowerCaseDate = BaseCore.Constants.DATE_FORMAT.toLowerCase();
    return lowerCaseDate.replaceAll('m', 'M');
};

export const getReactDatePickerSlashFormat = () => {
    // React datepicker dateFormat property is case sensitive and Month must be uppercase.
    const lowerCaseDate = BaseCore.Constants.DATE_FORMAT_SLASH.toLowerCase();
    return lowerCaseDate.replaceAll('m', 'M');
};

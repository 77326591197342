import React, { useCallback } from 'react';
import hexToHsl from 'hex-to-hsl';

import * as Core from '../../core';

interface ThemeVariablesProps {
    className?: string;
    themeColors: Core.Models.ThemeColors;
}

const ThemeVariables = (props: ThemeVariablesProps): JSX.Element => {
    const { className, themeColors } = props;

    const createThemeColor = useCallback((hex: string, type?: string) => {
        const hslArray = hexToHsl(hex);

        switch (type) {
            case 'h':
                return hslArray[0].toString();
            case 's':
                return hslArray[1] + '%';
            case 'l':
                // TODO: setup a check to make sure the theme color is not too light
                return hslArray[2] + '%';
            default:
                return `hsl(${hslArray[0]}, ${hslArray[1] + '%'}, ${hslArray[2] + '%'})`;
        }
    }, []);

    return (
        <>
            <style>{`
                 ${!!className ? `.${className}` : ':root'} {
                    --theme-background-color: ${createThemeColor(themeColors.backgroundColor)};
                    --theme-background-color-h: ${createThemeColor(themeColors.backgroundColor, 'h')};
                    --theme-background-color-s: ${createThemeColor(themeColors.backgroundColor, 's')};
                    --theme-background-color-l: ${createThemeColor(themeColors.backgroundColor, 'l')};
                    --theme-primary-color: ${createThemeColor(themeColors.primaryColor)};
                    --theme-primary-color-h: ${createThemeColor(themeColors.primaryColor, 'h')};
                    --theme-primary-color-s: ${createThemeColor(themeColors.primaryColor, 's')};
                    --theme-primary-color-l: ${createThemeColor(themeColors.primaryColor, 'l')};
                    --theme-secondary-color: ${createThemeColor(themeColors.secondaryColor)};
                    --theme-secondary-color-h: ${createThemeColor(themeColors.secondaryColor, 'h')};
                    --theme-secondary-color-s: ${createThemeColor(themeColors.secondaryColor, 's')};
                    --theme-secondary-color-l: ${createThemeColor(themeColors.secondaryColor, 'l')};
                    --theme-accent1-color: ${createThemeColor(themeColors.accent1)};
                    --theme-accent1-color-h: ${createThemeColor(themeColors.accent1, 'h')};
                    --theme-accent1-color-s: ${createThemeColor(themeColors.accent1, 's')};
                    --theme-accent1-color-l: ${createThemeColor(themeColors.accent1, 'l')};
                    --theme-accent2-color: ${createThemeColor(themeColors.accent2)};
                    --theme-accent2-color-h: ${createThemeColor(themeColors.accent2, 'h')};
                    --theme-accent2-color-s: ${createThemeColor(themeColors.accent2, 's')};
                    --theme-accent2-color-l: ${createThemeColor(themeColors.accent2, 'l')};
                    --theme-success-color: ${createThemeColor(themeColors.success)};
                    --theme-success-color-h: ${createThemeColor(themeColors.success, 'h')};
                    --theme-success-color-s: ${createThemeColor(themeColors.success, 's')};
                    --theme-success-color-l: ${createThemeColor(themeColors.success, 'l')};
                    --theme-error-color: ${createThemeColor(themeColors.error)};
                    --theme-error-color-h: ${createThemeColor(themeColors.error, 'h')};
                    --theme-error-color-s: ${createThemeColor(themeColors.error, 's')};
                    --theme-error-color-l: ${createThemeColor(themeColors.error, 'l')};
                    --theme-primary-text-color: ${createThemeColor(themeColors.primaryTextColor)};
                    --theme-primary-text-color-h: ${createThemeColor(themeColors.primaryTextColor, 'h')};
                    --theme-primary-text-color-s: ${createThemeColor(themeColors.primaryTextColor, 's')};
                    --theme-primary-text-color-l: ${createThemeColor(themeColors.primaryTextColor, 'l')};
                    --theme-primary-button-text-color: ${createThemeColor(themeColors.primaryButtonTextColor)};
                    --theme-secondary-button-text-color: ${createThemeColor(themeColors.secondaryButtonTextColor)};
                }
          `}</style>
        </>
    );
};

export default ThemeVariables;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import { Dictionary, includes, isUndefined, keys, orderBy, pickBy, values } from 'lodash';
import pluralize from 'pluralize';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../core';
import AddMatchParticipant from './addMatchParticipant';
import CheckInModal from './checkInModal';
import ChooseDeckModal from './hearthstone/chooseDeckModal';
import MatchParticipantDecks from './hearthstone/matchParticipantDecks';
import MatchGamesScores from './matchGamesScores';
import MatchUsersList from './matchUserList/matchUserList';
import { CreateRejectReschedulesForm, CreateRescheduleRequestForm } from './reschedule';
import SubmitMatchResult from './submitMatchResult';
import { HollowButton, SolidButton } from '../../components/buttons-visuals';
import CompetitionMatchup from '../../components/competitionMatchup';
import ConfirmModal from '../../components/confirmModal';
import ErrorMessage from '../../components/errorMessage';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';
import Modal from '../../components/modal';
import { useCreateLocationStationModal } from '../../components/modifyLocationStationModal';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useModal } from '../../hooks/modal';
import { useLeague } from '../../hooks/store';
import { MatchParticipantService } from '../../services/matchParticipantService';
import { MatchService } from '../../services/matchService';
import PlatformEventsService from '../../services/platformEventsService';

const MATCH_PARTICIPANT_BANNED_DECK = 'MatchParticipantBannedDeck';
const MATCH_PARTICIPANT_CHECKED_IN = 'MatchParticipantCheckedIn';

interface MatchParticipantsProps extends WithLoadingProps {
    addLocationStation?: (s: Core.Models.LeagueLocationStation) => void;
    canEditSeason: boolean;
    isMyMatch: boolean;
    location?: Core.Models.LeagueLocation;
    match: {
        bestOf: number;
        competitionStyle: Core.Models.CompetitionStyle;
        currentState: Core.Models.MatchState;
        game: {
            eventType: Core.Models.EventType;
            featureSet: Core.Models.FeatureSet;
            gameHandleSource?: Core.Models.GameHandleSource;
            maximumLobbySize?: number;
            minimumSeats: number;
            name: string;
            rankings: { [key: string]: number };
            rankingTerm: string;
            rankingType: Core.Models.RankingType;
            resultFileExtensions?: string[];
        };
        hostNotes?: string;
        id: string;
        isDisputed: boolean;
        participants: {
            hasCheckedIn: boolean;
            hasForfeited: boolean;
            name?: string;
            participantId: string;
            rejectRescheduleRequestsAtUtc?: string;
            sortOrder: number;
            teamId?: string;
        }[];
        requireGameRanks: boolean;
        requireHandleForCheckin: boolean;
        roundState: Core.Models.CompetitionState;
        scoringType: Core.Models.ScoringType;
        season: {
            id: string;
            payableExemptLeagueDesignationIds: string[];
            payableId?: string;
            tennisStyle: boolean;
            tennisStyleSets?: number;
            titleConfiguration?: Core.Models.ChessConfiguration;
        };
        stageSettings: Core.Models.StageSettings;
        stageState: Core.Models.StageState;
        stageTypeId: Core.Models.StageTypeId;
        useSimplifiedCheckin: boolean;
    };
    participantsUserCanEdit: {
        hasCheckedIn: boolean;
        hasForfeited: boolean;
        id: string;
        name?: string;
        participantId: string;
        rejectRescheduleRequestsAt: Core.Models.RejectRescheduleRequestsAt;
        rejectRescheduleRequestsAtUtc?: string;
        sortOrder: number;
        teamId?: string;
    }[];
    reloadMatchData: () => Promise<void>;
    within15MinutesOfStartTime: boolean;
}

interface UpdateHostNotesFormValues {
    hostNotes: string;
}

export const getCheckedRosterUsers = (
    users: Core.Models.MatchParticipantUser[],
    roster: { [key: string]: boolean }
): Core.Models.MatchParticipantUser[] =>
    users.filter((user) => (user.userId && roster ? !!roster[user.userId] : false));

const MatchParticipants = (props: MatchParticipantsProps): JSX.Element => {
    const {
        addLocationStation,
        canEditSeason,
        isMyMatch,
        location,
        match,
        participantsUserCanEdit,
        reloadMatchData,
        setError,
        setIsLoading,
        within15MinutesOfStartTime,
    } = props;

    const [isConfirmingCheckIn, setIsConfirmingCheckIn] = useState<boolean>(false);
    const [isConfirmingUndoCheckIn, setIsConfirmingUndoCheckin] = useState<boolean>(false);
    const [isForfeiting, setIsForfeiting] = useState<boolean>(false);
    const [isForfeitingAll, setIsForfeitingAll] = useState<boolean>(false);
    const [isSubmittingMatchResults, setIsSubmittingMatchResults] = useState<boolean>(false);
    const [activeMatchParticipantId, setActiveMatchParticipantId] = useState<string | undefined>(undefined);
    const [matchParticipants, setMatchParticipants] = useState<Core.Models.MatchParticipant[] | undefined>(undefined);
    const [rosters, setRosters] = useState<{ [key: string]: { [key: string]: boolean } }>({});
    const [teamForfeiting, setTeamForfeiting] = useState<
        { participantId: string; teamId?: string; name?: string } | undefined
    >(undefined);
    const [teamBanningDecks, setTeamBanningDecks] = useState<Core.Models.MatchParticipant | undefined>(undefined);
    const [teamRemoving, setTeamRemoving] = useState<Core.Models.MatchParticipant | undefined>(undefined);
    const [isAdding, setIsAdding] = useState<boolean>(false);

    const loadData = useCallback(async () => {
        try {
            const data = await MatchService.getMatchParticipants(match.id);
            setMatchParticipants(data);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [match.id, setError, setIsLoading]);

    useEffect(() => {
        (async () => {
            await loadData();
        })();
    }, [loadData]);

    const league = useLeague();

    useEffect(() => {
        if (!league?.id) return;
        let listenerId: string | null = null;
        (async () => {
            listenerId = await PlatformEventsService.startListening(
                [MATCH_PARTICIPANT_BANNED_DECK, MATCH_PARTICIPANT_CHECKED_IN],
                league.id
            );
        })();
        return () => {
            (async () => {
                if (!!listenerId)
                    await PlatformEventsService.stopListening(listenerId, [
                        MATCH_PARTICIPANT_BANNED_DECK,
                        MATCH_PARTICIPANT_CHECKED_IN,
                    ]);
            })();
        };
    }, [league?.id]);

    const handleMatchParticipantBannedDeck = useCallback(
        async (matchId: string) => {
            if (matchId === match.id) {
                await loadData();
                await reloadMatchData();
            }
        },
        [loadData, match.id, reloadMatchData]
    );

    const handleMatchParticipantCheckedIn = useCallback(
        async (matchId: string) => {
            if (matchId === match.id) {
                await loadData();
                await reloadMatchData();
            }
        },
        [loadData, match.id, reloadMatchData]
    );

    const routeDataReceived = useCallback(
        ({ eventName, data }: { eventName: string; data: any }) => {
            switch (eventName) {
                case MATCH_PARTICIPANT_BANNED_DECK:
                    return handleMatchParticipantBannedDeck(data);
                case MATCH_PARTICIPANT_CHECKED_IN:
                    return handleMatchParticipantCheckedIn(data);
                default:
            }
        },
        [handleMatchParticipantBannedDeck, handleMatchParticipantCheckedIn]
    );

    useEffect(() => {
        const subscription = PlatformEventsService.dataReceived.subscribe(routeDataReceived);
        return () => subscription.unsubscribe();
    }, [routeDataReceived]);

    const matchWord = useMemo(() => Core.Competition.getMatchWord(match.game.eventType), [match.game.eventType]);

    const isEliminationStage = useMemo(() => {
        const stageTypeIds = [Core.Models.StageTypeId.DoubleElimination, Core.Models.StageTypeId.SingleElimination];
        return stageTypeIds.includes(match.stageTypeId);
    }, [match.stageTypeId]);

    const canEditAParticipant = participantsUserCanEdit.length > 0;

    const canCheckIn = useMemo(
        () =>
            match.currentState <= Core.Models.MatchState.NotStarted &&
            (!isEliminationStage ||
                (match.participants.length >= 2 &&
                    match.participants.every((participant: { teamId?: string }) => !!participant.teamId))) &&
            (canEditSeason || within15MinutesOfStartTime),
        [canEditSeason, isEliminationStage, match.currentState, match.participants, within15MinutesOfStartTime]
    );

    const changeResultsWarningNode = useMemo(
        () =>
            match.roundState >= Core.Models.CompetitionState.IsComplete &&
            match.currentState >= Core.Models.MatchState.IsComplete &&
            (match.stageTypeId === Core.Models.StageTypeId.Swiss ? (
                <InfoMessage
                    message={`Changing the results of a past ${matchWord.toLowerCase()} within a Swiss system stage may affect competitive integrity, since future rounds are determined by the results of previous rounds.`}
                    type="info"
                />
            ) : (
                match.stageTypeId === Core.Models.StageTypeId.SingleElimination && (
                    <InfoMessage
                        message={`Changing the winner of a past ${matchWord.toLowerCase()} within a single elimination stage will clear out all future completed ${pluralize(
                            matchWord
                        ).toLowerCase()} that branch off of it.`}
                        type="info"
                    />
                )
            )),
        [match.currentState, match.roundState, match.stageTypeId, matchWord]
    );

    const canSubmitMatchGameResult = useMemo(
        () =>
            (match.competitionStyle === Core.Models.CompetitionStyle.HeadToHead &&
                participantsUserCanEdit.length > 0 &&
                match.currentState === Core.Models.MatchState.InProgress) ||
            (canEditSeason &&
                match.currentState > Core.Models.MatchState.RescheduleRequested &&
                match.stageState < Core.Models.StageState.IsComplete),
        [canEditSeason, match.competitionStyle, match.currentState, match.stageState, participantsUserCanEdit.length]
    );

    const getRostersUserCanEdit = useCallback((): Dictionary<{ [key: string]: boolean }> => {
        if (isUndefined(matchParticipants)) return {};

        return pickBy(rosters, (_value, key) => {
            return participantsUserCanEdit.filter(
                (participant: { participantId: string }) => participant.participantId === key
            )[0];
        });
    }, [matchParticipants, participantsUserCanEdit, rosters]);

    const simplifiedCheckin = useCallback(
        async (participantIds: string[]) => {
            try {
                await MatchService.simplifiedCheckin({
                    matchId: match.id,
                    participantIds,
                });

                await loadData();
                await reloadMatchData();
            } catch (e) {
                const message = Core.API.getErrorMessage(e);
                toast.error(`There was an issue checking into this ${matchWord.toLowerCase()}: ${message}`);
            }
        },
        [loadData, match.id, matchWord, reloadMatchData]
    );

    const forfeitMatch = useCallback(async () => {
        const participantId = teamForfeiting?.participantId;
        if (!participantId) return;

        try {
            await MatchService.forfeitMatch({
                matchId: match.id,
                participantId,
            });
            await loadData();
            await reloadMatchData();
            setTeamForfeiting(undefined);
        } catch (err) {
            toast.error(`There was an issue forfeiting this ${matchWord.toLowerCase()}. Please try again.`);
        }
    }, [loadData, match.id, matchWord, reloadMatchData, teamForfeiting]);

    const unforfeitMatch = useCallback(async () => {
        const participantId = teamForfeiting?.participantId;
        if (!participantId) return;

        try {
            await MatchService.unforfeitMatch({
                matchId: match.id,
                participantId,
            });
            await loadData();
            await reloadMatchData();
            setTeamForfeiting(undefined);
        } catch (err) {
            toast.error(`There was an issue unforfeiting this ${matchWord.toLowerCase()}. Please try again.`);
        }
    }, [loadData, match.id, matchWord, reloadMatchData, teamForfeiting]);

    const forfeitMatchAll = useCallback(async () => {
        try {
            await MatchService.forfeitMatchAll({
                matchId: match.id,
            });
            await loadData();
            await reloadMatchData();
            setIsForfeitingAll(false);
        } catch (err) {
            toast.error(
                `There was an issue forfeiting all participants for this ${matchWord.toLowerCase()}. Please try again.`
            );
        }
    }, [loadData, match.id, matchWord, reloadMatchData]);

    const removeMatchParticipant = useCallback(async () => {
        const matchParticipantId = teamRemoving?.id;
        if (!matchParticipantId) return;

        try {
            await MatchParticipantService.delete(matchParticipantId);
            await loadData();
            await reloadMatchData();
            setTeamRemoving(undefined);
        } catch (err) {
            toast.error(`There was an issue removing this team. Please try again.`);
        }
    }, [loadData, reloadMatchData, teamRemoving?.id]);

    const renderForfeitPrompt = useCallback(
        (
            participant: { hasForfeited: boolean; name?: string; participantId: string; sortOrder: number },
            canAccessMultiple: boolean
        ): JSX.Element | null => {
            const { hasForfeited, sortOrder } = participant;
            return (
                <HollowButton
                    as="button"
                    className={classNames(`forfeit-${sortOrder - 1}`)}
                    color="destructive"
                    key={participant.participantId}
                    onClick={() => {
                        setIsForfeiting(!participant.hasForfeited);
                        setTeamForfeiting(participant);
                    }}
                    title={`${hasForfeited ? 'Unfo forfeit' : 'Forfeit'} ${participant.name}`}
                >
                    {`${hasForfeited ? 'Undo forfeit' : 'Forfeit'} ${canAccessMultiple ? participant.name : ''}`}
                </HollowButton>
            );
        },
        []
    );

    const renderForfeitPrompts = useCallback(() => {
        const { competitionStyle, currentState, stageState } = match;

        if (competitionStyle !== Core.Models.CompetitionStyle.HeadToHead) return null;
        if (stageState >= Core.Models.StageState.IsComplete) return null;

        const forfeitedParticipants = participantsUserCanEdit.filter(
            (participant: { hasForfeited: boolean }) => participant.hasForfeited
        );
        const participantPromptsToRender =
            forfeitedParticipants.length > 0 ? forfeitedParticipants : participantsUserCanEdit;
        return orderBy(
            participantPromptsToRender,
            (participant: { hasForfeited: boolean; participantId: string; sortOrder: number; teamId?: string }) =>
                participant.sortOrder
        ).map((participant: { hasForfeited: boolean; participantId: string; sortOrder: number; teamId?: string }) => {
            if (
                !participant.teamId ||
                (!canEditSeason && (participant.hasForfeited || currentState >= Core.Models.MatchState.IsComplete))
            )
                return null;

            return renderForfeitPrompt(participant, participantsUserCanEdit.length > 1);
        });
    }, [canEditSeason, participantsUserCanEdit, match, renderForfeitPrompt]);

    const renderForfeitAllPrompt = useCallback((): JSX.Element => {
        if (match.competitionStyle !== Core.Models.CompetitionStyle.HeadToHead) return <></>;
        if (match.stageState >= Core.Models.StageState.IsComplete) return <></>;
        if (isEliminationStage) return <></>;

        const isBye = match.participants.length <= 1;
        if (!canEditSeason || isBye) return <></>;

        // everyone has already forfeited - can't forfeit any harder!
        if (match.participants.every((participant) => participant.hasForfeited)) return <></>;

        return (
            <HollowButton
                as="button"
                className="forfeit-both"
                color="destructive"
                onClick={() => setIsForfeitingAll(true)}
            >
                Forfeit both teams
            </HollowButton>
        );
    }, [canEditSeason, isEliminationStage, match.competitionStyle, match.participants, match.stageState]);

    const confirmUndoCheckIn = useCallback(async () => {
        try {
            const participantIdsUserCanEdit = participantsUserCanEdit
                .filter((participant: { hasCheckedIn: boolean }) => participant.hasCheckedIn) // remove non-checked-in teams
                .map((participant: { participantId: string }) => participant.participantId);
            for (const rosterId of participantIdsUserCanEdit) {
                await MatchService.undoCheckIn({
                    matchId: match.id,
                    participantId: rosterId,
                });
            }

            await loadData();
            await reloadMatchData();
            setIsConfirmingUndoCheckin(false);
        } catch (err) {
            toast.error(`There was an issue undoing checkin for this ${matchWord.toLowerCase()}. Please try again.`);
        }
    }, [loadData, match.id, matchWord, participantsUserCanEdit, reloadMatchData]);

    const handleUserListClick = useCallback(
        (userId: string, participantId: string, checked: boolean) => {
            const newRosters = {
                ...rosters,
                [participantId]: {
                    ...rosters[participantId],
                    [userId]: checked,
                },
            };
            setRosters(newRosters);
        },
        [rosters]
    );

    const renderBanDeckPrompt = useCallback(
        (opposingParticipant: Core.Models.MatchParticipant, canAccessMultiple: boolean): JSX.Element | null => {
            return (
                <SolidButton
                    as="button"
                    className={classNames(`teams-${opposingParticipant.sortOrder - 1}`, 'attention-light')}
                    key={opposingParticipant.id}
                    onClick={() => setTeamBanningDecks(opposingParticipant)}
                    title={`Ban ${canAccessMultiple ? `${opposingParticipant.name}` : 'Opponent'} Deck`}
                >
                    {`Ban ${canAccessMultiple ? `${opposingParticipant.name}` : 'Opponent'} Deck`}
                </SolidButton>
            );
        },
        []
    );

    const renderBanDeckPrompts = useCallback(() => {
        const { competitionStyle, currentState } = match;

        if (!matchParticipants) return <></>;
        if (matchParticipants.some((mp: Core.Models.MatchParticipant) => !mp.teamId)) return <></>;
        if (competitionStyle !== Core.Models.CompetitionStyle.HeadToHead) return <></>;
        if (currentState !== Core.Models.MatchState.NotStarted) return <></>;

        return orderBy(participantsUserCanEdit, (participant) => participant.sortOrder).map(
            (participant: { hasCheckedIn: boolean; id: string; teamId?: string; name?: string }) => {
                if (!participant.hasCheckedIn) return null;
                const opposingMatchParticipant = matchParticipants.find(
                    (mp: Core.Models.MatchParticipant) => mp.id !== participant.id
                );
                if (
                    !opposingMatchParticipant?.hearthstoneDecks ||
                    opposingMatchParticipant.hearthstoneDecks.length === 0 ||
                    opposingMatchParticipant.hearthstoneDecks.some(
                        (deck: Core.Models.MatchHearthstoneDeck) => deck.isBanned
                    )
                )
                    return null;

                return renderBanDeckPrompt(opposingMatchParticipant, participantsUserCanEdit.length > 1);
            }
        );
    }, [match, matchParticipants, participantsUserCanEdit, renderBanDeckPrompt]);

    const shouldDisableCheckInButton = useCallback(() => {
        const rostersUserCanEdit = getRostersUserCanEdit();
        const checkedInCounts = values(rostersUserCanEdit).map((roster) => {
            return keys(pickBy(values(roster))).length;
        });
        if (!checkedInCounts.length) return true;
        // not doing greater than check here because you can't check more than minimumSeats
        return !checkedInCounts.every((count: number) => match.game.minimumSeats === count);
    }, [getRostersUserCanEdit, match.game.minimumSeats]);

    const renderCheckInStatus = useCallback(
        (participant: Core.Models.MatchParticipant) => {
            const { users } = participant;
            const checkedInUserCount = participant.hasCheckedIn
                ? match.game.minimumSeats
                : getCheckedRosterUsers(users, rosters[participant.participantId]).length;
            return <h5 className="heading-5 mt3x">{`${checkedInUserCount}/${match.game.minimumSeats} players`}</h5>;
        },
        [getCheckedRosterUsers, match.game.minimumSeats, rosters]
    );

    const matchParticipantIsEligible = useCallback(
        (matchParticipant: Core.Models.MatchParticipant): boolean => {
            if (!match.season.payableId) return true;
            if (match.season.payableExemptLeagueDesignationIds.includes(matchParticipant.leagueDesignationId ?? ''))
                return true;

            return matchParticipant.fulfillments.some(
                (fulfillment: Core.Models.PayableFulfillment) =>
                    fulfillment.payableId === match.season.payableId && fulfillment.complete && !fulfillment.refunded
            );
        },
        [match.season.payableId]
    );

    const isFull = useCallback(
        (matchParticipantId: string) => {
            return keys(pickBy(rosters[matchParticipantId])).length >= match.game.minimumSeats;
        },
        [match.game.minimumSeats, rosters]
    );

    const renderUndoCheckInPrompt = useCallback((): JSX.Element => {
        if (match.competitionStyle !== Core.Models.CompetitionStyle.HeadToHead) return <></>;

        const anyParticipantCheckedIn = match.participants.some(
            (participant: { hasCheckedIn: boolean }) => participant.hasCheckedIn
        );
        const anyParticipantForfeited = match.participants.some(
            (participant: { hasForfeited: boolean }) => participant.hasForfeited
        );
        if (!canEditSeason || !anyParticipantCheckedIn || anyParticipantForfeited) return <></>;

        return (
            <HollowButton
                as="button"
                className="teams-both"
                color="secondary"
                onClick={() => setIsConfirmingUndoCheckin(true)}
            >
                Undo check in
            </HollowButton>
        );
    }, [canEditSeason, match.competitionStyle, match.participants]);

    const shouldShowCheckInPrompt = useCallback((): boolean => {
        if (match.competitionStyle !== Core.Models.CompetitionStyle.HeadToHead) return false;

        return (
            match.currentState <= Core.Models.MatchState.NotStarted &&
            !participantsUserCanEdit.every((participant) => participant.hasCheckedIn)
        );
    }, [match.competitionStyle, match.currentState, participantsUserCanEdit]);

    const renderCheckInPrompt = useCallback((): JSX.Element => {
        // check for check-in prompts first
        const showCheckInPrompt = shouldShowCheckInPrompt();
        if (!showCheckInPrompt) return <></>;

        if (match.useSimplifiedCheckin) {
            const participantsNeedingCheckin = orderBy(
                match.participants.filter((mp) => !mp.hasCheckedIn),
                (mp) => mp.sortOrder
            );
            const editableParticipantIds = participantsUserCanEdit.map(
                (participant: { participantId: string }) => participant.participantId
            );
            const editableParticipantsNeedingCheckin = participantsNeedingCheckin.filter((mp) =>
                editableParticipantIds.includes(mp.participantId)
            );

            return (
                <>
                    {editableParticipantsNeedingCheckin.map((mp) => (
                        <SolidButton
                            as="button"
                            className={classNames(`teams-${mp.sortOrder - 1}`)}
                            onClick={() => simplifiedCheckin([mp.participantId])}
                        >
                            {mp.name} ready
                        </SolidButton>
                    ))}
                    {editableParticipantIds.length > 0 && participantsNeedingCheckin.length > 1 && (
                        <SolidButton
                            as="button"
                            className="teams-both"
                            onClick={() => simplifiedCheckin(participantsNeedingCheckin.map((mp) => mp.participantId))}
                        >
                            Both teams ready
                        </SolidButton>
                    )}
                </>
            );
        }

        const disableCheckInButton = shouldDisableCheckInButton();
        return (
            <SolidButton
                as="button"
                className="teams-0"
                disabled={disableCheckInButton}
                onClick={() => setIsConfirmingCheckIn(true)}
            >
                {disableCheckInButton ? `Select ${match.game.minimumSeats} team members below to check in` : 'Check in'}
            </SolidButton>
        );
    }, [match.game.minimumSeats, shouldDisableCheckInButton, shouldShowCheckInPrompt]);

    const renderMatchParticipant = useCallback(
        (matchParticipantIndex: number): JSX.Element => (
            <div className="match-page__match-participant">
                {match.competitionStyle === Core.Models.CompetitionStyle.HeadToHead &&
                    !!matchParticipants?.[matchParticipantIndex]?.participantId && (
                        <>
                            {matchParticipantIsEligible(matchParticipants[matchParticipantIndex]) ? (
                                <>
                                    {renderCheckInStatus(matchParticipants[matchParticipantIndex])}
                                    <MatchUsersList
                                        canEdit={
                                            participantsUserCanEdit
                                                .map((participant) => participant.participantId)
                                                .includes(matchParticipants[matchParticipantIndex].participantId) &&
                                            canCheckIn
                                        }
                                        full={isFull(matchParticipants[matchParticipantIndex].participantId)}
                                        game={match.game}
                                        handleListClick={handleUserListClick}
                                        hideCheckinSelector={match.useSimplifiedCheckin}
                                        participant={matchParticipants[matchParticipantIndex]}
                                        requireGameRanks={match.requireGameRanks}
                                        requireHandleForCheckin={match.requireHandleForCheckin}
                                        roster={rosters[matchParticipants[matchParticipantIndex].participantId] || {}}
                                        seasonId={match.season.id}
                                    />
                                    {match.game.featureSet === Core.Models.FeatureSet.Hearthstone && (
                                        <MatchParticipantDecks
                                            matchParticipant={matchParticipants[matchParticipantIndex]}
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className="badge badge--completed">Not eligible</div>
                                    {(canEditSeason || isMyMatch) && (
                                        <p className="warning">Team has not paid entry fee</p>
                                    )}
                                </>
                            )}
                        </>
                    )}
            </div>
        ),
        [
            canCheckIn,
            canEditSeason,
            handleUserListClick,
            isFull,
            isMyMatch,
            match.competitionStyle,
            match.game,
            match.requireGameRanks,
            match.requireHandleForCheckin,
            match.season.id,
            matchParticipantIsEligible,
            matchParticipants,
            participantsUserCanEdit,
            renderCheckInStatus,
            rosters,
        ]
    );

    const updateHostNotes = useCallback(
        async (formValues: UpdateHostNotesFormValues) => {
            const command = {
                ...formValues,
                matchId: match.id,
            };
            await MatchService.updateHostNotes(command);
            await reloadMatchData();
        },
        [match.id, reloadMatchData]
    );

    const [rescheduleMatchModal, openRescheduleMatchModal] = useModal(
        () => 'Request a Reschedule',
        (closeModal) => (
            <CreateRescheduleRequestForm
                match={match}
                matchWord={matchWord}
                onClose={async () => {
                    closeModal();
                    await reloadMatchData();
                }}
            />
        )
    );

    const hasAutoRejectReschedules = useMemo(
        () =>
            match.participants.some(
                (p: { rejectRescheduleRequestsAtUtc?: string }) =>
                    new Date(p.rejectRescheduleRequestsAtUtc ?? '').getTime() <= new Date().getTime()
            ),
        [match.participants]
    );
    const [rejectReschedulesModal, openRejectReschedulesModal] = useModal(
        () => 'Auto-reject reschedules',
        (closeModal) => (
            <CreateRejectReschedulesForm
                match={match}
                matchParticipants={participantsUserCanEdit}
                onClose={async () => {
                    closeModal();
                    await reloadMatchData();
                }}
            />
        )
    );

    const [createStationModal, showCreateStation] = useCreateLocationStationModal({
        locationId: location?.id ?? null,
        onSubmitted: async (s: Core.Models.LeagueLocationStation) => {
            addLocationStation?.(s);
            setMatchParticipants(
                (previousParticipants) =>
                    previousParticipants?.map((p: Core.Models.MatchParticipant) =>
                        activeMatchParticipantId === p.id
                            ? {
                                  ...p,
                                  stationId: s.id,
                              }
                            : p
                    )
            );
            if (!!activeMatchParticipantId) {
                await MatchParticipantService.assignStation({
                    matchParticipantId: activeMatchParticipantId,
                    stationId: s.id,
                });
                const name =
                    matchParticipants?.find((mp: Core.Models.MatchParticipant) => mp.id === activeMatchParticipantId)
                        ?.name ?? 'participant';
                toast.success(`Successfully updated ${name}'s station`);
            }
            setActiveMatchParticipantId(undefined);
        },
    });

    if (isUndefined(matchParticipants)) return <></>;
    if (match.competitionStyle !== Core.Models.CompetitionStyle.Leaderboard && matchParticipants.length > 2) {
        return <div>{matchWord}es with more than 2 participants aren't supported.</div>;
    }
    return (
        <div className="match-page__match-participants">
            <div className="match-page__match-participants__controls">
                <div className="match-page__section__buttons" id="match-controls">
                    <div className="match-page__section__buttons__grid">
                        {match.game.featureSet === Core.Models.FeatureSet.Hearthstone && renderBanDeckPrompts()}
                        {renderCheckInPrompt()}
                        {renderUndoCheckInPrompt()}
                        {renderForfeitPrompts()}
                        {renderForfeitAllPrompt()}
                    </div>
                    <div className="match-page__section__buttons__space">
                        {canEditAParticipant &&
                            match.stageSettings.allowRescheduleRequests &&
                            match.competitionStyle === Core.Models.CompetitionStyle.HeadToHead &&
                            match.currentState <= Core.Models.MatchState.NotStarted && (
                                <>
                                    <HollowButton
                                        as="button"
                                        color="secondary"
                                        disabled={hasAutoRejectReschedules}
                                        onClick={() => openRescheduleMatchModal()}
                                        title={`Request to reschedule`}
                                    >
                                        {hasAutoRejectReschedules ? 'Another Participant Auto-Rejected' : 'Reschedule'}
                                    </HollowButton>
                                    <HollowButton
                                        as="button"
                                        color="secondary"
                                        onClick={() => openRejectReschedulesModal()}
                                    >
                                        Auto-reject reschedules
                                    </HollowButton>
                                </>
                            )}
                    </div>
                </div>
            </div>
            <div className="match-page__match">
                {renderMatchParticipant(0)}
                <div className="match-page__match-results">
                    <CompetitionMatchup
                        {...{
                            canEdit: canEditAParticipant,
                            className: 'match-page__match-results__matchup mb4x',
                            enableHighlight: true,
                            location,
                            match,
                            matchParticipants,
                            onAdd: () => setIsAdding(true),
                            onForfeit: (participant: Core.Models.MatchParticipant) => {
                                setIsForfeiting(!participant.hasForfeited);
                                setTeamForfeiting(participant);
                            },
                            onRemove: (participant: Core.Models.MatchParticipant) => {
                                setTeamRemoving(participant);
                            },
                            onStationSelect: (id: string) => {
                                setActiveMatchParticipantId(id);
                                showCreateStation();
                            },
                        }}
                    />
                    {match.currentState > Core.Models.MatchState.RescheduleRequested && (
                        <MatchGamesScores
                            canEditSeason={canEditSeason}
                            canSubmitMatchGameResult={canSubmitMatchGameResult}
                            canViewResultDetails={canEditAParticipant}
                            isMyMatch={isMyMatch}
                            match={match}
                            matchParticipants={matchParticipants}
                            matchWord={matchWord}
                            minParticipantSeats={match.game.minimumSeats}
                            participantsUserCanEdit={participantsUserCanEdit}
                            reloadMatchData={reloadMatchData}
                            reloadMatchParticipants={loadData}
                            requireResultScreenshots={match.stageSettings.requireResultScreenshots || false}
                            setIsSubmittingMatchResults={(value: boolean) => setIsSubmittingMatchResults(value)}
                        />
                    )}

                    {canEditSeason && (
                        <Formik
                            initialValues={Object.assign(
                                {},
                                {
                                    /** anything not specified here won't show an error message after an attempted submit */
                                    hostNotes: match.hostNotes || '',
                                }
                            )}
                            validationSchema={() => {
                                const schema = {
                                    hostNotes: Yup.string().notRequired().max(4096),
                                };
                                return Yup.object().shape(schema);
                            }}
                            onSubmit={async (
                                formValues: UpdateHostNotesFormValues,
                                actions: FormikActions<UpdateHostNotesFormValues>
                            ) => {
                                actions.setStatus(undefined);
                                try {
                                    await updateHostNotes(formValues);
                                } catch (e) {
                                    const message = Core.API.getErrorMessage(e);
                                    actions.setStatus(message);
                                }
                                actions.setSubmitting(false);
                            }}
                            render={(formProps: FormikProps<UpdateHostNotesFormValues>) => (
                                <Form className="form">
                                    <FormField component="textarea" name="hostNotes" description="Host notes" />

                                    {formProps.status && <ErrorMessage error={formProps.status} />}
                                    <ErrorMessage error={formProps.errors} filter={formProps.touched} />

                                    <fieldset className="form-group form-group--undecorated">
                                        <SolidButton
                                            as="button"
                                            className="full-width"
                                            disabled={formProps.isSubmitting}
                                            onClick={formProps.submitForm}
                                            pending={formProps.isSubmitting}
                                            size="medium"
                                        >
                                            Update notes
                                        </SolidButton>
                                    </fieldset>
                                </Form>
                            )}
                        />
                    )}
                </div>
                {renderMatchParticipant(1)}
            </div>

            {match.currentState <= Core.Models.MatchState.NotStarted && rejectReschedulesModal}
            {match.currentState <= Core.Models.MatchState.NotStarted && rescheduleMatchModal}

            {isConfirmingCheckIn && (
                <CheckInModal
                    canReorder={
                        match.season.tennisStyle &&
                        (match.game.featureSet !== Core.Models.FeatureSet.Chess || match.game.minimumSeats !== 4)
                    }
                    gameFeatureSet={match.game.featureSet}
                    matchId={match.id}
                    onCancel={() => setIsConfirmingCheckIn(false)}
                    onCheckedIn={async () => {
                        await loadData();
                        await reloadMatchData();
                        setIsConfirmingCheckIn(false);
                        setRosters({}); // clear rosters
                    }}
                    {...{
                        getCheckedRosterUsers,
                        matchParticipants,
                        rosters,
                    }}
                />
            )}
            {isConfirmingUndoCheckIn && (
                <ConfirmModal
                    onCancel={() => setIsConfirmingUndoCheckin(false)}
                    onConfirm={confirmUndoCheckIn}
                    title="Please confirm undo check-in"
                >
                    <p className="match-page__modal-text">This will undo check-in for all participants.</p>
                    {match.season.tennisStyle ? (
                        <InfoMessage
                            message={`This will delete all games and results for this ${matchWord.toLowerCase()}. This action cannot be undone.`}
                            type="info"
                        />
                    ) : (
                        !!match.season.titleConfiguration?.generateChessDotComLinks && (
                            <InfoMessage
                                message={`This will regenerate Chess.com challenge links for the latest game in this ${matchWord.toLowerCase()}.`}
                                type="info"
                            />
                        )
                    )}
                </ConfirmModal>
            )}
            {teamForfeiting && (
                <ConfirmModal
                    onCancel={() => setTeamForfeiting(undefined)}
                    onConfirm={isForfeiting ? forfeitMatch : unforfeitMatch}
                    title={`Are you sure you want to ${isForfeiting ? '' : 'undo'} forfeit?`}
                >
                    <p className="match-page__modal-text">
                        This will {isForfeiting ? 'forfeit' : 'undo the forfeit of'} the {matchWord.toLowerCase()}
                        {isForfeiting ? ` and will be recorded as a loss for ${teamForfeiting.name}` : ''}.
                        {!canEditSeason ? ' You cannot undo this action.' : ''}
                    </p>
                    {changeResultsWarningNode}
                </ConfirmModal>
            )}
            {!!teamRemoving && (
                <ConfirmModal
                    onCancel={() => setTeamRemoving(undefined)}
                    onConfirm={removeMatchParticipant}
                    title={`Are you sure`}
                >
                    <p className="match-page__modal-text">
                        This will remove {teamRemoving.name || 'this team'} from this {matchWord.toLowerCase()}.
                    </p>
                </ConfirmModal>
            )}
            {!!isAdding && (
                <Modal onClose={() => setIsAdding(false)} title={`Add Team(s) to ${matchWord}`}>
                    <AddMatchParticipant
                        match={match}
                        matchWord={matchWord}
                        onSubmit={async (groupParticipantIds: string[]) => {
                            await MatchParticipantService.create({
                                groupParticipantIds,
                                matchId: match.id,
                            });
                            await loadData();
                            await reloadMatchData();
                            setIsAdding(false);
                        }}
                    />
                </Modal>
            )}
            {isForfeitingAll && (
                <ConfirmModal
                    onCancel={() => setIsForfeitingAll(false)}
                    onConfirm={forfeitMatchAll}
                    title={`Are you sure you want to forfeit for all teams?`}
                >
                    <p className="match-page__modal-text">
                        This will forfeit this {matchWord.toLowerCase()} for <strong>all teams</strong>. You cannot undo
                        this action.
                    </p>
                    {changeResultsWarningNode}
                </ConfirmModal>
            )}
            {!!teamBanningDecks && (
                <ChooseDeckModal
                    decks={teamBanningDecks.hearthstoneDecks!}
                    isOpen={!!teamBanningDecks}
                    onClose={() => setTeamBanningDecks(undefined)}
                    onSubmit={async (value: string) => {
                        await MatchService.banOpponentDeck({
                            deckCode: value,
                            matchParticipantId: teamBanningDecks.id,
                        });
                        await loadData();
                        await reloadMatchData();
                        setTeamBanningDecks(undefined);
                    }}
                    modalTitle={`Select one of ${teamBanningDecks.name}'s decks to ban`}
                />
            )}
            {isSubmittingMatchResults && (
                <Modal onClose={() => setIsSubmittingMatchResults(false)} title={`${matchWord} Results`}>
                    <SubmitMatchResult
                        changeResultsWarningNode={changeResultsWarningNode}
                        matchId={match.id}
                        matchParticipants={matchParticipants}
                        onCancel={() => setIsSubmittingMatchResults(false)}
                        onComplete={async () => {
                            await reloadMatchData();
                            setIsSubmittingMatchResults(false);
                        }}
                        reload={reloadMatchData}
                    />
                </Modal>
            )}
            {createStationModal}
        </div>
    );
};

export default withLoading(MatchParticipants, {
    loadingProps: { blockItem: true },
});

import * as Core from '../core';

export abstract class TeamService {
    public static async getById(id: string): Promise<Core.Models.Team> {
        const route = Core.API.ServerRoute.forAction('teams', id);
        return await Core.API.get(route);
    }

    public static async createTeam(values: Core.Models.CreateTeamCommand): Promise<Core.Models.Team> {
        const route = Core.API.ServerRoute.forAction('teams');
        return await Core.API.post(route, values);
    }

    public static async editTeam(values: Core.Models.EditTeamCommand): Promise<Core.Models.Team> {
        const route = Core.API.ServerRoute.forAction('teams');
        return await Core.API.patch(route, values);
    }

    public static async deleteTeam(teamId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('teams', `${teamId}`);
        return await Core.API.delete(route);
    }

    public static async uploadLogo(values: Core.Models.UploadLogoCommand): Promise<string> {
        const route = Core.API.ServerRoute.forAction('teams', 'logo');
        return await Core.API.post(route, values);
    }

    public static async changeUserTeamRole(command: Core.Models.ChangeUserTeamRoleCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('teams', 'roles');
        return await Core.API.patch(route, command);
    }

    public static async deleteUserTeamRole(userTeamRoleId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('teams', `roles/${userTeamRoleId}`);
        return await Core.API.delete(route);
    }

    public static async addUser(
        command: Core.Models.CreateUserTeamRoleCommand
    ): Promise<Core.Models.CreateUserTeamRoleResponse[]> {
        const route = Core.API.ServerRoute.forAction('teams', `user`);
        return await Core.API.post(route, command);
    }

    public static async getEligibleSeasons(teamId: string): Promise<Core.Models.TeamEligibleSeasonResult> {
        const route = Core.API.ServerRoute.forAction('teams', `${teamId}/eligibleSeasons`);
        return await Core.API.get(route);
    }

    public static async setSeason(command: Core.Models.TeamSeasonCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('teams', 'season');
        return await Core.API.patch(route, command);
    }

    public static async getMatches(id: string): Promise<Core.Models.MatchHistory[]> {
        const route = Core.API.ServerRoute.forAction('teams', `${id}/matches`);
        return await Core.API.get(route);
    }

    public static async create1v1TeamWithSinglePlayer(gameId: string): Promise<Core.Models.Team> {
        const route = Core.API.ServerRoute.forAction('teams', 'create1v1TeamWithSinglePlayer');
        return await Core.API.post(route, { gameId: gameId });
    }

    public static async setOpenToFreeAgents(values: Core.Models.SetOpenToFreeAgentsRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('teams', 'openToFreeAgents');
        return await Core.API.post(route, values);
    }

    public static async setJoinCode(values: Core.Models.SetTeamJoinCodeRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('teams', 'joinCode');
        return await Core.API.post(route, values);
    }
}

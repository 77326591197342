import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { orderBy, take } from 'lodash';

import * as Core from '../../core';
import PointBreakdown from './pointBreakdown';
import ResolveTie from './resolveTie';
import InfoMessage from '../../components/infoMessage';
import { useModal } from '../../hooks/modal';
import TiebreakerService from '../../services/tiebreakerService';
import { Button } from '../button';
import { HollowButton } from '../buttons-visuals';
import GroupHeader from '../groupHeader';
import { Popover } from '../overlays';
import ParticipantLink from '../participantLink';

import './index.scss';

interface GroupStandingsProps {
    externalLinks: boolean;
    group: Core.Models.Group;
    isMyParticipant?: (participant: { teamId?: string }) => boolean;
    reloadStage: () => Promise<void>;
    showGroupName?: boolean;
    showStageName?: boolean;
    showTies?: boolean;
    stage: Core.Models.Stage;
}

const pageSize = 10;

const GroupStandings = (props: GroupStandingsProps): JSX.Element => {
    const { group, isMyParticipant, reloadStage, showTies, stage } = props;

    const isLeaderboardCompetition = stage.competitionStyle === Core.Models.CompetitionStyle.Leaderboard;

    const useTieGroup = useMemo(() => {
        const stageTypeIds = [Core.Models.StageTypeId.AutoMatcher, Core.Models.StageTypeId.Swiss];
        return stageTypeIds.includes(stage.stageTypeId);
    }, [stage.stageTypeId]);

    const participants = useMemo(
        () =>
            orderBy(group.groupParticipants, [
                (gp: Core.Models.GroupParticipant) => gp.outputRank,
                (gp: Core.Models.GroupParticipant) => -gp.points,
                (gp: Core.Models.GroupParticipant) => -(gp.winsByForfeit + gp.winsByPlay),
                (gp: Core.Models.GroupParticipant) => gp.lossesByForfeit + gp.lossesByPlay,
                (gp: Core.Models.GroupParticipant) => gp.inputRank,
            ]),
        [group.groupParticipants]
    );

    const [showAll, setShowAll] = useState(participants.length <= pageSize);
    const toShow = showAll ? participants : take(participants, pageSize);
    const needsTiebreakers = useMemo(() => TiebreakerService.tiebreakersRequired(participants), [participants]);
    const hasOpenTiebreakers = useMemo(
        () => TiebreakerService.tiebreakersApplied(participants, stage.appliedAutoTiebreakers, !!showTies),
        [participants, showTies, stage.appliedAutoTiebreakers]
    );

    const showPoints = useMemo(() => {
        const stageTypeIds = [
            Core.Models.StageTypeId.AutoMatcher,
            Core.Models.StageTypeId.DoubleRoundRobin,
            Core.Models.StageTypeId.RoundRobin,
            Core.Models.StageTypeId.Swiss,
        ];
        return stageTypeIds.includes(stage.stageTypeId);
    }, [stage.stageTypeId]);

    return (
        <div className="group-standings">
            <GroupHeader className="group-standings__name" {...props} />
            {showTies && needsTiebreakers ? (
                <InfoMessage type="error" message="Tiebreaker(s) Required" />
            ) : (
                hasOpenTiebreakers && <InfoMessage type="info" message="Auto-Tiebreakers Applied" />
            )}
            <div className="group-standings__header">
                <div className="group-standings__header-rank">#</div>
                <div className="group-standings__header-name">Team Name</div>
                <div className="group-standings__header-record">
                    {isLeaderboardCompetition ? 'Score' : `W-L${stage.settings.allowMatchTies ? `-T` : ''}`}
                </div>
                {showPoints && <div className="group-standings__header-record">Points</div>}
            </div>
            <div className="group-standings__participants">
                {toShow.map((groupParticipant: Core.Models.GroupParticipant, ix: number) => {
                    // only show the resolve link if we're resolving and this is the first conflicting for this slot
                    const showResolve =
                        showTies &&
                        groupParticipant.needsTieBreak &&
                        !take(participants, ix).some((innerParticipant: Core.Models.GroupParticipant) =>
                            innerParticipant.needsTieBreak && useTieGroup
                                ? innerParticipant.tieGroup === groupParticipant.tieGroup
                                : innerParticipant.outputRank === groupParticipant.outputRank
                        );
                    const tiedGroupParticipants = participants.filter(
                        (innerParticipant: Core.Models.GroupParticipant) =>
                            innerParticipant.needsTieBreak && useTieGroup
                                ? innerParticipant.tieGroup === groupParticipant.tieGroup
                                : innerParticipant.outputRank === groupParticipant.outputRank
                    );
                    return (
                        <GroupStandingsItem
                            key={groupParticipant.id}
                            {...{
                                groupParticipant,
                                isLeaderboardCompetition,
                                isMyParticipant,
                                reloadData: reloadStage,
                                showPoints,
                                showResolve,
                                showTies,
                                stage,
                                tiedGroupParticipants,
                                useTieGroup,
                            }}
                        />
                    );
                })}
            </div>
            {!showAll && (
                <div className="group-standings__participants-show-all">
                    <Button wide styleType={Core.Models.StyleType.OutlineOnly} onClick={() => setShowAll(true)}>
                        View All
                    </Button>
                </div>
            )}
        </div>
    );
};

interface GroupStandingsItemProps {
    groupParticipant: Core.Models.GroupParticipant;
    isLeaderboardCompetition: boolean;
    isMyParticipant?: (participant: { teamId?: string }) => boolean;
    reloadData: () => Promise<void>;
    showPoints?: boolean;
    showResolve?: boolean;
    showTies?: boolean;
    stage: Core.Models.Stage;
    tiedGroupParticipants: Core.Models.GroupParticipant[];
    useTieGroup: boolean;
}

const GroupStandingsItem = (props: GroupStandingsItemProps): JSX.Element => {
    const {
        groupParticipant,
        isLeaderboardCompetition,
        isMyParticipant,
        reloadData,
        showPoints,
        showResolve,
        showTies,
        stage,
        tiedGroupParticipants,
        useTieGroup,
    } = props;

    const [resolveTieNode, showResolveTie] = useModal(
        () => 'You must break the following tie(s) to proceed:',
        (onClose) => <ResolveTie stageId={stage.id} {...{ onClose, reloadData, tiedGroupParticipants }} />,
        { className: 'modal-container--resolve-tie' }
    );

    return (
        <div
            className={classNames('group-standings__participant', {
                'group-standings__participant--my-participant': isMyParticipant && isMyParticipant(groupParticipant),
            })}
        >
            <div className="group-standings__participant-rank">
                {groupParticipant.outputRank || groupParticipant.inputRank}
            </div>
            <div className="group-standings__participant-name" title={groupParticipant.name}>
                {groupParticipant.name ? (
                    <ParticipantLink participant={groupParticipant}>{groupParticipant.name}</ParticipantLink>
                ) : (
                    <em>Deleted team</em>
                )}
            </div>
            {showResolve && (
                <HollowButton as="button" color="secondary" onClick={showResolveTie} size="small">
                    Resolve Tie
                </HollowButton>
            )}
            <div className="group-standings__participant-record">
                {showTies && groupParticipant.needsTieBreak && (
                    <div className="group-standings__participant-needs-tie-break">
                        {useTieGroup ? groupParticipant.tieGroup : groupParticipant.outputRank}
                    </div>
                )}
                {isLeaderboardCompetition
                    ? groupParticipant.points
                    : `${groupParticipant.winsByForfeit + groupParticipant.winsByPlay || 0}-${
                          groupParticipant.lossesByForfeit + groupParticipant.lossesByPlay || 0
                      }${stage.settings.allowMatchTies ? `-${groupParticipant.ties || 0}` : ''}`}
            </div>
            {showPoints && (
                <div className="group-standings__participant-record">
                    <Popover
                        button={
                            <span className="group-standings__participant-record__value">
                                {groupParticipant.points}
                            </span>
                        }
                    >
                        <PointBreakdown participant={groupParticipant} stageSettings={stage.settings} />
                    </Popover>
                </div>
            )}
            {resolveTieNode}
        </div>
    );
};

export default GroupStandings;

import React, { useEffect, useState } from 'react';

import * as Core from '../../core';
import { StageService } from '../../services/stageService';
import TiebreakerService from '../../services/tiebreakerService';
import InfoMessage from '../infoMessage';
import withLoading, { WithLoadingProps } from '../withLoading';

import './index.scss';

interface ConfirmCompleteStageProps extends WithLoadingProps {
    stage: Core.Models.Stage;
}

const ConfirmCompleteStage = ({ setError, setIsLoading, stage }: ConfirmCompleteStageProps) => {
    const [canCompleteResponse, setCanCompleteResponse] = useState<Core.Models.CanCompleteStageResponse | undefined>(
        undefined
    );

    useEffect(() => {
        (async () => {
            try {
                const response = await StageService.canComplete(stage.id);
                setCanCompleteResponse(response);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    if (!canCompleteResponse) return <></>;

    if (!canCompleteResponse.canComplete) {
        return (
            <>
                <p>
                    You cannot complete <strong>{stage.name}</strong> due to issues.
                </p>
                <InfoMessage message={canCompleteResponse.reason || 'Error'} type="error" />
            </>
        );
    }

    const showAutoTiebreakerNotice =
        stage.appliedAutoTiebreakers &&
        stage.groups.some((g: Core.Models.Group) =>
            TiebreakerService.groupParticipantNeedsTiebreak(g.groupParticipants!)
        );

    return (
        <>
            <p className="confirm-complete-stage__text">
                Are you sure you want to complete <strong>{stage.name}</strong>? You cannot undo this action.
            </p>

            {showAutoTiebreakerNotice && (
                <p>
                    Note: An automatic tiebreaker was applied to one or more groups, based on strength of schedule,
                    strength of opponents' schedules, and weight of losses. You can still manually resolve them below,
                    but once you complete the stage, these rankings can not be changed.
                </p>
            )}
        </>
    );
};

export default withLoading(ConfirmCompleteStage, { loadingProps: { blockItem: true } });

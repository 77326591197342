import { useCallback, useEffect, useMemo, useState } from 'react';
import { compact } from 'lodash';
import moment from 'moment';

import * as Core from '../../../core';
import {
    AutoRejectGame,
    AutoRejectMatchParticipant,
    RejectReschedulesMatchParticipant,
    RejectReschedulingGame,
} from './types';
import { WithLoadingProps } from '../../../components/withLoading';
import { useTimezone, useUserPermissionService } from '../../../hooks/store';
import { MatchService } from '../../../services/matchService';

export const useAutoRejectParticipantList = (
    { minimumSeats }: AutoRejectGame,
    matchParticipants: AutoRejectMatchParticipant[]
) => {
    const header = useMemo(
        () => `${minimumSeats > 1 ? 'Team' : 'Player'}(s) currently auto-rejecting reschedule requests`,
        [minimumSeats]
    );
    const autoRejectParticipants = useMemo(
        () =>
            matchParticipants.filter(
                (p: { rejectRescheduleRequestsAtUtc?: string }) =>
                    new Date(p.rejectRescheduleRequestsAtUtc ?? '').getTime() <= new Date().getTime()
            ),
        [matchParticipants]
    );
    return {
        autoRejectParticipants,
        header,
    };
};

export const useCreateRejectReschedulesForm = (
    { eventType, minimumSeats }: RejectReschedulingGame,
    matchParticipants: RejectReschedulesMatchParticipant[],
    matchStartTimeUtc?: string
) => {
    const matchWord = useMemo(() => Core.Competition.getMatchWord(eventType, { lowercase: true }), [eventType]);
    const teamWord = useMemo(() => (minimumSeats > 1 ? 'team' : 'player'), [minimumSeats]);
    const getAutoRejectInfoMessage = useCallback(
        (rejectRescheduleRequestsAt: Core.Models.RejectRescheduleRequestsAt) => {
            switch (+rejectRescheduleRequestsAt) {
                case Core.Models.RejectRescheduleRequestsAt.None:
                    return `Stop automatically rejecting all future reschedule requests for this ${teamWord}/${matchWord}.`;
                case Core.Models.RejectRescheduleRequestsAt.All:
                    return `Automatically reject all future reschedule requests for this ${teamWord}/${matchWord}.`;
                case Core.Models.RejectRescheduleRequestsAt.OneDayBeforeMatchStart:
                    return `Automatically reject all reschedule requests within 24 hours of match start time for this ${teamWord}/${matchWord}.`;
                default:
                    return 'Unknown';
            }
        },
        [matchWord, teamWord]
    );
    const getCurrentMatchParticipant = useCallback(
        (currentMatchParticipantId: string) => matchParticipants.find(({ id }) => id === currentMatchParticipantId),
        [matchParticipants]
    );
    const isInvalidMatchStartSelection = useCallback(
        (rejectRescheduleRequestsAt: Core.Models.RejectRescheduleRequestsAt) =>
            rejectRescheduleRequestsAt >= Core.Models.RejectRescheduleRequestsAt.OneDayBeforeMatchStart &&
            !matchStartTimeUtc,
        [matchStartTimeUtc]
    );
    return {
        getAutoRejectInfoMessage,
        getCurrentMatchParticipant,
        isInvalidMatchStartSelection,
    };
};

export const useCreateRescheduleRequestForm = (rescheduleCutoffUtc?: string) => {
    const timezone = useTimezone();
    const maxRescheduleCutoff = useMemo(
        () =>
            !!rescheduleCutoffUtc
                ? moment.tz(rescheduleCutoffUtc, timezone).format(Core.Constants.MATCH_DATE_TIME_FORMAT)
                : undefined,
        [rescheduleCutoffUtc, timezone]
    );

    return {
        maxRescheduleCutoff,
    };
};

export const useVoteOnRescheduleRequest = (
    matchId: string,
    participantsUserCanEdit: { teamId?: string }[],
    setError: WithLoadingProps['setError'],
    setIsLoading: WithLoadingProps['setIsLoading']
) => {
    const timezone = useTimezone();
    const userPermissionService = useUserPermissionService();

    const [autoReject, setAutoReject] = useState<boolean>(false);
    const [pendingRescheduleRequest, setPendingRescheduleRequest] = useState<Core.Models.RescheduleRequest | undefined>(
        undefined
    );
    const [votingMatchParticipantId, setVotingMatchParticipantId] = useState<string | undefined>(undefined);

    const proposedTime = !!pendingRescheduleRequest
        ? moment.tz(pendingRescheduleRequest.proposedTimeUtc, timezone)
        : undefined;
    const teamIds = compact(participantsUserCanEdit.map((mp: { teamId?: string }) => mp.teamId));

    const loadData = useCallback(async () => {
        try {
            const data = await MatchService.getMatchRescheduleRequest(matchId);
            setPendingRescheduleRequest(data);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [matchId, setError, setIsLoading]);

    useEffect(() => {
        (async () => {
            await loadData();
        })();
    }, [loadData]);

    return {
        autoReject,
        loadData,
        pendingRescheduleRequest,
        proposedTime,
        setAutoReject,
        setPendingRescheduleRequest,
        setVotingMatchParticipantId,
        teamIds,
        userPermissionService,
        votingMatchParticipantId,
    };
};

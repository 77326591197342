import * as React from 'react';
import { Router, BrowserRouter } from 'react-router-dom';

import history from '../../services/history';

// similar to BrowserRouter from react-router-dom, but uses our shared history service to allow for redirecting without <Redirect/>
export default class CustomBrowserRouter extends BrowserRouter {
    public render() {
        return <Router history={history}>{this.props.children}</Router>;
    }
}

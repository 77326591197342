import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { isUndefined, orderBy } from 'lodash';
import moment from 'moment-timezone';

import * as Core from '../../core';
import { useNow } from '../../hooks/now';
import { useHasAnyLeagueMembershipRole, useIsPlatform, useTimezone, useUserPermissionService } from '../../hooks/store';
import { MatchService } from '../../services/matchService';
import { TimeService } from '../../services/timeService';
import { LinkButton } from '../button';

import './index.scss';

const NextMatch = (): JSX.Element => {
    const isPlatform = useIsPlatform();
    const now = useNow(60000);
    const timezone = useTimezone();
    const userPermissionService = useUserPermissionService();
    const isLeagueMember = useHasAnyLeagueMembershipRole(Core.Models.PermissionLevel.ListSpecific);

    const [matches, setMatches] = useState<Core.Models.TodaysMatch[] | undefined>(undefined);
    const [response, setResponse] = useState<Core.Models.HasResponseDate | undefined>(undefined);

    const orderedMatches = useMemo(() => {
        if (isUndefined(matches)) return [];
        return orderBy(matches, [
            (match: { currentState: Core.Models.MatchState }) =>
                match.currentState >= Core.Models.MatchState.IsComplete, // order non-completed first. this appears backward but false comes before true
            (match: { startTimeUtc: string }) => moment(match.startTimeUtc).toDate(), // then by date (earliest first)
        ]);
    }, [matches]);

    const load = useCallback(async () => {
        if (!isLeagueMember) return;
        if (isPlatform) return;

        try {
            const data = await MatchService.getMyMatchesToday();
            setMatches(data.matches);
            setResponse(data);
        } catch (error) {
            console.error(`Could not load today's matches`, error);
        }
    }, [isLeagueMember, isPlatform, setMatches, setResponse]);

    const renderTimeDescription = useCallback((isComplete: boolean, minutesUntilStart: number) => {
        if (isComplete) return 'now';
        if (minutesUntilStart <= 1) return 'now';
        if (minutesUntilStart <= 30) return `in ${minutesUntilStart} min`;
        return 'today';
    }, []);

    const renderMatch = useCallback(
        (match: Core.Models.TodaysMatch): JSX.Element => {
            if (!response) return <React.Fragment key={match.id}></React.Fragment>;

            const isComplete = match.currentState >= Core.Models.MatchState.IsComplete;
            const matchStart = TimeService.getAdjustedTime(response, match.startTimeUtc, timezone);
            const canCheckIn =
                match.competitionStyle === Core.Models.CompetitionStyle.HeadToHead &&
                match.matchParticipants.some((participant: Core.Models.TodaysMatchParticipant) =>
                    userPermissionService.hasTeamAccess(Core.Models.PermissionLevel.Edit, participant.teamId)
                );

            const minutesUntilStart = Math.ceil(matchStart.diff(now, 'minute', true));
            const isNow = !isComplete && minutesUntilStart <= 15;
            const aria = !isComplete && minutesUntilStart <= 1 ? { role: 'alert' } : {};
            const matchWord = Core.Competition.getMatchWord(match.eventType);
            return (
                <div key={match.id} className={classNames('next-match', { 'next-match--is-now': isNow })} {...aria}>
                    <div className="next-match-content">
                        <span className="next-match__label">
                            {isComplete ? 'Last' : 'Next'} {match.titleName} {matchWord}:{' '}
                            <span className="next-match__match-time">
                                {renderTimeDescription(isComplete, minutesUntilStart)}
                            </span>
                        </span>{' '}
                        <LinkButton
                            styleType={Core.Models.StyleType.TextOnly}
                            className="next-match__link"
                            to={`/matches/${match.id}`}
                        >
                            {canCheckIn && !isComplete ? 'Check In' : 'View'}
                        </LinkButton>
                    </div>
                </div>
            );
        },
        [now, renderTimeDescription, response, timezone, userPermissionService]
    );

    useEffect(() => {
        if (!userPermissionService.getUserId()) return; // only start this up if the user is logged in

        (async () => {
            await load();
        })();
    }, [load, userPermissionService]);

    useEffect(() => {
        if (isUndefined(matches)) return;
        if (!response) return;

        const interval = setInterval(
            async () => {
                await load();
            },
            MatchService.getMyMatchesTodayDelay({ matches, ...response }, timezone)
        );

        return () => clearInterval(interval);
    }, [load, matches, response, timezone]);

    if ((matches?.length ?? 0) <= 0) return <></>;
    return <div>{orderedMatches.map((match: Core.Models.TodaysMatch) => renderMatch(match))}</div>;
};

export default NextMatch;

import * as React from 'react';
import { Formik, FormikActions, FormikProps, Form } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../core';
import { SolidButton } from '../../components/buttons-visuals';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';
import { FieldSet } from '../../components/inputs';
import { useHasLeagueAccess } from '../../hooks/store';

interface EditRoundProps {
    allowRescheduleRequests: boolean;
    autoStartRounds: boolean;
    hideBestOf: boolean;
    matchWord: string;
    onSubmit: (values: EditRoundValues) => Promise<void>;
    round: Core.Models.Round;
}

interface EditRoundValues {
    autoStartDisabled?: boolean;
    bestOf: number;
    name: string;
    rescheduleCutoffUtc?: string;
    startTimeUtc?: string;
}

const EditRound = ({
    allowRescheduleRequests,
    autoStartRounds,
    hideBestOf,
    matchWord,
    onSubmit,
    round,
}: EditRoundProps): JSX.Element => {
    const canElevatedEditLeague = useHasLeagueAccess(Core.Models.PermissionLevel.ElevatedEdit);

    return (
        <Formik
            initialValues={Object.assign(
                {
                    autoStartDisabled: undefined,
                    bestOf: 1,
                    name: '',
                    rescheduleCutoffUtc: undefined,
                    startTimeUtc: undefined,
                },
                round
            )}
            validationSchema={Yup.object().shape({
                autoStartDisabled: Yup.boolean().nullable(),
                bestOf: Yup.number()
                    .positive(`Number of games per ${matchWord.toLowerCase()} must be positive`)
                    .required(`Number of games per ${matchWord.toLowerCase()} is required`),
                name: Yup.string()
                    .required('Name is required')
                    .max(Core.Constants.NAME_MAX_LENGTH, 'Name must be fewer than 50 characters'),
                rescheduleCutoffUtc: Yup.date().when('startTimeUtc', (startTimeUtc: Date) => {
                    if (!!startTimeUtc) {
                        return Yup.date()
                            .min(startTimeUtc, 'Reschedule cutoff must be after start time')
                            .nullable()
                            .notRequired();
                    }
                    return Yup.date().nullable().notRequired();
                }),
                startTimeUtc: Yup.date().nullable().notRequired(),
            })}
            onSubmit={async (values: EditRoundValues, actions: FormikActions<EditRoundValues>) => {
                actions.setStatus(undefined);
                try {
                    await onSubmit(values);
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(formProps: FormikProps<EditRoundValues>) => (
                <Form className="form">
                    <fieldset className="form-group">
                        {canElevatedEditLeague && <FormField description="Id" disabled name="id" type="text" />}
                        <FormField description="Name" name="name" type="text" />
                        <FormField component="datetime" label="Start time" name="startTimeUtc" />
                        {allowRescheduleRequests && (
                            <FormField
                                component="datetime"
                                label="Reschedule cutoff (optional)"
                                name="rescheduleCutoffUtc"
                            />
                        )}
                        {!hideBestOf && (
                            <FormField
                                description={`Games per ${matchWord.toLowerCase()}`}
                                name="bestOf"
                                type="number"
                            />
                        )}
                    </fieldset>
                    {autoStartRounds && (
                        <FormField description="Disable round auto start" name="autoStartDisabled" type="checkbox" />
                    )}

                    {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                    <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                    <FieldSet>
                        <SolidButton
                            as="button"
                            layout="full"
                            onClick={formProps.submitForm}
                            pending={formProps.isSubmitting}
                        >
                            Save round
                        </SolidButton>
                    </FieldSet>
                </Form>
            )}
        />
    );
};

export default EditRound;

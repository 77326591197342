import * as React from 'react';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import * as Core from '../../core';
import { Button } from '../../components/button';
import ErrorMessage from '../../components/errorMessage';
import FormField from '../../components/formField';
import Loading from '../../components/loading';
import RegistrationHeader from '../../components/registrationHeader';
import { UserService } from '../../services/userService';

interface ForgotUsernameFormValues {
    email: string;
}

const validSchema = Yup.object().shape({
    email: Yup.string().email('Email must be formatted like an email address.').required('Email is required.'),
});

export const ForgotUsername = () => {
    const [email, setEmail] = React.useState<string | undefined>(undefined);

    const handleSubmit = async (values: ForgotUsernameFormValues, actions: FormikActions<ForgotUsernameFormValues>) => {
        try {
            const emailValue = values.email.trim();
            await UserService.forgotUsername(emailValue);
            setEmail(emailValue);
        } catch (error) {
            const message = Core.API.getErrorMessage(error);
            actions.setStatus(message);
        }
        actions.setSubmitting(false);
    };

    return !!email ? (
        <div className="form">
            <RegistrationHeader
                message={
                    <>
                        A forgot username email has been requested for {email}. If we can find an account associated
                        with this email address, we'll send you an email. If you don't get an email, check that you
                        entered the correct email address. In the meantime, you can still{' '}
                        <Link to="/">browse the league</Link> without logging in.
                    </>
                }
            />
        </div>
    ) : (
        <Formik
            initialValues={{ email: '' }}
            onSubmit={handleSubmit}
            validationSchema={validSchema}
            render={(props: FormikProps<ForgotUsernameFormValues>) => (
                <Form className="form">
                    <RegistrationHeader message="Please enter your email address to receive the username(s) associated with your email." />
                    <fieldset className="form-group">
                        <FormField
                            type="email"
                            name="email"
                            description="Your email"
                            placeholder="Email"
                            className="reset-password-request__text-input"
                        />
                    </fieldset>

                    {props.status && <ErrorMessage error={props.status} />}
                    <ErrorMessage error={props.errors} filter={props.touched} />
                    <fieldset className="form-group form-group--undecorated">
                        {props.isSubmitting && <Loading buttonLoader />}
                        <Button onClick={props.submitForm} disabled={props.isSubmitting} wide round>
                            Send Forgot Username Email
                        </Button>
                    </fieldset>
                </Form>
            )}
        />
    );
};

export default ForgotUsername;

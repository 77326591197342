import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, Redirect } from 'react-router';

import * as Core from '../../core';
import SeasonDetails from './details';
import SeasonFreeAgents from './freeAgents';
import { SeasonStages } from './seasonStages';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useCanEditSeason } from '../../hooks/store';
import { SeasonService } from '../../services/seasonService';

interface SeasonPageRouteProps {
    seasonId: string;
    stageId?: string;
}

interface SeasonPageProps extends RouteComponentProps<SeasonPageRouteProps>, WithLoadingProps {}

export const SeasonPage = (props: SeasonPageProps) => {
    const {
        match: {
            params: { seasonId, stageId },
        },
        setError,
        setIsLoading,
    } = props;

    const [season, setSeason] = useState<Core.Models.Season | undefined>(undefined);

    const load = useCallback(async () => {
        try {
            const response = await SeasonService.getById(seasonId);
            setSeason(response);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [setError, setIsLoading]);

    useEffect(() => {
        (async () => await load())();
    }, [load]);

    const canEditSeason = useCanEditSeason(season?.id);

    if (!season) return <></>;
    return (
        <div className="page">
            <SeasonDetails season={season} reloadSeason={load} />
            <SeasonStages externalLinks={false} initialStageId={stageId} reloadSeason={load} season={season} />
            <SeasonFreeAgents canEditSeason={canEditSeason} season={season} />

            {/* if we come in with a stage id set, redirect to not have it.  We'll render once first with it set, which will set the tabs default selection correctly, but avoid the ugly URL */}
            {!!stageId && <Redirect to={`/seasons/${seasonId}`} />}
        </div>
    );
};

export default withLoading(SeasonPage, {
    errorDisplayPageProps: { fullPage: true },
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

import React, { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNumber } from 'lodash';
import moment from 'moment-timezone';
import pluralize from 'pluralize';

import * as Core from '../../../core';
import { SeasonCardDisplayProps } from '..';
import { StatusIndicator, BaseButton, SolidButton } from '../../../components/buttons-visuals';
import EsrbRating from '../../../components/esrbRating';
import { useTimezone } from '../../../hooks/store';
import { Avatar } from '../../buttons-visuals';

import './index.scss';

const SeasonCardGeneric = (props: SeasonCardDisplayProps): JSX.Element => {
    const {
        alreadyInSeason,
        canJoinSeason,
        canRegisterForSeason,
        joinButtonText,
        joinSeasonModal,
        maxParticipants,
        numParticipants,
        onJoinSeasonClicked,
        season,
        seasonAlternateName,
        showImage,
        showSeasonOperator,
        showTimingDescription,
    } = props;

    const timezone = useTimezone();

    const seasonOperator = useMemo(() => (season.seasonOperators || [])[0], [season]);

    const renderBadge = useCallback((): JSX.Element => {
        // hide 1v1 badge if this is a class
        if (
            season.game.eventType === Core.Models.EventType.Class &&
            season.currentState !== Core.Models.CompetitionState.IsComplete
        )
            return <></>;

        return (
            <StatusIndicator
                className="badge--season-league"
                color={season.currentState === Core.Models.CompetitionState.IsComplete ? 'success' : 'info'}
                outlineOnly
            >
                {season.currentState === Core.Models.CompetitionState.IsComplete
                    ? 'Completed'
                    : Core.Competition.renderCompetitionFormat(season.game)}
            </StatusIndicator>
        );
    }, [season]);

    const renderParticipantStatus = useCallback((): string => {
        if (!isNumber(numParticipants)) return '';
        if (!maxParticipants || season.currentState > Core.Models.CompetitionState.NotStarted) {
            const participantTerm = season.game.minimumSeats > 1 ? 'team' : 'player';
            return `${numParticipants} ${pluralize(participantTerm, numParticipants)}`;
        }

        if (numParticipants >= maxParticipants) return 'FULL';

        const spotsRemaining = maxParticipants - numParticipants;
        return `${spotsRemaining} ${pluralize('spot', spotsRemaining)} remaining`;
    }, [maxParticipants, numParticipants, season.currentState, season.game.minimumSeats]);

    return (
        <>
            <BaseButton as="link" to={`/seasons/${season.id}`} className="league-page__seasons__season">
                <div className="league-page__seasons__season__status-icons">
                    {!season.isVisible && <FontAwesomeIcon className="ml" icon={['fas', 'eye-slash']} />}
                    {!!season.payable && <FontAwesomeIcon className="ml" icon={['fas', 'ticket']} />}
                </div>
                {showSeasonOperator && !!seasonOperator && season.game.eventType === Core.Models.EventType.Class && (
                    <div className="league-page__seasons__season__season-operator">
                        <div className="avatar-container">
                            <Avatar fallback="user" size="small" src={seasonOperator.avatarUrl} />
                        </div>
                        <p className="season-operator-name">{Core.Identity.renderMemberName(seasonOperator)}</p>
                    </div>
                )}
                {showImage && (
                    <div className="league-page__seasons__season__image">
                        {season.game.cardUrl && <img src={season.game.cardUrl} alt={season.game.name} />}
                        {season.game.esrbRating !== Core.Models.EsrbRating.None && (
                            <div className="league-page__seasons__season__image__esrb-rating">
                                <EsrbRating rating={season.game.esrbRating} />
                            </div>
                        )}
                    </div>
                )}
                <div className="league-page__seasons__season__body">
                    <div className="league-page__seasons__season__game-name">{season.game.name}</div>
                    {renderBadge()}
                    <div className="league-page__seasons__season__name">{season.name}</div>
                    {!!season.startDate && season.currentState <= Core.Models.CompetitionState.NotStarted && (
                        <div className="league-page__seasons__season__start-date">
                            Starting: {moment.tz(season.startDate, timezone).format(Core.Time.getFormat())}
                        </div>
                    )}
                    {season.timingDescription && showTimingDescription && (
                        <div className="league-page__seasons__season__timing-description">
                            {season.timingDescription}
                        </div>
                    )}
                    {isNumber(numParticipants) && (
                        <div className="league-page__seasons__season__participant-status">
                            <p>{renderParticipantStatus()}</p>
                        </div>
                    )}
                    {!!canJoinSeason && (
                        <div className="league-page__seasons__join-season">
                            <SolidButton as="button" layout="full" onClick={onJoinSeasonClicked} size="small">
                                {joinButtonText}
                            </SolidButton>
                        </div>
                    )}
                    {!!canRegisterForSeason && (
                        <div className="league-page__seasons__join-season">
                            <SolidButton as="link" layout="full" to={`/seasons/join/${season.id}`} size="small">
                                {joinButtonText}
                            </SolidButton>
                        </div>
                    )}
                    {!!alreadyInSeason && (
                        <div className="league-page__seasons__already-in-season">
                            <StatusIndicator color="info" outlineOnly>
                                Already in {seasonAlternateName.toLowerCase()}
                            </StatusIndicator>
                        </div>
                    )}
                </div>
            </BaseButton>
            {joinSeasonModal}
        </>
    );
};

export default SeasonCardGeneric;

import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import history from '../../services/history';
import { StageService } from '../../services/stageService';
import EditStageForm from '../editStage/form';

interface CreateStagePageRouteProps {
    seasonId: string;
}

interface CreateStagePageProps extends RouteComponentProps<CreateStagePageRouteProps> {}

const CreateStagePage = (props: CreateStagePageProps) => {
    const { seasonId } = props.match.params;

    return (
        <div className="page pt8x">
            <h2 className="heading-2 form__header text-center">Create Stage</h2>
            <EditStageForm
                isCreate
                onSubmit={async (values) => {
                    const data = await StageService.create({
                        seasonId,
                        ...values,
                    });
                    history.push(`/seasons/${seasonId}/${data.id}`);
                }}
                seasonId={seasonId}
            />
        </div>
    );
};

export default CreateStagePage;

import React, { ImgHTMLAttributes, useCallback, useState } from 'react';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    fallbackSrc?: string;
}

const Image = ({ fallbackSrc, src, ...rest }: ImageProps) => {
    const [source, setSource] = useState<string | undefined>(src);

    const handleError = useCallback(() => {
        if (!fallbackSrc) return;
        setSource(fallbackSrc);
    }, [fallbackSrc]);

    // `alt` and `onError` are overrideable. setting `alt` to avoid React accessibility warning.
    // `src` is not overrideable since we're handling that within this component. that's why it's at the end, since that will override whatever is in `{...rest}`
    return <img alt="" onError={handleError} {...rest} src={source} />;
};

export { Image };

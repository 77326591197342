import React from 'react';

import * as Core from '../../core';
import CompetitionGroup from './competitionGroup';
import HeadToHead from './headToHead';

export interface CompetitionMatchupProps {
    canEdit?: boolean;
    className?: string;
    enableHighlight?: boolean;
    isMyParticipant?: (participant: { teamId?: string }) => boolean;
    isPreview?: boolean;
    location?: Core.Models.LeagueLocation;
    match: {
        competitionStyle: Core.Models.CompetitionStyle;
        currentState: Core.Models.MatchState;
        id: string;
        game?: {
            gameHandleSource?: Core.Models.GameHandleSource;
            maximumLobbySize?: number;
            name: string;
            rankings: { [key: string]: number };
            rankingTerm: string;
            rankingType: Core.Models.RankingType;
        };
        scoringType: Core.Models.ScoringType;
        season: {
            id: string;
        };
    };
    matchParticipants: Core.Models.MatchParticipant[];
    onAdd?: () => void;
    onForfeit?: (participant: Core.Models.MatchParticipant) => void;
    onRemove?: (participant: Core.Models.MatchParticipant) => void;
    onStationSelect?: (id: string) => void;
}

const CompetitionMatchup = (props: CompetitionMatchupProps): JSX.Element => {
    if (props.match.competitionStyle === Core.Models.CompetitionStyle.Leaderboard)
        return <CompetitionGroup {...props} />;
    return <HeadToHead {...props} />;
};

export default CompetitionMatchup;

import React from 'react';

import InfoMessage from '../infoMessage';

interface LiteLimitationMessageProps {
    className?: string;
    message: string;
}

const LiteLimitationMessage = ({ className, message }: LiteLimitationMessageProps): JSX.Element => {
    return (
        <InfoMessage
            className={className}
            message={
                <>
                    <span className="mr">{message}</span>
                    {false && ( // todo: remove `false` check and update href to pricing page or similar
                        <a href={'https://leaguespot.gg'} rel="noopener noreferrer" target="_blank">
                            Learn more
                        </a>
                    )}
                </>
            }
            type="info"
        />
    );
};

export default LiteLimitationMessage;

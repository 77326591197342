import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import * as Core from '../../core';
import { Avatar } from '../../components/buttons-visuals';
import { Filter } from '../../components/inputs';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import useApiPagination from '../../hooks/apiPagination';
import { UserService } from '../../services/userService';

import './userSearch.scss';

const SEARCH_RESULTS_PER_PAGE = 20;

const UserSearch = (): JSX.Element => {
    const [search, setSearch] = useState<string>('');

    return (
        <div className="page user-search">
            <Filter
                debounceMs={Core.Constants.FORM_DEBOUNCE_TIME_MS}
                label="Search users"
                setValue={setSearch}
                value={search}
            />
            <SearchResults {...{ search }} />
        </div>
    );
};

interface SearchResultsProps extends WithLoadingProps {
    search: string;
}

const SearchResults = withLoading(({ search, setError, setIsLoading }: SearchResultsProps): JSX.Element => {
    const getPaginatedLeaguesAsync = useCallback(
        (page: number, pageSize: number): Promise<Core.Models.PaginatedResult<Core.Models.UserSearch.Result>> =>
            UserService.getUsers({ page, pageSize, search }),
        [search]
    );

    const { paginatedResults, Paginator } = useApiPagination(
        getPaginatedLeaguesAsync,
        setError,
        setIsLoading,
        SEARCH_RESULTS_PER_PAGE
    );

    return (
        <div>
            <h3 className="heading-3">{!!search ? 'Search results' : 'All users'}</h3>
            <div className="user-search__results">
                <div className="user-search__results__result weight-700">
                    <div>Name</div>
                    <div>Contact info</div>
                    <div>Accounts</div>
                    <div>Memberships</div>
                </div>
                {!!paginatedResults?.results.length ? (
                    paginatedResults.results.map((userSearchResult: Core.Models.UserSearch.Result) => (
                        <UserSearchResult key={userSearchResult.id} {...{ userSearchResult }} />
                    ))
                ) : (
                    <p className="full-width text-center mt4x">There are no users that match that criteria.</p>
                )}
            </div>
            {Paginator}
        </div>
    );
});

interface UserSearchResultProps {
    userSearchResult: Core.Models.UserSearch.Result;
}

export const UserSearchResult = ({ userSearchResult }: UserSearchResultProps): JSX.Element => (
    <div className="user-search__results__result">
        <div>
            <div className="disp-flex flex-dir-col align-center mb">
                <Avatar
                    className="mr"
                    fallback="user"
                    isUnder13={false}
                    size="small"
                    src={userSearchResult.avatarUrl}
                />
                <div>
                    <div className="disp-flex">
                        <Link to={`/users/${userSearchResult.id}`}>
                            {userSearchResult.firstName} {userSearchResult.lastName}
                        </Link>
                        <div className="ml align-start">
                            <FontAwesomeIcon
                                className={classNames(
                                    'ml',
                                    `color-${userSearchResult.isMfaEnabled ? 'success' : 'error'}`
                                )}
                                icon={['fas', userSearchResult.isMfaEnabled ? 'lock' : 'lock-open']}
                                title="MFA enabled"
                            />
                            {userSearchResult.isAdministrator && (
                                <FontAwesomeIcon
                                    className="color-primary ml"
                                    icon={['fas', 'crown']}
                                    title="Administrator"
                                />
                            )}
                        </div>
                    </div>
                    <div className="color-gray text-small">{userSearchResult.gamerTag ?? 'No gamer tag'}</div>
                </div>
            </div>
            <p>
                <FontAwesomeIcon className="mr" icon={['fas', 'calendar']} title="Birthdate" />
                {userSearchResult.birthdate ?? 'No birthdate'}{' '}
                {!!userSearchResult.birthdate && (
                    <span> (age {moment().diff(userSearchResult.birthdate, 'years')})</span>
                )}
            </p>
        </div>
        <div>
            <p>
                <FontAwesomeIcon className="mr" icon={['fas', 'user']} title="Username" />
                {userSearchResult.username}
            </p>
            <p>
                <FontAwesomeIcon className="mr" icon={['fas', 'envelope']} title="Email" />
                {userSearchResult.email}
                <FontAwesomeIcon
                    className={classNames('ml', `color-${userSearchResult.isEmailConfirmed ? 'success' : 'error'}`)}
                    icon={['fas', userSearchResult.isEmailConfirmed ? 'square-check' : 'square-xmark']}
                    title="Verified"
                />
            </p>
            {!!userSearchResult.parentEmail && (
                <p>
                    <FontAwesomeIcon className="mr" icon={['fas', 'user-nurse']} title="Parent email" />
                    {userSearchResult.parentEmail}
                    <FontAwesomeIcon
                        className={classNames(
                            'ml',
                            `color-${userSearchResult.isParentEmailConfirmed ? 'success' : 'error'}`
                        )}
                        icon={['fas', userSearchResult.isParentEmailConfirmed ? 'square-check' : 'square-xmark']}
                        title="Verified"
                    />
                </p>
            )}
        </div>
        <div>
            {renderGameAccounts('Linked accounts', userSearchResult.linkedAccounts)}
            {renderGameAccounts('Game accounts', userSearchResult.gameAccounts)}
        </div>
        <div>
            {userSearchResult.memberships.map((membership: Core.Models.UserSearch.Membership) => (
                <div className="disp-flex flex-dir-col align-center mb2x" key={membership.id}>
                    <Avatar className="mr2x" fallback="organization" size="small" src={membership.logoUrl} />
                    <div>
                        <div>
                            <FontAwesomeIcon className="mr" icon={['fas', 'school']} title="Organization" />
                            <a href={`${membership.leagueUrl}/organizations/${membership.organizationId}`}>
                                {membership.organizationName}
                            </a>
                        </div>
                        <div>
                            <FontAwesomeIcon className="mr" icon={['fas', 'people-group']} title="League" />
                            <a className="color-gray text-small" href={membership.leagueUrl}>
                                {membership.leagueName}
                            </a>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
);

const renderGameAccounts = (name: string, accounts: Core.Models.UserSearch.GameAccount[]): JSX.Element => (
    <div className="mb2x">
        <h5 className="heading-5">
            <FontAwesomeIcon className="mr" icon={['fas', 'link']} title={name} />
            {name}
        </h5>
        {accounts.length > 0 ? (
            accounts.map((account: Core.Models.UserSearch.GameAccount) => (
                <div className="disp-flex flex-dir-col align-center m2x" key={account.id}>
                    <Avatar
                        className="mr"
                        fallback="question"
                        size="xsmall"
                        src={
                            account.id === Core.Constants.OAUTH_DISCORD_PROVIDER_ID
                                ? 'https://cdn.leaguespot.gg/static/media/handle-sources/icon-discord.svg'
                                : account.id === Core.Constants.OAUTH_TWITCH_PROVIDER_ID
                                ? 'https://cdn.leaguespot.gg/static/media/handle-sources/icon-twitch.svg'
                                : account.iconUrl
                        }
                        title={account.name}
                    />
                    <div>{account.username}</div>
                </div>
            ))
        ) : (
            <div className="m2x">No accounts</div>
        )}
    </div>
);

export default UserSearch;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import indefinite from 'indefinite';

import { ToolTip } from '../../../components/overlays';
import { useAlternateSeasonName, useOrganizationTerm } from '../../../hooks/store';

import './index.scss';

interface PublicJoinTooltipProps {
    restrictRegistrationToWhitelistedDomains: boolean;
}

const PublicJoinTooltip = (props: PublicJoinTooltipProps) => {
    const { restrictRegistrationToWhitelistedDomains } = props;
    const organizationTerm = useOrganizationTerm();
    const seasonAlternateName = useAlternateSeasonName({ lowercase: true });

    return (
        <ToolTip trigger={<FontAwesomeIcon icon={['fas', 'circle-info']} size="1x" />}>
            <>
                <div>Turning this option on will:</div>
                <ul>
                    <li>
                        Display a public join button on this {organizationTerm.toLowerCase()}'s page to users who are
                        not logged in
                    </li>
                    <li>
                        Allow anyone{' '}
                        {restrictRegistrationToWhitelistedDomains
                            ? 'with an email address in the list of allowed email domains'
                            : 'on the internet'}{' '}
                        to join this {organizationTerm.toLowerCase()} when signing up for{' '}
                        {indefinite(seasonAlternateName)} (if they aren't already a member)
                    </li>
                </ul>
            </>
        </ToolTip>
    );
};

export default PublicJoinTooltip;

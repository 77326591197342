import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { chain } from 'lodash';

import * as Core from '../../core';
import useCanJoinSeason from './canJoinSeason';
import CreateSeason, { CreateSeasonFormValues } from './createSeason';
import { AddButton, SolidButton } from '../../components/buttons-visuals';
import { Select, ToggleSwitch } from '../../components/inputs';
import SeasonCard from '../../components/seasonCard';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import useHashLinks from '../../hooks/hashLinks';
import { useModal } from '../../hooks/modal';
import { useAlternateSeasonName, useHasLeagueAccess, useProfile } from '../../hooks/store';
import { SeasonService } from '../../services/seasonService';

interface SeasonCategoryProps {
    canCreateSeason: boolean;
    id: string;
    name: string;
    openCreateSeason: (values?: Partial<CreateSeasonFormValues>) => void;
    renderSeasons: (seasons: Core.Models.Season[], hasCompletedSeasons: boolean) => JSX.Element | JSX.Element[];
    seasonAlternateName: string;
    seasons: Core.Models.Season[];
}

const SeasonCategory = ({
    canCreateSeason,
    id,
    name,
    openCreateSeason,
    renderSeasons,
    seasonAlternateName,
    seasons,
}: SeasonCategoryProps): JSX.Element => {
    useHashLinks();

    const [showCompletedSeasons, setShowCompletedSeasons] = useState<boolean>(false);

    const filteredSeasons = useMemo(
        () =>
            seasons.filter(
                (season: Core.Models.Season) =>
                    showCompletedSeasons || season.currentState < Core.Models.CompetitionState.IsComplete
            ),
        [seasons, showCompletedSeasons]
    );
    const hasCompletedSeasons = useMemo(
        () =>
            seasons.some(
                (season: Core.Models.Season) => season.currentState >= Core.Models.CompetitionState.IsComplete
            ),
        [seasons]
    );

    // if there aren't visible seasons in this category and the user is not a host+, don't render it at all
    if (seasons.length <= 0 && !canCreateSeason) return <></>;

    return (
        <div className="league-page__season-category" key={id}>
            <div className="league-page__seasons__header">
                <h4 id="all_seasons" className="league-page__seasons__title">
                    {name}
                </h4>
                {hasCompletedSeasons && (
                    <ToggleSwitch
                        label="Show completed"
                        onToggleSwitch={(value: boolean) => Promise.resolve(setShowCompletedSeasons(value))}
                        value={showCompletedSeasons}
                    />
                )}
            </div>

            <div className="league-page__seasons__season-container">
                {renderSeasons(filteredSeasons, hasCompletedSeasons)}
                {canCreateSeason && (
                    <AddButton
                        as="button"
                        buttonStyle="hollow"
                        className={classNames(
                            'league-page__seasons__season create',
                            seasons.length <= 0 && 'attention'
                        )}
                        iconSize="xl"
                        onClick={() => openCreateSeason({ seasonCategoryId: id })}
                    >
                        Create {seasonAlternateName}
                    </AddButton>
                )}
            </div>
        </div>
    );
};

interface LeagueSeasonsProps extends WithLoadingProps {
    requestedSelectedGameId?: string;
}

const LeagueSeasons = (props: LeagueSeasonsProps): JSX.Element => {
    const { requestedSelectedGameId, setError, setIsLoading } = props;
    const canCreateSeason = useHasLeagueAccess(Core.Models.PermissionLevel.Edit);

    const [seasonCategories, setSeasonCategories] = useState<Core.Models.SeasonCategory[]>([]);
    const [selectedGameId, setSelectedGameId] = useState<string | undefined>(undefined);
    const [createSeasonValues, setCreateSeasonValues] = useState<Partial<CreateSeasonFormValues> | undefined>(
        undefined
    );

    // update `selectedGameId` if the `requestedSelectedGameId` prop changes
    useEffect(() => {
        if (!requestedSelectedGameId) return;

        const gameIds = seasonCategories
            .flatMap((sc: Core.Models.SeasonCategory) => sc.seasons)
            .map((s: Core.Models.Season) => s.game.id);
        if (!gameIds.includes(requestedSelectedGameId)) return;

        setSelectedGameId(requestedSelectedGameId);
    }, [requestedSelectedGameId, seasonCategories]);

    const load = useCallback(async () => {
        try {
            const data = await SeasonService.getAllSeasonCategories();
            setSeasonCategories(data);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [setError, setIsLoading]);

    useEffect(() => {
        (async () => {
            await load();
        })();
    }, [load]);

    const sortedGames = chain(seasonCategories)
        .flatMap((sc) => sc.seasons)
        .flatMap((s) => s.game)
        .uniqBy((g) => g.id)
        .orderBy((g) => g.name)
        .value();

    const seasonAlternateName = useAlternateSeasonName();

    const profile = useProfile();
    const [createSeasonModal, openCreateSeasonModal] = useModal(
        () => `Create ${seasonAlternateName}`,
        () => <CreateSeason initialValues={createSeasonValues} />
    );

    const openCreateSeason = useCallback(
        (values?: Partial<CreateSeasonFormValues>) => {
            setCreateSeasonValues(values);
            openCreateSeasonModal();
        },
        [openCreateSeasonModal]
    );

    const { alreadyInSeason, canJoinSeason, canRegisterForSeason } = useCanJoinSeason();

    const renderSeasons = useCallback(
        (seasons: Core.Models.Season[], hasCompletedSeasons: boolean) => {
            if (seasons.length <= 0)
                return (
                    <div className="league-page__seasons__no-filtered-items">
                        {hasCompletedSeasons ? (
                            <>
                                There are {seasonAlternateName.toLowerCase()}s in this category, but they are all
                                completed.
                            </>
                        ) : (
                            <>
                                There are no {seasonAlternateName.toLowerCase()}s in this category for the selected
                                game.
                            </>
                        )}{' '}
                        Remove the filter to see more.
                    </div>
                );

            return seasons.map((season: Core.Models.Season) => (
                <SeasonCard
                    alreadyInSeason={alreadyInSeason(season) && !!profile?.organizationId}
                    canJoinSeason={canJoinSeason(season)}
                    canRegisterForSeason={canRegisterForSeason(season)}
                    key={season.id}
                    reloadDataOnJoin={load}
                    season={season}
                    showImage
                    showSeasonOperator
                    showTimingDescription
                />
            ));
        },
        [alreadyInSeason, canJoinSeason, canRegisterForSeason, load, profile?.organizationId, seasonAlternateName]
    );

    // IMPORTANT: keep `id="seasons"` because it is a scroll target */
    return (
        <div id="seasons" className="league-page__seasons">
            {canCreateSeason && (
                <div className="league-page__seasons__controls">
                    <SolidButton as="button" onClick={() => openCreateSeason(undefined)} size="medium">
                        <FontAwesomeIcon className="mr" icon={['fas', 'circle-plus']} size="1x" /> Create{' '}
                        {seasonAlternateName}
                    </SolidButton>
                </div>
            )}
            <Select
                id="gameId"
                label="Select a game to filter"
                layoutInline
                onChange={(e) => setSelectedGameId(e.target.value)}
                value={selectedGameId}
            >
                <option value="">All games</option>
                {sortedGames.map((game: Core.Models.Game) => (
                    <option key={game.id} value={game.id}>
                        {game.name}
                    </option>
                ))}
            </Select>
            {seasonCategories.length <= 0 ? (
                <div className="league-page__seasons__no-items">
                    There are currently no active {seasonAlternateName.toLowerCase()}s - check back later.
                </div>
            ) : (
                seasonCategories.map(({ id, name, seasons }: Core.Models.SeasonCategory) => {
                    const categorySeasons = seasons.filter(
                        (season: Core.Models.Season) => !selectedGameId || season.game.id === selectedGameId
                    );

                    return (
                        <SeasonCategory
                            canCreateSeason={canCreateSeason}
                            id={id}
                            key={id}
                            name={name}
                            openCreateSeason={openCreateSeason}
                            renderSeasons={renderSeasons}
                            seasonAlternateName={seasonAlternateName}
                            seasons={categorySeasons}
                        />
                    );
                })
            )}
            {createSeasonModal}
        </div>
    );
};

export default withLoading(LeagueSeasons, {
    loadingProps: { blockItem: true },
});

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';

import CookiesService from './cookiesService';
import { Configuration } from '../core/configuration';
import history from '../services/history';
import { FeatureGroup } from '@leaguespot/platform-js/src/models';

const appInsights =
    Configuration.disableAnalytics || !process.env.REACT_APP_APPLICATION_INSIGHTS_KEY
        ? undefined
        : new ApplicationInsights({
            config: {
                disableCookiesUsage: !CookiesService.getCookies()?.performance,
                enableCorsCorrelation: true,
                instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY,
            },
        });

let amazonEnabled = false;

if (appInsights) {
    appInsights.loadAppInsights();

    appInsights.context.application.build = Configuration.version;
    history.listen((_, action) => {
        if (action !== 'PUSH' && action !== 'REPLACE') {
            return;
        }

        // slight timeout to let the navigation happen first
        setTimeout(() => {
            ReactGA.send('pageview');
            appInsights.trackPageView({});
        }, 100);
    });
}

const event = (name: string, props?: any) => {
    ReactGA.event({ category: 'event', action: name });
    if (appInsights) {
        appInsights.trackEvent({ name }, props);
    }
};

const exception = (error: Error) => {
    if (appInsights) {
        appInsights.trackException({ error });
    }
};

const getAllCookies = (startsWith?: string) =>
    Object.entries(Cookies.get()).filter((entry) => !startsWith || entry[0].startsWith(startsWith));

const initializeGoogleAnalytics = (enabled: boolean) => {
    if (!!process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
        if (enabled) {
            ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
        } else {
            ReactGA.reset();
        }
    }
};

// initialize GA on initial load
if (!!CookiesService.getCookies()?.performance) {
    initializeGoogleAnalytics(true);
}

const login = (userId: string) => {
    ReactGA.event({ category: 'login', action: 'login' });

    if (!!appInsights) {
        appInsights.setAuthenticatedUserContext(userId);
    }

    if (amazonEnabled) {
        pushAmazonEvent("NA", "907ef2f8-55bb-4920-bea3-be76b5e07f23", "LSLogin", "LeagueSpot - Login")
    }
};

const homePageView = () => {
    if (amazonEnabled) {
        pushAmazonEvent("NA", "907ef2f8-55bb-4920-bea3-be76b5e07f23", "LSPageView", "LeagueSpot - Home Page")
    }
}

const register = () => {
    if (amazonEnabled) {
        pushAmazonEvent("NA", "907ef2f8-55bb-4920-bea3-be76b5e07f23", "LSRegistration", "LeagueSpot - Registration")
    }
}

const logout = () => {
    ReactGA.event({ category: 'logout', action: 'logout' });

    if (!!appInsights) {
        appInsights.clearAuthenticatedUserContext();
    }
};

const removeCookie = (name: string, domain: string) => {
    console.log(`Removing '${name}' 🍪 from ${domain}`);
    Cookies.remove(name, { path: '/', domain });
};

const updatePerformanceCookiesPreference = (performanceCookiesEnabled: boolean, featureGroup: FeatureGroup) => {
    if (!!appInsights) {
        appInsights.core.getCookieMgr().setEnabled(!!performanceCookiesEnabled);
    }

    // turn on GA after accepting
    initializeGoogleAnalytics(performanceCookiesEnabled);

    if (!performanceCookiesEnabled) {
        // delete app insights cookies
        const appInsightsCookies = getAllCookies('ai_');
        for (const cookie of appInsightsCookies) {
            removeCookie(cookie[0], window.location.hostname);
        }

        // delete GA cookies
        const gaCookies = getAllCookies('_ga');
        const hostname = window.location.hostname === 'localhost' ? 'localhost' : '.leaguespot.gg'; // just how GA does it
        for (const cookie of gaCookies) {
            removeCookie(cookie[0], hostname);
        }
    }

    if (featureGroup === FeatureGroup.NACE && performanceCookiesEnabled) {
        addAmazonAdTag();
    }
};

const addAmazonAdTag = () => {
    // Only add if we have performance cookies allowed
    if (!CookiesService.getCookies()?.performance) {
        return;
    }

    // Check if the script is already added
    if (document.getElementById('amzn-pixel')) {
        return;
    }

    // Create the script element
    const script = document.createElement('script');
    script.src = "https://c.amazon-adsystem.com/aat/amzn.js";
    script.id = "amzn-pixel";
    script.async = true;

    // Add the script to the head
    document.head.appendChild(script);

    amazonEnabled = true;
}

declare global {
    interface Window {
        amzn: any;
    }
}

const pushAmazonEvent = (region: string, tag: string, event: string, pageName: string) => {
    try {
        window.amzn = window.amzn || function () {
            window.amzn.q.push([arguments, (new Date).getTime()]);
        };
        window.amzn.q = [];
        window.amzn.version = "0.0";

        // Set region and add tag
        window.amzn("setRegion", region);
        window.amzn("addTag", tag);
        window.amzn("trackEvent", event, { page: pageName });
    }
    catch (err) {
        console.log(err);
    }
}

const AnalyticsService = {
    event,
    exception,
    login,
    logout,
    homePageView,
    register,
    updatePerformanceCookiesPreference,
    addAmazonAdTag,
    pushAmazonEvent
};

export default AnalyticsService;

import React, { useMemo } from 'react';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../core';
import GameRankingField from './gameRanking/gameRankingField';
import { Button } from '../../components/button';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';
import Loading from '../../components/loading';

interface EditGameRankingProps {
    onSubmit: (gameId: string, value: string) => Promise<void>;
    userGameRanking: Core.Models.UserGameMetadata;
}

interface EditNewRankingValues {
    value: string;
}

const EditGameRanking: React.FunctionComponent<EditGameRankingProps> = (props) => {
    const { onSubmit, userGameRanking } = props;
    const { game, gameId, id, value } = userGameRanking;

    const existingRankingIsValid = useMemo(() => Core.Competition.isValidRanking(value, game), [value, game]);

    const saveChanges = async (values: EditNewRankingValues, actions: FormikActions<EditNewRankingValues>) => {
        try {
            await onSubmit(id, values.value);
        } catch (error) {
            const message = Core.API.getErrorMessage(error);
            actions.setStatus(message);
        } finally {
            actions.setSubmitting(false);
        }
    };

    const schema = Yup.object().shape({
        value: Yup.string().required(`${game.rankingTerm} is required.`),
    });

    return (
        <Formik
            initialValues={{ value }}
            onSubmit={saveChanges}
            validationSchema={schema}
            render={(formProps: FormikProps<EditNewRankingValues>) => (
                <Form>
                    <fieldset className="form-group">
                        <FormField component="select" name="gameId" description="Game" disabled>
                            <option value={gameId}>{game.name}</option>
                        </FormField>
                        <GameRankingField game={game} value={value} />
                    </fieldset>

                    {!existingRankingIsValid && value === formProps.values.value && (
                        <InfoMessage
                            message={`This ${game.rankingTerm} is no longer valid. Please select a new one.`}
                            type="error"
                        />
                    )}

                    {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                    <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                    <fieldset className="form-group form-group--undecorated">
                        {formProps.isSubmitting && <Loading buttonLoader />}
                        <Button onClick={formProps.submitForm} disabled={formProps.isSubmitting} round wide>
                            Save
                        </Button>
                    </fieldset>
                </Form>
            )}
        />
    );
};

export default EditGameRanking;

import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { difference } from 'lodash';
import { toast } from 'react-toastify';
import { Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import * as Core from '../core';

// event hub modeled as singleton instance to ensure only maintaining a single connection
const activeListeners: { [listenerId: string]: string[] } = {};
const dataReceived = new Subject<any>();
let hubConnection: HubConnection | null = null;

const closeConnection = async (): Promise<void> => {
    if (!hubConnection || isConnectionDisconnected(hubConnection)) return;

    return hubConnection.stop();
};

const getConnection = (): HubConnection => {
    if (!!hubConnection) return hubConnection;

    hubConnection = Core.API.createSignalRConnection(`hubs/events`);

    return hubConnection;
};

const getStartedConnection = async (): Promise<HubConnection> => {
    const connection = getConnection();

    if (isConnectionDisconnected(connection)) {
        try {
            await connection.start();
        } catch (err) {
            console.error(err);

            // if the connection is still disconnected, communicate to the user. otherwise consider this transient
            if (isConnectionDisconnected(connection))
                toast.error('Could not start events connection. Please refresh the page.');
        }
    }

    return connection;
};

const handleDataReceived = (eventName: string, thisLeagueId: string) => (leagueId: string, data: any) => {
    if (thisLeagueId !== leagueId) return;
    return dataReceived.next({ data, eventName });
};

const isConnectionDisconnected = (connection?: HubConnection): boolean =>
    !connection || connection.state === HubConnectionState.Disconnected;

const startListening = async (events: string[], leagueId: Core.Models.League['id']): Promise<string> => {
    const connection = await getStartedConnection();

    // begin listening for events
    events.forEach((event) => connection.on(event, handleDataReceived(event, leagueId)));

    // add new listener with events
    const listenerId = uuidv4();
    activeListeners[listenerId] = events;

    return listenerId;
};

const stopListening = async (listenerId: string, events: string[]): Promise<void> => {
    const connection = getConnection();

    // stop listening for events
    // generally we would want to pass in a callback to explicitly unsubscribe, but it is not currently working.
    // be aware as we scale this out that we'll need to watch this for bug creation as I consider it a risky implementation long term
    events.forEach((event) => connection.off(event));

    // remove events from active listener
    if (!!activeListeners[listenerId]) {
        const remainingEvents = difference(activeListeners[listenerId], events);
        if (remainingEvents.length > 0) {
            activeListeners[listenerId] = remainingEvents;
        } else {
            delete activeListeners[listenerId]; // remove listener if no events left
        }
    }

    // stop connection if no listeners
    if (Object.keys(activeListeners).length <= 0) await closeConnection();
};

const PlatformEventsService = {
    dataReceived,
    startListening,
    stopListening,
};

export default PlatformEventsService;

import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import './TextButton.scss';

const TextButton = ({
    className,
    color = 'gray-dark',
    ...rest
}: Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'disabled'>) => {
    const textButtonClasses = classNames(className, 'ds-text-button');

    return <button className={textButtonClasses} {...rest} />;
};

export default TextButton;

import React, { Context, createContext, ReactChild, useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import * as Core from '../core';
import { useHasAnyLeagueMembershipRole, useIsPlatform, useLeague } from '../hooks/store';
import ChatService from '../services/chatService';
import PlatformEventsService from '../services/platformEventsService';

// SignalR server events
export const MESSAGE_UPDATED = 'MessageUpdated';
export const NEW_MATCH_THREAD_CREATED = 'NewMatchThreadCreated';
export const NEW_MESSAGE_CREATED = 'NewMessageCreated';
export const NEW_THREAD_CREATED = 'NewThreadCreated';
export const PARTICIPANT_ADDED_TO_THREAD = 'ParticipantAddedToThread';
export const PARTICIPANT_REMOVED_FROM_THREAD = 'ParticipantRemovedFromThread';
export const THREAD_DELETED = 'ThreadDeleted';

const CHAT_EVENTS = [
    MESSAGE_UPDATED,
    NEW_MATCH_THREAD_CREATED,
    NEW_MESSAGE_CREATED,
    NEW_THREAD_CREATED,
    PARTICIPANT_ADDED_TO_THREAD,
    PARTICIPANT_REMOVED_FROM_THREAD,
    THREAD_DELETED,
];

interface ChatContextType {
    isLoading: boolean;
    setLatestMessageReadId: (threadId: string, messageId: string) => void;
    unreadThreads: Core.Models.UnreadThread[];
}

const ChatContext: Context<ChatContextType> = createContext<ChatContextType>({} as ChatContextType);

export const ChatProvider = ({ children }: { children: ReactChild }) => {
    const league = useLeague();
    const isPlatform = useIsPlatform();
    const isLeagueMember = useHasAnyLeagueMembershipRole(Core.Models.PermissionLevel.ListSpecific);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [unreadThreads, setUnreadThreads] = useState<Core.Models.UnreadThread[]>([]);

    useEffect(() => {
        if (!isLeagueMember) return;
        if (isPlatform) return;
        if (!league?.enableChat || !league?.id) return;

        let listenerId: string | null = null;
        (async () => {
            setIsLoading(true);

            try {
                const threadsData = await ChatService.getUnreadThreads();
                setUnreadThreads(threadsData);
            } catch (err) {
                toast.error('Unable to retrieve unread chat threads. Please refresh the page.');
            } finally {
                setIsLoading(false);
            }

            listenerId = await PlatformEventsService.startListening(CHAT_EVENTS, league.id);
        })();

        return () => {
            (async () => {
                if (!!listenerId) await PlatformEventsService.stopListening(listenerId, CHAT_EVENTS);
            })();
        };
    }, [isLeagueMember, isPlatform, league?.enableChat, league?.id]);

    const handleNewMessageCreated = useCallback(
        (message: Core.Models.ChatThreadMessage) =>
            setUnreadThreads((currentThreads) => {
                const threadIndex = currentThreads.findIndex(
                    (thread: Core.Models.UnreadThread) => thread.id === message.threadId
                );
                if (threadIndex >= 0) return currentThreads;

                return [...currentThreads, { id: message.threadId }];
            }),
        []
    );

    const handleNewThreadCreated = useCallback(
        (thread: Core.Models.ChatThread) =>
            setUnreadThreads((currentThreads) => {
                const threadIndex = currentThreads.findIndex(
                    (currentThread: Core.Models.UnreadThread) => currentThread.id === thread.id
                );
                if (threadIndex >= 0) return currentThreads;

                return [...currentThreads, { id: thread.id }];
            }),
        []
    );

    const handleThreadDeleted = useCallback(
        (threadId: string) =>
            setUnreadThreads((currentThreads) => {
                const threadIndex = currentThreads.findIndex(
                    (thread: Core.Models.UnreadThread) => thread.id === threadId
                );
                if (threadIndex < 0) return currentThreads;

                return [...currentThreads.filter((thread: Core.Models.UnreadThread) => thread.id !== threadId)];
            }),
        []
    );

    const routeDataReceived = useCallback(
        ({ eventName, data }: { eventName: string; data: any }) => {
            switch (eventName) {
                case NEW_MESSAGE_CREATED:
                    return handleNewMessageCreated(data);
                case NEW_THREAD_CREATED:
                    return handleNewThreadCreated(data);
                case THREAD_DELETED:
                    return handleThreadDeleted(data);
                default:
            }
        },
        [handleNewMessageCreated, handleNewThreadCreated, handleThreadDeleted]
    );

    // this should only run once - the subscription shouldn't change between rerenders
    useEffect(() => {
        const subscription = PlatformEventsService.dataReceived.subscribe(routeDataReceived);
        return () => subscription.unsubscribe();
    }, [routeDataReceived]);

    const setLatestMessageReadId = (threadId: string, messageId: string): void => {
        setUnreadThreads((currentThreads) => {
            const threadIndex = currentThreads.findIndex((thread: Core.Models.UnreadThread) => thread.id === threadId);
            if (threadIndex < 0) return currentThreads;

            return [...currentThreads.filter((thread: Core.Models.UnreadThread) => thread.id !== threadId)];
        });
    };

    return (
        <ChatContext.Provider
            value={{
                isLoading,
                setLatestMessageReadId,
                unreadThreads,
            }}
        >
            {children}
        </ChatContext.Provider>
    );
};

// custom hook to access the context without requiring extra references
export const useChatContext = () => useContext<ChatContextType>(ChatContext);

export default ChatContext;

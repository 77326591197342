import * as React from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../core';
import { Button } from '../../components/button';
import ErrorMessage from '../../components/errorMessage';
import FormField from '../../components/formField';
import Loading from '../../components/loading';

interface EditGroupProps {
    initialValues?: Partial<EditGroupFormValues>;
    onSubmit: (values: EditGroupFormValues) => Promise<void>;
}

export interface EditGroupFormValues {
    id: string;
    name: string;
}

const schema = Yup.object().shape({
    name: Yup.string().required('Group name is required').max(Core.Constants.NAME_MAX_LENGTH),
});

const EditGroup = (props: EditGroupProps): JSX.Element => {
    const { initialValues, onSubmit } = props;

    return (
        <Formik
            initialValues={Object.assign(
                {
                    name: '',
                } as Partial<EditGroupFormValues>,
                initialValues || {}
            )}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
                actions.setStatus(undefined);

                try {
                    await onSubmit(values as EditGroupFormValues);
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                } finally {
                    actions.setSubmitting(false);
                }
            }}
            render={(formProps: FormikProps<Partial<EditGroupFormValues>>) => (
                <Form className="form">
                    <fieldset className="form-group">
                        <FormField description="Group name" name="name" type="text" />
                    </fieldset>

                    {formProps.status && <ErrorMessage error={formProps.status} />}
                    <ErrorMessage error={formProps.errors} filter={formProps.touched} />

                    <fieldset className="form-group form-group--undecorated">
                        {formProps.isSubmitting && <Loading buttonLoader />}
                        <Button onClick={formProps.submitForm} disabled={formProps.isSubmitting} wide round>
                            Edit group
                        </Button>
                    </fieldset>
                </Form>
            )}
        />
    );
};

export default EditGroup;

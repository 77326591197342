import React from 'react';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../core';
import { Button } from '../../components/button';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';
import Modal from '../../components/modal';
import { TeamService } from '../../services/teamService';
import Loading from '../loading';

import './index.scss';

export interface EditDesignationFormValues {
    designationId: string;
}

export interface EditDesignationsFormActions extends FormikActions<EditDesignationFormValues> {}

interface ChangeDesignationModalProps {
    designations?: Core.Models.LeagueDesignation[];
    onClose?: () => void;
    onRemoved?: () => Promise<void>;
    onSubmit: (values: EditDesignationFormValues, actions: EditDesignationsFormActions) => Promise<void>;
    team: {
        designationId?: string;
        id: string;
        name: string;
    };
}

const ChangeDesignationModal = ({
    designations,
    onClose,
    onRemoved,
    onSubmit,
    team,
}: ChangeDesignationModalProps): JSX.Element => {
    return (
        <Modal className="change-designation-modal" onClose={onClose} title="Team Designation">
            <Formik<EditDesignationFormValues>
                initialValues={{
                    designationId: team.designationId || '',
                }}
                onSubmit={onSubmit}
                render={(formProps: FormikProps<EditDesignationFormValues>) => (
                    <Form>
                        <fieldset className="form-group">
                            <FormField
                                className="form-field--touched"
                                component="select"
                                description="Team Designation"
                                disabled={(designations?.length ?? 0) <= 0}
                                name="designationId"
                            >
                                <option value="" hidden disabled>
                                    --
                                </option>
                                {designations?.map((designation: Core.Models.LeagueDesignation) => (
                                    <option key={designation.id} value={designation.id}>
                                        {designation.name}
                                    </option>
                                ))}
                            </FormField>
                        </fieldset>

                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                        <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />
                        {!designations?.length && (
                            <InfoMessage type="info" message="No designations found for your League." />
                        )}

                        {formProps.isSubmitting && <Loading buttonLoader />}
                        <fieldset className="change-designation-modal__buttons form-group form-group--undecorated">
                            <Button
                                disabled={formProps.isSubmitting || !team.designationId}
                                styleType={Core.Models.StyleType.Secondary}
                                onClick={async () => {
                                    try {
                                        await TeamService.editTeam({
                                            id: team.id,
                                            name: team.name,
                                            designationId: null,
                                        });
                                        await onRemoved?.();
                                    } catch (error) {
                                        const message = Core.API.getErrorMessage(error);
                                        toast.error(message);
                                    }
                                }}
                                outline
                                round
                            >
                                Remove Designation
                            </Button>
                            <Button disabled={formProps.isSubmitting} onClick={formProps.submitForm} round>
                                Set Designation
                            </Button>
                        </fieldset>
                    </Form>
                )}
                validationSchema={Yup.object().shape({
                    designationId: Yup.string().required('Designation is required'),
                })}
            />
        </Modal>
    );
};

export default ChangeDesignationModal;

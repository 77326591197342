import React, { SVGAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import indefinite from 'indefinite';
import { orderBy } from 'lodash';
import pluralize from 'pluralize';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import Counter from './counter';
import { useAlternateSeasonName, useProfile } from '../../hooks/store';
import history from '../../services/history';
import { UserService } from '../../services/userService';
import { HollowButton, IconButton } from '../buttons-visuals';
import Loading from '../loading';
import { Dropdown } from '../navigationComponents';

import './notifications.scss';

interface NotificationItemProps {
    closeNotification: () => void;
    item: Core.Models.CompetitionReadyItem;
    userId: string;
}

interface ItemData {
    buttonData?: {
        Icon?: SVGAttributes<SVGSVGElement>;
        text: string;
        onClick: () => void;
    };
    description: string;
    Icon?: SVGAttributes<SVGSVGElement>;
    title: string;
}

const NotificationItem = ({
    closeNotification,
    item: {
        authorizeUrl,
        handleSourceIconSvgUrl,
        handleSourceName,
        isCritical,
        matchId,
        name,
        oAuthProviderId,
        type,
        verificationState,
    },
    userId,
}: NotificationItemProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const seasonAlternateName = useAlternateSeasonName();
    const itemData: ItemData = useMemo(() => {
        switch (type) {
            case Core.Models.CompetitionReadyItemType.JoinSeason:
                return {
                    buttonData: {
                        Icon: <FontAwesomeIcon className="mr" icon={['fas', 'plus']} size="1x" />,
                        text: `Choose ${seasonAlternateName}`,
                        onClick: () => {
                            history.push('/league#all_seasons');
                            closeNotification();
                        },
                    },
                    description: `Show and improve your skills by competing in our available ${pluralize(
                        seasonAlternateName.toLowerCase()
                    )}`,
                    title: `Join ${indefinite(seasonAlternateName.toLowerCase())}`,
                };
            case Core.Models.CompetitionReadyItemType.LinkDiscord:
                return {
                    buttonData: {
                        text: 'Link Now',
                        onClick: () => {
                            if (!authorizeUrl || !oAuthProviderId) {
                                toast.error('Unknown Authorization URL.');
                                return;
                            }
                            const url = Core.Utils.generateFullAuthorizeUrl(
                                window.location.href,
                                oAuthProviderId,
                                authorizeUrl,
                                userId
                            );
                            window.location.href = url;
                        },
                    },
                    description: 'Allows you to interact with your league community.',
                    Icon: <FontAwesomeIcon className="mr" icon={['fab', 'discord']} size="lg" />,
                    title: 'Link your Discord',
                };
            case Core.Models.CompetitionReadyItemType.LinkHandle:
                return {
                    buttonData: {
                        text: 'Link Now',
                        onClick: () => {
                            if (!authorizeUrl || !oAuthProviderId) {
                                toast.error('Unknown Authorization URL.');
                                return;
                            }
                            const url = Core.Utils.generateFullAuthorizeUrl(
                                window.location.href,
                                oAuthProviderId,
                                authorizeUrl,
                                userId
                            );
                            window.location.href = url;
                        },
                    },
                    description: 'Verifies that you are the owner of this account.',
                    title: `Link your ${handleSourceName?.toUpperCase() ?? 'ACCOUNT'}`,
                };
            case Core.Models.CompetitionReadyItemType.AddHandle:
                return {
                    buttonData: {
                        text: 'Add Now',
                        onClick: () => {
                            history.push(`/users/${userId}#game_handles`);
                            closeNotification();
                        },
                    },
                    description: 'Allows you to match up with your opponent(s) in-game.',
                    title: `Add your ${handleSourceName?.toUpperCase() ?? 'ACCOUNT'}`,
                };
            case Core.Models.CompetitionReadyItemType.ResolveReschedules:
                return {
                    buttonData: {
                        text: 'Resolve Now',
                        onClick: () => {
                            if (!matchId) {
                                toast.error('Unknown match ID.');
                                return;
                            }
                            history.push(`/matches/${matchId}`);
                            closeNotification();
                        },
                    },
                    description: 'Resolve reschedules.',
                    title: 'Pending Match Reschedule',
                };
            case Core.Models.CompetitionReadyItemType.VerifyEmail:
                return {
                    buttonData: {
                        Icon: <FontAwesomeIcon className="mr" icon={['fas', 'envelope-circle-check']} size="1x" />,
                        text: 'Resend Verification Email',
                        onClick: async () => {
                            try {
                                setIsLoading(true);
                                await UserService.resendVerificationEmail({
                                    userId,
                                    verificationType: Core.Models.VerificationType.Email,
                                });
                                toast.success('A verification email has been re-sent!');
                            } catch (err) {
                                toast.error('There was an issue resending your verification email. Please try again.');
                            } finally {
                                setIsLoading(false);
                                closeNotification();
                            }
                        },
                    },
                    description: 'Gain full access to all of the available league features and competitions.',
                    title: 'Verify your email address',
                };
            case Core.Models.CompetitionReadyItemType.VerifyIdentity:
                const getButtonData = () => {
                    if (verificationState === Core.Models.UserIdentityVerificationSubmissionState.Rejected)
                        return undefined;
                    return {
                        text: 'Submit Now',
                        onClick: () => {
                            history.push(`/users/${userId}#identity_verification`);
                            closeNotification();
                        },
                    };
                };
                const getTitle = () => {
                    switch (verificationState) {
                        case Core.Models.UserIdentityVerificationSubmissionState.MoreInformationRequired:
                            return 'More Info Needed';
                        case Core.Models.UserIdentityVerificationSubmissionState.Rejected:
                            return 'Verification Rejected';
                        default:
                            return 'Verification Not Submitted';
                    }
                };
                const getDescription = () => {
                    switch (verificationState) {
                        case Core.Models.UserIdentityVerificationSubmissionState.MoreInformationRequired:
                            return 'Resolve your identity verification.';
                        case Core.Models.UserIdentityVerificationSubmissionState.Rejected:
                            return 'You are not able to compete in this league. If you believe this is in error, please contact your league host.';
                        default:
                            return 'Submit your identity verification';
                    }
                };
                return {
                    buttonData: getButtonData(),
                    description: getDescription(),
                    title: getTitle(),
                };
            case Core.Models.CompetitionReadyItemType.RelinkHandle:
                return {
                    buttonData: {
                        text: 'Relink Now',
                        onClick: () => {
                            if (!authorizeUrl || !oAuthProviderId) {
                                toast.error('Unknown Authorization URL.');
                                return;
                            }
                            const url = Core.Utils.generateFullAuthorizeUrl(
                                window.location.href,
                                oAuthProviderId,
                                authorizeUrl,
                                userId
                            );
                            window.location.href = url;
                        },
                    },
                    description: 'Verifies that you are the owner of this account.',
                    title: `Relink your ${handleSourceName?.toUpperCase() ?? 'ACCOUNT'}`,
                };
            case Core.Models.CompetitionReadyItemType.VerifyParentConsent:
                return {
                    buttonData: {
                        Icon: <FontAwesomeIcon className="mr" icon={['fas', 'envelope-circle-check']} size="1x" />,
                        text: 'Resend Parent Consent Email',
                        onClick: async () => {
                            try {
                                setIsLoading(true);
                                await UserService.resendVerificationEmail({
                                    userId,
                                    verificationType: Core.Models.VerificationType.ParentConsent,
                                });
                                toast.success('Consent email has been re-sent!');
                            } catch (err) {
                                toast.error('There was an issue resending parent consent email. Please try again.');
                            } finally {
                                setIsLoading(false);
                                closeNotification();
                            }
                        },
                    },
                    description: 'We need to collect consent from a parent of all users under 13 years old.',
                    title: 'Have your parent/guardian confirm that you can play',
                };
            case Core.Models.CompetitionReadyItemType.OutstandingLeagueFee:
                return {
                    buttonData: {
                        Icon: <FontAwesomeIcon className="mr" icon={['fas', 'dollar-sign']} size="1x" />,
                        text: 'Pay fee',
                        onClick: async () => {
                            history.push(`/users/${userId}#league_fees`);
                            closeNotification();
                        },
                    },
                    description: `You won't be able to participate in any events until you pay this fee.`,
                    title: `You need to pay ${!!name ? `a fee: ${name}` : 'a league fee'}`,
                };
            default:
                return {} as ItemData;
        }
    }, [
        authorizeUrl,
        closeNotification,
        handleSourceName,
        matchId,
        oAuthProviderId,
        seasonAlternateName,
        type,
        userId,
        verificationState,
    ]);

    if (!itemData) return <></>;
    const { buttonData, title, description, Icon } = itemData;

    return (
        <li className="notifications__dropdown__item text-left">
            {isCritical ? (
                <FontAwesomeIcon
                    icon={['fas', 'exclamation-triangle']}
                    size="1x"
                    className="notifications__dropdown__item__critical-icon color-error"
                />
            ) : (
                <FontAwesomeIcon
                    icon={['fas', 'exclamation-triangle']}
                    size="1x"
                    className="notifications__dropdown__item__critical-icon color-warning"
                />
            )}
            <div className="notifications__dropdown__item__content">
                <div className="notifications__dropdown__item__title">{title}</div>
                <div className="notifications__dropdown__item__description">{description}</div>
                {isCritical && verificationState !== Core.Models.UserIdentityVerificationSubmissionState.Rejected && (
                    <div className="notifications__dropdown__item__compete-warning">
                        You are unable to compete until this is completed.
                    </div>
                )}
                {isLoading && <Loading />}
                {!!buttonData && (
                    <HollowButton
                        color="secondary"
                        as="button"
                        className="mt"
                        onClick={buttonData.onClick}
                        size="small"
                    >
                        <>
                            {buttonData.Icon && buttonData.Icon}
                            {buttonData.text}
                        </>
                    </HollowButton>
                )}
            </div>
            {handleSourceIconSvgUrl && (
                <div className="notifications__dropdown__item__image">
                    <img alt="handle source" src={handleSourceIconSvgUrl} />
                </div>
            )}
            {Icon && (
                <div className="notifications__dropdown__item__image">
                    {React.cloneElement(Icon as React.ReactElement<SVGSVGElement>, {
                        className: 'color-white',
                    })}
                </div>
            )}
        </li>
    );
};

const Notifications = (): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);
    const profile = useProfile();

    const [competitionReadyStatus, setCompetitionReadyStatus] = useState<Core.Models.GetCompetitionReadyStatusResponse>(
        { isReady: false, items: [] }
    );
    const counterCount = useMemo(
        () =>
            competitionReadyStatus.items.filter((item: Core.Models.CompetitionReadyItem) => item.isCritical)?.length ||
            competitionReadyStatus.items.filter((item: Core.Models.CompetitionReadyItem) => !item.isCritical)?.length ||
            0,
        [competitionReadyStatus]
    );
    const counterIsCritical = useMemo(
        () =>
            competitionReadyStatus.items.length > 0 &&
            competitionReadyStatus.items.some((item: Core.Models.CompetitionReadyItem) => item.isCritical),
        [competitionReadyStatus]
    );

    const load = useCallback(async () => {
        const response = await UserService.getCompetitionReadyStatus();
        setCompetitionReadyStatus(response);
    }, []);

    useEffect(() => {
        (async () => {
            if (!profile) return;
            await load();
        })();
    }, [load, profile]);

    useEffect(() => {
        if (counterIsCritical) {
            setIsOpen(true);
        }
    }, [counterIsCritical]);

    if (!profile) return <></>;

    return (
        <Dropdown
            button={
                <IconButton
                    as="button"
                    buttonLabel="Notifications button"
                    buttonSize="large"
                    className="notifications__dropdown-trigger"
                >
                    <>
                        <FontAwesomeIcon icon={['fas', 'bell']} />
                        {counterCount > 0 && <Counter content={counterCount} critical={counterIsCritical} />}
                    </>
                </IconButton>
            }
            className="notifications"
            position="right"
            open={isOpen}
        >
            <ul className="notifications__dropdown">
                {competitionReadyStatus.items.length > 0 ? (
                    orderBy(competitionReadyStatus.items, ['isCritical', 'order'], ['desc', 'asc']).map(
                        (item: Core.Models.CompetitionReadyItem, ii: number) => (
                            <NotificationItem
                                closeNotification={() => setIsOpen(false)}
                                item={item}
                                key={`${item.type}-${ii}`}
                                userId={profile.userId}
                            />
                        )
                    )
                ) : (
                    <li className="disp-flex justify-center">All caught up!</li>
                )}
            </ul>
        </Dropdown>
    );
};

export default Notifications;

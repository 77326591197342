import * as React from 'react';
import { Buffer } from 'buffer';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import * as Core from '../../core';
import Login from './login';
import { AppState } from '../../store';
import { login } from '../../store/login/actions';

import './index.scss';

interface LoginRouteProps {
    from?: string;
}

interface LoginContainerProps extends RouteComponentProps<LoginRouteProps> {
    login: (loginUserCommand: Core.Models.LoginUserCommand) => Promise<void>;
    isAuthenticated: boolean;
    league?: Core.Models.League;
}

interface LoginContainerState {
    bypassPlatformLogin: boolean;
    loginInProgress?: boolean;
}

class LoginContainer extends React.Component<LoginContainerProps, LoginContainerState> {
    constructor(props: LoginContainerProps) {
        super(props);

        const [, paramsStr] = decodeURIComponent(props.match.params.from ?? '').split('?');
        const encodedLinkState = new URLSearchParams(paramsStr).get('state');
        const { bypassPlatformLogin }: Core.Models.OAuthState = encodedLinkState
            ? JSON.parse(Buffer.from(encodedLinkState, 'base64').toString())
            : {};

        this.state = {
            bypassPlatformLogin: !!bypassPlatformLogin,
        };
    }

    public render(): JSX.Element {
        const { isAuthenticated, match, league } = this.props;
        const { bypassPlatformLogin, loginInProgress } = this.state;

        if (bypassPlatformLogin || (isAuthenticated && !loginInProgress)) {
            const target = match.params.from ? decodeURIComponent(match.params.from) : '/';
            return <Redirect to={target} />;
        }

        return <Login onSubmit={this.handleSubmit} league={league} />;
    }

    private handleSubmit = async (values: Core.Models.LoginUserCommand) => {
        try {
            this.setState({ loginInProgress: true });
            await this.props.login(values);
        } finally {
            this.setState({ loginInProgress: false });
        }
    };
}

const mapStateToProps = (state: AppState) => {
    return {
        isAuthenticated: state.loginState.authenticated === true,
        error: state.loginState.error,
        league: state.leagueState.league && state.leagueState.league.league,
    };
};

export default connect(mapStateToProps, {
    login,
})(LoginContainer);

import React from 'react';

interface ForbiddenPageProps {
    message?: React.ReactNode;
    title?: React.ReactNode;
}

const ForbiddenPage = ({ message, title }: ForbiddenPageProps) => {
    return (
        <div className="global-container-centered text-center mt8x">
            <h1 className="heading-1 mt4x">{title || `You aren't allowed to do that`}</h1>
            <p>{message || `Sorry, you are not allowed to do that based on your account privileges.`}</p>
        </div>
    );
};

export default ForbiddenPage;

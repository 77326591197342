import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Core from '../../core';

import './pointBreakdown.scss';

interface PointBreakdownProps {
    participant: Core.Models.GroupParticipant;
    stageSettings: Core.Models.StageSettings;
}

const PointBreakdown = (props: PointBreakdownProps) => {
    const {
        participant: {
            byes = 0,
            gamePointsRegulation = 0,
            gamePointsTiebreakers = 0,
            gameTies = 0,
            gameWins = 0,
            lossesByForfeit = 0,
            lossesByPlay = 0,
            ties = 0,
            winsByForfeit = 0,
            winsByPlay = 0,
        },
        stageSettings: {
            standingsPointsForBye = 0,
            standingsPointsForGameTie = 0,
            standingsPointsForGameWin = 0,
            standingsPointsForMatchLossByForfeit = 0,
            standingsPointsForMatchLossByPlay = 0,
            standingsPointsForMatchTie = 0,
            standingsPointsForMatchWinByForfeit = 0,
            standingsPointsForMatchWinByPlay = 0,
        },
    } = props;
    const [isShowingAll, setIsShowingAll] = useState<boolean>(false);

    const byePoints = useMemo(() => standingsPointsForBye * byes, [byes, standingsPointsForBye]);
    const gameTiePoints = useMemo(() => standingsPointsForGameTie * gameTies, [gameTies, standingsPointsForGameTie]);
    const gameWinPoints = useMemo(() => standingsPointsForGameWin * gameWins, [gameWins, standingsPointsForGameWin]);
    const matchLossesByForfeitPoints = useMemo(
        () => standingsPointsForMatchLossByForfeit * lossesByForfeit,
        [lossesByForfeit, standingsPointsForMatchLossByForfeit]
    );
    const matchLossesByPlayPoints = useMemo(
        () => standingsPointsForMatchLossByPlay * lossesByPlay,
        [lossesByPlay, standingsPointsForMatchLossByPlay]
    );
    const matchTiePoints = useMemo(() => standingsPointsForMatchTie * ties, [standingsPointsForMatchTie, ties]);
    const matchWinsByForfeitPoints = useMemo(
        () => standingsPointsForMatchWinByForfeit * winsByForfeit,
        [standingsPointsForMatchWinByForfeit, winsByForfeit]
    );
    const matchWinsByPlayPoints = useMemo(
        () => standingsPointsForMatchWinByPlay * winsByPlay,
        [standingsPointsForMatchWinByPlay, winsByPlay]
    );
    const totalPoints = useMemo(() => {
        return (
            byePoints +
            gameTiePoints +
            gameWinPoints +
            matchLossesByForfeitPoints +
            matchLossesByPlayPoints +
            matchTiePoints +
            matchWinsByForfeitPoints +
            matchWinsByPlayPoints
        );
    }, [
        byePoints,
        gameTiePoints,
        gameWinPoints,
        matchLossesByForfeitPoints,
        matchLossesByPlayPoints,
        matchTiePoints,
        matchWinsByForfeitPoints,
        matchWinsByPlayPoints,
    ]);
    return (
        <div className="point-breakdown">
            <div className="point-breakdown__header">Point Breakdown</div>
            <table className="point-breakdown__table">
                <thead>
                    <tr>
                        <th>
                            Category (
                            <button onClick={() => setIsShowingAll(!isShowingAll)}>
                                {isShowingAll ? 'hide' : 'show'} all
                            </button>
                            )
                        </th>
                        <th>#</th>
                        <th></th>
                        <th>per</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {(!!standingsPointsForMatchWinByForfeit || isShowingAll) && (
                        <tr>
                            <td>Match Wins by Forfeit</td>
                            <td>{winsByForfeit}</td>
                            <td>
                                <FontAwesomeIcon icon={['fas', 'times']} size="xs" />
                            </td>
                            <td>{standingsPointsForMatchWinByForfeit}</td>
                            <td>=</td>
                            <td>{matchWinsByForfeitPoints}</td>
                        </tr>
                    )}
                    {(!!standingsPointsForMatchWinByPlay || isShowingAll) && (
                        <tr>
                            <td>Match Wins by Play</td>
                            <td>{winsByPlay}</td>
                            <td>
                                <FontAwesomeIcon icon={['fas', 'times']} size="xs" />
                            </td>
                            <td>{standingsPointsForMatchWinByPlay}</td>
                            <td>=</td>
                            <td>{matchWinsByPlayPoints}</td>
                        </tr>
                    )}
                    {(!!standingsPointsForMatchLossByForfeit || isShowingAll) && (
                        <tr>
                            <td>Match Losses by Forfeit</td>
                            <td>{lossesByForfeit}</td>
                            <td>
                                <FontAwesomeIcon icon={['fas', 'times']} size="xs" />
                            </td>
                            <td>{standingsPointsForMatchLossByForfeit}</td>
                            <td>=</td>
                            <td>{matchLossesByForfeitPoints}</td>
                        </tr>
                    )}
                    {(!!standingsPointsForMatchLossByPlay || isShowingAll) && (
                        <tr>
                            <td>Match Losses by Play</td>
                            <td>{lossesByPlay}</td>
                            <td>
                                <FontAwesomeIcon icon={['fas', 'times']} size="xs" />
                            </td>
                            <td>{standingsPointsForMatchLossByPlay}</td>
                            <td>=</td>
                            <td>{matchLossesByPlayPoints}</td>
                        </tr>
                    )}
                    {(!!standingsPointsForMatchTie || isShowingAll) && (
                        <tr>
                            <td>Match Ties</td>
                            <td>{ties}</td>
                            <td>
                                <FontAwesomeIcon icon={['fas', 'times']} size="xs" />
                            </td>
                            <td>{standingsPointsForMatchTie}</td>
                            <td>=</td>
                            <td>{matchTiePoints}</td>
                        </tr>
                    )}
                    {(!!standingsPointsForGameWin || isShowingAll) && (
                        <tr>
                            <td>Game Wins</td>
                            <td>{gameWins}</td>
                            <td>
                                <FontAwesomeIcon icon={['fas', 'times']} size="xs" />
                            </td>
                            <td>{standingsPointsForGameWin}</td>
                            <td>=</td>
                            <td>{gameWinPoints}</td>
                        </tr>
                    )}
                    {(!!standingsPointsForGameTie || isShowingAll) && (
                        <tr>
                            <td>Game Ties</td>
                            <td>{gameTies}</td>
                            <td>
                                <FontAwesomeIcon icon={['fas', 'times']} size="xs" />
                            </td>
                            <td>{standingsPointsForGameTie}</td>
                            <td>=</td>
                            <td>{gameTiePoints}</td>
                        </tr>
                    )}
                    {(!!standingsPointsForBye || isShowingAll) && (
                        <tr>
                            <td>Byes</td>
                            <td>{byes}</td>
                            <td>
                                <FontAwesomeIcon icon={['fas', 'times']} size="xs" />
                            </td>
                            <td>{standingsPointsForBye}</td>
                            <td>=</td>
                            <td>{byePoints}</td>
                        </tr>
                    )}
                    {isShowingAll && (
                        <tr>
                            <td>Total Regulation Points</td>
                            <td>{gamePointsRegulation}</td>
                            <td>
                                <FontAwesomeIcon icon={['fas', 'times']} size="xs" />
                            </td>
                            <td>N/A</td>
                            <td>=</td>
                            <td>N/A</td>
                        </tr>
                    )}
                    {isShowingAll && (
                        <tr>
                            <td>Total Tiebreaker Points</td>
                            <td>{gamePointsTiebreakers}</td>
                            <td>
                                <FontAwesomeIcon icon={['fas', 'times']} size="xs" />
                            </td>
                            <td>N/A</td>
                            <td>=</td>
                            <td>N/A</td>
                        </tr>
                    )}
                    <tr>
                        <td>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>=</td>
                        <td>{totalPoints}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default PointBreakdown;

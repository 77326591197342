import React, { RefObject, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import ReactTwitchEmbedVideo from 'react-twitch-embed-video';

import * as Core from '../../core';

import './multiStream.scss';

interface TwitchMultiStreamProps {
    openTwitchSettings: () => void;
    streams: Core.Models.TwitchStream[];
}

const TwitchMultiStream = (props: TwitchMultiStreamProps): JSX.Element => {
    const { openTwitchSettings, streams } = props;

    const [activeStream, setActiveStream] = useState<Core.Models.TwitchStream | undefined>(undefined);
    const [liveStreams, setLiveStreams] = useState<Core.Models.TwitchStream[]>([]);

    const activeStreamComponent: RefObject<HTMLDivElement> = React.createRef();
    const setActiveAndFocus = (stream: Core.Models.TwitchStream): void => {
        setActiveStream(stream);

        // focus on stream
        if (activeStreamComponent.current && activeStreamComponent.current.clientWidth < 768) {
            // tablet
            activeStreamComponent.current.scrollIntoView();
        }
    };

    useEffect(() => {
        const data = streams
            .filter((s: Core.Models.TwitchStream) => s.live)
            .map((s: Core.Models.TwitchStream) => {
                if (!s.thumbnailUrl) return s;

                // Twitch URLs are returned in the format: https://static-cdn.jtvnw.net/previews-ttv/live_user_esl_csgo-{width}x{height}.jpg
                s.thumbnailUrl = s.thumbnailUrl.replace('{width}', '400');
                s.thumbnailUrl = s.thumbnailUrl.replace('{height}', '225'); // 16:9 aspect ratio (400 * (9/16) = 225)

                return s;
            });
        setLiveStreams(data);

        // initialize with first stream
        if (data.length > 0) setActiveStream(data[0]);
    }, [streams]);

    if (liveStreams.length <= 0) return <></>; // hide completely if there are no live streams

    return (
        <div className="twitch-multi-stream">
            <h4 className="twitch-multi-stream__header">
                <div>
                    <span className="live-indicator"></span>
                    <span>Live now</span>
                </div>
                <button onClick={openTwitchSettings}>
                    <FontAwesomeIcon icon={['fas', 'gear']} className="fa-icon" />
                </button>
            </h4>
            <div className="twitch-multi-stream__selector">
                {liveStreams.map((stream: Core.Models.TwitchStream, streamIx: number) => (
                    <div
                        className={classNames(
                            'twitch-multi-stream__selector__stream',
                            activeStream && stream.id === activeStream.id && 'active'
                        )}
                        key={streamIx}
                        onClick={() => setActiveAndFocus(stream)}
                    >
                        <img src={stream.thumbnailUrl} title={stream.title} alt={stream.title} />
                        <h5>{stream.username}</h5>
                    </div>
                ))}
            </div>
            {typeof activeStream !== 'undefined' && (
                <div className="twitch-multi-stream__active-stream" ref={activeStreamComponent}>
                    <ReactTwitchEmbedVideo
                        allowfullscreen
                        autoplay
                        channel={activeStream.channelName}
                        height="100%"
                        layout="video-with-chat"
                        muted
                        targetId="twitch-embed"
                        theme="dark"
                        width="100%"
                    />
                </div>
            )}
        </div>
    );
};

export default TwitchMultiStream;

import React from 'react';
import classNames from 'classnames';

import './Overlay.scss';

interface OverlayProps extends React.HTMLAttributes<HTMLDivElement> {
    isAnimating: boolean;
    isOpen: boolean;
}

const Overlay = ({ children, className, isAnimating, isOpen }: OverlayProps) => {
    const overlayClasses = classNames(className, 'ds-overlay', {
        'ds-overlay--animating': isAnimating,
        'ds-overlay--open': isOpen,
    });

    return <div className={overlayClasses}>{children}</div>;
};

export default Overlay;

import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import SubmitDecksModal from './submitDecksModal';
import { Button } from '../../../components/button';
import { useConfirmModal } from '../../../hooks/confirmModal';
import { useUserPermissionService } from '../../../hooks/store';
import { ParticipantService } from '../../../services/participantService';
import DeckImage from '../../match/hearthstone/deckImage';

import './index.scss';

interface ParticipantDecksProps {
    canEditSeason: boolean;
    participant: Core.Models.Participant;
    reloadSeason: () => Promise<void>;
    season: Core.Models.Season;
}

export const ParticipantDecks = (props: ParticipantDecksProps): JSX.Element => {
    const { canEditSeason, participant, reloadSeason, season } = props;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [participantDeletingDecks, setParticipantDeletingDecks] = useState<string | undefined>(undefined);
    const userPermissionService = useUserPermissionService();

    const canSubmitDecks = useMemo(() => {
        if (canEditSeason && season.currentState <= Core.Models.CompetitionState.InProgress) return true;
        return (
            userPermissionService.hasTeamAccess(Core.Models.PermissionLevel.Edit, participant.teamId) &&
            season.currentState <= Core.Models.CompetitionState.NotStarted
        );
    }, [canEditSeason, participant.teamId, season.currentState, userPermissionService]);

    const canDeleteDecks = useMemo(
        () => canEditSeason && season.currentState <= Core.Models.CompetitionState.NotStarted,
        [canEditSeason, season.currentState]
    );

    const [deleteParticipantDecksNode, openDeleteParticipantDecks, closeDeleteParticipantDecks] = useConfirmModal(
        () => `Delete decks for ${participantDeletingDecks}?`,
        () => (
            <p>
                Are you sure you want to delete the submitted decks for <strong>{participantDeletingDecks}</strong>?
            </p>
        ),
        async () => {
            await ParticipantService.deleteHearthstoneDecks(participant.id);
            await reloadSeason();
            toast.success(`Successfully removed decks for ${participantDeletingDecks}`);
            setParticipantDeletingDecks(undefined);
            closeDeleteParticipantDecks();
        }
    );

    return (
        <>
            {!!participant.hearthstoneDecks?.some((deck) => !!deck) ? (
                <div className="stage-participant-decks">
                    {participant.hearthstoneDecks.map((deck: Core.Models.HearthstoneDeck) => (
                        <a
                            key={deck.code}
                            className="stage-participant-decks__link"
                            href={`${Core.Constants.HEARTHSTONE_DECK_BASE_URL}deckstring=${encodeURIComponent(
                                deck.code
                            )}`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <DeckImage name={deck.name} />
                        </a>
                    ))}
                    <a
                        className="stage-participant-decks__view-all link-button link-button--primary"
                        href={`${Core.Constants.HEARTHSTONE_DECK_BASE_URL}${participant.hearthstoneDecks
                            .map((deck: Core.Models.HearthstoneDeck) => `deckstring=${encodeURIComponent(deck.code)}`)
                            .join('&')}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        title={`View All Decks for ${participant.name}`}
                    >
                        <FontAwesomeIcon icon={['fas', 'eye']} />
                    </a>
                    {canDeleteDecks && (
                        <Button
                            className="stage-participant-decks__delete-decks"
                            onClick={() => {
                                setParticipantDeletingDecks(participant.name);
                                openDeleteParticipantDecks();
                            }}
                            styleType={Core.Models.StyleType.Secondary}
                            title={`Delete Decks for ${participant.name}`}
                        >
                            <FontAwesomeIcon icon={['fas', 'trash']} />
                        </Button>
                    )}
                </div>
            ) : canSubmitDecks ? (
                <Button
                    className="stage-participant-decks__submit-decks"
                    onClick={() => setIsSubmitting(true)}
                    semiRound
                    styleType={Core.Models.StyleType.Primary}
                    title={`Submit Decks for ${participant.name}`}
                >
                    <FontAwesomeIcon icon={['fas', 'plus']} className="hide-for-medium" />
                    <span>Submit Decks</span>
                </Button>
            ) : (
                <div className="stage-participant-decks__not-submitted">
                    {season.currentState <= Core.Models.CompetitionState.NotStarted
                        ? 'None to display yet'
                        : 'Not submitted'}
                </div>
            )}
            {isSubmitting && (
                <SubmitDecksModal
                    onClose={() => setIsSubmitting(false)}
                    participant={participant}
                    reloadSeason={reloadSeason}
                />
            )}
            {deleteParticipantDecksNode}
        </>
    );
};

export default ParticipantDecks;

import * as ReduxThunk from 'redux-thunk';

import * as Core from '../../core';
import { PERMISSIONS_LOADING, PERMISSIONS_SUCCESS, PERMISSIONS_FAILURE } from './types';
import { AppAction, AppState } from '..';
import { UserService } from '../../services/userService';

// action creators
export const loadPermissionsAction = () => {
    return {
        type: PERMISSIONS_LOADING,
    };
};

export const permissionsSuccessAction = (permissions: Core.Models.AssignedRoles) => {
    return {
        type: PERMISSIONS_SUCCESS,
        payload: permissions,
    };
};

export const permissionsFailureAction = (error: Core.Models.AppError) => {
    return {
        type: PERMISSIONS_FAILURE,
        payload: error,
        error: true,
    };
};

// thunk actions
export const getPermissions =
    (): ReduxThunk.ThunkAction<Promise<void>, AppState, null, AppAction> => async (dispatch, getState) => {
        const { startupState } = getState();

        dispatch(loadPermissionsAction());

        try {
            const roles = startupState.isPlatform
                ? await UserService.getMyPlatformRoles()
                : await UserService.getMyRoles();
            dispatch(permissionsSuccessAction(roles));
        } catch (error) {
            dispatch(permissionsFailureAction(error));
        }
    };

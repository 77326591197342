import * as React from 'react';
import classNames from 'classnames';
import * as _ from 'lodash';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

import './index.scss';

interface DraggableListProps<T> {
    className?: string;
    disabled?: boolean;
    items: T[];
    setItems: (items: T[]) => void;
    getId: (item: T) => string;
    renderItem: (item: T, ix: number) => React.ReactNode;
    renderBeforeItem?: (item: T, ix: number) => React.ReactNode;
    renderAfterItem?: (item: T, ix: number) => React.ReactNode;
}

// simple wrapper around react-beautiful-dnd to handle the simplest case - reordering a simple list
function DraggableList<T>(props: React.PropsWithChildren<DraggableListProps<T>>) {
    const [uniqueId] = React.useState(_.uniqueId());
    const { items, setItems, renderItem, renderBeforeItem, renderAfterItem, getId } = props;

    const reorder = React.useCallback(
        (sourceIx: number, targetIx: number) => {
            const result = [...items];
            const [removed] = result.splice(sourceIx, 1);
            result.splice(targetIx, 0, removed);

            setItems(result);
        },
        [items, setItems]
    );

    const onDragEnd = React.useCallback(
        (result: DropResult) => {
            // dropped outside the list
            if (!result.destination) {
                return;
            }

            reorder(result.source.index, result.destination.index);
        },
        [reorder]
    );

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={uniqueId}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={classNames('draggable-list__droppable', props.className, {
                            'draggable-list__droppable--is-dragging-over': snapshot.isDraggingOver,
                            'draggable-list__droppable--is-dragging': snapshot.draggingFromThisWith,
                        })}
                    >
                        {items.map((i, ix) => (
                            <React.Fragment key={ix}>
                                {renderBeforeItem && renderBeforeItem(i, ix)}
                                <Draggable
                                    key={getId(i)}
                                    draggableId={getId(i)}
                                    index={ix}
                                    isDragDisabled={props.disabled}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={provided.draggableProps.style}
                                            className={classNames('draggable-list__draggable', {
                                                'draggable-list__draggable--is-dragging': snapshot.isDragging,
                                            })}
                                        >
                                            {renderItem(i, ix)}
                                        </div>
                                    )}
                                </Draggable>
                                {renderAfterItem && renderAfterItem(i, ix)}
                            </React.Fragment>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default DraggableList;

import { ColorOption } from './types';
import { genericThemeColors } from '../../../../components/themeContainer';

export const NEW_DESIGNATION_ID = 'new_designation_id';

export enum DesignationEditorMode {
    New = 0,
    Display = 1,
    Edit = 2,
    Delete = 3,
}

export const colorOptions: ColorOption[] = [
    // pulling from genericThemeColors for testing
    // TODO these need to pull from league.colors
    { name: 'primary', hex: genericThemeColors.primaryColor },
    { name: 'secondary', hex: genericThemeColors.secondaryColor },
    { name: 'accent1', hex: genericThemeColors.accent1 },
    { name: 'accent2', hex: genericThemeColors.accent2 },
];

export const defaultDesignations = ['Club', 'Junior Varsity', 'Varsity'];

export const iconOptions = [
    'chess-king',
    'chess-knight',
    'chess-queen',
    'chess-rook',
    'face-grin-stars',
    'fire-flame-curved',
    'fire',
    'jedi',
    'medal',
    'meteor',
    'pepper-hot',
    'robot',
    'snowflake',
    'star',
];

import * as React from 'react';
import { createPortal } from 'react-dom';

import Modal, { BaseModalProps } from '../components/overlays/modal/Modal';

// this is intended to make it easier to use a <Modal> from a functional component.
// It will track whether the modal is open and provides the element to render and functions to call to open/close it from the containing component.
// The first argument returned is the `React.ReactNode` to render, followed by the functions to call to open and close the modal.
export function useModal(
    title: () => React.ReactNode,
    modalContent: (closeModal: () => void) => React.ReactNode,
    modalProperties?: Partial<BaseModalProps>
): [React.ReactNode, () => void, () => void] {
    const [isShowing, setIsShowing] = React.useState(false);
    const close = React.useCallback(() => setIsShowing(false), [setIsShowing]);
    const open = React.useCallback(() => setIsShowing(true), [setIsShowing]);
    return [
        isShowing ? (
            createPortal(
                <Modal isOpen={isShowing} onClose={close} title={title()} {...modalProperties}>
                    {modalContent(close)}
                </Modal>,
                document.body
            )
        ) : (
            <></>
        ),
        open,
        close,
    ];
}

import { Location } from 'history';

export abstract class LoginRedirectService {
    public static buildLoginUrl(inputLocation?: Location): string {
        const location = inputLocation || window.location;
        const target = location.pathname + location.search + location.hash;
        if (!target || target === '/dashboard' || target === '/home') {
            return '/login';
        }
        return `/login/${encodeURIComponent(target)}`;
    }
}

import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { isUndefined, orderBy } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import { BaseButton, IconButton } from '../../components/buttons-visuals';
import CompetitionMatchup from '../../components/competitionMatchup';
import { useCreateLocationStationModal } from '../../components/modifyLocationStationModal';
import { useConfirmModal } from '../../hooks/confirmModal';
import { useCanEditSeason } from '../../hooks/store';
import { MatchParticipantService } from '../../services/matchParticipantService';

import './match.scss';

interface MatchProps {
    isMyParticipant?: (participant: { teamId?: string }) => boolean;
    locations: Core.Models.LeagueLocation[];
    match: Core.Models.Match;
    matchWord: string;
    myMatch?: boolean;
    onDelete?: (matchId: string) => Promise<void>;
    stage: Core.Models.Stage;
    streamStatus?: Core.Models.StreamStatus;
    tennisStyle: boolean;
    timezone: string;
}

const Match = ({
    isMyParticipant,
    locations,
    match,
    matchWord,
    myMatch,
    onDelete,
    stage,
    streamStatus,
    tennisStyle,
    timezone,
}: MatchProps): JSX.Element => {
    const [matchParticipants, setMatchParticipants] = useState<Core.Models.MatchParticipant[]>([]);

    useEffect(
        () =>
            setMatchParticipants(orderBy(match.matchParticipants, (mp: Core.Models.MatchParticipant) => mp.sortOrder)),
        [match.matchParticipants]
    );

    const time = moment.tz(match.startTimeUtc, timezone);
    const forfeitures = matchParticipants.filter(
        (participant: Core.Models.MatchParticipant) => participant.hasForfeited
    ).length;
    const canEditSeason = useCanEditSeason(stage.seasonId);
    const deleteMatchStageTypeIds = [
        Core.Models.StageTypeId.AutoMatcher,
        Core.Models.StageTypeId.DoubleRoundRobin,
        Core.Models.StageTypeId.Leaderboard,
        Core.Models.StageTypeId.LeaderboardAutoMatcher,
        Core.Models.StageTypeId.RoundRobin,
        Core.Models.StageTypeId.Swiss,
    ];
    const canDeleteMatch =
        canEditSeason &&
        (match.currentState <= Core.Models.MatchState.RescheduleRequested || matchParticipants.length <= 1) &&
        stage.currentState <= Core.Models.StageState.InProgress &&
        deleteMatchStageTypeIds.filter((id) => id === stage.stageTypeId).length > 0 &&
        !!onDelete;

    const [deleteMatchNode, openDeleteMatch, closeDeleteMatch] = useConfirmModal(
        () => `Delete ${matchWord}`,
        () => (
            <p>
                Are you sure you want to delete this {matchWord.toLowerCase()}?{' '}
                <strong>You cannot undo this action.</strong>
            </p>
        ),
        async () => {
            if (!onDelete) return;

            await onDelete(match.id);
            closeDeleteMatch();
        }
    );

    const streamIsOnline = useMemo(() => {
        if (isUndefined(streamStatus)) return undefined;
        return streamStatus === Core.Models.StreamStatus.Online;
    }, [streamStatus]);

    const [activeMatchParticipantId, setActiveMatchParticipantId] = useState<string | undefined>(undefined);
    const [location, setLocation] = useState<Core.Models.LeagueLocation | undefined>(
        locations.find((l: Core.Models.LeagueLocation) => l.id === stage.settings.locationId)
    );
    const [createStationModal, showCreateStation] = useCreateLocationStationModal({
        locationId: location?.id ?? null,
        onSubmitted: async (s: Core.Models.LeagueLocationStation) => {
            setLocation(
                (previousLocation) =>
                    ({
                        ...previousLocation,
                        stations: orderBy(
                            [...(previousLocation?.stations ?? []), s],
                            (s: Core.Models.LeagueLocationStation) => s.name
                        ),
                    }) as Core.Models.LeagueLocation
            );
            setMatchParticipants((previousParticipants) =>
                previousParticipants.map((p: Core.Models.MatchParticipant) =>
                    activeMatchParticipantId === p.id
                        ? {
                              ...p,
                              stationId: s.id,
                          }
                        : p
                )
            );
            if (!!activeMatchParticipantId) {
                await MatchParticipantService.assignStation({
                    matchParticipantId: activeMatchParticipantId,
                    stationId: s.id,
                });
                const name =
                    matchParticipants.find((mp: Core.Models.MatchParticipant) => mp.id === activeMatchParticipantId)
                        ?.name ?? 'participant';
                toast.success(`Successfully updated ${name}'s station`);
            }
            setActiveMatchParticipantId(undefined);
        },
    });

    return (
        <div className="match-wrapper">
            <BaseButton
                as="link"
                className={classNames('match', {
                    'match--my-match': myMatch,
                    'match--head-to-head': match.competitionStyle === Core.Models.CompetitionStyle.HeadToHead,
                })}
                to={`/matches/${match.id}`}
            >
                <h4 className="match-title">{matchParticipants.map((ii) => ii.name).join(' vs. ')}</h4>
                <div
                    className={classNames('match-header', {
                        'match-header--head-to-head':
                            match.competitionStyle === Core.Models.CompetitionStyle.HeadToHead,
                    })}
                >
                    <div className="disp-flex align-center flex-gap">
                        <div
                            className={classNames('match-header__badge', {
                                'match-header__badge--not-started':
                                    match.currentState === Core.Models.MatchState.NotStarted,
                                'match-header__badge--in-progress':
                                    match.currentState === Core.Models.MatchState.InProgress,
                                'match-header__badge--completed':
                                    match.currentState === Core.Models.MatchState.IsComplete,
                                'match-header__badge--reschedule-requested':
                                    match.currentState === Core.Models.MatchState.RescheduleRequested,
                                'match-header__badge--under-review': match.isDisputed,
                                'match-header__badge--forfeit':
                                    Core.Models.MatchState.IsComplete &&
                                    0 < forfeitures &&
                                    forfeitures >= matchParticipants.length - 1,
                            })}
                        >
                            {match.isDisputed ? (
                                <>Under Review</>
                            ) : match.currentState === Core.Models.MatchState.NotStarted ? (
                                match.competitionStyle !== Core.Models.CompetitionStyle.Leaderboard && !tennisStyle ? (
                                    `Best of ${match.bestOf}`
                                ) : (
                                    'Not Started'
                                )
                            ) : match.currentState === Core.Models.MatchState.RescheduleRequested ? (
                                <>Reschedule Requested</>
                            ) : match.currentState === Core.Models.MatchState.InProgress ? (
                                <>In Progress</>
                            ) : match.currentState === Core.Models.MatchState.IsComplete ? (
                                0 < forfeitures && forfeitures >= matchParticipants.length - 1 ? (
                                    <>Forfeit</>
                                ) : (
                                    <>Completed</>
                                )
                            ) : null}
                        </div>
                        {time.isValid() && (
                            <div className="match-header__time">
                                {time.format(Core.Constants.MATCH_TIME_FORMAT)}
                                <span className="ml mr color-gray">|</span>
                                {time.format(Core.Constants.MATCH_DATE_FORMAT)}
                            </div>
                        )}
                    </div>
                    {!isUndefined(streamIsOnline) ? (
                        <div className="match-header__live">
                            {streamIsOnline ? 'Live' : 'Offline'}
                            <span
                                className={classNames('match-header__live-indicator', {
                                    'match-header__live-indicator--offline': !streamIsOnline,
                                })}
                            />
                        </div>
                    ) : (
                        <FontAwesomeIcon icon={['fas', 'chevron-right']} className="mr2x" />
                    )}
                </div>
                <CompetitionMatchup
                    {...{
                        className: 'match__competition-matchup',
                        enableHighlight: true,
                        isMyParticipant,
                        isPreview: match.competitionStyle === Core.Models.CompetitionStyle.Leaderboard,
                        location,
                        match: {
                            ...match,
                            season: {
                                id: stage.seasonId,
                            },
                        },
                        matchParticipants,
                        onStationSelect: (id: string) => {
                            setActiveMatchParticipantId(id);
                            showCreateStation();
                        },
                    }}
                />
            </BaseButton>
            {canDeleteMatch && (
                <div
                    className={classNames('season-match-delete', {
                        'season-match-delete--leaderboard':
                            match.competitionStyle === Core.Models.CompetitionStyle.Leaderboard,
                        'season-match-delete--live': streamIsOnline,
                    })}
                >
                    <IconButton as="button" buttonLabel="Delete" buttonSize="small" onClick={openDeleteMatch}>
                        <FontAwesomeIcon icon={['fas', 'trash']} />
                    </IconButton>
                    {deleteMatchNode}
                </div>
            )}
            {createStationModal}
        </div>
    );
};

export default Match;

import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import useClickOutside from '../../hooks/clickOutside';
import { IHasChildren } from '../../layouts/types';

import './index.scss';

export interface MenuProps extends IHasChildren {
    className?: string;
    orientation?: 'horizontal' | 'vertical';
}

const Menu = (props: MenuProps): JSX.Element => {
    const { children, className, orientation } = props;
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    useClickOutside(menuRef, () => {
        if (isOpen) {
            setIsOpen(false);
        }
    });

    return (
        <div className={classNames('menu', className, { 'menu--is-open': isOpen })} ref={menuRef}>
            <button
                className={classNames('menu__button', {
                    'menu__button--rotated': orientation && orientation === 'vertical',
                })}
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="menu__ellipsis">...</span>
            </button>
            {isOpen && (
                <div className="menu__dropdown">
                    {children &&
                        React.Children.map(children, (item, index) => {
                            return (
                                item && (
                                    <div key={index} onClick={() => setIsOpen(false)}>
                                        {item}
                                    </div>
                                )
                            );
                        })}
                </div>
            )}
        </div>
    );
};

export default Menu;

import React, { useMemo } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import Select, { OptionsType, SelectOption } from 'react-select';
import * as Yup from 'yup';

import * as Core from '../../core';
import { SolidButton } from '../../components/buttons-visuals';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';
import { FieldSet } from '../../components/inputs';

interface CreateMatchProps {
    game: Core.Models.Game;
    groupParticipants: Core.Models.GroupParticipant[];
    hideBestOf: boolean;
    initialValues: Partial<CreateMatchValues>;
    matchWord: string;
    onSubmit: (values: CreateMatchValues) => Promise<void>;
    round: Core.Models.Round;
    stage: Core.Models.Stage;
}

export interface CreateMatchValues {
    bestOf: number;
    groupParticipantIds: string[];
    startTimeUtc?: string;
}

const CreateMatch = ({
    game,
    groupParticipants,
    hideBestOf,
    initialValues,
    matchWord,
    onSubmit,
    round,
    stage,
}: CreateMatchProps) => {
    const maxNumParticipants = useMemo(
        () => (stage.competitionStyle === Core.Models.CompetitionStyle.HeadToHead ? 2 : game.maximumLobbySize),
        [game.maximumLobbySize, stage.competitionStyle]
    );
    const participants = useMemo(() => {
        if (stage.stageTypeId === Core.Models.StageTypeId.AutoMatcher && round.roundGroupParticipants) {
            const participantIds = round.roundGroupParticipants.map(
                (rgp: Core.Models.RoundGroupParticipant) => rgp.groupParticipant?.participantId
            );
            return groupParticipants.filter((gp: Core.Models.GroupParticipant) =>
                participantIds.includes(gp.participantId)
            );
        } else {
            return groupParticipants.filter((gp: Core.Models.GroupParticipant) => gp.isParticipating);
        }
    }, [groupParticipants, round, stage]);

    return (
        <Formik
            initialValues={Object.assign({
                /** anything not specified here won't show an error message after an attempted submit */
                bestOf: initialValues.bestOf || undefined,
                groupParticipantIds: [],
                startTimeUtc: initialValues.startTimeUtc || undefined,
            } as Partial<CreateMatchValues>)}
            validationSchema={Yup.object().shape({
                bestOf: Yup.number().required(),
                groupParticipantIds: Yup.array()
                    .of(Yup.string())
                    .min(1)
                    .test(
                        'max-length',
                        `Must include ${maxNumParticipants} teams or fewer`,
                        (groupParticipantIds: (string | undefined)[] | undefined) => {
                            return !maxNumParticipants || (groupParticipantIds || []).length <= maxNumParticipants;
                        }
                    )
                    .required(),
                startTimeUtc: Yup.string(),
            })}
            onSubmit={async (values, actions) => {
                actions.setStatus(undefined);
                try {
                    await onSubmit(values);
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(formProps: FormikProps<CreateMatchValues>) => {
                const { groupParticipantIds } = formProps.values;
                const options = participants
                    .filter((gp: Core.Models.GroupParticipant) => !groupParticipantIds!.includes(gp.id))
                    .map((gp: Core.Models.GroupParticipant) => ({ label: gp.name, value: gp.id }) as SelectOption);
                const participantLimitReached =
                    !!maxNumParticipants && !!groupParticipantIds && groupParticipantIds.length >= maxNumParticipants;
                return (
                    <Form className="form">
                        <fieldset className="form-group">
                            <Select
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isMulti
                                isOptionDisabled={() => participantLimitReached}
                                name="groupParticipantIds"
                                onChange={(selectedOptions: OptionsType<SelectOption>) => {
                                    formProps.setFieldValue(
                                        'groupParticipantIds',
                                        selectedOptions.map((option: SelectOption) => option.value)
                                    );
                                }}
                                options={options}
                                placeholder="Select teams"
                                styles={{
                                    control: (provided: any) => ({
                                        ...provided,
                                        border: 'none',
                                        borderBottom: 'solid 0.1rem #6b707c',
                                        borderRadius: '0.4rem 0.4rem 0 0',
                                    }),
                                    menu: (provided: any) => ({ ...provided, zIndex: 2 }),
                                }}
                            />
                            <FormField component="datetime" name="startTimeUtc" label="Start time" />
                            {!hideBestOf && (
                                <FormField type="number" name="bestOf" description={`Games per ${matchWord}`} />
                            )}
                        </fieldset>

                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                        <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                        <FieldSet>
                            <SolidButton
                                as="button"
                                layout="full"
                                onClick={formProps.submitForm}
                                pending={formProps.isSubmitting}
                            >
                                Create {matchWord}
                            </SolidButton>
                        </FieldSet>
                    </Form>
                );
            }}
        />
    );
};

export default CreateMatch;

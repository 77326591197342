import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FocusOn } from 'react-focus-on';

import { IconButton } from '../../../components/buttons-visuals';
import { Overlay, Portal } from '../../overlays';

import './Modal.scss';

export interface BaseModalProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
    title?: string | React.ReactNode;
    isOpen: boolean;
    id?: string;
    onAnimation?: (isAnimating: boolean) => void;
    onClose: () => void;
    scrollLock?: boolean;
    size?: 'small' | 'medium' | 'large';
}

const Modal = ({
    children,
    className,
    title,
    isOpen,
    onAnimation,
    onClose,
    scrollLock = false,
    size = 'small',
    ...rest
}: BaseModalProps) => {
    const [showModal, setShowModal] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        setIsAnimating(isOpen !== showModal);
        if (onAnimation) {
            onAnimation(true);
        }
        if (isOpen) {
            setShowModal(true);
        }
    }, [isOpen]);

    const handleAnimationEnd = useCallback(() => {
        setIsAnimating(false);
        if (onAnimation) {
            onAnimation(false);
        }
        if (!isOpen) {
            setShowModal(false);
        }
    }, [isOpen, onAnimation]);

    const modalClasses = classNames(className, 'ds-modal');

    const modalInnerClasses = useMemo(
        () =>
            classNames('ds-modal-inner', `ds-modal-inner--size-${size}`, {
                'ds-modal-inner--open': isOpen,
            }),
        [isOpen, size]
    );

    const modalHeaderClasses = useMemo(
        () => classNames('ds-modal-inner__header', title ? 'justify-between' : 'justify-end'),
        [title]
    );

    return (
        <Portal elementId="portal">
            {showModal && (
                <Overlay isAnimating={isAnimating} isOpen={isOpen}>
                    <div className={modalClasses} role="presentation" {...rest}>
                        <FocusOn onClickOutside={onClose} onEscapeKey={onClose} scrollLock={scrollLock}>
                            <div className={modalInnerClasses} onAnimationEnd={handleAnimationEnd}>
                                <div className={modalHeaderClasses}>
                                    {title && <h2 className="heading-2 mb0 mt mr2x">{title}</h2>}
                                    <IconButton as="button" buttonLabel="Close" buttonSize="large" onClick={onClose}>
                                        <FontAwesomeIcon icon="times" />
                                    </IconButton>
                                </div>
                                <div className="ds-modal-inner__content">{children}</div>
                            </div>
                        </FocusOn>
                    </div>
                </Overlay>
            )}
        </Portal>
    );
};

export default Modal;

import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { useLeague } from '../../../hooks/store';

import './avatar.scss';

export interface AvatarProps {
    alt?: string;
    captain?: boolean;
    chatDisabled?: boolean;
    className?: string;
    coach?: boolean;
    fallback?: 'league' | 'organization' | 'question' | 'team' | 'user';
    ineligible?: boolean;
    isUnder13?: boolean;
    manager?: boolean;
    size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
    src?: string;
    title?: string;
    unverified?: boolean;
}

const Avatar = ({
    alt = '',
    captain,
    chatDisabled,
    className,
    coach,
    fallback,
    ineligible,
    isUnder13,
    manager,
    size,
    src,
    title,
    unverified,
}: AvatarProps): JSX.Element => {
    const league = useLeague();
    const lsLogoSvg = (
        <svg width="47" height="64" viewBox="0 0 47 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 0H46.3204V44.6477L23.1616 64L0 44.6477V0ZM4.6296 4.62493V42.4879L23.1615 57.9719L41.6908 42.4881V4.6394L4.6296 4.62493ZM8.04202 8.35995H12.6716V38.307L17.2387 42.0931V31.3426H21.8684V51.9393L8.04202 40.4777V8.35995Z"
                fill="currentColor"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24.675 7.92517H38.4311V19.532H33.8051V12.5483H29.3012V22.9098H38.4908V40.4801L24.675 51.9418V31.353H29.3012V42.0955L33.8647 38.3093V27.5329H24.675V7.92517Z"
                fill="currentColor"
            />
        </svg>
    );
    const imageElement = useMemo(() => {
        switch (fallback) {
            case 'organization':
                return src ? <img src={src} alt={alt} /> : <FontAwesomeIcon icon={['fas', 'building']} />;
            case 'question':
                return src ? <img src={src} alt={alt} /> : <FontAwesomeIcon icon={['fas', 'question']} />;
            case 'team':
                return src ? <img src={src} alt={alt} /> : <FontAwesomeIcon icon={['fas', 'user-group']} />;
            case 'league':
                return src ? <img src={src} alt={alt} /> : lsLogoSvg;
            case 'user':
                return league?.disablePfp || !src ? (
                    <FontAwesomeIcon icon={['fas', 'user']} />
                ) : (
                    <img src={src} alt={alt} />
                );
            default:
                return <img src={src || ''} alt={alt} />;
        }
    }, [fallback, league, src]);

    const isCaptain = !!captain && !ineligible;
    const isCoach = !!coach && !ineligible;

    const showTitle = (isCoach || isCaptain) && size !== 'xsmall';

    const decorationIcon = useMemo(() => {
        if (manager) return 'gear';
        if (isCoach) return 'bullhorn';
        if (isCaptain) return 'anchor';
        if (chatDisabled) return 'volume-xmark';
        if (ineligible) return 'xmark';
        if (unverified) return 'lock';
        return undefined;
    }, [chatDisabled, ineligible, isCaptain, isCoach, manager, unverified]);

    const decorationBackgroundClass = useMemo(() => {
        if (manager) return 'background-secondary';
        if (chatDisabled || ineligible || unverified) return 'background-error';

        return 'background-text-primary';
    }, [chatDisabled, ineligible, manager, unverified]);

    return (
        <div className={classNames(className, 'ds-avatar', { mb2x: showTitle })} title={title}>
            <div
                className={classNames(
                    'ds-avatar__image',
                    `ds-avatar__image--${size}`,
                    `ds-avatar__image--${fallback}`,
                    {
                        'ds-avatar__image--captain': isCaptain,
                        'ds-avatar__image--chatDisabled': chatDisabled,
                        'ds-avatar__image--coach': isCoach,
                        'ds-avatar__image--ineligible': ineligible,
                        'ds-avatar__image--manager': manager,
                        'ds-avatar__image--unverified': unverified,
                        'ds-avatar__image--under-13': isUnder13,
                        'ds-avatar__image--organization': fallback === 'organization',
                        'ds-avatar__image--has-image': src,
                    }
                )}
            >
                {imageElement}
            </div>
            {!src && fallback === 'organization' && <div className="ds-avatar__organization-border" />}
            {!src && fallback === 'league' && <div className="ds-avatar__league-border" />}
            {fallback === 'user' && !!decorationIcon && (
                <div
                    className={classNames('ds-avatar__decoration', decorationBackgroundClass, {
                        'ds-avatar-decoration--has-title': showTitle,
                    })}
                >
                    <FontAwesomeIcon
                        className={classNames(
                            'ds-avatar__decoration__icon',
                            isCaptain || isCoach ? 'color-secondary' : 'color-white'
                        )}
                        icon={['fas', `${decorationIcon}`]}
                    />
                </div>
            )}
            {isCaptain && showTitle && <p className="ds-avatar__role">Captain</p>}
            {isCoach && showTitle && <p className="ds-avatar__role">Coach</p>}
        </div>
    );
};

export default Avatar;

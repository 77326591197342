import React, { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import * as Core from './core';
import logoText from './assets/logoText';
import { Authenticator } from './components/authenicator';
import CustomBrowserRouter from './components/customBrowserRouter';
import { HeaderTags } from './components/headerTags';
import ScrollToTopOnNavigate from './components/scrollToTopOnNavigate';
import ThemeContainer from './components/themeContainer';
import { ChatProvider } from './contexts/chatContext';
import { IHasChildren } from './layouts/types';
import { store } from './store';
import StyleLoader from './styles/custom/styleLoader';

import 'react-toastify/dist/ReactToastify.css';

interface AppContainerProps extends IHasChildren {}

const AppContainer = ({ children }: AppContainerProps): JSX.Element => {
    useEffect(() => {
        console.log(logoText, `font-family: monospace`);
        console.log(
            `Welcome to ${Core.Constants.Company}!\nVersion: ${Core.Configuration.version}\nYour connection is secure 🔒`
        );
    }, []);

    const stripe = loadStripe(Core.Configuration.stripePublishableKey);

    return (
        <Provider store={store}>
            <HeaderTags>
                <ChatProvider>
                    <ThemeContainer>
                        <Elements {...{ stripe }}>
                            <CustomBrowserRouter>
                                <Authenticator />
                                <ScrollToTopOnNavigate />
                                {children}
                                <ToastContainer />
                                <StyleLoader />
                            </CustomBrowserRouter>
                        </Elements>
                    </ThemeContainer>
                </ChatProvider>
            </HeaderTags>
        </Provider>
    );
};

export default AppContainer;

import * as React from 'react';
import classNames from 'classnames';
import { chain } from 'lodash';
import { Link } from 'react-router-dom';

import * as Core from '../../core';
import ParticipantDecks from './hearthstone/participantDecks';
import { useAlternateSeasonName, useCanEditSeason, useOrganizationTerm } from '../../hooks/store';

import './stageParticipants.scss';

interface StageParticipantsProps {
    reloadSeason: () => Promise<void>;
    season: Core.Models.Season;
    stage: Core.Models.Stage;
}

const StageParticipants: React.FunctionComponent<StageParticipantsProps> = (props) => {
    const { reloadSeason, season, stage } = props;

    const stageHasStarted = stage.currentState > Core.Models.StageState.NotStarted;
    const canEditSeason = useCanEditSeason(season.id);
    const seasonAlternateName = useAlternateSeasonName();
    const organizationTerm = useOrganizationTerm();

    const empty = (
        <div className="stage-participants__items__empty">
            Stage participants have not been locked in yet. Please check back soon.
        </div>
    );
    const tableHeader = (
        <div
            className={classNames('stage-participants__items__header', {
                'stage-participants__items__header--has-metadata':
                    season.game.featureSet === Core.Models.FeatureSet.Hearthstone,
            })}
        >
            <div>{organizationTerm}</div>
            <div>Team</div>
            {season.game.featureSet === Core.Models.FeatureSet.Hearthstone && <div>Decks</div>}
        </div>
    );

    // render participants and source description based on source
    const renderParticipantItems = (): JSX.Element => {
        switch (stage.settings.inputParticipantSource) {
            case Core.Models.InputParticipantSourceType.AllSeasonParticipants:
                const filteredPartipants =
                    stage.settings.requireStageCheckIn && stage.currentState > Core.Models.StageState.CheckingIn
                        ? stage.participants.filter((p: Core.Models.StageParticipant) => p.isParticipating)
                        : season.participants.filter((p: Core.Models.Participant) => p.isParticipating);
                return (
                    <>
                        {canEditSeason && (
                            <div className="stage-participants__description">
                                <p>
                                    The <strong>{stage.name}</strong> stage includes all <strong>{season.name}</strong>{' '}
                                    participants{stage.settings.requireStageCheckIn && ' that check in'}.
                                    {!stageHasStarted && (
                                        <>
                                            {' '}
                                            Start this stage to automatically pull in all <strong>
                                                {season.name}
                                            </strong>{' '}
                                            participants.
                                        </>
                                    )}
                                </p>
                                {filteredPartipants.length <= 0 ? (
                                    <p>
                                        You haven't added teams to your {seasonAlternateName.toLowerCase()} yet! Please{' '}
                                        <Link to={`/seasons/${season.id}/editParticipants`}>
                                            add teams to your {seasonAlternateName.toLowerCase()}
                                        </Link>{' '}
                                        to begin.
                                    </p>
                                ) : (
                                    <p>
                                        There are {filteredPartipants.length} teams participating in the{' '}
                                        <strong>{stage.name}</strong> stage.
                                    </p>
                                )}
                            </div>
                        )}
                        {filteredPartipants.length > 0 ? (
                            <div className="stage-participants__items">
                                {tableHeader}
                                {chain(filteredPartipants)
                                    .filter((p) => p.isParticipating)
                                    .sortBy((p) => p.name)
                                    .map((participant: Core.Models.Participant) => (
                                        <div
                                            key={participant.id}
                                            className={classNames('stage-participants__items__item', {
                                                'stage-participants__items__item--has-metadata':
                                                    season.game.featureSet === Core.Models.FeatureSet.Hearthstone,
                                            })}
                                        >
                                            <div>{participant.organizationName}</div>
                                            <div>
                                                <Link to={`/teams/${participant.teamId}`}>
                                                    {participant.name || <em>Deleted team</em>}
                                                </Link>
                                            </div>
                                            {season.game.featureSet === Core.Models.FeatureSet.Hearthstone && (
                                                <ParticipantDecks
                                                    canEditSeason={canEditSeason}
                                                    participant={participant}
                                                    reloadSeason={reloadSeason}
                                                    season={season}
                                                />
                                            )}
                                        </div>
                                    ))
                                    .value()}
                            </div>
                        ) : (
                            empty
                        )}
                    </>
                );
            case Core.Models.InputParticipantSourceType.FromPreviousStage:
                const previousStage = season.stages.find((s) => s.id === stage.settings.previousStageInputSourceId)!;

                return (
                    <>
                        {canEditSeason && (
                            <div className="stage-participants__description">
                                <p>
                                    The <strong>{stage.name}</strong> stage includes{' '}
                                    {stage.settings.numberOfInputParticipants
                                        ? `the top ${stage.settings.numberOfInputParticipants}`
                                        : 'all'}{' '}
                                    participants from the <strong>{previousStage.name}</strong> stage
                                    {stage.settings.requireStageCheckIn && ' that check in'}.
                                    {!stageHasStarted && (
                                        <>
                                            {' '}
                                            Start this stage to automatically pull in participants from the{' '}
                                            <strong>{previousStage.name}</strong> stage.
                                        </>
                                    )}
                                </p>
                            </div>
                        )}
                        {stage.participants.length > 0 ? (
                            <div className="stage-participants__items">
                                {tableHeader}
                                {stage.participants.map((sp: Core.Models.StageParticipant) => {
                                    const participant = season.participants.find(
                                        (p: Core.Models.Participant) => p.id === sp.participantId
                                    );
                                    return (
                                        <div
                                            key={sp.id}
                                            className={classNames('stage-participants__items__item', {
                                                'stage-participants__items__item--has-metadata':
                                                    season.game.featureSet === Core.Models.FeatureSet.Hearthstone,
                                            })}
                                        >
                                            <div>{sp.organizationName}</div>
                                            <div>
                                                <Link to={`/teams/${sp.teamId}`}>
                                                    {sp.name || <em>Deleted team</em>}
                                                </Link>
                                            </div>
                                            {season.game.featureSet === Core.Models.FeatureSet.Hearthstone &&
                                                !!participant && (
                                                    <ParticipantDecks
                                                        canEditSeason={canEditSeason}
                                                        participant={participant}
                                                        reloadSeason={reloadSeason}
                                                        season={season}
                                                    />
                                                )}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            empty
                        )}
                    </>
                );
            case Core.Models.InputParticipantSourceType.ManualList:
                return (
                    <>
                        {canEditSeason && (
                            <div className="stage-participants__description">
                                <p>
                                    The <strong>{stage.name}</strong> stage includes manually selected participants from{' '}
                                    <strong>{season.name}</strong>
                                    {stage.settings.requireStageCheckIn && ' that check in'}.
                                    {!stageHasStarted && <> Start this stage to lock in selected participants.</>}
                                </p>
                                {stage.participants.length <= 0 && (
                                    <p>
                                        You haven't added teams to this stage yet! Please{' '}
                                        <Link to={`/stages/${stage.id}/participants`}>
                                            add participants to this stage
                                        </Link>{' '}
                                        to begin.
                                    </p>
                                )}
                            </div>
                        )}
                        {stage.participants.length > 0 ? (
                            <div className="stage-participants__items">
                                {tableHeader}
                                {stage.participants.map((sp: Core.Models.StageParticipant) => {
                                    const participant = season.participants.find(
                                        (p: Core.Models.Participant) => p.id === sp.participantId
                                    );
                                    return (
                                        <div
                                            key={sp.id}
                                            className={classNames('stage-participants__items__item', {
                                                'stage-participants__items__item--has-metadata':
                                                    season.game.featureSet === Core.Models.FeatureSet.Hearthstone,
                                            })}
                                        >
                                            <div>{sp.organizationName}</div>
                                            <div>
                                                <Link to={`/teams/${sp.teamId}`}>
                                                    {sp.name || <em>Deleted team</em>}
                                                </Link>
                                            </div>
                                            {season.game.featureSet === Core.Models.FeatureSet.Hearthstone &&
                                                !!participant && (
                                                    <ParticipantDecks
                                                        canEditSeason={canEditSeason}
                                                        participant={participant}
                                                        reloadSeason={reloadSeason}
                                                        season={season}
                                                    />
                                                )}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            empty
                        )}
                    </>
                );
            default:
                return <></>;
        }
    };

    return (
        <div className="stage-participants">
            <div className="stage-participants__header">
                <h3>Participants</h3>
            </div>
            {renderParticipantItems()}
        </div>
    );
};

export default StageParticipants;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../core';
import { Avatar, HollowButton, SolidButton, TextLink } from '../../components/buttons-visuals';
import { IconButton } from '../../components/buttons-visuals';
import ChangeDesignationModal, {
    EditDesignationFormValues,
    EditDesignationsFormActions,
} from '../../components/changeDesignationModal';
import FormField from '../../components/formField';
import GameIcon from '../../components/gameIcon';
import ImageUploader from '../../components/imageUploader';
import InfoMessage from '../../components/infoMessage';
import { CopyTextField, ToggleSwitch } from '../../components/inputs';
import Menu from '../../components/menu';
import SetTeamSeason from '../../components/setTeamSeason';
import TeamDesignation from '../../components/teamDesignation';
import { useAlternateSeasonName, useLeagueConfiguration, useUserPermissionService } from '../../hooks/store';
import { TeamService } from '../../services/teamService';
import { loadProfile } from '../../store/profile/actions';
import { Calendar } from '../calendar';

import './index.scss';

interface TeamDetailsProps {
    team: Core.Models.Team;
    reloadData: (showLoading: boolean) => Promise<void>;
}

interface EditTeamValues {
    name: string;
}

const schema = Yup.object().shape({
    name: Yup.string()
        .required('Team name is required.')
        .max(
            Core.Constants.NAME_MAX_LENGTH,
            `Team name is too long. (${Core.Constants.NAME_MAX_LENGTH} character maximum)`
        ),
});

const TeamDetails = (props: TeamDetailsProps) => {
    const { team: inputTeam, reloadData } = props;

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isSettingSeason, setIsSettingSeason] = useState<boolean>(false);
    const [team, setTeam] = useState<Core.Models.Team>(inputTeam);
    const [teamBeingDesignated, setTeamBeingDesignated] = useState<Core.Models.Team | undefined>(undefined);

    const userPermissionService = useUserPermissionService();
    const canEdit = userPermissionService.hasTeamAccess(Core.Models.PermissionLevel.Edit, team.id);
    const dispatch = useDispatch();
    const leagueConfiguration = useLeagueConfiguration();
    const seasonAlternateName = useAlternateSeasonName();

    // have to do this based on how we're capturing `inputTeam` into `team`
    useEffect(() => setTeam(inputTeam), [inputTeam]);

    const uploadImage = async (imageData: string) => {
        await TeamService.uploadLogo({ id: team.id, imageData });
        await reloadData(false);
    };

    const updateFreeAgentPreferences = useCallback(async () => {
        try {
            await TeamService.setOpenToFreeAgents({ openToFreeAgents: !team.openToFreeAgents, teamId: team.id });
            toast.success('Successfully updated free agent preferences');
        } catch (e) {
            toast.error('Unable to update free agent preferences.  Please try again.');
            throw e; // handled in ToggleSwitch
        }
    }, [team]);

    const updateJoinCodePreferences = useCallback(async () => {
        try {
            await TeamService.setJoinCode({ shouldHaveJoinCode: !team.joinCode, teamId: team.id });
            await props.reloadData(false);
            toast.success('Successfully updated join code preferences');
        } catch (e) {
            toast.error('Unable to update join code preferences.  Please try again.');
            throw e; // handled in ToggleSwitch
        }
    }, [props, team.id, team.joinCode]);

    const saveChanges = useCallback(
        async (values: EditTeamValues, actions: FormikActions<EditTeamValues>) => {
            try {
                await TeamService.editTeam({
                    id: team.id,
                    name: values.name,
                    designationId: team.designationId || null,
                });
                setIsEditing(false);
                await props.reloadData(false);
                dispatch(loadProfile());
            } catch (error) {
                const message = Core.API.getErrorMessage(error);
                actions.setStatus(message);
            }
            actions.setSubmitting(false);
        },
        [dispatch, props, team.id, team.designationId]
    );

    const seasonStatus = useMemo(
        () =>
            !!team.currentSeason?.isParticipating
                ? team.currentSeason.season.currentState === Core.Models.CompetitionState.NotStarted
                    ? 'Not started'
                    : team.currentSeason.season.currentState === Core.Models.CompetitionState.InProgress
                    ? 'Competing'
                    : team.currentSeason.season.currentState === Core.Models.CompetitionState.IsComplete
                    ? 'Competition complete'
                    : 'unknown'
                : 'Not competing',
        [team.currentSeason]
    );

    const canEditTeam = useMemo(
        () =>
            userPermissionService.hasTeamAccess(Core.Models.PermissionLevel.Edit, team.organizationId),
        [team, userPermissionService]
    );

    const hasOutstandingPayables = useMemo(
        () =>
            team.members.some(
                (member: Core.Models.TeamMember) =>
                    member.roleId !== Core.Models.TeamRoleId.Coach && member.hasOutstandingPayables
            ),
        [team.members]
    );

    return (
        <div className="team-profile-details">
            <div className="team-profile-details__header">
                <div className="team-profile-details__header__info">
                    <div
                        className={classNames('team-profile-details__header__info__avatar', {
                            'team-profile-details__header__info__avatar--editable': canEdit,
                        })}
                    >
                        <Avatar fallback="team" size="xlarge" src={team.logoUrl || team.organizationLogoUrl} />
                        {canEdit && (
                            <ImageUploader
                                aspectRatio={16 / 16}
                                contentType="image/jpeg"
                                defaultBackgroundColor="#FFFFFF"
                                icon="pen-to-square"
                                text="Update Image"
                                maxImageWidth={Core.Constants.IMAGES.MAX_AVATAR_IMAGE_WIDTH}
                                uploadImage={uploadImage}
                                flush
                            />
                        )}
                    </div>
                    <div>
                        {!!inputTeam.designation && (
                            <TeamDesignation
                                designation={inputTeam.designation}
                                {...(canEditTeam && {
                                    menu: (
                                        <Menu className="team-details__menu">
                                            <button onClick={() => setTeamBeingDesignated(team)}>
                                                Change Designation
                                            </button>
                                        </Menu>
                                    ),
                                })}
                            />
                        )}
                        {!isEditing ? (
                            <>
                                <h1 className="heading-1 mb0">{team.name}</h1>
                                {!!team.organization && (
                                    <p className="weight-700 mb color-gray">
                                        <Link to={`/organizations/${team.organization.id}`} className="page__text">
                                            {team.organization.name}
                                        </Link>
                                    </p>
                                )}
                                {canEdit && (
                                    <IconButton
                                        as="button"
                                        className="mr"
                                        buttonLabel="Edit team details"
                                        buttonSize="medium"
                                        onClick={() => setIsEditing(true)}
                                    >
                                        <FontAwesomeIcon icon={['fas', 'pen']} />
                                    </IconButton>
                                )}
                                <Calendar entity={team} entityType="team" />
                            </>
                        ) : (
                            <Formik
                                initialValues={{ name: team.name }}
                                onSubmit={saveChanges}
                                validationSchema={schema}
                                render={(formProps: FormikProps<EditTeamValues>) => (
                                    <Form>
                                        <FormField
                                            className="organization-page__organization-name"
                                            description="Team Name"
                                            name="name"
                                            type="text"
                                            value={team.name}
                                        />
                                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                                        <InfoMessage
                                            filter={formProps.touched}
                                            message={formProps.errors}
                                            type="error"
                                        />
                                        <HollowButton
                                            as="button"
                                            color="secondary"
                                            disabled={formProps.isSubmitting}
                                            onClick={() => setIsEditing(false)}
                                        >
                                            Cancel
                                        </HollowButton>
                                        <SolidButton
                                            as="button"
                                            className="ml"
                                            onClick={formProps.submitForm}
                                            pending={formProps.isSubmitting}
                                        >
                                            Save
                                        </SolidButton>
                                    </Form>
                                )}
                            />
                        )}
                    </div>
                </div>
            </div>

            {!isEditing && !!team.game && (
                <>
                    <div className="team-profile-details__game">
                        <div className="team-profile-details__game__header">
                            {team.game.eventType !== Core.Models.EventType.Class && (
                                <div className="team-profile-details__game__header__format">
                                    {Core.Competition.renderCompetitionFormat(team.game)}
                                </div>
                            )}
                            <div className="team-profile-details__game__header__status">{seasonStatus}</div>
                        </div>
                        <div className="team-profile-details__game__info">
                            <div className="disp-flex align-center">
                                <GameIcon game={team.game} team />
                                <div>
                                    {team.currentSeason?.isParticipating && (
                                        <TextLink
                                            className="weight-700"
                                            to={`/seasons/${team.currentSeason.season.id}`}
                                        >
                                            {team.currentSeason.season.name}
                                        </TextLink>
                                    )}
                                    <p className="color-gray mb0">{team.game.name}</p>
                                </div>
                            </div>
                            {canEdit && (
                                <>
                                    {team.currentSeason?.isParticipating ? (
                                        <HollowButton
                                            as="button"
                                            className="mt2x"
                                            color="destructive"
                                            onClick={() => setIsSettingSeason(true)}
                                        >
                                            Leave {seasonAlternateName.toLowerCase()}
                                        </HollowButton>
                                    ) : (
                                        <div>
                                            <SolidButton
                                                as="button"
                                                className="mt2x"
                                                disabled={hasOutstandingPayables}
                                                onClick={() => setIsSettingSeason(true)}
                                            >
                                                Join {seasonAlternateName.toLowerCase()}
                                            </SolidButton>
                                            {hasOutstandingPayables && (
                                                <InfoMessage
                                                    className="mt"
                                                    message="All team members must pay league fee before joining a season"
                                                    type="info"
                                                />
                                            )}
                                        </div>
                                    )}
                                    {((!!team.currentSeason &&
                                        team.currentSeason.season.allowFreeAgents &&
                                        team.currentSeason.season.currentState <=
                                            Core.Models.CompetitionState.NotStarted) ||
                                        team.openToFreeAgents) && (
                                        <ToggleSwitch
                                            className="mt2x mb0"
                                            label="Allow free agents to be placed onto this team"
                                            onToggleSwitch={updateFreeAgentPreferences}
                                            value={team.openToFreeAgents}
                                        />
                                    )}
                                    {team.game.minimumSeats > 1 && (
                                        <>
                                            <ToggleSwitch
                                                className="mt2x mb0"
                                                label="Allow users to join this team when signing up"
                                                onToggleSwitch={updateJoinCodePreferences}
                                                value={!!team.joinCode}
                                            />
                                            {!!team.joinCode && (
                                                <CopyTextField
                                                    className="mt2x mb0"
                                                    layoutInline
                                                    id="teamJoinCodeField"
                                                    label="Team Join Code"
                                                    value={team.joinCode}
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
            {isSettingSeason && (
                <SetTeamSeason
                    hasEntryFee={!!team.currentSeason?.season.payable}
                    onAddFulfillment={async (fulfillment: Core.Models.PayableFulfillment) => {
                        setTeam((currentTeam: Core.Models.Team) => {
                            currentTeam.fulfillments.push(fulfillment);
                            return { ...currentTeam };
                        });
                        await reloadData(false);
                    }}
                    onComplete={async () => {
                        await reloadData(false);
                        setIsSettingSeason(false);
                    }}
                    onCancel={() => {
                        setIsSettingSeason(false);
                    }}
                    seasonId={team.currentSeason && team.currentSeason.season.id}
                    team={team}
                />
            )}
            {!!teamBeingDesignated && (
                <ChangeDesignationModal
                    designations={leagueConfiguration?.designations}
                    onClose={() => setTeamBeingDesignated(undefined)}
                    onRemoved={async () => {
                        setTeamBeingDesignated(undefined);
                        await reloadData(false);
                    }}
                    onSubmit={async (values: EditDesignationFormValues, actions: EditDesignationsFormActions) => {
                        try {
                            actions.setStatus(undefined);
                            await TeamService.editTeam({
                                id: teamBeingDesignated.id,
                                name: teamBeingDesignated.name,
                                designationId: values.designationId,
                            });
                            setTeamBeingDesignated(undefined);
                            await reloadData(false);
                        } catch (error) {
                            const message = Core.API.getErrorMessage(error);
                            actions.setStatus(message);
                        } finally {
                            actions.setSubmitting(false);
                        }
                    }}
                    team={teamBeingDesignated}
                />
            )}
        </div>
    );
};

export default TeamDetails;

import React, { useState } from 'react';

import * as Core from '../../../core';
import PaginatedTeamsList from './paginatedTeamsList';
import { Filter } from '../../../components/inputs';

interface GameTeamsProps {
    game: Core.Models.NamedObject;
    hideTeamControls?: boolean;
    hideTeamDetails?: boolean;
    organizationId: string;
}

const GameTeams = (props: GameTeamsProps): JSX.Element => {
    const { hideTeamControls, hideTeamDetails, game, organizationId } = props;

    const [filter, setFilter] = useState<string>('');

    return (
        <div className="team-list-by-game">
            <div className="team-list-by-game__header">
                <div className="team-list-by-game__header__game-name">{game.name}</div>

                <Filter
                    className="league-page__organizations-filter"
                    debounceMs={Core.Constants.FORM_DEBOUNCE_TIME_MS}
                    label="Find your team"
                    setValue={setFilter}
                    value={filter}
                />
            </div>

            <PaginatedTeamsList
                {...{
                    filter,
                    game,
                    hideTeamControls,
                    hideTeamDetails,
                    organizationId,
                }}
            />
        </div>
    );
};

export default GameTeams;

import * as Core from '../core';
import { FulfillPayableFormValues } from '../components/payments/fulfillPayable';

const attachGatewayBankAccount = async (request: Core.Models.AttachGatewayBankAccountRequest): Promise<void> => {
    const route = Core.API.ServerRoute.forAction('paymentGateways/bankAccount');
    return await Core.API.post(route, request);
};

const createPayable = async (request: Core.Models.UpsertPayableRequest): Promise<Core.Models.Payable> => {
    const route = Core.API.ServerRoute.forAction('paymentGateways/payables');
    return await Core.API.post(route, request);
};

const editPayable = async (request: Core.Models.UpsertPayableRequest): Promise<Core.Models.Payable> => {
    const route = Core.API.ServerRoute.forAction('paymentGateways/payables');
    return await Core.API.patch(route, request);
};

const deletePayable = async (payableId: string): Promise<void> => {
    const route = Core.API.ServerRoute.forAction(`paymentGateways/payables/${payableId}`);
    return await Core.API.delete(route);
};

const createPaymentMethod = async (request: Core.Models.CreatePaymentMethodRequest): Promise<void> => {
    const route = Core.API.ServerRoute.forAction('paymentGateways/paymentMethods');
    return await Core.API.post(route, request);
};

const getPaymentMethods = async (paymentGatewayId: string): Promise<Core.Models.PaymentMethod[]> => {
    const route = Core.API.ServerRoute.forAction(`paymentGateways/paymentMethods/${paymentGatewayId}`);
    return await Core.API.get(route);
};

const deletePaymentMethod = async (paymentMethodId: string): Promise<void> => {
    const route = Core.API.ServerRoute.forAction(`paymentGateways/paymentMethods/${paymentMethodId}`);
    return await Core.API.delete(route);
};

const createAccountLink = async (command: {
    accountLinkType: Core.Models.AccountLinkType;
    leagueId: string;
    paymentGatewayId: string;
}): Promise<Core.Models.AccountLink> => {
    const route = Core.API.ServerRoute.forAction('paymentGateways', 'accountLink');
    return await Core.API.post(route, command, Core.API.ApiVersion.v1, { headers: { 'X-Cross-League': true } });
};

const createGatewayAccount = async (command: { paymentGatewayId: string }): Promise<void> => {
    const route = Core.API.ServerRoute.forAction('paymentGateways');
    return await Core.API.post(route, command);
};

const fulfillPayable = async (command: FulfillPayableFormValues): Promise<Core.Models.PayableFulfillment> => {
    const route = Core.API.ServerRoute.forAction('paymentGateways/payables/fulfill');
    return await Core.API.post(route, command);
};

const getPayables = async (entityType?: Core.Models.PayableEntityType): Promise<Core.Models.Payable[]> => {
    const route = Core.API.ServerRoute.forAction(`paymentGateways/payables/${entityType ?? ''}`);
    return await Core.API.get(route);
};

const getStripeAccount = async (paymentGatewayId: string): Promise<Core.Models.GatewayAccount | undefined> => {
    const route = Core.API.ServerRoute.forAction(`paymentGateways/${paymentGatewayId}`);
    return await Core.API.get(route);
};

const refundPayableFulfillment = async (payableFulfillmentId: string): Promise<void> => {
    const route = Core.API.ServerRoute.forAction(`paymentGateways/payableFulfillments/${payableFulfillmentId}/refund`);
    return await Core.API.post(route, null);
};

const PaymentGatewayService = {
    attachGatewayBankAccount,
    createAccountLink,
    createGatewayAccount,
    createPayable,
    createPaymentMethod,
    deletePayable,
    deletePaymentMethod,
    editPayable,
    fulfillPayable,
    getPayables,
    getPaymentMethods,
    getStripeAccount,
    refundPayableFulfillment,
};

export default PaymentGatewayService;

import * as React from 'react';
import { orderBy, uniqBy } from 'lodash';
import { useSelector } from 'react-redux';

import * as Core from '../../core';
import TabNav from '../../components/tabNav';
import { StageTypeHandlerService } from '../../services/stageTypeHandlerService';
import { getIsMyParticipant } from '../../store/selectors/myParticipant';

import './index.scss';

interface StandingsBySeasonProps {
    externalLinks?: boolean;
    standings: Core.Models.RelevantGroupMergedStandingsResult;
}

const StandingsBySeason = (props: StandingsBySeasonProps) => {
    const { externalLinks, standings } = props;
    const seasons = React.useMemo(() => {
        return orderBy(
            uniqBy(
                standings.groups.map((g: Core.Models.Group) => g.stage!.season!),
                (s: Core.Models.Season) => s.id
            ),
            (s: Core.Models.Season) => s.name
        );
    }, [standings]);
    const [currentTabIndex, setCurrentTabIndex] = React.useState<number>(0);
    const tabs = seasons.map((season: Core.Models.Season, index: number) => {
        return {
            contents: <span>{season.name}</span>,
            id: season.id,
            name: season.name.replace(/\s/g, '-').toLowerCase(),
            onClick: (e: React.MouseEvent) => {
                e.preventDefault();
                setCurrentTabIndex(index);
            },
        };
    });

    const season = seasons[currentTabIndex];
    const seasonStandings = React.useMemo(() => {
        return {
            ...standings,
            groups: standings.groups.filter(
                (group: Core.Models.Group) => season && group.stage!.season!.id === season.id
            ),
        };
    }, [season, standings]);

    const isMyParticipant = useSelector(getIsMyParticipant);

    return (
        <div className="standings-by-season">
            <div className="standings-by-season__header">
                <TabNav items={tabs} current={currentTabIndex} className="mb2x" />
            </div>
            {!!season &&
                seasonStandings.groups.map((group: Core.Models.Group) => {
                    const handlers = StageTypeHandlerService.getHandlers(group.stage!.stageTypeId);
                    return React.createElement(handlers.groupStandingsDisplayComponent, {
                        externalLinks: !!externalLinks,
                        group,
                        stage: group.stage!,
                        showGroupName: (group.numGroups || 0) > 1,
                        showStageName: true,
                        key: group.id,
                        participantIdsToAlwaysShow: props.standings.participantIds,
                        participantIdsToHighlight: props.standings.participantIds,
                        isMyParticipant,
                    });
                })}
        </div>
    );
};

export default StandingsBySeason;

import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../core';
import { ModifyLocationFormProps, ModifyLocationFormValues } from './types';
import { Button } from '../../components/button';
import FormField from '../formField';
import InfoMessage from '../infoMessage';
import Loading from '../loading';

import './styles.scss';

export const ModifyLocationForm = ({ create, onDelete, onSubmit, values }: ModifyLocationFormProps) => {
    return (
        <Formik<ModifyLocationFormValues>
            initialValues={Object.assign(
                {
                    description: '',
                    name: '',
                    numberOfStations: '',
                },
                values
            )}
            onSubmit={onSubmit}
            render={({ errors, isSubmitting, status, submitForm, touched }: FormikProps<ModifyLocationFormValues>) => (
                <Form className="modify-location__modal-form">
                    <fieldset className="form-group">
                        <FormField description="Location name" name="name" placeholder="Location Name" type="text" />
                        {create && (
                            <FormField
                                description="Number of Stations"
                                max={100}
                                min={1}
                                name="numberOfStations"
                                placeholder="Number of Stations (optional)"
                                type="number"
                            />
                        )}
                        <FormField
                            component="textarea"
                            description="Additional Details"
                            name="description"
                            placeholder="Additional Details (optional)"
                        />
                    </fieldset>
                    {status && <InfoMessage message={status} type="error" />}
                    <InfoMessage message={errors} filter={touched} type="error" />

                    <fieldset className="form-group form-group--undecorated">
                        {isSubmitting && <Loading buttonLoader />}
                        <Button className="mb" disabled={isSubmitting} onClick={submitForm} wide>
                            Save
                        </Button>
                        {onDelete && !create && (
                            <Button
                                onClick={() => onDelete()}
                                disabled={isSubmitting}
                                styleType={Core.Models.StyleType.Secondary}
                                wide
                            >
                                Remove Location
                            </Button>
                        )}
                    </fieldset>
                </Form>
            )}
            validationSchema={Yup.object().shape({
                description: Yup.string()
                    .max(Core.Constants.MARKDOWN_MAX_LENGTH, 'Location description is too long.')
                    .nullable(),
                name: Yup.string()
                    .max(Core.Constants.NAME_MAX_LENGTH, 'Location name is too long.')
                    .required('Location name is required.'),
                numberOfStations: Yup.number()
                    .min(1, 'Number of stations must be 1 or more.')
                    .max(100, 'Number of stations must be 100 or fewer.')
                    .nullable(),
            })}
        />
    );
};

import React from 'react';
import { Redirect } from 'react-router-dom';

import * as Core from '../../core';
import { useIdentityVerificationPage } from './hooks';
import { IdentityVerificationItem } from './identityVerificationItem';
import { IdentityVerificationList } from './identityVerificationList';
import { IdentityVerificationModal } from './identityVerificationModal';
import { IdentityVerificationPageProps } from './types';
import withLoading from '../../components/withLoading';

import './styles.scss';

const IdentityVerificationPage = (props: IdentityVerificationPageProps) => {
    const {
        activeTabIndex,
        calculateSubmissionQueue,
        canEditLeague,
        closeModal,
        isModalOpen,
        isPermissionsLoading,
        items,
        openModal,
        setActiveTabIndex,
        submissionQueue,
        tabs,
    } = useIdentityVerificationPage(props);

    if (!isPermissionsLoading && !canEditLeague) return <Redirect to="/" />;

    return (
        <div className="page identity-verification__page">
            <h2 className="identity-verification__page__title">Identity Verification</h2>
            <IdentityVerificationList
                activeTabIndex={activeTabIndex}
                setActiveTabIndex={setActiveTabIndex}
                tabs={tabs}
                emptyMessage={
                    tabs[activeTabIndex]?.state === Core.Models.UserIdentityVerificationSubmissionState.Submitted
                        ? 'All caught up!'
                        : undefined
                }
                items={items}
                renderItem={(item: Core.Models.IdentityVerificationSubmissionSummary) => (
                    <IdentityVerificationItem key={item.userId} onClick={() => openModal(item.userId)} {...item} />
                )}
            />
            {isModalOpen && submissionQueue && (
                <IdentityVerificationModal
                    calculateSubmissionQueue={calculateSubmissionQueue}
                    onClose={closeModal}
                    queue={submissionQueue}
                />
            )}
        </div>
    );
};

export default withLoading(IdentityVerificationPage, {
    loadingProps: { blockItem: true },
});

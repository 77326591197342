import * as React from 'react';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import { orderBy } from 'lodash';
import * as Yup from 'yup';

import * as Core from '../../core';
import { Button } from '../../components/button';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';
import Loading from '../../components/loading';

interface AddNewHandleProps {
    gameHandleSources: Core.Models.GameHandleSource[];
    onSubmit: (gameSourceId: string, handle: string) => Promise<void>;
}

interface AddNewHandleValues {
    gameSourceId: string;
    handle: string;
}

const schema = Yup.object().shape({
    gameSourceId: Yup.string().required('Game source is required.'),
    handle: Yup.string()
        .required('Handle is required.')
        .max(
            Core.Constants.NAME_MAX_LENGTH,
            `Handle is too long. (${Core.Constants.NAME_MAX_LENGTH} character maximum)`
        ),
});

const AddHandle = (props: AddNewHandleProps) => {
    const { gameHandleSources, onSubmit } = props;

    const saveChanges = async (values: AddNewHandleValues, actions: FormikActions<AddNewHandleValues>) => {
        try {
            const { gameSourceId, handle } = values;
            await onSubmit(gameSourceId, handle);
        } catch (error) {
            const message = Core.API.getErrorMessage(error);
            actions.setStatus(message);
        }
        actions.setSubmitting(false);
    };

    return (
        <Formik
            initialValues={{ gameSourceId: '', handle: '' }}
            onSubmit={saveChanges}
            validationSchema={schema}
            render={(props: FormikProps<AddNewHandleValues>) => (
                <Form>
                    <fieldset className="form-group">
                        <FormField component="select" name="gameSourceId" label="Select a Game Source">
                            <option value="" hidden disabled>
                                --
                            </option>
                            {orderBy(gameHandleSources, (ghs: Core.Models.GameHandleSource) => ghs.name)
                                .filter((ghs: Core.Models.GameHandleSource) => !ghs.oAuthProviderId) // ignore those that require link
                                .map((ghs: Core.Models.GameHandleSource) => (
                                    <option value={ghs.id} key={ghs.id}>
                                        {ghs.name}
                                    </option>
                                ))}
                        </FormField>
                    </fieldset>
                    <fieldset className="form-group">
                        <FormField type="text" name="handle" description="Handle" />
                    </fieldset>
                    <fieldset className="form-group form-group--undecorated">
                        {props.status && <InfoMessage message={props.status} type="error" />}
                        <InfoMessage filter={props.touched} message={props.errors} type="error" />

                        {props.isSubmitting && <Loading buttonLoader />}
                        <Button onClick={props.submitForm} disabled={props.isSubmitting} round wide>
                            Save
                        </Button>
                    </fieldset>
                </Form>
            )}
        />
    );
};

export default AddHandle;

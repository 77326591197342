import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isUndefined } from 'lodash';
import * as QueryString from 'query-string';
import { useDispatch } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import * as Core from '../../../core';
import InfoMessage from '../../../components/infoMessage';
import Loading from '../../../components/loading';
import { useLoginState } from '../../../hooks/store';
import { LoginRedirectService } from '../../../services/loginRedirectService';
import { OAuthService } from '../../../services/oAuthService';
import { logout } from '../../../store/login/actions';

import './generic.scss';

interface LinkGenericOAuthAccountProps extends RouteComponentProps<{ type: 'generic' | 'steam' }> {}

const LinkGenericOAuthAccount = ({
    location,
    match: {
        params: { type },
    },
}: LinkGenericOAuthAccountProps) => {
    const {
        code,
        'openid.identity': openIdIdentity,
        state,
    } = useMemo(() => QueryString.parse(location.search), [location.search]);
    const {
        bypassPlatformLogin,
        oAuthProviderId,
        returnUrl,
        ...stateRest
    }: Core.Models.GenericOAuthState | Core.Models.VerificationProviderOAuthState = useMemo(
        () => JSON.parse(atob(decodeURI((state as string).toString()))),
        [state]
    );

    const [error, setError] = useState<string | undefined>(undefined);
    const [success, setSuccess] = useState<boolean>(!returnUrl);

    const dispatch = useDispatch();
    const loginState = useLoginState();

    const requiresLogin = useMemo(
        () => !bypassPlatformLogin && isUndefined(loginState.decodedToken),
        [bypassPlatformLogin, loginState.decodedToken]
    );

    // if the intended `userId` doesn't match the JWT's userId then force re-login
    const forceLogin = useMemo(
        () =>
            !isUndefined((stateRest as Core.Models.GenericOAuthState).userId) &&
            !loginState.isLoading &&
            !isUndefined(loginState.decodedToken) &&
            loginState.decodedToken.sub !== (stateRest as Core.Models.GenericOAuthState).userId,
        [loginState.decodedToken, loginState.isLoading, stateRest]
    );

    useEffect(() => {
        (async () => {
            // skip entirely and redirect later on if login is required
            if (requiresLogin) return;

            // logout and redirect to login page
            if (!bypassPlatformLogin && forceLogin) {
                dispatch(logout());
                return;
            }

            if (success || !isUndefined(error)) return;

            try {
                const baseCommand = {
                    code:
                        type === 'generic'
                            ? (code as string)
                            : openIdIdentity?.toString()?.split('/').reverse()[0] ?? '',
                    oAuthProviderId,
                    redirectUri: window.location.origin + window.location.pathname,
                };
                const response = !!bypassPlatformLogin
                    ? await OAuthService.verifyGenericOAuthProvider({
                          ...baseCommand,
                          discordChannelId: (stateRest as Core.Models.VerificationProviderOAuthState).discordChannelId,
                          discordGuildId: (stateRest as Core.Models.VerificationProviderOAuthState).discordGuildId,
                          discordRoleId: (stateRest as Core.Models.VerificationProviderOAuthState).discordRoleId,
                          discordUserId: (stateRest as Core.Models.VerificationProviderOAuthState).discordUserId,
                      })
                    : await OAuthService.linkGenericOAuthProvider(baseCommand);
                if (!response.success) throw Error(response.errorMessage);

                setSuccess(true);

                if (returnUrl) {
                    setTimeout(() => (window.location.href = returnUrl), 1000);
                }
            } catch (e) {
                const message = Core.API.getErrorMessage(e);
                setError(message);
            }
        })();
    }, [
        bypassPlatformLogin,
        code,
        dispatch,
        error,
        forceLogin,
        oAuthProviderId,
        openIdIdentity,
        requiresLogin,
        returnUrl,
        stateRest,
        success,
        type,
    ]);

    if (requiresLogin) return <Redirect to={LoginRedirectService.buildLoginUrl()} />;

    return (
        <div className="link-generic-oauth-account">
            <div className="link-generic-oauth-account__box">
                <div className="link-generic-oauth-account__box_icon">
                    {!!error ? (
                        <FontAwesomeIcon icon={['fas', 'circle-exclamation']} size="1x" className="color-error" />
                    ) : success ? (
                        <FontAwesomeIcon icon={['fas', 'circle-check']} size="1x" className="color-success" />
                    ) : (
                        <Loading blockItem />
                    )}
                </div>
                <p>{!!error ? 'An Error Occurred' : success ? 'Linked' : 'Linking'}</p>
                <p>
                    {!!error ? (
                        <>
                            Please try linking your account again from your profile
                            {!!returnUrl && (
                                <>
                                    {' '}
                                    by <a href={returnUrl}>clicking here</a>
                                </>
                            )}
                            .
                        </>
                    ) : success ? (
                        <>
                            If you are not redirected after a few seconds, please
                            {!!returnUrl ? (
                                <>
                                    {' '}
                                    <a href={returnUrl}>click this link</a>
                                </>
                            ) : (
                                <> close this window</>
                            )}
                            .
                        </>
                    ) : (
                        <>
                            If you are not redirected after a few seconds, please{' '}
                            <a href={returnUrl}>click this link</a>.
                        </>
                    )}
                </p>
                {!!error && <InfoMessage message={error} type="error" />}
            </div>
        </div>
    );
};

export default LinkGenericOAuthAccount;

import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import EnableMfaModal from './enableMfaModal';
import { HollowButton } from '../../../components/buttons-visuals';
import ConfirmModal from '../../../components/confirmModal';
import { UserService } from '../../../services/userService';

interface SecureWithMfaProps {
    canEditGlobalUser: boolean;
    isMe: boolean;
    profile: Core.Models.UserProfile;
    reloadData: () => Promise<void>;
}

const SecureWithMfa = ({ canEditGlobalUser, isMe, profile, reloadData }: SecureWithMfaProps): JSX.Element => {
    const [isDisablingMfa, setIsDisablingMfa] = useState<boolean>(false);
    const [isEnablingMfa, setIsEnablingMfa] = useState<boolean>(false);

    const disableMfa = useCallback(async () => {
        try {
            await UserService.disableUserMfa({ userId: profile.userId });
            toast.success('Removed two-factor authentication from this account.');
            setIsDisablingMfa(false);
            reloadData();
        } catch {
            toast.error('Unable to remove two-factor authentication from this account.');
        }
    }, [profile.userId, reloadData]);

    return (
        <div className="secure-with-mfa">
            <h4 className="heading-4">Two-factor authentication</h4>
            {profile.mfaEnabled ? (
                <div className="disp-flex align-center justify-between">
                    <span>
                        <FontAwesomeIcon icon={['fas', 'lock']} size="xs" className="mr" />
                        Enabled
                    </span>
                    {canEditGlobalUser && (
                        <HollowButton
                            as="button"
                            className="ml2x"
                            color="destructive"
                            onClick={() => setIsDisablingMfa(true)}
                            size="small"
                        >
                            <FontAwesomeIcon icon={['fas', 'lock-open']} size="sm" className="mr" />
                            Remove
                        </HollowButton>
                    )}
                </div>
            ) : (
                <>
                    {isMe ? (
                        <HollowButton as="button" color="primary" size="small" onClick={() => setIsEnablingMfa(true)}>
                            <FontAwesomeIcon icon={['fas', 'lock']} size="xs" className="mr" />
                            Configure
                        </HollowButton>
                    ) : (
                        <span>
                            <FontAwesomeIcon icon={['fas', 'lock-open']} size="xs" className="mr" />
                            Not enabled
                        </span>
                    )}
                </>
            )}

            {isDisablingMfa && (
                <ConfirmModal
                    onCancel={() => setIsDisablingMfa(false)}
                    onConfirm={disableMfa}
                    title="Are you sure you want to remove two-factor authentication?"
                >
                    <p>If you remove two-factor authentication, this account will be less secure.</p>
                </ConfirmModal>
            )}
            {isEnablingMfa && <EnableMfaModal onClose={() => setIsEnablingMfa(false)} {...{ reloadData }} />}
        </div>
    );
};

export default SecureWithMfa;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormikActions } from 'formik';
import { debounce, isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import { tabs } from './constants';
import { IdentityVerificationPageProps, IdentityVerificationValues, SubmissionUserIdQueue } from './types';
import useAbortablePromise from '../../hooks/abortablePromise';
import usePagination from '../../hooks/pagination';
import { useIsPermissionsLoading, useLeague, useUserPermissionService } from '../../hooks/store';
import { UserService } from '../../services/userService';

export const useIdentityVerificationList = (allItems: Core.Models.IdentityVerificationSubmissionSummary[]) => {
    const pagination = usePagination(allItems, Core.Constants.PAGINATION.IDENTITY_VERIFICATIONS_PER_PAGE);
    return {
        currentItems: pagination.currentData(),
        pageSize: Core.Constants.PAGINATION.IDENTITY_VERIFICATIONS_PER_PAGE,
        totalCount: allItems.length,
        ...pagination,
    };
};

export const useIdentityVerificationModal = (userId: string) => {
    const [submission, setSubmission] = useState<Core.Models.IdentityVerificationSubmissionDetails | null>(null);
    const [showAdminNotes, setShowAdminNotes] = useState(!!submission?.adminNotes);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentState, setCurrentState] = useState<Core.Models.UserIdentityVerificationSubmissionState>(
        submission?.state ?? Core.Models.UserIdentityVerificationSubmissionState.Submitted
    );

    const loadSubmission = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await UserService.getIdentityVerificationDetails(userId);
            setSubmission(response.submission);
            setCurrentState(response.submission.state);
            setShowAdminNotes(!!response.submission.adminNotes);
        } catch (e) {
            const message = Core.API.getErrorMessage(e);
            toast.error(message);
        } finally {
            setIsLoading(false);
        }
    }, [userId]);
    useAbortablePromise(loadSubmission);

    const updateVerificationSubmission = useCallback(
        async (values: IdentityVerificationValues, actions?: FormikActions<IdentityVerificationValues>) => {
            try {
                actions?.setStatus(undefined);
                await UserService.updateUserIdentityVerification({
                    userId,
                    state: currentState,
                    ...values,
                });
                setSubmission((s: Core.Models.IdentityVerificationSubmissionDetails | null) =>
                    !!s
                        ? {
                              ...s,
                              adminNotes: values?.notes ?? '',
                          }
                        : s
                );
            } catch (e) {
                const message = Core.API.getErrorMessage(e);
                actions?.setStatus(message);
            } finally {
                actions?.setSubmitting(false);
            }
        },
        [userId, currentState]
    );

    const autoSave = useMemo(
        () => debounce(updateVerificationSubmission, Core.Constants.FORM_DEBOUNCE_TIME_MS_LONG),
        [updateVerificationSubmission]
    );

    const currentStateText = useMemo(() => {
        switch (currentState) {
            case Core.Models.UserIdentityVerificationSubmissionState.Approved:
                return 'Approved';
            case Core.Models.UserIdentityVerificationSubmissionState.MoreInformationRequired:
                return 'Needs more info';
            case Core.Models.UserIdentityVerificationSubmissionState.Rejected:
                return 'Rejected';
            default:
                return 'Submitted';
        }
    }, [currentState]);

    const adminNotesButtonText = useMemo(() => {
        if (showAdminNotes) return '- Hide Notes';
        return isEmpty(submission?.adminNotes) ? '+ Add Notes' : '+ Show Notes';
    }, [showAdminNotes, submission?.adminNotes]);

    return {
        adminNotesButtonText,
        autoSave,
        currentState,
        currentStateText,
        isLoading,
        setCurrentState,
        setShowAdminNotes,
        showAdminNotes,
        submission,
        updateVerificationSubmission,
    };
};

export const useIdentityVerificationPage = ({ setIsLoading }: IdentityVerificationPageProps) => {
    const [activeTabIndex, setActiveTabIndex] = useState<Core.Models.UserIdentityVerificationSubmissionState>(
        Core.Models.UserIdentityVerificationSubmissionState.Submitted
    );
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [items, setItems] = useState<Core.Models.IdentityVerificationSubmissionSummary[]>([]);
    const [submissionQueue, setSubmissionQueue] = useState<SubmissionUserIdQueue | null>(null);
    const league = useLeague();
    const userPermissionService = useUserPermissionService();
    const canEditLeague = userPermissionService.hasLeagueAccess(Core.Models.PermissionLevel.Edit, league);
    const isPermissionsLoading = useIsPermissionsLoading();

    const reload = useCallback(async () => {
        try {
            setIsLoading(true);
            const { submissions } = await UserService.getIdentityVerificationsSummary({
                state: tabs[activeTabIndex]?.state,
            });
            setItems(submissions);
        } catch (e) {
            const message = Core.API.getErrorMessage(e);
            toast.error(message);
        } finally {
            setIsLoading(false);
        }
    }, [activeTabIndex]);
    useAbortablePromise(reload);

    const calculateSubmissionQueue = useCallback(
        (userId: string) => {
            const currentItemIndex = items.findIndex(
                (i: Core.Models.IdentityVerificationSubmissionSummary) => i.userId === userId
            );
            if (currentItemIndex < 0) {
                setSubmissionQueue({
                    currentId: userId,
                });
                return;
            }
            setSubmissionQueue({
                currentId: userId,
                nextId: items[currentItemIndex + 1]?.userId,
                previousId: items[currentItemIndex - 1]?.userId,
            });
        },
        [items]
    );

    const openModal = useCallback(
        (userId: string) => {
            calculateSubmissionQueue(userId);
        },
        [calculateSubmissionQueue]
    );

    const closeModal = useCallback(() => {
        setSubmissionQueue(null);
    }, []);

    useEffect(() => setIsModalOpen(!!submissionQueue), [submissionQueue]);

    return {
        activeTabIndex,
        calculateSubmissionQueue,
        canEditLeague,
        closeModal,
        isModalOpen,
        isPermissionsLoading,
        items,
        openModal,
        setActiveTabIndex,
        submissionQueue,
        tabs,
    };
};

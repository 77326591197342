import { useCallback, useMemo } from 'react';
import { flatMap, keyBy, last, orderBy, sortBy } from 'lodash';
import { useSelector } from 'react-redux';

import * as Core from '../../core';
import { BracketRound } from './types';
import { getBracketRoundName, getFinalRoundIndex, getRoundMatches } from './utils';
import { useAlternateSeasonName } from '../../hooks/store';
import { getIsMyParticipant } from '../../store/selectors/myParticipant';

export const useBracket = (
    bracket: Core.Models.Bracket,
    bracketMatches: Core.Models.Match[],
    stageRounds: Core.Models.Round[]
) => {
    const isMyParticipant = useSelector(getIsMyParticipant);
    const isSubBracket = useMemo(
        () => bracket === Core.Models.Bracket.Finals || bracket === Core.Models.Bracket.Lower,
        [bracket]
    );
    const isMatchInBracket = useCallback(
        (matchId: string) => {
            const match = bracketMatches.find((m: Core.Models.Match) => m.id === matchId);
            return !!match && match.matchParticipants.length > 1; // exists and is not a bye
        },
        [bracketMatches]
    );

    // the `Bracket` component needs the matches in a specific order in each round, and needs them to be structured in a specific way
    // Matches in a round must be in "perfect" order - ie. the first match's winner leads to the top team in the first match of the next round, second match's winner leads to bottom team in first match of next round, etc.
    // We're building clones of the rounds/matches so that we don't mutate the objects passed in
    const bracketRounds = useMemo(() => {
        const outputRounds: BracketRound[] = orderBy(stageRounds, (r: Core.Models.Round) => r.sortOrder).map(
            ({ id, name, sortOrder }: Core.Models.Round) => {
                return {
                    id,
                    name: getBracketRoundName(bracket, name, sortOrder),
                    matches: [] as (Core.Models.Match | null)[],
                };
            }
        );
        const matchesById = keyBy(bracketMatches, (m: Core.Models.Match) => m.id);

        const finalRoundIndex = getFinalRoundIndex(bracketMatches, outputRounds);
        if (finalRoundIndex < 0) return [];

        // Fills in the final round's match(es).
        const finalRound = outputRounds[finalRoundIndex];
        finalRound.matches = orderBy(
            bracketMatches.filter((m: Core.Models.Match) => m.roundId === finalRound.id && m.sortOrder === 1),
            (m: Core.Models.Match) => m.sortOrder
        );

        // Fills in the other rounds starting from the second last round.
        for (let i = finalRoundIndex - 1; i >= 0; i--) {
            const nextRound = outputRounds[i + 1];
            const round = outputRounds[i];

            // from the matches in the next round, get the matches in this round that lead to it (with nulls for missing matches)
            round.matches = flatMap(nextRound.matches, (m: Core.Models.Match | null) =>
                m ? getRoundMatches(isSubBracket, matchesById, m.matchParticipants) : [null, null]
            );
        }

        // Adds the 3rd-place match if it exists.
        const thirdPlaceMatch = bracketMatches.filter(
            (m: Core.Models.Match) => m.roundId === finalRound.id && m.sortOrder === 2
        );
        if (!!thirdPlaceMatch) finalRound.matches.push(thirdPlaceMatch[0]);

        // Removes rounds that contain no matches or just byes and returns.
        return outputRounds.filter((_r, i: number) => i <= finalRoundIndex);
    }, [bracket, bracketMatches, isSubBracket, stageRounds]);

    // this code currently relies on the items in the matches array to be 'undefined' when there's no match in that slot, and for the matches to be all in-order relative to the UI
    // once we're getting real data from the server, we should have logic in this (or the parent) component that remaps what the server gives us to line up to these constraints
    const winner = useMemo(
        () =>
            last(bracketRounds)?.matches[0]?.matchParticipants.filter(
                (mp: Core.Models.MatchParticipant) => mp.isWinner
            )[0],
        [bracketRounds]
    );

    // Hides link to pair-match for sub brackets.
    const hideLinkSubBracket = useCallback(
        (cssRoundNum: number): boolean => isSubBracket && cssRoundNum % 2 === 0,
        [isSubBracket]
    );

    return {
        hideLinkSubBracket,
        isMatchInBracket,
        isSubBracket,
        winner,
        bracketRounds,
        isMyParticipant,
    };
};

export const useGroupEliminationBracket = (group: Core.Models.Group) => {
    const seasonAlternateName = useAlternateSeasonName();
    const groupMatches = useMemo(
        () => (group.matches ?? []).filter((m: Core.Models.Match | undefined): m is Core.Models.Match => !!m),
        [group]
    );
    const brackets = useMemo(
        () =>
            groupMatches.reduce((acc: Core.Models.Bracket[], m: Core.Models.Match) => {
                if (acc.findIndex((bracket: Core.Models.Bracket) => bracket === m.bracket) >= 0) return acc;
                return sortBy([...acc, m.bracket]);
            }, [] as Core.Models.Bracket[]),
        [groupMatches]
    );

    return {
        brackets,
        groupMatches,
        seasonAlternateName,
    };
};

import React from 'react';

import * as Core from '../../core';
import { ReactComponent as EsrbRatingAo } from '../../assets/images/icons/esrb-ao.svg';
import { ReactComponent as EsrbRatingE } from '../../assets/images/icons/esrb-e.svg';
import { ReactComponent as EsrbRatingE10plus } from '../../assets/images/icons/esrb-e10plus.svg';
import { ReactComponent as EsrbRatingM } from '../../assets/images/icons/esrb-m.svg';
import { ReactComponent as EsrbRatingRp } from '../../assets/images/icons/esrb-rp.svg';
import { ReactComponent as EsrbRatingRp17plus } from '../../assets/images/icons/esrb-rp17plus.svg';
import { ReactComponent as EsrbRatingT } from '../../assets/images/icons/esrb-t.svg';

interface EsrbRatingProps {
    rating: Core.Models.EsrbRating;
}

const EsrbRating = ({ rating }: EsrbRatingProps) => {
    switch (rating) {
        case Core.Models.EsrbRating.AO:
            return <EsrbRatingAo />;
        case Core.Models.EsrbRating.E:
            return <EsrbRatingE />;
        case Core.Models.EsrbRating.E10Plus:
            return <EsrbRatingE10plus />;
        case Core.Models.EsrbRating.M:
            return <EsrbRatingM />;
        case Core.Models.EsrbRating.RP:
            return <EsrbRatingRp />;
        case Core.Models.EsrbRating.RP17Plus:
            return <EsrbRatingRp17plus />;
        case Core.Models.EsrbRating.T:
            return <EsrbRatingT />;
        default:
            return <></>;
    }
};

export default EsrbRating;

import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { IHasChildren } from '../../layouts/types';

import './index.scss';

interface ParticipantLinkProps extends IHasChildren {
    className?: string;
    participant: { teamId?: string };
    unstyled?: boolean;
}

// renders a <a> with the correct link to team/user or a <span> if there's no team/user
const ParticipantLink = (props: ParticipantLinkProps) => {
    const { participant, unstyled } = props;
    const link = participant.teamId ? `/teams/${participant.teamId}` : undefined;
    if (link) {
        return (
            <Link
                to={link}
                className={classNames(props.className, 'participation-link', {
                    'participation-link--unstyled': unstyled,
                })}
            >
                {props.children}
            </Link>
        );
    }
    return <span className={props.className}>{props.children}</span>;
};

export default ParticipantLink;

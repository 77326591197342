import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import { chain } from 'lodash';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import * as Core from '../../core';
import PublicJoinTooltip from './publicJoinTooltip';
import { Avatar, HollowButton, SolidButton } from '../../components/buttons-visuals';
import { IconButton } from '../../components/buttons-visuals';
import FormField from '../../components/formField';
import ImageUploader from '../../components/imageUploader';
import InfoMessage from '../../components/infoMessage';
import {
    useHasAnyLeagueMembershipRole,
    useLeague,
    useOrganizationTerm,
    useUserPermissionService,
} from '../../hooks/store';
import { OrganizationService } from '../../services/organizationService';
import { loadProfile } from '../../store/profile/actions';
import { Calendar } from '../calendar';

import './details.scss';

interface OrganizationDetailsProps {
    organization: Core.Models.Organization;
    reloadData: () => Promise<void>;
    loadProfile: () => Promise<void>;
}

interface EditOrganizationValues {
    emailDomains?: string;
    name: string;
    phoneNumber?: string;
    restrictRegistrationToWhitelistedDomains: boolean;
    showPublicJoinUrl: boolean;
}

const OrganizationDetails = (props: OrganizationDetailsProps) => {
    const { organization } = props;
    const { emailDomains, id, joinCode, logoUrl, name, phoneNumber, showPublicJoinUrl } = organization;
    const userPermissionService = useUserPermissionService();
    const league = useLeague();
    const organizationTerm = useOrganizationTerm();
    const location = OrganizationService.getLocationString(organization);
    const canEditOrg = userPermissionService.hasOrganizationAccess(Core.Models.PermissionLevel.ElevatedEdit, id);
    const canEditLeague = userPermissionService.hasLeagueAccess(Core.Models.PermissionLevel.Edit, league);
    const restrictRegistrationToWhitelistedDomains =
        !!league?.restrictRegistrationToWhitelistedDomains || organization.restrictRegistrationToWhitelistedDomains;
    const isMissingEmailDomains =
        restrictRegistrationToWhitelistedDomains && (!emailDomains || emailDomains.length <= 0);
    const isLeagueMember = useHasAnyLeagueMembershipRole(Core.Models.PermissionLevel.ListSpecific);

    const [isEditing, setIsEditing] = useState(false);

    const schema = Yup.object().shape({
        emailDomains: Yup.string().when('restrictRegistrationToWhitelistedDomains', {
            is: true,
            then: Yup.string().required('At least one email domain is required'),
        }),
        name: Yup.string()
            .required('Organization name is required.')
            .max(
                Core.Constants.NAME_MAX_LENGTH,
                `${organizationTerm} name is too long. (${Core.Constants.NAME_MAX_LENGTH} character maximum)`
            ),
        phoneNumber: Yup.string().max(20, 'Phone number is too long. (20 character maximum)').nullable(),
        restrictRegistrationToWhitelistedDomains: Yup.boolean(),
        showPublicJoinUrl: Yup.boolean(),
    });

    const uploadImage = async (imageData: string) => {
        await OrganizationService.uploadLogo({ id, imageData });
        await props.reloadData();
    };

    const isClubLeague = league && league.edition === Core.Models.LeagueEdition.Club;
    if (isClubLeague && !canEditOrg) {
        return (
            <div className="page__details">
                <h2 className="page__text page__text--primary">{league && `${league.name} - `}All Teams</h2>
            </div>
        );
    }

    const saveChanges = async (values: EditOrganizationValues, actions: FormikActions<EditOrganizationValues>) => {
        actions.setStatus(undefined);
        try {
            let emailDomains = parseEmailDomains(values.emailDomains);
            if (!!emailDomains && !domainsAreProperlyFormatted(emailDomains)) {
                actions.setStatus(
                    'One or more domains are formatted incorrectly. Domains must be in the form of what comes after the "@" in an email address (e.g. "leaguespot.gg" or "leaguespot.com").'
                );
                actions.setSubmitting(false);
                return;
            }
            await OrganizationService.editOrganization({ id, ...values, emailDomains });
            setIsEditing(false);
            await Promise.all([props.reloadData(), props.loadProfile()]);
        } catch (error) {
            const message = Core.API.getErrorMessage(error);
            actions.setStatus(message);
        }
        actions.setSubmitting(false);
    };

    const parseEmailDomains = (value: string | undefined): string[] | undefined => {
        if (!value) return undefined;

        return chain(value)
            .split(/,|\n|\r/) // split up the input by comma or carriage return
            .map((option: string) => option.trim().replace(/^@+/g, '').toLowerCase()) // trim whitespace and '@' from beginning
            .compact()
            .value();
    };

    const domainsAreProperlyFormatted = (domains: string[]): boolean => {
        const domainRegex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/; // alphanumeric (and hyphens) dot alphanumeric (e.g. leaguespot.gg or league-spot.com) optional dot alphanumeric
        return domains.every((domain: string) => domainRegex.test(domain));
    };

    const publicJoinText = restrictRegistrationToWhitelistedDomains
        ? `Allow anyone with an email address in the list of allowed email domains to join this ${organizationTerm.toLowerCase()}`
        : `Allow anyone to join this ${organizationTerm.toLowerCase()}`;

    return (
        <div className="organization-profile-details mb4x">
            <div className="organization-profile-details__header">
                <div className="organization-profile-details__header__info">
                    <div
                        className={classNames('organization-profile-details__header__info__avatar', {
                            'organization-profile-details__header__info__avatar--editable': canEditOrg,
                        })}
                    >
                        <Avatar fallback="organization" src={logoUrl} size="xlarge" />
                        {canEditOrg && (
                            <ImageUploader
                                aspectRatio={16 / 16}
                                contentType="image/jpeg"
                                defaultBackgroundColor="#FFFFFF"
                                flush
                                maxImageWidth={Core.Constants.IMAGES.MAX_AVATAR_IMAGE_WIDTH}
                                icon="pen-to-square"
                                text="Update Image"
                                uploadImage={uploadImage}
                            />
                        )}
                    </div>
                    {!isEditing ? (
                        <div>
                            <h1 className="heading-1 mb0">{name}</h1>
                            {location && <p className="weight-700 color-gray mb0">{location}</p>}
                            {phoneNumber && <p className="weight-700 color-gray mb0">{phoneNumber}</p>}
                            <div className="disp-flex align-center flex-gap mt">
                                {showPublicJoinUrl && !!joinCode && !isLeagueMember && (
                                    <SolidButton as="link" size="small" to={`/organizations/join/${joinCode}`}>
                                        Join {organizationTerm}
                                    </SolidButton>
                                )}
                                {canEditOrg && (
                                    <IconButton
                                        as="button"
                                        buttonLabel="Edit organization"
                                        buttonSize="medium"
                                        onClick={() => setIsEditing(true)}
                                    >
                                        <FontAwesomeIcon icon={['fas', 'pen-to-square']} />
                                    </IconButton>
                                )}
                                <Calendar entity={organization} entityType="organization" />
                            </div>
                        </div>
                    ) : (
                        <Formik
                            initialValues={{
                                emailDomains: emailDomains?.join('\n'),
                                name,
                                phoneNumber,
                                restrictRegistrationToWhitelistedDomains,
                                showPublicJoinUrl,
                            }}
                            onSubmit={saveChanges}
                            validationSchema={schema}
                            render={(formProps: FormikProps<EditOrganizationValues>) => (
                                <Form className="organization-profile-details__header__info__form">
                                    <FormField
                                        type="text"
                                        name="name"
                                        description={`${organizationTerm} Name`}
                                        value={name}
                                    />
                                    <FormField
                                        type="tel"
                                        name="phoneNumber"
                                        description="Phone Number"
                                        value={phoneNumber}
                                    />

                                    <h4 className="heading-4">Registration Preferences</h4>
                                    <FormField
                                        description="Restrict registration to a list of allowed email domains"
                                        disabled={!!league?.restrictRegistrationToWhitelistedDomains}
                                        name="restrictRegistrationToWhitelistedDomains"
                                        type="checkbox"
                                    />
                                    {formProps.values.restrictRegistrationToWhitelistedDomains && (
                                        <FormField
                                            component="textarea"
                                            label="Email domains"
                                            name="emailDomains"
                                            placeholder="Email domains (separated by comma or new line)"
                                        />
                                    )}
                                    {canEditLeague && (
                                        <>
                                            <div className="disp-flex align-start flex-gap">
                                                <FormField
                                                    description={publicJoinText}
                                                    name="showPublicJoinUrl"
                                                    type="checkbox"
                                                />
                                                <PublicJoinTooltip {...{ restrictRegistrationToWhitelistedDomains }} />
                                            </div>
                                            {!formProps.values.restrictRegistrationToWhitelistedDomains &&
                                                !!formProps.values.showPublicJoinUrl && (
                                                    <InfoMessage
                                                        message={`Anyone on the internet can join this ${organizationTerm.toLowerCase()}. To restrict membership, select 'Restrict registration to a list of allowed email domains' above and add allowed domains.`}
                                                        type="info"
                                                    />
                                                )}
                                        </>
                                    )}

                                    {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                                    <InfoMessage message={formProps.errors} type="error" filter={formProps.touched} />

                                    <HollowButton
                                        as="button"
                                        color="secondary"
                                        size="large"
                                        onClick={() => setIsEditing(false)}
                                        disabled={formProps.isSubmitting}
                                    >
                                        Cancel
                                    </HollowButton>
                                    <SolidButton
                                        as="button"
                                        className="ml"
                                        size="large"
                                        onClick={formProps.submitForm}
                                        pending={formProps.isSubmitting}
                                    >
                                        Save
                                    </SolidButton>
                                </Form>
                            )}
                        />
                    )}
                </div>

                {!isEditing && canEditOrg && (
                    <div className="organization-profile-details__header__preferences">
                        <h4 className="heading-4">Registration Preferences</h4>
                        <div className="disp-flex align-start justify-between flex-gap">
                            <p className="mb0">Restrict registration to a list of allowed email domains</p>
                            <p className="heading-3 mb0">{restrictRegistrationToWhitelistedDomains ? 'On' : 'Off'}</p>
                        </div>

                        {restrictRegistrationToWhitelistedDomains && (
                            <div className="ml2x mb2x">
                                <h5
                                    className={classNames('heading-5 mb0', {
                                        'email-domains': !isMissingEmailDomains,
                                        'email-domains__warning': isMissingEmailDomains,
                                    })}
                                    title={
                                        isMissingEmailDomains
                                            ? `Your league restricts user registration to a specified set of email domains.  Update your ${organizationTerm.toLowerCase()}'s email domains to start getting players registered.`
                                            : undefined
                                    }
                                >
                                    Email domains
                                </h5>
                                <ul>{emailDomains?.map((domain: string) => <li key={domain}>- {domain}</li>)}</ul>
                            </div>
                        )}
                        <div className="disp-flex align-start justify-between flex-gap">
                            <div className="disp-flex align-start flex-gap">
                                <p>{publicJoinText}</p>
                                <PublicJoinTooltip {...{ restrictRegistrationToWhitelistedDomains }} />
                            </div>
                            <p className="heading-3">{showPublicJoinUrl ? 'On' : 'Off'}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default connect(undefined, { loadProfile })(OrganizationDetails);

import React from 'react';

import InfoMessage from '../infoMessage';

interface ErrorMessageProps {
    error: string | any;
    filter?: any;
    order?: string[];
}

/**
 * @deprecated Use <InfoMessage ... type="error" />` instead.
 */
const ErrorMessage = (props: ErrorMessageProps) => (
    <InfoMessage filter={props.filter} message={props.error} order={props.order} type="error" />
);

export default ErrorMessage;

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Core from '../../core';
import { useLeague, useProfile } from '../../hooks/store';
import { AuthenticationService } from '../../services/authenticationService';
import DiscordService from '../../services/discordService';
import { UserService } from '../../services/userService';

import './index.scss';

const DiscordActivator: React.FunctionComponent<{}> = (): JSX.Element => {
    const [authorizeUrl, setAuthorizeUrl] = React.useState<string | undefined>(undefined);
    const [discordStatus, setDiscordStatus] = React.useState<Core.Models.DiscordStatus>(
        Core.Models.DiscordStatus.Linked
    ); // assume linked by default

    const league = useLeague();
    const profile = useProfile();

    React.useEffect(() => {
        (async function () {
            try {
                // only run if the League has a Discord
                if (!league || !league.discordUrl) return;

                // only run if there is a user ID
                const userId = AuthenticationService.getUserId();
                if (typeof userId === 'undefined') return;

                if (!profile || !!profile?.isUnder13) return;

                const response = await UserService.getMyDiscordStatus();
                const authUrl = DiscordService.generateFullAuthorizeUrl(response.authorizeUrl, userId);
                setAuthorizeUrl(authUrl);
                setDiscordStatus(response.status);
            } catch {} // ignore errors
        })();
    }, [league, profile]);

    const renderCta = (): JSX.Element => {
        switch (discordStatus) {
            case Core.Models.DiscordStatus.NotLinked:
                return (
                    <>
                        <span className="discord-activator__content__label">This league is using Discord</span>
                        <a className="discord-activator__content__cta" href={authorizeUrl}>
                            Link account
                        </a>
                    </>
                );
            case Core.Models.DiscordStatus.RequiresRelink:
                return (
                    <>
                        <span className="discord-activator__content__label">
                            We need additional Discord permissions
                        </span>
                        <a className="discord-activator__content__cta" href={authorizeUrl}>
                            Relink account
                        </a>
                    </>
                );
            case Core.Models.DiscordStatus.Expired:
                return (
                    <>
                        <span className="discord-activator__content__label">Your Discord session has expired</span>
                        <a className="discord-activator__content__cta" href={authorizeUrl}>
                            Log back in
                        </a>
                    </>
                );
            default:
                return <></>;
        }
    };

    return (
        <>
            {discordStatus < Core.Models.DiscordStatus.Linked && (
                <div className="discord-activator">
                    <div className="discord-activator__content">
                        <FontAwesomeIcon icon={['fab', 'discord']} size="2x" />
                        {renderCta()}
                    </div>
                </div>
            )}
        </>
    );
};

export default DiscordActivator;

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';

import * as Core from '../../core';
import EditRegistrationField from './editRegistrationField';
import { AddButton, IconButton } from '../../components/buttons-visuals';
import ConfirmModal from '../../components/confirmModal';
import { ContentContainer } from '../../components/containers';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import CustomRegistrationFormField from '../../components/formField/customRegistrationFormField';
import { withLoadDataDefaultConfig } from '../../components/loadData';
import Modal from '../../components/modal';
import { LeagueService } from '../../services/leagueService';
import { AppState } from '../../store';
import { getUserPermissionService } from '../../store/permissions/selector';

interface RegistrationFieldsProps {
    customRegistrationFields: Core.Models.CustomRegistrationField[];
    customRegistrationFieldEntityType: Core.Models.CustomRegistrationFieldEntityType;
    customRegistrationFieldsEnabled: boolean;
    isAdministrator: boolean;
    reloadData: () => Promise<void>;
    title: string;
    uniqueKey: string;
}

const RegistrationFields: React.FunctionComponent<RegistrationFieldsProps> = (props) => {
    const { customRegistrationFields, customRegistrationFieldsEnabled, isAdministrator, title, uniqueKey } = props;

    const [fieldToEdit, setFieldToEdit] = useState<Core.Models.CustomRegistrationField | undefined>(undefined);
    const [fieldToDelete, setFieldToDelete] = useState<Core.Models.CustomRegistrationField | undefined>(undefined);

    const deleteField = async () => {
        if (!fieldToDelete) return;
        await LeagueService.deleteLeagueCustomRegistrationField(fieldToDelete.id);
        setFieldToDelete(undefined);
        await props.reloadData();
    };

    return (
        <ContentContainer className="global-container-centered" shade={Core.Models.Shades.Dark40}>
            <h3 className="heading-3">{title}</h3>

            {!customRegistrationFieldsEnabled && !isAdministrator && (
                <div>Your league does not support custom registration fields.</div>
            )}

            {(customRegistrationFieldsEnabled || isAdministrator) && (
                <>
                    <AddButton
                        as="button"
                        buttonStyle="solid"
                        buttonSize="medium"
                        className="mb3x"
                        onClick={() =>
                            setFieldToEdit(
                                new Core.Models.CustomRegistrationField({
                                    entityType: props.customRegistrationFieldEntityType,
                                })
                            )
                        }
                    >
                        Add
                    </AddButton>
                    <Formik
                        initialValues={{}}
                        onSubmit={() => {}}
                        render={() => (
                            <Form className="form">
                                {!!customRegistrationFields &&
                                    customRegistrationFields.length > 0 &&
                                    customRegistrationFields.map((field, ix) => {
                                        return (
                                            <div key={field.id} className="disp-flex align-center">
                                                <div className="flex-grow">
                                                    <CustomRegistrationFormField
                                                        fieldArrayName={`customRegistrationFields${uniqueKey}`}
                                                        fieldSubmissionResponse={
                                                            new Core.Models.CustomRegistrationFieldSubmissionResponse(
                                                                field,
                                                                ''
                                                            )
                                                        }
                                                        index={ix}
                                                    />
                                                </div>
                                                <div className="disp-flex align-center">
                                                    <IconButton
                                                        as="button"
                                                        buttonLabel="Edit custom field"
                                                        buttonSize="medium"
                                                        className="mb2x mr ml2x"
                                                        onClick={() => setFieldToEdit(field)}
                                                    >
                                                        <FontAwesomeIcon icon={['fas', 'edit']} />
                                                    </IconButton>
                                                    <IconButton
                                                        as="button"
                                                        buttonLabel="Delete custom field"
                                                        buttonSize="medium"
                                                        className="mb2x"
                                                        onClick={() => {
                                                            setFieldToDelete(field);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={['fas', 'trash-can']} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </Form>
                        )}
                    />

                    {customRegistrationFields && customRegistrationFields.length <= 0 && (
                        <p>No custom registration fields found for this league.</p>
                    )}

                    {fieldToEdit && (
                        <Modal onClose={() => setFieldToEdit(undefined)} title="Edit registration field">
                            <EditRegistrationField
                                customRegistrationField={fieldToEdit}
                                reloadData={props.reloadData}
                            />
                        </Modal>
                    )}

                    {fieldToDelete && (
                        <ConfirmModal
                            confirmText="Yes"
                            onCancel={() => setFieldToDelete(undefined)}
                            onConfirm={deleteField}
                            title="Are you sure"
                        >
                            <p>
                                Are you sure you want to delete <em>{fieldToDelete.name}</em>?
                            </p>
                        </ConfirmModal>
                    )}
                </>
            )}
        </ContentContainer>
    );
};

export default connect((state: AppState) => {
    const league = state.leagueState.league!.league;
    const userPermissionService = getUserPermissionService(state);

    return {
        isAdministrator: userPermissionService.getIsAdmin(),
        customRegistrationFieldsEnabled: typeof league !== 'undefined' && league.customRegistrationFieldsEnabled,
    };
}, {})(
    withLoadDataDefaultConfig(
        errorLoadingWrapperHOC<RegistrationFieldsProps>(RegistrationFields, { loadingOptions: { blockItem: true } }),
        (props: RegistrationFieldsProps) => props,
        async (props: RegistrationFieldsProps) => {
            const userCustomRegistrationFields = await LeagueService.getLeagueCustomRegistrationFields(
                Core.Models.CustomRegistrationFieldEntityType.User
            );

            return {
                ...props,
                customRegistrationFields: userCustomRegistrationFields,
                customRegistrationFieldEntityType: Core.Models.CustomRegistrationFieldEntityType.User,
                title: 'Custom user registration fields',
                uniqueKey: Core.Models.CustomRegistrationFieldEntityType.User.toString(),
            };
        }
    )
);

import { EMPTY_STRING } from '../constants';

export class ServerRoute {
	private constructor(controller: string, action?: string) {
		this.controller = controller;
		this.action = action;

		if (!this.controller) {
			throw new Error(`Expected controller argument to be defined.`);
		}
	}

	public static forAction(controller: string, action?: string): ServerRoute {
		const result: ServerRoute = new ServerRoute(controller, action);

		result.fullUrl = '/' + controller + (action ? '/' + action : '');

		return result;
	}

	private readonly controller: string;
	private readonly action?: string;
	private fullUrl?: string;

	public url(): string {
		return this.fullUrl || EMPTY_STRING;
	}
}

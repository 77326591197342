import React, { forwardRef, useImperativeHandle, useRef, useMemo } from 'react';
import classNames from 'classnames';
import { isUndefined, uniqueId } from 'lodash';

import InfoMessage from '../../../components/infoMessage';
import { SelectProps } from '../Inputs.types';

import './Select.scss';

const Select = forwardRef((props: SelectProps, forwardedRef) => {
    const {
        className,
        containerClassName,
        inputClassName,
        error,
        errorMessage,
        id,
        label,
        layoutInline,
        value,
        ...rest
    } = props;
    const labelRef = useRef<HTMLLabelElement>(null);
    const inputRef = useRef<HTMLSelectElement>(null);
    useImperativeHandle(forwardedRef, () => inputRef.current);
    const inputClassNames = classNames(inputClassName, 'ds-select-field__wrapper__input', {
        'ds-select-field__wrapper__input--has-value': !isUndefined(value),
    });

    const inputId = useMemo(() => id || uniqueId('select-input-'), [id]);
    const detailsId = useMemo(() => uniqueId('select-input-details-'), []);

    return (
        <div
            className={classNames(className, 'ds-select-field', { 'fit-content': layoutInline })}
            style={{ minWidth: `${labelRef.current && (labelRef.current.clientWidth + 64) / 10}rem` }}
        >
            <div
                className={classNames('ds-select-field__wrapper', containerClassName, {
                    'ds-select-field__wrapper--error': error || errorMessage,
                })}
            >
                <select
                    {...rest}
                    aria-describedby={`${detailsId} ${inputId}`}
                    className={inputClassNames}
                    form={undefined}
                    id={inputId}
                    ref={inputRef}
                    value={value}
                />
                <label htmlFor={inputId} ref={labelRef} className="ds-select-field__wrapper__label">
                    {label}
                </label>
            </div>
            {props['aria-describedby'] && (
                <p id={detailsId} className="ds-select-field__message ds-select-field__message--helpText">
                    {props['aria-describedby']}
                </p>
            )}
            {errorMessage && (
                <InfoMessage
                    className="ds-select-field__message ds-select-field__message--error"
                    type="error"
                    message={errorMessage}
                />
            )}
        </div>
    );
});

export default Select;

import { AppAction } from '..';

export interface StartupState {
    isPlatform: boolean;
}

export const STARTUP_SUCCESS = 'STARTUP_SUCCESS';

export interface StartupSuccess extends AppAction<boolean, typeof STARTUP_SUCCESS> {
    type: typeof STARTUP_SUCCESS;
    payload: boolean;
}

export type StartupActionTypes = StartupSuccess;

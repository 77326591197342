import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertNumberToCurrency } from '@leaguespot/platform-js';
import { Payable, PayableFulfillment } from '@leaguespot/platform-js/src/models';
import FulfillPayable from './fulfillPayable';
import { TextInput } from '../../components/inputs';
import { Rule } from '../buttons-visuals/rule';
import InfoMessage from '../infoMessage';
import { Radio } from '../inputs/radio';

import * as Core from '../../core';

interface ReviewPayableProps {
    canBypassPayable?: boolean;
    canDeferPayment?: boolean;
    etherialMode?: boolean;
    hasFulfilledPayable: boolean;
    onAddFulfillment?: (payableFulfillment: PayableFulfillment) => void;
    onPaymentMethodSelected?: (paymentMethodId: string) => void;
    payable: Payable;
    teamId?: string;
    userId?: string;
    organizationId?: string;
}

const ReviewPayable = ({
    canBypassPayable,
    canDeferPayment,
    etherialMode,
    hasFulfilledPayable,
    onAddFulfillment,
    onPaymentMethodSelected,
    payable,
    teamId,
    userId,
    organizationId,
}: ReviewPayableProps) => {
    const [payNow, setPayNow] = useState<boolean>(true);

    return (
        <div className="review-payable">
            <div className="disp-flex align-center">
                <TextInput
                    className="flex-grow"
                    disabled={true}
                    id="entryFee"
                    label="Entry fee"
                    type="text"
                    value={`${payable.name} - ${!!canBypassPayable ? '$0.00' : convertNumberToCurrency(Core.Utils.isDiscounted(payable) ? payable.discountFee! : payable.fee)}`}
                />
                {(hasFulfilledPayable || !!canBypassPayable) && (
                    <FontAwesomeIcon className="color-success mb2x ml2x" icon={['fas', 'circle-check']} size="1x" />
                )}
            </div>

            {!!canBypassPayable && <InfoMessage message="This team is exempt from this entry fee" type="info" />}

            {!hasFulfilledPayable && !!canDeferPayment && (
                <ul>
                    <li>
                        <Radio
                            checked={!payNow}
                            id="payLater"
                            label="Pay later"
                            name="payLater"
                            onChange={() => {
                                setPayNow(false);
                                onPaymentMethodSelected?.('');
                            }}
                            value="payLater"
                        />
                    </li>
                    {!payNow && (
                        <InfoMessage
                            message="You will not be able to participate in any competitions until you pay the entry fee from your profile."
                            type="info"
                        />
                    )}
                    <Rule type="horizontal" />
                    <li>
                        <Radio
                            checked={payNow}
                            id="payNow"
                            label="Pay now"
                            name="payNow"
                            onChange={() => setPayNow(true)}
                            value="payNow"
                        />
                    </li>
                </ul>
            )}

            {payNow && !hasFulfilledPayable && !canBypassPayable && (
                <div className="ml3x mr3x">
                    <FulfillPayable
                        {...{
                            etherialMode,
                            onPaymentMethodSelected,
                            teamId,
                            userId,
                        }}
                        onCreated={onAddFulfillment}
                        payableEntityType={payable.entityType}
                        payableId={payable.id}
                        organizationId={organizationId}
                    />
                </div>
            )}
        </div>
    );
};

export default ReviewPayable;

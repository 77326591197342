import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orderBy } from 'lodash';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';

import * as Core from '../../core';
import AssignGroupToStation from './assignGroupToStation';
import Match from './match';
import MatchSchedule from './matchSchedule';
import RescheduleGroup from './rescheduleGroup';
import { IconButton, TertiaryButton } from '../../components/buttons-visuals';
import Modal from '../../components/modal';
import { useTimezone } from '../../hooks/store';
import { GroupService } from '../../services/groupService';
import { getMyMatchSorters, getIsMyMatch, getIsMyParticipant } from '../../store/selectors/myParticipant';

interface GroupProps {
    group: Core.Models.Group; // Groups must contain matches.
    groupSelector: React.ReactNode;
    locations: Core.Models.LeagueLocation[];
    matchWord: string;
    reloadData: () => Promise<void>;
    rounds: Core.Models.Round[];
    stage: Core.Models.Stage;
    tennisStyle: boolean;
}

const Group = ({
    group,
    groupSelector,
    locations,
    matchWord,
    reloadData,
    rounds: inputRounds,
    stage,
    tennisStyle,
}: GroupProps) => {
    const [isRescheduling, setIsRescheduling] = useState<Core.Models.Round | undefined>(undefined);
    const [isAssigningStation, setIsAssigningStation] = useState<boolean>(false);
    const timezone = useTimezone();

    const myMatchSorters = useSelector(getMyMatchSorters);
    const isMyMatch = useSelector(getIsMyMatch);
    const isMyParticipant = useSelector(getIsMyParticipant);

    const rounds = useMemo(
        () =>
            orderBy(inputRounds, [(r: Core.Models.Round) => r.startTimeUtc, (r: Core.Models.Round) => r.sortOrder]).map(
                (r: Core.Models.Round) =>
                    ({
                        ...r,
                        matches: orderBy(
                            group
                                .matches!.filter((m: Core.Models.Match) => m.roundId === r.id)
                                .filter((m: Core.Models.Match) => m.matchParticipants.length >= 2),
                            [
                                ...myMatchSorters,
                                (m: Core.Models.Match) => m.startTimeUtc,
                                (m: Core.Models.Match) => m.sortOrder,
                            ]
                        ),
                    }) as Core.Models.Round
            ),
        [group.matches, inputRounds, myMatchSorters]
    );

    const location = useMemo(
        () => locations.find((l: Core.Models.LeagueLocation) => l.id === stage.settings.locationId),
        [locations, stage.settings.locationId]
    );

    return (
        <div className="group">
            <div className="group__header mb2x">
                <TertiaryButton as="a" href="#rankings">
                    View Rankings
                </TertiaryButton>
                {!!location && (
                    <IconButton
                        as="button"
                        buttonLabel="Assign group to station"
                        buttonSize="large"
                        onClick={() => setIsAssigningStation(true)}
                    >
                        <FontAwesomeIcon icon={['fas', 'computer']} />
                    </IconButton>
                )}
                <div className="group__header-group-selector">{groupSelector}</div>
            </div>
            {rounds.map((r: Core.Models.Round) => (
                <MatchSchedule
                    title={`${matchWord} Schedule ${r.name}`}
                    controls={
                        <IconButton
                            as="button"
                            buttonLabel="Reschedule matches"
                            buttonSize="large"
                            onClick={() => setIsRescheduling(r)}
                        >
                            <FontAwesomeIcon icon={['fas', 'clock-rotate-left']} />
                        </IconButton>
                    }
                    key={r.id}
                >
                    {!r.matches || r.matches.length === 0 ? (
                        <p className="mb0">
                            There are no {pluralize(matchWord.toLowerCase())} scheduled for this group yet.
                        </p>
                    ) : (
                        r.matches.map((match: Core.Models.Match) => (
                            <Match
                                key={match.id}
                                myMatch={isMyMatch(match.matchParticipants)}
                                {...{
                                    isMyParticipant,
                                    locations,
                                    match,
                                    matchWord,
                                    stage,
                                    tennisStyle,
                                    timezone,
                                }}
                            />
                        ))
                    )}
                </MatchSchedule>
            ))}
            {!!isRescheduling && (
                <Modal
                    className="round__edit-group"
                    onClose={() => setIsRescheduling(undefined)}
                    title={`Reschedule ${pluralize(matchWord.toLowerCase())} for ${group.name}`}
                >
                    <RescheduleGroup
                        includeBracket
                        initialValues={{ roundId: isRescheduling.id }}
                        matchWord={matchWord}
                        onSubmit={async (values) => {
                            await GroupService.reschedule({
                                id: group.id,
                                ...values,
                            });
                            await reloadData();
                            setIsRescheduling(undefined);
                        }}
                        rounds={rounds}
                    />
                </Modal>
            )}
            {isAssigningStation && (
                <Modal onClose={() => setIsAssigningStation(false)} title="Assign group to station">
                    <AssignGroupToStation
                        group={group}
                        onSubmit={async (values: Core.Models.AssignGroupToStationRequest) => {
                            await GroupService.assignToStation(values);
                            await reloadData();
                            setIsAssigningStation(false);
                        }}
                        stage={stage}
                        stations={location?.stations ?? []}
                    />
                </Modal>
            )}
        </div>
    );
};

export default Group;

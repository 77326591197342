import React, { useCallback, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethod, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import PaymentGatewayService from '../../services/paymentGatewayService';
import { SolidButton } from '../buttons-visuals';
import InfoMessage from '../infoMessage';
import { Checkbox } from '../inputs';

import './createPaymentMethod.scss';

interface CreatePaymentMethodProps {
    disableSave?: boolean;
    forceSave?: boolean;
    onPaymentMethodCreated: (paymentMethod: PaymentMethod) => void;
    paymentGatewayId: string;
}

const CreatePaymentMethod = ({
    disableSave,
    forceSave,
    onPaymentMethodCreated,
    paymentGatewayId,
}: CreatePaymentMethodProps) => {
    const stripe = useStripe()!;
    const elements = useElements();

    const [complete, setComplete] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [savePaymentMethod, setSavePaymentMethod] = useState<boolean>(!disableSave); // checked by default if `disableSave` is turned off

    const handleSubmit = useCallback(async () => {
        if (!stripe || !elements) return;
        if (!complete) return;
        if (!!error) return;

        setIsSubmitting(true);

        try {
            const { error: paymentMethodCreateError, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement)!,
            });

            if (!!paymentMethodCreateError) {
                toast.error(paymentMethodCreateError.message);
                return;
            }

            if (savePaymentMethod) {
                await PaymentGatewayService.createPaymentMethod({
                    paymentMethodId: paymentMethod.id,
                    paymentGatewayId,
                });
            }

            onPaymentMethodCreated(paymentMethod);
        } catch (e) {
            const message = Core.API.getErrorMessage(e);
            toast.error(`There was an issue creating this payment method: ${message}`);
        } finally {
            setIsSubmitting(false);
        }
    }, [complete, elements, error, paymentGatewayId, savePaymentMethod, setIsSubmitting, stripe]);

    return (
        <div className="create-payment-method">
            <fieldset className="form-group p">
                <CardElement
                    className="mb2x"
                    onChange={(event: StripeCardElementChangeEvent) => {
                        setError(undefined);

                        if (!!event.error) {
                            setError(event.error.message);
                            return;
                        }

                        setComplete(event.complete);
                    }}
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                            },
                        },
                    }}
                />
                {!disableSave && !forceSave && (
                    <Checkbox
                        checked={savePaymentMethod}
                        label="Save payment method for future use"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setSavePaymentMethod(event.target.checked)
                        }
                    />
                )}
            </fieldset>

            <InfoMessage message={error} type="error" />

            <fieldset className="form-group form-group--undecorated">
                <SolidButton
                    as="button"
                    className="mt2x full-width"
                    disabled={!stripe || !complete}
                    onClick={handleSubmit}
                    pending={isSubmitting}
                >
                    Create card
                </SolidButton>
            </fieldset>
        </div>
    );
};

export default CreatePaymentMethod;

import React, { useEffect, useState } from 'react';

import * as Core from '../../../core';
import StandingsBySeason from '../../../components/standingsBySeason';
import withLoading, { WithLoadingProps } from '../../../components/withLoading';
import { OrganizationService } from '../../../services/organizationService';

import './index.scss';

interface OrganizationStandingsProps extends WithLoadingProps {
    organizationId: string;
}

const OrganizationStandings = ({ organizationId, setError, setIsLoading }: OrganizationStandingsProps) => {
    const [standings, setStandings] = useState<Core.Models.RelevantGroupMergedStandingsResult | undefined>(undefined);

    useEffect(() => {
        (async () => {
            try {
                const response = await OrganizationService.getStandings(organizationId);
                setStandings(response);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [organizationId]);

    if (!standings) return <></>;
    return (
        <div className="organization-standings">
            <div className="organization-standings__header">
                <h4 className="heading-2">Current Standings</h4>
            </div>

            <StandingsBySeason {...{ standings }} />
        </div>
    );
};

export default withLoading(OrganizationStandings, {
    errorDisplayPageProps: { fullPage: true },
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

import React, { useEffect, useMemo } from 'react';
import * as QueryString from 'query-string';

import * as Core from '../core';
import { ContentContainer } from '../components/containers';
import Loading from '../components/loading';

const Redirector = () => {
    const { to } = useMemo(() => QueryString.parse(location.search), [location.search]);

    useEffect(() => {
        if (!to) return;
        window.location.href = to.toString();
    }, [to]);

    return (
        <ContentContainer
            className="global-container-centered"
            radius={Core.Models.RadiusSizes.Large}
            shade={Core.Models.Shades.Dark40}
            style={{ marginTop: '10rem' }}
        >
            <div className="pt2x text-center">
                <Loading blockItem />
                <h3 className="heading-3 mt4x text-center ">Redirecting...</h3>
                {!!to && (
                    <p className="text-small mt4x">
                        If you are not redirected after a few seconds, please proceed to{' '}
                        <a href={to.toString()}>{to.toString()}</a>.
                    </p>
                )}
            </div>
        </ContentContainer>
    );
};

export default Redirector;

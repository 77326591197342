import { AppAction } from '..';

export interface ConsentState {
    lastUpdatedUtc?: number;
    twitch: boolean;
}

export const CONSENT_SUCCESS = 'CONSENT_SUCCESS';

export interface ConsentSuccess extends AppAction<ConsentState, typeof CONSENT_SUCCESS> {
    payload: ConsentState;
    type: typeof CONSENT_SUCCESS;
}

export type ConsentActionTypes = ConsentSuccess;

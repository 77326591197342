import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import * as Core from '../../../../core';
import { ReactComponent as MsTeamsIcon } from './icons/ms-teams.svg';
import { Button } from '../../../../components/button';
import ConfirmModal from '../../../../components/confirmModal';
import { MatchService } from '../../../../services/matchService';

import './index.scss';

interface MatchLinkProps {
    canEditLinks: boolean;
    link: Core.Models.MatchLink;
    reloadData: (showLoading: boolean) => Promise<void>;
}

const MatchLink = (props: MatchLinkProps): JSX.Element => {
    const { canEditLinks, link, reloadData } = props;

    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const deleteMatchLink = useCallback(async () => {
        try {
            await MatchService.deleteMatchLink(link.id);
            toast.success('Deleted match link');
            setIsDeleting(false);
            await reloadData(false);
        } catch (e) {
            toast.error('Unable to delete match link');
        }
    }, [link.id, reloadData]);

    switch (link.type) {
        case Core.Models.MatchLinkType.TeamsMeeting:
            return (
                <div className="match-link ms-teams">
                    <Button
                        className="cta"
                        onClick={() => window.open(link.url, '_blank')}
                        semiRound
                        styleType={Core.Models.StyleType.OutlineOnly}
                    >
                        <span>
                            <MsTeamsIcon />
                        </span>{' '}
                        <span>Join Teams Meeting</span>
                    </Button>

                    {canEditLinks && (
                        <div>
                            <Button styleType={Core.Models.StyleType.Secondary} onClick={() => setIsDeleting(true)}>
                                Delete
                            </Button>

                            {isDeleting && (
                                <ConfirmModal
                                    confirmText="Yes"
                                    onCancel={() => setIsDeleting(false)}
                                    onConfirm={deleteMatchLink}
                                    title="Are you sure?"
                                >
                                    <p>Are you sure you want to delete this link?</p>
                                </ConfirmModal>
                            )}
                        </div>
                    )}
                </div>
            );
        default:
            return <></>;
    }
};

export default MatchLink;

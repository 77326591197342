import React from 'react';

import { ReactComponent as ErrorRemote } from './error-remote.svg';
import { useIsPlatform } from '../../hooks/store';
import { SolidButton } from '../buttons-visuals';

export interface ErrorDisplayPageProps {
    fullPage?: boolean;
    message?: string;
    title?: string;
}

const ErrorDisplayPage = ({ fullPage, message, title }: ErrorDisplayPageProps) => {
    const isPlatform = useIsPlatform();

    return (
        <div className="global-container-centered text-center mt8x">
            <ErrorRemote fill="currentColor" />
            <h1 className="heading-1 mt4x">{title || 'An error has occurred'}</h1>
            {message && <p>{message}</p>}
            {!isPlatform && (
                <>
                    <p>Please try again or contact your league host.</p>
                    <SolidButton as="a" className="mb4x" href="/league" size="medium">
                        Head back to the league
                    </SolidButton>
                </>
            )}
        </div>
    );
};

export default ErrorDisplayPage;

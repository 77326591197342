import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Core from '../../core';
import { ReactComponent as DiscordJoin } from '../../assets/images/icons/discord-join.svg';
import { useHasAnyLeagueMembershipRole, useLeague } from '../../hooks/store';
import { IconButton } from '../buttons-visuals';

const DiscordLink = (): JSX.Element => {
    const league = useLeague();
    const isLeagueMember = useHasAnyLeagueMembershipRole(Core.Models.PermissionLevel.ListSpecific);

    if (!league?.discordUrl || !isLeagueMember) return <></>;

    return (
        <IconButton
            as="a"
            buttonLabel="Join us on Discord"
            buttonSize="large"
            className="header__discord-link"
            href={league?.discordUrl}
            rel="noopener noreferrer"
            target="_blank"
        >
            <>
                <FontAwesomeIcon className="hide-for-large" icon={['fab', 'discord']} />
                <span className="show-for-large disp-flex align-center">
                    <DiscordJoin />
                </span>
            </>
        </IconButton>
    );
};

export default DiscordLink;

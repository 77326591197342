import React, { ReactNode } from 'react';

import * as Core from '../../core';
import { useIdentityVerificationList } from './hooks';
import { TabItem } from './types';
import Paginator from '../../components/paginator';
import TabNav from '../../components/tabNav';

import './styles.scss';

interface IdentityVerificationListProps {
    activeTabIndex: number;
    setActiveTabIndex: (ii: number) => void;
    emptyMessage?: string;
    items: Core.Models.IdentityVerificationSubmissionSummary[];
    renderItem: (item: Core.Models.IdentityVerificationSubmissionSummary) => ReactNode;
    tabs: TabItem[];
}

export const IdentityVerificationList = ({
    activeTabIndex,
    setActiveTabIndex,
    emptyMessage,
    items,
    renderItem,
    tabs,
}: IdentityVerificationListProps) => {
    const { currentItems, onIncrementPage, onSelectPage, page, pageSize, totalCount } =
        useIdentityVerificationList(items);
    return (
        <div className="identity-verification__list">
            <TabNav
                items={tabs.map((navItem: TabItem, index: number) => ({
                    contents: <span>{navItem.name}</span>,
                    id: index,
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault();
                        setActiveTabIndex(index);
                        onSelectPage(1);
                    },
                    name: navItem.name,
                }))}
                current={activeTabIndex}
            />
            <ul className="identity-verification__list__header">
                <li>First Name</li>
                <li>Last Name</li>
                <li>DOB</li>
                <li>Email</li>
                <li>Discord</li>
                <li>Action</li>
            </ul>
            <ul className="identity-verification__list__items">
                {currentItems.length > 0 ? (
                    currentItems.map((item: Core.Models.IdentityVerificationSubmissionSummary) => renderItem(item))
                ) : (
                    <li className="identity-verification__list__items--empty">{emptyMessage ?? 'Nothing to show.'}</li>
                )}
            </ul>
            {totalCount > pageSize && (
                <Paginator
                    onIncrementPage={onIncrementPage}
                    onSelectPage={onSelectPage}
                    page={page}
                    pageCount={Math.ceil(totalCount / pageSize)}
                    pageSize={pageSize}
                    totalCount={totalCount}
                />
            )}
        </div>
    );
};

import React, { useEffect, useState } from 'react';

import './index.scss';

interface DeckImageProps {
    name: string;
}

const imageBaseUrl = '/static/media/titles/hearthstone';

const DeckImage = (props: DeckImageProps): JSX.Element => {
    const { name } = props;
    const [src, setSrc] = useState<string>(`${imageBaseUrl}/${name.toLowerCase()}.jpg`);

    useEffect(() => setSrc(`${imageBaseUrl}/${name.toLowerCase()}.jpg`), [name]);

    return (
        <img
            alt={`${name} deck`}
            onError={() => setSrc(`${imageBaseUrl}/fallback.png`)}
            src={src}
            title={`${name} deck`}
        />
    );
};

export default DeckImage;

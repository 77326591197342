import * as React from 'react';

import Markdown from '../markdown';

export interface JsonDebugProps {
    show?: boolean;
    data: any;
}

const JsonDebug: React.FunctionComponent<JsonDebugProps> = (props) => {
    const shouldRender = props.show || (process.env.NODE_ENV === 'development' && props.show !== false);
    return shouldRender ? <Markdown source={JSON.stringify(props.data, undefined, 2)} /> : <></>;
};
export default JsonDebug;

import React from 'react';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import { ModifyLocationForm } from './modifyLocationForm';
import {
    CreateLocationModalProps,
    DeleteLocationModalProps,
    ModifyLocationFormActions,
    ModifyLocationFormValues,
    UpdateLocationModalProps,
} from './types';
import { useConfirmModal } from '../../hooks/confirmModal';
import { useModal } from '../../hooks/modal';
import { LeagueService } from '../../services/leagueService';

export const useCreateLocationModal = ({ onSubmitted }: CreateLocationModalProps) => {
    const modal = useModal(
        () => 'Add Location',
        (close) => (
            <ModifyLocationForm
                create
                onSubmit={async (values: ModifyLocationFormValues, actions: ModifyLocationFormActions) => {
                    try {
                        actions.setStatus(undefined);
                        const { location } = await LeagueService.createLeagueLocation({
                            ...values,
                        });
                        onSubmitted?.(location);
                        close();
                    } catch (e) {
                        const message = Core.API.getErrorMessage(e);
                        actions.setStatus(message);
                    } finally {
                        actions.setSubmitting(false);
                    }
                }}
            />
        )
    );
    return modal;
};

export const useDeleteLocationModal = ({ locationId, onDeleted }: DeleteLocationModalProps) => {
    const modal = useConfirmModal(
        () => 'Remove Location',
        () => <p>Are you sure you want to remove this location?</p>,
        async (close) => {
            if (!locationId) return;
            try {
                await LeagueService.deleteLeagueLocation(locationId);
                onDeleted?.(locationId);
                close();
            } catch (e) {
                const message = Core.API.getErrorMessage(e);
                toast.error(message);
            }
        },
        {
            cancelText: 'Nevermind',
            confirmText: 'Yes',
        }
    );
    return modal;
};

export const useUpdateLocationModal = ({ locationId, onDelete, onSubmitted, values }: UpdateLocationModalProps) => {
    const modal = useModal(
        () => 'Edit Location',
        (close) => (
            <ModifyLocationForm
                onDelete={() => {
                    close();
                    locationId && onDelete(locationId);
                }}
                onSubmit={async (values: ModifyLocationFormValues, actions: ModifyLocationFormActions) => {
                    if (!locationId) return;
                    try {
                        actions.setStatus(undefined);
                        const { location } = await LeagueService.updateLeagueLocation({
                            ...values,
                            locationId,
                        });
                        onSubmitted?.(location);
                        close();
                    } catch (e) {
                        const message = Core.API.getErrorMessage(e);
                        actions.setStatus(message);
                    } finally {
                        actions.setSubmitting(false);
                    }
                }}
                values={values}
            />
        )
    );
    return modal;
};

import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';

import * as Core from '../../../core';
import withLoading, { WithLoadingProps } from '../../../components/withLoading';
import history from '../../../services/history';
import { SeasonService } from '../../../services/seasonService';
import EditSeason from '../editSeason';

import './seasonSettingsPage.scss';

interface SeasonSettingsPageRouteProps {
    seasonId: string;
}

interface SeasonSettingsPageProps extends WithLoadingProps, RouteComponentProps<SeasonSettingsPageRouteProps> {}

const SeasonSettingsPage = (props: SeasonSettingsPageProps) => {
    const {
        match: {
            params: { seasonId },
        },
        setError,
        setIsLoading,
    } = props;
    const [currentSeason, setCurrentSeason] = useState<Core.Models.Season | undefined>(undefined);

    useEffect(() => {
        (async () => {
            try {
                const response = await SeasonService.getById(seasonId);
                setCurrentSeason(response);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [seasonId, setError, setIsLoading]);

    if (!currentSeason) return <></>;

    return (
        <>
            <div className="season-page-image" style={{ backgroundImage: `url("${currentSeason?.game.heroUrl}")` }}>
                <div className="season-page-image-gradiant" />
            </div>
            <div className="season-page-form global-container-centered global-container-centered--wide">
                <EditSeason
                    season={currentSeason}
                    seasonId={seasonId}
                    onComplete={async () => {
                        history.push(`/seasons/${seasonId}`);
                    }}
                />
            </div>
        </>
    );
};

export default withLoading(SeasonSettingsPage, {
    errorDisplayPageProps: { fullPage: true },
    isLoadingByDefault: false,
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

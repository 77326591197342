import * as React from 'react';
import classNames from 'classnames';

import BackgroundImage from '../backgroundImage';

import './index.scss';

interface GameIconProps {
    game?: { iconUrl?: string };
    team?: boolean;
}

const GameIcon = (props: GameIconProps): JSX.Element => {
    const { game, team } = props;

    if (!game?.iconUrl) return <></>;
    return <BackgroundImage className={classNames('game-icon', { 'game-icon--team': !!team })} src={game.iconUrl} />;
};

export default GameIcon;

import * as React from 'react';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../core';
import { Button } from '../../components/button';
import ErrorMessage from '../../components/errorMessage';
import FormField from '../../components/formField';
import Loading from '../../components/loading';

interface InviteManagerProps {
    onSubmit: (email: string) => Promise<void>;
}

export interface InviteManagerFormValues {
    email: string;
}

const schema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be formatted like an email address'),
});

export class InviteManager extends React.Component<InviteManagerProps> {
    handleSubmit = async (values: InviteManagerFormValues, actions: FormikActions<InviteManagerFormValues>) => {
        try {
            await this.props.onSubmit(values.email);
        } catch (error) {
            const message = Core.API.getErrorMessage(error);
            actions.setStatus(message);
        }
        actions.setSubmitting(false);
    };

    public render(): JSX.Element {
        return (
            <div className="invite-manager">
                <Formik
                    initialValues={{ email: '' }}
                    onSubmit={this.handleSubmit}
                    validationSchema={schema}
                    render={(props: FormikProps<InviteManagerFormValues>) => (
                        <Form>
                            <fieldset className="form-group">
                                <FormField
                                    type="email"
                                    name="email"
                                    description="Manager email"
                                    placeholder="Manager email address"
                                    className="invite-manager__text-input"
                                />
                            </fieldset>

                            {props.status && <ErrorMessage error={props.status} />}
                            <ErrorMessage error={props.errors} filter={props.touched} />

                            <fieldset className="form-group form-group--undecorated">
                                {props.isSubmitting && <Loading buttonLoader />}
                                <Button onClick={props.submitForm} disabled={props.isSubmitting} wide round>
                                    Send Invites
                                </Button>
                            </fieldset>
                        </Form>
                    )}
                />
            </div>
        );
    }
}

import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { LoginRedirectService } from '../../services/loginRedirectService';
import { SolidButton } from '../buttons-visuals';

// interface can't extend something based on React.ComponentProps
interface LoginButtonProps {
    children: React.ReactNode;
    returnurl?: string;
    size?: 'xlarge' | 'large' | 'medium' | 'small';
}

const LoginButton = (props: LoginButtonProps): JSX.Element => {
    const { children, returnurl, size = 'medium' } = props;
    const location = useLocation();

    const getReturnUrlLocation = useCallback(() => {
        if (!returnurl) return location;

        return Object.assign(location, {
            pathname: returnurl,
        });
    }, [location, returnurl]);

    return (
        <SolidButton
            size={size}
            color="primary"
            as="link"
            to={LoginRedirectService.buildLoginUrl(getReturnUrlLocation() ?? location)}
        >
            {children}
        </SolidButton>
    );
};

export default LoginButton;

import React, { useCallback, useEffect, useState } from 'react';
import animateScrollTo from 'animated-scroll-to';
import classNames from 'classnames';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';

import * as Core from '../../core';
import leagueSpotLogo from '../../assets/images/logo.svg';
import Markdown from '../../components/markdown';
import Paginator from '../../components/paginator';
import { useAlternateSeasonName, useHasLeagueAccess, useLeague, useOrganizationTerm } from '../../hooks/store';

const BANNER_AUTOROTATE_INTERVAL = 3000;

interface LeagueDetailsProps {
    selectGame: (gameId: string) => void;
}

const LeagueDetails = (props: LeagueDetailsProps): JSX.Element => {
    const { selectGame } = props;

    const canEditLeague = useHasLeagueAccess(Core.Models.PermissionLevel.Edit);
    const league = useLeague();
    const seasonAlternateName = useAlternateSeasonName();
    const organizationTerm = useOrganizationTerm({ lowercase: true });

    const onLeagueGridItemClick = useCallback(
        (item: { titleId: string }) => {
            selectGame(item.titleId);
            const seasonsElement = document.getElementById('seasons');
            if (!!seasonsElement) animateScrollTo(seasonsElement, { speed: 250 });
        },
        [selectGame]
    );

    if (!!league?.bannerUrl) {
        return (
            <div className={classNames('league-page__banner', { 'has-grid': !!league.grid })}>
                <img alt="" className="banner" src={league.bannerUrl} />
                <div
                    className="league-page__banner__backdrop"
                    style={{ backgroundImage: `url("${league.bannerUrl}")` }}
                />

                {!!league?.grid && (
                    <div className="league-page__banner__league-grid">
                        <BannerImageRotator
                            onLeagueGridItemClick={onLeagueGridItemClick}
                            rotatorItems={league.grid.rotatorItems}
                        />
                        {league.grid.gridItems.map((item: Core.Models.LeagueGridItem) => (
                            <div
                                className="league-page__banner__league-grid__grid-item"
                                key={item.url}
                                onClick={() => onLeagueGridItemClick(item)}
                            >
                                <img alt="" src={item.url} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="league-page__details">
            <div className="league-page__details__logo">
                <img src={league?.logoUrl ?? leagueSpotLogo} alt=""></img>
            </div>
            {league && (
                <div className="league-page__details__message">
                    <h2 className="league-page__details__header">Welcome to {league.name}</h2>
                    <div className="league-page__details__description">
                        {league.description || !canEditLeague ? ( // description is visible to anyone who cannot edit the league if it exists
                            <Markdown source={league.description || ''} />
                        ) : (
                            // if it does not exist, show instructions to anyone who can edit the league. otherwise this section will be blank
                            <div className="league-page__details__description__host-instructions">
                                <h3>Please set up your league now!</h3>
                                <em>
                                    Note: this message is only visible to league hosts and will be replaced when you set
                                    a league description or banner image
                                </em>

                                <h4>
                                    The <Link to={`/league-settings`}>league settings page</Link> allows you to
                                    customize your league:
                                </h4>
                                <ul>
                                    <li>
                                        Basic settings such as league name, description, contact information and website
                                        URL
                                    </li>
                                    <li>
                                        {seasonAlternateName} categories and manual {seasonAlternateName.toLowerCase()}{' '}
                                        ordering
                                    </li>
                                    <li>
                                        Connect your league to your Discord server and use the {Core.Constants.Company}{' '}
                                        bot
                                    </li>
                                    <li>Make your branding pop with custom league images</li>
                                    <li>
                                        Invite managers to set up their {pluralize(organizationTerm)} by retrieving your{' '}
                                        {organizationTerm} invite URL
                                    </li>
                                    <li>Set approved games that your league will play</li>
                                    <li>Add helpful links to the left navigation panel</li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

interface BannerImageRotatorProps {
    onLeagueGridItemClick: (item: { titleId: string }) => void;
    rotatorItems: Core.Models.LeagueGridItem[];
}

const BannerImageRotator = (props: BannerImageRotatorProps): JSX.Element => {
    const { onLeagueGridItemClick, rotatorItems } = props;

    const [active, setActive] = useState<number>(0);
    const [isAutoRotateEnabled, setIsAutoRotateEnabled] = useState<boolean>(true);

    const onIncrementPage = useCallback(
        (increment: number, automatic?: boolean) => {
            // don't auto-rotate if disabled
            if (!!automatic && !isAutoRotateEnabled) return;

            const incremented = active + increment;
            setActive(incremented < rotatorItems.length ? incremented : 0);
        },
        [active, isAutoRotateEnabled, rotatorItems.length]
    );

    // auto-rotate every `BANNER_AUTOROTATE_INTERVAL` milliseconds
    useEffect(() => {
        const interval = setTimeout(() => onIncrementPage(1, true), BANNER_AUTOROTATE_INTERVAL);
        return () => clearTimeout(interval);
    }, [onIncrementPage]);

    if (rotatorItems.length <= 0) return <></>;
    return (
        <div
            className="league-page__banner__league-grid__grid-item league-page__banner__league-grid__rotator"
            onMouseEnter={() => setIsAutoRotateEnabled(false)}
            onMouseLeave={() => setIsAutoRotateEnabled(true)}
        >
            {rotatorItems.map((item: Core.Models.LeagueGridItem, index: number) => (
                <div
                    className={classNames('item', { active: active === index })}
                    key={item.url}
                    onClick={() => onLeagueGridItemClick(item)}
                >
                    <img alt="" src={item.url} />
                </div>
            ))}
            <div className="league-page__banner__league-grid__rotator__dots">
                <Paginator
                    hideItemNumbers
                    hideItemsSummary
                    onIncrementPage={onIncrementPage}
                    onSelectPage={(nextPage: number) => setActive(nextPage - 1)}
                    page={active + 1}
                    pageCount={rotatorItems.length}
                    pageSize={0}
                    totalCount={0}
                />
            </div>
        </div>
    );
};

export default LeagueDetails;

import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { isUndefined } from 'lodash';
import moment from 'moment-timezone';

import * as Core from '../../../../core';
import { TitleScoringProps } from '.';
import AccountSelector, { Account } from './accountSelector';
import { HollowButton } from '../../../../components/buttons-visuals';
import { ContentContainer } from '../../../../components/containers';
import InfoMessage from '../../../../components/infoMessage';
import Loading from '../../../../components/loading';
import { useTimezone } from '../../../../hooks/store';
import IntegrationService from '../../../../services/integrationService';
import { ChessMatchStats } from '../../matchStats';

interface ChessScoringProps extends TitleScoringProps {
    matchId: string;
    matchParticipantUserIds: string[];
}

interface Month {
    display: string;
    month: string;
    year: string;
}

const MONTHS_TO_SHOW = 4;

const months: Month[] = (() => {
    const date = new Date();
    return [...Array(MONTHS_TO_SHOW)]
        .map((_, i: number) => {
            if (i > 0) date.setMonth(date.getMonth() - 1);
            const m = moment(date);
            return {
                display: m.format('MMM YYYY'),
                month: m.format('MM'),
                year: m.format('YYYY'),
            };
        })
        .reverse();
})();

const ChessScoring = ({ matchId, matchParticipantUserIds, setAutoScoringData }: ChessScoringProps): JSX.Element => {
    const [username, setUsername] = useState<string | undefined>(undefined);

    // clear auto scoring data if account isn't selected
    useEffect(() => {
        if (!username) setAutoScoringData(undefined);
    }, [username, setAutoScoringData]);

    return (
        <>
            <AccountSelector
                {...{
                    matchId,
                    matchParticipantUserIds,
                    onAccountSelected: (account: Account) => setUsername(account.username),
                }}
            />
            {username && <ChessMatchFinder {...{ matchId, setAutoScoringData, username }} />}
        </>
    );
};

interface ChessMatchFinderProps {
    matchId: string;
    setAutoScoringData: (data: string | undefined) => void;
    username: string;
}

const ChessMatchFinder = ({ matchId, setAutoScoringData, username }: ChessMatchFinderProps): JSX.Element => {
    const timezone = useTimezone();

    const [error, setError] = useState<string | undefined>(undefined);
    const [match, setMatch] = useState<Core.Models.ChessMatchSummary | undefined>(undefined);
    const [matches, setMatches] = useState<Core.Models.ChessMatchSummary[] | undefined>(undefined);
    const [month, setMonth] = useState<Month>(months[MONTHS_TO_SHOW - 1]);

    useEffect(() => {
        (async () => {
            setError(undefined);
            setMatches(undefined);
            setMatch(undefined);

            try {
                const data = await IntegrationService.getChessMatchHistory(matchId, username, month.year, month.month);
                setMatches(data);
            } catch (err) {
                setError('There was an issue loading matches. Please reopen this modal to try again.');
            }
        })();
    }, [matchId, month, username]);

    const selectMatch = useCallback(
        (m: Core.Models.ChessMatchSummary) => {
            setMatch(m);
            setAutoScoringData(
                JSON.stringify({
                    month: month.month,
                    uuid: m.id,
                    year: month.year,
                    username,
                })
            );
        },
        [month, setAutoScoringData, username]
    );

    return (
        <>
            <div className="mb2x disp-flex justify-between">
                {months.map((m: Month) => (
                    <HollowButton
                        as="button"
                        className={classNames(m.display === month.display && 'active')}
                        key={m.display}
                        layout="inline"
                        onClick={() => setMonth(m)}
                        size="small"
                    >
                        {m.display}
                    </HollowButton>
                ))}
            </div>

            <ContentContainer className="match-list mb2x">
                {isUndefined(matches) ? (
                    <div className="p2x">
                        <p>Loading matches...</p>
                        <Loading />
                    </div>
                ) : (
                    <>
                        {matches.length <= 0 ? (
                            <div className="p2x">No matches found</div>
                        ) : (
                            <>
                                {matches.map((m: Core.Models.ChessMatchSummary) => (
                                    <button
                                        className={classNames(
                                            'match-list__item p2x selectable disp-flex align-center full-width',
                                            m.id === match?.id && 'selected'
                                        )}
                                        key={m.id}
                                        onClick={() => selectMatch(m)}
                                    >
                                        <div>
                                            <img
                                                className="mr2x"
                                                src="https://cdn.leaguespot.gg/static/media/titles/chess/chess.png"
                                                alt=""
                                                height="40"
                                                width="40"
                                            />
                                        </div>
                                        <div className="text-left">
                                            {renderPlayer(m.white, m.draw)}
                                            {renderPlayer(m.black, m.draw)}
                                            {renderEndTime(m.gameEndTimeUtc, timezone)}
                                            {renderTimeControl(m.timeClass, m.timeClassIconUrl, m.timeControl)}
                                        </div>
                                    </button>
                                ))}
                            </>
                        )}
                    </>
                )}
            </ContentContainer>

            {!!match && (
                <fieldset className="form-group match-summary mb2x">
                    <ContentContainer>
                        {!match ? (
                            <>
                                <p>Loading match...</p>
                                <Loading />
                            </>
                        ) : (
                            <>
                                {renderEndTime(match.gameEndTimeUtc, timezone)}
                                {renderTimeControl(match.timeClass, match.timeClassIconUrl, match.timeControl)}
                                <div className="disp-flex align-center mb">
                                    <FontAwesomeIcon icon={['fas', 'arrow-up-right-from-square']} className="mr" />
                                    <a href={match.url} target="_blank" rel="noopener noreferrer">
                                        View on Chess.com
                                    </a>
                                </div>

                                <hr />

                                <ChessMatchStats match={match} />
                            </>
                        )}
                    </ContentContainer>
                </fieldset>
            )}

            {!!error && <InfoMessage message={error} type="error" />}
        </>
    );
};

const renderEndTime = (gameEndTimeUtc: string, timezone: string) => (
    <div className="disp-flex align-center mb">
        <FontAwesomeIcon icon={['fas', 'calendar']} className="icon mr" />
        <div>{moment.tz(gameEndTimeUtc, timezone).format(Core.Time.getFormat(true))}</div>
    </div>
);

export const renderPlayer = (side: Core.Models.ChessMatchSide, draw: boolean) => (
    <div className="disp-flex align-center mb">
        <FontAwesomeIcon className={`icon mr color-${side.name.toLowerCase()}`} icon={['fas', 'chess-pawn']} />
        <div>
            <a
                className="weight-700"
                href={`https://www.chess.com/member/${side.username}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {side.username}
            </a>
            ({side.rating})<span className="weight-700 ml">{renderWTL(side.won, draw)}</span>
        </div>
    </div>
);

const renderTimeControl = (timeClass: string, timeClassIconUrl: string, timeControl?: string) => (
    <div className="disp-flex align-center mb">
        <div className="chess-time-icon">
            <img src={timeClassIconUrl} alt={timeClass} title={timeClass} />
        </div>
        <div>{timeControl}</div>
    </div>
);

export const getWTLClass = (won: boolean, draw: boolean) => {
    if (draw) return 'color-inherit';
    return won && 'color-primary';
};

export const renderWTL = (won: boolean, draw: boolean) => {
    if (draw) return 'D';
    return won ? 'W' : 'L';
};

export default ChessScoring;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps, Form } from 'formik';
import { orderBy } from 'lodash';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import * as Core from '../../core';
import { useLeagueConfiguration, useProfile } from '../../hooks/store';
import { LeagueService } from '../../services/leagueService';
import { TeamService } from '../../services/teamService';
import { SolidButton } from '../buttons-visuals';
import FormField from '../formField';
import InfoMessage from '../infoMessage';
import withLoading, { WithLoadingProps } from '../withLoading';

interface CreateTeamProps extends WithLoadingProps {
    allowRoleSelection: boolean;
    onSubmit: (gameId: string) => Promise<void>;
    organizationId: string;
}

export interface CreateTeamValues {
    gameId: string;
    name: string;
    teamRoleId: string;
}

const schema = Yup.object().shape({
    gameId: Yup.string().required('Game is required.'),
    name: Yup.string().required('Team name is required.').max(Core.Constants.NAME_MAX_LENGTH, 'Team name is too long.'),
    teamRoleId: Yup.string().required('Team role is required'),
});

const CreateTeam = ({ allowRoleSelection, organizationId, onSubmit, setIsLoading, setError }: CreateTeamProps) => {
    const [games, setGames] = useState<Core.Models.Game[]>([]);
    const sortedGames = useMemo(() => orderBy(games, (g: Core.Models.Game) => g.name), [games]);
    const leagueConfiguration = useLeagueConfiguration();
    const me = useProfile();

    const load = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await LeagueService.getLeagueApprovedGames();
            setGames(response);
        } catch (e) {
            const message = Core.API.getErrorMessage(e);
            setError(message);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        (async () => {
            await load();
        })();
    }, [load]);

    return (
        <Formik
            initialValues={{
                gameId: games.length === 1 ? games[0].id : '',
                name: '',
                teamRoleId: Core.Models.TeamRoleId.Captain,
            }}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
                const { gameId, name, teamRoleId } = values;
                actions.setStatus(undefined);
                try {
                    await TeamService.createTeam({ organizationId, gameId, name, teamRoleId });
                    await onSubmit(gameId);
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(formProps: FormikProps<CreateTeamValues>) => (
                <Form className="form">
                    <FormField type="text" name="name" label="Team name" />

                    <FormField component="select" name="gameId" label="Select game">
                        <option value="" hidden disabled>
                            --
                        </option>
                        {sortedGames.map((g: Core.Models.Game) => (
                            <option key={g.id} value={g.id}>
                                {g.name}
                            </option>
                        ))}
                    </FormField>

                    {allowRoleSelection && (
                        <FormField component="select" name="teamRoleId" description="Role">
                            <option value={Core.Models.TeamRoleId.Captain}>Captain</option>
                            <option value={Core.Models.TeamRoleId.Coach}>Coach</option>
                        </FormField>
                    )}

                    {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                    <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                    {!!me?.userId &&
                    !!leagueConfiguration?.leagueFee &&
                    !me.hasFulfilledPayable &&
                    formProps.values.teamRoleId !== Core.Models.TeamRoleId.Coach ? (
                        <InfoMessage
                            message={
                                <span>
                                    You cannot create a team until you pay the entry fee from{' '}
                                    <Link to={`/users/${me.userId}#league_fees`}>your profile</Link>.
                                </span>
                            }
                            type="error"
                        />
                    ) : (
                        <SolidButton
                            as="button"
                            className="mb2x"
                            color="primary"
                            layout="full"
                            onClick={formProps.submitForm}
                            pending={formProps.isSubmitting}
                            size="large"
                        >
                            Create team
                        </SolidButton>
                    )}
                </Form>
            )}
        />
    );
};

export default withLoading(CreateTeam, { loadingProps: { blockItem: true } });

import * as Core from '../../core';
import { ModalAction, TabItem } from './types';

export const actions: ModalAction[] = [
    {
        buttonStyle: {
            type: Core.Models.StyleType.Primary,
        },
        name: 'Save & Approve',
        newState: Core.Models.UserIdentityVerificationSubmissionState.Approved,
        primaryAction: true,
    },
    {
        buttonStyle: {
            outline: true,
        },
        name: 'Save & Request More Info',
        newState: Core.Models.UserIdentityVerificationSubmissionState.MoreInformationRequired,
    },
    {
        buttonStyle: {
            type: Core.Models.StyleType.Secondary,
        },
        name: 'Save & Reject',
        newState: Core.Models.UserIdentityVerificationSubmissionState.Rejected,
    },
];

export const tabs: TabItem[] = [
    { name: 'Inbox', state: Core.Models.UserIdentityVerificationSubmissionState.Submitted },
    { name: 'Needs more info', state: Core.Models.UserIdentityVerificationSubmissionState.MoreInformationRequired },
    { name: 'Rejected', state: Core.Models.UserIdentityVerificationSubmissionState.Rejected },
    { name: 'All' },
];

import React from 'react';
import { FeatureGroup } from "@leaguespot/platform-js/src/models";
import { useEffect } from "react";
import { useCookies, useLeague } from '../../hooks/store';
import AnalyticsService from "../../services/analyticsService";

const AddAmazonTag = (): JSX.Element => {
    const cookies = useCookies();
    const league = useLeague();

    useEffect(() => {
        if (league?.featureGroup === FeatureGroup.NACE) {
            AnalyticsService.addAmazonAdTag();
        }
    }, [cookies, league?.featureGroup])

    return <></>
}

export default AddAmazonTag;
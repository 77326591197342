import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import { IconButton } from '../../components/buttons-visuals';
import EsrbRating from '../../components/esrbRating';
import { LeagueService } from '../../services/leagueService';

import './addGame.scss';

interface AddGameProps {
    games: Core.Models.Game[];
    onComplete: () => Promise<void>;
}

const AddGame = (props: AddGameProps) => {
    const { games, onComplete } = props;
    const [submittingGameId, setSubmittingGameId] = useState<string | undefined>(undefined);

    const onAdd = useCallback(
        async (gameId: string) => {
            setSubmittingGameId(gameId);
            try {
                await LeagueService.addLeagueApprovedGame({ gameId });
                await onComplete();
            } catch (e) {
                const message = Core.API.getErrorMessage(e);
                toast.error(message);
            } finally {
                setSubmittingGameId(undefined);
            }
        },
        [onComplete]
    );

    return (
        <div className="add-game-modal">
            <div className="add-game-modal__list">
                {games.map((game: Core.Models.Game) => {
                    return (
                        <div key={game.id} className="add-game-modal__list__item">
                            <img className="add-game-modal__list__item__game-image" src={game.cardUrl} alt="" />
                            <EsrbRating rating={game.esrbRating} />
                            <p className="mb0">{game.name}</p>
                            <IconButton
                                as="button"
                                buttonLabel="Add Game"
                                buttonSize="xlarge"
                                className="add-game-modal__list__item__add-button"
                                disabled={!!submittingGameId}
                                pending={submittingGameId === game.id}
                                onClick={() => onAdd(game.id)}
                            >
                                <FontAwesomeIcon className="color-success" icon={['fas', 'circle-plus']} />
                            </IconButton>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default AddGame;

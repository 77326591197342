import AnalyticsService from './analyticsService';
import { CookiesState } from '../store/cookies/types';
import { FeatureGroup } from '@leaguespot/platform-js/src/models';

const LOCAL_STORAGE_COOKIES_PREFERENCES = 'cookies_preferences';

export default class CookiesService {
    public static getCookies(): CookiesState | undefined {
        const config = localStorage.getItem(LOCAL_STORAGE_COOKIES_PREFERENCES);
        if (!!config) {
            return JSON.parse(config);
        }
        return undefined;
    }

    public static setCookies(preferences: CookiesState, featureGroup: FeatureGroup) {
        localStorage.setItem(LOCAL_STORAGE_COOKIES_PREFERENCES, JSON.stringify({ ...preferences }));
        AnalyticsService.updatePerformanceCookiesPreference(preferences.performance, featureGroup);
    }
}

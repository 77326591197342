import * as Redux from 'redux';

import { ProfileState, ProfileActionTypes, PROFILE_LOADING, PROFILE_SUCCESS, PROFILE_FAILURE } from './types';
import { LOGIN_CLEAR_PRELOADED_DATA } from '../login/types';

const initialState: ProfileState = {
    isLoading: false,
};

const profileReducer: Redux.Reducer<ProfileState> = (
    state = initialState,
    action: ProfileActionTypes
): ProfileState => {
    switch (action.type) {
        case LOGIN_CLEAR_PRELOADED_DATA:
            return initialState;
        case PROFILE_LOADING:
            return {
                ...state,
                isLoading: true,
                loadingError: undefined,
            };
        case PROFILE_SUCCESS:
            const { payload } = action;
            return {
                ...state,
                isLoading: false,
                profile: payload,
                loadingError: undefined,
            };
        case PROFILE_FAILURE:
            return {
                ...state,
                isLoading: false,
                profile: undefined,
                loadingError: action.payload,
            };
        default:
            return state;
    }
};

export default profileReducer;

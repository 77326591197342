import React, { useCallback, useState } from 'react';

import * as Core from '../../core';
import NotFoundPage from '../../pages/notFound';
import ErrorDisplayPage, { ErrorDisplayPageProps } from '../error/errorDisplayPage';
import Loading, { LoadingProps } from '../loading';

export interface WithLoadingProps {
    isLoading: boolean;
    setError: (error?: unknown) => void;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface WithLoadingOptions {
    errorDisplayPageProps?: ErrorDisplayPageProps;
    isLoadingByDefault?: boolean;
    loadingProps?: LoadingProps;
    showNotFoundFor404?: boolean;
}

const withLoading =
    <TComponentProps,>(Component: (props: TComponentProps) => JSX.Element, options?: WithLoadingOptions) =>
    (props: Pick<TComponentProps, Exclude<keyof TComponentProps, keyof WithLoadingProps>>): JSX.Element => {
        const [errorCode, setErrorCode] = useState<number | undefined>();
        const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
        const [isLoading, setIsLoading] = useState<boolean>(options?.isLoadingByDefault ?? true);

        const setError = useCallback(
            (error?: Core.Models.AppError): void => {
                if (!error) {
                    // clear error states
                    setErrorCode(undefined);
                    setErrorMessage(undefined);
                    return;
                }

                setErrorCode(Core.API.getStatus(error));
                setErrorMessage(Core.API.getErrorMessage(error));
            },
            [setErrorCode, setErrorMessage]
        );

        // handle errors
        if (!!errorCode) {
            if (errorCode === 404 && !!options?.showNotFoundFor404) return <NotFoundPage />;

            const message = process.env.NODE_ENV === 'development' ? errorMessage : undefined;
            return <ErrorDisplayPage {...{ ...options?.errorDisplayPageProps, message }} />;
        }

        return (
            <>
                {isLoading && <Loading {...options?.loadingProps} />}

                {/* 
				using CSS to hide the component instead of conditionally load it to the DOM since unloading from the DOM 
				would cause the child component to unmount and stop loading
			*/}
                <div style={{ visibility: isLoading ? 'hidden' : 'visible', width: '100%' }}>
                    <Component
                        {...{
                            isLoading,
                            setError,
                            setIsLoading,
                            ...(props as TComponentProps),
                        }}
                    />
                </div>
            </>
        );
    };

export default withLoading;

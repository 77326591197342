import React, { useState } from 'react';
import { Formik, Form, Field, FormikActions } from 'formik';
import { orderBy } from 'lodash';
import * as Yup from 'yup';

import * as Core from '../../../core';
import { Button } from '../../../components/button';
import ErrorMessage from '../../../components/errorMessage';
import Loading from '../../../components/loading';
import Modal from '../../../components/overlays/modal/Modal';

import './index.scss';

interface ChooseDeckModalProps {
    decks: Core.Models.HearthstoneDeck[];
    isOpen: boolean;
    modalTitle: string;
    onClose: () => void;
    onSubmit: (value: string) => void;
}

export interface ChooseDeckModalValues {
    deckCode: string;
}

const schema = Yup.object().shape({
    deckCode: Yup.string().required('A deck selection is required'),
});

const ChooseDeckModal = (props: ChooseDeckModalProps): JSX.Element => {
    const { decks, isOpen = false, modalTitle, onClose, onSubmit } = props;

    const [chooseDeckModalOpen, setChooseDeckModalOpen] = useState<boolean>(isOpen);

    return (
        <Modal
            className="choose-deck-modal"
            isOpen={chooseDeckModalOpen}
            onClose={() => {
                onClose();
                setChooseDeckModalOpen(false);
            }}
            title={modalTitle}
        >
            <Formik
                initialValues={Object.assign({
                    deckCode: '',
                })}
                validationSchema={schema}
                onSubmit={async (values: ChooseDeckModalValues, actions: FormikActions<ChooseDeckModalValues>) => {
                    actions.setStatus(undefined);
                    try {
                        await onSubmit(values.deckCode);
                    } catch (e) {
                        const message = Core.API.getErrorMessage(e);
                        actions.setStatus(message);
                    }
                    actions.setSubmitting(false);
                }}
                render={(formProps) => (
                    <Form className="form">
                        <fieldset
                            className="choose-deck-modal__decks"
                            role="group"
                            aria-labelledby="hearthstone decks radio group"
                        >
                            {orderBy(decks, (deck: Core.Models.HearthstoneDeck) => deck.sortOrder).map(
                                (deck: Core.Models.HearthstoneDeck) => (
                                    <label className="choose-deck-modal__decks__deck" key={deck.code}>
                                        <img
                                            src={`/static/media/titles/hearthstone/${deck.name.toLowerCase()}.jpg`}
                                            alt={`${deck.name} deck`}
                                        />
                                        <span>{deck.name}</span>
                                        <Field type="radio" name="deckCode" value={deck.code} />
                                    </label>
                                )
                            )}
                        </fieldset>

                        {formProps.status && <ErrorMessage error={formProps.status} />}
                        <ErrorMessage error={formProps.errors} filter={formProps.touched} />

                        <fieldset className="form-group form-group--undecorated">
                            {formProps.isSubmitting && <Loading buttonLoader />}
                            <Button onClick={formProps.submitForm} disabled={formProps.isSubmitting} wide round>
                                Submit
                            </Button>
                        </fieldset>
                    </Form>
                )}
            />
        </Modal>
    );
};

export default ChooseDeckModal;

import React from 'react';

import { HollowButton } from '../../../../components/buttons-visuals';
import InfoMessage from '../../../../components/infoMessage';
import { CopyTextField } from '../../../../components/inputs';

interface ViewEmailVerificationLinkProps {
    tokens: string[];
}

const ViewEmailVerificationLink = (props: ViewEmailVerificationLinkProps): JSX.Element => {
    const { tokens } = props;

    return (
        <div className="view-email-verification-link">
            {tokens.map((token: string) => (
                <div key={token}>
                    <p className="mt2x mb2x">Share this email verification link or click Verify Now to auto-verify:</p>
                    <fieldset className="form-group mb2x">
                        <CopyTextField
                            id="emailVerificationLink"
                            label="Email verification link"
                            value={`${window.location.protocol}//${window.location.host}/verify-email/${token}`}
                        />
                    </fieldset>
                    <HollowButton
                        as="a"
                        href={`${window.location.protocol}//${window.location.host}/verify-email/${token}`}
                        layout="full"
                        rel="noopener noreferrer"
                        size="medium"
                        target="_blank"
                    >
                        Verify Now
                    </HollowButton>
                </div>
            ))}
            <InfoMessage
                message={
                    "This will bypass our email verification process; please be sure that you know this person's identity, and you have confirmed they are registered for this league."
                }
                type="info"
            />
        </div>
    );
};

export default ViewEmailVerificationLink;

import * as Redux from 'redux';

import { StartupState, STARTUP_SUCCESS, StartupSuccess } from './types';

const initialState: StartupState = {
    isPlatform: false,
};

const startupReducer: Redux.Reducer<StartupState, StartupSuccess> = (
    state = initialState,
    action: StartupSuccess
): StartupState => {
    switch (action.type) {
        case STARTUP_SUCCESS:
            return {
                ...state,
                isPlatform: action.payload,
            };
        default:
            return state;
    }
};

export default startupReducer;

import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTimer } from 'react-timer-hook';

const useCountdown = (format: string, expiresAtUtc?: string | Date) => {
    const { hours, minutes, seconds } = useTimer({
        autoStart: true,
        expiryTimestamp: new Date(expiresAtUtc ?? new Date()),
    });

    const [formattedTimer, setFormattedTimer] = useState<string>('');
    const [secondsRemaining, setSecondsRemaining] = useState<number>(0);

    useEffect(() => {
        setSecondsRemaining(hours * 3600 + minutes * 60 + seconds);
    }, [hours, minutes, seconds]);

    useEffect(() => {
        setFormattedTimer(moment(new Date().setTime(secondsRemaining * 1000)).format(format));
    }, [format, secondsRemaining]);

    return {
        formattedTimer,
        secondsRemaining,
    };
};

export default useCountdown;

import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import moment from 'moment-timezone';
import pluralize from 'pluralize';
import Select, { OptionsType, SelectOption } from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../core';
import { useAlternateSeasonName, useLeagueConfiguration, useTimezone } from '../../hooks/store';
import PaymentGatewayService from '../../services/paymentGatewayService';
import { SolidButton } from '../buttons-visuals';
import FormField from '../formField';
import InfoMessage from '../infoMessage';
import { ToolTip } from '../overlays';

interface UpsertPayableProps {
    onCompleted: (payable: Core.Models.Payable) => Promise<void>;
    payable?: Core.Models.Payable;
}

const UpsertPayable = ({ onCompleted, payable }: UpsertPayableProps) => {
    const seasonAlternateName = useAlternateSeasonName();
    const leagueConfiguration = useLeagueConfiguration();
    const timezone = useTimezone();

    const options = useMemo(
        () =>
            leagueConfiguration?.designations.map((designation: Core.Models.LeagueDesignation) => ({
                label: designation.name,
                value: designation.id,
            })),
        [leagueConfiguration?.designations]
    );

    return (
        <Formik<Core.Models.UpsertPayableRequest>
            initialValues={{
                discountFee: !!payable && !!payable.discountFee ? Core.Currency.convertNumberToCurrency(payable.discountFee) : undefined,
                discountEndUtc: !!payable?.discountEndUtc ? moment.tz(payable.discountEndUtc, timezone).format() : undefined,
                endDate: payable?.endDate ?? undefined,
                entityType: payable?.entityType ?? Core.Models.PayableEntityType.Season,
                fee: !!payable ? Core.Currency.convertNumberToCurrency(payable.fee) : undefined,
                leagueDesignationIds: payable?.exemptLeagueDesignations?.map((ld) => ld.id) ?? [],
                forLeagueDesignationIds: payable?.exemptLeagueDesignations?.map((ld) => ld.id) ?? [],
                name: payable?.name ?? '',
                payableId: payable?.id ?? undefined,
                startDate: payable?.startDate ?? undefined,
            }}
            validationSchema={Yup.object().shape({
                fee: Yup.string()
                    .required('Fee amount is required')
                    .test('positive', 'Fee amount must be a positive number', (fee: string | undefined) => {
                        if (!fee) return false;
                        const numeric = Core.Currency.convertCurrencyToNumber(fee);
                        if (isNaN(numeric)) return false;
                        return true;
                    }),
                name: Yup.string()
                    .required('Fee name is required')
                    .max(50, 'Fee name must be fewer than 50 characters'),
                payableId: Yup.string().nullable().notRequired(),
                discountFee: Yup.string()
                    .test('positive', 'Fee amount must be a positive number', (discountFee: string | undefined) => {
                        if (!discountFee) return true;
                        const numeric = Core.Currency.convertCurrencyToNumber(discountFee);
                        if (isNaN(numeric)) return false;
                        return true;
                    })
                    .test('isLessThanFee', 'Discount must be less than fee', (discountFee: string | undefined, context) => {
                        const fee = context.parent.fee;
                        if (!fee) return true; // this is tested elsewhere
                        if (!discountFee) return true;

                        return Core.Currency.convertCurrencyToNumber(discountFee!) < Core.Currency.convertCurrencyToNumber(fee!);
                    }),
                discountEndUtc: Yup.string(),
            })}
            onSubmit={async (
                values: Core.Models.UpsertPayableRequest,
                actions: FormikActions<Core.Models.UpsertPayableRequest>
            ) => {
                if (!values.fee) return;

                try {
                    const payload = {
                        ...values,
                        endDate: !!values.endDate
                            ? moment.tz(values.endDate, timezone).format(Core.Time.getFormat())
                            : undefined,
                        fee: Core.Currency.convertCurrencyToNumber(values.fee).toString(),
                        discountFee: !!values.discountFee
                            ? Core.Currency.convertCurrencyToNumber(values.discountFee).toString()
                            : undefined,
                        discountEndUtc: !!values.discountEndUtc
                            ? moment.utc(values.discountEndUtc).format(Core.Time.getFormat(true))
                            : undefined,
                        startDate: !!values.startDate
                            ? moment.tz(values.startDate, timezone).format(Core.Time.getFormat())
                            : undefined,
                    };

                    const payableData = !!payable
                        ? await PaymentGatewayService.editPayable(payload)
                        : await PaymentGatewayService.createPayable(payload);

                    await onCompleted(payableData);
                } catch (error) {
                    const message = Core.API.getErrorMessage(error);
                    toast.error(message);
                } finally {
                    actions.setSubmitting(false);
                }
            }}
            render={(formProps: FormikProps<Core.Models.UpsertPayableRequest>) => (
                <>
                    <fieldset className="form-group">
                        <FormField
                            component="text"
                            label="Fee name"
                            name="name"
                            placeholder={`Fee name (e.g., Fall ${new Date().getFullYear()} ${seasonAlternateName})`}
                        />
                        <FormField
                            component="currency"
                            name="fee"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                formProps.setFieldValue('fee', e.target.value)
                            }
                            placeholder="Fee amount ($USD)"
                        />
                        <Field name="payableId" type="hidden" />

                        <FormField
                            component="select"
                            label="Fee type"
                            disabled={!!payable}
                            name="entityType"
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                formProps.setFieldValue('entityType', +event.target.value)
                            }
                        >
                            <option value={Core.Models.PayableEntityType.Season}>
                                {seasonAlternateName} entry fee
                            </option>
                            <option value={Core.Models.PayableEntityType.League}>League fee</option>
                            <option value={Core.Models.PayableEntityType.TeamDesignation}>Team Designation fee</option>
                        </FormField>

                        {/* BEGIN: Get info message */}
                        {formProps.values.entityType === Core.Models.PayableEntityType.League &&
                            <InfoMessage
                                message="League fees are one-time fees that must be paid by every user who wants to play within the time period between the selected start and end dates (inclusive)."
                                type="info"
                            />
                        }
                        {formProps.values.entityType === Core.Models.PayableEntityType.Season &&
                            <InfoMessage
                                message={`
                                ${seasonAlternateName} entry fees are reusable fees that must be paid
                                by every team that wants to play in ${pluralize(
                                    seasonAlternateName.toLowerCase()
                                )} to which each fee is associated.
                                These fees can be added to multiple ${pluralize(
                                    seasonAlternateName.toLowerCase()
                                )} to allow one team to pay once and play in multiple.
                            `}
                                type="info"
                            />
                        }
                        {formProps.values.entityType === Core.Models.PayableEntityType.TeamDesignation &&
                            <InfoMessage
                                message="Team Designation fees are one-time fees that must be paid by every user who wants to play on a team with a given team designation within the time period between the selected start and end dates (inclusive)."
                                type="info"
                            />
                        }
                        {/* END: Get info message */}



                        <fieldset className="form-group">
                            <FormField
                                component="currency"
                                name="discountFee"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    formProps.setFieldValue('discountFee', e.target.value)
                                }
                                placeholder="Optional Discount Amount ($USD)"
                            />
                            <FormField component="datetime" name="discountEndUtc" label="Discount end date time" />
                        </fieldset>

                        {(formProps.values.entityType === Core.Models.PayableEntityType.League
                            || formProps.values.entityType === Core.Models.PayableEntityType.TeamDesignation)
                            && (
                                <>
                                    <FormField component="date" label="Start date" name="startDate" />
                                    <FormField component="date" label="End date" name="endDate" />
                                </>
                            )
                        }

                        {
                            formProps.values.entityType === Core.Models.PayableEntityType.Season && (
                                <>
                                    <label className="basic-multi-select__label" htmlFor="leagueDesignationIds">
                                        Exempt designations
                                        <ToolTip
                                            className="ml"
                                            trigger={<FontAwesomeIcon icon={['fas', 'circle-info']} size="1x" />}
                                        >
                                            <span>
                                                These designations are exempt from this fee and can participate in the{' '}
                                                {seasonAlternateName.toLowerCase()}
                                                without paying.
                                            </span>
                                        </ToolTip>
                                    </label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isMulti
                                        onChange={(selectedOptions: OptionsType<SelectOption>) => {
                                            formProps.setFieldValue(
                                                'leagueDesignationIds',
                                                selectedOptions.map((option: SelectOption) => option.value)
                                            );
                                        }}
                                        options={options}
                                        name="leagueDesignationIds"
                                        placeholder="Select exempt designations"
                                        styles={{
                                            control: (provided: any) => ({
                                                ...provided,
                                                border: 'none',
                                                minHeight: '5.6rem',
                                            }),
                                            menu: (provided: any) => ({ ...provided, zIndex: 2 }),
                                        }}
                                        defaultValue={options?.filter((o) =>
                                            formProps.values.leagueDesignationIds.includes(o.value)
                                        )}
                                    />
                                </>
                            )
                        }

                        {
                            formProps.values.entityType === Core.Models.PayableEntityType.TeamDesignation && (
                                <>
                                    <label className="basic-multi-select__label" htmlFor="forLeagueDesignationIds">
                                        For designations
                                        <ToolTip
                                            className="ml"
                                            trigger={<FontAwesomeIcon icon={['fas', 'circle-info']} size="1x" />}
                                        >
                                            <span>
                                                These are the designations that paying this fee will give a user access to
                                            </span>
                                        </ToolTip>
                                    </label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isMulti
                                        onChange={(selectedOptions: OptionsType<SelectOption>) => {
                                            formProps.setFieldValue(
                                                'forLeagueDesignationIds',
                                                selectedOptions.map((option: SelectOption) => option.value)
                                            );
                                        }}
                                        options={options}
                                        name="forLeagueDesignationIds"
                                        placeholder="Select allowed team designations to join"
                                        styles={{
                                            control: (provided: any) => ({
                                                ...provided,
                                                border: 'none',
                                                minHeight: '5.6rem',
                                            }),
                                            menu: (provided: any) => ({ ...provided, zIndex: 2 }),
                                        }}
                                        defaultValue={options?.filter((o) =>
                                            formProps.values.forLeagueDesignationIds.includes(o.value)
                                        )}
                                    />
                                </>
                            )
                        }
                    </fieldset>

                    {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                    <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                    <fieldset className="form-group form-group--undecorated">
                        <SolidButton
                            as="button"
                            layout="full"
                            onClick={formProps.submitForm}
                            pending={formProps.isSubmitting}
                        >
                            {!!payable ? 'Edit' : 'Create'} fee
                        </SolidButton>
                    </fieldset>
                </>
            )}
        />
    );
};

export default UpsertPayable;

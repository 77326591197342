import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

import './index.scss';
import { BaseButton } from '../buttons-visuals';

interface TabNavItem {
    contents: JSX.Element;
    id: string | number;
    name: string;
    onClick: (e: React.MouseEvent) => void;
}

export interface TabNavProps extends HTMLAttributes<HTMLDivElement> {
    current: number;
    items: TabNavItem[];
}

const TabNav = (props: TabNavProps) => {
    const { className, current, items } = props;

    if (items.length <= 0) return <></>;
    return (
        <div role="tablist" className={classNames(className, 'tab-nav')}>
            {items.map((item: TabNavItem, index: number) => {
                const { contents, id, name, onClick } = item;
                return (
                    <BaseButton
                        as="button"
                        aria-controls={`${name}-panel`}
                        aria-selected={index === current}
                        className={classNames('tab-nav__item', { active: index === current })}
                        key={id}
                        onClick={onClick}
                        role="tab"
                    >
                        {contents}
                    </BaseButton>
                );
            })}
        </div>
    );
};

export default TabNav;

import React, { useCallback, useState } from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { isFinite } from 'lodash';
import Cropper from 'react-cropper';

import * as Core from '../../core';
import { ToggleSwitch } from '../../components/inputs';
import { ToolTip } from '../../components/overlays';
import { LabelButton } from '../button';
import { SolidButton } from '../buttons-visuals';
import InfoMessage from '../infoMessage';
import Loading from '../loading';
import Modal from '../modal';

import 'cropperjs/dist/cropper.css';
import './index.scss';

interface ImageUploaderProps {
    aspectRatio?: number;
    className?: string;
    contentType: string;
    defaultBackgroundColor?: string;
    disableCrop?: boolean;
    disabled?: boolean;
    flush?: boolean;
    ignoreMaxSize?: boolean;
    maxFileSize?: number;
    maxImageWidth?: number;
    icon?: 'upload' | 'pen-to-square';
    text: string;
    uploadImage: (imageSource: string) => Promise<void>;
}

const ImageUploader = (props: ImageUploaderProps): JSX.Element => {
    const {
        aspectRatio,
        className,
        contentType,
        defaultBackgroundColor,
        disableCrop,
        disabled,
        flush,
        icon,
        ignoreMaxSize,
        maxFileSize = Core.Constants.IMAGES.MAX_IMAGE_SIZE,
        maxImageWidth,
        text,
        uploadImage,
    } = props;

    const [cropper, setCropper] = useState<Cropper | undefined>(undefined);
    const [croppingAvatar, setCroppingAvatar] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [imageString, setImageString] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [sendOriginalImage, setSendOriginalImage] = useState<boolean>(!!disableCrop);

    const resizeImage = useCallback(
        (image: HTMLImageElement, width: number, height: number): string => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d')!;
            canvas.width = width;
            canvas.height = height;

            if (!!defaultBackgroundColor) {
                context.fillStyle = defaultBackgroundColor;
                context.fillRect(0, 0, width, height);
            }

            // draw uploaded image
            context.drawImage(image, 0, 0, width, height);

            return canvas.toDataURL(contentType);
        },
        [contentType, defaultBackgroundColor]
    );

    const closeModal = useCallback(() => {
        setCroppingAvatar(false);
    }, []);

    const saveImage = useCallback(async () => {
        setLoading(true);
        setErrorMessage(undefined);

        let imageSource: string;
        if (sendOriginalImage) {
            imageSource = imageString ?? '';
        } else {
            if (!cropper) return;
            imageSource = cropper.getCroppedCanvas().toDataURL(contentType);
        }

        const image = new Image();
        image.onload = async () => {
            if (!sendOriginalImage && isFinite(maxImageWidth)) {
                imageSource = resizeImage(
                    image,
                    maxImageWidth!,
                    maxImageWidth! / (aspectRatio || image.width / image.height)
                );
            }

            if (ignoreMaxSize || imageSource.length <= maxFileSize) {
                try {
                    await uploadImage(imageSource.split(',')[1]);
                    closeModal();
                } catch (error) {
                    setErrorMessage(Core.API.getErrorMessage(error));
                }
            } else {
                setErrorMessage(`File size too large. Maximum ${maxFileSize / 1000} KB`);
            }

            setLoading(false);
        };
        image.src = imageSource;
    }, [
        aspectRatio,
        closeModal,
        contentType,
        cropper,
        ignoreMaxSize,
        imageString,
        maxFileSize,
        maxImageWidth,
        resizeImage,
        sendOriginalImage,
        uploadImage,
    ]);

    const getImage = useCallback(async (onChangeEvent: React.ChangeEvent<HTMLInputElement>) => {
        if (!onChangeEvent.target.files?.[0]) return;

        const imageFile = onChangeEvent.target.files[0];

        // confirm the file is an image
        if (!imageFile.type.startsWith('image/')) {
            setErrorMessage('Invalid image file.');
            return;
        }

        const reader = new FileReader();
        reader.onload = async (loadedEvent: any) => {
            // got the uploaded image, begin cropping
            setCroppingAvatar(true); // always load cropper
            setImageString(loadedEvent.target.result);
        };

        // get image string
        reader.readAsDataURL(imageFile);

        // reset the file input (if the user selects an image, closes without saving, then chooses same image)
        onChangeEvent.target.value = '';
    }, []);

    return (
        <div
            className={classNames(className, 'image-uploader', {
                'image-uploader--disabled': disabled,
                'image-uploader--flush': flush,
            })}
        >
            <ToolTip
                hide={!disabled}
                trigger={
                    <LabelButton
                        styleType={Core.Models.StyleType.LinkLike}
                        className={classNames('image-uploader__update-image', {
                            'image-uploader__update-image--icon': icon,
                        })}
                    >
                        {icon ? (
                            <FontAwesomeIcon
                                className="image-uploader__update-image__icon"
                                icon={['fas', icon as IconName]}
                            />
                        ) : (
                            text
                        )}
                        <input
                            accept={'image/*'}
                            className="image-uploader__file-input"
                            disabled={disabled}
                            multiple={false}
                            onChange={async (onChangeEvent) => await getImage(onChangeEvent)}
                            onClick={() => setErrorMessage(undefined)}
                            type="file"
                        />
                    </LabelButton>
                }
            >
                <span>This action is not available in {Core.Constants.PLANS.LITE.NAME}.</span>
            </ToolTip>

            {croppingAvatar && (
                <Modal onClose={closeModal} title="Review image">
                    {sendOriginalImage ? (
                        <img className="mb2x full-width" src={imageString} alt="" />
                    ) : (
                        <Cropper
                            aspectRatio={aspectRatio}
                            className="image-uploader__cropper mb2x"
                            onInitialized={(instance: Cropper) => setCropper(instance)}
                            src={imageString}
                        />
                    )}
                    {!disableCrop && ( // if cropping is disabled, don't allow user to turn it back on
                        <div className="disp-flex flex-dir-row align-center justify-center">
                            <span className="mr2x color-black">Upload image without cropping</span>
                            <ToggleSwitch
                                className="mb0"
                                onToggleSwitch={async (value: boolean) => setSendOriginalImage(value)}
                                value={sendOriginalImage}
                            />
                        </div>
                    )}
                    {loading && <Loading buttonLoader />}
                    <SolidButton
                        as="button"
                        className="image-uploader__save-button"
                        disabled={loading}
                        onClick={() => saveImage()}
                        size="small"
                    >
                        Save
                    </SolidButton>
                    {!!errorMessage && <InfoMessage message={errorMessage} type="error" />}
                </Modal>
            )}
        </div>
    );
};

export default ImageUploader;

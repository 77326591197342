import { createSelector } from 'reselect';

import * as Core from '../../core';
import { AppState } from '..';
import { getUserPermissionService } from '../permissions/selector';

const getProfileState = ({ profileState }: AppState) => profileState;

interface Participant {
    teamId?: string;
}

// the methods exported from this file are designed to be used with `mapStateToProps` or `useSelector` to get a function to evaluate 'my' participants/matches

// constructs a function to identify if the current user is part of the participant (directly or as part of a team)
const getIsParticipant = createSelector([getProfileState], (profileState) => {
    const teamIds = (profileState?.profile?.teams || []).map((team: Core.Models.Team) => team.id);
    return (participant: Participant) => teamIds.some((teamId: string) => teamId === participant.teamId);
});

// constructs a function to identify if the current user is a participant (directly or as part of a team) for a given match
const getIsMatchParticipant = createSelector([getIsParticipant], (isParticipant) => {
    return (matchParticipants: Participant[]) => {
        return matchParticipants.some(isParticipant);
    };
});

// constructs a function to identify if the current user has edit rights to a team on the participant, but not the league
const getHasNonLeagueEditRights = createSelector([getUserPermissionService], (userPermissionService) => {
    return (participant: Participant) => {
        const teamRole = userPermissionService.getTeamRole(participant.teamId);
        // look for roles that have team edit, but not league edit (ie. org manager, not league host)
        return (
            teamRole.teamPermissions >= Core.Models.PermissionLevel.Edit &&
            teamRole.leaguePermissions < Core.Models.PermissionLevel.Edit
        );
    };
});

// constructs a function to identify if the current user has edit rights to a team on the match, but not the league
const getMatchHasNonLeagueEditRights = createSelector([getHasNonLeagueEditRights], (hasNonLeagueEditRights) => {
    return (matchParticipants: Participant[]) => {
        return matchParticipants.some(hasNonLeagueEditRights);
    };
});

// constructs a array of functions to use to sort matches where 'my matches' are at the beginning (ie. matches I am a participant in, then where I'm manager, then everything else)
// use the returned functions as the first ones passed to `_.orderBy`
export const getMyMatchSorters = createSelector(
    [getIsMatchParticipant, getMatchHasNonLeagueEditRights],
    (isParticipant, hasNonLeagueHostEditRights) => {
        return [
            (match: Core.Models.Match) => (isParticipant(match.matchParticipants) ? -1 : 1),
            (match: Core.Models.Match) => (hasNonLeagueHostEditRights(match.matchParticipants) ? -1 : 1),
        ];
    }
);

// constructs a function to use to identify if this is 'my match' (ie. I'm part of the participant or a manager of it)
export const getIsMyMatch = createSelector(
    [getIsMatchParticipant, getMatchHasNonLeagueEditRights],
    (isParticipant, hasNonLeagueHostEditRights) => {
        return (matchParticipants: Participant[]) => {
            return isParticipant(matchParticipants) || hasNonLeagueHostEditRights(matchParticipants);
        };
    }
);

// constructs a function to use to identify if this is 'my participant' (ie. I'm part of the participant or a manager of it)
export const getIsMyParticipant = createSelector(
    [getIsParticipant, getHasNonLeagueEditRights],
    (isParticipant, hasNonLeagueHostEditRights) => {
        return (participant: Participant) => {
            return isParticipant(participant) || hasNonLeagueHostEditRights(participant);
        };
    }
);

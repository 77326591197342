import { useEffect, useState } from 'react';

// returns `new Date()` and updates it automatically every `updateInterval` milliseconds
export const useNow = (updateInterval: number) => {
    const [now, setNow] = useState<Date>(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date());
        }, updateInterval);

        return () => clearInterval(interval);
    }, [setNow, updateInterval]);

    return now;
};

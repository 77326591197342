// influenced by: https://codesandbox.io/s/react-currency-input-with-react-text-mask-4es23

import * as React from 'react';
import MaskedInput from '@biproxi/react-text-mask'; // using forked repo because original doesn't support React v17
import { omit } from 'lodash';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export interface MaskOptions {
    allowDecimal: boolean;
    allowLeadingZeroes: boolean;
    allowNegative: boolean;
    decimalLimit: string;
    decimalSymbol: string;
    includeThousandsSeparator: boolean;
    integerLimit: number;
    prefix: string;
    requireDecimal: boolean;
    suffix: string;
}

interface CurrencyInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    maskOptions?: MaskOptions;
}

const CurrencyInput = (props: CurrencyInputProps): JSX.Element => {
    const { maskOptions } = props;
    const currencyMask = createNumberMask(
        Object.assign(
            {
                prefix: '$',
                suffix: '',
                includeThousandsSeparator: true,
                thousandsSeparatorSymbol: ',',
                allowDecimal: true,
                decimalSymbol: '.',
                decimalLimit: 2, // how many digits allowed after the decimal
                integerLimit: 7, // limit length of integer numbers
                allowNegative: false,
                allowLeadingZeroes: false,
            },
            maskOptions
        )
    );

    return (
        <div className="ds-text-field">
            <div className="ds-text-field__wrapper">
                <MaskedInput
                    className="ds-text-field__wrapper__input"
                    id={props.name}
                    mask={currencyMask}
                    {...omit(props, 'maskOptions')}
                    placeholder=""
                />
                <label className="ds-text-field__wrapper__label" htmlFor={props.name}>
                    {props.placeholder}
                </label>
            </div>
        </div>
    );
};

export default CurrencyInput;

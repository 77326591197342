import React, { useCallback, useState } from 'react';

import * as Core from '../../core';
import { LeagueSearchResultCard } from './leagueSearch';
import { Filter } from '../../components/inputs';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import useApiPagination from '../../hooks/apiPagination';
import { LeagueService } from '../../services/leagueService';

const SEARCH_RESULTS_PER_PAGE = 9;

const Search = (): JSX.Element => {
    const [search, setSearch] = useState<string>('');

    return (
        <div className="league-search__search">
            <Filter
                className="league-search__search__input"
                debounceMs={Core.Constants.FORM_DEBOUNCE_TIME_MS}
                label="Search leagues"
                setValue={setSearch}
                value={search}
            />
            <SearchResults {...{ search }} />
        </div>
    );
};

interface SearchResultsProps extends WithLoadingProps {
    search: string;
}

const SearchResults = withLoading(({ search, setError, setIsLoading }: SearchResultsProps): JSX.Element => {
    const getPaginatedLeaguesAsync = useCallback(
        (
            page: number,
            pageSize: number
        ): Promise<Core.Models.PaginatedResult<Core.Models.LeagueSearchResult>> | undefined => {
            return LeagueService.getLeagues({ page, pageSize, search });
        },
        [search]
    );

    const { paginatedResults, Paginator } = useApiPagination(
        getPaginatedLeaguesAsync,
        setError,
        setIsLoading,
        SEARCH_RESULTS_PER_PAGE
    );

    return (
        <div>
            <h2>{!!search ? 'Search results' : 'All leagues'}</h2>
            <div>
                {!!paginatedResults?.results.length ? (
                    <div className="league-search__results-grid">
                        {paginatedResults.results.map((leagueSearchResult: Core.Models.LeagueSearchResult) => (
                            <LeagueSearchResultCard key={leagueSearchResult.id} {...{ leagueSearchResult }} />
                        ))}
                    </div>
                ) : (
                    <p className="full-width text-center">There are no leagues that match that criteria.</p>
                )}
            </div>
            {Paginator}
        </div>
    );
});

export default Search;

import React, { useCallback, useEffect } from 'react';

import * as Core from '../../../core';
import TeamList from '../../../components/teamList';
import withLoading, { WithLoadingProps } from '../../../components/withLoading';
import useApiPagination from '../../../hooks/apiPagination';
import { OrganizationService } from '../../../services/organizationService';

interface PaginatedTeamsListProps extends WithLoadingProps {
    filter: string;
    game: Core.Models.NamedObject;
    hideTeamControls?: boolean;
    hideTeamDetails?: boolean;
    organizationId: string;
}

const PaginatedTeamsList = (props: PaginatedTeamsListProps): JSX.Element => {
    const { filter, game, hideTeamControls, hideTeamDetails, organizationId, setError, setIsLoading } = props;

    const getPaginatedTeamsAsync = useCallback(
        (page: number, pageSize: number, search: string): Promise<Core.Models.PaginatedResult<Core.Models.Team>> => {
            return OrganizationService.getTeamsByOrganizationAndGame(organizationId, game.id, {
                page,
                pageSize,
                search,
            });
        },
        [game, organizationId]
    );

    const { paginatedResults, Paginator, selectPage, setSearch } = useApiPagination(
        getPaginatedTeamsAsync,
        setError,
        setIsLoading,
        Core.Constants.PAGINATION.TEAMS_PER_PAGE
    );

    useEffect(() => {
        setSearch(filter);
    }, [filter, setSearch]);

    if (!paginatedResults) return <></>;

    return (
        <div>
            <TeamList
                hideTeamControls={hideTeamControls}
                hideTeamDetails={hideTeamDetails}
                organizationId={organizationId}
                reloadTeamList={() => selectPage(1)}
                teams={paginatedResults.results}
            />
            {Paginator}
        </div>
    );
};

export default withLoading(PaginatedTeamsList, { loadingProps: { blockItem: true } });

import React from 'react';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../../core';
import { SolidButton } from '../../../components/buttons-visuals';
import FormField from '../../../components/formField';
import InfoMessage from '../../../components/infoMessage';
import MarkdownPreview from '../../../components/markdown/markdownPreview';
import { MatchService } from '../../../services/matchService';

interface AddLobbyInstructionsProps {
    lobbyInstructions?: string;
    matchId: string;
    matchWord: string;
    onClose: () => void;
    reloadData: (showLoading: boolean) => Promise<void>;
}

interface AddLobbyInstructionsValues extends Core.Models.AddMatchLobbyInstructionsCommand {}

const AddLobbyInstructions = (props: AddLobbyInstructionsProps): JSX.Element => {
    const { lobbyInstructions, matchId, matchWord, onClose, reloadData } = props;

    return (
        <Formik<AddLobbyInstructionsValues>
            initialValues={{
                lobbyInstructions: lobbyInstructions ?? '',
                matchId,
            }}
            validationSchema={Yup.object().shape({
                lobbyInstructions: Yup.string()
                    .max(
                        Core.Constants.MARKDOWN_MAX_LENGTH,
                        `Instructions must be max ${Core.Constants.MARKDOWN_MAX_LENGTH} characters`
                    )
                    .notRequired()
                    .nullable(),
            })}
            onSubmit={async (
                values: AddLobbyInstructionsValues,
                actions: FormikActions<AddLobbyInstructionsValues>
            ) => {
                actions.setStatus(undefined);

                try {
                    await MatchService.addMatchLobbyInstructions(values);
                    toast.success('Added lobby instructions');
                    await reloadData(false);
                    onClose();
                } catch (e) {
                    toast.error('Unable to add lobby instructions');
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                } finally {
                    actions.setSubmitting(false);
                }
            }}
            render={(formProps: FormikProps<AddLobbyInstructionsValues>) => (
                <Form>
                    <fieldset className="form-group">
                        <input type="hidden" name="matchId" />

                        <FormField
                            component="textarea"
                            description={`${matchWord} lobby instructions`}
                            name="lobbyInstructions"
                            placeholder={`Are there any special instructions that participants need for this ${matchWord.toLowerCase()}? This field allows markdown formatting.`}
                        />
                    </fieldset>

                    <MarkdownPreview fieldName="Lobby instructions" source={formProps.values.lobbyInstructions} />

                    {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                    <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                    <fieldset className="form-group form-group--undecorated">
                        <SolidButton
                            as="button"
                            className="full-width"
                            disabled={formProps.isSubmitting}
                            onClick={formProps.submitForm}
                            pending={formProps.isSubmitting}
                        >
                            Add lobby instructions
                        </SolidButton>
                    </fieldset>
                </Form>
            )}
        />
    );
};

export default AddLobbyInstructions;

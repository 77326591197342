import React, { useCallback } from 'react';
import { Switch } from 'react-router';

import AuthenticatedLayoutRoute from './components/routing/authenticatedLayoutRoute';
import LayoutRoute from './components/routing/layoutRoute';
import DynamicLoader from './dynamicLoader';
import { useLeague } from './hooks/store';
import AffiliateLeaguesPage from './pages/affiliateLeagues';
import ChatPage from './pages/chat';
import ContactPage from './pages/contact';
import CreateOrganizationPage from './pages/createOrganization';
import CreateStagePage from './pages/createStage';
import Dashboard from './pages/dashboard';
import EditStagePage from './pages/editStage';
import EditStageParticipantsPage from './pages/editStageParticipants';
import EmailVerificationSentPage from './pages/emailVerificationSent';
import ForgotUsernamePage from './pages/forgotUsername';
import IdentityVerificationPage from './pages/identityVerification';
import InviteTokenPage from './pages/inviteToken';
import JoinOrganizationPage from './pages/joinOrganization';
import JoinSeasonPage from './pages/joinSeason';
import LeaguePage from './pages/league';
import LeagueStandings from './pages/league/standings';
import LeagueSettingsPage from './pages/leagueSettings';
import LoginPage from './pages/login';
import LogoutPage from './pages/logout';
import MatchPage from './pages/match';
import NotFoundPage from './pages/notFound';
import OrganizationPage from './pages/organization';
import ForgotPasswordPage from './pages/password/forgotPassword';
import ResetPasswordContainer from './pages/password/resetPassword';
import SeasonPage from './pages/season';
import AssignFreeAgents from './pages/season/assignFreeAgents';
import EditSeasonParticipants from './pages/season/editParticipants';
import SeasonSettingsPage from './pages/season/seasonSettingsPage';
import StartStagePage from './pages/startStage';
import Switchboard from './pages/switchboard';
import TeamPage from './pages/team';
import UserPage from './pages/user';
import VerifyEmailPage from './pages/verifyEmail';
import OrganizationStandings from './pages/widgets/organizationStandings';
// these must come last because otherwise the entire app will show up with a blank screen. no idea why this happens but it's a thing 👻
import EmptyLayout from './layouts/emptyLayout'; // eslint-disable-line import/order
import MainLayout from './layouts/mainLayout'; // eslint-disable-line import/order
import RegistrationLayout from './layouts/registrationLayout'; // eslint-disable-line import/order
import WidgetLayout from './layouts/widgetLayout'; // eslint-disable-line import/order

import './components/buttons-visuals/icon/IconLibrary';
import * as Core from './core';
import AddAmazonTag from './components/acceptCookies/addAmazonTag';

const App = () => {
    const league = useLeague();

    const LandingPageRoute = useCallback(
        () => (
            <LayoutRoute
                component={() => <DynamicLoader component={league?.landingPageFilePath} />}
                exact
                key="/home"
                layout={MainLayout}
                path="/home"
            />
        ),
        [league?.landingPageFilePath]
    );

    return (
        <>
            {league?.featureGroup === Core.Models.FeatureGroup.NACE && (
                <AddAmazonTag />
            )}
            <Switch>
                <LayoutRoute path="/" exact layout={MainLayout} component={Switchboard} />
                <LayoutRoute path="/dashboard" layout={MainLayout} component={Dashboard} />
                {!!league?.enableChat && <AuthenticatedLayoutRoute path="/chat" layout={MainLayout} component={ChatPage} />}
                {!!league?.landingPageFilePath && [LandingPageRoute()]}
                <LayoutRoute
                    path="/organizations/create/:inviteCode"
                    component={CreateOrganizationPage}
                    layout={RegistrationLayout}
                />
                <LayoutRoute
                    path="/resetPassword/:userId/:code"
                    component={ResetPasswordContainer}
                    layout={RegistrationLayout}
                />
                <LayoutRoute path="/forgotPassword" component={ForgotPasswordPage} layout={RegistrationLayout} />
                <LayoutRoute path="/forgotUsername" component={ForgotUsernamePage} layout={RegistrationLayout} />
                <LayoutRoute path="/login/:from?" component={LoginPage} layout={MainLayout} />
                <LayoutRoute path="/logout" component={LogoutPage} layout={EmptyLayout} />
                <LayoutRoute path="/join/:inviteCode" component={InviteTokenPage} layout={RegistrationLayout} />
                <LayoutRoute
                    path="/organizations/join/:joinCode"
                    layout={RegistrationLayout}
                    component={JoinOrganizationPage}
                />
                <LayoutRoute
                    path="/widgets/standings/:organizationId"
                    layout={WidgetLayout}
                    component={OrganizationStandings}
                />
                <LayoutRoute path="/widgets/standings" layout={WidgetLayout} component={LeagueStandings} />
                <LayoutRoute path="/league" layout={MainLayout} component={LeaguePage} />
                <LayoutRoute path="/organizations/:organizationId?" layout={MainLayout} component={OrganizationPage} />
                <LayoutRoute path="/teams/:id" layout={MainLayout} component={TeamPage} />
                <LayoutRoute path="/users/:userId" layout={MainLayout} component={UserPage} />
                <AuthenticatedLayoutRoute
                    path="/identity-verification"
                    layout={MainLayout}
                    component={IdentityVerificationPage}
                />
                <LayoutRoute path="/seasons/join/:seasonId" layout={MainLayout} component={JoinSeasonPage} />
                <AuthenticatedLayoutRoute
                    path="/seasons/:seasonId/createStage"
                    layout={MainLayout}
                    component={CreateStagePage}
                />
                <AuthenticatedLayoutRoute
                    path="/seasons/:seasonId/settings"
                    layout={MainLayout}
                    component={SeasonSettingsPage}
                />
                <AuthenticatedLayoutRoute
                    path="/seasons/:seasonId/editParticipants"
                    layout={MainLayout}
                    component={EditSeasonParticipants}
                />
                <AuthenticatedLayoutRoute
                    path="/seasons/:seasonId/assignFreeAgents"
                    layout={MainLayout}
                    component={AssignFreeAgents}
                />
                <LayoutRoute path="/seasons/:seasonId/:stageId?" layout={MainLayout} component={SeasonPage} />
                <LayoutRoute path="/matches/:id" layout={MainLayout} component={MatchPage} />
                <AuthenticatedLayoutRoute path="/stages/:id/edit" layout={MainLayout} component={EditStagePage} />
                <AuthenticatedLayoutRoute path="/stages/:id/start" layout={MainLayout} component={StartStagePage} />
                <AuthenticatedLayoutRoute
                    path="/stages/:id/participants"
                    layout={MainLayout}
                    component={EditStageParticipantsPage}
                />
                <LayoutRoute path="/contact" layout={MainLayout} component={ContactPage} />
                <AuthenticatedLayoutRoute path="/league-settings" layout={MainLayout} component={LeagueSettingsPage} />
                <LayoutRoute path="/affiliate-leagues" layout={MainLayout} component={AffiliateLeaguesPage} />
                <LayoutRoute path="/verify-email/:token" layout={RegistrationLayout} component={VerifyEmailPage} />
                <LayoutRoute path="/email-verification-sent" component={EmailVerificationSentPage} layout={MainLayout} />
                <LayoutRoute path="/" layout={MainLayout} component={NotFoundPage} />
            </Switch>
        </>
    );
};

export default App;

import { useCallback, useState } from 'react';
import { orderBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import { DesignationEditorMode, NEW_DESIGNATION_ID, colorOptions, iconOptions } from './designationEditor/constants';
import { FocusedDesignation } from './designationEditor/types';
import { useLeagueConfiguration } from '../../../hooks/store';
import { LeagueService } from '../../../services/leagueService';
import { getLeague } from '../../../store/league/actions';

const getNewDesignation = () => ({
    color: colorOptions[Math.floor(Math.random() * colorOptions.length)].hex,
    iconName: iconOptions[Math.floor(Math.random() * iconOptions.length)],
    name: '',
});

export const useDesignations = () => {
    const dispatch = useDispatch();
    const leagueConfiguration = useLeagueConfiguration();
    const [designations, setDesignations] = useState<Core.Models.LeagueDesignation[]>(
        orderBy(leagueConfiguration?.designations ?? [], 'name')
    );
    const [focusedDesignation, setFocusedDesignation] = useState<FocusedDesignation | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const createDesignation = useCallback(() => {
        setDesignations((prevDesignations) => [
            ...prevDesignations.filter((d: Core.Models.LeagueDesignation) => d.id !== NEW_DESIGNATION_ID),
            {
                ...getNewDesignation(),
                id: NEW_DESIGNATION_ID,
            },
        ]);
        setFocusedDesignation({
            id: NEW_DESIGNATION_ID,
            mode: DesignationEditorMode.Edit,
        });
    }, [setDesignations, setFocusedDesignation]);

    const deleteDesignation = useCallback(
        async (id: string) => {
            try {
                setIsLoading(true);
                if (id !== NEW_DESIGNATION_ID) {
                    await LeagueService.deleteLeagueDesignation({
                        designationId: id,
                    });

                    dispatch(getLeague(false));
                }
                setDesignations((prevDesignations) =>
                    prevDesignations.filter((d: Core.Models.LeagueDesignation) => d.id !== id, [])
                );
            } catch (e) {
                const message = Core.API.getErrorMessage(e);
                toast.error(message);
            } finally {
                setIsLoading(false);
                setFocusedDesignation(null);
            }
        },
        [setDesignations, setIsLoading]
    );

    const changeDesignationFocus = useCallback(
        (id: string, mode: DesignationEditorMode) => {
            if (mode === DesignationEditorMode.Delete || mode === DesignationEditorMode.Edit) {
                setFocusedDesignation({
                    id,
                    mode,
                });
            } else {
                setFocusedDesignation(null);
            }
        },
        [setFocusedDesignation]
    );

    const saveDesignation = useCallback(
        async ({ id, color, iconName, name }: Core.Models.LeagueDesignation) => {
            try {
                const { designation } =
                    id === NEW_DESIGNATION_ID
                        ? await LeagueService.createLeagueDesignation({
                              color,
                              name,
                              iconName,
                          })
                        : await LeagueService.updateLeagueDesignations({
                              designationId: id,
                              color,
                              iconName,
                              name,
                          });

                dispatch(getLeague(false));

                // Updates specific list item stored in memory
                setDesignations((prevDesignations) =>
                    orderBy(
                        prevDesignations.reduce(
                            (acc: Core.Models.LeagueDesignation[], curr: Core.Models.LeagueDesignation) =>
                                curr.id === designation.id || curr.id === NEW_DESIGNATION_ID
                                    ? [...acc, designation]
                                    : [...acc, curr],
                            []
                        ),
                        'name'
                    )
                );
            } catch (e) {
                const message = Core.API.getErrorMessage(e);
                toast.error(message);
            }
        },
        [setDesignations]
    );

    return {
        changeDesignationFocus,
        createDesignation,
        deleteDesignation,
        designations,
        focusedDesignation,
        isLoading,
        saveDesignation,
    };
};

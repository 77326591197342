import React, { useCallback } from 'react';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../core';
import { SolidButton } from '../buttons-visuals';
import ErrorMessage from '../errorMessage';
import FormField from '../formField';

interface EditStreamFormProps {
    onComplete: () => Promise<void>;
    onEditStream: (request: Core.Models.EditStreamRequest) => Promise<void>;
    stream: Core.Models.Stream;
}

interface EditStreamFormValues extends Core.Models.EditStreamRequest {}

const EditStreamForm = (props: EditStreamFormProps): JSX.Element => {
    const { onComplete, onEditStream, stream } = props;

    const handleSubmit = useCallback(
        async (values: EditStreamFormValues, actions: FormikActions<EditStreamFormValues>) => {
            try {
                await onEditStream(values);
                toast.success('Stream successfully edited');
                await onComplete();
            } catch (error) {
                actions.setStatus(undefined);
                const message = Core.API.getErrorMessage(error);
                actions.setStatus(message);
            }
            actions.setSubmitting(false);
        },
        [onComplete, onEditStream]
    );

    return (
        <div className="edit-stream">
            <Formik<EditStreamFormValues>
                initialValues={{
                    streamId: stream.id,
                    username: stream.username,
                }}
                onSubmit={handleSubmit}
                validationSchema={{
                    username: Yup.string()
                        .required('Username is required')
                        .min(
                            Core.Constants.USERNAME_MIN_LENGTH,
                            `Username must be at least ${Core.Constants.USERNAME_MIN_LENGTH} characters`
                        )
                        .max(
                            Core.Constants.TWITCH_USERNAME_MAX_LENGTH,
                            `Username must be at most ${Core.Constants.TWITCH_USERNAME_MAX_LENGTH} characters`
                        ),
                }}
                render={(formProps: FormikProps<EditStreamFormValues>) => (
                    <Form>
                        <fieldset className="form-group">
                            <FormField type="text" name="username" description="Username" placeholder="Username" />
                        </fieldset>

                        {formProps.status && <ErrorMessage error={formProps.status} />}
                        <ErrorMessage error={formProps.errors} filter={formProps.touched} />

                        <fieldset className="form-group form-group--undecorated">
                            <SolidButton
                                as="button"
                                layout="full"
                                onClick={formProps.submitForm}
                                pending={formProps.isSubmitting}
                                size="medium"
                            >
                                Edit Stream
                            </SolidButton>
                        </fieldset>
                    </Form>
                )}
            />
        </div>
    );
};

export default EditStreamForm;

import * as React from 'react';
import { orderBy } from 'lodash';

import * as Core from '../../core';
import GameCard from '../gameCard';

import './index.scss';

interface GameInterestListProps {
    leagueGames: Core.Models.Game[];
    setSelectedGames: (gameIds: string[]) => void;
    selectedGames: string[];
}

const GameInterestList = (props: GameInterestListProps) => {
    const { leagueGames, selectedGames, setSelectedGames } = props;

    const onGameCardClick = (game: Core.Models.Game) => {
        let gameInterests = selectedGames;
        if (gameInterests.filter((gi: string) => gi === game.id).length > 0) {
            gameInterests = gameInterests.filter((gi: string) => gi !== game.id);
        } else {
            gameInterests.push(game.id);
        }

        setSelectedGames(gameInterests);
    };

    return (
        <>
            <p className="game-interest-list__message">I am interested in playing the following game titles:</p>
            <ul>
                {orderBy(leagueGames, (gi: Core.Models.Game) => gi.name).map((game: Core.Models.Game) => (
                    <li key={game.id} className="game-interest-list__list-item">
                        <button className="game-interest-list__btn" onClick={() => onGameCardClick(game)} type="button">
                            <GameCard
                                gameName={game.name}
                                imageUrl={game.cardUrl}
                                isSelecting
                                isSelected={selectedGames.filter((g: string) => g === game.id).length > 0}
                                size="small"
                            />
                        </button>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default GameInterestList;

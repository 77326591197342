export const ordinal = (inputValue: number) => {
	const value = inputValue % 100;
	if (Math.floor(value / 10) === 1) {
		return inputValue + 'th';
	}
	switch (value % 10) {
		case 1:
			return inputValue + 'st';
		case 2:
			return inputValue + 'nd';
		case 3:
			return inputValue + 'rd';
		default:
			return inputValue + 'th';
	}
}

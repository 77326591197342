import { uniq } from 'lodash';

import * as Core from '../core';

export default abstract class TiebreakerService {
    public static tiebreakersRequired(groupParticipants: Core.Models.GroupParticipant[]): boolean {
        const numGroupParticipants = groupParticipants.filter(
            (gp: Core.Models.GroupParticipant) => gp.isParticipating
        ).length;
        const numUniqueOutputRanks = uniq(
            groupParticipants
                .filter((gp: Core.Models.GroupParticipant) => gp.isParticipating)
                .map((gp: Core.Models.GroupParticipant) => gp.outputRank)
        ).length;
        return numGroupParticipants !== numUniqueOutputRanks;
    }

    public static tiebreakersApplied(
        groupParticipants: Core.Models.GroupParticipant[],
        appliedAutoTiebreakers: boolean,
        shouldShow: boolean
    ): boolean {
        return this.groupParticipantNeedsTiebreak(groupParticipants) && appliedAutoTiebreakers && shouldShow;
    }

    public static groupParticipantNeedsTiebreak(groupParticipants: Core.Models.GroupParticipant[]): boolean {
        return groupParticipants.some((gp: Core.Models.GroupParticipant) => gp.needsTieBreak);
    }
}

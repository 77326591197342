import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { head, isNumber, keys, max, orderBy, pickBy, range, some, toPairs, values } from 'lodash';
import pluralize from 'pluralize';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import AutoScoringData from './autoScoring';
import insufficientRosterIcon from '../../../assets/images/icon-error.svg';
import { LabelButton } from '../../../components/button';
import { SolidButton, TextButton } from '../../../components/buttons-visuals';
import InfoMessage from '../../../components/infoMessage';
import { Checkbox, TextInput } from '../../../components/inputs';
import Loading from '../../../components/loading';
import { usePromiseOperation } from '../../../hooks/promiseOperation';
import { MatchService } from '../../../services/matchService';

import './index.scss';

interface SubmitMatchGameResultProps {
    allowMatchGameTies: boolean;
    competitionStyle: Core.Models.CompetitionStyle;
    gameFeatureSet: Core.Models.FeatureSet;
    gameMinimumSeats: number;
    gameName: string;
    matchGame: Core.Models.MatchGame;
    matchId: string;
    matchParticipants: Core.Models.MatchParticipant[];
    matchParticipantUserIds: string[];
    minParticipantSeats: number;
    onCancel: () => void;
    onComplete: () => Promise<void>;
    requireResultScreenshots: boolean;
    resultFileExtensions?: string[];
    scoringType: Core.Models.ScoringType;
    tennisStyle: boolean;
    useSimplifiedCheckin: boolean;
}

interface Scores {
    [id: string]: number | undefined;
}

enum CategoricalOutcome {
    Win = 0,
    Loss = 1,
    Tie = 2,
}

// Returns a list of participant ID(s). Array with one item
//  if there's a clear winner and multiple items if tied.
const getParticipantIdsByScores = (scores: Scores) => {
    // Only one form field has data
    const entriesArr = Object.entries(scores);
    if (entriesArr.length <= 1 || entriesArr.some((i) => i === undefined)) return undefined;

    // Finds highest score. Explicitly checks if highest score is 0
    const winningScore = max(values(pickBy(scores, (i) => i || i === 0)));
    // Finds IDs associated to highest score
    const ids = keys(pickBy(scores, (i) => i === winningScore));

    return ids;
};

const SubmitMatchGameResult = ({
    allowMatchGameTies,
    competitionStyle,
    gameFeatureSet,
    gameMinimumSeats,
    gameName,
    matchGame,
    matchId,
    matchParticipants,
    matchParticipantUserIds,
    minParticipantSeats,
    onCancel,
    onComplete,
    requireResultScreenshots,
    resultFileExtensions,
    scoringType,
    tennisStyle,
    useSimplifiedCheckin,
}: SubmitMatchGameResultProps): JSX.Element => {
    const participants = useMemo(
        () => orderBy(matchParticipants, (mp: Core.Models.MatchParticipant) => mp.sortOrder),
        [matchParticipants]
    );

    const supportsAutomatedScoring = useMemo(
        () =>
            [Core.Models.FeatureSet.LeagueOfLegends, Core.Models.FeatureSet.LeagueOfLegendsAram].includes(
                gameFeatureSet
            ),
        [gameFeatureSet]
    );
    const supportsResultFileAutomation = useMemo(
        () => gameFeatureSet === Core.Models.FeatureSet.Fortnite,
        [gameFeatureSet]
    );

    var initialScores: Scores = {};
    (matchGame.results.length > 0
        ? orderBy(matchGame.results, (r) => r.submittedTimeUtc, 'desc')[0].scores
        : []
    ).forEach((s: Core.Models.MatchGameResultScore) => {
        initialScores[s.matchParticipantId] = s.score;
    });

    const [autoScoringData, setAutoScoringData] = useState<string | undefined>(undefined);
    const [resultFiles, setResultFiles] = useState<Core.Models.ResultFile[]>([]);
    const [scores, setScores] = useState<Scores>(initialScores);

    const canSubmit = useMemo(
        () =>
            participants.filter((mp: Core.Models.MatchParticipant) => {
                const score = scores[mp.id];
                if (!isNumber(score)) return false; // blanks are OK
                return !useSimplifiedCheckin && mp.rosterSize < minParticipantSeats; // but if it has a value and doesn't meet the roster size, not OK
            }).length <= 0,
        [minParticipantSeats, participants, scores]
    );

    const onScoreChanged = useCallback(
        (id: string, value: string) => {
            setScores({
                ...scores,
                [id]: !!value ? parseFloat(value) : undefined,
            });
        },
        [scores]
    );

    const onCategoricalScoreChanged = useCallback(
        (id: string, value: CategoricalOutcome) => {
            const newScores = Object.fromEntries(
                matchParticipants.map((mp: Core.Models.MatchParticipant) => {
                    if (value === CategoricalOutcome.Tie) return [mp.id, 0.5];
                    if (
                        (value === CategoricalOutcome.Win && mp.id !== id) ||
                        (value === CategoricalOutcome.Loss && mp.id === id)
                    ) {
                        return [mp.id, 0];
                    }
                    return [mp.id, 1];
                })
            );

            setScores(newScores);
        },
        [matchParticipants]
    );

    const onImageChanged = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const target = e.target;
            const files = await Promise.all(
                range(0, e.target.files.length).map(
                    (i) =>
                        new Promise<Core.Models.ResultFile>((resolve, reject) => {
                            const file = e.target.files![i];

                            let hasFileExtension;
                            resultFileExtensions?.forEach((rfe: string) => {
                                if (file.name.endsWith(rfe)) {
                                    hasFileExtension = true;
                                }
                            });

                            if (!file.type.startsWith('image/') && !hasFileExtension) {
                                toast.error('Invalid file type.');
                            }

                            if (!file.type.startsWith('image/')) {
                                if (file.size > Core.Constants.FILES.MAX_RESULT_FILE_SIZE)
                                    toast.error('The file you selected exceeds the maximum size of 50 MB');
                            }

                            const reader = new FileReader();
                            reader.onload = () => {
                                resolve({
                                    fileName: file.name,
                                    data: (reader.result as string).split(',')[1],
                                });
                            };
                            reader.onerror = reject;
                            reader.onabort = reject;
                            reader.readAsDataURL(file);
                        })
                )
            );
            setResultFiles(files);

            // reset the file input (if the user selects an image, closes without saving, then chooses same image)
            target.value = '';
        }
    };

    const [isSubmitting, submit, submitError] = usePromiseOperation(async () => {
        if (!hasAutoScoringInput) {
            if (
                competitionStyle === Core.Models.CompetitionStyle.HeadToHead &&
                keys(pickBy(scores, (i) => i !== undefined)).length !== participants.length
            ) {
                throw new Error('You must submit scores for all participants');
            }

            if (!allowMatchGameTies && ids && ids.length >= 2) {
                throw new Error('There must be a winner for this game because ties are not allowed.');
            }

            if (requireResultScreenshots && resultFiles.length <= 0) {
                throw new Error(
                    `You must submit a screenshot ${
                        !!formattedExtensions ? `or ${formattedExtensions} file` : ''
                    } of scores`
                );
            }
        }

        await MatchService.submitMatchGameResult({
            autoScoringData,
            matchGameId: matchGame.id,
            scores: !hasAutoScoringInput
                ? toPairs(scores).map((score: [string, number | undefined]) => ({
                      matchParticipantId: score[0],
                      score: score[1]!,
                  }))
                : undefined,
            screenshots: resultFiles,
        });
        await onComplete();
    });

    // Finds list of participant ID(s) with highest score
    const ids = useMemo(() => getParticipantIdsByScores(scores), [scores]);

    // Determines whether the ID is associated to a winner
    const isWinner = useCallback(
        (id: string): boolean => {
            if (!!!ids) return false;
            else return ids.length === 1 && ids[0] === id;
        },
        [ids]
    );

    // Returns game result; '-' if result cannot be determined
    const result = useCallback(
        (participantId: string): JSX.Element | string => {
            // test for incomplete team first
            const score = scores[participantId];
            const participant = head(
                participants.filter((mp: Core.Models.MatchParticipant) => mp.id === participantId)
            );
            if (!!participant && isNumber(score) && participant.rosterSize < minParticipantSeats)
                return <img src={insufficientRosterIcon} alt="" />;

            // then test for winner(s)
            if (!ids) return '-';
            if (!ids.includes(participantId)) return '-';

            if (ids.length === 1) return 'winner';
            else if (ids.length >= 2) return 'tie';

            return '-';
        },
        [ids, minParticipantSeats, participants, scores]
    );

    const acceptedInputs = ['image/*', ...(resultFileExtensions || [])].join(',.');
    const formattedExtensions = resultFileExtensions?.join(', ');

    const hasAutoScoringInput = useMemo(() => {
        if (gameFeatureSet === Core.Models.FeatureSet.Fortnite) {
            if (!resultFileExtensions?.length) return false;
            return some(resultFiles, (resultFile: Core.Models.ResultFile) =>
                some(resultFileExtensions, (resultFileExtension: string) =>
                    resultFile.fileName.toLowerCase().endsWith(resultFileExtension.toLowerCase())
                )
            );
        } else {
            return !!autoScoringData;
        }
    }, [autoScoringData, gameFeatureSet, resultFileExtensions, resultFiles]);

    return (
        <div className="submit-match-game-result">
            <AutoScoringData
                {...{
                    autoScoringData,
                    gameFeatureSet,
                    gameMinimumSeats,
                    hasResultFiles: resultFiles.length > 0,
                    matchId,
                    matchParticipantUserIds,
                    setAutoScoringData,
                    tennisStyle,
                }}
            />
            {!autoScoringData && ( // can't accept both a file and an auto-scoring code
                <>
                    <LabelButton className="m-auto disp-block" outline={!requireResultScreenshots} wide>
                        Select
                        {!!resultFileExtensions?.length ? ` ${formattedExtensions} file and/or ` : ' '}
                        screenshot(s)
                        {!requireResultScreenshots && ' (optional)'}
                        <input
                            accept={acceptedInputs}
                            className="image-uploader__file-input"
                            multiple={true}
                            onChange={onImageChanged}
                            type="file"
                        />
                    </LabelButton>
                    {resultFiles.map((resultFile: Core.Models.ResultFile, index: number) => (
                        <div key={index} className="image-uploader__file-name">
                            {resultFile.fileName}
                        </div>
                    ))}
                </>
            )}
            {supportsResultFileAutomation && (
                <InfoMessage
                    message={`
						${gameName} now supports automatic scoring from replay files. 
						You cannot submit a replay file and manual scores at the same time.
						This feature is experimental.
					`}
                    type="info"
                />
            )}
            {!hasAutoScoringInput && (
                <div className="submit-match-game-result__participants">
                    <div className="submit-match-game-result__participant">
                        <div>Team</div>
                        <div>{pluralize('Player', !!matchGame.tennisStyle ? 1 : 0)}</div>
                        <div>{scoringType === Core.Models.ScoringType.Discrete && 'Status'}</div>
                        <div>Result</div>
                    </div>
                    {participants.map((participant: Core.Models.MatchParticipant, ix: number) => {
                        const scoreInputId = 'submit-match-game-result__score__' + ix;
                        const player = matchGame.tennisStyle
                            ? participant.users.find((u: Core.Models.MatchParticipantUser) =>
                                  matchParticipantUserIds.some((id: string) => id === u.id)
                              )
                            : undefined;

                        return (
                            <div
                                className={classNames('submit-match-game-result__participant', {
                                    'submit-match-result__participant--winner':
                                        scoringType === Core.Models.ScoringType.Discrete && isWinner(participant.id),
                                })}
                                key={participant.id}
                            >
                                <div className="submit-match-game-result__participant-name">{participant.name}</div>
                                <div className="submit-match-game-result__participant-result truncate-single-line">
                                    {matchGame.tennisStyle
                                        ? player?.gamerHandle ?? player
                                            ? Core.Identity.renderMemberName(player)
                                            : 'Anonymous'
                                        : `${participant.rosterSize} / ${minParticipantSeats}`}
                                </div>
                                <div className="submit-match-game-result__participant-result">
                                    {scoringType === Core.Models.ScoringType.Discrete && result(participant.id)}
                                </div>
                                <div className="submit-match-game-result__participant-score">
                                    {scoringType === Core.Models.ScoringType.Discrete ? (
                                        <TextInput
                                            className="mb0"
                                            id={scoreInputId}
                                            min={0}
                                            label="Score"
                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                onScoreChanged(participant.id, e.target.value)
                                            }
                                            type="number"
                                            value={scores[participant.id]}
                                        />
                                    ) : scoringType === Core.Models.ScoringType.Categorical ? (
                                        <>
                                            <fieldset className="results-radio-group" role="group">
                                                <div className="results-radio-group__option">
                                                    <input
                                                        checked={isWinner(participant.id)}
                                                        id={`${participant.id}-win`}
                                                        onChange={() =>
                                                            onCategoricalScoreChanged(
                                                                participant.id,
                                                                CategoricalOutcome.Win
                                                            )
                                                        }
                                                        name={`${participant.id}-results`}
                                                        type="radio"
                                                    />
                                                    <label
                                                        className="results-radio-group__option__win"
                                                        htmlFor={`${participant.id}-win`}
                                                    >
                                                        W
                                                    </label>
                                                </div>
                                                <div className="results-radio-group__option">
                                                    <input
                                                        checked={!!ids && !ids.includes(participant.id)}
                                                        id={`${participant.id}-loss`}
                                                        onChange={() =>
                                                            onCategoricalScoreChanged(
                                                                participant.id,
                                                                CategoricalOutcome.Loss
                                                            )
                                                        }
                                                        name={`${participant.id}-results`}
                                                        type="radio"
                                                    />
                                                    <label
                                                        className="results-radio-group__option__loss"
                                                        htmlFor={`${participant.id}-loss`}
                                                    >
                                                        L
                                                    </label>
                                                </div>
                                                {allowMatchGameTies && (
                                                    <div className="results-radio-group__option">
                                                        <input
                                                            checked={!!ids && ids.length === matchParticipants.length}
                                                            id={`${participant.id}-tie`}
                                                            onChange={() =>
                                                                onCategoricalScoreChanged(
                                                                    participant.id,
                                                                    CategoricalOutcome.Tie
                                                                )
                                                            }
                                                            name={`${participant.id}-results`}
                                                            type="radio"
                                                        />
                                                        <label
                                                            className="results-radio-group__option__tie"
                                                            htmlFor={`${participant.id}-tie`}
                                                        >
                                                            T
                                                        </label>
                                                    </div>
                                                )}
                                            </fieldset>
                                        </>
                                    ) : (
                                        <>
                                            <Checkbox
                                                checked={!!scores[participant.id]}
                                                onChange={() =>
                                                    onScoreChanged(participant.id, !!scores[participant.id] ? '' : '1')
                                                }
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            {!canSubmit && (
                <InfoMessage
                    message="You cannot submit scores for teams that do not meet the roster minimum. Either add enough players for each team, or don't submit scores for teams that don't have enough players."
                    type="error"
                />
            )}
            <div className="vr">
                {isSubmitting && <Loading buttonLoader />}
                {supportsAutomatedScoring && (
                    <InfoMessage
                        message="Game reporting for this title is fully automated. Manually submitted scores may be overridden by results provided by the publisher."
                        type="info"
                    />
                )}
                {submitError && <InfoMessage message={submitError} type="error" />}
                <SolidButton
                    as="button"
                    disabled={!canSubmit || isSubmitting}
                    layout="full"
                    onClick={submit}
                    size="medium"
                >
                    Submit{supportsAutomatedScoring && ' anyway'}
                </SolidButton>
            </div>
            <div className="submit-match-game-result__cancel-button-container">
                <TextButton onClick={onCancel} className="mt2x">
                    Go Back
                </TextButton>
            </div>
        </div>
    );
};

export default SubmitMatchGameResult;

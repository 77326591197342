import * as React from 'react';
import classNames from 'classnames';
import { pick } from 'lodash';

import { Avatar } from '../buttons-visuals';

import './index.scss';

interface MediaObjectProps {
    avatarBackground?: boolean;
    captain?: boolean;
    chatDisabled?: boolean;
    children: React.ReactNode;
    className?: string;
    fallback?: 'organization' | 'team' | 'user';
    imageSize?: 'small' | 'large';
    imageUrl?: string;
    ineligible?: boolean;
    isUnder13?: boolean;
    manager?: boolean;
    title?: string;
    unverified?: boolean;
}

export const MediaObject = (props: MediaObjectProps): JSX.Element => {
    return (
        <div className={`media-object ${props.className}`}>
            <Avatar
                {...pick(props, ['captain', 'chatDisabled', 'fallback', 'ineligible', 'manager', 'unverified'])}
                className="mr2x"
                isUnder13={props.isUnder13}
                size={props.imageSize}
                src={props.imageUrl}
                title={props.title}
            />
            <div className="media-object__text">{props.children}</div>
        </div>
    );
};

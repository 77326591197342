import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import * as Core from '../../../core';
import { InputProps } from '../../../components/inputs/Inputs.types';
import { TextInput } from '../../../components/inputs/textinput';
import { SolidButton } from '../../buttons-visuals';

import './CopyTextField.scss';

const CopyTextField = (props: InputProps): JSX.Element => {
    const { className, id, label, layoutInline, value } = props;
    const [copySuccess, setCopySuccess] = useState<boolean>(false);

    const handleSuccess = () => {
        setCopySuccess(true);
        setTimeout(() => {
            setCopySuccess(false);
        }, 2000);
    };

    return (
        <div className={classNames(className, 'ds-copy-text-field', { 'fit-content': layoutInline })}>
            <TextInput className="ds-copy-text-field__input" id={id} label={label} readOnly type="text" value={value} />
            <SolidButton
                as="button"
                size="large"
                className="ds-copy-text-field__button"
                onClick={() => {
                    Core.Utils.copyToClipboard(value?.toString());
                    handleSuccess();
                }}
            >
                <FontAwesomeIcon icon={['fas', copySuccess ? 'check' : 'copy']} size="1x" />
            </SolidButton>
        </div>
    );
};

export default CopyTextField;

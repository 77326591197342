import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import { debounce, range } from 'lodash';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../core';
import { HollowButton, IconButton, SolidButton } from '../../components/buttons-visuals';
import { ContentContainer } from '../../components/containers';
import FormField from '../../components/formField';
import CustomRegistrationFormFields from '../../components/formField/customRegistrationFormFields';
import InfoMessage from '../../components/infoMessage';
import { FieldSet } from '../../components/inputs';
import { useLeague, useTimezone } from '../../hooks/store';
import { UserService } from '../../services/userService';

import './index.scss';

interface EditUserDetailsProps {
    canEditGlobalUser: boolean;
    canEditLeagueUser: boolean;
    isAdmin: boolean;
    isLeagueHostPlus: boolean;
    isMe: boolean;
    onCancel: () => void;
    onSubmit: () => Promise<void>;
    profile: Core.Models.UserProfile;
    userId: string;
}

interface EditMyProfileValues {
    birthdate?: string;
    customRegistrationFields?: Core.Models.CustomRegistrationFieldSubmissionRequest[];
    email?: string;
    firstName: string;
    gamerHandle?: string;
    lastName: string;
    parentEmail?: string;
    preferredTimezone?: string;
    pronouns?: string;
    username: string;
}

const LEAGUE_TIMEZONE = 'league_timezone';

const EditUserDetails = (props: EditUserDetailsProps): JSX.Element => {
    const {
        canEditGlobalUser,
        canEditLeagueUser,
        isAdmin,
        isLeagueHostPlus,
        isMe,
        onCancel,
        onSubmit,
        profile,
        userId,
    } = props;

    const timezone = useTimezone();
    const league = useLeague();

    const [emailInUse, setEmailInUse] = useState<boolean>(false);
    const [usernameInUse, setUsernameInUse] = useState<boolean>(false);

    const schema = Yup.object().shape({
        birthdate: Yup.string().test(
            'is-valid',
            'Please enter a valid date of birth',
            (birthdate: string | undefined) => {
                if (!birthdate) return true; // null birthdate is ok
                const { birthdateIsValid } = Core.Time.checkBirthdateStringUnder13(birthdate);
                return birthdateIsValid;
            }
        ),
        email: Yup.string()
            .email('Email must be formatted like an email address')
            .test('unique', 'Email is taken', (e: string | undefined) => (!!e ? !emailInUse : false))
            .notRequired()
            .nullable(),
        firstName: Yup.string()
            .required('First name is required.')
            .max(
                Core.Constants.NAME_MAX_LENGTH,
                `First name is too long. (${Core.Constants.NAME_MAX_LENGTH} character maximum)`
            ),
        lastName: Yup.string()
            .required('Last name is required.')
            .max(
                Core.Constants.NAME_MAX_LENGTH,
                `Last name is too long. (${Core.Constants.NAME_MAX_LENGTH} character maximum)`
            ),
        gamerHandle: Yup.string()
            .nullable()
            .max(
                Core.Constants.GAMER_HANDLE_MAX_LENGTH,
                `Gamer nickname is too long. (${Core.Constants.GAMER_HANDLE_MAX_LENGTH} character maximum)`
            )
            .test(
                'is-required-u13',
                'Gamer nickname is required for users under 13',
                (nickname: string | null | undefined) =>
                    !profile.isUnder13 || (!!nickname && nickname.trim().length > 0)
            ),
        parentEmail: Yup.string()
            .email('Parent email must be formatted like an email address')
            .notRequired()
            .nullable(),
        pronouns: Yup.string()
            .oneOf([null, ...Core.Constants.PRONOUNS])
            .notRequired()
            .nullable(),
        username: Yup.string()
            .min(
                Core.Constants.USERNAME_MIN_LENGTH,
                `Username must be ${Core.Constants.USERNAME_MIN_LENGTH} characters or more`
            )
            .max(
                Core.Constants.USERNAME_MAX_LENGTH,
                `Username must be ${Core.Constants.USERNAME_MAX_LENGTH} characters or fewer`
            )
            .required('Username is required')
            .test('unique', 'Username is taken', (u: string | undefined) => (!!u ? !usernameInUse : false))
            .test(
                'valid-characters',
                'Usernames may only contain letters, numbers, and -._@+',
                (u: string | undefined) => !!u && Core.Validation.isUsername(u)
            ),
    });

    const updateEmail = debounce(async (passedEmail) => {
        try {
            const isValid = /.+@.+\..+/.test(passedEmail);
            if (!isValid) {
                setEmailInUse(false);
                return;
            }

            const inUse = (await UserService.validateEmail(passedEmail)).isInUse;
            setEmailInUse(inUse && passedEmail !== profile.email);
        } catch (e) {
            toast.error('There was an issue checking the availability of this email. Please try again.');
        }
    }, Core.Constants.FORM_DEBOUNCE_TIME_MS);

    const updateUsername = debounce(async (passedUsername) => {
        try {
            const isValid = Core.Validation.isUsername(passedUsername);
            if (!isValid) return setUsernameInUse(false);

            const inUse = (await UserService.validateUsername(Core.Models.RegistrationAction.None, passedUsername))
                .isInUse;
            setUsernameInUse(inUse && passedUsername !== profile.username);
        } catch (e) {
            toast.error('There was an issue checking the availability of this username. Please try again.');
        }
    }, Core.Constants.FORM_DEBOUNCE_TIME_MS);

    const saveChanges = async (values: EditMyProfileValues, actions: FormikActions<EditMyProfileValues>) => {
        try {
            if (!values.customRegistrationFields) values.customRegistrationFields = [];

            if (
                profile.leagueCustomFields &&
                values.customRegistrationFields &&
                values.customRegistrationFields.length
            ) {
                if (values.customRegistrationFields.length !== profile.leagueCustomFields.length)
                    throw new Error('Custom registration fields passed in and collected are not of same length');

                for (let i = 0; i < values.customRegistrationFields.length; i++) {
                    values.customRegistrationFields[i].id = profile.leagueCustomFields[i].field.id;
                    values.customRegistrationFields[i].name = profile.leagueCustomFields[i].field.name;
                }
            }

            if (values.preferredTimezone === LEAGUE_TIMEZONE) {
                values.preferredTimezone = undefined;
            }

            await UserService.editProfile({
                ...values,
                birthdate:
                    (isAdmin || (isMe && !profile.birthdate)) && !!values.birthdate
                        ? moment.tz(values.birthdate, timezone).format(Core.Time.getFormat())
                        : undefined,
                customRegistrationFields: values.customRegistrationFields,
                email: isLeagueHostPlus ? values.email : undefined,
                id: userId,
                parentEmail: isLeagueHostPlus && !!values.parentEmail ? values.parentEmail : undefined,
            });

            await onSubmit();
        } catch (e) {
            const message = Core.API.getErrorMessage(e);
            actions.setStatus(message);
        }
        actions.setSubmitting(false);
    };

    return (
        <Formik
            initialValues={{
                birthdate: profile.birthdate || '',
                customRegistrationFields:
                    profile.leagueCustomFields && profile.leagueCustomFields.length
                        ? profile.leagueCustomFields.map((x) => ({
                              id: x.field.id,
                              name: x.field.name,
                              value: x.value,
                          }))
                        : range(
                              0,
                              (profile.leagueCustomFields && profile.leagueCustomFields.length) || 0
                          ).map<Core.Models.CustomRegistrationFieldSubmissionRequest>(() => ({
                              id: '',
                              name: '',
                              value: '',
                          })),
                email: profile.email ?? '',
                firstName: profile.firstName,
                gamerHandle: profile.gamerHandle,
                lastName: profile.lastName,
                parentEmail: profile.parentEmail ?? '',
                preferredTimezone: profile.preferredTimezone || LEAGUE_TIMEZONE,
                pronouns: profile.pronouns || '',
                username: profile.username!,
            }}
            onSubmit={saveChanges}
            validationSchema={schema}
            render={(formProps: FormikProps<EditMyProfileValues>) => (
                <ContentContainer shade={Core.Models.Shades.Dark40} className="mb4x">
                    <Form>
                        <div className="disp-flex align-center justify-between mb2x">
                            <h2 className="heading-2 mb0">Edit Profile</h2>
                            <div className="disp-flex align-center flex-gap">
                                <SolidButton
                                    as="button"
                                    layout="inline"
                                    onClick={formProps.submitForm}
                                    pending={formProps.isSubmitting}
                                    size="small"
                                >
                                    Save
                                </SolidButton>
                                <IconButton
                                    as="button"
                                    buttonLabel="Cancel"
                                    buttonSize="medium"
                                    color="destructive"
                                    onClick={() => onCancel()}
                                    disabled={formProps.isSubmitting}
                                >
                                    <FontAwesomeIcon icon={['fas', 'circle-xmark']} />
                                </IconButton>
                            </div>
                        </div>
                        <div className="grid-columns grid-columns--2 grid-columns--for-large">
                            <FormField
                                description="First Name"
                                disabled={!canEditGlobalUser}
                                name="firstName"
                                type="text"
                                value={profile.firstName}
                                {...(!canEditGlobalUser && {
                                    'aria-describedby': 'Only this user or an administrator can edit this field',
                                })}
                            />
                            <FormField
                                description="Last Name"
                                disabled={!canEditGlobalUser}
                                name="lastName"
                                type="text"
                                value={profile.lastName}
                                {...(!canEditGlobalUser && {
                                    'aria-describedby': 'Only this user or an administrator can edit this field',
                                })}
                            />
                        </div>

                        <FormField
                            component="select"
                            description="Pronouns"
                            disabled={!canEditGlobalUser}
                            name="pronouns"
                            value={profile.pronouns}
                            {...(!canEditGlobalUser && {
                                'aria-describedby': 'Only this user or an administrator can edit this field',
                            })}
                        >
                            <option value="">Don't show</option>
                            {Core.Constants.PRONOUNS.map((p: string) => (
                                <option key={p} value={p}>
                                    {p}
                                </option>
                            ))}
                        </FormField>

                        <FormField
                            description="Gamer Nickname (What should people call you?)"
                            disabled={!canEditGlobalUser}
                            name="gamerHandle"
                            type="text"
                            value={profile.gamerHandle}
                            {...(!canEditGlobalUser && {
                                'aria-describedby': 'Only this user or an administrator can edit this field',
                            })}
                        />

                        <FormField
                            description="Username"
                            disabled={!canEditGlobalUser}
                            name="username"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateUsername(e.target.value)}
                            type="text"
                            value={profile.username}
                            {...(usernameInUse && { errorMessage: 'This username is taken.' })}
                            {...(!canEditGlobalUser && {
                                'aria-describedby': 'Only this user or an administrator can edit this field',
                            })}
                        />

                        {!!profile.email && (
                            <FormField
                                description="Email"
                                disabled={!isLeagueHostPlus}
                                name="email"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateEmail(e.target.value)}
                                type="text"
                                value={profile.email}
                                {...(emailInUse && { errorMessage: 'This email is taken.' })}
                                {...(!isLeagueHostPlus && {
                                    'aria-describedby':
                                        'Please contact your league host if you want to change this field',
                                })}
                            />
                        )}

                        {!!profile.isUnder13 && (
                            <FormField
                                description="Parent email"
                                disabled={!isLeagueHostPlus}
                                name="parentEmail"
                                type="text"
                                value={profile.parentEmail}
                                {...(!isLeagueHostPlus && {
                                    'aria-describedby':
                                        'Please contact your league host if you want to change this field',
                                })}
                            />
                        )}

                        {(canEditLeagueUser || !!profile.birthdate) && (
                            <FormField
                                component="date"
                                contextualProps={{
                                    disabled: !(isAdmin || (isMe && !profile.birthdate)),
                                    hidePicker: true,
                                }} // isn't an admin or is me and `birthdate` already exists
                                id="birthdate"
                                label={`Birthdate (${Core.Time.getFormat()})`}
                                name="birthdate"
                                aria-describedby="Please contact an administrator if you want to change this field"
                            />
                        )}

                        <FormField
                            component="select"
                            description="Time zone"
                            disabled={!canEditGlobalUser}
                            name="preferredTimezone"
                            value={profile.preferredTimezone || LEAGUE_TIMEZONE}
                            {...(!canEditGlobalUser && {
                                'aria-describedby': 'Only this user or an administrator can edit this field',
                            })}
                        >
                            <option key={-1} value={''} hidden disabled>
                                Select time zone
                            </option>
                            <option value={LEAGUE_TIMEZONE}>Use league timezone ({league?.timezone})</option>
                            {Core.Constants.SUPPORTED_TIMEZONES.map((tz: string, ix: number) => {
                                return (
                                    <option key={ix} value={tz}>
                                        {tz}
                                    </option>
                                );
                            })}
                        </FormField>

                        {!!profile.leagueCustomFields && profile.leagueCustomFields.length > 0 && (
                            <FieldSet legend="League Custom Fields">
                                <CustomRegistrationFormFields customRegistrationFields={profile.leagueCustomFields} />
                            </FieldSet>
                        )}

                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                        <InfoMessage message={formProps.errors} type="error" />

                        <p className="user-profile__clean-content-reminder">
                            <i>
                                Submitting inappropriate information will result in a ban from this platform at the
                                discretion of the league host.
                            </i>
                        </p>
                        <div className="grid-columns grid-columns--2">
                            <HollowButton
                                as="button"
                                color="secondary"
                                onClick={() => onCancel()}
                                pending={formProps.isSubmitting}
                            >
                                Cancel
                            </HollowButton>
                            <SolidButton as="button" onClick={formProps.submitForm} pending={formProps.isSubmitting}>
                                Save
                            </SolidButton>
                        </div>
                    </Form>
                </ContentContainer>
            )}
        />
    );
};

export default EditUserDetails;

import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../core';
import { AddButton, IconButton, SolidButton } from '../buttons-visuals';
import ConfirmModal from '../confirmModal';
import { ContentContainer } from '../containers';
import EditStream from '../editStream';
import ErrorMessage from '../errorMessage';
import FormField from '../formField';
import { ToggleSwitch } from '../inputs';
import Modal from '../overlays/modal/Modal';
import withLoading, { WithLoadingProps } from '../withLoading';

interface StreamSettingsProps extends WithLoadingProps {
    className?: string;
    loadStreams: () => Promise<Core.Models.Stream[]>;
    onAddStream: (request: Core.Models.CreateLeagueStreamRequest) => Promise<void>;
    onDeleteStream: (streamId: string) => Promise<void>;
    onEditStream: (request: Core.Models.EditStreamRequest) => Promise<void>;
    onEnableStream: (request: Core.Models.EnableStreamRequest) => Promise<void>;
    streamEntityName: string;
}

interface AddStreamFormValues extends Core.Models.CreateLeagueStreamRequest {}

const StreamSettings = (props: StreamSettingsProps): JSX.Element => {
    const {
        className,
        loadStreams,
        onAddStream,
        onDeleteStream,
        onEditStream,
        onEnableStream,
        setError,
        setIsLoading,
        streamEntityName,
    } = props;

    const [isAddingStream, setIsAddingStream] = useState<boolean>(false);
    const [streams, setStreams] = useState<Core.Models.Stream[]>([]);
    const [streamToDelete, setStreamToDelete] = useState<Core.Models.Stream | undefined>(undefined);
    const [streamToEdit, setStreamToEdit] = useState<Core.Models.Stream | undefined>(undefined);

    const load = useCallback(async () => {
        try {
            const streams = await loadStreams();
            setStreams(streams);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [loadStreams, setError, setIsLoading]);

    useEffect(() => {
        (async () => {
            await load();
        })();
    }, [load]);

    const deleteStream = useCallback(async () => {
        if (!streamToDelete) return;
        try {
            await onDeleteStream(streamToDelete.id);
            toast.success(`Stream successfully deleted`);
            await load();
        } catch (err) {
            toast.error('Unable to delete stream');
        } finally {
            setStreamToDelete(undefined);
        }
    }, [load, onDeleteStream, streamToDelete]);

    const handleToggleStream = useCallback(
        async (value: boolean, streamId: string): Promise<void> => {
            try {
                await onEnableStream({
                    isEnabled: value,
                    streamId,
                });
                toast.success(`Stream successfully ${value ? 'enabled' : 'disabled'}`);
                await load();
            } catch (err) {
                toast.error('Unable to toggle stream');
            }
        },
        [load, onEnableStream]
    );

    const submitNewStream = useCallback(
        async (values: AddStreamFormValues, actions: FormikActions<AddStreamFormValues>) => {
            try {
                await onAddStream(values);
                toast.success(`Added stream for ${values.username}`);
                await load();
                setIsAddingStream(false);
            } catch (error) {
                const message = Core.API.getErrorMessage(error);
                actions.setStatus(message);
            } finally {
                actions.setSubmitting(false);
            }
        },
        [load, onAddStream]
    );

    return (
        <ContentContainer className={classNames(className, 'stream-settings')} shade={Core.Models.Shades.Dark40}>
            <AddButton
                as="button"
                buttonSize="medium"
                buttonStyle="solid"
                className="mb3x"
                onClick={() => setIsAddingStream(true)}
            >
                Add stream
            </AddButton>

            {!!streams &&
                streams.length > 0 &&
                streams.map((stream: Core.Models.Stream) => {
                    return (
                        <div key={stream.id} className="disp-flex align-center justify-between">
                            <div className="disp-flex align-center">
                                <ToggleSwitch
                                    className="mb0"
                                    onToggleSwitch={(value: boolean) => handleToggleStream(value, stream.id)}
                                    value={stream.isEnabled}
                                />
                                <div>{stream.username}</div>
                            </div>
                            <div>
                                <IconButton
                                    as="button"
                                    buttonLabel="Edit stream"
                                    buttonSize="medium"
                                    className="mr ml2x"
                                    onClick={() => setStreamToEdit(stream)}
                                >
                                    <FontAwesomeIcon icon={['fas', 'edit']} />
                                </IconButton>
                                <IconButton
                                    as="button"
                                    buttonLabel="Delete stream"
                                    buttonSize="medium"
                                    onClick={() => {
                                        setStreamToDelete(stream);
                                    }}
                                >
                                    <FontAwesomeIcon icon={['fas', 'trash-can']} />
                                </IconButton>
                            </div>
                        </div>
                    );
                })}

            {!!streams && streams.length <= 0 && (
                <p>This {streamEntityName.toLowerCase()} doesn't have any streams yet.</p>
            )}
            {streamToEdit && (
                <Modal
                    isOpen={streamToEdit !== undefined}
                    onClose={() => setStreamToEdit(undefined)}
                    title="Edit stream"
                >
                    <EditStream
                        stream={streamToEdit}
                        onComplete={async () => {
                            await load();
                            setStreamToEdit(undefined);
                        }}
                        onEditStream={onEditStream}
                    />
                </Modal>
            )}

            <Modal isOpen={isAddingStream} onClose={() => setIsAddingStream(false)} title="Add new stream">
                <Formik
                    initialValues={{ username: '' }}
                    onSubmit={submitNewStream}
                    validationSchema={{
                        username: Yup.string()
                            .required('Username is required')
                            .min(
                                Core.Constants.USERNAME_MIN_LENGTH,
                                `Username must be at least ${Core.Constants.USERNAME_MIN_LENGTH} characters`
                            )
                            .max(
                                Core.Constants.TWITCH_USERNAME_MAX_LENGTH,
                                `Username must be at most ${Core.Constants.TWITCH_USERNAME_MAX_LENGTH} characters`
                            ),
                    }}
                    render={(props: FormikProps<AddStreamFormValues>) => (
                        <Form>
                            <fieldset className="form-group">
                                <FormField description="Username" name="username" placeholder="Username" type="text" />
                            </fieldset>

                            {props.status && <ErrorMessage error={props.status} />}
                            <ErrorMessage error={props.errors} filter={props.touched} />

                            <fieldset className="form-group form-group--undecorated">
                                <SolidButton
                                    as="button"
                                    className="full-width"
                                    disabled={props.isSubmitting}
                                    onClick={props.submitForm}
                                    pending={props.isSubmitting}
                                >
                                    Add new stream
                                </SolidButton>
                            </fieldset>
                        </Form>
                    )}
                />
            </Modal>

            {streamToDelete && (
                <ConfirmModal
                    confirmText="Yes"
                    onCancel={() => setStreamToDelete(undefined)}
                    onConfirm={deleteStream}
                    title="Are you sure"
                >
                    <p>
                        Are you sure you want to delete <em>{streamToDelete.username}</em>?
                    </p>
                </ConfirmModal>
            )}
        </ContentContainer>
    );
};

export default withLoading(StreamSettings, { loadingProps: { blockItem: true } });

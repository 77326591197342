import React from 'react';

import * as Core from '../../core';
import MyLeagues from './myLeagues';
import Search from './search';

import './leagueSearch.scss';

const LeagueSearch = (): JSX.Element => (
    <div className="page league-search">
        <MyLeagues />
        <Search />
    </div>
);

interface LeagueSearchResultCardProps {
    leagueSearchResult: Core.Models.LeagueSearchResult;
}

export const LeagueSearchResultCard = ({ leagueSearchResult }: LeagueSearchResultCardProps): JSX.Element => (
    <a
        className="league-search__result-card disp-flex flex-dir-row justify-center align-center"
        href={leagueSearchResult.url}
    >
        {!!leagueSearchResult.logoUrl && (
            <img src={leagueSearchResult.logoUrl} className="league-search__result-card__logo mr2x" />
        )}
        <div className="league-search__result-card__details full-width">
            <h3>{leagueSearchResult.name}</h3>
            <p>
                Members:{' '}
                <span className="metric">
                    {leagueSearchResult.members.toLocaleString(undefined, { useGrouping: true })}
                </span>
            </p>
            <p>
                Active Seasons:{' '}
                <span className="metric">
                    {leagueSearchResult.seasons.toLocaleString(undefined, { useGrouping: true })}
                </span>
            </p>
            <p>
                Titles:{' '}
                <span className="metric">
                    {leagueSearchResult.titles.toLocaleString(undefined, { useGrouping: true })}
                </span>
            </p>
        </div>
    </a>
);

export default LeagueSearch;

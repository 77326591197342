import React, { useCallback } from 'react';

import * as Core from '../../core';
import SeasonCardGeneric from './generic';
import { useModal } from '../../hooks/modal';
import { useAlternateSeasonName } from '../../hooks/store';
import JoinSeason from '../../pages/league/joinSeason';

export interface SeasonCardProps {
    alreadyInSeason?: boolean;
    canJoinSeason?: boolean;
    canRegisterForSeason?: boolean;
    joinButtonText?: string;
    reloadDataOnJoin?: () => Promise<void>;
    season: Core.Models.Season;
    showImage?: boolean;
    showSeasonOperator?: boolean;
    showTimingDescription?: boolean;
}

export interface SeasonCardDisplayProps {
    alreadyInSeason: boolean;
    canJoinSeason: boolean;
    canRegisterForSeason: boolean;
    joinButtonText: string;
    joinSeasonModal: React.ReactNode;
    maxParticipants?: number;
    numParticipants?: number;
    onJoinSeasonClicked: (e: React.MouseEvent) => void;
    season: Core.Models.Season;
    seasonAlternateName: string;
    showImage: boolean;
    showSeasonOperator: boolean;
    showTimingDescription: boolean;
}

const SeasonCard = (props: SeasonCardProps): JSX.Element => {
    const {
        alreadyInSeason,
        canJoinSeason,
        canRegisterForSeason,
        joinButtonText,
        reloadDataOnJoin,
        season,
        showImage,
        showSeasonOperator,
        showTimingDescription,
    } = props;

    const seasonAlternateName = useAlternateSeasonName();

    const [currentlyJoiningSeason, setCurrentlyJoiningSeason] = React.useState<Core.Models.Season>();
    const [joinSeasonModal, openJoinSeason, closeJoinSeason] = useModal(
        () => `Join ${seasonAlternateName}`,
        () => (
            <JoinSeason
                onComplete={async () => closeJoinSeason()}
                season={currentlyJoiningSeason}
                reloadData={reloadDataOnJoin}
            />
        )
    );

    const onJoinSeasonClicked = useCallback(
        (e: React.MouseEvent): void => {
            // allow button to sit over a link
            e.preventDefault();
            e.stopPropagation();

            setCurrentlyJoiningSeason(season);
            openJoinSeason();
        },
        [season, setCurrentlyJoiningSeason, openJoinSeason]
    );

    return (
        <SeasonCardGeneric
            {...{
                alreadyInSeason: !!alreadyInSeason,
                canJoinSeason: !!canJoinSeason,
                canRegisterForSeason: !!canRegisterForSeason,
                joinButtonText: joinButtonText || 'Join',
                joinSeasonModal,
                maxParticipants: season.maxParticipants,
                numParticipants: season.participants.filter((p: Core.Models.Participant) => p.isParticipating).length,
                onJoinSeasonClicked,
                season,
                seasonAlternateName,
                showImage: !!showImage,
                showSeasonOperator: !!showSeasonOperator,
                showTimingDescription: !!showTimingDescription,
            }}
        />
    );
};

export default SeasonCard;

import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orderBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import AddHelpfulLink from '../../components/addHelpfulLink';
import { AddButton, IconButton } from '../../components/buttons-visuals';
import ConfirmModal from '../../components/confirmModal';
import { ContentContainer } from '../../components/containers';
import DraggableList from '../../components/draggableList';
import Modal from '../../components/modal';
import { useLeagueConfiguration } from '../../hooks/store';
import { LeagueService } from '../../services/leagueService';
import { getLeague } from '../../store/league/actions';

const HelpfulLinks = () => {
    const [helpfulLinks, setHelpfulLinks] = useState<Core.Models.HelpfulLink[]>([]);
    const [isAddingLink, setIsAddingLink] = useState<boolean>(false);
    const [isUpdatingOrder, setIsUpdatingOrder] = useState<boolean>(false);
    const [linkBeingDeleted, setLinkBeingDeleted] = useState<Core.Models.HelpfulLink | undefined>(undefined);

    const leagueConfiguration = useLeagueConfiguration();
    const dispatch = useDispatch();

    useEffect(
        () =>
            setHelpfulLinks(
                orderBy(leagueConfiguration?.helpfulLinks, (hl: Core.Models.HelpfulLink) => hl.sortOrder) || []
            ),
        [leagueConfiguration?.helpfulLinks]
    );

    const reloadLeague = useCallback(async () => dispatch(getLeague(false)), [dispatch]);
    const addLink = useCallback(() => setIsAddingLink(true), [setIsAddingLink]);

    const updateOrder = useCallback(
        async (helpfulLinks: Core.Models.HelpfulLink[]) => {
            // save new order immediately and revert only if update fails
            setHelpfulLinks(helpfulLinks);
            setIsUpdatingOrder(true);
            try {
                const leagueHelpfulLinkIds = helpfulLinks.map((hl: Core.Models.HelpfulLink) => hl.id);
                await LeagueService.updateLeagueHelpfulLinkOrder({ leagueHelpfulLinkIds });
                await reloadLeague();
            } catch {
                toast.error('Helpful link re-order failed. Please try again.');
                setHelpfulLinks(
                    orderBy(leagueConfiguration?.helpfulLinks || [], (hl: Core.Models.HelpfulLink) => hl.sortOrder)
                );
            } finally {
                setIsUpdatingOrder(false);
            }
        },
        [leagueConfiguration?.helpfulLinks, reloadLeague]
    );

    return (
        <ContentContainer className="global-container-centered" shade={Core.Models.Shades.Dark40}>
            <AddButton
                as="button"
                buttonStyle="solid"
                buttonSize="medium"
                className="mb2x"
                disabled={isAddingLink}
                onClick={addLink}
            >
                Add Helpful Link
            </AddButton>

            <div>
                {helpfulLinks.length === 0 ? (
                    <p>
                        Helpful links are links that show up on the left navigation bar for users that interact with
                        your league, with an option to only show to logged-in users. Common uses of helpful links are
                        for rulebooks, eligibility guidelines, guidebooks, sponsor pages, etc. You currently have no
                        helpful links - add one here!
                    </p>
                ) : (
                    <DraggableList
                        disabled={isUpdatingOrder}
                        getId={(hl: Core.Models.HelpfulLink) => hl.id}
                        items={helpfulLinks}
                        renderItem={(hl: Core.Models.HelpfulLink) => {
                            return (
                                <div className="disp-flex justify-between align-center mb">
                                    <div className="ml league-settings-draggable__handle" aria-hidden="true" />
                                    <a className="ml2x" href={hl.url} rel="noopener noreferrer" target="_blank">
                                        {hl.name}
                                        {hl.disablePublic && (
                                            <FontAwesomeIcon className="ml" icon={['fas', 'lock']} size="xs" />
                                        )}
                                    </a>
                                    <IconButton
                                        as="button"
                                        buttonLabel="Delete"
                                        buttonSize="medium"
                                        onClick={() => setLinkBeingDeleted(hl)}
                                    >
                                        <FontAwesomeIcon className="color-error" icon={['fas', 'circle-minus']} />
                                    </IconButton>
                                </div>
                            );
                        }}
                        setItems={updateOrder}
                    />
                )}
            </div>

            {isAddingLink && (
                <Modal onClose={() => setIsAddingLink(false)} title={'Add helpful link'}>
                    <AddHelpfulLink
                        onComplete={async () => {
                            await reloadLeague();
                            setIsAddingLink(false);
                        }}
                    />
                </Modal>
            )}

            {linkBeingDeleted && (
                <ConfirmModal
                    confirmText="Yes"
                    onCancel={() => setLinkBeingDeleted(undefined)}
                    onConfirm={async () => {
                        await LeagueService.deleteLeagueHelpfulLink(linkBeingDeleted!.id);
                        await reloadLeague();
                        setLinkBeingDeleted(undefined);
                    }}
                    title="Are you sure"
                >
                    <p>
                        Are you sure you want to delete <em>{linkBeingDeleted.name}</em>?
                    </p>
                </ConfirmModal>
            )}
        </ContentContainer>
    );
};

export default HelpfulLinks;

import { isUndefined } from 'lodash';

import * as Core from '../core';
import { useLeague } from './store';

const useIsFeatureGroup = (featureGroups: Core.Models.FeatureGroup[]): boolean => {
    const league = useLeague();

    if (!league || isUndefined(league?.featureGroup)) return false;
    return featureGroups.includes(league.featureGroup);
};

export default useIsFeatureGroup;

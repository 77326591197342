import {
    BaseCore,
    convertCurrencyToNumber,
    convertNumberToCurrency,
    generateFullAuthorizeUrl,
    getEnumAsIntArray,
    Models,
    ordinal,
    renderMemberName,
} from '@leaguespot/platform-js';
import API from './api';
import { copyToClipboard } from './clipboard';
import Configuration from './configuration';
import * as Time from './time';
import * as Validation from './validation';

// Defines objects here for backward compat after PlatformJs implementation.
const Currency = {
    convertCurrencyToNumber,
    convertNumberToCurrency,
};

const Identity = {
    renderMemberName,
};

const Number = {
    ordinal,
};

const Utils = {
    copyToClipboard,
    generateFullAuthorizeUrl,
    getEnumAsIntArray,
    isDiscounted: (payable: Models.Payable) => !!payable.discountFee && !!payable.discountEndUtc && payable.discountEndUtc > new Date(Date.now()).toISOString()
};

const { Access, Competition, Constants } = BaseCore;
export {
    Access,
    API,
    Competition,
    Configuration,
    Constants,
    Currency,
    Identity,
    Models,
    Number,
    Time,
    Utils,
    Validation,
};

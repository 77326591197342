import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { range, sortBy } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as Core from '../../core';
import AddTeamMemberForm from './addTeamMemberForm';
import { useConfirmModal } from '../../hooks/confirmModal';
import { useLeague } from '../../hooks/store';
import { TeamService } from '../../services/teamService';
import { UserPermissionService } from '../../services/userPermissionService';
import { AppState } from '../../store';
import { getPermissions } from '../../store/permissions/actions';
import { getUserPermissionService } from '../../store/permissions/selector';
import { Button } from '../button';
import { Avatar, SolidButton } from '../buttons-visuals';
import ConfirmModal from '../confirmModal';
import ErrorMessage from '../errorMessage';
import Loading from '../loading';
import Menu from '../menu';
import Modal from '../modal';

import './index.scss';

interface TeamMembersProps {
    canAdd?: boolean;
    canEdit?: boolean;
    getPermissions: () => Promise<void>;
    playersCanLeaveTeams: boolean;
    team: Core.Models.Team;
    userId?: string;
    userPermissionService: UserPermissionService;
}

function getIsCaptain(member: Partial<Core.Models.TeamMember>) {
    return member.roleId === Core.Models.TeamRoleId.Captain;
}

function getIsCoach(member: Partial<Core.Models.TeamMember>) {
    return member.roleId === Core.Models.TeamRoleId.Coach;
}

function getIsPlayer(member: Partial<Core.Models.TeamMember>) {
    return member.roleId === Core.Models.TeamRoleId.Player;
}

function getIsIneligible(member: Partial<Core.Models.TeamMember>) {
    return member.isEligible === false;
}

const sortMembers = [
    (tm: Core.Models.TeamMember) => !getIsCaptain(tm),
    (tm: Core.Models.TeamMember) => (tm.gamerHandle || Core.Identity.renderMemberName(tm) || 'zzzzzz').toLowerCase(),
    (tm: Core.Models.TeamMember) => (Core.Identity.renderMemberName(tm) || 'zzzzzz').toLowerCase(),
];

const TeamMembers: React.FunctionComponent<TeamMembersProps> = (props) => {
    const { canEdit, canAdd, userId, getPermissions, playersCanLeaveTeams, team, userPermissionService } = props;

    // initialize state from props (so we can update it as things change)
    const [teamMembers, setTeamMembers] = React.useState(team.members);
    // if members from props changes, copy it back to state (losing the changes we've been tracking)
    React.useEffect(() => {
        setTeamMembers(team.members);
    }, [team.members]);

    const [isAdding, setIsAdding] = React.useState(false);
    // useCallback keeps us from using a new instance of this function on every render
    const addTeamMembers = React.useCallback(
        async (members: Core.Models.OrganizationMember[], teamRoleId: string) => {
            const response = await TeamService.addUser({
                teamId: team.id,
                teamRoleId,
                userOrganizationRoleIds: members.map((m: Core.Models.OrganizationMember) => m.userEntityRoleId),
            });
            const newMembers = members.map((m: Core.Models.OrganizationMember) => {
                const newMember = response.find(
                    (nm: Core.Models.CreateUserTeamRoleResponse) => nm.userOrganizationRoleId === m.userEntityRoleId
                )!;
                return {
                    ...m,
                    roleId: newMember.teamRoleId,
                    userEntityRoleId: newMember.userTeamRoleId,
                };
            });
            setTeamMembers([...teamMembers, ...newMembers]);
            setIsAdding(false);
        },
        [team, teamMembers, setTeamMembers, setIsAdding]
    );
    const setIsAddingTrue = React.useCallback(() => setIsAdding(true), [setIsAdding]);
    const setIsAddingFalse = React.useCallback(() => setIsAdding(false), [setIsAdding]);

    const [removeWhileRosterLockedNode, openAddWhileRosterLocked, closeAddWhileRosterLocked] = useConfirmModal(
        () => 'Roster Locked',
        () => <p>This team's roster is currently locked. Would you like to proceed anyway?</p>,
        async () => {
            setIsAddingTrue();
            closeAddWhileRosterLocked();
        }
    );

    const captains = React.useMemo(() => {
        return sortBy(teamMembers.filter(getIsCaptain), sortMembers);
    }, [teamMembers]);
    const coaches = React.useMemo(() => {
        return sortBy(teamMembers.filter(getIsCoach), sortMembers);
    }, [teamMembers]);
    const players = React.useMemo(() => {
        return sortBy(teamMembers.filter(getIsPlayer), sortMembers);
    }, [teamMembers]);
    const missingPlayers = React.useMemo(() => {
        if (team.game?.minimumSeats) {
            return Math.max(0, team.game.minimumSeats - (captains.length + players.length));
        }
        return 0;
    }, [team.game, captains.length, players.length]);
    const canLeaveTeams = userPermissionService.hasTeamAccess(
        playersCanLeaveTeams ? Core.Models.PermissionLevel.Contribute : Core.Models.PermissionLevel.Edit,
        team.id
    );

    const league = useLeague();
    const canOverrideRosterLocks = userPermissionService.hasLeagueAccess(Core.Models.PermissionLevel.Edit, league?.id);

    const createTeamMember = (teamMember: Core.Models.TeamMember) => (
        <TeamMember
            canEdit={canEdit}
            canLeaveTeam={canLeaveTeams && teamMember.userId === userId}
            canOverrideRosterLocks={canOverrideRosterLocks}
            key={teamMember.userEntityRoleId}
            removed={() => {
                if (userId === teamMember.userId) {
                    getPermissions(); // fire-and-forget
                }
                setTeamMembers([...teamMembers.filter((i) => i.userEntityRoleId !== teamMember.userEntityRoleId)]);
            }}
            roleChanged={(newRoleId) => {
                if (userId === teamMember.userId) {
                    getPermissions(); // fire-and-forget
                }
                setTeamMembers([
                    ...teamMembers.filter((i) => i.userEntityRoleId !== teamMember.userEntityRoleId),
                    { ...teamMember, roleId: newRoleId },
                ]);
            }}
            isRosterLocked={!!team.isRosterLocked}
            teamMember={teamMember}
        />
    );

    const showRoleSeparator = coaches.length + captains.length > 0 && players.length + missingPlayers > 0;
    return (
        <>
            <ul className="team-members">
                {coaches.map(createTeamMember)}
                {captains.map(createTeamMember)}
                {showRoleSeparator && <li className="separator" />}
                {players.map(createTeamMember)}
                {range(0, missingPlayers).map((_, index: number) => (
                    <TeamMember
                        canOverrideRosterLocks={canOverrideRosterLocks}
                        isOpenPosition
                        isRosterLocked={!!team.isRosterLocked}
                        key={index}
                        teamMember={{
                            firstName: 'Open',
                            lastName: 'Position',
                        }}
                    />
                ))}
            </ul>
            {canAdd && (
                <SolidButton
                    as="button"
                    className="team-members__add-user"
                    disabled={team.isRosterLocked && !canOverrideRosterLocks}
                    onClick={team.isRosterLocked ? openAddWhileRosterLocked : setIsAddingTrue}
                >
                    {team.isRosterLocked ? <FontAwesomeIcon icon={['fas', 'lock']} size="1x" className="mr" /> : '+'}
                    {!team.isRosterLocked || canOverrideRosterLocks ? ' Add User' : ' Roster Locked'}
                </SolidButton>
            )}
            {isAdding && (
                <Modal onClose={setIsAddingFalse} title="Add user to team">
                    <AddTeamMemberForm
                        canAddPlayers={captains.length + players.length < (team.game?.maximumSeats ?? 0)}
                        enableSeasonAgeRestrictions={!!league?.enableSeasonAgeRestrictions}
                        team={team}
                        teamMemberLength={captains.length + players.length} // exclude Coaches from counts
                        onSubmit={addTeamMembers}
                    />
                </Modal>
            )}
            {removeWhileRosterLockedNode}
        </>
    );
};

interface TeamMemberProps {
    canEdit?: boolean;
    canLeaveTeam?: boolean;
    canOverrideRosterLocks: boolean;
    className?: string;
    isOpenPosition?: boolean;
    isRosterLocked: boolean;
    removed?: () => void;
    roleChanged?: (newRoleId: Core.Models.TeamRoleId) => void;
    teamMember: Partial<Core.Models.TeamMember>;
}

interface TeamMemberState {
    isProcessing?: boolean;
    isRemoving?: boolean;
    error?: string;
}

const renderName = (teamMember: Partial<Core.Models.TeamMember>, hideLeagueFeeStatus: boolean) => {
    // if you have both, render both.  If you only have one, render it as the 'gamer handle' (since that's always shown)
    const handleToRender =
        teamMember.gamerHandle || Core.Identity.renderMemberName(teamMember) || teamMember.email || 'Anonymous';
    return (
        <div className="team-members__member__text">
            {teamMember.userId ? (
                <div className="team-members__member__gamer-handle">
                    <Link to={`/users/${teamMember.userId}`}>{handleToRender}</Link>
                    {!hideLeagueFeeStatus && !!teamMember.hasOutstandingPayables && (
                        <FontAwesomeIcon
                            className="ml color-error"
                            icon={['fas', 'dollar-sign']}
                            title="User has not paid the league fee"
                        />
                    )}
                </div>
            ) : (
                <span className="team-members__member__gamer-handle">{handleToRender}</span>
            )}
            {/* we only need to render the 'name' if we have a gamer handle (otherwise the name would have been rendered above) */}
            {teamMember.gamerHandle && teamMember.firstName && (
                <span className="team-members__member__name">{Core.Identity.renderMemberName(teamMember)}</span>
            )}
        </div>
    );
};

export class TeamMember extends React.PureComponent<TeamMemberProps, TeamMemberState> {
    constructor(props: TeamMemberProps) {
        super(props);
        this.state = {};
    }
    public render(): JSX.Element {
        const { canEdit, canLeaveTeam, canOverrideRosterLocks, className, isOpenPosition, isRosterLocked, teamMember } =
            this.props;
        const { isProcessing, isRemoving, error } = this.state;
        const isCaptain = getIsCaptain(teamMember);
        const isCoach = getIsCoach(teamMember);
        const isPlayer = getIsPlayer(teamMember);
        const isIneligible = getIsIneligible(teamMember);

        return (
            <li
                className={classNames('team-members__member', className, {
                    captain: isCaptain,
                    coach: isCoach,
                    player: isPlayer,
                })}
            >
                <div className="team-members__member__avatar">
                    <Avatar
                        captain={isCaptain}
                        className="mr"
                        coach={isCoach}
                        fallback={isOpenPosition ? 'question' : 'user'}
                        ineligible={isIneligible}
                        isUnder13={!!teamMember.isUnder13}
                        size="small"
                        src={teamMember.avatarUrl}
                    />
                </div>
                <div
                    className={classNames('team-members__member-wrap', {
                        'team-members__member-wrap--has-menu': canEdit || canLeaveTeam,
                    })}
                >
                    {renderName(teamMember, isCoach)}
                    {canEdit ? (
                        <Menu className="team-members__member__menu">
                            <button
                                onClick={this.removeMember}
                                disabled={isRemoving || (isRosterLocked && !canOverrideRosterLocks)}
                            >
                                {!isRosterLocked ? (
                                    'Remove'
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={['fas', 'lock']} size="xs" className="mr" />
                                        Remove
                                    </>
                                )}
                            </button>
                            {isPlayer && (
                                <button onClick={this.makeCaptain} disabled={this.state.isProcessing}>
                                    Promote to Captain
                                </button>
                            )}
                            {isCaptain && (
                                <button onClick={this.makePlayer} disabled={this.state.isProcessing}>
                                    Demote to Player
                                </button>
                            )}
                        </Menu>
                    ) : canLeaveTeam ? (
                        <Menu className="team-members__member__menu">
                            <button onClick={this.removeMember} disabled={this.state.isRemoving}>
                                Leave
                            </button>
                        </Menu>
                    ) : (
                        <></>
                    )}
                    {isRemoving && (
                        <ConfirmModal
                            confirmText="Yes"
                            onCancel={this.cancelRemoveMember}
                            onConfirm={this.processRemoveMember}
                            title="Are you sure"
                        >
                            <p>
                                {canEdit ? (
                                    <>
                                        Are you sure you want to remove{' '}
                                        <em>{Core.Identity.renderMemberName(teamMember)}</em> from this team?
                                    </>
                                ) : (
                                    <>Are you sure you want to leave this team?</>
                                )}
                            </p>
                            <br />
                            {teamMember.roleId === Core.Models.TeamRoleId.Captain && (
                                <>
                                    <p>
                                        <strong>
                                            Only Captains and Coaches can perform check-ins. If no Captains remain, the
                                            team may be unable to check-in. Please add a new Captain prior to removing
                                            the final Captain from the team.
                                        </strong>
                                    </p>
                                    <br />
                                </>
                            )}
                            <p>
                                Note: please double check that removing a team member does not violate your league's
                                roster lock policy.
                            </p>
                        </ConfirmModal>
                    )}
                    {isProcessing && <Loading buttonLoader />}
                    {error && (
                        <Modal onClose={this.clearError} title="Error">
                            <ErrorMessage error={error} />
                            <Button onClick={this.clearError}>Ok</Button>
                        </Modal>
                    )}
                </div>
            </li>
        );
    }

    private removeMember = () => {
        this.setState({ isRemoving: true });
    };
    private processRemoveMember = async () => {
        await TeamService.deleteUserTeamRole(this.props.teamMember.userEntityRoleId!);
        this.setState({ isRemoving: false });
        this.props.removed!();
    };
    private cancelRemoveMember = () => {
        this.setState({ isRemoving: false });
    };

    private makeCaptain = () => {
        return this.changeRole(Core.Models.TeamRoleId.Captain);
    };

    private makePlayer = () => {
        return this.changeRole(Core.Models.TeamRoleId.Player);
    };

    private changeRole = async (newRoleId: Core.Models.TeamRoleId) => {
        try {
            this.setState({ isProcessing: true, error: undefined });
            await TeamService.changeUserTeamRole({
                userTeamRoleId: this.props.teamMember.userEntityRoleId!,
                newTeamRoleId: newRoleId,
            });
            this.setState({ isProcessing: false }, () => {
                this.props.roleChanged!(newRoleId);
            });
        } catch (error) {
            this.setState({ isProcessing: false, error: Core.API.getErrorMessage(error) });
        }
    };

    private clearError = () => {
        this.setState({ error: undefined });
    };
}

function mapStateToProps(state: AppState) {
    const userPermissionService = getUserPermissionService(state);
    return {
        playersCanLeaveTeams: !!state.leagueState.league?.league?.playersCanLeaveTeams,
        userId: state.loginState.decodedToken && state.loginState.decodedToken.sub,
        userPermissionService,
    };
}
export default connect(mapStateToProps, { getPermissions })(TeamMembers);

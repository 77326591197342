import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import MembersList from './membersList';
import OrganizationUser from './organizationUser';
import { Button } from '../../../components/button';
import { Filter } from '../../../components/inputs';
import InviteManager from '../../../components/inviteManager';
import InvitePlayers from '../../../components/invitePlayers';
import Modal from '../../../components/modal';
import ResendInvites from '../../../components/resendInvites';
import withLoading, { WithLoadingProps } from '../../../components/withLoading';
import {
    useHasLeagueAccess,
    useLeague,
    useLoginState,
    useOrganizationTerm,
    useUserPermissionService,
} from '../../../hooks/store';
import { OrganizationService } from '../../../services/organizationService';
import { getPermissions } from '../../../store/permissions/actions';

import './index.scss';

interface UsersListProps extends WithLoadingProps {
    organization: Core.Models.Organization;
}

export const UsersList = (props: UsersListProps): JSX.Element => {
    const { organization, setIsLoading } = props;

    const dispatch = useDispatch();
    const league = useLeague();
    const login = useLoginState();
    const organizationTerm = useOrganizationTerm({ lowercase: true });
    const userPermissionService = useUserPermissionService();

    const [isAddingManager, setIsAddingManager] = useState<boolean>(false);
    const [managers, setManagers] = useState<Core.Models.OrganizationMember[]>([]);
    const [isAddingPlayers, setIsAddingPlayers] = useState<boolean>(false);
    const [isResendingInvites, setIsResendingInvites] = useState<boolean>(false);
    const [org, setOrg] = useState<Core.Models.Organization>(organization);
    const [filter, setFilter] = useState<string>('');
    const userId = useMemo(() => login.decodedToken?.sub, [login]);
    const canEdit = useMemo(
        () => userPermissionService.hasOrganizationAccess(Core.Models.PermissionLevel.Edit, organization.id),
        [organization.id, userPermissionService]
    );
    const canEditLeague = useHasLeagueAccess(Core.Models.PermissionLevel.Edit);
    const canElevatedEditLeague = useHasLeagueAccess(Core.Models.PermissionLevel.ElevatedEdit);
    const shouldShowInviteButtons = useMemo(
        () => canEditLeague || (typeof league !== 'undefined' && !league.disableInvites),
        [canEditLeague, league]
    );
    const chatFeatureEnabled = useMemo(() => !!league?.enableChat, [league]);

    const getManagersAsync = useCallback(async () => {
        try {
            const result = await OrganizationService.getMembers(
                organization.id,
                Core.Models.OrganizationRoleId.Manager,
                { page: 1, pageSize: 1000 } // not paginated yet and this list is unlikely to exceed 1000 results, so this should query all managers
            );
            setManagers(
                result.results.filter(
                    (member: Core.Models.OrganizationMember) => member.roleId === Core.Models.OrganizationRoleId.Manager
                )
            );
        } catch {
            toast.error('Error loading organization managers');
        } finally {
            setIsLoading(false);
        }
    }, [organization.id, setIsLoading, setManagers]);

    useEffect(() => {
        (async () => {
            setOrg(organization);
        })();
    }, [organization]);

    useEffect(() => {
        (async () => {
            await getManagersAsync();
        })();
    }, [getManagersAsync]);

    const removed = useCallback(
        (user: Core.Models.OrganizationMember) => {
            if (userId === user.userId) {
                dispatch(getPermissions()); // fire-and-forget
            }
            setManagers([
                ...managers.filter(
                    (manager: Core.Models.OrganizationMember) => manager.userEntityRoleId !== user.userEntityRoleId
                ),
            ]);
        },
        [dispatch, managers, setManagers, userId]
    );

    const chatDisabledChanged = useCallback(
        (user: Core.Models.OrganizationMember, chatDisabled: boolean) => {
            setManagers([
                ...managers.map((i) => {
                    if (i.userEntityRoleId !== user.userEntityRoleId) {
                        return i;
                    }
                    return { ...i, chatDisabled };
                }),
            ]);
        },
        [managers, setManagers]
    );

    const isEligibleChanged = useCallback(
        (user: Core.Models.OrganizationMember, isEligible: boolean) => {
            setManagers([
                ...managers.map((manager: Core.Models.OrganizationMember) => {
                    if (manager.userEntityRoleId !== user.userEntityRoleId) {
                        return manager;
                    }
                    return { ...manager, isEligible };
                }),
            ]);
        },
        [managers, setManagers]
    );

    const roleChanged = useCallback(async () => {
        dispatch(getPermissions()); // fire-and-forget
        await getManagersAsync();
        setOrg({ ...org }); // hack - this forces the member list component to rerender when a manager's role is changed to member
    }, [dispatch, getManagersAsync, org]);

    return (
        <>
            <div className="organization-users">
                <div className="organization-users__header mb2x">
                    <h4 className="heading-2 mr2x">{organization.name} Members</h4>
                    {canEdit && shouldShowInviteButtons && (
                        <div className="organization-users__header__buttons">
                            <Button
                                semiRound
                                onClick={() => setIsAddingManager(true)}
                                styleType={Core.Models.StyleType.OutlineOnly}
                            >
                                <span className="organization-users__header__button__plus">+</span> Invite Manager
                            </Button>
                        </div>
                    )}
                </div>
                <div className="organization-users__list">
                    {(managers.length > 0 || canEdit) && (
                        <div className="organization-users__list__managers">
                            {managers.map((member: Core.Models.OrganizationMember, index: number) => (
                                <OrganizationUser
                                    allowRemove={shouldShowInviteButtons}
                                    canEdit={canEdit}
                                    canResendInviteLink={canEdit}
                                    canViewEmailVerificationLink={canElevatedEditLeague}
                                    canViewInviteLink={canEditLeague}
                                    canViewPasswordResetLink={canElevatedEditLeague}
                                    chatDisabledChanged={chatDisabledChanged.bind(null, member)}
                                    chatFeatureEnabled={chatFeatureEnabled}
                                    isEligibleChanged={isEligibleChanged.bind(null, member)}
                                    key={index}
                                    organizationId={organization.id}
                                    removed={removed.bind(null, member)}
                                    roleChanged={roleChanged}
                                    user={member}
                                />
                            ))}
                        </div>
                    )}

                    {canEdit && shouldShowInviteButtons && (
                        <div className="organization-users__header vr mb4x">
                            <div className="organization-users__header__buttons">
                                <Button
                                    semiRound
                                    onClick={() => setIsResendingInvites(true)}
                                    styleType={Core.Models.StyleType.OutlineOnly}
                                >
                                    <span className="organization-users__header__button__resend">
                                        <FontAwesomeIcon icon={['fas', 'repeat']} />
                                    </span>{' '}
                                    Resend Invites
                                </Button>
                                <Button
                                    semiRound
                                    onClick={() => setIsAddingPlayers(true)}
                                    styleType={Core.Models.StyleType.OutlineOnly}
                                >
                                    <span className="organization-users__header__button__plus">+</span> Invite Players
                                </Button>
                            </div>
                        </div>
                    )}
                    <Filter
                        className="align-self-end"
                        debounceMs={Core.Constants.FORM_DEBOUNCE_TIME_MS}
                        label="Find a member"
                        setValue={setFilter}
                        value={filter}
                    />
                    <MembersList
                        {...{
                            canEdit,
                            canEditLeague,
                            canViewEmailVerificationLink: canElevatedEditLeague,
                            canViewPasswordResetLink: canElevatedEditLeague,
                            chatFeatureEnabled,
                            filter,
                            managerRoleChanged: roleChanged,
                            organization: org,
                            shouldShowInviteButtons,
                            userId,
                        }}
                    />

                    {isAddingPlayers && (
                        <Modal
                            onClose={() => setIsAddingPlayers(false)}
                            title={`Invite players to your ${organizationTerm}`}
                        >
                            <InvitePlayers
                                organizationId={organization.id}
                                onComplete={async () => {
                                    setIsAddingPlayers(false);
                                }}
                            />
                        </Modal>
                    )}
                    {isResendingInvites && (
                        <Modal
                            onClose={() => setIsResendingInvites(false)}
                            title={`Resend invites to players in your ${organizationTerm}`}
                        >
                            <ResendInvites
                                organizationId={organization.id}
                                onComplete={() => setIsResendingInvites(false)}
                            />
                        </Modal>
                    )}
                </div>
            </div>

            {isAddingManager && (
                <Modal onClose={() => setIsAddingManager(false)} title={`Invite Manager to your ${organizationTerm}`}>
                    <InviteManager
                        organizationId={organization.id}
                        onComplete={async () => {
                            setIsAddingManager(false);
                        }}
                    />
                </Modal>
            )}
        </>
    );
};

export default withLoading(UsersList, {
    loadingProps: { blockItem: true },
});

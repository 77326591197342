import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

import { BubbleArrowLocation } from '../Popover.types';

import './Bubble.scss';

interface BubbleProps extends HTMLAttributes<HTMLDivElement> {
    arrowLocation: BubbleArrowLocation;
}

const Bubble = ({ arrowLocation, children, className, ...rest }: BubbleProps) => {
    const bubbleClasses = classNames(className, 'ds-bubble', `ds-bubble--arrow-${arrowLocation}`);

    return (
        <div className={bubbleClasses} {...rest}>
            {children}
        </div>
    );
};

export default Bubble;

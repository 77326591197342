import React, { useEffect, useState } from 'react';

import * as Core from '../../core';
import { ContentContainer } from '../../components/containers';
import TeamList from '../../components/teamList';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { UserService } from '../../services/userService';

interface UserProfileTeamsProps extends WithLoadingProps {
    id: string;
}

const UserProfileTeams = ({ id, setError, setIsLoading }: UserProfileTeamsProps) => {
    const [teams, setTeams] = useState<Core.Models.Team[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const response = await UserService.getTeams(id);
                setTeams(response);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        <ContentContainer className="global-container mb4x" shade={Core.Models.Shades.Dark40}>
            <h3 className="heading-2">Teams</h3>
            <TeamList showGameName teams={teams} readonly />
        </ContentContainer>
    );
};

export default withLoading(UserProfileTeams, { loadingProps: { blockItem: true } });

import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import { SolidButton } from '../../components/buttons-visuals';
import { Select } from '../../components/inputs';
import SetTeamSeasonForm, { SetTeamSeasonFormValues } from '../../components/setTeamSeason/form';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useAlternateSeasonName, useProfile, useUserPermissionService } from '../../hooks/store';
import { TeamService } from '../../services/teamService';
import { loadProfile } from '../../store/profile/actions';

interface JoinSeasonDataProps {
    onComplete?: () => Promise<void>;
    reloadData?: () => Promise<void>;
    season: Core.Models.Season | undefined;
}

interface JoinSeasonProps extends WithLoadingProps, JoinSeasonDataProps {}

const JoinSeason = (props: JoinSeasonProps): JSX.Element => {
    const { season, setIsLoading } = props;

    const profile = useProfile();
    const dispatch = useDispatch();
    const userPermissionService = useUserPermissionService();

    const getUserTeamsForThisGame = useCallback(
        () =>
            profile?.teams?.filter(
                (t: Core.Models.Team) =>
                    t.gameId === season?.game?.id &&
                    !t.currentSeasonId &&
                    userPermissionService.hasTeamAccess(Core.Models.PermissionLevel.Edit, t.id)
            ) || [],
        [profile, season, userPermissionService]
    );

    // State
    const userTeamsForGame = getUserTeamsForThisGame();
    const [userTeams, setUserTeams] = useState(userTeamsForGame);
    const [selectedTeam, setSelectedTeam] = useState<Core.Models.Team | undefined>(() => userTeamsForGame[0]);
    const seasonAlternateName = useAlternateSeasonName();

    const autoCreate1v1Team = useCallback(async () => {
        if (!season) return;
        setIsLoading(true);

        try {
            var team = await TeamService.create1v1TeamWithSinglePlayer(season.game.id);
            dispatch(loadProfile());
            setUserTeams([team]);
            setSelectedTeam(team);
        } catch {
            toast.error('There was an issue quick-creating your 1v1 team.');
        } finally {
            setIsLoading(false);
        }
    }, [dispatch, setIsLoading, season]);

    const onComplete = useCallback(() => {
        if (props.reloadData) props.reloadData();
        dispatch(loadProfile());
        props.onComplete?.();
    }, [dispatch, props]);

    const handleSelectTeam = useCallback(
        (event: any) => {
            setSelectedTeam(userTeams.find((t) => t.id === event.target.value));
        },
        [userTeams]
    );

    if (!userTeams || !userTeams.length) {
        if (season?.game && season.game.minimumSeats > 1) {
            return (
                <p className="create-team-info">
                    You are not currently part of any teams for this game that are eligible to join. Because it's a team
                    game, you'll have to create a team in your teams list and add teammates.
                </p>
            );
            // TODO: LINK on the "create a team" to org page
        }

        return (
            <>
                <p className="create-team-info">
                    Players must be on a team to join {seasonAlternateName.toLowerCase()}s, even if you're participating
                    by yourself. If you'd like to join this {seasonAlternateName.toLowerCase()}, please click below to
                    auto-create a team.
                </p>
                <SolidButton as="button" layout="full" onClick={autoCreate1v1Team}>
                    Create team
                </SolidButton>
            </>
        );
    }

    return (
        <>
            <fieldset className="form-group section-selector select-team">
                <div className="form-field form-field--component-select">
                    <Select value={selectedTeam?.id} label="Select team" onChange={handleSelectTeam}>
                        {userTeams.map((t, ix) => (
                            <option key={t.id} value={t.id}>
                                {t.name}
                            </option>
                        ))}
                    </Select>
                </div>
            </fieldset>

            {!!selectedTeam && (
                <SetTeamSeasonForm
                    fulfillments={selectedTeam.fulfillments}
                    filterOnSeasonId={season?.id}
                    leagueDesignationId={selectedTeam.designationId}
                    onAddFulfillment={async (fulfillment: Core.Models.PayableFulfillment) => {
                        var fulfillments = [fulfillment];
                        setSelectedTeam({ ...selectedTeam, fulfillments });
                    }}
                    onNotParticipateInSeason={() => undefined}
                    onRemoveSeason={async () => undefined}
                    onSetSeason={async (
                        values: SetTeamSeasonFormValues,
                        teamMemberRankings: Core.Models.TeamMemberRanking[]
                    ) => {
                        await TeamService.setSeason({
                            teamId: selectedTeam.id,
                            teamMemberRankings,
                            ...values,
                        });
                        onComplete();
                    }}
                    teamId={selectedTeam.id}
                />
            )}
        </>
    );
};

export default withLoading(JoinSeason, {
    errorDisplayPageProps: { fullPage: false },
    isLoadingByDefault: false,
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import * as Core from '../../core';
import { AuthenticationService } from '../../services/authenticationService';
import DiscordService from '../../services/discordService';
import { LeagueService } from '../../services/leagueService';
import { OAuthService } from '../../services/oAuthService';
import { SolidButton } from '../buttons-visuals';
import InfoMessage from '../infoMessage';
import { Select } from '../inputs';

interface ManageDiscordServerProps {
    discordGuildId?: string;
    discordRequiresRelink?: boolean;
    discordUser?: Core.Models.DiscordUser;
    enableSubmit: boolean;
    reloadData?: () => Promise<void>;
    showLeagues: boolean;
}

const ManageDiscordServer: React.FunctionComponent<ManageDiscordServerProps> = (props) => {
    const { discordGuildId, discordRequiresRelink, discordUser, enableSubmit, reloadData, showLeagues } = props;
    const [authorizeUrl, setAuthorizeUrl] = React.useState<string | undefined>(undefined);
    const [error, setError] = React.useState<string | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [selectedGuildId, setSelectedGuildId] = React.useState<string>(discordGuildId || '-1');

    // componentDidMount
    React.useEffect(() => {
        // IIFE can be a workaround to make `React.useEffect` async
        (async function asyncIife() {
            const oAuthProvider = await OAuthService.getOAuthProvider(Core.Constants.OAUTH_DISCORD_PROVIDER_ID);

            const userId = AuthenticationService.getUserId(); // pass user ID if we have it
            const authorizeUrl = DiscordService.generateFullAuthorizeUrl(oAuthProvider.authorizeUrl, userId);
            setAuthorizeUrl(authorizeUrl);
        })();
    }, []);

    const submit = async () => {
        setIsSubmitting(true);

        try {
            let guildId: string | undefined = selectedGuildId;
            if (guildId === '-1') guildId = undefined;
            await LeagueService.updateDiscordGuild(guildId);

            // reload
            if (reloadData) await reloadData();
        } catch (error) {
            setError(Core.API.getErrorMessage(error));
        }

        setIsSubmitting(false);
    };

    return (
        <div className="manage-discord-server form-group form-group--undecorated">
            <fieldset className="form-group">
                <div className="disp-flex flex-dir-column align-center mb2x">
                    {!discordUser && discordRequiresRelink && (
                        <>
                            <FontAwesomeIcon icon={['fas', 'triangle-exclamation']} size="2x" className="mb" />
                            <p className="text-center">
                                Oops, something went wrong!
                                <br />
                                Please <a href={authorizeUrl}>relink your account</a> with Discord
                            </p>
                        </>
                    )}
                    {!discordUser && !discordRequiresRelink && (
                        <SolidButton as="a" href={authorizeUrl ? authorizeUrl : ''} layout="full" size="large">
                            <FontAwesomeIcon icon={['fab', 'discord']} size="sm" className="mr" />
                            Please link your account with Discord
                        </SolidButton>
                    )}
                    {discordUser && (
                        <>
                            <FontAwesomeIcon icon={['fab', 'discord']} size="2x" className="mb" />
                            <p className="heading-3">{discordUser.username}</p>
                        </>
                    )}
                </div>

                {/* League selection */}
                {showLeagues && discordUser && (
                    <>
                        <div className="disp-flex flex-gap align-center mb2x">
                            <Select
                                className="flex-grow mb0"
                                label="Select a server"
                                name="discord-server"
                                value={selectedGuildId}
                                onChange={(event) => setSelectedGuildId(event.target.value)}
                            >
                                <option key="-1" value="-1">
                                    None
                                </option>
                                {discordUser.guilds.map((guild) => (
                                    <option key={guild.id} value={guild.id}>
                                        {guild.name}
                                    </option>
                                ))}
                            </Select>
                            {enableSubmit && discordUser && (
                                <SolidButton
                                    as="button"
                                    color="primary"
                                    onClick={submit}
                                    pending={isSubmitting}
                                    size="large"
                                >
                                    Submit
                                </SolidButton>
                            )}
                        </div>
                        {enableSubmit && discordUser && <InfoMessage type="error" message={error} />}
                    </>
                )}
            </fieldset>

            {showLeagues &&
                discordUser &&
                discordGuildId &&
                !_.some(discordUser.guilds, (g) => g.id === discordGuildId) && (
                    <p>Note: This league is set to a Discord server that you don't have access to: {discordGuildId}</p>
                )}
        </div>
    );
};

export default ManageDiscordServer;

import React, { useEffect, useMemo, useState } from 'react';
import { orderBy } from 'lodash';

import * as Core from '../../core';
import { useCanEditSeason } from '../../hooks/store';
import { StageService } from '../../services/stageService';
import CompleteStageButton from '../completeStageButton';
import GroupStandings from '../groupStandings';
import withLoading, { WithLoadingProps } from '../withLoading';

import './index.scss';

interface StageStandingsProps extends WithLoadingProps {
    externalLinks: boolean;
    isMyParticipant?: (participant: { teamId?: string }) => boolean;
    matchWord: string;
    reloadStage: () => Promise<void>;
    stage: Core.Models.Stage;
}

const StageStandings = ({
    externalLinks,
    isMyParticipant,
    matchWord,
    reloadStage,
    setError,
    setIsLoading,
    stage,
}: StageStandingsProps): JSX.Element => {
    const [groups, setGroups] = useState<Core.Models.Group[]>([]);
    const canEditSeason = useCanEditSeason(stage.seasonId);

    useEffect(() => {
        (async () => {
            try {
                const response = await StageService.getStandings(stage.id);
                setGroups(response);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    const showTies = useMemo(
        () =>
            canEditSeason &&
            stage.rounds.every((r: Core.Models.Round) => r.currentState === Core.Models.CompetitionState.IsComplete) &&
            stage.allMatchesAreComplete &&
            stage.currentState === Core.Models.StageState.InProgress,
        [canEditSeason, stage]
    );

    const showGroupName = groups.length > 1;
    return (
        <div className="stage-standings">
            <div className="stage-standings__header disp-flex align-center">
                <h3 className="heading-3 stage-standings__header-text mb0">Rankings - {stage.name}</h3>
                <div className="stage-standings__header-buttons ml2x">
                    <CompleteStageButton
                        matchWord={matchWord}
                        reloadStage={reloadStage}
                        showTiebreakerWarning={false}
                        stage={stage}
                    />
                </div>
            </div>
            <div className="stage-standings__groups">
                {orderBy(groups, (group: Core.Models.Group) => group.sortOrder).map((group: Core.Models.Group) => {
                    return (
                        <div className="stage-standings__group" key={group.id}>
                            <GroupStandings
                                {...{
                                    externalLinks,
                                    group,
                                    isMyParticipant,
                                    reloadStage,
                                    showGroupName,
                                    showTies,
                                    stage,
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default withLoading(StageStandings);

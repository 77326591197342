import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import * as Core from '../core';
import Paginator from '../components/paginator';

const useApiPagination = <TResult,>(
    loadResultsAsync: (
        page: number,
        pageSize: number,
        search: string
    ) => Promise<Core.Models.PaginatedResult<TResult>> | undefined,
    setError: React.Dispatch<React.SetStateAction<string>>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    initialPageSize: number
) => {
    const [paginatedResults, setPaginatedResults] = useState<Core.Models.PaginatedResult<TResult> | undefined>(
        undefined
    );
    const [searchString, setSearchString] = useState<string>('');

    const load = useCallback(
        async (page: number = 1, pageSize: number = 10, showLoading: boolean = true) => {
            try {
                if (showLoading) setIsLoading(true);

                const data = await loadResultsAsync(page, pageSize, searchString);
                setPaginatedResults(data);
            } catch (error) {
                setError(Core.API.getErrorMessage(error));
                toast.error('There was an issue loading data');
            } finally {
                if (showLoading) setIsLoading(false);
            }
        },
        [setError, setIsLoading, loadResultsAsync, searchString, setPaginatedResults]
    );

    // initial load
    useEffect(() => {
        (async () => {
            await load(1, initialPageSize);
        })();
    }, [initialPageSize, load]);

    const incrementPage = useCallback(
        async (increment: number, showLoading: boolean = true): Promise<void> => {
            if (!paginatedResults) return;

            const nextPage = paginatedResults.page + increment;
            if (1 <= nextPage && nextPage <= paginatedResults.totalPages) {
                await load(nextPage, paginatedResults.pageSize, showLoading);
            }
        },
        [load, paginatedResults]
    );

    const selectPage = useCallback(
        async (page: number): Promise<void> => {
            if (!paginatedResults) return;

            const nextPage = page;
            if (1 <= nextPage && nextPage <= paginatedResults.totalPages) {
                await load(nextPage, paginatedResults.pageSize);
            }
        },
        [load, paginatedResults]
    );

    const setSearch = useCallback((search: string): void => setSearchString(search), [setSearchString]);

    const updatePaginatedResults = useCallback(
        (results: TResult[]): void => {
            if (!paginatedResults) return;

            setPaginatedResults({
                ...paginatedResults,
                results,
                total: paginatedResults.total + (results.length - paginatedResults.results.length),
            });
        },
        [paginatedResults, setPaginatedResults]
    );

    return {
        incrementPage,
        paginatedResults,
        Paginator:
            !!paginatedResults && paginatedResults.totalPages > 1 ? (
                <Paginator
                    onIncrementPage={incrementPage}
                    onSelectPage={selectPage}
                    page={paginatedResults.page}
                    pageCount={paginatedResults.totalPages}
                    pageSize={paginatedResults.pageSize}
                    totalCount={paginatedResults.total}
                />
            ) : (
                <></>
            ),
        selectPage,
        setSearch,
        updatePaginatedResults,
    };
};

export default useApiPagination;

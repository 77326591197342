import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { groupBy, orderBy, sortBy } from 'lodash';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import AddTeamToRound from './addTeamToRound';
import AssignGroupToStation from './assignGroupToStation';
import CreateMatch from './createMatch';
import EditGroup from './editGroup';
import EditRound from './editRound';
import Match from './match';
import MatchSchedule from './matchSchedule';
import RescheduleGroup from './rescheduleGroup';
import RoundLobby from './roundLobby';
import { IconButton, SolidButton, TertiaryButton } from '../../components/buttons-visuals';
import Modal from '../../components/modal';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useConfirmModal } from '../../hooks/confirmModal';
import { useModal } from '../../hooks/modal';
import { useCanEditSeason, useLeague, useTimezone } from '../../hooks/store';
import { GroupService } from '../../services/groupService';
import { MatchService } from '../../services/matchService';
import PlatformEventsService from '../../services/platformEventsService';
import { RoundService } from '../../services/roundService';
import { getMyMatchSorters, getIsMyMatch, getIsMyParticipant } from '../../store/selectors/myParticipant';

const ROUND_PARTICIPANT_JOINED = 'RoundParticipantJoined';
const ROUND_PARTICIPANT_REMOVED = 'RoundParticipantRemoved';
const ROUND_STARTED = 'RoundStarted';

interface RoundProps extends WithLoadingProps {
    incrementRound: () => void;
    locations: Core.Models.LeagueLocation[];
    reloadStage: () => Promise<void>;
    round: Core.Models.Round;
    roundSelector: React.ReactNode;
    season: Core.Models.Season;
    stage: Core.Models.Stage;
}

const Round = ({
    locations,
    incrementRound,
    reloadStage,
    round,
    roundSelector,
    season,
    setIsLoading,
    setError,
    stage,
}: RoundProps): JSX.Element => {
    const [dateResponse, setDateResponse] = useState<Core.Models.HasResponseDate | undefined>(undefined);
    const [matches, setMatches] = useState<Core.Models.Match[]>([]);

    const myMatchSorters = useSelector(getMyMatchSorters);
    const isMyMatch = useSelector(getIsMyMatch);
    const isMyParticipant = useSelector(getIsMyParticipant);

    const hideBestOf = useMemo(
        () =>
            season.game.featureSet === Core.Models.FeatureSet.Hearthstone ||
            stage.competitionStyle === Core.Models.CompetitionStyle.Leaderboard ||
            season.tennisStyle,
        [season.game.featureSet, stage.competitionStyle, season.tennisStyle]
    );

    const reloadMatches = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await RoundService.getMatches(round.id);

            setDateResponse({
                receivedDateUtc: response.receivedDateUtc,
                responseDateUtc: response.responseDateUtc,
            });
            setMatches(
                orderBy(
                    response.matches.filter(
                        (match: Core.Models.Match) =>
                            stage.stageTypeId !== Core.Models.StageTypeId.SingleElimination ||
                            match.matchParticipants.length >= 2
                    ),
                    [
                        ...myMatchSorters,
                        (match: Core.Models.Match) => match.startTimeUtc,
                        (match: Core.Models.Match) => match.sortOrder,
                    ]
                )
            );
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [setDateResponse, setError, setIsLoading, setMatches, round.id]);

    const groupedMatches = useMemo(() => groupBy(matches, (match: Core.Models.Match) => match.groupId), [matches]);

    const matchWord = useMemo(() => Core.Competition.getMatchWord(season?.game?.eventType), [season]);

    const [editRoundNode, openEditRound, closeEditRound] = useModal(
        () => 'Edit Round',
        () => {
            return (
                <EditRound
                    allowRescheduleRequests={stage.settings.allowRescheduleRequests}
                    autoStartRounds={!!stage.settings.autoStartRounds}
                    hideBestOf={hideBestOf}
                    matchWord={matchWord}
                    onSubmit={async (values) => {
                        await RoundService.edit({
                            id: round.id,
                            ...values,
                        });
                        await Promise.all([reloadMatches(), reloadStage()]);
                        closeEditRound();
                    }}
                    round={round}
                />
            );
        },
        { className: 'round__edit-round' }
    );

    const [groupToEdit, setGroupToEdit] = useState<Core.Models.Group | undefined>(undefined);
    const [groupToReschedule, setGroupToReschedule] = useState<Core.Models.Group | undefined>(undefined);
    const [groupToAssignStation, setGroupToAssignStation] = useState<Core.Models.Group | undefined>(undefined);
    const [newMatchGroup, setNewMatchGroup] = useState<Core.Models.Group | undefined>(undefined);
    const [newTeamGroup, setNewTeamGroup] = useState<Core.Models.Group | undefined>(undefined);
    const [streamStatusByMatch, setStreamStatusByMatch] = useState<
        { [key: string]: Core.Models.StreamStatus } | undefined
    >(undefined);

    const [startRoundNode, openStartRound, closeStartRound] = useConfirmModal(
        () => 'Start Round',
        () => (
            <p>
                Are you sure you want to start <strong>{round.name}</strong>?
            </p>
        ),
        async () => {
            await RoundService.start({ id: round.id });
            await Promise.all([reloadStage(), reloadMatches()]);
            closeStartRound();
        }
    );

    const [completeRoundNode, openCompleteRound, closeCompleteRound] = useConfirmModal(
        () => 'Complete Round',
        () => (
            <p>
                Are you sure you want to complete <strong>{round.name}</strong>? You cannot undo this action.
            </p>
        ),
        async () => {
            await RoundService.complete(round.id);
            await reloadStage();
            incrementRound();
            closeCompleteRound();
        }
    );

    const canEditSeason = useCanEditSeason(season.id);
    const showStartRound = canEditSeason && round?.currentState === Core.Models.CompetitionState.NotStarted;
    const incompletePriorRound = useMemo(
        () =>
            stage.rounds
                .filter((r: Core.Models.Round) => r.sortOrder < round.sortOrder)
                .filter((r: Core.Models.Round) => r.currentState !== Core.Models.CompetitionState.IsComplete)[0],
        [round.sortOrder, stage.rounds]
    );
    const canStartRound = showStartRound && !incompletePriorRound;

    const showCompleteRound = canEditSeason && round?.currentState === Core.Models.CompetitionState.InProgress;
    const numIncompleteMatches = useMemo(
        () =>
            matches.filter((m: Core.Models.Match) => m.currentState < Core.Models.MatchState.IsComplete || m.isDisputed)
                .length,
        [matches]
    );
    const requireAllMatchesComplete = [Core.Models.StageTypeId.AutoMatcher, Core.Models.StageTypeId.Swiss].includes(
        stage.stageTypeId
    );
    const canCompleteRound = showCompleteRound && (!requireAllMatchesComplete || numIncompleteMatches === 0);
    const location = useMemo(
        () => locations.find((l: Core.Models.LeagueLocation) => l.id === stage.settings.locationId),
        [locations, stage.settings.locationId]
    );

    const canCreateMatch = useMemo(() => {
        const createMatchStageTypeIds = [
            Core.Models.StageTypeId.AutoMatcher,
            Core.Models.StageTypeId.DoubleRoundRobin,
            Core.Models.StageTypeId.Leaderboard,
            Core.Models.StageTypeId.LeaderboardAutoMatcher,
            Core.Models.StageTypeId.RoundRobin,
            Core.Models.StageTypeId.Swiss,
        ];

        return (
            canEditSeason &&
            stage.currentState <= Core.Models.StageState.InProgress &&
            createMatchStageTypeIds.includes(stage.stageTypeId)
        );
    }, [canEditSeason, stage.currentState, stage.stageTypeId]);

    const canAddTeamToRound = useMemo(() => {
        const addTeamToRoundStageTypeIds = [
            Core.Models.StageTypeId.AutoMatcher,
            Core.Models.StageTypeId.LeaderboardAutoMatcher,
        ];

        return (
            canEditSeason &&
            round.currentState === Core.Models.CompetitionState.InProgress &&
            addTeamToRoundStageTypeIds.includes(stage.stageTypeId)
        );
    }, [canEditSeason, round.currentState, stage.stageTypeId]);

    const onDeleteMatch = useCallback(
        async (matchId: string) => {
            await MatchService.delete(matchId);
            await reloadMatches();
        },
        [reloadMatches]
    );

    const timezone = useTimezone();
    const league = useLeague();

    const isAutoMatcher = useMemo(() => {
        const stageTypeIds = [Core.Models.StageTypeId.AutoMatcher, Core.Models.StageTypeId.LeaderboardAutoMatcher];
        return stageTypeIds.includes(stage.stageTypeId);
    }, [stage.stageTypeId]);

    const isAutoMatcherLobby = useMemo(
        () => isAutoMatcher && round.currentState <= Core.Models.CompetitionState.NotStarted,
        [isAutoMatcher, round.currentState]
    );

    const initialRoundGroupParticipants = useMemo(() => {
        return groupBy(
            (round.roundGroupParticipants || []).filter(
                (rgp: Core.Models.RoundGroupParticipant) => !!rgp.groupParticipant?.groupId
            ),
            (rgp: Core.Models.RoundGroupParticipant) => rgp.groupParticipant?.groupId
        );
    }, [round]);
    const [roundGroupParticipants, setRoundGroupParticipants] = useState(initialRoundGroupParticipants);

    useEffect(() => {
        (async () => {
            await reloadMatches();
        })();
    }, [reloadMatches, round.id]);

    useEffect(() => {
        if (!league?.id) return;
        if (!isAutoMatcher) return;

        let listenerId: string | null = null;
        (async () => {
            listenerId = await PlatformEventsService.startListening(
                [ROUND_PARTICIPANT_JOINED, ROUND_PARTICIPANT_REMOVED, ROUND_STARTED],
                league.id
            );
        })();

        return () => {
            (async () => {
                if (!!listenerId)
                    await PlatformEventsService.stopListening(listenerId, [
                        ROUND_PARTICIPANT_JOINED,
                        ROUND_PARTICIPANT_REMOVED,
                        ROUND_STARTED,
                    ]);
            })();
        };
    }, [isAutoMatcher, league?.id]);

    const handleRoundParticipantJoined = useCallback(
        (newParticipant: Core.Models.RoundGroupParticipant) => {
            if (newParticipant.roundId === round.id && !!newParticipant.groupParticipant) {
                const groupId = newParticipant.groupParticipant.groupId;
                setRoundGroupParticipants({
                    ...roundGroupParticipants,
                    [groupId]: [...(roundGroupParticipants[groupId] || []), newParticipant],
                });
                toast.success(`${newParticipant.groupParticipant.name} has joined the round.`);
            }
        },
        [round, roundGroupParticipants]
    );

    const handleRoundParticipantRemoved = useCallback(
        (deletedParticipant: Core.Models.RoundGroupParticipant) => {
            if (deletedParticipant.roundId === round.id && !!deletedParticipant.groupParticipant) {
                const groupId = deletedParticipant.groupParticipant.groupId;
                setRoundGroupParticipants({
                    ...roundGroupParticipants,
                    [groupId]: [
                        ...(roundGroupParticipants[groupId] || []).filter(
                            (rgp: Core.Models.RoundGroupParticipant) => rgp.id !== deletedParticipant.id
                        ),
                    ],
                });
            }
        },
        [round, roundGroupParticipants]
    );

    const handleRoundStarted = useCallback(
        async (roundId: string) => {
            if (roundId === round.id) {
                await Promise.all([reloadMatches(), reloadStage()]);
            }
        },
        [reloadMatches, reloadStage]
    );

    const routeDataReceived = useCallback(
        ({ eventName, data }: { eventName: string; data: any }) => {
            switch (eventName) {
                case ROUND_PARTICIPANT_JOINED:
                    return handleRoundParticipantJoined(data);
                case ROUND_PARTICIPANT_REMOVED:
                    return handleRoundParticipantRemoved(data);
                case ROUND_STARTED:
                    return handleRoundStarted(data);
                default:
            }
        },
        [handleRoundParticipantJoined, handleRoundParticipantRemoved, handleRoundStarted]
    );

    useEffect(() => {
        (async () => {
            const data = await RoundService.getMatchStreamStatusByRound(round.id);
            setStreamStatusByMatch(data);
        })();
    }, [matches, round]);

    const renderMatches = useCallback(
        (group: Core.Models.Group): JSX.Element => {
            if ((groupedMatches[group.id] ?? []).length <= 0) {
                return (
                    <p className="mb0">
                        There are no {matchWord.toLowerCase()}es scheduled{' '}
                        {stage.groups.length > 1 ? 'within this group ' : ''}
                        for this round yet.
                    </p>
                );
            }

            return (
                <>
                    {groupedMatches[group.id].map((match: Core.Models.Match) => (
                        <Match
                            key={match.id}
                            myMatch={isMyMatch(match.matchParticipants)}
                            onDelete={onDeleteMatch}
                            streamStatus={streamStatusByMatch?.[match.id]}
                            tennisStyle={season.tennisStyle}
                            {...{
                                isMyParticipant,
                                locations,
                                match,
                                matchWord,
                                stage,
                                timezone,
                            }}
                        />
                    ))}
                </>
            );
        },
        [
            groupedMatches,
            isMyMatch,
            isMyParticipant,
            locations,
            matchWord,
            onDeleteMatch,
            stage,
            streamStatusByMatch,
            timezone,
        ]
    );

    useEffect(() => {
        if (!isAutoMatcher) return;

        const subscription = PlatformEventsService.dataReceived.subscribe(routeDataReceived);
        return () => subscription.unsubscribe();
    }, [isAutoMatcher, routeDataReceived]);

    return (
        <div className="round">
            <div className="round__header mb2x">
                <TertiaryButton as="a" href="#rankings">
                    View Rankings
                </TertiaryButton>
                {canEditSeason && (
                    <IconButton as="button" buttonLabel="Edit round" buttonSize="large" onClick={openEditRound}>
                        <FontAwesomeIcon icon={['fas', 'pen']} />
                    </IconButton>
                )}
                {showStartRound && (
                    <SolidButton
                        as="button"
                        size="large"
                        onClick={openStartRound}
                        disabled={!canStartRound}
                        title={incompletePriorRound && `${incompletePriorRound.name} is not complete`}
                    >
                        Start Round
                    </SolidButton>
                )}
                {showCompleteRound && (
                    <SolidButton
                        as="button"
                        size="large"
                        attention={numIncompleteMatches === 0}
                        onClick={openCompleteRound}
                        disabled={!canCompleteRound}
                        title={`${numIncompleteMatches} incomplete ${matchWord.toLowerCase()}(es)`}
                    >
                        Complete Round
                    </SolidButton>
                )}
                {roundSelector}
            </div>
            {editRoundNode}

            {groupToEdit && (
                <Modal className="round__edit-group" onClose={() => setGroupToEdit(undefined)} title="Edit group">
                    <EditGroup
                        initialValues={{ id: groupToEdit.id, name: groupToEdit.name }}
                        onSubmit={async (values) => {
                            await GroupService.edit(values);
                            await Promise.all([reloadMatches(), reloadStage()]);
                            setGroupToEdit(undefined);
                        }}
                    />
                </Modal>
            )}

            {groupToReschedule && (
                <Modal
                    className="round__edit-group"
                    onClose={() => setGroupToReschedule(undefined)}
                    title={`Reschedule group ${pluralize(matchWord.toLowerCase())}`}
                >
                    <RescheduleGroup
                        initialValues={{ startTimeUtc: round.startTimeUtc }}
                        matchWord={matchWord}
                        onSubmit={async (values) => {
                            await GroupService.reschedule({
                                id: groupToReschedule.id,
                                ...values,
                            });
                            await reloadMatches();
                            setGroupToReschedule(undefined);
                        }}
                        rounds={[round]}
                    />
                </Modal>
            )}

            {groupToAssignStation && (
                <Modal onClose={() => setGroupToAssignStation(undefined)} title="Assign group to station">
                    <AssignGroupToStation
                        group={groupToAssignStation}
                        onSubmit={async (values: Core.Models.AssignGroupToStationRequest) => {
                            await GroupService.assignToStation(values);
                            await reloadMatches();
                            setGroupToAssignStation(undefined);
                        }}
                        stage={stage}
                        stations={location?.stations ?? []}
                    />
                </Modal>
            )}

            {startRoundNode}
            {completeRoundNode}

            {sortBy(stage.groups, (group: Core.Models.Group) => group.sortOrder).map((group: Core.Models.Group) => (
                <React.Fragment key={group.id}>
                    <MatchSchedule
                        controls={
                            <>
                                {stage.groups.length > 1 && canEditSeason && (
                                    <>
                                        <IconButton
                                            as="button"
                                            buttonLabel={`Edit ${matchWord.toLowerCase()}`}
                                            buttonSize="medium"
                                            onClick={() => setGroupToEdit(group)}
                                        >
                                            <FontAwesomeIcon icon={['fas', 'pen']} />
                                        </IconButton>
                                    </>
                                )}
                                {canAddTeamToRound && (
                                    <>
                                        <IconButton
                                            as="button"
                                            buttonLabel="Add team to round"
                                            buttonSize="medium"
                                            onClick={() => setNewTeamGroup(group)}
                                        >
                                            <FontAwesomeIcon icon={['fas', 'user-plus']} />
                                        </IconButton>
                                    </>
                                )}
                                {canCreateMatch && !isAutoMatcherLobby && (
                                    <IconButton
                                        as="button"
                                        buttonLabel={`Create ${matchWord.toLowerCase()}`}
                                        buttonSize="medium"
                                        onClick={() => setNewMatchGroup(group)}
                                    >
                                        <FontAwesomeIcon icon={['fas', 'plus']} />
                                    </IconButton>
                                )}
                                {canEditSeason && (
                                    <>
                                        {!isAutoMatcherLobby && (
                                            <IconButton
                                                as="button"
                                                buttonLabel={`Reschedule ${matchWord.toLowerCase()}`}
                                                buttonSize="medium"
                                                onClick={() => setGroupToReschedule(group)}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', 'clock-rotate-left']}
                                                    flip="horizontal"
                                                />
                                            </IconButton>
                                        )}
                                        {!!location && (
                                            <IconButton
                                                as="button"
                                                buttonLabel="Assign group to station"
                                                buttonSize="medium"
                                                onClick={() => setGroupToAssignStation(group)}
                                            >
                                                <FontAwesomeIcon icon={['fas', 'computer']} />
                                            </IconButton>
                                        )}
                                    </>
                                )}
                            </>
                        }
                        title={
                            isAutoMatcherLobby
                                ? `Round Lobby ${stage.groups.length > 1 ? group.name : ''}`
                                : `${matchWord} Schedule ${stage.groups.length > 1 ? group.name : ''}`
                        }
                    >
                        {isAutoMatcherLobby && !!dateResponse ? (
                            <RoundLobby
                                dateResponse={dateResponse}
                                group={group}
                                round={round}
                                roundGroupParticipants={roundGroupParticipants[group.id] || []}
                                stage={stage}
                            />
                        ) : (
                            renderMatches(group)
                        )}
                    </MatchSchedule>

                    {newTeamGroup === group && !!group.groupParticipants && (
                        <Modal onClose={() => setNewTeamGroup(undefined)} title="Add team to round">
                            <AddTeamToRound
                                group={group}
                                onSubmit={async (groupParticipantId: string) => {
                                    await RoundService.join({
                                        roundId: round.id,
                                        groupParticipantId,
                                    });
                                    await Promise.all([reloadMatches(), reloadStage()]);
                                    setNewTeamGroup(undefined);
                                }}
                                round={round}
                                season={season}
                                stage={stage}
                            />
                        </Modal>
                    )}

                    {newMatchGroup === group && !!group.groupParticipants && (
                        <Modal onClose={() => setNewMatchGroup(undefined)} title={`Create ${matchWord.toLowerCase()}`}>
                            <CreateMatch
                                game={season.game}
                                groupParticipants={group.groupParticipants}
                                hideBestOf={hideBestOf}
                                initialValues={{
                                    bestOf: round.bestOf,
                                    startTimeUtc: round.startTimeUtc,
                                }}
                                matchWord={matchWord}
                                onSubmit={async (values) => {
                                    await MatchService.create({
                                        bestOf: values.bestOf,
                                        groupId: group.id,
                                        groupParticipantIds: values.groupParticipantIds,
                                        roundId: round.id,
                                        startTimeUtc: values.startTimeUtc,
                                    });
                                    await reloadMatches();
                                    setNewMatchGroup(undefined);
                                }}
                                round={round}
                                stage={stage}
                            />
                        </Modal>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default withLoading(Round);

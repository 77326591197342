import React, { useCallback, useEffect, useMemo } from 'react';

import * as Core from '../../core';
import { LeagueSearchResultCard } from './leagueSearch';
import LoginButton from '../../components/loginButton';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import useApiPagination from '../../hooks/apiPagination';
import { AuthenticationService } from '../../services/authenticationService';
import { LeagueService } from '../../services/leagueService';

const MY_LEAGUES_PER_PAGE = 6;

interface MyLeaguesProps extends WithLoadingProps {}

const MyLeagues = ({ setError, setIsLoading }: MyLeaguesProps): JSX.Element => {
    const isAuthenticated = useMemo(() => AuthenticationService.isAuthenticated(), []);

    useEffect(() => {
        if (!isAuthenticated) setIsLoading(false);
    }, [isAuthenticated]);

    const getMyPaginatedLeaguesAsync = useCallback(
        (
            page: number,
            pageSize: number
        ): Promise<Core.Models.PaginatedResult<Core.Models.LeagueSearchResult>> | undefined => {
            if (!isAuthenticated) return undefined;
            return LeagueService.getMyLeagues({ page, pageSize });
        },
        [isAuthenticated]
    );

    const { paginatedResults, Paginator } = useApiPagination(
        getMyPaginatedLeaguesAsync,
        setError,
        setIsLoading,
        MY_LEAGUES_PER_PAGE
    );

    return (
        <div className="league-search__my-leagues mb2x">
            {isAuthenticated ? (
                <div>
                    <h2>Your leagues</h2>
                    <div>
                        {!!paginatedResults?.results.length ? (
                            <div className="league-search__results-grid">
                                {paginatedResults.results.map((leagueSearchResult: Core.Models.LeagueSearchResult) => (
                                    <LeagueSearchResultCard key={leagueSearchResult.id} {...{ leagueSearchResult }} />
                                ))}
                            </div>
                        ) : (
                            <p className="full-width text-center">You aren't in any leagues yet.</p>
                        )}
                    </div>
                    {Paginator}
                </div>
            ) : (
                <div className="disp-flex align-center justify-center m4x">
                    <LoginButton>Log in to view your leagues</LoginButton>
                </div>
            )}
        </div>
    );
};

export default withLoading(MyLeagues);

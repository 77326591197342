import React from 'react';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';

import './index.scss';

interface MarkdownProps {
    className?: string;
    source: string;
}

const Markdown = ({ className, source }: MarkdownProps): JSX.Element => (
    <ReactMarkdown className={classNames('markdown', className)} linkTarget="_blank">
        {source}
    </ReactMarkdown>
);

export default Markdown;

import React, { FieldsetHTMLAttributes } from 'react';
import classNames from 'classnames';

export interface FieldsetProps extends FieldsetHTMLAttributes<HTMLFieldSetElement> {
    description?: React.ReactNode;
    legend?: React.ReactNode;
}

function FieldSet({ className, children, description, legend, ...rest }: FieldsetProps) {
    const fieldsetClasses = classNames(className, 'ds-fieldset');

    return (
        <fieldset className={fieldsetClasses} {...rest}>
            {!!legend && <legend className="heading-3">{legend}</legend>}
            {!!description && <p className="text-small color-gray">{description}</p>}
            <div className={classNames('ds-fieldset-inputs', { mt3x: !!legend || !!description })}>{children}</div>
        </fieldset>
    );
}

export default FieldSet;

import React, { useMemo } from 'react';

import * as Core from '../../../../core';
import ApexLegendsScoring from './apexLegends';
import ChessScoring from './chess';
import ValorantScoring from './valorant';

import './index.scss';

interface AutoScoringDataProps {
    gameFeatureSet: Core.Models.FeatureSet;
    gameMinimumSeats: number;
    hasResultFiles: boolean;
    matchId: string;
    matchParticipantUserIds: string[];
    setAutoScoringData: (data: string | undefined) => void;
    tennisStyle: boolean;
}

export interface TitleScoringProps {
    setAutoScoringData: (data: string | undefined) => void;
}

const AutoScoringData = ({
    gameFeatureSet,
    gameMinimumSeats,
    hasResultFiles,
    matchId,
    matchParticipantUserIds,
    setAutoScoringData,
    tennisStyle,
}: AutoScoringDataProps): JSX.Element => {
    const titleAutoScoring = useMemo(() => {
        switch (gameFeatureSet) {
            case Core.Models.FeatureSet.ApexLegends:
                return <ApexLegendsScoring {...{ setAutoScoringData }} />;
            case Core.Models.FeatureSet.Chess:
                return (
                    (gameMinimumSeats === 1 || tennisStyle) && (
                        <ChessScoring {...{ matchId, matchParticipantUserIds, setAutoScoringData }} />
                    )
                );
            case Core.Models.FeatureSet.Valorant:
                return <ValorantScoring {...{ matchId, setAutoScoringData }} />;
            default:
                return <></>;
        }
    }, [gameFeatureSet, matchId, matchParticipantUserIds, setAutoScoringData]);

    if (hasResultFiles) return <></>;

    return <div className="auto-scoring-data">{titleAutoScoring}</div>;
};

export default AutoScoringData;

import React, { useMemo } from 'react';
import classNames from 'classnames';

import './index.scss';

interface PaginatorProps {
    hideItemNumbers?: boolean;
    hideItemsSummary?: boolean;
    onIncrementPage: (increment: number) => void;
    onSelectPage: (page: number) => void;
    page: number;
    pageCount: number;
    pageSize: number;
    totalCount: number;
}

const MAX_PAGE_SELECTIONS = 5;

const Paginator = (props: PaginatorProps): JSX.Element => {
    const { hideItemNumbers, hideItemsSummary, onIncrementPage, onSelectPage, page, pageCount, pageSize, totalCount } =
        props;

    const pageNumbers = useMemo(() => {
        const allPageNumbers = [...Array.from(Array(pageCount).keys())].map((pageNumber: number) => pageNumber + 1);

        const [start, end] = getPageNumberBounds(page, pageCount);

        return allPageNumbers.slice(start, end);
    }, [page, pageCount]);

    return (
        <div className="paginator">
            <ul>
                <li className={classNames('arrow', page === 1 && 'hidden')} onClick={() => onIncrementPage(-1)}>
                    ❮
                </li>

                {pageNumbers.map((pageNumber: number) => (
                    <li
                        className={classNames(pageNumber === page && 'active')}
                        key={pageNumber}
                        onClick={() => onSelectPage(pageNumber)}
                    >
                        {!hideItemNumbers && <>{pageNumber}</>}
                    </li>
                ))}

                <li className={classNames('arrow', page === pageCount && 'hidden')} onClick={() => onIncrementPage(1)}>
                    ❯
                </li>
            </ul>
            {!hideItemsSummary && (
                <p>
                    ({pageSize} per page, {totalCount.toLocaleString('en-US')} total results)
                </p>
            )}
        </div>
    );
};

const getPageNumberBounds = (page: number, pageCount: number): number[] => {
    if (page < pageCount - page) {
        // closer to the start
        const start = Math.max(0, page - MAX_PAGE_SELECTIONS / 2);
        const end = Math.min(pageCount, start + MAX_PAGE_SELECTIONS);
        return [start, end];
    } else {
        // closer to the end
        const end = Math.min(pageCount, page + MAX_PAGE_SELECTIONS / 2);
        const start = Math.max(0, end - MAX_PAGE_SELECTIONS);
        return [start, end];
    }
};

export default Paginator;

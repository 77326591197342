import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import indefinite from 'indefinite';
import moment from 'moment-timezone';
import pluralize from 'pluralize';

import * as Core from '../../core';
import { AddButton, IconButton } from '../../components/buttons-visuals';
import ConfirmModal from '../../components/confirmModal';
import InfoMessage from '../../components/infoMessage';
import Modal from '../../components/modal';
import { ToolTip } from '../../components/overlays';
import UpsertPayable from '../../components/payments/upsertPayable';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useAlternateSeasonName, useTimezone } from '../../hooks/store';
import PaymentGatewayService from '../../services/paymentGatewayService';

import './leagueFees.scss';

interface LeagueFeesProps extends WithLoadingProps { }

const LeagueFees = ({ setError, setIsLoading }: LeagueFeesProps) => {
    const alternateSeasonName = useAlternateSeasonName();

    const [isCreatingPayable, setIsCreatingPayable] = useState<boolean>(false);
    const [payables, setPayables] = useState<Core.Models.Payable[]>([]);
    const [payableToDelete, setPayableToDelete] = useState<Core.Models.Payable | undefined>(undefined);
    const [payableToEdit, setPayableToEdit] = useState<Core.Models.Payable | undefined>(undefined);

    const timezone = useTimezone();

    const load = useCallback(async () => {
        try {
            const data = await PaymentGatewayService.getPayables();
            setPayables(data);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [setError, setIsLoading]);

    useEffect(() => {
        (async () => load())();
    }, [load]);

    return (
        <section>
            <AddButton
                as="button"
                buttonStyle="solid"
                buttonSize="large"
                className="m-auto mb4x"
                layoutInline
                onClick={() => setIsCreatingPayable(true)}
            >
                Create New Fee
            </AddButton>

            <div className="league-fees__payables">
                {payables.length > 0 ? (
                    payables.map((payable: Core.Models.Payable) => (
                        <div className="disp-flex flex-dir-column" id={payable.id} key={payable.id}>
                            <h4
                                className={classNames(
                                    'disp-flex justify-between align-center',
                                    payable.entityType === Core.Models.PayableEntityType.League && 'league-fee',
                                    payable.entityType === Core.Models.PayableEntityType.TeamDesignation && 'team-designation-fee'
                                )}
                            >
                                <div className="mr">{payable.name}</div>
                                {payable.entityType === Core.Models.PayableEntityType.League && (
                                    <ToolTip trigger={<FontAwesomeIcon icon={['fas', 'door-open']} size="1x" />}>
                                        <>
                                            <h3 className="heading-3">League fee</h3>
                                            <p>
                                                League fees are one-time fees that must be paid by every user who wants
                                                to play within the time period between the selected start and end dates
                                                (inclusive).
                                            </p>
                                            <p>They can be paid from user's profile.</p>
                                        </>
                                    </ToolTip>
                                )}
                                {payable.entityType === Core.Models.PayableEntityType.Season && (
                                    <ToolTip trigger={<FontAwesomeIcon icon={['fas', 'ticket']} size="1x" />}>
                                        <>
                                            <h3 className="heading-3">{alternateSeasonName} entry fee</h3>
                                            <p>
                                                {alternateSeasonName} entry fees are reusable fees that must be paid by
                                                every team that wants to play in{' '}
                                                {pluralize(alternateSeasonName.toLowerCase())} to which each fee is
                                                associated. These fees can be added to multiple{' '}
                                                {pluralize(alternateSeasonName.toLowerCase())} to allow one team to pay
                                                once and play in multiple.
                                            </p>
                                            <p>
                                                They can be paid from the team page when joining a{' '}
                                                {alternateSeasonName.toLowerCase()}.
                                            </p>
                                        </>
                                    </ToolTip>
                                )}
                                {payable.entityType === Core.Models.PayableEntityType.TeamDesignation && (
                                    <ToolTip trigger={<FontAwesomeIcon icon={['fas', 'ticket']} size="1x" />}>
                                        <>
                                            <h3 className="heading-3">Team Designation fee</h3>
                                            <p>
                                                Team Designation fees are one-time fees that must be paid by every user who
                                                wants to play on a team with a given team designation within the time period
                                                between the selected start and end dates (inclusive).
                                            </p>
                                            <p>They can be paid from user's profile.</p>
                                        </>
                                    </ToolTip>
                                )}
                            </h4>
                            <div className="league-fees__payables__payable">
                                <div>
                                    <p className="league-fees__payables__payable__fee heading-3 color-primary-light-20 mt2x" style={{ textDecoration: !!payable.discountFee && Core.Utils.isDiscounted(payable) ? 'line-through' : 'none' }}>
                                        {Core.Currency.convertNumberToCurrency(payable.fee)}
                                    </p>

                                    {!!payable.discountFee &&
                                        <>
                                            <p>Discount fee: {Core.Currency.convertNumberToCurrency(payable.discountFee)}</p>
                                            <p>Discount end date time: {moment.tz(payable.discountEndUtc, timezone).format(Core.Constants.TIMESTAMP_FORMAT)}</p>
                                        </>
                                    }

                                    <p>Times paid: {payable.timesPaid}</p>
                                    <p>
                                        Times overridden: {payable.timesOverridden}
                                        <ToolTip
                                            className="ml"
                                            trigger={<FontAwesomeIcon icon={['fas', 'circle-info']} size="1x" />}
                                        >
                                            <span>
                                                Hosts are allowed to override league fees when entering a team into{' '}
                                                {indefinite(alternateSeasonName.toLowerCase())}. The most common use
                                                case is for teams who pay outside of the platform.
                                            </span>
                                        </ToolTip>
                                    </p>
                                    <p>Times refunded: {payable.timesRefunded}</p>
                                    <p>
                                        Total revenue: {Core.Currency.convertNumberToCurrency(payable.totalRevenue)}
                                        <ToolTip
                                            className="ml"
                                            trigger={<FontAwesomeIcon icon={['fas', 'circle-info']} size="1x" />}
                                        >
                                            <code>(Times Paid - Times Refunded) * Fee</code>
                                        </ToolTip>
                                    </p>
                                    <p>
                                        Net revenue: {Core.Currency.convertNumberToCurrency(payable.netRevenue)}
                                        <ToolTip
                                            className="ml"
                                            trigger={<FontAwesomeIcon icon={['fas', 'circle-info']} size="1x" />}
                                        >
                                            Our payment processor takes a $0.30 + 2.9% fee from every transaction, which
                                            is deducted from total revenue.
                                        </ToolTip>
                                    </p>
                                    {payable.entityType === Core.Models.PayableEntityType.Season &&
                                        payable.exemptLeagueDesignations !== undefined && (
                                            <p>
                                                Exempt designations: {payable.exemptLeagueDesignations.length}
                                                <ToolTip
                                                    className="ml"
                                                    trigger={
                                                        <FontAwesomeIcon icon={['fas', 'circle-info']} size="1x" />
                                                    }
                                                >
                                                    {payable.exemptLeagueDesignations.length > 0 ? (
                                                        <ul>
                                                            {payable.exemptLeagueDesignations.map(
                                                                (ld: Core.Models.LeagueDesignation) => (
                                                                    <li key={ld.id}>{ld.name}</li>
                                                                )
                                                            )}
                                                        </ul>
                                                    ) : (
                                                        <span>No exclusions</span>
                                                    )}
                                                </ToolTip>
                                            </p>
                                        )}
                                    {payable.entityType === Core.Models.PayableEntityType.TeamDesignation &&
                                        payable.forLeagueDesignations !== undefined && (
                                            <p>
                                                Allowed designations: {payable.forLeagueDesignations.length}
                                                <ToolTip
                                                    className="ml"
                                                    trigger={
                                                        <FontAwesomeIcon icon={['fas', 'circle-info']} size="1x" />
                                                    }
                                                >
                                                    {payable.forLeagueDesignations.length > 0 ? (
                                                        <ul>
                                                            {payable.forLeagueDesignations.map(
                                                                (ld: Core.Models.LeagueDesignation) => (
                                                                    <li key={ld.id}>{ld.name}</li>
                                                                )
                                                            )}
                                                        </ul>
                                                    ) : (
                                                        <span>No allowed designations</span>
                                                    )}
                                                </ToolTip>
                                            </p>
                                        )}

                                    {[Core.Models.PayableEntityType.League, Core.Models.PayableEntityType.TeamDesignation].includes(payable.entityType) &&
                                        !!payable.startDate && (
                                            <p>Start date: {moment(payable.startDate).format('MM/DD/yyyy')}</p>
                                        )}

                                    {[Core.Models.PayableEntityType.League, Core.Models.PayableEntityType.TeamDesignation].includes(payable.entityType) &&
                                        !!payable.endDate && (
                                            <p>End date: {moment(payable.endDate).format('MM/DD/yyyy')}</p>
                                        )}
                                </div>

                                <div className="disp-flex justify-end mt2x">
                                    <IconButton
                                        as="button"
                                        buttonLabel="Edit payable"
                                        buttonSize="medium"
                                        className="mr"
                                        onClick={() => setPayableToEdit(payable)}
                                    >
                                        <FontAwesomeIcon icon={['fas', 'edit']} />
                                    </IconButton>
                                    <IconButton
                                        as="button"
                                        buttonLabel="Delete payable"
                                        buttonSize="medium"
                                        onClick={() => setPayableToDelete(payable)}
                                    >
                                        <FontAwesomeIcon icon={['fas', 'trash-can']} />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>You have not set up any league fees.</p>
                )}
            </div>

            {isCreatingPayable && (
                <Modal onClose={() => setIsCreatingPayable(false)} title="Create fee">
                    <UpsertPayable
                        onCompleted={async () => {
                            await load();
                            setIsCreatingPayable(false);
                        }}
                    />
                </Modal>
            )}

            {!!payableToEdit && (
                <Modal onClose={() => setPayableToEdit(undefined)} title="Edit fee">
                    <UpsertPayable
                        onCompleted={async () => {
                            await load();
                            setPayableToEdit(undefined);
                        }}
                        payable={payableToEdit}
                    />
                </Modal>
            )}

            {!!payableToDelete && (
                <ConfirmModal
                    confirmText="Yes"
                    onCancel={() => setPayableToDelete(undefined)}
                    onConfirm={async () => {
                        await PaymentGatewayService.deletePayable(payableToDelete.id);
                        await load();
                        setPayableToDelete(undefined);
                    }}
                    title="Are you sure?"
                >
                    <p>
                        Are you sure you want to delete <em>{payableToDelete.name}</em>?
                    </p>
                    <InfoMessage
                        message="Deleting this fee will not refund any payments that have already been made."
                        type="info"
                    />
                </ConfirmModal>
            )}
        </section>
    );
};

export default withLoading(LeagueFees, { loadingProps: { blockItem: true } });

import React, { useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import AdminAgreement from './modals/adminAgreement';
import GeneratePasswordReset from './modals/generatePasswordReset';
import ViewEmailVerificationLink from './modals/viewEmailVerificationLink';
import { Button } from '../../../components/button';
import ConfirmModal from '../../../components/confirmModal';
import InfoMessage from '../../../components/infoMessage';
import { CopyTextField } from '../../../components/inputs';
import Loading from '../../../components/loading';
import { MediaObject } from '../../../components/mediaObject';
import Menu from '../../../components/menu';
import Modal from '../../../components/modal';
import { OrganizationService } from '../../../services/organizationService';

import './index.scss';

interface OrganizationUserProps {
    allowRemove: boolean;
    canEdit?: boolean;
    canResendInviteLink: boolean;
    canViewEmailVerificationLink: boolean;
    canViewInviteLink: boolean;
    canViewPasswordResetLink: boolean;
    chatDisabledChanged: (chatDisabled: boolean) => void;
    chatFeatureEnabled: boolean;
    isEligibleChanged: (isEligible: boolean) => void;
    organizationId: string;
    removed: () => void;
    roleChanged: () => void;
    user: Core.Models.OrganizationMember;
}

const OrganizationUser = (props: OrganizationUserProps) => {
    const {
        allowRemove,
        canEdit,
        canResendInviteLink,
        canViewEmailVerificationLink,
        canViewInviteLink,
        canViewPasswordResetLink,
        chatDisabledChanged,
        chatFeatureEnabled,
        isEligibleChanged,
        organizationId,
        removed,
        roleChanged,
        user,
    } = props;

    const [error, setError] = useState<string | undefined>(undefined);
    const [inviteLinkToShow, setInviteLinkToShow] = useState<string | undefined>(undefined);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isRemoving, setIsRemoving] = useState<boolean>(false);
    const [verifyingEmailVerificationLinkForUser, setVerifyingEmailVerificationLinkForUser] = useState<
        Core.Models.OrganizationMember | undefined
    >(undefined);
    const [verifyingPasswordResetForUser, setVerifyingPasswordResetForUser] = useState<
        Core.Models.OrganizationMember | undefined
    >(undefined);
    const [viewingEmailVerificationLinkForUser, setViewingEmailVerificationLinkForUser] = useState<
        Core.Models.OrganizationMember | undefined
    >(undefined);
    const [viewingPasswordResetForUser, setViewingPasswordResetForUser] = useState<
        Core.Models.OrganizationMember | undefined
    >(undefined);

    const nameToRender = useMemo(() => Core.Identity.renderMemberName(user) || user.email || 'Anonymous', [user]);
    const isManager = useMemo(() => user.roleId === Core.Models.OrganizationRoleId.Manager, [user.roleId]);
    const isMember = useMemo(() => user.roleId === Core.Models.OrganizationRoleId.Member, [user.roleId]);
    const userType = useMemo(() => (isManager ? 'Manager' : undefined), [isManager]);

    const changeRole = useCallback(
        async (newRoleId: Core.Models.OrganizationRoleId) => {
            setIsProcessing(true);
            setError(undefined);
            try {
                await OrganizationService.changeUserOrganizationRole({
                    newOrganizationRoleId: newRoleId,
                    userOrganizationRoleId: user.userEntityRoleId,
                });
                user.roleId = newRoleId; // set new role for refresh
                roleChanged();
            } catch (error) {
                const message = Core.API.getErrorMessage(error);
                setError(message);
            } finally {
                setIsProcessing(false);
            }
        },
        [roleChanged, user]
    );

    const removeMember = useCallback(async () => {
        await OrganizationService.deleteUserOrganizationRole(user.userEntityRoleId);
        setIsRemoving(false);
        removed();
    }, [removed, user.userEntityRoleId]);

    const resendInviteLink = useCallback(
        async (userInviteToken: string) => {
            setIsProcessing(true);
            setError(undefined);
            try {
                await OrganizationService.resendUserInvites({
                    organizationId,
                    userInviteTokens: [userInviteToken],
                });
                toast.success('Succcessfully resent invite link to this user.');
            } catch (error) {
                toast.error('There was issue resending an invite link to this user. Please try again.');
            } finally {
                setIsProcessing(false);
            }
        },
        [organizationId]
    );

    const setIsEligible = useCallback(
        async (isEligible: boolean) => {
            setIsProcessing(true);
            setError(undefined);
            try {
                await OrganizationService.setIsEligible({
                    userOrganizationRoleId: user.userEntityRoleId,
                    isEligible,
                });
                isEligibleChanged(isEligible);
            } catch (error) {
                const message = Core.API.getErrorMessage(error);
                setError(message);
            } finally {
                setIsProcessing(false);
            }
        },
        [isEligibleChanged, user.userEntityRoleId]
    );

    const setChatDisabled = useCallback(
        async (chatDisabled: boolean) => {
            setIsProcessing(true);
            setError(undefined);
            try {
                await OrganizationService.setChatDisabled({
                    chatDisabled,
                    userOrganizationRoleId: user.userEntityRoleId,
                });
                chatDisabledChanged(chatDisabled);
            } catch (error) {
                const message = Core.API.getErrorMessage(error);
                setError(message);
            } finally {
                setIsProcessing(false);
            }
        },
        [chatDisabledChanged, user.userEntityRoleId]
    );

    const getTitle = useCallback(() => {
        if (chatFeatureEnabled && user.chatDisabled) return 'Chat is disabled';
        if (!user.isEligible) return 'User is ineligible';
        if (isManager) return 'Manager';

        if (!user.isUnverifiedAccount) return undefined;
        const verificationRequirements = [
            !user.hasVerifiedEmail ? 'email verification' : undefined,
            !user.hasParentConsent ? 'parent consent' : undefined,
        ]
            .filter(Boolean)
            .join(' and ');
        return !!verificationRequirements ? `Requires ${verificationRequirements}` : undefined;
    }, [chatFeatureEnabled, isManager, user]);

    return (
        <MediaObject
            chatDisabled={chatFeatureEnabled && user.chatDisabled}
            className={classNames('organization-users__user', {
                'organization-users__user--has-menu': canEdit,
            })}
            fallback="user"
            imageSize="large"
            imageUrl={user.avatarUrl}
            ineligible={!user.isEligible}
            isUnder13={user.isUnder13}
            manager={isManager}
            title={getTitle()}
            unverified={user.isUnverifiedAccount}
        >
            {user.userId ? (
                <div className="organization-users__user-details">
                    <Link to={`/users/${user.userId}`}>{nameToRender}</Link>
                    {!isManager && !!user.hasOutstandingPayables && (
                        <FontAwesomeIcon
                            className="ml color-error"
                            icon={['fas', 'dollar-sign']}
                            title="User has not paid the league fee"
                        />
                    )}
                </div>
            ) : (
                <div className="organization-users__user-details">{nameToRender}</div>
            )}
            {userType ? (
                <div className="organization-users__user-details-subtext">{userType}</div>
            ) : user.gamerHandle ? (
                <div className="organization-users__user-details-subtext">{user.gamerHandle}</div>
            ) : undefined}
            {canEdit && (
                <Menu className="organization-users__menu">
                    {allowRemove && (
                        <button onClick={() => setIsRemoving(true)} disabled={isRemoving}>
                            Remove
                        </button>
                    )}
                    {user.isEligible && (
                        <button onClick={() => setIsEligible(false)} disabled={isProcessing}>
                            Mark User as Ineligible
                        </button>
                    )}
                    {user.isEligible === false && (
                        <button onClick={() => setIsEligible(true)} disabled={isProcessing}>
                            Mark User as Eligible
                        </button>
                    )}
                    {chatFeatureEnabled && (
                        <button onClick={() => setChatDisabled(!user.chatDisabled)} disabled={isProcessing}>
                            <>{user.chatDisabled ? 'Unblock' : 'Block'} user from chat</>
                        </button>
                    )}
                    {isManager && (
                        <button
                            onClick={() => changeRole(Core.Models.OrganizationRoleId.Member)}
                            disabled={isProcessing}
                        >
                            Demote to normal member
                        </button>
                    )}
                    {isMember && (
                        <button
                            onClick={() => changeRole(Core.Models.OrganizationRoleId.Manager)}
                            disabled={isProcessing}
                        >
                            Promote to manager
                        </button>
                    )}
                    {canViewInviteLink && !!user.userInviteToken && (
                        <button onClick={() => setInviteLinkToShow(user.userInviteToken)}>Show invite link</button>
                    )}
                    {canResendInviteLink && !!user.userInviteToken && (
                        <button onClick={() => resendInviteLink(user.userInviteToken!)}>Resend invite link</button>
                    )}
                    {canViewEmailVerificationLink && (user.emailVerificationTokens?.length ?? 0) > 0 && (
                        <button onClick={() => setVerifyingEmailVerificationLinkForUser(user)}>
                            View email verification link
                        </button>
                    )}
                    {!!user.userId && canViewPasswordResetLink && (
                        <button onClick={() => setVerifyingPasswordResetForUser(user)}>Reset password</button>
                    )}
                </Menu>
            )}
            {isRemoving && (
                <ConfirmModal onCancel={() => setIsRemoving(false)} onConfirm={removeMember} title="Are you sure">
                    <p className="mb2x">
                        Are you sure you want to remove{' '}
                        <em>{user.userId ? Core.Identity.renderMemberName(user) : user.email}</em> from this
                        organization?
                    </p>
                    <p className="mb2x">User will be removed from any associated teams. This action is irreversible.</p>
                </ConfirmModal>
            )}
            {inviteLinkToShow && (
                <Modal onClose={() => setInviteLinkToShow(undefined)} title="Invite link">
                    <p className="mb2x">Please share this link:</p>
                    <fieldset className="form-group">
                        <CopyTextField
                            id="memberInviteLink"
                            label="Invite Link"
                            value={`${window.location.origin}/join/${inviteLinkToShow}`}
                        />
                    </fieldset>
                </Modal>
            )}
            {verifyingEmailVerificationLinkForUser && (
                <Modal onClose={() => setVerifyingEmailVerificationLinkForUser(undefined)} title={`Admin Agreement`}>
                    <AdminAgreement
                        email={verifyingEmailVerificationLinkForUser.email}
                        onSubmit={() => {
                            setViewingEmailVerificationLinkForUser(verifyingEmailVerificationLinkForUser);
                            setVerifyingEmailVerificationLinkForUser(undefined);
                        }}
                    />
                </Modal>
            )}
            {!!viewingEmailVerificationLinkForUser?.emailVerificationTokens &&
                (viewingEmailVerificationLinkForUser?.emailVerificationTokens?.length ?? 0) > 0 && (
                    <Modal
                        onClose={() => setViewingEmailVerificationLinkForUser(undefined)}
                        title={`${Core.Identity.renderMemberName(
                            viewingEmailVerificationLinkForUser
                        )}'s email verification link`}
                    >
                        <ViewEmailVerificationLink
                            tokens={viewingEmailVerificationLinkForUser.emailVerificationTokens}
                        />
                    </Modal>
                )}
            {verifyingPasswordResetForUser && (
                <Modal onClose={() => setVerifyingPasswordResetForUser(undefined)} title={`Admin Agreement`}>
                    <AdminAgreement
                        email={verifyingPasswordResetForUser.email}
                        onSubmit={() => {
                            setViewingPasswordResetForUser(verifyingPasswordResetForUser);
                            setVerifyingPasswordResetForUser(undefined);
                        }}
                    />
                </Modal>
            )}
            {viewingPasswordResetForUser && (
                <Modal
                    onClose={() => setViewingPasswordResetForUser(undefined)}
                    title={`Reset ${Core.Identity.renderMemberName(viewingPasswordResetForUser)}'s password`}
                >
                    <GeneratePasswordReset userId={viewingPasswordResetForUser.userId!} />
                </Modal>
            )}
            {isProcessing && <Loading buttonLoader />}
            {!!error && (
                <Modal onClose={() => setError(undefined)} title="Error">
                    <InfoMessage message={error} type="error" />
                    <Button onClick={() => setError(undefined)}>Ok</Button>
                </Modal>
            )}
        </MediaObject>
    );
};

export default OrganizationUser;

import * as React from 'react';
import { chain, orderBy } from 'lodash';

import * as Core from '../../core';
import genericLeagueIcon from '../../assets/images/logo.svg';
import { BaseButton, TextLink } from '../../components/buttons-visuals';
import { useLeagueConfiguration } from '../../hooks/store';

const NUM_AFFILIATE_LEAGUES_TO_SHOW = 3;

const FeaturedAffiliates = (): JSX.Element => {
    const league = useLeagueConfiguration()!;

    // `React.useRef<T>` to only compute this once, avoiding a "live shuffling" effect
    const affiliateLeaguesToShow = React.useRef<Core.Models.BasicLeague[]>(
        league.childLeagues.length > NUM_AFFILIATE_LEAGUES_TO_SHOW
            ? chain(league.childLeagues).shuffle().take(NUM_AFFILIATE_LEAGUES_TO_SHOW).value()
            : orderBy(league.childLeagues, (affiliateLeague: Core.Models.BasicLeague) => affiliateLeague.name)
    );

    if (league.childLeagues.length <= 0) return <></>;

    return (
        <div className="league-page__affiliates">
            <h4 className="league-page__affiliates__title">Featured affiliates</h4>
            <div className="league-page__affiliates__container">
                {affiliateLeaguesToShow.current.map(
                    (affiliateLeague: Core.Models.BasicLeague, affiliateLeagueIndex: number) => (
                        <BaseButton
                            as="a"
                            className="league-page__affiliates__affiliate"
                            href={affiliateLeague.domain}
                            key={affiliateLeagueIndex}
                        >
                            <div className="league-page__affiliates__affiliate__logo">
                                <img src={affiliateLeague.logoUrl || genericLeagueIcon} alt="" />
                            </div>
                            <div className="league-page__affiliates__affiliate__name">
                                <h3>{affiliateLeague.name}</h3>
                            </div>
                        </BaseButton>
                    )
                )}
            </div>
            {league.childLeagues.length > NUM_AFFILIATE_LEAGUES_TO_SHOW && (
                <div className="league-page__affiliates__view-more">
                    <TextLink to="/affiliate-leagues">View more</TextLink>
                </div>
            )}
        </div>
    );
};

export default FeaturedAffiliates;

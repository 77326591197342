import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import * as Core from '../../core';
import SeasonCard from '../../components/seasonCard';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useAlternateSeasonName, useProfile, useTimezone } from '../../hooks/store';
import { SeasonService } from '../../services/seasonService';
import useCanJoinSeason from '../league/canJoinSeason';

interface SuggestedSeasonsProps extends WithLoadingProps {
    onJoinSeason: () => void;
}

const SuggestedSeasons = (props: SuggestedSeasonsProps) => {
    const { onJoinSeason, setError, setIsLoading } = props;

    const [confirmSeasons, setConfirmSeasons] = useState<Core.Models.Season[]>([]);
    const [remainingSeasons, setRemainingSeasons] = useState<Core.Models.Season[]>([]);

    const profile = useProfile();
    const timezone = useTimezone();
    const seasonAlternateName = useAlternateSeasonName();

    const { alreadyInSeason, canJoinSeason, canRegisterForSeason } = useCanJoinSeason();

    const load = useCallback(async () => {
        try {
            const response = await SeasonService.getSuggestedSeasons();
            let seasonsToConfirm: Core.Models.Season[] = [];
            let seasonsRemaining: Core.Models.Season[] = response;

            if (profile?.gameInterests?.some((gi: Core.Models.UserOrganizationRoleGameInterest) => !!gi)) {
                // build out list of 'Confirm Attendance' seasons if the game/skill level/date exactly matches one of the user's game interests
                seasonsToConfirm = response.filter((s: Core.Models.Season) => {
                    return profile.gameInterests!.some(
                        (gi: Core.Models.UserOrganizationRoleGameInterest) =>
                            gi.gameId === s.game.id &&
                            (gi.skillLevel === s.skillLevel ||
                                (s.game.eventType === Core.Models.EventType.Competition &&
                                    s.skillLevel === Core.Models.SkillLevel.All)) &&
                            gi.dates.some(
                                (date: string) =>
                                    moment.tz(date, timezone).startOf('day').format() ===
                                    moment.tz(s.startDate, timezone).startOf('day').format()
                            )
                    );
                });

                if (seasonsToConfirm.length > 0) {
                    seasonsRemaining = seasonsRemaining.filter(
                        (sr: Core.Models.Season) => !seasonsToConfirm.some((sc: Core.Models.Season) => sc.id === sr.id)
                    );
                }
            }

            setConfirmSeasons(seasonsToConfirm);
            setRemainingSeasons(seasonsRemaining);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [profile, setError, setIsLoading, timezone]);

    const onJoinedSeason = useCallback(async () => {
        await load();
        onJoinSeason();
    }, [onJoinSeason, load]);

    useEffect(() => {
        (async () => {
            await load();
        })();
    }, [load]);

    return (
        <div className="suggested-seasons">
            <h2>
                Suggested <span className="theme__text__alternate">{seasonAlternateName}s</span>
            </h2>
            {confirmSeasons.length === 0 && remainingSeasons.length === 0 ? (
                <p>
                    Looks like we don't have any suggested {seasonAlternateName.toLowerCase()}s to display. Update your
                    game interests on your profile to get more suggestions, or go to your{' '}
                    <Link to={'/league'}>league home</Link> to discover upcoming {seasonAlternateName.toLowerCase()}s.
                </p>
            ) : (
                <>
                    {confirmSeasons.length > 0 && (
                        <>
                            <h3 className="suggested-seasons__sub-header">
                                Complete your Registration by Confirming your Spot
                            </h3>
                            <div className="league-page__seasons__season-container confirm-seasons">
                                {confirmSeasons.map((season: Core.Models.Season) => (
                                    <SeasonCard
                                        alreadyInSeason={alreadyInSeason(season) && !!profile?.organizationId}
                                        canJoinSeason={canJoinSeason(season)}
                                        canRegisterForSeason={canRegisterForSeason(season)}
                                        key={season.id}
                                        joinButtonText={'Confirm'}
                                        reloadDataOnJoin={onJoinedSeason}
                                        season={season}
                                        showImage
                                        showSeasonOperator
                                        showTimingDescription
                                    />
                                ))}
                            </div>
                        </>
                    )}
                    {remainingSeasons.length > 0 && (
                        <>
                            {confirmSeasons.length > 0 && (
                                <h3 className="suggested-seasons__sub-header">Other {seasonAlternateName}s</h3>
                            )}
                            <div className="league-page__seasons__season-container">
                                {remainingSeasons.map((season: Core.Models.Season) => (
                                    <SeasonCard
                                        alreadyInSeason={alreadyInSeason(season) && !!profile?.organizationId}
                                        canJoinSeason={canJoinSeason(season)}
                                        canRegisterForSeason={canRegisterForSeason(season)}
                                        key={season.id}
                                        reloadDataOnJoin={onJoinedSeason}
                                        season={season}
                                        showImage
                                        showSeasonOperator
                                        showTimingDescription
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default withLoading(SuggestedSeasons, {
    loadingProps: { blockItem: true },
});

import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import { useConfirmModal } from '../../hooks/confirmModal';
import { useLeague, useTimezone, useUserPermissionService } from '../../hooks/store';
import { PostService } from '../../services/postService';
import { Avatar } from '../buttons-visuals';
import Menu from '../menu';
import { ToolTip } from '../overlays';

interface CommentProps {
    comment: Core.Models.Comment;
    onDelete: (id: string) => void;
}

const Comment = ({ comment, onDelete }: CommentProps) => {
    const [commentTimeFromNow, setCommentTimeFromNow] = useState<string | undefined>(undefined);
    const { createdAtUtc, content, id, user, userId } = comment;
    const league = useLeague();
    const userPermissionService = useUserPermissionService();
    const timezone = useTimezone();

    useEffect(() => {
        setCommentTimeFromNow(moment.tz(createdAtUtc, timezone).fromNow());
        const interval = setInterval(
            () => setCommentTimeFromNow(moment.tz(createdAtUtc, timezone).fromNow()),
            Core.Constants.POST_CREATED_TIME_MS
        );
        return () => clearInterval(interval);
    }, [createdAtUtc, timezone]);

    const commentTime = useMemo(
        () => moment.tz(createdAtUtc, timezone).format(Core.Time.getFormat(true)),
        [createdAtUtc, timezone]
    );

    const canDeleteComment = useMemo(
        () =>
            userId === userPermissionService.getUserId() ||
            userPermissionService.hasLeagueAccess(Core.Models.PermissionLevel.Edit, league),
        [league, userId, userPermissionService]
    );

    const [deleteCommentNode, openDeleteComment] = useConfirmModal(
        () => `Delete Comment`,
        () => <p>Are you sure you want to delete this comment?</p>,
        async (closeModal) => {
            try {
                await PostService.deleteComment(id);
                onDelete(id);
                closeModal();
                toast.success('Comment deleted successfully');
            } catch {
                toast.success('Failed to delete comment. Please try again.');
            }
        }
    );

    return (
        <div>
            {canDeleteComment && (
                <>
                    <Menu>
                        <button onClick={() => openDeleteComment()}>Delete</button>
                    </Menu>
                    {deleteCommentNode}
                </>
            )}
            <Link to={`/users/${userId}`}>
                <Avatar fallback="user" isUnder13={user.isUnder13} size="small" src={user.avatarUrl} />
                <span>{user.gamerHandle || Core.Identity.renderMemberName(user)}</span>
            </Link>
            <ToolTip trigger={<span>{commentTimeFromNow}</span>}>{commentTime}</ToolTip>
            <p>{content}</p>
        </div>
    );
};

export default Comment;

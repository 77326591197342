import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { isUndefined, orderBy } from 'lodash';
import pluralize from 'pluralize';

import * as Core from '../../../core';
import { BaseButton } from '../../../components/buttons-visuals';
import { ContentContainer } from '../../../components/containers';
import InfoMessage from '../../../components/infoMessage';
import { useUserPermissionService } from '../../../hooks/store';

import './matchGameLink.scss';

interface ChessMatchGameLinkProps {
    canEditSeason: boolean;
    matchGame?: Core.Models.MatchGame;
    matchGames: Core.Models.MatchGame[];
    matchParticipants: Core.Models.MatchParticipant[];
    matchState: Core.Models.MatchState;
    minimumSeats: number;
    participantsUserCanEdit: {
        id: string;
    }[];
    seasonTitleConfiguration?: Core.Models.ChessConfiguration;
    tennisStyle: boolean;
}

const ChessMatchGameLink = (props: ChessMatchGameLinkProps): JSX.Element => {
    const {
        canEditSeason,
        matchGame,
        matchGames,
        matchParticipants,
        matchState,
        minimumSeats,
        participantsUserCanEdit,
        seasonTitleConfiguration,
        tennisStyle,
    } = props;

    const userPermissionService = useUserPermissionService();

    const currentUserId = useMemo(() => userPermissionService.getUserId(), [userPermissionService]);

    const matchParticipantsUserCanEdit = useMemo(
        () =>
            matchParticipants.filter((mp: Core.Models.MatchParticipant) =>
                participantsUserCanEdit.some((p: { id: string }) => p.id === mp.id)
            ),
        [matchParticipants, participantsUserCanEdit]
    );

    const latestIncompleteSetNumber = useMemo(() => matchGame?.tennisStyleSetNumber, [matchGame?.tennisStyleSetNumber]);

    const setGames = useMemo(() => {
        if (!tennisStyle) return [];
        return matchGames.filter((mg: Core.Models.MatchGame) => mg.tennisStyleSetNumber === latestIncompleteSetNumber);
    }, [latestIncompleteSetNumber, matchGames, tennisStyle]);

    const matchParticipantMetadata = useMemo(
        () =>
            matchParticipants.map((matchParticipant: Core.Models.MatchParticipant) => {
                const chessMetadata = (
                    matchParticipant.matchGameMetadata
                        ?.filter((mgm: Core.Models.MatchGameMetadata) => mgm.matchParticipantId === matchParticipant.id)
                        .filter((mgm: Core.Models.MatchGameMetadata) =>
                            tennisStyle
                                ? setGames.some((mg: Core.Models.MatchGame) => mg.id === mgm.matchGameId)
                                : mgm.matchGameId === matchGame?.id
                        ) || []
                )
                    .map(
                        (mpMetadata: Core.Models.MatchGameMetadata) =>
                            mpMetadata.value as Core.Models.ChessMetadata | undefined
                    )
                    .filter((m: Core.Models.ChessMetadata | undefined): m is Core.Models.ChessMetadata => !!m);

                const sorted = orderBy(chessMetadata, [
                    (metadata: Core.Models.ChessMetadata) => {
                        const user = matchParticipant.users.find(
                            (mpu: Core.Models.MatchParticipantUser) => mpu.id === metadata.matchParticipantUserId
                        );
                        return user?.sortOrder;
                    },
                ]);

                return { matchParticipantId: matchParticipant.id, metadata: sorted };
            }),
        [matchGame?.id, matchParticipants]
    );

    const matchParticipantUsers = useMemo(
        () => matchParticipants.flatMap((mp: Core.Models.MatchParticipant) => mp.users),
        [matchParticipants]
    );

    const currentUserMetadata = useMemo(
        () =>
            matchParticipantMetadata
                .flatMap((mpm) => mpm.metadata)
                .filter((m: Core.Models.ChessMetadata) => {
                    const matchParticipantUser = matchParticipantUsers.find(
                        (mpu: Core.Models.MatchParticipantUser) => mpu.id === m.matchParticipantUserId
                    );
                    return matchParticipantUser?.userId === currentUserId;
                }),
        [currentUserId, matchParticipantMetadata, matchParticipantUsers]
    );

    const missingMatchGameUrls = useMemo(() => {
        const matchParticipantsUserCanContribute = matchParticipants.filter(
            (mp: Core.Models.MatchParticipant) =>
                canEditSeason || userPermissionService.hasTeamAccess(Core.Models.PermissionLevel.Contribute, mp.teamId)
        );
        return matchParticipantMetadata
            .filter((mpm) =>
                matchParticipantsUserCanContribute.some(
                    (mp: Core.Models.MatchParticipant) => mp.id === mpm.matchParticipantId
                )
            )
            .some((mpm) => mpm.metadata.filter((cm) => !!cm.opponentUsername).length !== minimumSeats);
    }, [canEditSeason, matchParticipantMetadata, matchParticipants, minimumSeats, userPermissionService]);

    if (matchState !== Core.Models.MatchState.InProgress) return <></>;
    if (isUndefined(matchGame)) return <></>;
    if (!seasonTitleConfiguration?.generateChessDotComLinks) return <></>;

    return (
        <ContentContainer
            className="chess-match-game-links mb"
            radius={Core.Models.RadiusSizes.Large}
            shade={Core.Models.Shades.Dark20}
        >
            <h2 className="heading-2">
                Chess.com {pluralize('link', currentUserMetadata.length)} for{' '}
                {tennisStyle ? `Set ${latestIncompleteSetNumber}` : `Game ${matchGame.sortOrder}`}
            </h2>
            <p className="text-small">
                <em>
                    Players: Please ensure you are logged in to Chess.com, and click the link below to create a
                    challenge against your opponent.
                </em>
            </p>
            {missingMatchGameUrls ? (
                <InfoMessage
                    message={`Error generating challenge links for ${
                        tennisStyle ? `Set ${latestIncompleteSetNumber}` : `Game ${matchGame.sortOrder}`
                    }. Please ensure ${
                        minimumSeats === 1 ? 'you and your opponent' : 'everyone on your team and the opposing team'
                    } have added your Chess.com handles added to your profiles in order for links to be generated for future games.`}
                    type="error"
                />
            ) : currentUserMetadata.length > 0 ? (
                currentUserMetadata.map((metadata: Core.Models.ChessMetadata, index: number) => {
                    const user = matchParticipantUsers.find(
                        (mpu: Core.Models.MatchParticipantUser) => mpu.id === metadata.matchParticipantUserId
                    );
                    const opponentUser = matchParticipantUsers.find(
                        (mpu: Core.Models.MatchParticipantUser) => mpu.id === metadata.opponentMatchParticipantUserId
                    );
                    if (!user || !opponentUser) return <></>;
                    return (
                        <ChessExternalLink
                            currentUserId={currentUserId}
                            key={index}
                            matchGameSortOrder={matchGame.sortOrder}
                            metadata={metadata}
                            opponentUser={opponentUser}
                            user={user}
                        />
                    );
                })
            ) : (
                matchParticipantsUserCanEdit.map((mp: Core.Models.MatchParticipant) => {
                    const chessMetadata =
                        matchParticipantMetadata.find((mpm) => mpm.matchParticipantId === mp.id)?.metadata || [];
                    return (
                        <div className="chess-match-game-links__wrapper" key={mp.id}>
                            <h3 className="heading-3">{mp.name}:</h3>
                            {chessMetadata.map((metadata: Core.Models.ChessMetadata, index: number) => {
                                const user = mp.users.find(
                                    (mpu: Core.Models.MatchParticipantUser) =>
                                        mpu.id === metadata.matchParticipantUserId
                                );
                                const opponentUser = matchParticipantUsers.find(
                                    (mpu: Core.Models.MatchParticipantUser) =>
                                        mpu.id === metadata.opponentMatchParticipantUserId
                                );
                                if (!user || !opponentUser) return <></>;
                                return (
                                    <ChessExternalLink
                                        key={index}
                                        matchGameSortOrder={matchGame.sortOrder}
                                        metadata={metadata}
                                        opponentUser={opponentUser}
                                        user={user}
                                    />
                                );
                            })}
                        </div>
                    );
                })
            )}
        </ContentContainer>
    );
};

interface ChessExternalLinkProps {
    currentUserId?: string;
    matchGameSortOrder: number;
    metadata: Core.Models.ChessMetadata;
    opponentUser: Core.Models.MatchParticipantUser;
    user: Core.Models.MatchParticipantUser;
}

const ChessExternalLink = (props: ChessExternalLinkProps): JSX.Element => {
    const { currentUserId, matchGameSortOrder, metadata, opponentUser, user } = props;
    const { baseTimeControl, color, opponentUsername, timeIncrement } = metadata;
    const url = useMemo(
        () =>
            `https://www.chess.com/play/online?action=createLiveChallenge&base=${baseTimeControl}&timeIncrement=${timeIncrement}&opponent=${opponentUsername}&color=${color}`,
        [baseTimeControl, color, opponentUsername, timeIncrement]
    );

    const whiteSide = color === Core.Models.ChessColor.White ? user : opponentUser;
    const blackSide = color === Core.Models.ChessColor.Black ? user : opponentUser;

    return (
        <BaseButton
            as="a"
            className="chess-external-link"
            href={url}
            rel="noopener noreferrer"
            target="_blank"
            title={`Go to Game ${matchGameSortOrder} on Chess.com`}
        >
            <ContentContainer
                className="chess-external-link__users"
                radius={Core.Models.RadiusSizes.Medium}
                overflowHidden
                shade={Core.Models.Shades.Dark40}
            >
                <div className="chess-external-link__users__user">
                    <div className="background-white-dark mr radius-xsmall">
                        <FontAwesomeIcon className="color-white pl pr pt-half pb-half" icon={['fas', 'chess-pawn']} />
                    </div>
                    <div
                        className={classNames('chess-external-link__users__user-name pr2x', {
                            'chess-external-link__users__user-name--isme': currentUserId === whiteSide.userId,
                        })}
                    >
                        {Core.Identity.renderMemberName(whiteSide)}
                    </div>
                </div>
                <div className="chess-external-link__vs">vs</div>
                <div className="chess-external-link__users__user text-right">
                    <div
                        className={classNames('chess-external-link__users__user-name pl2x', {
                            'chess-external-link__users__user-name--isme': currentUserId === blackSide.userId,
                        })}
                    >
                        {Core.Identity.renderMemberName(blackSide)}
                    </div>
                    <div className="background-black-light ml radius-xsmall">
                        <FontAwesomeIcon className="color-black pl pr pt-half pb-half" icon={['fas', 'chess-pawn']} />
                    </div>
                </div>
            </ContentContainer>
            <div className="chess-external-link__icon">
                <FontAwesomeIcon icon={['fas', 'arrow-up-right-from-square']} />
            </div>
        </BaseButton>
    );
};

export default ChessMatchGameLink;

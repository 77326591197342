import * as Core from '../core';
import { CreateLeagueFormValues } from '../pages/createLeague';

export abstract class LeagueService {
    public static async getLeagueConfigurationForUrl(url: string): Promise<Core.Models.LeagueConfiguration> {
        const route = Core.API.ServerRoute.forAction('leagues', `byUrl?url=${encodeURIComponent(url)}`);
        return await Core.API.get(route, undefined, undefined, { sendLeagueId: false });
    }

    public static async editLeague(command: Core.Models.EditLeagueRequest): Promise<Core.Models.League> {
        const route = Core.API.ServerRoute.forAction('leagues', '');
        return await Core.API.patch(route, command);
    }

    public static async createLeague(request: CreateLeagueFormValues): Promise<Core.Models.RegistrationResponse> {
        const route = Core.API.ServerRoute.forAction('users', 'league');
        return await Core.API.post(route, request, undefined);
    }

    public static async extractLeagueLogoColors(): Promise<void> {
        const route = Core.API.ServerRoute.forAction('leagues', 'images/extract-logo-colors');
        return await Core.API.post(route, undefined);
    }

    public static async uploadImage(command: Core.Models.UploadLeagueImageCommand): Promise<string> {
        const route = Core.API.ServerRoute.forAction('leagues', `images`);
        return await Core.API.post(route, command);
    }

    public static async deleteImage(imageType: Core.Models.LeagueImageType): Promise<void> {
        const route = Core.API.ServerRoute.forAction('leagues', `images/${imageType}`);
        return await Core.API.delete(route);
    }

    public static async getLeagueUsersByRole(roleId?: string, seasonId?: string): Promise<Core.Models.LeagueMember[]> {
        const route = Core.API.ServerRoute.forAction('leagues', 'users');
        return await Core.API.get(route, undefined, { roleId, seasonId });
    }

    public static async getLeagueApprovedGames(): Promise<Core.Models.Game[]> {
        const route = Core.API.ServerRoute.forAction('leagues', 'games');
        return await Core.API.getAnonymous(route);
    }

    public static async addLeagueApprovedGame(
        command: Core.Models.AddLeagueApprovedGameCommand
    ): Promise<Core.Models.Game[]> {
        const route = Core.API.ServerRoute.forAction('leagues', 'games');
        return await Core.API.post(route, command);
    }

    public static async deleteLeagueApprovedGame(gameId: string): Promise<Core.Models.Game[]> {
        const route = Core.API.ServerRoute.forAction('leagues', `games/${gameId}`);
        return await Core.API.delete(route);
    }

    public static async inviteUsers(command: Core.Models.InviteToLeagueCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('leagues', 'users/invite');
        return await Core.API.post(route, command);
    }

    public static async deleteUserLeagueRole(userLeagueRoleId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('leagues', `users/${userLeagueRoleId}`);
        return await Core.API.delete(route);
    }

    public static async getIsValidSubdomain(subdomain: string): Promise<boolean> {
        const route = Core.API.ServerRoute.forAction('leagues', `available?subdomain=${subdomain}`);
        return await Core.API.getAnonymous(route);
    }

    // custom registration fields
    public static async getLeagueCustomRegistrationFields(
        entityType: Core.Models.CustomRegistrationFieldEntityType
    ): Promise<Core.Models.CustomRegistrationField[]> {
        const route = Core.API.ServerRoute.forAction(
            'leagues',
            `registrationFields?entityType=${encodeURIComponent(entityType.toString())}`
        );
        return await Core.API.getAnonymous(route);
    }

    public static async createLeagueCustomRegistrationField(
        command: Core.Models.CustomRegistrationField
    ): Promise<Core.Models.CustomRegistrationField> {
        const route = Core.API.ServerRoute.forAction('leagues', `registrationFields`);
        return await Core.API.post(route, command);
    }

    public static async editLeagueCustomRegistrationField(
        command: Core.Models.CustomRegistrationField
    ): Promise<Core.Models.CustomRegistrationField> {
        const route = Core.API.ServerRoute.forAction('leagues', `registrationFields`);
        return await Core.API.put(route, command);
    }

    public static async deleteLeagueCustomRegistrationField(fieldId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('leagues', `registrationFields/${fieldId}`);
        return await Core.API.delete(route);
    }

    public static async addLeagueHelpfulLink(
        command: Core.Models.AddLeagueHelpfulLinkCommand
    ): Promise<Core.Models.HelpfulLink> {
        const route = Core.API.ServerRoute.forAction('leagues', 'helpfulLinks');
        return await Core.API.post(route, command);
    }

    public static async deleteLeagueHelpfulLink(id: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('leagues', `helpfulLinks/${id}`);
        return await Core.API.delete(route);
    }

    public static async updateLeagueHelpfulLinkOrder(
        request: Core.Models.UpdateLeagueHelpfulLinkOrderRequest
    ): Promise<void> {
        const route = Core.API.ServerRoute.forAction('leagues', 'helpfulLinks/order');
        return await Core.API.patch(route, request);
    }

    public static async updateDiscordGuild(guildId: string | undefined): Promise<void> {
        const route = Core.API.ServerRoute.forAction('leagues', 'discordGuild');
        return await Core.API.patch(route, { guildId });
    }

    public static async getStandings(): Promise<Core.Models.RelevantGroupMergedStandingsResult> {
        const route = Core.API.ServerRoute.forAction('leagues', `teamStandings`);
        const response = await Core.API.get<Core.Models.RelevantGroupStandingsResult>(route);

        return {
            participantIds: response.participantIds,
            groups: [...response.rankedGroups, ...response.eliminationGroups],
        };
    }

    public static async getTwitchLeagueStreams(): Promise<Core.Models.TwitchStream[]> {
        const route = Core.API.ServerRoute.forAction('leagues', `streams/video`);
        return await Core.API.getAnonymous(route);
    }

    public static async getLeagueStreams(): Promise<Core.Models.Stream[]> {
        const route = Core.API.ServerRoute.forAction('leagues', `streams`);
        return await Core.API.getAnonymous(route);
    }

    public static async createLeagueStream(request: Core.Models.CreateLeagueStreamRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('leagues', `streams`);
        return await Core.API.post(route, request);
    }

    public static async editLeagueStream(request: Core.Models.EditStreamRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('leagues', `streams`);
        return await Core.API.patch(route, request);
    }

    public static async updateEnableStream(request: Core.Models.EnableStreamRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`leagues`, `streams/enable`);
        return await Core.API.patch(route, request);
    }

    public static async deleteLeagueStream(streamId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('leagues', `streams/${streamId}`);
        return await Core.API.delete(route);
    }

    public static async createLeagueDesignation(command: Core.Models.CreateLeagueDesignationCommand) {
        const route = Core.API.ServerRoute.forAction('leagues', 'designations');
        return await Core.API.post<Core.Models.CreateLeagueDesignationResponse>(route, command);
    }

    public static async deleteLeagueDesignation({ designationId }: Core.Models.DeleteLeagueDesignationCommand) {
        const route = Core.API.ServerRoute.forAction('leagues', `designations/${designationId}`);
        return await Core.API.delete(route);
    }

    public static async updateLeagueDesignations(command: Core.Models.UpdateLeagueDesignationCommand) {
        const route = Core.API.ServerRoute.forAction('leagues', 'designations');
        return await Core.API.patch<Core.Models.UpdateLeagueDesignationResponse>(route, command);
    }

    public static async createLeagueLocation(command: Core.Models.CreateLeagueLocationCommand) {
        const route = Core.API.ServerRoute.forAction('leagues', 'locations');
        return await Core.API.post<Core.Models.CreateLeagueLocationResponse>(route, command);
    }

    public static async deleteLeagueLocation(locationId: string) {
        const route = Core.API.ServerRoute.forAction('leagues', `locations/${locationId}`);
        return await Core.API.delete(route);
    }

    public static async getLeagueLocationsWithStations(locationId?: string) {
        const route = Core.API.ServerRoute.forAction('leagues', `locations${!!locationId ? `/${locationId}` : ''}`);
        return await Core.API.get<Core.Models.GetLeagueLocationsWithStationsResponse>(route);
    }

    public static async updateLeagueLocation(command: Core.Models.UpdateLeagueLocationCommand) {
        const route = Core.API.ServerRoute.forAction('leagues', 'locations');
        return await Core.API.patch<Core.Models.UpdateLeagueLocationResponse>(route, command);
    }

    public static async createLeagueLocationStation(command: Core.Models.CreateLeagueLocationStationCommand) {
        const route = Core.API.ServerRoute.forAction('leagues', 'stations');
        return await Core.API.post<Core.Models.CreateLeagueLocationStationResponse>(route, command);
    }

    public static async deleteLeagueLocationStation(stationId: string) {
        const route = Core.API.ServerRoute.forAction('leagues', `stations/${stationId}`);
        return await Core.API.delete(route);
    }

    public static async updateLeagueLocationStation(command: Core.Models.UpdateLeagueLocationStationCommand) {
        const route = Core.API.ServerRoute.forAction('leagues', 'stations');
        return await Core.API.patch<Core.Models.UpdateLeagueLocationStationResponse>(route, command);
    }

    public static async updateLeagueThemeColors(request: Core.Models.ThemeColors) {
        const route = Core.API.ServerRoute.forAction('leagues', 'colors');
        return await Core.API.patch<void>(route, request);
    }

    public static async getMyLeagues(
        paginatedOptions: Core.Models.PaginatedOptions
    ): Promise<Core.Models.PaginatedResult<Core.Models.LeagueSearchResult>> {
        const route = Core.API.ServerRoute.forAction('leagues', 'me');
        return await Core.API.get(route, Core.API.ApiVersion.v1, paginatedOptions, {
            headers: { 'X-Cross-League': true },
        });
    }

    public static async getLeagues(
        paginatedOptions: Core.Models.PaginatedOptions
    ): Promise<Core.Models.PaginatedResult<Core.Models.LeagueSearchResult>> {
        const route = Core.API.ServerRoute.forAction('leagues', 'search');
        return await Core.API.get(route, Core.API.ApiVersion.v1, paginatedOptions, {
            headers: { 'X-Cross-League': true },
        });
    }
}

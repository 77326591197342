import React, { ChangeEvent } from 'react';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../../core';
import { useCreateRejectReschedulesForm } from './hooks';
import { RejectReschedulesMatchParticipant, RejectReschedulingMatch } from './types';
import { Button } from '../../../components/button';
import FormField from '../../../components/formField';
import InfoMessage from '../../../components/infoMessage';
import Loading from '../../../components/loading';
import { MatchService } from '../../../services/matchService';

import './styles.scss';

interface CreateRejectReschedulesFormProps {
    match: RejectReschedulingMatch;
    matchParticipants: RejectReschedulesMatchParticipant[];
    onClose: () => Promise<void>;
}

interface CreateRejectReschedulesFormValues {
    matchParticipantId: string;
    rejectRescheduleRequestsAt: Core.Models.RejectRescheduleRequestsAt;
}

const CreateRejectReschedulesForm = ({ match, matchParticipants, onClose }: CreateRejectReschedulesFormProps) => {
    const { getAutoRejectInfoMessage, getCurrentMatchParticipant, isInvalidMatchStartSelection } =
        useCreateRejectReschedulesForm(match.game, matchParticipants, match.startTimeUtc);
    return (
        <Formik<CreateRejectReschedulesFormValues>
            initialValues={Object.assign({
                matchParticipantId: matchParticipants[0]?.id ?? '',
                rejectRescheduleRequestsAt:
                    matchParticipants[0]?.rejectRescheduleRequestsAt ?? Core.Models.RejectRescheduleRequestsAt.None,
            })}
            onSubmit={async (
                { matchParticipantId, rejectRescheduleRequestsAt }: CreateRejectReschedulesFormValues,
                actions: FormikActions<CreateRejectReschedulesFormValues>
            ) => {
                actions.setStatus(undefined);
                try {
                    await MatchService.rejectReschedules({
                        matchId: match.id,
                        matchParticipantId,
                        rejectRescheduleRequestsAt,
                    });
                    await onClose();
                } catch (error) {
                    const message = Core.API.getErrorMessage(error);
                    actions.setStatus(message);
                } finally {
                    actions.setSubmitting(false);
                }
            }}
            render={({
                values,
                status,
                touched,
                errors,
                isSubmitting,
                setFieldValue,
                submitForm,
            }: FormikProps<CreateRejectReschedulesFormValues>) => {
                return (
                    <Form>
                        <fieldset className="form-group">
                            <FormField
                                component="select"
                                description="Team"
                                label="Team"
                                name="matchParticipantId"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    const newRejectRescheduleRequestsAt =
                                        getCurrentMatchParticipant(e.target.value)?.rejectRescheduleRequestsAt ??
                                        Core.Models.RejectRescheduleRequestsAt.None;
                                    setFieldValue('rejectRescheduleRequestsAt', newRejectRescheduleRequestsAt);
                                }}
                            >
                                {matchParticipants.map(({ id, name }) => (
                                    <option key={id} value={id}>
                                        {name || 'Deleted team'}
                                    </option>
                                ))}
                            </FormField>
                            <FormField
                                component="select"
                                description="Auto-reject options"
                                label="Auto-Reject Option"
                                name="rejectRescheduleRequestsAt"
                            >
                                <option
                                    key={Core.Models.RejectRescheduleRequestsAt.None}
                                    value={Core.Models.RejectRescheduleRequestsAt.None}
                                >
                                    None
                                </option>
                                <option
                                    key={Core.Models.RejectRescheduleRequestsAt.All}
                                    value={Core.Models.RejectRescheduleRequestsAt.All}
                                >
                                    All
                                </option>
                                <option
                                    key={Core.Models.RejectRescheduleRequestsAt.OneDayBeforeMatchStart}
                                    value={Core.Models.RejectRescheduleRequestsAt.OneDayBeforeMatchStart}
                                >
                                    24h Before Match Start
                                </option>
                            </FormField>
                        </fieldset>
                        {values.rejectRescheduleRequestsAt >= Core.Models.RejectRescheduleRequestsAt.None &&
                            !isInvalidMatchStartSelection(values.rejectRescheduleRequestsAt) && (
                                <InfoMessage
                                    message={getAutoRejectInfoMessage(values.rejectRescheduleRequestsAt)}
                                    type="info"
                                />
                            )}
                        {isInvalidMatchStartSelection(values.rejectRescheduleRequestsAt) && (
                            <InfoMessage message="Match does not have a valid start time." type="error" />
                        )}
                        {status && <InfoMessage message={status} type="error" />}
                        <InfoMessage message={errors} filter={touched} type="error" />
                        <fieldset className="form-group form-group--undecorated">
                            {isSubmitting && <Loading buttonLoader />}
                            <Button
                                disabled={
                                    isSubmitting ||
                                    values.rejectRescheduleRequestsAt < 0 ||
                                    isInvalidMatchStartSelection(values.rejectRescheduleRequestsAt)
                                }
                                onClick={submitForm}
                                round
                                wide
                            >
                                Submit Auto-Reject
                            </Button>
                        </fieldset>
                    </Form>
                );
            }}
            validationSchema={Yup.object().shape({
                matchParticipantId: Yup.string().required(),
                rejectRescheduleRequestsAt: Yup.number()
                    .required()
                    .oneOf(Core.Utils.getEnumAsIntArray(Core.Models.RejectRescheduleRequestsAt)),
            })}
        />
    );
};

export default CreateRejectReschedulesForm;

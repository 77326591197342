import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import './Toggle.scss';

interface ToggleSwitchProps {
    className?: string;
    disabled?: boolean;
    inverse?: boolean; // this allows the toggle to display the inverse of `value` while still preserving the value of `value`
    label?: string;
    onToggleSwitch: (value: boolean) => Promise<void>;
    value: boolean;
}

const ToggleSwitch = (props: ToggleSwitchProps): JSX.Element => {
    const { className, disabled, inverse, label, onToggleSwitch, value } = props;
    const ref = React.createRef<HTMLInputElement>();
    const [isChecked, setIsChecked] = useState<boolean>(xor(value, !!inverse));
    const [isFocused, setIsFocused] = useState<boolean>(false);

    useEffect(() => {
        const handleDown = async (event: KeyboardEvent) => {
            const { key: pressedKey } = event;
            if (isFocused && 'Enter' === pressedKey) {
                const checked = ref.current?.checked;
                setIsChecked(!checked);
                try {
                    await onToggleSwitch(xor(!checked, !!inverse));
                } catch (e) {
                    setIsChecked(!checked);
                }
            }
        };
        window.addEventListener('keydown', handleDown);
        return () => {
            window.removeEventListener('keydown', handleDown);
        };
    }, [isChecked, isFocused, inverse, ref, onToggleSwitch]);

    return (
        <label className={classNames(className, 'ds-toggle', { 'ds-toggle--checked': isChecked })}>
            <input
                checked={isChecked}
                disabled={!!disabled}
                onChange={async (e) => {
                    const checked = e.target.checked;
                    setIsChecked(checked);

                    try {
                        await onToggleSwitch(xor(checked, !!inverse));
                    } catch (e) {
                        setIsChecked(!checked);
                    }
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                ref={ref}
                type="checkbox"
            />
            <span
                className={classNames('ds-toggle__slider', {
                    disabled: !!disabled,
                })}
            ></span>
            {label}
        </label>
    );
};

// simplified XOR logic influenced by: https://www.howtocreate.co.uk/xor.html
const xor = (a: boolean, b: boolean) => a !== b;

export default ToggleSwitch;

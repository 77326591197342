import * as Redux from 'redux';

import { COOKIES_SUCCESS, CookiesActionTypes, CookiesState } from './types';

const initialState: CookiesState = {
    lastUpdatedUtc: undefined,
    performance: false,
    strictlyNecessary: true,
};

const cookiesReducer: Redux.Reducer<CookiesState, CookiesActionTypes> = (
    state = initialState,
    action: CookiesActionTypes
): CookiesState => {
    switch (action.type) {
        case COOKIES_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default cookiesReducer;

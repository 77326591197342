import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaymentMethod } from '@stripe/stripe-js';
import { Formik, FormikActions, FormikProps } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../core';
import CreatePaymentMethod from './createPaymentMethod';
import { useLeague, useUserPermissionService } from '../../hooks/store';
import { AuthenticationService } from '../../services/authenticationService';
import PaymentGatewayService from '../../services/paymentGatewayService';
import { SolidButton } from '../buttons-visuals';
import CreditCard from '../creditCard/creditCard';
import FormField from '../formField';
import InfoMessage from '../infoMessage';
import { Radio } from '../inputs/radio';
import withLoading, { WithLoadingProps } from '../withLoading';

interface FulfillPayableProps extends WithLoadingProps {
    etherialMode?: boolean;
    onCreated?: (payableFulfillment: Core.Models.PayableFulfillment) => void;
    onPaymentMethodSelected?: (paymentMethodId: string) => void;
    payableEntityType: Core.Models.PayableEntityType;
    payableId: string;
    teamId?: string;
    userId?: string;
    organizationId?: string;
}

export interface FulfillPayableFormValues {
    override: boolean;
    payableId: string;
    paymentGatewayId: string;
    paymentMethodId?: string;
    teamId?: string;
    userId?: string;
    organizationId?: string;
}

const FulfillPayable = ({
    etherialMode,
    onCreated,
    onPaymentMethodSelected,
    payableEntityType,
    payableId,
    setError,
    setIsLoading,
    teamId,
    userId,
    organizationId,
}: FulfillPayableProps) => {
    const league = useLeague();
    const userPermissionService = useUserPermissionService();

    const entityName = useMemo(
        () => (payableEntityType === Core.Models.PayableEntityType.Season ? 'team' : 'user'),
        [payableEntityType]
    );

    const [paymentMethods, setPaymentMethods] = useState<Core.Models.PaymentMethod[] | undefined>(undefined);

    const canEditLeague = useMemo(
        () => userPermissionService.hasLeagueAccess(Core.Models.PermissionLevel.Edit, league?.id),
        [league?.id]
    );

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            try {
                setPaymentMethods(
                    AuthenticationService.isAuthenticated()
                        ? await PaymentGatewayService.getPaymentMethods(Core.Models.PaymentGateway.Stripe)
                        : []
                );
            } catch {
                setError('There was an issue loading your existing payment methods. Please try again.');
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    if (paymentMethods === undefined) return <></>;
    return (
        <Formik<FulfillPayableFormValues>
            initialValues={{
                override: false,
                payableId,
                paymentGatewayId: Core.Models.PaymentGateway.Stripe,
                paymentMethodId: paymentMethods[0]?.paymentMethodId ?? '',
                teamId,
                userId,
                organizationId
            }}
            validationSchema={Yup.object().shape({
                override: Yup.boolean(),
                payableId: Yup.string().required('Entry fee is required'),
                paymentGatewayId: Yup.string().required('Payment gateway is required'),
                paymentMethodId: Yup.string().when('override', {
                    is: false,
                    then: Yup.string().required('Payment method is required'),
                }),
                ...(payableEntityType === Core.Models.PayableEntityType.Season
                    ? { teamId: Yup.string().required('Team is required') }
                    : {}),
                ...(payableEntityType === Core.Models.PayableEntityType.League
                    ? { userId: Yup.string().required('User is required') }
                    : {}),
            })}
            onSubmit={async (values: FulfillPayableFormValues, actions: FormikActions<FulfillPayableFormValues>) => {
                try {
                    const fulfillmentData = await PaymentGatewayService.fulfillPayable(values);

                    onCreated?.(fulfillmentData);
                } catch (error) {
                    const message = Core.API.getErrorMessage(error);
                    toast.error(message);
                } finally {
                    actions.setSubmitting(false);
                }
            }}
            render={(formProps: FormikProps<FulfillPayableFormValues>) => (
                <>
                    {canEditLeague ? (
                        <FormField description="Manually override entry fee" name="override" type="checkbox" />
                    ) : (
                        <input name="override" type="hidden" />
                    )}
                    <input name="payableId" type="hidden" />
                    <input name="paymentGatewayId" type="hidden" />
                    <input name="teamId" type="hidden" />
                    <input name="userId" type="hidden" />
                    <input name="organizationId" type="hidden" />

                    {formProps.values.override ? (
                        <p className="form__warning">
                            Overriding the entry fee for this {entityName} will set it as paid and assumes a transaction
                            has occurred outside of the system. The {entityName} will not be able to pay the fee through
                            the system later.
                        </p>
                    ) : (
                        <div className="mt2x mb2x">
                            {paymentMethods.length > 0 && (
                                <div>
                                    <ul>
                                        {paymentMethods.map((pm: Core.Models.PaymentMethod) => (
                                            <li key={pm.paymentMethodId}>
                                                <Radio
                                                    checked={formProps.values.paymentMethodId === pm.paymentMethodId}
                                                    id={pm.paymentMethodId}
                                                    label={<CreditCard paymentMethod={pm} />}
                                                    name="paymentMethodId"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const paymentMethodId = e.target.value;
                                                        formProps.setFieldValue('paymentMethodId', paymentMethodId);
                                                        onPaymentMethodSelected?.(paymentMethodId);
                                                    }}
                                                    value={pm.paymentMethodId}
                                                />
                                            </li>
                                        ))}
                                        <li>
                                            <Radio
                                                checked={formProps.values.paymentMethodId === ''}
                                                id="create-new"
                                                label={
                                                    <div className="disp-flex align-center">
                                                        <FontAwesomeIcon icon={['fas', 'credit-card']} />
                                                        <div className="ml2x text-italic">Create new</div>
                                                    </div>
                                                }
                                                name="paymentMethodId"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const paymentMethodId = e.target.value;
                                                    formProps.setFieldValue('paymentMethodId', paymentMethodId);
                                                    onPaymentMethodSelected?.(paymentMethodId);
                                                }}
                                                value=""
                                            />
                                        </li>
                                    </ul>
                                </div>
                            )}

                            {formProps.values.paymentMethodId === '' && (
                                <CreatePaymentMethod
                                    disableSave={etherialMode}
                                    onPaymentMethodCreated={(pm: PaymentMethod) => {
                                        // add new card inline
                                        paymentMethods.push({
                                            accountType: Core.Models.PaymentMethodAccountType.Card,
                                            brand: pm.card!.brand,
                                            expMonth: pm.card!.exp_month,
                                            expYear: pm.card!.exp_year,
                                            fundingSource: pm.card!.funding,
                                            id: pm.id,
                                            last4: pm.card!.last4,
                                            paymentMethodId: pm.id,
                                        });
                                        formProps.setFieldValue('paymentMethodId', pm.id);
                                        onPaymentMethodSelected?.(pm.id);
                                    }}
                                    paymentGatewayId={formProps.values.paymentGatewayId}
                                />
                            )}
                        </div>
                    )}

                    {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                    <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                    {!etherialMode && (formProps.values.override || !!formProps.values.paymentMethodId) && (
                        <fieldset className="form-group form-group--undecorated">
                            <SolidButton
                                as="button"
                                className="full-width"
                                disabled={!formProps.values.override && !formProps.values.paymentMethodId}
                                onClick={formProps.submitForm}
                                pending={formProps.isSubmitting}
                            >
                                Pay entry fee
                            </SolidButton>
                        </fieldset>
                    )}
                </>
            )}
        />
    );
};

export default withLoading(FulfillPayable);

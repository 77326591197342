import * as Core from '../core';

export abstract class MatchParticipantService {
    public static async create(request: Core.Models.CreateMatchParticipantRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matchParticipants');
        return await Core.API.post(route, request);
    }

    public static async delete(matchParticipantId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matchParticipants', matchParticipantId);
        return await Core.API.delete(route);
    }

    public static async assignStation(command: Core.Models.AssignMatchParticipantStationCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('matchParticipants', 'assign-station');
        return await Core.API.patch(route, command);
    }
}

import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { orderBy } from 'lodash';

import * as Core from '../../../core';
import StageSettingsModal from './stageSettingsModal';
import { AddButton, IconButton, TextButton } from '../../../components/buttons-visuals';
import Markdown from '../../../components/markdown';
import TabNav from '../../../components/tabNav';
import { useModal } from '../../../hooks/modal';
import { useCanEditSeason } from '../../../hooks/store';
import { LeagueService } from '../../../services/leagueService';
import SeasonStage from '../stage';

import './seasonStages.scss';

export interface SeasonStagesProps {
    externalLinks: boolean;
    initialStageId?: string;
    reloadSeason: (showLoading?: boolean) => Promise<void>;
    season: Core.Models.Season;
}

const SeasonStages = (props: SeasonStagesProps) => {
    const { externalLinks, initialStageId, reloadSeason, season } = props;

    const canEditSeason = useCanEditSeason(season.id);
    const seasonHasCompleted = season.currentState === Core.Models.CompetitionState.IsComplete;
    const stages = orderBy(season.stages, [(s: Core.Models.Stage) => s.sortOrder, (s: Core.Models.Stage) => s.id]);
    const [activeStage, setActiveStage] = useState<Core.Models.Stage | undefined>(undefined);
    const [currentTabIx, setCurrentTabIx] = useState(
        Math.max(
            stages.findIndex((s: Core.Models.Stage) => s.id === (initialStageId || season.currentStageId)),
            0
        )
    );

    useEffect(() => {
        if (!!activeStage) {
            const stage = season.stages.find((s: Core.Models.Stage) => s.id === activeStage.id);
            setActiveStage(stage);
        }
    }, [activeStage, season]);

    const [selectableLocations, setSelectableLocations] = useState<Core.Models.LeagueLocation[]>([]);
    useEffect(() => {
        (async () => {
            const { locations } = await LeagueService.getLeagueLocationsWithStations();
            setSelectableLocations(locations.filter((l: Core.Models.LeagueLocation) => !l.deleted));
        })();
    }, []);

    const [stageSettingsModal, openStageSettingsModal] = useModal(
        () => `${activeStage!.name} Settings`,
        () => (
            <StageSettingsModal
                {...{
                    game: season.game,
                    reloadSeason: () => reloadSeason(true),
                    stageId: activeStage!.id,
                }}
            />
        )
    );

    const tabs = useMemo(
        () =>
            stages.map((stage: Core.Models.Stage, index: number) => {
                const stageTypeName = Core.Competition.getStageTypeName(stage.stageTypeId);
                const showStatusIcon =
                    stage.currentState === Core.Models.StageState.IsComplete ||
                    stage.currentState === Core.Models.StageState.InProgress;

                return {
                    contents: (
                        <div className="ds-stages__stage">
                            <p className="text-large weight-700 mb0">
                                {showStatusIcon && (
                                    <FontAwesomeIcon
                                        className={classNames('mr', {
                                            'color-gray': stage.currentState === Core.Models.StageState.InProgress,
                                            'color-success': stage.currentState === Core.Models.StageState.IsComplete,
                                        })}
                                        icon={['fas', 'circle-check']}
                                        size="1x"
                                    />
                                )}
                                {stage.name}
                            </p>
                            <div className="color-gray no-wrap">
                                <p>{stageTypeName}</p>
                                <TextButton
                                    className="color-gray"
                                    onClick={() => {
                                        setActiveStage(stage);
                                        openStageSettingsModal();
                                    }}
                                >
                                    View all settings
                                </TextButton>
                            </div>
                        </div>
                    ),
                    id: stage.id,
                    name: stage.name.replace(/\s/g, '-').toLowerCase(),
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault();
                        setCurrentTabIx(index);
                    },
                };
            }),
        [openStageSettingsModal, stages]
    );

    return (
        <>
            <div className="global-container disp-flex align-center justify-start mt3x mb4x">
                <h2 className="heading-2 mb0 mr">Stages</h2>
                {stages.length > 0 && canEditSeason && !seasonHasCompleted && (
                    <IconButton
                        as="link"
                        buttonLabel="Create stage"
                        buttonSize="medium"
                        to={`/seasons/${season.id}/createStage`}
                    >
                        <FontAwesomeIcon className="color-success" icon={['fas', 'circle-plus']} />
                    </IconButton>
                )}
            </div>

            {stages.length > 0 && (
                <div className="ds-stages mb">
                    <TabNav current={currentTabIx} id={stages[currentTabIx].id} items={tabs} />
                </div>
            )}

            {stages.length <= 0 && canEditSeason && !seasonHasCompleted && (
                <div className="global-container">
                    <AddButton
                        as="link"
                        aria-label="Create stage"
                        buttonStyle="hollow"
                        buttonSize="xlarge"
                        className="attention mb2x"
                        layoutInline
                        to={`/seasons/${season.id}/createStage`}
                    >
                        Add Stage
                    </AddButton>
                </div>
            )}

            {stages.length <= 0 && season.description && (
                <div className="global-container">
                    <Markdown source={season.description} />
                </div>
            )}

            <div className="stages__content global-container">
                {stages[currentTabIx] && (
                    <SeasonStage
                        externalLinks={externalLinks}
                        id={stages[currentTabIx].id}
                        locations={selectableLocations}
                        onDelete={() => setCurrentTabIx(0)}
                        reloadSeason={reloadSeason}
                        season={season}
                    />
                )}
            </div>

            {stageSettingsModal}
        </>
    );
};

export default SeasonStages;

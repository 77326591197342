import { orderBy } from 'lodash';
import pluralize from 'pluralize';

import { CompetitionStyle, EventType, Ranking, RankingType, StageTypeId } from '../models';

export const renderCompetitionFormat = (game: {
    competitionStyle: CompetitionStyle;
    eventType: EventType;
    minimumSeats: number;
}) => {
    if (game.eventType === EventType.Class) return 'Class';
    if (game.competitionStyle === CompetitionStyle.HeadToHead)
        return `${game.minimumSeats}v${game.minimumSeats}`;
    switch (game.minimumSeats) {
        case 1:
            return 'Solos';
        case 2:
            return 'Duos';
        case 3:
            return 'Trios';
        case 4:
            return 'Squads';
        default:
            return '5+';
    }
};

export const hasRankings = (game?: {
    rankings: { [key: string]: number };
    rankingType: RankingType;
}): boolean => {
    if (!game) return false;
    if (
        game.rankingType === RankingType.Dictionary &&
        (!game.rankings || Object.keys(game.rankings).length <= 0)
    )
        return false;

    return true;
};

export const getRankings = (game?: { rankings: { [key: string]: number } }): Ranking[] => {
    if (!game || !game.rankings) return [];
    const rankings = Object.keys(game.rankings).map((key) => ({ key, value: game.rankings[key] }));
    return orderBy(rankings, (r: Ranking) => r.value, 'desc');
};

export const isValidRanking = (
    value: string,
    game?: {
        rankings: { [key: string]: number };
        rankingType: RankingType;
    }
): boolean => {
    const rankings = getRankings(game);
    return (
        !rankings ||
        (game?.rankingType === RankingType.Dictionary
            ? rankings.some((r: Ranking) => r.key === value)
            : !!value && isFinite(+value))
    );
};

export const getMatchWord = (
    eventType?: EventType,
    options?: { lowercase?: boolean; pluralize?: boolean }
): string => {
    let matchWord = eventType === EventType.Class ? 'Class' : 'Match';
    if (!!options?.lowercase) matchWord = matchWord.toLowerCase();
    if (!!options?.pluralize) matchWord = pluralize(matchWord);
    return matchWord;
};

export const getStageTypeName = (stageTypeId?: StageTypeId): string => {
    switch (stageTypeId) {
        case StageTypeId.AutoMatcher:
            return 'AutoMatcher';
        case StageTypeId.DoubleElimination:
            return 'Double Elimination';
        case StageTypeId.DoubleRoundRobin:
            return 'Double Round Robin';
        case StageTypeId.SingleElimination:
            return 'Single Elimination';
        case StageTypeId.Leaderboard:
            return 'Leaderboard';
        case StageTypeId.LeaderboardAutoMatcher:
            return 'Leaderboard AutoMatcher';
        case StageTypeId.RoundRobin:
            return 'Round Robin';
        case StageTypeId.Swiss:
            return 'Swiss';
        default:
            return '';
    }
};

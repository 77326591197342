import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { orderBy } from 'lodash';

import * as Core from '../../../core';
import DeckImage from './deckImage';

import './index.scss';

interface MatchParticipantDecksProps {
    matchParticipant: Core.Models.MatchParticipant;
}

const MatchParticipantDecks = (props: MatchParticipantDecksProps): JSX.Element => {
    const { matchParticipant } = props;

    const sortedDecks = useMemo(
        () => orderBy(matchParticipant.hearthstoneDecks, (deck: Core.Models.MatchHearthstoneDeck) => deck.sortOrder),
        [matchParticipant.hearthstoneDecks]
    );

    if (sortedDecks.length <= 0) return <></>;
    return (
        <div className="match-participant-decks">
            <h5 className="heading-5 mt3x">Decks</h5>
            <div className="match-participant-decks__container">
                {sortedDecks.map((deck: Core.Models.MatchHearthstoneDeck) => (
                    <a
                        className={classNames('match-participant-decks__deck', {
                            'match-participant-decks__deck--banned': deck.isBanned,
                        })}
                        href={`${Core.Constants.HEARTHSTONE_DECK_BASE_URL}deckstring=${encodeURIComponent(deck.code)}`}
                        key={deck.code}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <div className="match-participant-decks__deck_image">
                            <DeckImage name={deck.name} />
                            {deck.isBanned && (
                                <FontAwesomeIcon
                                    icon={['fas', 'times']}
                                    className="color-error position-absolute position-absolute--center"
                                    size="4x"
                                />
                            )}
                        </div>
                        <span className="match-participant-decks__deck_name">{deck.name}</span>
                    </a>
                ))}
                <a
                    className="link-button link-button--primary"
                    href={`${Core.Constants.HEARTHSTONE_DECK_BASE_URL}${sortedDecks
                        .map((deck: Core.Models.HearthstoneDeck) => `deckstring=${encodeURIComponent(deck.code)}`)
                        .join('&')}`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    View all
                </a>
            </div>
        </div>
    );
};

export default MatchParticipantDecks;

import { groupBy, mapValues } from 'lodash';
import { AssignedRole, AssignedRoles, PermissionLevel, Permissions } from '../models';

// anonymous users are allowed a certain level of access, so our 'no access' case is the anonymous level
export const anon: AssignedRole = {
    entityId: 'anon',
    leaguePermissions: PermissionLevel.Anonymous,
    organizationPermissions: PermissionLevel.Anonymous,
    seasonIds: [],
    teamPermissions: PermissionLevel.Anonymous,
};

export const globalAdminAccess: AssignedRole = {
    entityId: 'globalAdminAccess',
    leaguePermissions: PermissionLevel.ElevatedEdit,
    organizationPermissions: PermissionLevel.ElevatedEdit,
    seasonIds: [],
    teamPermissions: PermissionLevel.ElevatedEdit,
};

export const configurePermissions = ({ leagueRoles, organizationRoles, teamRoles }: AssignedRoles): Permissions => ({
    leagues: mapValues(
        groupBy(leagueRoles, (role: AssignedRole) => role.entityId),
        (roles: AssignedRole[]) => roles.reduce(mergePermissions)
    ),
    organizations: mapValues(
        groupBy(organizationRoles, (role: AssignedRole) => role.entityId),
        (roles: AssignedRole[]) => roles.reduce(mergePermissions)
    ),
    teams: mapValues(
        groupBy(teamRoles, (role: AssignedRole) => role.entityId),
        (roles: AssignedRole[]) => roles.reduce(mergePermissions)
    ),
});

/**
 * Merge two permission assignments together, keeping the highest rights of either.
 * This is designed to be usable as the method passed to Array.reduce()
 * @param p1 A role assignment
 * @param p2 A role assignment
 */
export const mergePermissions = (
    p1: AssignedRole | undefined,
    p2: AssignedRole
): AssignedRole => {
    if (!p1) {
        return p2;
    }
    if (!p2) {
        return p1;
    }

    return {
        entityId: p1.entityId,
        leaguePermissions: Math.max(p1.leaguePermissions, p2.leaguePermissions) as PermissionLevel,
        organizationPermissions: Math.max(
            p1.organizationPermissions,
            p2.organizationPermissions
        ) as PermissionLevel,
        seasonIds: p1.seasonIds,
        teamPermissions: Math.max(p1.teamPermissions, p2.teamPermissions) as PermissionLevel,
    };
};

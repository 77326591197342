import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../core';
import { Button } from '../../components/button';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';
import Loading from '../../components/loading';
import { MatchService } from '../../services/matchService';

interface EditMatchProps {
    gameFeatureSet: Core.Models.FeatureSet;
    match: {
        bestOf: number;
        game: { eventType: Core.Models.EventType };
        id: string;
        startTimeUtc?: string;
    };
    onCancel: () => void;
    onComplete: () => Promise<void>;
}

export interface EditMatchValues {
    bestOf: number;
    startTimeUtc?: string;
}

const schema = Yup.object().shape({
    bestOf: Yup.number(),
    startTimeUtc: Yup.string(),
});

const EditMatch = (props: EditMatchProps): JSX.Element => {
    const {
        gameFeatureSet,
        match: { bestOf, game, id, startTimeUtc },
    } = props;
    const matchWord = useMemo(() => Core.Competition.getMatchWord(game.eventType), [game.eventType]);

    return (
        <Formik
            initialValues={Object.assign({
                bestOf,
                startTimeUtc: startTimeUtc || '',
            })}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
                actions.setStatus(undefined);
                try {
                    await MatchService.edit({
                        ...(values as EditMatchValues),
                        id,
                    });
                    await props.onComplete();
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(formProps) => (
                <Form className="form">
                    <fieldset className="form-group">
                        <FormField component="datetime" name="startTimeUtc" label="Start time" />
                        {gameFeatureSet !== Core.Models.FeatureSet.Hearthstone && (
                            <FormField
                                description={`Games per ${matchWord.toLowerCase()}`}
                                name="bestOf"
                                type="number"
                            />
                        )}
                    </fieldset>

                    {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                    <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                    <fieldset className="form-group form-group--undecorated">
                        {formProps.isSubmitting && <Loading buttonLoader />}
                        <Button onClick={formProps.submitForm} disabled={formProps.isSubmitting} wide round>
                            Save {matchWord}
                        </Button>
                    </fieldset>
                </Form>
            )}
        />
    );
};

export default EditMatch;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { chunk, flatten, groupBy, isNumber, orderBy, shuffle } from 'lodash';
import { Link, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import { Button } from '../../../components/button';
import { Avatar } from '../../../components/buttons-visuals';
import Loading from '../../../components/loading';
import withLoading, { WithLoadingProps } from '../../../components/withLoading';
import { useAlternateSeasonName } from '../../../hooks/store';
import history from '../../../services/history';
import { SeasonService } from '../../../services/seasonService';

import './index.scss';

interface AssignFreeAgentsRouteProps {
    seasonId: string;
}

interface FreeAgentTeam {
    id?: string;
    logoUrl?: string;
    members: (FreeAgent | Core.Models.TeamMember)[];
    name: string;
    organization: Core.Models.Organization;
}

interface FreeAgent {
    avatarUrl?: string;
    email: string;
    firstName: string;
    hasOutstandingPayables?: boolean;
    joinedTimeUtc: string;
    lastName: string;
    organization: Core.Models.Organization;
    organizationId: string;
    pronouns?: string;
    userEntityRoleId?: string;
    userId: string;
}

interface AssignFreeAgentsProps extends RouteComponentProps<AssignFreeAgentsRouteProps>, WithLoadingProps {}

export const AssignFreeAgents = (props: AssignFreeAgentsProps) => {
    const {
        match: {
            params: { seasonId },
        },
        setError,
        setIsLoading,
    } = props;
    const [freeAgents, setFreeAgents] = useState<FreeAgent[]>([]);
    const [season, setSeason] = useState<Core.Models.Season | undefined>(undefined);
    const [teams, setTeams] = useState<Core.Models.Team[]>([]);
    const [freeAgentTeams, setFreeAgentTeams] = useState<FreeAgentTeam[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const seasonAlternateName = useAlternateSeasonName();

    const fillTeams = useCallback((freeAgents: FreeAgent[], season: Core.Models.Season, teams: Core.Models.Team[]) => {
        // randomize the order of teams
        const shuffledTeams = shuffle(teams);

        // group the free agents by organizationId and randomize the order
        const groupedFreeAgents = shuffle(
            Object.entries(
                groupBy(
                    orderBy(freeAgents, (fa: FreeAgent) => fa.joinedTimeUtc),
                    (fa: FreeAgent) => fa.organizationId
                )
            )
        );

        // create updated teams object (initially empty)
        let updatedTeams: FreeAgentTeam[] = [];

        // fill-in teams by organization
        for (let [organizationId, organizationFreeAgents] of groupedFreeAgents) {
            // get the existing teams within this organization and map to the desired type
            const existingTeams: FreeAgentTeam[] = shuffledTeams
                .filter((team: Core.Models.Team) => team.organizationId === organizationId)
                .map((team: Core.Models.Team) => ({
                    id: team.id,
                    logoUrl: team.logoUrl,
                    members: team.members
                        .filter((member: Core.Models.TeamMember) => !!member.userEntityRoleId)
                        .filter((member: Core.Models.TeamMember) => member.roleId !== Core.Models.TeamRoleId.Coach),
                    name: team.name,
                    organization: team.organization!,
                }));

            // if there's an entry fee or specified number of max participants, only take top N players (since they're currently ordered by joinedtime, but we randomize the order later when placing onto teams)
            if (!!season.payableId || isNumber(season.maxParticipants)) {
                // get the number of open spots available on existing teams
                let totalOpenSpots = existingTeams.reduce(
                    (previousValue: number, team: FreeAgentTeam) =>
                        previousValue + (season.game.maximumSeats - team.members.length),
                    0
                );

                // if there's a specified number of maximum participants and no entry fee, add number of spots on potential new teams to total number of open spots
                if (isNumber(season.maxParticipants) && !season.payableId) {
                    const numSeasonParticipants =
                        season.participants.filter((p: Core.Models.Participant) => p.isParticipating).length +
                        updatedTeams.filter((t: FreeAgentTeam) => !t.id).length;
                    const maxRemainingTeams = season.maxParticipants - numSeasonParticipants;
                    totalOpenSpots += maxRemainingTeams * season.game.maximumSeats;
                }

                // take the top number of free agents that can fit into existing teams
                organizationFreeAgents = organizationFreeAgents.slice(0, totalOpenSpots);
            }

            // randomize order of free agents
            organizationFreeAgents = shuffle(organizationFreeAgents);

            // fill in existing teams with participants up to the minimum roster size
            let organizationUpdatedTeams = [...existingTeams];
            organizationUpdatedTeams.forEach((team: FreeAgentTeam) => {
                const openSpots = season.game.minimumSeats - team.members.length;
                for (let i = 0; i < openSpots; i++) {
                    const freeAgent = organizationFreeAgents.pop();
                    if (!!freeAgent) team.members.push(freeAgent);
                }
            });

            // if there's no entry fee, create new teams from remaining free agents
            if (!season.payableId) {
                // group free agents into chunks of the minimumSeats
                const chunkedFreeAgents = chunk(organizationFreeAgents, season.game.minimumSeats);

                // get the number of new teams to be created based on number of remaining free agents
                let numberOfNewTeams =
                    organizationFreeAgents.length === 0
                        ? 0
                        : Math.floor(organizationFreeAgents.length / season.game.minimumSeats);

                // if season has a max participants value, take the min of the expected number of new teams (based on number of free agents) and the number of teams remaining before the season reaches capacity
                if (isNumber(season.maxParticipants)) {
                    const numSeasonParticipants =
                        season.participants.filter((p: Core.Models.Participant) => p.isParticipating).length +
                        updatedTeams.filter((t: FreeAgentTeam) => !t.id).length;
                    const maxRemainingTeams = season.maxParticipants - numSeasonParticipants;
                    numberOfNewTeams = Math.min(maxRemainingTeams, numberOfNewTeams);
                }

                // create new teams filled w/ free agents
                for (let i = 0; i < numberOfNewTeams; i++) {
                    const newTeamMembers = chunkedFreeAgents.shift()!;
                    organizationUpdatedTeams.push({
                        members: newTeamMembers,
                        name: `New Team ${i + 1}`,
                        organization: newTeamMembers[0].organization,
                    });
                }

                // flatten the remaining chunked free agents back into an array
                organizationFreeAgents = flatten(chunkedFreeAgents);

                // handle remaining participants
                if (organizationFreeAgents.length > 0) {
                    // get the number of teams with open roster spots
                    const numTeamsWithOpenSpots = organizationUpdatedTeams.filter(
                        (team: FreeAgentTeam) => team.members.length < season.game.maximumSeats
                    ).length;

                    // get the number of teams participating in this season (including newly created ones)
                    const numSeasonParticipants =
                        season.participants.filter((p: Core.Models.Participant) => p.isParticipating).length +
                        updatedTeams.filter((t: FreeAgentTeam) => !t.id).length +
                        organizationUpdatedTeams.filter((t: FreeAgentTeam) => !t.id).length;

                    if (
                        numTeamsWithOpenSpots < organizationFreeAgents.length &&
                        (!isNumber(season.maxParticipants) || numSeasonParticipants < season.maxParticipants)
                    ) {
                        // create a new, partially-filled team
                        organizationUpdatedTeams.push({
                            members: organizationFreeAgents,
                            name: `New Team ${numberOfNewTeams + 1}`,
                            organization: organizationFreeAgents[0].organization,
                        });

                        // remaining free agents have been assigned to the final team, clear the list of free agents
                        organizationFreeAgents = [];
                    }
                }
            }

            // loop through remaining free agents and place onto teams up to the maximumSeats
            while (
                organizationFreeAgents.length > 0 &&
                organizationUpdatedTeams.some((team: FreeAgentTeam) => team.members.length < season.game.maximumSeats)
            ) {
                const unfilledTeams = shuffle(
                    organizationUpdatedTeams.filter(
                        (team: FreeAgentTeam) => team.members.length < season.game.maximumSeats
                    )
                );
                for (const team of unfilledTeams) {
                    const freeAgent = organizationFreeAgents.shift();
                    if (!!freeAgent) {
                        team.members = [...team.members, freeAgent];
                    }
                }
            }

            // add this organization's teams to the full list of teams
            updatedTeams = [...updatedTeams, ...organizationUpdatedTeams];
        }

        setFreeAgentTeams(updatedTeams);
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const seasonResponse = await SeasonService.getById(seasonId);
                setSeason(seasonResponse);

                const { freeAgents: freeAgentsResponse, teams: teamsResponse } =
                    await SeasonService.getFreeAgencyDetails(seasonId);
                setTeams(teamsResponse);

                const initialFreeAgents: FreeAgent[] = freeAgentsResponse.map(
                    (freeAgent: Core.Models.SeasonFreeAgent) => {
                        const { avatarUrl, email, firstName, hasOutstandingPayables, lastName, pronouns } =
                            freeAgent.user!;
                        return {
                            ...freeAgent,
                            avatarUrl,
                            email,
                            firstName,
                            hasOutstandingPayables,
                            lastName,
                            organization: freeAgent.organization!,
                            pronouns,
                        };
                    }
                );
                setFreeAgents(initialFreeAgents);

                fillTeams(initialFreeAgents, seasonResponse, teamsResponse);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [fillTeams, seasonId, setError, setIsLoading]);

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            const teams = freeAgentTeams
                .map((team: FreeAgentTeam) => ({
                    organizationId: team.organization.id,
                    teamId: team.id,
                    userIds: team.members
                        .filter((m: FreeAgent | Core.Models.TeamMember): m is FreeAgent => !m.userEntityRoleId)
                        .map((m: FreeAgent) => m.userId),
                }))
                .filter((team) => team.userIds.length > 0);
            await SeasonService.setFreeAgentTeams({ seasonId, teams });
            toast.success('Successfully updated free agency teams');
            history.push(`/seasons/${seasonId}`);
        } catch (e) {
            toast.error('An error occured updating free agency teams.  Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const orderedFreeAgentTeams = useMemo(
        () => orderBy(freeAgentTeams, (team: FreeAgentTeam) => team.organization.name),
        [freeAgentTeams]
    );
    const groupedFreeAgentTeams = useMemo(
        () => groupBy(orderedFreeAgentTeams, (team: FreeAgentTeam) => team.organization.id),
        [orderedFreeAgentTeams]
    );
    const disabled = useMemo(
        () =>
            freeAgents.length === 0 ||
            (!!season?.payableId && teams.length === 0) ||
            (isNumber(season?.maxParticipants) &&
                season!.participants.filter((p) => p.isParticipating).length === season!.maxParticipants &&
                teams.length === 0),
        [freeAgents.length, season, teams]
    );

    if (!season) return <></>;

    return (
        <div className="page assign-free-agents-page">
            <div className="assign-free-agents">
                <div className="assign-free-agents__page-header">
                    <h2>Assign Free Agents for {season.name}</h2>
                    <div className="assign-free-agents__button-section">
                        <Button
                            disabled={disabled}
                            onClick={() => fillTeams(freeAgents, season, teams)}
                            outline
                            styleType={Core.Models.StyleType.LinkLike}
                        >
                            Shuffle
                        </Button>
                        <Button disabled={disabled} onClick={onSubmit} styleType={Core.Models.StyleType.Primary}>
                            Save
                        </Button>
                        {isSubmitting && <Loading buttonLoader />}
                    </div>
                </div>
                <div>
                    {disabled ? (
                        <div>There are no free agents to assign in this {seasonAlternateName.toLowerCase()}.</div>
                    ) : (
                        <>
                            {Object.keys(groupedFreeAgentTeams).map((organizationId: string) => {
                                const groupedTeams = groupedFreeAgentTeams[organizationId];
                                const organization = groupedTeams[0]?.organization;

                                if (!organization) return <></>;
                                return (
                                    <div className="assign-free-agents__organization-section" key={organizationId}>
                                        <div className="assign-free-agents__organization-section__header">
                                            <Avatar
                                                className="mr2x"
                                                fallback="organization"
                                                size="small"
                                                src={organization.logoUrl}
                                            />
                                            <h3>
                                                <Link to={`/organizations/${organization.id}`}>
                                                    {organization.name}
                                                </Link>
                                            </h3>
                                        </div>
                                        <div className="assign-free-agents__team-list">
                                            {orderBy(groupedTeams, (team: FreeAgentTeam) => team.name).map(
                                                (team: FreeAgentTeam, index: number) => (
                                                    <div key={index} className="assign-free-agents__team-card">
                                                        <div
                                                            className="assign-free-agents__team-card__name"
                                                            title={team.name}
                                                        >
                                                            {team.id ? (
                                                                <Link to={`/teams/${team.id}`}>{team.name}</Link>
                                                            ) : (
                                                                <div>{team.name}</div>
                                                            )}
                                                        </div>
                                                        {team.members.map(
                                                            (
                                                                member: FreeAgent | Core.Models.TeamMember,
                                                                index: number
                                                            ) => (
                                                                <div
                                                                    key={index}
                                                                    className={classNames(
                                                                        'assign-free-agents__team-card__member',
                                                                        {
                                                                            'is-team-member': !!member.userEntityRoleId,
                                                                            'is-free-agent': !member.userEntityRoleId,
                                                                        }
                                                                    )}
                                                                >
                                                                    <Avatar
                                                                        fallback="user"
                                                                        size="small"
                                                                        src={member.avatarUrl}
                                                                    />
                                                                    <div className="assign-free-agents__team-card__member-name">
                                                                        {member.userId ? (
                                                                            <div>
                                                                                <Link
                                                                                    title={Core.Identity.renderMemberName(
                                                                                        member
                                                                                    )}
                                                                                    to={`/users/${member.userId}`}
                                                                                >
                                                                                    {Core.Identity.renderMemberName(
                                                                                        member
                                                                                    )}
                                                                                </Link>
                                                                                {!!member.hasOutstandingPayables && (
                                                                                    <FontAwesomeIcon
                                                                                        className="ml color-error"
                                                                                        icon={['fas', 'dollar-sign']}
                                                                                        title="User has not paid the league fee"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        ) : (
                                                                            <>{member.email}</>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withLoading(AssignFreeAgents, { loadingProps: { blockItem: true } });

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Avatar,
    AvatarGroup,
    Conversation,
    ConversationList,
    MainContainer,
    Sidebar,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { isUndefined } from 'lodash';

import * as Core from '../../core';
import CreateThreadModal from './createThreadModal';
import ThreadParticipantList from './participantList';
import Thread from './thread';
import moderatorRequestedIcon from '../../assets/images/icon-error.svg';
import genericUserIcon from '../../assets/images/userDefault.svg';
import { SolidButton } from '../../components/buttons-visuals';
import {
    NEW_MESSAGE_CREATED,
    NEW_THREAD_CREATED,
    PARTICIPANT_ADDED_TO_THREAD,
    PARTICIPANT_REMOVED_FROM_THREAD,
    THREAD_DELETED,
    useChatContext,
} from '../../contexts/chatContext';
import useApiIncrementalLoader from '../../hooks/apiIncrementalLoader';
import { useChatDisabled, useHasAnyLeagueMembershipRole, useHasLeagueAccess, useLeague } from '../../hooks/store';
import ChatService from '../../services/chatService';
import PlatformEventsService from '../../services/platformEventsService';

import '../generic.scss';
import './index.scss';

const CHAT_THREADS_PER_REQUEST = 20; // 8 items is roughly the size of the initial load

// https://chatscope.io/storybook/react/?path=/story/components-maincontainer--default-story
const ChatPage = (): JSX.Element => {
    const canEditLeague = useHasLeagueAccess(Core.Models.PermissionLevel.Edit);
    const chatContext = useChatContext();
    const league = useLeague();
    const canAccessChat = useHasAnyLeagueMembershipRole(Core.Models.PermissionLevel.ListSpecific);
    const chatDisabled = useChatDisabled() || !canAccessChat;

    const [isCreatingChatThread, setIsCreatingChatThread] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedThread, setSelectedThread] = useState<Core.Models.ChatThread | undefined>(undefined);

    const dmsEnabled = useMemo(() => !!league && !league.disableDms && canAccessChat, [league]);

    const getIncrementalChatThreadsAsync = useCallback(
        (
            incrementalItems: number,
            startTimeUtc?: Date
        ): Promise<Core.Models.IncrementalResult<Core.Models.ChatThread>> =>
            ChatService.getIncremental({
                incrementalItems,
                startTimeUtc,
            }),
        []
    );

    const { incrementalResults, loadIncremental, remaining, setIncrementalResults } = useApiIncrementalLoader(
        CHAT_THREADS_PER_REQUEST,
        getIncrementalChatThreadsAsync,
        setIsLoading
    );

    useEffect(() => {
        // if a thread is selected and deleted but no other thread exist, remove selected
        if (!!selectedThread && (incrementalResults ?? []).length <= 0) {
            setSelectedThread(undefined);
            return;
        }

        // if there are threads and either no selected thread or the selected thread no longer exists, select the first thread
        if (
            !isUndefined(incrementalResults) &&
            incrementalResults.length > 0 &&
            (!selectedThread || !incrementalResults.find((t: Core.Models.ChatThread) => t.id === selectedThread.id))
        ) {
            setSelectedThread(incrementalResults[0]);
        }
    }, [selectedThread, incrementalResults]);

    const handleNewMessageCreated = useCallback(
        (message: Core.Models.ChatThreadMessage) =>
            setIncrementalResults((currentThreads) => {
                const threadIndex = currentThreads.findIndex(
                    (thread: Core.Models.ChatThread) => thread.id === message.threadId
                );
                if (threadIndex < 0) return currentThreads;

                const updatedThread = currentThreads.splice(threadIndex, 1)[0];
                updatedThread.latestMessage = message;

                return [updatedThread, ...currentThreads];
            }),
        [setIncrementalResults]
    );

    const handleNewThreadCreated = useCallback(
        (thread: Core.Models.ChatThread) =>
            setIncrementalResults((currentThreads) => {
                const threadIndex = currentThreads.findIndex(
                    (currentThread: Core.Models.ChatThread) => currentThread.id === thread.id
                );
                if (threadIndex < 0) return [thread, ...currentThreads];

                currentThreads[threadIndex] = Object.assign({}, currentThreads[threadIndex], {
                    moderatorRequested: thread.moderatorRequested,
                    name: thread.name,
                    participants: thread.participants,
                }); // replace thread if already found

                return [...currentThreads];
            }),
        [setIncrementalResults]
    );

    const handleParticipantAdded = useCallback(
        (participant: Core.Models.ChatThreadParticipant) =>
            setIncrementalResults((currentThreads) => {
                const threadIndex = currentThreads.findIndex(
                    (thread: Core.Models.ChatThread) => thread.id === participant.threadId
                );
                if (threadIndex < 0) return currentThreads;

                const participantIndex = currentThreads[threadIndex].participants.findIndex(
                    (p: Core.Models.ChatThreadParticipant) => p.userId === participant.userId
                );
                if (participantIndex >= 0) return currentThreads; // participant already in list

                currentThreads[threadIndex].participants.push(participant);

                return [...currentThreads];
            }),
        [setIncrementalResults]
    );

    const handleParticipantRemoved = useCallback(
        (participant: Core.Models.ChatThreadParticipant) => {
            setIncrementalResults((currentThreads) => {
                const threadIndex = currentThreads.findIndex(
                    (thread: Core.Models.ChatThread) => thread.id === participant.threadId
                );
                if (threadIndex < 0) return currentThreads;

                const participantIndex = currentThreads[threadIndex].participants.findIndex(
                    (p: Core.Models.ChatThreadParticipant) => p.userId === participant.userId
                );
                if (participantIndex < 0) return currentThreads; // participant not found

                currentThreads[threadIndex].participants.splice(participantIndex, 1); // remove participant

                return [...currentThreads];
            });
        },
        [setIncrementalResults]
    );

    const handleThreadDeleted = useCallback(
        (threadId: string) => {
            setIncrementalResults((currentThreads) => {
                const threadIndex = currentThreads.findIndex(
                    (thread: Core.Models.ChatThread) => thread.id === threadId
                );
                if (threadIndex < 0) return currentThreads;

                return [...currentThreads.filter((thread: Core.Models.ChatThread) => thread.id !== threadId)];
            });
        },
        [setIncrementalResults]
    );

    const routeDataReceived = useCallback(
        ({ eventName, data }: { eventName: string; data: any }) => {
            switch (eventName) {
                case NEW_MESSAGE_CREATED:
                    return handleNewMessageCreated(data);
                case NEW_THREAD_CREATED:
                    return handleNewThreadCreated(data);
                case PARTICIPANT_ADDED_TO_THREAD:
                    return handleParticipantAdded(data);
                case PARTICIPANT_REMOVED_FROM_THREAD:
                    return handleParticipantRemoved(data);
                case THREAD_DELETED:
                    return handleThreadDeleted(data);
                default:
            }
        },
        [
            handleNewMessageCreated,
            handleNewThreadCreated,
            handleParticipantAdded,
            handleParticipantRemoved,
            handleThreadDeleted,
        ]
    );

    // this should only run once - the subscription shouldn't change between rerenders
    useEffect(() => {
        const subscription = PlatformEventsService.dataReceived.subscribe(routeDataReceived);
        return () => subscription.unsubscribe();
    }, [routeDataReceived]);

    const setLatestMessageReadId = useCallback(
        (threadId: string, messageId: string): void => {
            setIncrementalResults((currentThreads) => {
                const threadIndex = currentThreads.findIndex(
                    (thread: Core.Models.ChatThread) => thread.id === threadId
                );
                if (threadIndex < 0) return currentThreads;

                const newThreads = [...currentThreads];
                newThreads[threadIndex].latestMessageReadId = messageId;

                return newThreads;
            });

            // also update the context's unread threads
            chatContext.setLatestMessageReadId(threadId, messageId);
        },
        [setIncrementalResults]
    );

    return (
        <div className="page generic-page chat-page">
            <h2 className="generic-page__title">Chat</h2>
            <div className="generic-page__description">
                Use this page to create conversations with other users in your league.
            </div>

            <div className="generic-page__content" style={{ height: '600px' }}>
                <MainContainer>
                    <Sidebar position="left">
                        {dmsEnabled && (
                            <SolidButton
                                as="button"
                                className="mb mt ml mr"
                                disabled={chatDisabled}
                                onClick={() => setIsCreatingChatThread(true)}
                                size="medium"
                            >
                                + Create new conversation
                            </SolidButton>
                        )}
                        {!isLoading && incrementalResults.length <= 0 ? (
                            <p className="no-content">No conversations. Start one now!</p>
                        ) : (
                            <ConversationList
                                loading={isLoading}
                                onYReachEnd={async () => {
                                    if (!remaining) return;
                                    await loadIncremental();
                                }}
                            >
                                {incrementalResults.map((thread: Core.Models.ChatThread) => (
                                    <Conversation
                                        active={selectedThread && selectedThread.id === thread.id}
                                        key={thread.id}
                                        onClick={() => setSelectedThread(thread)}
                                        name={thread.name}
                                        unreadDot={thread.latestMessage?.id !== thread.latestMessageReadId}
                                    >
                                        <AvatarGroup hoverToFront={true} size="sm" max={4}>
                                            {thread.participants
                                                .filter(
                                                    (participant: Core.Models.ChatThreadParticipant) =>
                                                        !participant.removedTimeUtc && !participant.isProgrammatic
                                                )
                                                .map((participant: Core.Models.ChatThreadParticipant) => {
                                                    const avatarSrc = league?.disablePfp
                                                        ? genericUserIcon
                                                        : participant.avatarUrl ?? genericUserIcon;
                                                    return (
                                                        <Avatar
                                                            key={participant.userId}
                                                            name={participant.name}
                                                            src={avatarSrc}
                                                        />
                                                    );
                                                })}
                                        </AvatarGroup>
                                        {thread.moderatorRequested && (
                                            <Conversation.Operations visible>
                                                <img
                                                    src={moderatorRequestedIcon}
                                                    title="Moderator requested"
                                                    alt="Moderator requested"
                                                />
                                            </Conversation.Operations>
                                        )}
                                    </Conversation>
                                ))}
                            </ConversationList>
                        )}
                    </Sidebar>
                    {!!selectedThread && (
                        <Thread {...{ selectedThread, setLatestMessageReadId, showMatchLink: true }} />
                    )}
                    {!!selectedThread &&
                        selectedThread.participants.filter((p: Core.Models.ChatThreadParticipant) => !p.removedTimeUtc)
                            .length > 0 && (
                            <Sidebar position="right">
                                <ThreadParticipantList
                                    canEdit={canEditLeague}
                                    participants={selectedThread.participants.filter(
                                        (p: Core.Models.ChatThreadParticipant) => !p.removedTimeUtc
                                    )}
                                />
                            </Sidebar>
                        )}
                </MainContainer>
                <p className="chat-page__disclaimer">
                    Note: Your chat history is visible to league administrators.
                    <br />
                    Try asking Spot for a coin flip by saying "!coinflip".
                </p>
                {isCreatingChatThread && <CreateThreadModal onClose={() => setIsCreatingChatThread(false)} />}
            </div>
        </div>
    );
};

export default ChatPage;

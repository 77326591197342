import * as React from 'react';
import { orderBy } from 'lodash';

import * as Core from '../../core';
import genericLeagueIcon from '../../assets/images/logo.svg';
import { useLeagueConfiguration } from '../../hooks/store';

import './index.scss';

const AffiliateLeaguesPage = () => {
    const league = useLeagueConfiguration()!;

    const orderedAffiliateLeagues = orderBy(
        league.childLeagues,
        (affiliateLeague: Core.Models.BasicLeague) => affiliateLeague.name
    );

    const renderAffiliates = (): JSX.Element => {
        if (orderedAffiliateLeagues.length <= 0) {
            return <p>{league.league.name} does not have any affiliate leagues</p>;
        }

        return (
            <>
                {orderedAffiliateLeagues.map(
                    (affiliateLeague: Core.Models.BasicLeague, affiliateLeagueIndex: number) => (
                        <a
                            className="league-page__affiliates__affiliate"
                            href={affiliateLeague.domain}
                            key={affiliateLeagueIndex}
                        >
                            <div className="league-page__affiliates__affiliate__logo">
                                <img src={affiliateLeague.logoUrl || genericLeagueIcon} alt="" />
                            </div>
                            <div className="league-page__affiliates__affiliate__name">
                                <h3>{affiliateLeague.name}</h3>
                            </div>
                        </a>
                    )
                )}
            </>
        );
    };

    return (
        <div className="page affiliate-leagues-page">
            <h2 className="affiliate-leagues-page__title">Affiliate leagues</h2>
            <div className="affiliate-leagues-page__description">Leagues affiliated with {league.league.name}</div>

            <div className="affiliate-leagues-page__content">{renderAffiliates()}</div>
        </div>
    );
};

export default AffiliateLeaguesPage;

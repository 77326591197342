import * as React from 'react';

import * as Core from '../../core';
import { InviteManager } from './inviteManager';
import { OrganizationService } from '../../services/organizationService';

interface InvitePlayersContainerProps {
    organizationId: string;
    onComplete: () => Promise<void>;
}

class InvitePlayersContainer extends React.Component<InvitePlayersContainerProps> {
    render() {
        return <InviteManager onSubmit={this.onSubmit} />;
    }
    onSubmit = async (email: string) => {
        await OrganizationService.inviteUsers({
            organizationId: this.props.organizationId,
            roleId: Core.Models.OrganizationRoleId.Manager,
            users: [{ email }],
        });
        await this.props.onComplete();
    };
}

export default InvitePlayersContainer;

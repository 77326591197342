import * as React from 'react';

import * as Core from '../../core';

interface GroupHeaderProps {
    className?: string;
    group: Core.Models.Group;
    showGroupName?: boolean;
    showStageName?: boolean;
    stage: Core.Models.Stage;
}

const GroupHeader: React.FunctionComponent<GroupHeaderProps> = (props) => {
    const { group, showGroupName, stage, showStageName, className } = props;
    const headerText = [showStageName && stage!.name, showGroupName && group.name].filter((i) => i).join(' - ');
    return headerText ? <h4 className={className}>{headerText}</h4> : null;
};

export default GroupHeader;

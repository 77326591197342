import React, { useEffect } from 'react';

import * as Core from '../../core';
import { useLeague } from '../../hooks/store';

const StyleLoader = () => {
    const league = useLeague();

    useEffect(() => {
        switch (league?.featureGroup) {
            case Core.Models.FeatureGroup.HerGalaxy:
                import('../../styles/custom/hergalaxy.scss');
                break;
            case Core.Models.FeatureGroup.NACE:
                import('../../styles/custom/nace.scss');
                break;
        }
    }, [league]);

    return <></>;
};

export default StyleLoader;

import React, { useMemo } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { orderBy } from 'lodash';
import pluralize from 'pluralize';
import Select, { SelectOption } from 'react-select';
import * as Yup from 'yup';

import * as Core from '../../core';
import { Button } from '../../components/button';
import InfoMessage from '../../components/infoMessage';
import Loading from '../../components/loading';

interface AddTeamToRoundProps {
    group: Core.Models.Group;
    onSubmit: (groupParticipantId: string) => Promise<void>;
    round: Core.Models.Round;
    season: Core.Models.Season;
    stage: Core.Models.Stage;
}

interface AddTeamToRoundValues {
    groupParticipantId: string;
}

const schema = Yup.object().shape({
    groupParticipantId: Yup.string().required(),
});

const AddTeamToRound = (props: AddTeamToRoundProps): JSX.Element => {
    const { group, onSubmit, round, season, stage } = props;
    const matchWord = useMemo(
        () => Core.Competition.getMatchWord(season.game.eventType, { lowercase: true }),
        [season.game.eventType]
    );
    const eligibleTeams = useMemo(
        () =>
            orderBy(
                (group.groupParticipants || [])
                    .filter((gp: Core.Models.GroupParticipant) => gp.isParticipating)
                    .filter(
                        (gp: Core.Models.GroupParticipant) =>
                            !round.roundGroupParticipants?.some(
                                (rgp: Core.Models.RoundGroupParticipant) =>
                                    rgp.groupParticipant?.participantId === gp.participantId
                            )
                    ),
                (gp: Core.Models.GroupParticipant) => gp.name
            ),
        [group.groupParticipants, round.roundGroupParticipants]
    );
    return (
        <Formik
            initialValues={Object.assign({
                /** anything not specified here won't show an error message after an attempted submit */
                groupParticipantId: '',
            } as Partial<AddTeamToRoundValues>)}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
                actions.setStatus(undefined);
                try {
                    await onSubmit(values.groupParticipantId);
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(formProps: FormikProps<AddTeamToRoundValues>) => {
                const options = eligibleTeams
                    .filter((gp: Core.Models.GroupParticipant) => formProps.values.groupParticipantId !== gp.id)
                    .map((gp: Core.Models.GroupParticipant) => ({ label: gp.name, value: gp.id }) as SelectOption);
                return (
                    <Form className="form">
                        <fieldset className="form-group">
                            <Select
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(selectedOption: SelectOption | null) => {
                                    if (!!selectedOption) {
                                        formProps.setFieldValue('groupParticipantId', selectedOption.value);
                                    }
                                }}
                                options={options}
                                name="groupParticipantId"
                                placeholder="Select team"
                                styles={{ menu: (provided: any) => ({ ...provided, zIndex: 2 }) }}
                            />
                        </fieldset>
                        <p className="add-team__info">
                            <span>This will add the selected team to this in-progress round. </span>
                            {stage.stageTypeId === Core.Models.StageTypeId.LeaderboardAutoMatcher ? (
                                <span>
                                    If there is room, they will be added to {stage.settings.matchesPerRound} existing,
                                    unstarted {pluralize(matchWord, stage.settings.matchesPerRound)} this round.
                                </span>
                            ) : (
                                <span>You will still need to manually create a {matchWord} for them.</span>
                            )}
                        </p>
                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                        <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                        <fieldset className="form-group form-group--undecorated">
                            {formProps.isSubmitting && <Loading buttonLoader />}
                            <Button
                                onClick={formProps.submitForm}
                                disabled={formProps.isSubmitting || !formProps.values.groupParticipantId}
                                wide
                                round
                            >
                                Add team
                            </Button>
                        </fieldset>
                    </Form>
                );
            }}
        />
    );
};

export default AddTeamToRound;

import React from 'react';
import classNames from 'classnames';
import { isNumber, max, orderBy } from 'lodash';

import * as Core from '../../../core';
import { Button } from '../../../components/button';
import ErrorMessage from '../../../components/errorMessage';
import Loading from '../../../components/loading';
import { usePromiseOperation } from '../../../hooks/promiseOperation';
import { MatchService } from '../../../services/matchService';

import './index.scss';

interface Participant extends Core.Models.NamedObject {
    gameTies: number;
    gameWins: number;
    score?: number;
    sortOrder: number;
}

interface SubmitMatchResultProps {
    changeResultsWarningNode: React.ReactNode;
    matchId: string;
    matchParticipants: Participant[];
    onCancel: () => void;
    onComplete: () => Promise<void>;
    reload: () => Promise<void>;
}

const getWinnerFromScores = (participants: Participant[]) => {
    const winningScore = max(participants.map((participant: Participant) => participant.score || 0));
    const winners = participants.filter((participant: Participant) => participant.score === winningScore);
    if (winners.length !== 1) {
        // no clear winner
        return undefined;
    }
    return winners[0].id;
};

const SubmitMatchResult = (props: SubmitMatchResultProps): JSX.Element => {
    const { changeResultsWarningNode, matchId, matchParticipants, onCancel, onComplete, reload } = props;
    const participants = React.useMemo(
        () => orderBy(matchParticipants, (participant: Participant) => participant.sortOrder),
        [matchParticipants]
    );

    const [canSubmit, setCanSubmit] = React.useState(true);
    const [isSubmitting, submit, submitError] = usePromiseOperation(async () => {
        try {
            await MatchService.submitMatchResult({
                matchId,
                scores: matchParticipants.map((participant: Participant) => {
                    return {
                        matchParticipantId: participant.id,
                        score: participant.score!,
                    };
                }),
            });
        } catch (e) {
            // reload on failure - it could be because our data is out of date
            // also, disable re-submit - want to make the user close the dialog, see the updated score, and submit again
            await reload();
            setCanSubmit(false);
            throw e;
        }
        await onComplete();
    });

    const winner = getWinnerFromScores(participants);
    return (
        <div className="submit-match-result">
            <div className="submit-match-result__participants">
                {participants.map((participant: Participant) => {
                    return (
                        <div
                            className={classNames('submit-match-result__participant', {
                                'submit-match-result__participant--winner': winner === participant.id,
                            })}
                            key={participant.id}
                        >
                            <div className="submit-match-result__participant-name">{participant.name}</div>
                            <div
                                className={classNames('submit-match-result__participant-result', {
                                    'submit-match-result__participant-result--winner': winner === participant.id,
                                })}
                            >
                                Score: {isNumber(participant.score) ? participant.score : '-'}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="submit-match-result__completed-match-caution">{changeResultsWarningNode}</div>
            <div>{submitError && <ErrorMessage error={submitError} />}</div>
            <div>{isSubmitting && <Loading buttonLoader />}</div>
            <div className="submit-match-result__buttons">
                <Button onClick={submit} disabled={isSubmitting || !canSubmit}>
                    Submit Results
                </Button>
                <Button outline onClick={onCancel} disabled={isSubmitting}>
                    Go Back
                </Button>
            </div>
        </div>
    );
};

export default SubmitMatchResult;

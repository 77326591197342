import * as Core from '../core';

export abstract class GameHandleSourceService {
    public static async getAll(): Promise<Core.Models.GameHandleSource[]> {
        const route = Core.API.ServerRoute.forAction('gameHandleSources');
        return await Core.API.get(route);
    }

    public static async unlinkAccount(command: Core.Models.UnlinkAccountCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('gameHandleSources', 'unlink');
        return await Core.API.post(route, command);
    }
}

import * as React from 'react';
import { Formik, FormikProps, Form } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../../core';
import { Button } from '../../../components/button';
import ErrorMessage from '../../../components/errorMessage';
import FormField from '../../../components/formField';
import { CreatePassword } from '../../../components/inputs';
import Loading from '../../../components/loading';

interface ChangePasswordFormProps {
    onSubmit: (values: ChangePasswordFormValues) => Promise<void>;
}

export interface ChangePasswordFormValues {
    currentPassword: string;
    password: string;
}

const schema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required.'),
    password: Core.Validation.password('Password'),
    passwordConfirmation: Yup.string()
        .required('Password Confirmation is required')
        .oneOf([Yup.ref('password'), null], 'New passwords must match'),
});

const ChangePasswordForm: React.FunctionComponent<ChangePasswordFormProps> = (props) => {
    return (
        <Formik
            initialValues={Object.assign(
                {},
                {
                    /** anything not specified here won't show an error message after an attempted submit */
                    currentPassword: '',
                    password: '',
                    passwordConfirmation: '',
                }
            )}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
                actions.setStatus(undefined);
                try {
                    await props.onSubmit(values);
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(props: FormikProps<ChangePasswordFormValues>) => (
                <Form className="form">
                    <fieldset className="form-group">
                        <FormField type="password" name="currentPassword" description="Current Password" />
                    </fieldset>
                    <CreatePassword
                        passwordDescription="New Password"
                        confirmPasswordDescription="Confirm New Password"
                        noFlyout
                    />
                    {props.status && <ErrorMessage error={props.status} />}
                    <ErrorMessage error={props.errors} filter={props.touched} />
                    <fieldset className="form-group form-group--undecorated">
                        {props.isSubmitting && <Loading buttonLoader />}
                        <Button onClick={props.submitForm} disabled={props.isSubmitting} wide outline>
                            Update
                        </Button>
                    </fieldset>
                </Form>
            )}
        />
    );
};

export default ChangePasswordForm;

import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { PullContainerProps } from './PullContainer.types';
import { IconButton } from '../../../components/buttons-visuals';

import './PullContainer.scss';

const PullContainer = (props: PullContainerProps) => {
    const { children, className, isOpen, handleToggle, externalTrigger, ...rest } = props;

    const contentRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(2000);
    const [containerOpen, setContainerOpen] = useState(isOpen);

    const pullContainerClasses = classNames(className, 'ds-pull-container', {
        'ds-pull-container--open': containerOpen,
    });

    const pullContainerStyles = {
        right: containerOpen ? 0 : -containerWidth,
    };

    useEffect(() => {
        setContainerWidth(contentRef.current ? contentRef.current.scrollWidth : 2000);
    }, []);

    const toggleOpen = () => {
        handleToggle && handleToggle();
        setContainerOpen(!containerOpen);
    };

    return (
        <div className={pullContainerClasses} ref={contentRef} style={pullContainerStyles} {...rest}>
            <IconButton
                as="button"
                buttonLabel={containerOpen ? 'close content' : 'reveal more'}
                buttonSize="small"
                className={classNames('ds-pull-container__trigger', {
                    'ds-pull-container__trigger--external': handleToggle,
                })}
                onClick={toggleOpen}
                tabIndex={handleToggle && !containerOpen ? -1 : 0}
            >
                <FontAwesomeIcon icon={['fas', handleToggle ? 'xmark' : 'ellipsis']} />
            </IconButton>
            <div className="ds-pull-container__content">
                {React.Children.map(children, (child) =>
                    React.cloneElement(child, {
                        tabIndex: containerOpen ? 0 : -1,
                    })
                )}
            </div>
        </div>
    );
};

export default PullContainer;

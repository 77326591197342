// general
export const Company: string = 'LeagueSpot';
export const EMPTY_STRING: string = '';

// cache keys
export const CACHE_TOKEN: string = 'token';
export const CACHE_TOKEN_ORIGINAL: string = 'token_original';

// error messages
export const USER_DOES_NOT_HAVE_ROLE_IN_THIS_LEAGUE = 'This user does not have a role in this league.';

// time
export const FORM_DEBOUNCE_TIME_MS = 300;
export const FORM_DEBOUNCE_TIME_MS_LONG = 600;
export const POST_CREATED_TIME_MS = 60_000;

// max lengths
export const DOUBLE_ELIM_GROUP_MAX_LENGTH = 64;
export const EMAIL_MAX_LENGTH = 256;
export const GAMER_HANDLE_MAX_LENGTH = 50;
export const MARKDOWN_MAX_LENGTH = 4000;
export const NAME_MAX_LENGTH = 50;
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 100;
export const PHONE_NUMBER_MAX_LENGTH = 20;
export const SOCIAL_POST_MAX_LENGTH = 200;
export const TWITCH_USERNAME_MAX_LENGTH = 25;
export const URL_MAX_LENGTH = 2000;
export const USERNAME_MAX_LENGTH = 50;
export const USERNAME_MIN_LENGTH = 2;

// images
export const IMAGES = {
    MAX_AVATAR_IMAGE_WIDTH: 200,
    MAX_IDENTITY_VERIFICATION_IMAGE_SIZE: 10_485_760, // 10 MB
    MAX_IMAGE_SIZE: 512_000, // 500 KB
    MAX_LEAGUE_IMAGE_SIZE: 2_097_152, // 2 MB
};

export const FILES = {
    MAX_RESULT_FILE_SIZE: 52_428_800, // 50 MB
};

// items per paginated page
export const PAGINATION = {
    FREE_AGENTS_PER_PAGE: 12,
    IDENTITY_VERIFICATIONS_PER_PAGE: 8,
    MATCHES_PER_PAGE: 6,
    ORGANIZATIONS_PER_PAGE: 36,
    POST_COMMENTS_PER_PAGE: 10,
    SOCIAL_POSTS_PER_PAGE: 10,
    TEAMS_PER_PAGE: 9,
    USERS_PER_PAGE: 40,
};

// plans
export const PLANS = {
    ENTERPRISE: {
        ID: 'enterprise',
        NAME: 'LeagueSpot Enterprise',
    },
    LEGACY: {
        ID: 'legacy',
        NAME: 'LeagueSpot Legacy',
    },
    LITE: {
        ID: 'lite',
        LIMITATIONS: {
            MAX_PLAYERS_PER_SEASON: 50,
        },
        NAME: 'LeagueSpot Lite',
    },
};

// date formats
export const DATE_FORMAT = 'MM-DD-YYYY';
export const DATE_FORMAT_SLASH = 'MM/DD/YYYY';
export const LONG_DATE_FORMAT = 'ddd MMMM Do';
export const MATCH_TIME_FORMAT = 'LT z';
export const MATCH_DATE_FORMAT = 'll';
export const MATCH_DATE_TIME_FORMAT = 'll [at] LT z';
export const TIMESTAMP_FORMAT = 'MM-DD-YYYY hh:mm a';
export const PROFILE_DATE_FORMAT = 'MMMM Do, YYYY';

export const MAX_DATE = new Date(8640000000000000);

// links
export const CHILDRENS_PRIVACY_POLICY_URL = 'https://leaguespot.gg/compliance/childrens-privacy-policy';
export const COOKIE_POLICY_URL = 'https://leaguespot.gg/compliance/cookie-policy';
export const PRICING_URL = 'https://leaguespot.gg/pricing';
export const PRIVACY_POLICY_URL = 'https://leaguespot.gg/compliance/privacy';
export const SUPPORT_EMAIL = 'contact@leaguespot.gg';
export const TERMS_OF_SERVICE_URL = 'https://leaguespot.gg/compliance/terms';
export const WEBSITE_URL = 'https://leaguespot.gg';

// supported timezones
export const SUPPORTED_TIMEZONES = [
    'Africa/Johannesburg',
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'Pacific/Honolulu',
    'Asia/Kolkata',
];

// image types
export const CONTENT_TYPE_JPG = 'image/jpeg';
export const CONTENT_TYPE_PNG = 'image/png';

export const PRONOUNS = ['He/Him', 'She/Her', 'They/Them', 'Ze/Zir', 'Ze/Hir', 'Other', 'Ask'];

export const OAUTH_DISCORD_PROVIDER_ID = '45a236ff-2ed5-4750-a5ff-b2947fa2f972';
export const OAUTH_TWITCH_PROVIDER_ID = 'b7ca8502-e424-460c-bd7a-7b52cbc10f4d';

export const HEARTHSTONE_DECK_BASE_URL = 'https://hsdeckviewer.github.io?';

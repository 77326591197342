import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import { useProfile } from '../../hooks/store';
import { UserService } from '../../services/userService';
import { SolidButton } from '../buttons-visuals';

import './index.scss';

const VerifyEmailReminder = () => {
    const profile = useProfile();
    const [isLoading, setIsLoading] = useState(false);

    const onResendEmailLink = useCallback(async () => {
        setIsLoading(true);
        try {
            await UserService.resendVerificationEmail({
                userId: profile!.userId,
                verificationType: Core.Models.VerificationType.All,
            });
            toast.success('Verification email(s) have been sent');
        } catch (err) {
            toast.error('There was an issue resending your verification email(s). Please try again.');
        } finally {
            setTimeout(() => setIsLoading(false), 2000); // disable for 2 seconds
        }
    }, [profile?.userId, setIsLoading]);

    if (!profile?.isUnverifiedAccount) return <></>;
    return (
        <div className="email-verification-reminder">
            <span>{renderVerificationText(profile)}</span>
            <SolidButton
                as="button"
                className="ml2x"
                color="destructive"
                disabled={isLoading}
                onClick={onResendEmailLink}
            >
                Resend Verification Email{!profile.hasParentConsent && !profile.hasVerifiedEmail && 's'}
            </SolidButton>
        </div>
    );
};

const renderVerificationText = ({ hasParentConsent }: Core.Models.MyUserProfile) => {
    if (!hasParentConsent) return `Your account is limited until your parent/guardian provides consent`;
    return `Your account is limited until you verify your email address`;
};

export default VerifyEmailReminder;

import * as Core from './../../core';
import { AppAction } from '..';

export interface LeagueState {
    league?: Core.Models.LeagueConfiguration;
    isLoading: boolean;
    loadingError?: Core.Models.AppError;
}

export const LEAGUE_LOADING = 'LEAGUE_LOADING';
export const LEAGUE_SUCCESS = 'LEAGUE_SUCCESS';
export const LEAGUE_FAILURE = 'LEAGUE_FAILURE';

interface LeagueLoading extends AppAction<void, typeof LEAGUE_LOADING> {
    type: typeof LEAGUE_LOADING;
}

export interface LeagueSuccess extends AppAction<Core.Models.LeagueConfiguration, typeof LEAGUE_SUCCESS> {
    type: typeof LEAGUE_SUCCESS;
    payload: Core.Models.LeagueConfiguration;
}

interface LeagueFailure extends AppAction<Core.Models.AppError, typeof LEAGUE_FAILURE> {
    type: typeof LEAGUE_FAILURE;
    payload: Core.Models.AppError;
}

export type LeagueActionTypes = LeagueLoading | LeagueSuccess | LeagueFailure;

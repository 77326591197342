import React from 'react';

import * as Core from '../../../core';
import { DesignationEditorMode } from './designationEditor/constants';
import { DesignationEditor } from './designationEditor/designationEditor';
import { useDesignations } from './hooks';
import Loading from '../../../components/loading';

import './index.scss';

const Designations = () => {
    const {
        changeDesignationFocus,
        createDesignation,
        deleteDesignation,
        designations,
        focusedDesignation,
        isLoading,
        saveDesignation,
    } = useDesignations();
    return (
        <section>
            {isLoading && <Loading />}
            <ul className="league-settings-page__designations__list">
                {designations.map((designation: Core.Models.LeagueDesignation) => (
                    <DesignationEditor
                        designation={designation}
                        key={designation.id}
                        onDelete={deleteDesignation}
                        onModeChange={changeDesignationFocus}
                        mode={
                            !!focusedDesignation && designation.id === focusedDesignation.id
                                ? focusedDesignation.mode
                                : DesignationEditorMode.Display
                        }
                        onSave={saveDesignation}
                    />
                ))}
                <DesignationEditor onCreate={createDesignation} mode={DesignationEditorMode.New} />
            </ul>
        </section>
    );
};

export default Designations;

import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isUndefined } from 'lodash';

import * as Core from '../../core';
import TwitchMultiStream from './multiStream';
import { useConsent } from '../../hooks/store';
import TwitchSettingsModal from '../acceptCookies/twitchSettings';
import { SolidButton } from '../buttons-visuals';
import { ContentContainer } from '../containers';

import './streamsPlayer.scss';

interface StreamsPlayerProps {
    loadStreams: () => Promise<Core.Models.TwitchStream[]>;
}

const StreamsPlayer = (props: StreamsPlayerProps): JSX.Element => {
    const { loadStreams } = props;
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState<boolean>(false);
    const [streams, setStreams] = useState<Core.Models.TwitchStream[] | undefined>(undefined);

    const consent = useConsent();
    const canLoadStreamContent = useMemo(() => !!consent?.twitch, [consent?.twitch]);

    useEffect(() => {
        (async () => {
            try {
                const streams = await loadStreams();
                setStreams(streams);
            } catch {} // ignore if this doesn't work
        })();
    }, [loadStreams]);

    if (isUndefined(streams) || streams.length <= 0) return <></>; // if streams have loaded but there aren't any, don't show anything

    return (
        <div className="streams-player">
            {canLoadStreamContent ? (
                <TwitchMultiStream {...{ streams }} openTwitchSettings={() => setIsSettingsModalOpen(true)} />
            ) : (
                <ContentContainer className="streams-player__content">
                    <FontAwesomeIcon icon={['fas', 'display']} size="2x" />
                    <div className="streams-player__content__message">
                        There are LeagueView streams that we can't show you until you provide your consent.
                        <SolidButton
                            as="button"
                            className="streams-player__content__button"
                            onClick={() => setIsSettingsModalOpen(true)}
                            size="small"
                        >
                            Enable LeagueView
                        </SolidButton>
                    </div>
                </ContentContainer>
            )}
            {!!isSettingsModalOpen && <TwitchSettingsModal onClose={() => setIsSettingsModalOpen(false)} />}
        </div>
    );
};

export default StreamsPlayer;

import React, { useMemo } from 'react';
import { difference } from 'lodash';

import * as Core from '../../../core';
import AddLink, { linkTypes } from './addLink';
import MatchLink from './matchLink';
import { Button } from '../../../components/button';
import { useModal } from '../../../hooks/modal';

import './index.scss';

interface MatchLinksProps {
    canEditLinks: boolean;
    matchId: string;
    matchLinks?: Core.Models.MatchLink[];
    reloadData: (showLoading: boolean) => Promise<void>;
}

const MatchLinks = (props: MatchLinksProps): JSX.Element => {
    const { canEditLinks, matchId, matchLinks, reloadData } = props;

    const availableMatchLinkTypes = useMemo(
        () => difference(linkTypes, matchLinks?.map((link: Core.Models.MatchLink) => link.type) ?? []),
        [matchLinks]
    );

    const [addLinkModal, openAddLinkModal] = useModal(
        () => 'Add link',
        (closeAddLinkModal) => (
            <AddLink {...{ availableMatchLinkTypes, matchId, reloadData }} onClose={closeAddLinkModal} />
        )
    );

    if ((matchLinks?.length ?? 0) <= 0 && !canEditLinks) return <></>;
    return (
        <div className="match-page__links">
            {matchLinks?.map((link: Core.Models.MatchLink) => (
                <MatchLink key={link.id} {...{ canEditLinks, link, reloadData }} />
            ))}

            {/* hiding controls for now since nobody is adding Microsoft Teams links anymore */}
            <div style={{ display: 'none' }}>
                {canEditLinks && availableMatchLinkTypes.length > 0 && (
                    <Button onClick={openAddLinkModal}>Add link</Button>
                )}

                {addLinkModal}
            </div>
        </div>
    );
};

export default MatchLinks;

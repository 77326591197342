import * as React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ErrorRemote404 } from './404-error-remote.svg';

interface NotFoundPageProps {
    title?: React.ReactNode;
    message?: React.ReactNode;
}

const NotFoundPage: React.FunctionComponent<NotFoundPageProps> = (props) => {
    const url = window.location.origin + '/';
    return (
        <div className="global-container-centered text-center mt8x">
            <ErrorRemote404 fill="currentColor" />
            <h1 className="heading-1 mt4x">{props.title || 'Page Not Found'}</h1>
            <p>
                {props.message || (
                    <>
                        Sorry, the page you were looking for doesn't exist or an error has occurred. Go back, or head
                        over to{' '}
                        <Link className="page-not-found__message__link" to="/">
                            {url}
                        </Link>{' '}
                        to choose a new direction.
                    </>
                )}
            </p>
        </div>
    );
};
export default NotFoundPage;

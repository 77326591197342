import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
    Avatar,
    ChatContainer,
    ConversationHeader,
    Message,
    MessageGroup as MessageGroupComponent,
    MessageInput,
    MessageList,
    MessageSeparator,
} from '@chatscope/chat-ui-kit-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { chain, compact, findLastIndex, flatMap, orderBy } from 'lodash';
import moment from 'moment';
import Linkify from 'react-linkify';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import genericUserIcon from '../../../assets/images/userDefault.svg';
import { HollowButton, SolidButton } from '../../../components/buttons-visuals';
import ConfirmModal from '../../../components/confirmModal';
import {
    MESSAGE_UPDATED,
    NEW_MESSAGE_CREATED,
    NEW_THREAD_CREATED,
    PARTICIPANT_ADDED_TO_THREAD,
    PARTICIPANT_REMOVED_FROM_THREAD,
    useChatContext,
} from '../../../contexts/chatContext';
import { useCanEditSeason, useChatDisabled, useHasLeagueAccess, useLeague } from '../../../hooks/store';
import { AuthenticationService } from '../../../services/authenticationService';
import ChatService from '../../../services/chatService';
import PlatformEventsService from '../../../services/platformEventsService';

import './index.scss';

enum MessageItemType {
    MessageGroup = 0,
    ParticipantAnnouncement = 1,
}

enum Direction {
    Added = 0,
    Removed = 1,
}

interface MessageItem {
    date: Date;
    item: MessageGroup | ParticipantAnnouncement;
    type: MessageItemType;
}

interface MessageGroup {
    author: Core.Models.ChatThreadParticipant;
    messages: Core.Models.ChatThreadMessage[];
}

interface ParticipantAnnouncement {
    author: Core.Models.ChatThreadParticipant;
    direction: Direction;
    eventTimeUtc: Date;
}

interface ThreadProps {
    selectedThread: Core.Models.ChatThread;
    setLatestMessageReadId: (threadId: string, messageId: string) => void;
    showMatchLink?: boolean;
}

const Thread = ({ selectedThread, setLatestMessageReadId, showMatchLink = false }: ThreadProps): JSX.Element => {
    const league = useLeague();

    const userId = AuthenticationService.getUserId();

    const [clickedLink, setClickedLink] = useState<string | undefined>(undefined);
    const [isConfirmingLeaveThread, setIsConfirmingLeaveThread] = useState<boolean>(false);
    const [isConfirmingRequestModerator, setIsConfirmingRequestModerator] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [messageItems, setMessageItems] = useState<MessageItem[]>([]);
    const [thread, setThread] = useState<Core.Models.ChatThread>(selectedThread);

    const canEditLeague = useHasLeagueAccess(Core.Models.PermissionLevel.Edit);
    const canEditSeason = useCanEditSeason(thread?.seasonId);

    const chatDisabled = useChatDisabled();

    const isModerator = !!thread.matchId ? canEditSeason : canEditLeague;

    useEffect(() => {
        setThread(selectedThread);
    }, [selectedThread]);

    const iAmAParticipant = useMemo(
        () =>
            !!thread &&
            thread.participants.filter(
                (tp: Core.Models.ChatThreadParticipant) => !tp.removedTimeUtc && tp.userId === userId
            ).length > 0,
        [thread, userId]
    );

    const addUserToThread = async () => {
        if (!userId) return;

        try {
            if (!!thread.matchId) {
                await ChatService.createMatchThreadParticipant({ matchId: thread.matchId });
            } else {
                await ChatService.createThreadParticipant({ threadId: thread.id, userId });
            }
        } catch (err) {
            toast.error(`Unable to add you to this thread`);
        }
    };

    const leaveThread = async () => {
        if (!userId) return;

        try {
            await ChatService.removeThreadParticipant(thread.id, userId);
            setIsConfirmingLeaveThread(false);
        } catch (err) {
            toast.error(`Unable to leave this thread`);
        }
    };

    const requestModerator = async () => {
        try {
            await ChatService.requestModerator({ threadId: thread.id });
            setIsConfirmingRequestModerator(false);
        } catch (err) {
            toast.error(`Unable to request a moderator for this thread`);
        }
    };

    const cancelModeratorRequest = async () => {
        try {
            await ChatService.cancelModeratorRequest({ threadId: thread.id });
        } catch (err) {
            toast.error(`Unable to cancel moderator request for this thread`);
        }
    };

    const handleNewMessageCreated = useCallback(
        (message: Core.Models.ChatThreadMessage) => {
            if (message.threadId !== thread.id) return; // this message is for a different thread

            setMessageItems((currentMessageItems) => {
                const newMessageItems = [...currentMessageItems];
                addMessageToMessageItems(newMessageItems, message);
                return [...newMessageItems];
            });

            if (!iAmAParticipant) return;

            (async () => {
                await ChatService.setMessageAsRead(message.id);
                setLatestMessageReadId(thread.id, message.id);
            })();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [thread.id]
    );

    const handleNewThreadCreated = useCallback(
        (newThread: Core.Models.ChatThread) => {
            if (newThread.id !== thread.id) return; // this is a different thread

            setThread((currentThread: Core.Models.ChatThread) =>
                Object.assign({}, currentThread, {
                    moderatorRequested: newThread.moderatorRequested,
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [thread.id]
    );

    const handleMessageUpdated = useCallback(
        (message: Core.Models.ChatThreadMessage) => {
            if (message.threadId !== thread.id) return; // this message is for a different thread

            setMessageItems((currentMessageItems) => {
                const newMessageItems = [...currentMessageItems];

                for (const messageItem of newMessageItems) {
                    if (messageItem.type !== MessageItemType.MessageGroup) continue;

                    const itemIndex = (messageItem.item as MessageGroup).messages.findIndex(
                        (messageGroupMessage: Core.Models.ChatThreadMessage) => messageGroupMessage.id === message.id
                    );
                    if (itemIndex < 0) continue;

                    (messageItem.item as MessageGroup).messages[itemIndex] = message;
                }

                return newMessageItems;
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [thread.id]
    );

    const handleNewParticipantAdded = useCallback(
        (participant: Core.Models.ChatThreadParticipant) => {
            if (participant.threadId !== thread.id) return; // this participant is for a different thread

            setThread((currentThread: Core.Models.ChatThread) => {
                const participantIndex = currentThread.participants.findIndex(
                    (p: Core.Models.ChatThreadParticipant) => p.userId === participant.userId
                );

                if (participantIndex >= 0) {
                    // if exists, update `removedTimeUtc`
                    currentThread.participants[participantIndex].removedTimeUtc = participant.removedTimeUtc;
                } else {
                    // if not exists, create
                    currentThread.participants.push(participant);
                }

                return { ...currentThread };
            });

            setMessageItems((currentMessageItems: MessageItem[]) => {
                const participantAddedIndex = currentMessageItems.findIndex(
                    (messageItem: MessageItem) =>
                        messageItem.type === MessageItemType.ParticipantAnnouncement &&
                        (messageItem.item as ParticipantAnnouncement).direction === Direction.Added &&
                        messageItem.item.author.userId === participant.userId
                );

                // add Added if not exists
                if (participantAddedIndex < 0)
                    currentMessageItems.push({
                        date: participant.addedTimeUtc,
                        item: {
                            author: participant,
                            direction: Direction.Added,
                            eventTimeUtc: participant.addedTimeUtc,
                        },
                        type: MessageItemType.ParticipantAnnouncement,
                    });

                const participantRemovedIndex = currentMessageItems.findIndex(
                    (messageItem: MessageItem) =>
                        messageItem.type === MessageItemType.ParticipantAnnouncement &&
                        (messageItem.item as ParticipantAnnouncement).direction === Direction.Removed &&
                        messageItem.item.author.userId === participant.userId
                );

                // remove Removed if exists
                if (participantRemovedIndex >= 0) currentMessageItems.splice(participantRemovedIndex, 1);

                return [...currentMessageItems];
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [thread.id]
    );

    const handleParticipantRemoved = useCallback(
        (participant: Core.Models.ChatThreadParticipant) => {
            if (participant.threadId !== thread.id) return; // this participant is for a different thread

            setThread((currentThread: Core.Models.ChatThread) => {
                const participantIndex = currentThread.participants.findIndex(
                    (p: Core.Models.ChatThreadParticipant) => p.userId === participant.userId
                );
                if (participantIndex < 0) return currentThread; // participant not found

                // set participant as removed
                currentThread.participants[participantIndex].removedTimeUtc = participant.removedTimeUtc;

                return { ...currentThread };
            });

            setMessageItems((currentMessageItems: MessageItem[]) => {
                // only update message items if participant has `removedTimeUtc`
                if (!participant.removedTimeUtc) return currentMessageItems;

                const participantIndex = currentMessageItems.findIndex(
                    (messageItem: MessageItem) =>
                        messageItem.type === MessageItemType.ParticipantAnnouncement &&
                        (messageItem.item as ParticipantAnnouncement).direction === Direction.Removed &&
                        messageItem.item.author.userId === participant.userId
                );
                if (participantIndex >= 0) return currentMessageItems; // participant already marked as left

                return [
                    ...currentMessageItems,
                    {
                        date: participant.removedTimeUtc,
                        item: {
                            author: participant,
                            direction: Direction.Removed,
                            eventTimeUtc: participant.removedTimeUtc,
                        },
                        type: MessageItemType.ParticipantAnnouncement,
                    },
                ];
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [thread.id]
    );

    const routeDataReceived = useCallback(
        ({ eventName, data }: { eventName: string; data: any }) => {
            switch (eventName) {
                case NEW_MESSAGE_CREATED:
                    return handleNewMessageCreated(data);
                case NEW_THREAD_CREATED:
                    return handleNewThreadCreated(data);
                case MESSAGE_UPDATED:
                    return handleMessageUpdated(data);
                case PARTICIPANT_ADDED_TO_THREAD:
                    return handleNewParticipantAdded(data);
                case PARTICIPANT_REMOVED_FROM_THREAD:
                    return handleParticipantRemoved(data);
                default:
            }
        },
        [
            handleNewMessageCreated,
            handleNewThreadCreated,
            handleMessageUpdated,
            handleNewParticipantAdded,
            handleParticipantRemoved,
        ]
    );

    // this should only run once - the subscription shouldn't change between rerenders
    useEffect(() => {
        const subscription = PlatformEventsService.dataReceived.subscribe(routeDataReceived);
        return () => subscription.unsubscribe();
    }, [routeDataReceived]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            try {
                // get all messages for thread
                const threadMessages = await ChatService.getThreadMessages(thread.id);

                // find all initial messages for non-programmatic participants
                const initialMessageItems: MessageItem[] = flatMap(
                    thread.participants.filter((p: Core.Models.ChatThreadParticipant) => !p.isProgrammatic),
                    (participant: Core.Models.ChatThreadParticipant) => {
                        return compact([
                            {
                                date: participant.addedTimeUtc,
                                item: {
                                    author: participant,
                                    direction: Direction.Added,
                                    eventTimeUtc: participant.addedTimeUtc,
                                },
                                type: MessageItemType.ParticipantAnnouncement,
                            },
                            participant.removedTimeUtc
                                ? {
                                      date: participant.removedTimeUtc,
                                      item: {
                                          author: participant,
                                          direction: Direction.Removed,
                                          eventTimeUtc: participant.removedTimeUtc,
                                      },
                                      type: MessageItemType.ParticipantAnnouncement,
                                  }
                                : undefined,
                        ]);
                    }
                );

                for (const message of threadMessages) {
                    addMessageToMessageItems(initialMessageItems, message);
                }

                setMessageItems(orderBy(initialMessageItems, (messageItem: MessageItem) => messageItem.date));

                if (!iAmAParticipant) return;

                try {
                    const lastMessage = chain(threadMessages)
                        .sortBy((message: Core.Models.ChatThreadMessage) => message.sentTimeUtc)
                        .last()
                        .value();
                    if (!!lastMessage) {
                        await ChatService.setMessageAsRead(lastMessage.id);
                        setLatestMessageReadId(thread.id, lastMessage.id);
                    }
                } catch {} // don't fail this process if the latest message can't be marked as read
            } catch (err) {
                toast.error('Unable to retrieve chat messages. Please refresh the page.');
            } finally {
                setIsLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thread.id]);

    const sendMessage = useCallback(
        async (content: string): Promise<void> => {
            if (!content) return;

            try {
                await ChatService.sendMessage({
                    content,
                    threadId: thread.id,
                });
            } catch (e) {
                const error = Core.API.getErrorMessage(e);
                toast.error(error);
            }
        },
        [thread]
    );

    const parseDate = (date: Date): string => moment(date).format('MMM D, YYYY, h:mm:ss a');

    const renderParticipantAnnouncement = (participantAnnouncement: ParticipantAnnouncement, index: number) => {
        const action = participantAnnouncement.direction === Direction.Added ? 'joined' : 'left';
        const eventTime = parseDate(participantAnnouncement.eventTimeUtc);

        return (
            <MessageSeparator
                content={`${participantAnnouncement.author.name} ${action} at ${eventTime}`}
                key={index}
            />
        );
    };

    const renderMessageGroup = (messageGroup: MessageGroup, index: number) => {
        const avatarSrc =
            league?.disablePfp && !messageGroup.author.isProgrammatic
                ? genericUserIcon
                : messageGroup.author.avatarUrl ?? genericUserIcon;
        return (
            <MessageGroupComponent
                direction={messageGroup.author.userId === userId ? 'outgoing' : 'incoming'}
                key={index}
                sender={messageGroup.author.name}
            >
                <Avatar name={messageGroup.author.name} size="md" src={avatarSrc} />
                <MessageGroupComponent.Header>
                    {messageGroup.author.isProgrammatic ? (
                        messageGroup.author.name
                    ) : (
                        <Link to={`/users/${messageGroup.author.userId}`}>{messageGroup.author.name}</Link>
                    )}
                    <span>- {parseDate(messageGroup.messages[0].sentTimeUtc)}</span>
                </MessageGroupComponent.Header>
                <MessageGroupComponent.Messages>
                    {messageGroup.messages.map((message: Core.Models.ChatThreadMessage) => (
                        <Message
                            className={classNames([message.redacted && 'redacted'])}
                            key={message.id}
                            model={{
                                payload: (
                                    <>
                                        <p>
                                            {!message.redacted || isModerator ? (
                                                <Linkify
                                                    componentDecorator={(href: string, text: string) => (
                                                        <button onClick={() => setClickedLink(href)}>{text}</button>
                                                    )}
                                                >
                                                    {message.content}
                                                </Linkify>
                                            ) : (
                                                '-- redacted --'
                                            )}
                                        </p>
                                        {isModerator && renderMessageControl(message.id, message.redacted)}
                                    </>
                                ),
                            }}
                            type="text"
                        >
                            {isModerator && message.redacted && <Message.Footer sender="Not visible" />}
                        </Message>
                    ))}
                </MessageGroupComponent.Messages>
            </MessageGroupComponent>
        );
    };

    return (
        <>
            <ChatContainer>
                <ConversationHeader>
                    <ConversationHeader.Content userName={thread.name} />
                    <ConversationHeader.Actions>
                        {thread.moderatorRequested && (
                            <>
                                <p className="cs-conversation-header__actions__info-badge">Moderator requested</p>
                                {isModerator && (
                                    <HollowButton
                                        as="button"
                                        color="destructive"
                                        onClick={cancelModeratorRequest}
                                        size="small"
                                    >
                                        Cancel Request
                                    </HollowButton>
                                )}
                            </>
                        )}
                        {!thread.moderatorRequested && iAmAParticipant && (
                            <SolidButton
                                as="button"
                                className="request-a-moderator"
                                color="destructive"
                                onClick={() => setIsConfirmingRequestModerator(true)}
                                size="small"
                            >
                                <FontAwesomeIcon icon={['fas', 'hand']} className="mr" />
                                <span>
                                    <span className="request-a-moderator__mobile">Help</span>
                                    <span className="request-a-moderator__normal">Request Moderator</span>
                                </span>
                            </SolidButton>
                        )}
                        {!iAmAParticipant && (
                            <SolidButton as="button" onClick={addUserToThread} size="small">
                                Join Chat
                            </SolidButton>
                        )}
                        {iAmAParticipant && (
                            <HollowButton
                                as="button"
                                color="destructive"
                                onClick={() => setIsConfirmingLeaveThread(true)}
                                size="small"
                            >
                                Leave Chat
                            </HollowButton>
                        )}
                        {!!thread.matchId && showMatchLink && (
                            <SolidButton as="link" size="small" to={`/matches/${thread.matchId}`}>
                                Lobby
                            </SolidButton>
                        )}
                    </ConversationHeader.Actions>
                </ConversationHeader>
                <MessageList loading={isLoading} scrollBehavior="auto">
                    <MessageSeparator content={`Created at ${parseDate(thread.createdTimeUtc)}`} />
                    {messageItems.length > 0 ? (
                        messageItems.map((messageItem: MessageItem, index: number) => {
                            switch (messageItem.type) {
                                case MessageItemType.MessageGroup:
                                    return renderMessageGroup(messageItem.item as MessageGroup, index);
                                case MessageItemType.ParticipantAnnouncement:
                                    return renderParticipantAnnouncement(
                                        messageItem.item as ParticipantAnnouncement,
                                        index
                                    );
                                default:
                                    return <Fragment key={index} />;
                            }
                        })
                    ) : (
                        <p className="no-content">Nobody has sent any messages yet.</p>
                    )}
                </MessageList>
                {iAmAParticipant && (
                    <MessageInput
                        attachButton={false}
                        disabled={chatDisabled}
                        onSend={async (_innerHtml: string, textContent: string) => await sendMessage(textContent)}
                        placeholder="Type message here"
                        value={
                            chatDisabled
                                ? 'You are currently blocked from chat. Please contact your league host for more information.'
                                : undefined
                        }
                    />
                )}
            </ChatContainer>
            {isConfirmingLeaveThread && (
                <ConfirmModal
                    onCancel={() => setIsConfirmingLeaveThread(false)}
                    onConfirm={leaveThread}
                    title="Are you sure you want to leave this thread?"
                >
                    <p>
                        This will remove you from the thread and you will not be able to view or participate in chat.
                        Your chat messages will still be visible to other participants.
                    </p>
                </ConfirmModal>
            )}
            {isConfirmingRequestModerator && (
                <ConfirmModal
                    onCancel={() => setIsConfirmingRequestModerator(false)}
                    onConfirm={requestModerator}
                    title="Are you sure you want to request a moderator?"
                >
                    <p>This action will notify one of your league hosts and they will be able to join this thread.</p>
                </ConfirmModal>
            )}
            {!!clickedLink && (
                <ConfirmModal
                    className="chat-link__confirm-modal"
                    onCancel={() => setClickedLink(undefined)}
                    onConfirm={async () => {
                        window.open(clickedLink, '_blank');
                    }}
                    title="Just To Be Sure"
                >
                    <p>
                        This link will take you to: <strong>{clickedLink}</strong>. Are you sure you want to go there?
                    </p>
                </ConfirmModal>
            )}
        </>
    );
};

const addMessageToMessageItems = (currentMessageItems: MessageItem[], message: Core.Models.ChatThreadMessage): void => {
    const lastGroupIndex = findLastIndex(
        currentMessageItems,
        (messageItem: MessageItem) => messageItem.type === MessageItemType.MessageGroup
    );
    if (
        lastGroupIndex < 0 ||
        !messagesShouldBeGrouped(currentMessageItems[lastGroupIndex].item as MessageGroup, message)
    ) {
        currentMessageItems.push({
            date: message.sentTimeUtc,
            item: {
                author: message.author,
                messages: [message],
            },
            type: MessageItemType.MessageGroup,
        });
    } else {
        (currentMessageItems[lastGroupIndex].item as MessageGroup).messages.push(message);
    }
};

const messagesShouldBeGrouped = (
    lastMessageGroup: MessageGroup,
    newMessage: Core.Models.ChatThreadMessage
): boolean => {
    const MESSAGE_GROUP_TIMESPAN_MS = 1000 * 60 * 5; // 5 minutes

    // determine whether the last two messages were sent by the same author
    const haveSameAuthor = lastMessageGroup.author.userId === newMessage.author.userId;
    if (!haveSameAuthor) return false;

    // determine whether the last two messages are sent within a certain threshold in which we want to group. for example,
    // all messages within a 5-min threshold extend a message group, but two messages 20 minutes apart are in different groups.
    // this is a common pattern with other messaging apps like SMS or Google Chat
    const newMessageTime = new Date(newMessage.sentTimeUtc).getTime();
    const lastMessageTime = new Date(
        lastMessageGroup.messages[lastMessageGroup.messages.length - 1].sentTimeUtc
    ).getTime();
    const areWithinTimespan = newMessageTime - lastMessageTime < MESSAGE_GROUP_TIMESPAN_MS;
    return areWithinTimespan;
};

const renderMessageControl = (messageId: string, redacted: boolean): JSX.Element => {
    return (
        <div
            className="cs-message__content__controls"
            onClick={async () => updateMessageVisibility(messageId, redacted)}
        >
            <i className={classNames('fa', [redacted ? 'fa-eye-slash' : 'fa-eye'])} />
        </div>
    );
};

const updateMessageVisibility = async (messageId: string, redacted: boolean): Promise<void> => {
    try {
        await ChatService.updateMessage({
            messageId,
            redacted: !redacted,
        });
    } catch (err) {
        toast.error(`Unable to update message visibility`);
    }
};

export default Thread;

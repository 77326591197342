import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';

import { COOKIE_POLICY_URL } from '@leaguespot/platform-js/src/baseCore/constants';
import Loading from '../../components/loading';
import Modal from '../../components/modal';
import { useCookies } from '../../hooks/store';
import { CookiePreferences } from '../../store/cookies/types';
import { IconButton, SolidButton } from '../buttons-visuals';
import { ToggleSwitch } from '../inputs';
import { Popover } from '../overlays';

interface CookieSettingsModalProps {
    onClose: () => void;
    setCookies: (preferences: CookiePreferences) => void;
}

const CookieSettingsModal = (props: CookieSettingsModalProps): JSX.Element => {
    const { onClose, setCookies } = props;
    const cookies = useCookies();

    return (
        <Modal onClose={onClose} title={`Cookie Settings`}>
            <Formik
                initialValues={Object.assign({
                    performance: !!cookies?.performance,
                    strictlyNecessary: true,
                })}
                validationSchema={Yup.object().shape({
                    performance: Yup.boolean(),
                    strictlyNecessary: Yup.boolean(),
                })}
                onSubmit={async (values, actions) => {
                    actions.setStatus(undefined);
                    setCookies({ ...values });
                    onClose();
                    actions.setSubmitting(false);
                }}
                render={(formProps: FormikProps<CookiePreferences>) => (
                    <Form className="form">
                        <p className="cookie_settings__text">
                            We use cookies to improve your experience and make the site work as you expect it to.
                            Because we respect your right to privacy, you can choose not to allow some types of cookies.
                            You will find more information about each category below. Please note, blocking some types
                            of cookies may impact your experience.
                        </p>
                        <p className="cookie_settings__text">
                            For more information, please review our{' '}
                            <a href={COOKIE_POLICY_URL} target="_blank" rel="noopener noreferrer">
                                Cookie Policy
                            </a>
                            .
                        </p>
                        <fieldset className="form-group form-group--undecorated">
                            <div className="cookie_settings__setting">
                                <div className="cookie_settings__setting_name">
                                    <label className="cookie_settings__setting_name_label">Strictly Necessary</label>
                                    <Popover
                                        button={
                                            <IconButton as="button" buttonLabel="More info" buttonSize="small">
                                                <FontAwesomeIcon icon={['fas', 'circle-info']} />
                                            </IconButton>
                                        }
                                    >
                                        <div>
                                            These cookies are necessary for our website to function properly and cannot
                                            be switched off. These are usually only set in response to actions made by
                                            you, such as setting your privacy preferences, logging in, or some other
                                            basic functionality. These cookies do not store any personally identifiable
                                            information.
                                        </div>
                                    </Popover>
                                </div>
                                <ToggleSwitch
                                    disabled
                                    onToggleSwitch={(value: boolean) =>
                                        Promise.resolve(formProps.setFieldValue('strictlyNecessary', value))
                                    }
                                    value={formProps.values.strictlyNecessary}
                                />
                            </div>
                            <div className="cookie_settings__setting">
                                <div className="cookie_settings__setting_name">
                                    <label className="cookie_settings__setting_name_label">Performance</label>
                                    <Popover
                                        button={
                                            <IconButton as="button" buttonLabel="More info" buttonSize="small">
                                                <FontAwesomeIcon icon={['fas', 'circle-info']} />
                                            </IconButton>
                                        }
                                    >
                                        <div>
                                            These cookies allow us to count visits and track exceptions so we can
                                            measure and improve the performance and reliability of our website. All
                                            information these cookies collect is aggregated and therefore anonymous. If
                                            you do not allow these cookies, we will not be able to use your experience
                                            to improve our website in this way.
                                        </div>
                                    </Popover>
                                </div>
                                <ToggleSwitch
                                    onToggleSwitch={(value: boolean) =>
                                        Promise.resolve(formProps.setFieldValue('performance', value))
                                    }
                                    value={formProps.values.performance}
                                />
                            </div>
                        </fieldset>

                        <fieldset className="form-group form-group--undecorated">
                            {formProps.isSubmitting && <Loading buttonLoader />}
                            <SolidButton
                                as="button"
                                layout="full"
                                onClick={formProps.submitForm}
                                pending={formProps.isSubmitting}
                            >
                                Submit
                            </SolidButton>
                        </fieldset>
                    </Form>
                )}
            />
        </Modal>
    );
};

export default CookieSettingsModal;

import React from 'react';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../core';
import { SolidButton } from '../../components/buttons-visuals';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';

interface EditAnnouncementProps {
    announcement: Core.Models.StageAnnouncement;
    onSubmit: (values: Core.Models.EditStageAnnouncementRequest) => Promise<void>;
}

const EditAnnouncement = ({ announcement, onSubmit }: EditAnnouncementProps): JSX.Element => (
    <Formik<Core.Models.EditStageAnnouncementRequest>
        initialValues={Object.assign({
            /** anything not specified here won't show an error message after an attempted submit */
            message: announcement.message,
            stageAnnouncementId: announcement.id,
        })}
        validationSchema={Yup.object().shape({
            message: Yup.string()
                .required('Message is required')
                .max(1_000, 'Message must be less than 1,000 characters'),
            stageAnnouncementId: Yup.string().required('Announcement is required'),
        })}
        onSubmit={async (
            values: Core.Models.EditStageAnnouncementRequest,
            actions: FormikActions<Core.Models.EditStageAnnouncementRequest>
        ) => {
            actions.setStatus(undefined);
            try {
                await onSubmit(values);
            } catch (e) {
                const message = Core.API.getErrorMessage(e);
                actions.setStatus(message);
            }
            actions.setSubmitting(false);
        }}
        render={(formProps: FormikProps<Core.Models.EditStageAnnouncementRequest>) => (
            <Form className="form">
                <FormField
                    component="textarea"
                    label="Message"
                    limit={1_000}
                    name="message"
                    placeholder="Message"
                    rows={5}
                />

                {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                <fieldset className="form-group form-group--undecorated">
                    <SolidButton
                        as="button"
                        layout="full"
                        onClick={formProps.submitForm}
                        pending={formProps.isSubmitting}
                    >
                        Update announcement
                    </SolidButton>
                </fieldset>
            </Form>
        )}
    />
);

export default EditAnnouncement;

import React from 'react';
import classNames from 'classnames';

import './Radio.scss';

interface radioProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: React.ReactNode;
}

const Radio = ({ className, id, label, ...rest }: radioProps) => {
    return (
        <div className="ds-radio">
            <input className="ds-radio__input" type="radio" {...rest} />
            <div
                className={classNames('ds-radio__choice choice-style', className, {
                    'ds-radio__choice--checked': rest.checked,
                })}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};

export default Radio;

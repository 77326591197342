import React from 'react';

import * as Core from '../../core';
import Loading from '../../components/loading';
import { useUserPermissionService } from '../../hooks/store';
import UserMatchList from '../user/matchList';

const MyUpcomingMatches = () => {
    const userId = useUserPermissionService().getUserId();
    if (!userId) return <Loading blockItem />;

    return (
        <div className="upcoming-matches">
            <h2>
                Upcoming <span className="theme__text__alternate">Events</span>
            </h2>
            <UserMatchList type={Core.Models.UserMatchListType.Upcoming} userId={userId} />
        </div>
    );
};

export default MyUpcomingMatches;

import React, { useCallback, useState } from 'react';
import { isUndefined } from 'lodash';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import { Button } from '../../../components/button';
import InfoMessage from '../../../components/infoMessage';
import { CopyTextField } from '../../../components/inputs';
import IntegrationService from '../../../services/integrationService';

import './tournamentCode.scss';

interface LolTournamentCodeProps {
    gameFeatureSet: Core.Models.FeatureSet;
    matchGame?: Core.Models.MatchGame;
    matchState: Core.Models.MatchState;
}

const LolTournamentCode = (props: LolTournamentCodeProps): JSX.Element => {
    const { gameFeatureSet, matchGame, matchState } = props;

    const [isRegenerating, setIsRegenerating] = useState<boolean>(false);

    const generateTournamentCode = useCallback(
        async (matchGameId: string) => {
            if (isRegenerating) return;

            setIsRegenerating(true);

            try {
                await IntegrationService.generateLolTournamentCode({ matchGameId });
                toast.success('Successfully submitted request to generate tournament code.');
            } catch (e) {
                const message = Core.API.getErrorMessage(e);
                toast.error(`Unable to generate tournament code: ${message}`);
            } finally {
                setIsRegenerating(false);
            }
        },
        [isRegenerating]
    );

    if (![Core.Models.FeatureSet.LeagueOfLegends, Core.Models.FeatureSet.LeagueOfLegendsAram].includes(gameFeatureSet))
        return <></>;
    if (matchState !== Core.Models.MatchState.InProgress) return <></>;
    if (isUndefined(matchGame)) return <></>;

    return (
        <div className="league-tournament-code p2x">
            <form className="form">
                <p className="info">
                    {!!matchGame?.titleMetadata && (
                        <>
                            <strong>
                                Please click the tournament code below to copy it, and then paste into your client.
                            </strong>{' '}
                            <a
                                href="https://cdn.leaguespot.gg/static/media/help/lol-tournament-code.jpg"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Click here for help
                            </a>
                            .
                        </>
                    )}
                </p>
                <p className="info">
                    Scores should be automatically reported at the end of a game. If you don't see scores reported
                    within 5 minutes after a game has completed, click Score Details and then Reprocess.
                </p>
                {!!matchGame && !matchGame.titleMetadata && (
                    <div className="generate-tournament-code">
                        <InfoMessage message="Error generating tournament code" type="error" />
                        <Button disabled={isRegenerating} onClick={() => generateTournamentCode(matchGame.id)}>
                            Generate tournament code
                        </Button>
                    </div>
                )}
                {!!matchGame?.titleMetadata && (
                    <fieldset className="form-group">
                        <CopyTextField
                            id="tournamentCode"
                            label={`Game ${matchGame.sortOrder} tournament code`}
                            value={matchGame.titleMetadata}
                        />
                    </fieldset>
                )}
            </form>
        </div>
    );
};

export default LolTournamentCode;

import {
    Action,
    applyMiddleware,
    compose,
    combineReducers,
    Middleware,
    Store,
    createStore as reduxCreateStore,
} from 'redux';
import thunkMiddleware from 'redux-thunk';

import consentReducer from './consent/reducers';
import cookiesReducer from './cookies/reducers';
import leagueReducer from './league/reducers';
import loginReducer from './login/reducers';
import permissionReducer from './permissions/reducers';
import profileReducer from './profile/reducers';
import startupStateReducer from './startup/reducers';

const rootReducer = combineReducers({
    consentState: consentReducer,
    cookiesState: cookiesReducer,
    leagueState: leagueReducer,
    loginState: loginReducer,
    permissionState: permissionReducer,
    profileState: profileReducer,
    startupState: startupStateReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

// https://github.com/redux-utilities/flux-standard-action
export interface AppAction<T = any, TT = string> extends Action<TT> {
    readonly type: TT;
    readonly payload?: T;
    readonly error?: boolean;
    readonly meta?: any;
}

export const CreateStore = (preloadedState?: Partial<AppState>): Store<AppState> => {
    const middlewares: Middleware[] = [thunkMiddleware];

    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const enhancer: any = composeEnhancers(applyMiddleware(...middlewares));

    return reduxCreateStore(rootReducer, preloadedState || {}, enhancer);
};

export const store = CreateStore();

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { padStart } from 'lodash';

import * as Core from '../../core';

interface CreditCardProps {
    paymentMethod: Core.Models.PaymentMethod;
}

const CreditCard = ({ paymentMethod }: CreditCardProps): JSX.Element => (
    <div className="disp-flex align-center">
        <FontAwesomeIcon icon={['fab', mapCardBrandIcon(paymentMethod.brand)]} />
        <div className="ml2x weight-700">{paymentMethod.last4}</div>
        <div className="ml2x text-italic">
            exp. {padStart(paymentMethod.expMonth.toString(), 2, '0')}/{paymentMethod.expYear}
        </div>
    </div>
);

// https://stripe.com/docs/api/errors#errors-payment_method-card-brand
// amex, diners, discover, eftpos_au, jcb, mastercard, unionpay, visa, or unknown.
const mapCardBrandIcon = (brand: string) => {
    switch (brand) {
        case 'amex':
            return 'cc-amex';
        case 'diners':
            return 'cc-diners-club';
        case 'discover':
            return 'cc-discover';
        case 'jcb':
            return 'cc-jcb';
        case 'mastercard':
            return 'cc-mastercard';
        case 'visa':
            return 'cc-visa';
        default:
            return 'credit-card';
    }
};

export default CreditCard;

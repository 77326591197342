import React from 'react';
import { Link } from 'react-router-dom';

import './index.scss';

interface ForgotUsernameLinkProps {
    linkText?: string;
}

export const ForgotUsernameLink = (props: ForgotUsernameLinkProps): JSX.Element => {
    return (
        <div className="forgot-username-link">
            <Link to="/forgotUsername">{props.linkText || 'Forgot your username?'}</Link>
        </div>
    );
};

import * as React from 'react';
import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

import * as Core from '../../core';

import './index.scss';

interface StyledButtonProps {
    className?: string;
    styleType?: Core.Models.StyleType;
    outline?: boolean;
    wide?: boolean;
    round?: boolean;
    semiRound?: boolean;
    underline?: boolean;
}

function buildButton<T extends { className?: string }>(
    BaseComponent: React.ComponentType<T>,
    baseClassName: string
): React.ComponentType<T & StyledButtonProps> {
    const LeaguePrimary = styled(BaseComponent)`
        background: ${(props) => props.theme.primaryColor};
    `;
    const LeagueSecondary = styled(BaseComponent)`
        background: ${(props) => props.theme.secondaryColor};
    `;
    const LeaguePrimaryOutline = styled(BaseComponent)`
        color: ${(props) => props.theme.primaryColor};
        border-color: ${(props) => props.theme.primaryColor};
        &:link,
        &:visited,
        &:hover {
            color: ${(props) => props.theme.primaryColor};
        }
    `;
    const LeagueSecondaryOutline = styled(BaseComponent)`
        color: ${(props) => props.theme.secondaryColor};
        border-color: ${(props) => props.theme.secondaryColor};
        &:link,
        &:visited,
        &:hover {
            color: ${(props) => props.theme.secondaryColor};
        }
    `;
    const Component: React.FunctionComponent<T & StyledButtonProps> = (props) => {
        const { className, styleType: inputStyleType, outline, wide, round, semiRound, underline, ...rest } = props;
        const styleType = inputStyleType || Core.Models.StyleType.Primary;
        const ThemedComponent = (
            styleType === Core.Models.StyleType.LeaguePrimary
                ? outline
                    ? LeaguePrimaryOutline
                    : LeaguePrimary
                : styleType === Core.Models.StyleType.LeagueSecondary
                ? outline
                    ? LeagueSecondaryOutline
                    : LeagueSecondary
                : BaseComponent
        ) as React.ComponentType<T>;
        const realClassName = classNames(className, baseClassName, `${baseClassName}--${styleType}`, {
            [`${baseClassName}--outline`]: outline,
            [`${baseClassName}--wide`]: wide,
            [`${baseClassName}--round`]: round,
            [`${baseClassName}--semi-round`]: semiRound,
            [`${baseClassName}--underline`]: underline,
        });
        const outProps = { className: realClassName, ...rest } as T;
        return <ThemedComponent {...outProps} />;
    };

    return Component;
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    type?: 'submit' | 'reset' | 'button';
}

const BaseButton: React.FunctionComponent<ButtonProps> = (props) => {
    const { type, children, ...rest } = props;
    return (
        <button type={type || 'button'} {...rest}>
            {children}
        </button>
    );
};

function preventDefault(e: React.MouseEvent) {
    e.preventDefault();
}
const DisableableLink: React.FunctionComponent<LinkProps & { disabled?: boolean }> = (props) => {
    return <Link {...props} {...(props.disabled ? { onClick: preventDefault } : undefined)} />;
};
const Label: React.FunctionComponent<React.LabelHTMLAttributes<HTMLLabelElement>> = (props) => (
    <label tabIndex={0} {...props} />
);

/**
 * @deprecated Use `SolidButton or HollowButton` as="a" or as="button" instead
 */
export const Button = buildButton(BaseButton, 'button');
/**
 * @deprecated Use `SolidButton or HollowButton` as="link" instead
 */
export const LinkButton = buildButton(DisableableLink, 'link-button');
export const LabelButton = buildButton(Label, 'label-button'); // TODO: create button style label input with inputs refactor

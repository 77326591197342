import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { isNumber, orderBy } from 'lodash';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import Comments from './comments';
import CreateComment from './createComment';
import { useConfirmModal } from '../../hooks/confirmModal';
import { useLeague, useTimezone, useUserPermissionService } from '../../hooks/store';
import { PostService } from '../../services/postService';
import { Avatar, IconButton, TextButton } from '../buttons-visuals';
import Menu from '../menu';
import { ToolTip } from '../overlays';

import './index.scss';

interface PostProps {
    onDelete: (postId: string) => void;
    post: Core.Models.Post;
}

const Post = (props: PostProps) => {
    const {
        onDelete,
        post: { content, createdAtUtc, id, numberOfComments: initialNumberOfComments, reactions, user, userId },
    } = props;
    const league = useLeague();
    const userPermissionService = useUserPermissionService();
    const timezone = useTimezone();

    const [postTimeFromNow, setPostTimeFromNow] = useState<string | undefined>(undefined);
    const [isUpdatingReaction, setIsUpdatingReaction] = useState<boolean>(false);
    const [activeReactionType, setActiveReactionType] = useState<Core.Models.ReactionType | undefined>(undefined);
    const [bouncingReactionType, setBouncingReactionType] = useState<Core.Models.ReactionType | undefined>(undefined);
    const [comments, setComments] = useState<Core.Models.Comment[]>([]);
    const [numberOfComments, setNumberOfComments] = useState<number>(initialNumberOfComments);
    const [isCommentsExpanded, setIsCommentsExpanded] = useState<boolean>(false);

    useEffect(() => {
        setActiveReactionType(
            reactions.filter((r: Core.Models.Reaction) => r.userId == userPermissionService.getUserId())[0]?.type
        );
    }, [reactions, userPermissionService]);

    useEffect(() => {
        setPostTimeFromNow(moment.tz(createdAtUtc, timezone).fromNow());
        const interval = setInterval(
            () => setPostTimeFromNow(moment.tz(createdAtUtc, timezone).fromNow()),
            Core.Constants.POST_CREATED_TIME_MS
        );
        return () => clearInterval(interval);
    }, [createdAtUtc, timezone]);

    const postTime = useMemo(
        () => moment.tz(createdAtUtc, timezone).format(Core.Time.getFormat(true)),
        [createdAtUtc, timezone]
    );

    const canDeletePost = useMemo(
        () =>
            userId === userPermissionService.getUserId() ||
            userPermissionService.hasLeagueAccess(Core.Models.PermissionLevel.Edit, league),
        [league, userId, userPermissionService]
    );

    const [deletePostNode, openDeletePost] = useConfirmModal(
        () => `Delete Post`,
        () => <p>Are you sure you want to delete this post?</p>,
        async (closeModal) => {
            try {
                await PostService.deletePost(id);
                onDelete(id);
                closeModal();
                toast.success('Post deleted successfully');
            } catch {
                toast.success('Failed to delete post. Please try again.');
            }
        }
    );

    const updateReaction = async (type: Core.Models.ReactionType) => {
        setIsUpdatingReaction(true);
        const newType = activeReactionType == type ? undefined : type;
        try {
            await PostService.updateReaction({ postId: id, type: newType });
            setActiveReactionType(newType);

            // show bounce animation for 1 second
            if (isNumber(newType)) {
                setBouncingReactionType(newType);
                setTimeout(() => setBouncingReactionType(undefined), 1000);
            }
        } finally {
            setIsUpdatingReaction(false);
        }
    };

    const authorName = useMemo(() => user.gamerHandle || Core.Identity.renderMemberName(user), [user]);

    return (
        <div className="social-post">
            {canDeletePost && (
                <>
                    <Menu>
                        <button onClick={() => openDeletePost()}>Delete</button>
                    </Menu>
                    {deletePostNode}
                </>
            )}
            <div>
                <Link to={`/users/${user.id}`}>
                    <Avatar fallback="user" isUnder13={user.isUnder13} size="small" src={user.avatarUrl} />
                    <span>{authorName}</span>
                </Link>
                <ToolTip trigger={<span>{postTimeFromNow}</span>}>{postTime}</ToolTip>
                <IconButton
                    as="button"
                    buttonLabel={`React with ${Core.Models.ReactionType[Core.Models.ReactionType.Fire].toLowerCase()}`}
                    buttonSize="medium"
                    className={classNames('reaction-button', {
                        'is-active': activeReactionType == Core.Models.ReactionType.Fire,
                    })}
                    disabled={isUpdatingReaction}
                    onClick={() => updateReaction(Core.Models.ReactionType.Fire)}
                >
                    <FontAwesomeIcon
                        bounce={bouncingReactionType === Core.Models.ReactionType.Fire}
                        icon={['fas', 'fire-flame-curved']}
                    />
                </IconButton>
            </div>
            <p>{content}</p>
            {numberOfComments > 0 && !isCommentsExpanded && (
                <TextButton onClick={() => setIsCommentsExpanded(true)}>View comments</TextButton>
            )}
            <CreateComment
                onComplete={(comment: Core.Models.Comment) => {
                    setComments([comment, ...comments]);
                    if (!isCommentsExpanded) setIsCommentsExpanded(true);
                }}
                postId={id}
            />
            {isCommentsExpanded && (
                <Comments
                    comments={comments}
                    numberOfComments={numberOfComments}
                    postId={id}
                    setComments={setComments}
                    setNumberOfComments={setNumberOfComments}
                />
            )}
        </div>
    );
};

export default Post;

import React, { ChangeEvent, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Core from '../../core';
import SearchResults from './results';
import useClickOutside from '../../hooks/clickOutside';
import useDebouncedValue from '../../hooks/debouncedValue';
import { IconButton } from '../buttons-visuals';
import { TextInput } from '../inputs';
import { Dropdown } from '../navigationComponents';

import './index.scss';

const SiteSearch = () => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const debouncedSearchTerm = useDebouncedValue(searchTerm, Core.Constants.FORM_DEBOUNCE_TIME_MS);

    const searchRef = useRef<HTMLDivElement>(null);

    const toggleSearchField = () => {
        setIsOpen(!isOpen);
        setSearchTerm('');
    };

    useClickOutside(searchRef, () => {
        if (isOpen) {
            setIsOpen(false);
            setSearchTerm('');
        }
    });

    return (
        <div ref={searchRef}>
            <Dropdown
                button={
                    <IconButton as="button" buttonLabel="Search" buttonSize="large" onClick={toggleSearchField}>
                        <FontAwesomeIcon icon={['fas', 'magnifying-glass']} />
                    </IconButton>
                }
                className="site-search__dropdown"
                open={isOpen}
                position="center"
            >
                <div className="site-search__dropdown__content">
                    <TextInput
                        autoComplete="off"
                        className="site-search__input"
                        label="Search"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                        placeholder="Please enter a search term"
                        role="search"
                        type="search"
                        value={searchTerm}
                    />
                    <SearchResults clearAndCloseSearch={toggleSearchField} search={debouncedSearchTerm} />
                </div>
            </Dropdown>
        </div>
    );
};

export default SiteSearch;

import React from 'react';
import classNames from 'classnames';

import { NavListProps, NavListItemProps } from './index';
import './NavList.scss';

const NavList = ({ backgroundColor = 'default', children, className, ...rest }: NavListProps) => {
    const navListClasses = classNames(className, 'ds-nav-list list-unstyled', `ds-nav-list--${backgroundColor}`);

    return (
        <ul className={navListClasses} {...rest}>
            {new Array<typeof children>().concat(children).map((child: typeof children, index: number) => {
                return React.cloneElement(child as React.ReactElement<NavListItemProps>, {
                    key: index,
                });
            })}
        </ul>
    );
};

export default NavList;

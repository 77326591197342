import * as Core from '../../core';
import { AppAction } from '..';

export interface ProfileState {
    profile?: Core.Models.MyUserProfile;
    isLoading: boolean;
    loadingError?: Core.Models.AppError;
}

export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAILURE = 'PROFILE_FAILURE';

export interface ProfileLoading extends AppAction<void, typeof PROFILE_LOADING> {
    type: typeof PROFILE_LOADING;
}

export interface ProfileSuccess extends AppAction<Core.Models.MyUserProfile, typeof PROFILE_SUCCESS> {
    type: typeof PROFILE_SUCCESS;
    payload?: Core.Models.MyUserProfile;
}

export interface ProfileFailure extends AppAction<Core.Models.AppError, typeof PROFILE_FAILURE> {
    type: typeof PROFILE_FAILURE;
    payload: Core.Models.AppError;
}

export type ProfileActionTypes = ProfileLoading | ProfileSuccess | ProfileFailure;

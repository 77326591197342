import * as ReduxThunk from 'redux-thunk';

import * as Core from '../../core';
import {
    LOGIN_CLEAR_PRELOADED_DATA,
    LOGIN_FAILURE,
    LOGIN_INPROGRESS,
    LOGIN_REQUIRES_MFA,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    TokenResult,
} from './types';
import { AppAction, AppState } from '..';
import AnalyticsService from '../../services/analyticsService';
import { AuthenticationService } from '../../services/authenticationService';
import { UserService } from '../../services/userService';
import { getLeague } from '../league/actions';
import { getPermissions } from '../permissions/actions';
import { loadProfile } from '../profile/actions';

// action creators
export const loginInProgress = () => {
    return {
        type: LOGIN_INPROGRESS,
    };
};

export const loginRequiresMfa = () => {
    return {
        type: LOGIN_REQUIRES_MFA,
    };
};

export const loginSuccess = (tokenResult: TokenResult) => {
    return {
        type: LOGIN_SUCCESS,
        payload: tokenResult,
    };
};

export const loginClearPreloadedData = () => {
    return {
        type: LOGIN_CLEAR_PRELOADED_DATA,
    };
};

export const loginFailure = (error: Core.Models.AppError) => {
    return {
        type: LOGIN_FAILURE,
        payload: error,
        error: true,
    };
};

export const logoutSuccess = () => {
    return {
        type: LOGOUT_SUCCESS,
        payload: {},
    };
};

// thunk actions
const handleLoginError =
    (error: Core.Models.AppError): ReduxThunk.ThunkAction<Promise<void>, AppState, null, AppAction> =>
    async (dispatch) => {
        if (!!error?.message && (error.message as string).indexOf('401') >= 0) {
            dispatch(loginFailure(new Error('Invalid username or password')));
            return;
        }

        AnalyticsService.exception(error);

        // failed to login
        dispatch(loginFailure(error));
    };

export const login =
    (
        loginUserCommand: Core.Models.LoginUserCommand
    ): ReduxThunk.ThunkAction<Promise<void>, AppState, null, AppAction> =>
    async (dispatch) => {
        // login in progress
        dispatch(loginInProgress());

        try {
            // attempt to login
            const response = await UserService.login(loginUserCommand);

            if (response.requiresMfa) {
                dispatch(loginRequiresMfa());
                return;
            }

            const token = response.token!;
            dispatch(finalizeLogin(token));
        } catch (error) {
            dispatch(handleLoginError(error));
            throw error;
        }
    };

export const finalizeLogin =
    (token: string): ReduxThunk.ThunkAction<Promise<void>, AppState, null, AppAction> =>
    async (dispatch, getState) => {
        AuthenticationService.setToken(token);

        // successfully logged in
        dispatch(loginClearPreloadedData());
        dispatch(loginSuccess({ token } as TokenResult));

        // set login context
        if (token) {
            const decodedToken = AuthenticationService.decodeToken(token);
            if (decodedToken) AnalyticsService.login(decodedToken.sub); // set context with User ID
        }

        const { startupState } = getState();
        const loadLeague = () =>
            !startupState.isPlatform ? dispatch(getLeague(false)).catch((i) => i) : Promise.resolve();

        await Promise.all([dispatch(preloadData()), loadLeague()]);
    };

export const preloadData = (): ReduxThunk.ThunkAction<Promise<void>, AppState, null, AppAction> => async (dispatch) => {
    // preload data, but ignore errors
    await Promise.all([dispatch(getPermissions()).catch((i) => i), dispatch(loadProfile()).catch((i) => i)]);
};

export const logout =
    (redirectUrl?: string): ReduxThunk.ThunkAction<void, AppState, null, AppAction> =>
    (dispatch) => {
        AnalyticsService.logout();
        UserService.logout();
        dispatch(loginClearPreloadedData());
        dispatch(logoutSuccess());
        if (!!redirectUrl) {
            window.location.href = redirectUrl;
        } else {
            window.location.reload();
        }
    };

import * as ReduxThunk from 'redux-thunk';

import * as Core from '../../core';
import {
    PROFILE_LOADING,
    PROFILE_SUCCESS,
    PROFILE_FAILURE,
    ProfileSuccess,
    ProfileLoading,
    ProfileFailure,
} from './types';
import { AppAction, AppState } from '..';
import { UserService } from '../../services/userService';

// action creators
const profileLoadAction = (): ProfileLoading => {
    return {
        type: PROFILE_LOADING,
    };
};

const profileSuccessAction = (profile?: Core.Models.MyUserProfile): ProfileSuccess => {
    return {
        type: PROFILE_SUCCESS,
        payload: profile,
    };
};

const profileFailureAction = (error: Core.Models.AppError): ProfileFailure => {
    return {
        type: PROFILE_FAILURE,
        payload: error,
        error: true,
    };
};

// thunk actions
export const loadProfile =
    (): ReduxThunk.ThunkAction<Promise<void>, AppState, null, AppAction> => async (dispatch, getState) => {
        const { loginState, startupState } = getState();
        if (loginState.authenticated) {
            dispatch(profileLoadAction());

            try {
                const profile = startupState.isPlatform
                    ? await UserService.getMyPlatformProfile()
                    : await UserService.getMyProfile();
                dispatch(profileSuccessAction(profile));
            } catch (error) {
                dispatch(profileFailureAction(error));
            }
        } else {
            // if there's no user, there's no profile
            dispatch(profileSuccessAction(undefined));
        }
    };

import { Dictionary, orderBy, uniqBy } from 'lodash';

import * as Core from '../../core';
import { BracketRound } from './types';

export const getBracketRoundName = (bracket: Core.Models.Bracket, roundName: string, sortOrder: number) => {
    switch (bracket) {
        case Core.Models.Bracket.Finals:
            return sortOrder <= 1 ? 'Grand Finals' : 'Grand Finals Reset';
        case Core.Models.Bracket.Upper:
            return `Upper ${roundName}`;
        case Core.Models.Bracket.Lower:
            return `Lower ${roundName}`;
        default:
            return roundName;
    }
};

// Determines the final round index, defined as the first round from the end that has at least one match.
export const getFinalRoundIndex = (bracketMatches: Core.Models.Match[], outputRounds: BracketRound[]) => {
    for (let i = outputRounds.length - 1; i >= 0; i--) {
        const roundMatchCount = bracketMatches.filter(
            (m: Core.Models.Match) => m.roundId === outputRounds[i].id
        ).length;
        if (roundMatchCount > 0) return i;
    }

    return -1;
};

// Gets matches for current round.
export const getRoundMatches = (
    isSubBracket: boolean,
    matchesById: Dictionary<Core.Models.Match>,
    participants: Core.Models.MatchParticipant[]
) => {
    // Orders participants by sortOrder.
    const orderedParticipants = orderBy(participants, (mp: Core.Models.MatchParticipant) => mp.sortOrder);
    // Removes participants with the same previousMatchId.
    const uniqueByPreviousMatchId = orderedParticipants.every(
        (mp: Core.Models.MatchParticipant) => !!mp.previousMatchId
    )
        ? uniqBy(orderedParticipants, 'previousMatchId')
        : orderedParticipants;

    // Removes participants that are pointing to a match not within the list of matches.
    const validPreviousMatchParticipants = isSubBracket
        ? uniqueByPreviousMatchId.filter(
              (mp: Core.Models.MatchParticipant) => mp.previousMatchId && matchesById[mp.previousMatchId]
          )
        : uniqueByPreviousMatchId;

    // Returns null if array is empty. This keeps matches in the correct order.
    if (validPreviousMatchParticipants.length === 0) return null;

    // Returns matches associated to participants.
    return validPreviousMatchParticipants.map((i) => (i.previousMatchId && matchesById[i.previousMatchId]) || null);
};

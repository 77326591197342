import * as Core from '../../core';
import { DecodedToken } from '@leaguespot/platform-js/src/models';
import { AppAction } from '..';

export interface LoginState {
    authenticated: boolean;
    decodedToken?: DecodedToken;
    error?: string;
    isLoading: boolean;
    requiresMfa: boolean;
    token?: string;
}

export interface TokenResult {
    token: string;
}

export const LOGIN_CLEAR_PRELOADED_DATA: string = 'LOGIN_CLEAR_PRELOADED_DATA';
export const LOGIN_FAILURE: string = 'LOGIN_FAILURE';
export const LOGIN_INPROGRESS: string = 'LOGIN_INPROGRESS';
export const LOGIN_REQUIRES_MFA: string = 'LOGIN_REQUIRES_MFA';
export const LOGIN_SUCCESS: string = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS: string = 'LOGOUT_SUCCESS';

interface LoginInProgress extends AppAction {
    type: typeof LOGIN_INPROGRESS;
}

interface LoginSuccess extends AppAction<TokenResult> {
    type: typeof LOGIN_SUCCESS;
    payload: TokenResult;
}

interface LoginFailure extends AppAction<Core.Models.AppError> {
    type: typeof LOGIN_FAILURE;
    payload: Core.Models.AppError;
}

interface LogoutSuccess extends AppAction<TokenResult> {
    type: typeof LOGOUT_SUCCESS;
}

export type LoginActionTypes = LoginInProgress | LoginSuccess | LoginFailure | LogoutSuccess;

import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import { HollowButton, SolidButton } from '../../components/buttons-visuals';
import InfoMessage from '../../components/infoMessage';
import LiteLimitationMessage from '../../components/liteLimitationMessage';
import Loading from '../../components/loading';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useAlternateSeasonName, useIsLite } from '../../hooks/store';
import { SeasonService } from '../../services/seasonService';

interface StartSeasonProps extends WithLoadingProps {
    onCancel: () => void;
    onConfirm: () => Promise<void>;
    season: Core.Models.Season;
}

const StartSeason = ({ onCancel, onConfirm, season, setError, setIsLoading }: StartSeasonProps): JSX.Element => {
    const [hasActiveSeason, setHasActiveSeason] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const seasonAlternateName = useAlternateSeasonName({ lowercase: true });
    const isLite = useIsLite();

    useEffect(() => {
        (async () => {
            try {
                if (isLite) {
                    const response = await SeasonService.getAllSeasonCategories();
                    const hasActiveSeason = response
                        .flatMap((sc: Core.Models.SeasonCategory) => sc.seasons)
                        .some((s: Core.Models.Season) => s.currentState === Core.Models.CompetitionState.InProgress);
                    setHasActiveSeason(hasActiveSeason);
                }
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [isLite, setError, setIsLoading]);

    const onSubmit = useCallback(async () => {
        setIsSubmitting(true);
        try {
            await SeasonService.start(season.id);
            await onConfirm();
        } catch (e) {
            const message = Core.API.getErrorMessage(e);
            toast.error(message);
        } finally {
            setIsSubmitting(false);
        }
    }, [onConfirm, season.id]);

    if (hasActiveSeason)
        return (
            <LiteLimitationMessage
                message={`There is already an in progress ${seasonAlternateName} in this league. ${Core.Constants.PLANS.LITE.NAME} only allows for one in progress ${seasonAlternateName} at a time.`}
            />
        );

    return (
        <>
            <p>
                Are you sure you want to start <strong>{season.name}</strong>? Participating teams will be locked in to
                this {seasonAlternateName}, and no new teams will be able to join once it is started.
            </p>
            {season.game.featureSet === Core.Models.FeatureSet.Hearthstone && (
                <InfoMessage
                    message={`Once teams are locked in, they can no longer submit their Hearthstone decks. We recommend removing teams who haven't submitted decks before starting the first stage.`}
                    type="info"
                />
            )}
            {isSubmitting && <Loading buttonLoader />}
            <div className="confirm-modal__buttons">
                <HollowButton as="button" color="destructive" disabled={isSubmitting} onClick={onSubmit} size="medium">
                    Confirm
                </HollowButton>
                <SolidButton as="button" color="destructive" disabled={isSubmitting} onClick={onCancel} size="medium">
                    Cancel
                </SolidButton>
            </div>
        </>
    );
};

export default withLoading(StartSeason, {
    loadingProps: { blockItem: true },
});

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, Redirect } from 'react-router';

import * as Core from '../../core';
import OrganizationDetails from './details';
import OrganizationStandings from './standings';
import OrganizationTeams from './teams';
import UsersList from './usersList';
import { ContentContainer } from '../../components/containers';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useLeague } from '../../hooks/store';
import { OrganizationService } from '../../services/organizationService';

interface OrganizationPageRouteProps {
    organizationId?: string;
}

interface OrganizationPageProps extends WithLoadingProps, RouteComponentProps<OrganizationPageRouteProps> {}

const OrganizationPage = (props: OrganizationPageProps): JSX.Element => {
    const {
        match: {
            params: { organizationId },
        },
        setError,
        setIsLoading,
    } = props;

    const league = useLeague();
    const isAssociationLeague = useMemo(() => league?.edition === Core.Models.LeagueEdition.Association, [league]);

    const [organization, setOrganization] = useState<Core.Models.Organization | undefined>(undefined);

    const load = useCallback(async () => {
        if (!organizationId) return;

        try {
            const data = await OrganizationService.getById(organizationId);
            setOrganization(data);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [organizationId, setError, setIsLoading]);

    useEffect(() => {
        (async () => {
            await load();
        })();
    }, [load]);

    if (!organizationId) {
        // if there's no org specified, show the league page where all the orgs are listed
        // maybe in the future this should go to the dashboard or detect your org?
        return <Redirect to="/league" />;
    }

    if (!organization) return <></>;
    return (
        <div className="global-container pt4x pb4x">
            <OrganizationDetails {...{ organization }} reloadData={load} />
            {isAssociationLeague && (
                <ContentContainer shade={Core.Models.Shades.Dark40} className="mb4x">
                    <OrganizationStandings {...{ organizationId }} />
                </ContentContainer>
            )}
            <ContentContainer shade={Core.Models.Shades.Dark40} className="mb4x">
                <OrganizationTeams {...{ organizationId }} />
            </ContentContainer>
            <ContentContainer shade={Core.Models.Shades.Dark40} className="mb4x">
                <UsersList {...{ organization }} />
            </ContentContainer>
        </div>
    );
};

export default withLoading(OrganizationPage, {
    errorDisplayPageProps: { fullPage: true },
    isLoadingByDefault: false,
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import animateScrollTo from 'animated-scroll-to';
import classNames from 'classnames';
import { orderBy } from 'lodash';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import EditMatch from './editMatch';
import MatchLinks from './matchLinks';
import MatchLobby from './matchLobby';
import MatchLobbyInstructions from './matchLobbyInstructions';
import MatchParticipants from './matchParticipants';
import { AutoRejectParticipantsList, VoteOnRescheduleRequest } from './reschedule';
import { HollowButton, IconButton, SolidButton } from '../../components/buttons-visuals';
import ConfirmModal from '../../components/confirmModal';
import GameIcon from '../../components/gameIcon';
import LoginButton from '../../components/loginButton';
import Modal from '../../components/overlays/modal/Modal';
import StreamSettings from '../../components/stream/streamSettings';
import StreamsPlayer from '../../components/twitch/streamsPlayer';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useCanEditSeason, useLeague, useProfile, useTimezone, useUserPermissionService } from '../../hooks/store';
import { LeagueService } from '../../services/leagueService';
import { MatchService } from '../../services/matchService';
import { TimeService } from '../../services/timeService';
import { getIsMyMatch, getIsMyParticipant } from '../../store/selectors/myParticipant';

import './index.scss';

interface MatchPageRouteProps {
    id: string;
}

interface MatchPageProps extends WithLoadingProps, RouteComponentProps<MatchPageRouteProps> {}

const MatchPage = (props: MatchPageProps): JSX.Element => {
    const {
        match: {
            params: { id },
        },
        setError,
        setIsLoading,
    } = props;

    const isMyMatch = useSelector(getIsMyMatch);
    const isMyParticipant = useSelector(getIsMyParticipant);
    const league = useLeague();
    const timezone = useTimezone();
    const userPermissionService = useUserPermissionService();
    const profile = useProfile();

    const [isConfirmingStartMatch, setIsConfirmingStartMatch] = useState<boolean>(false);
    const [isConfirmingUndoStartMatch, setIsConfirmingUndoStartMatch] = useState<boolean>(false);
    const [isDisputingMatch, setIsDisputingMatch] = useState<boolean>(false);
    const [isEditingMatch, setIsEditingMatch] = useState<boolean>(false);
    const [isResolvingDispute, setIsResolvingDispute] = useState<boolean>(false);
    const [match, setMatch] = useState<Core.Models.MatchDetails | undefined>(undefined);
    const [response, setResponse] = useState<Core.Models.HasResponseDate | undefined>(undefined);

    const canEditSeason = useCanEditSeason(match?.season.id);

    const canEditMatch = useMemo(
        () => !!match && canEditSeason && match.currentState <= Core.Models.MatchState.RescheduleRequested,
        [canEditSeason, match]
    );
    const myMatch = useMemo(() => isMyMatch(match?.participants ?? []), [isMyMatch, match?.participants]);
    const userId = useMemo(() => userPermissionService.getUserId(), [userPermissionService]);
    const loggedIn = useMemo(() => !!userId, [userId]);
    const matchWord = useMemo(() => Core.Competition.getMatchWord(match?.game.eventType), [match?.game.eventType]);

    // Restricts chat users to coaches+
    const restrictChatUsers = useMemo(() => {
        // Continues if flag is not set or `true`
        if (!league?.restrictChatUsers) return false;

        const myTeamId = match?.participants.find((p: { teamId?: string }) => isMyParticipant(p))?.teamId;
        const teamMembers = profile?.teams.find((t: Core.Models.Team) => t.id === myTeamId)?.members;
        const myTeamRoleId = teamMembers?.find((m: Core.Models.TeamMember) => m.userId === userId)?.roleId;

        const canEditTeam =
            canEditSeason || userPermissionService.hasTeamAccess(Core.Models.PermissionLevel.Edit, myTeamId);
        return !canEditTeam || myTeamRoleId === Core.Models.TeamRoleId.Captain;
    }, [canEditSeason, league?.restrictChatUsers, match?.participants, profile?.teams, userId, userPermissionService]);

    const [location, setLocation] = useState<Core.Models.LeagueLocation | undefined>(undefined);
    useEffect(() => {
        (async () => {
            try {
                if (!!match?.stageSettings.locationId) {
                    const { locations } = await LeagueService.getLeagueLocationsWithStations(
                        match?.stageSettings.locationId
                    );
                    setLocation(locations[0]);
                }
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [match?.stageSettings.locationId, setError, setIsLoading]);

    const loadData = useCallback(async () => {
        try {
            setIsLoading(true);

            const matchData = await MatchService.getMatchById(id);
            const { responseDateUtc, receivedDateUtc } = matchData;

            setMatch(matchData.match);
            setResponse({ responseDateUtc, receivedDateUtc });
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    }, [id, setError, setIsLoading]);

    useEffect(() => {
        (async () => {
            await loadData();
        })();
    }, [loadData]);

    const confirmStartMatch = useCallback(async () => {
        if (!match) return;

        try {
            setIsConfirmingStartMatch(false);
            await MatchService.startMatch({ matchId: match.id });
            await loadData();
        } catch (err) {
            toast.error(`There was an issue starting this ${matchWord.toLowerCase()}. Please try again.`);
        }
    }, [loadData, match, matchWord]);

    const confirmUndoStartMatch = useCallback(async () => {
        if (!match) return;

        try {
            setIsConfirmingUndoStartMatch(false);
            await MatchService.unstartMatch({ matchId: match.id });
            await loadData();
        } catch (err) {
            toast.error(`There was an issue unstarting this ${matchWord.toLowerCase()}. Please try again.`);
        }
    }, [loadData, match, matchWord]);

    const disputeMatch = useCallback(async () => {
        if (!match) return;
        try {
            setIsDisputingMatch(false);
            await MatchService.disputeMatch({
                id: match.id,
                isDisputed: true,
            });
            await loadData();
        } catch (err) {
            toast.error(`There was an issue disputing this ${matchWord.toLowerCase()}. Please try again.`);
        }
    }, [loadData, match, matchWord]);

    const resolveDispute = useCallback(async () => {
        if (!match) return;

        try {
            setIsResolvingDispute(false);
            await MatchService.disputeMatch({
                id: match.id,
                isDisputed: false,
            });
            await loadData();
        } catch (err) {
            toast.error('There was an issue resolving this dispute. Please try again');
        }
    }, [loadData, match]);

    const within15MinutesOfStartTime = useCallback((): boolean => {
        if (!match) return false;
        if (!response) return false;

        const matchStart = TimeService.getAdjustedTime(response, match.startTimeUtc, timezone);
        const minutesUntilStart = Math.ceil(matchStart.diff(new Date(), 'minute'));
        return minutesUntilStart <= 15;
    }, [match, response, timezone]);

    const canEditParticipant = useCallback(
        (participant: { teamId?: string }): boolean => {
            return (
                canEditSeason ||
                userPermissionService.hasTeamAccess(Core.Models.PermissionLevel.Edit, participant.teamId)
            );
        },
        [canEditSeason, userPermissionService]
    );

    const getParticipantsUserCanEdit = useCallback(() => {
        if (!match) return [];
        return match.participants.filter((participant: { teamId?: string }) => canEditParticipant(participant));
    }, [canEditParticipant, match]);

    const renderCheckInBadge = useCallback((): JSX.Element => {
        if (!match) return <></>;

        // do not render if RescheduleRequested. there is a separate visual treatment that gets applied elsewhere
        if (match.currentState === Core.Models.MatchState.RescheduleRequested) return <></>;

        if (match.isDisputed) return <div className="badge badge--match badge--match-under-review">Under Review</div>;

        const isMatchOver = match.currentState >= Core.Models.MatchState.IsComplete;

        if (!isMatchOver && !loggedIn) {
            return (
                <div className="action-cta">
                    <p>Is this your {matchWord.toLowerCase()}?</p>
                    <LoginButton>Log in to start!</LoginButton>
                </div>
            );
        }

        const allParticipantsCheckedIn = match.participants.every(
            (participant: { hasCheckedIn: boolean }) => participant.hasCheckedIn
        );
        if (match.competitionStyle === Core.Models.CompetitionStyle.HeadToHead) {
            if (!isMatchOver) {
                const canEditAnyParticipants = getParticipantsUserCanEdit().length > 0;
                if (!allParticipantsCheckedIn && canEditAnyParticipants && within15MinutesOfStartTime()) {
                    return (
                        <SolidButton
                            as="button"
                            className="action-cta attention-light"
                            onClick={() => {
                                const matchControlsElement = document.getElementById('match-controls');
                                if (!!matchControlsElement) animateScrollTo(matchControlsElement, { speed: 250 });
                            }}
                        >
                            Check in now!
                        </SolidButton>
                    );
                }
                if (allParticipantsCheckedIn) {
                    if (canEditAnyParticipants) {
                        return (
                            <SolidButton
                                as="button"
                                className="action-cta attention-light"
                                onClick={() => {
                                    const matchControlsElement = document.getElementById('match-controls');
                                    if (!!matchControlsElement) animateScrollTo(matchControlsElement, { speed: 250 });
                                }}
                            >
                                Submit scores
                            </SolidButton>
                        );
                    }
                    return <div className={classNames('badge badge--check-in')}>Checked in</div>;
                }
            }

            const isForfeit = match.participants.some(
                (participant: { hasForfeited: boolean }) => participant.hasForfeited
            );
            if (isMatchOver && isForfeit) return <div className="badge badge--forfeit">Forfeit</div>;
        }

        if (isMatchOver) return <div className="badge badge--completed">Completed</div>;

        if (match.roundState === Core.Models.CompetitionState.NotStarted) {
            return <div className="badge">Round Not Started</div>;
        }

        return <></>;
    }, [getParticipantsUserCanEdit, loggedIn, match, matchWord, within15MinutesOfStartTime]);

    const shouldShowStartMatchPrompt = useCallback((): boolean => {
        if (!match) return false;
        if (match.competitionStyle !== Core.Models.CompetitionStyle.Leaderboard) return false;
        return canEditSeason && match.currentState <= Core.Models.MatchState.NotStarted;
    }, [canEditSeason, match]);

    const renderStartMatchPrompt = useCallback((): JSX.Element => {
        // check for start match prompt
        if (!shouldShowStartMatchPrompt()) return <></>;

        return (
            <SolidButton as="button" onClick={() => setIsConfirmingStartMatch(true)}>
                Start {matchWord.toLowerCase()}
            </SolidButton>
        );
    }, [matchWord, shouldShowStartMatchPrompt]);

    const renderUndoStartMatchPrompt = useCallback((): JSX.Element => {
        if (!match) return <></>;
        if (match.competitionStyle !== Core.Models.CompetitionStyle.Leaderboard) return <></>;
        if (!canEditSeason) return <></>; // only Hosts can do this
        if (match.currentState !== Core.Models.MatchState.InProgress) return <></>;

        return (
            <HollowButton as="button" onClick={() => setIsConfirmingUndoStartMatch(true)}>
                Undo start {matchWord.toLowerCase()}
            </HollowButton>
        );
    }, [canEditSeason, match, matchWord]);

    const shouldShowDisputeMatchButton = useCallback((): boolean => {
        if (!match) return false;

        return (
            match.currentState >= Core.Models.MatchState.IsComplete &&
            getParticipantsUserCanEdit().length > 0 &&
            !canEditSeason &&
            !match.isDisputed
        );
    }, [canEditSeason, getParticipantsUserCanEdit, match]);

    const shouldShowResolveDisputeButton = useCallback((): boolean => {
        if (!match) return false;
        return canEditSeason && match.isDisputed;
    }, [canEditSeason, match]);

    const loadTwitchStreams = useCallback(async () => {
        if (!match) return [];
        return await MatchService.getTwitchMatchStreams(match.id);
    }, [match]);

    const loadMatchStreams = useCallback(async () => {
        if (!match) return [];
        return await MatchService.getMatchStreams(match.id);
    }, [match]);

    if (!match || !response) return <></>;
    return (
        <div className="page match-page">
            <div className="page__details page__details--match">
                <div className="page__details--match__header">
                    <div className="page__details--match__header__match-info">
                        <h2 className="page__text page__text--primary">{matchWord} Details</h2>
                        <div>
                            <h4>
                                <Link to={`/seasons/${match.season.id}`}>{match.season.name}</Link>
                                <FontAwesomeIcon className="ml" icon={['fas', 'caret-right']} size="1x" />
                                <Link className="ml" to={`/seasons/${match.season.id}/${match.stageId}`}>
                                    {match.stageName}
                                </Link>
                                <FontAwesomeIcon className="ml" icon={['fas', 'caret-right']} size="1x" />
                                <span className="ml">{match.roundName}</span>
                            </h4>
                            {match.stageSettings.numberOfGroups > 1 && (
                                <div className="match-group__sub-header">{match.groupName}</div>
                            )}
                            <div className="match-page__section match-page__section--has-inline-button">
                                {match.startTimeUtc && (
                                    <div className="match-page__text-container--top">
                                        <p className="match-page__text match-page__text--time">
                                            {moment
                                                .tz(match.startTimeUtc, timezone)
                                                .format(Core.Constants.MATCH_TIME_FORMAT)}
                                        </p>
                                        <p className="match-page__text">
                                            {moment
                                                .tz(match.startTimeUtc, timezone)
                                                .format(Core.Constants.MATCH_DATE_FORMAT)}
                                        </p>
                                    </div>
                                )}
                                <div className="game__title-wrap">
                                    <GameIcon game={match.game} />
                                    <p className="game__title">{match.game.name}</p>
                                </div>
                                {canEditMatch && (
                                    <IconButton
                                        as="button"
                                        buttonLabel={`Edit ${matchWord}`}
                                        buttonSize="medium"
                                        onClick={() => setIsEditingMatch(true)}
                                    >
                                        <FontAwesomeIcon icon={['fas', 'pen']} />
                                    </IconButton>
                                )}
                            </div>
                            <div className="match-page__text-container--bottom">
                                <div>
                                    {match.competitionStyle !== Core.Models.CompetitionStyle.Leaderboard &&
                                        !match.season.tennisStyle && (
                                            <p className="match-page__text match-page__text--best-of">
                                                Best of {match.bestOf}
                                            </p>
                                        )}
                                    <p className="match-page__text match-page__text--player-requirement">
                                        {match.game.minimumSeats} player
                                        {match.game.minimumSeats !== 1 ? 's' : ''} per team
                                    </p>
                                </div>
                                {renderCheckInBadge()}
                            </div>
                        </div>
                        {!!getParticipantsUserCanEdit().length && (
                            <StreamSettings
                                className="mt2x"
                                loadStreams={loadMatchStreams}
                                onAddStream={(request: Core.Models.CreateLeagueStreamRequest) =>
                                    MatchService.createMatchStream({ ...request, matchId: match.id })
                                }
                                onDeleteStream={(streamId: string) => MatchService.deleteLeagueStream(streamId)}
                                onEditStream={(request: Core.Models.EditStreamRequest) =>
                                    MatchService.editMatchStream(request)
                                }
                                onEnableStream={(request: Core.Models.EnableStreamRequest) =>
                                    MatchService.updateEnableStream(request)
                                }
                                streamEntityName="Match"
                            />
                        )}
                    </div>
                    <div className="page__details--match__header__match-streams">
                        <StreamsPlayer loadStreams={loadTwitchStreams} />
                    </div>
                </div>
                <AutoRejectParticipantsList game={match.game} matchParticipants={match?.participants ?? []} />
                {loggedIn && match.currentState === Core.Models.MatchState.RescheduleRequested ? (
                    <VoteOnRescheduleRequest
                        match={match}
                        participantsUserCanEdit={getParticipantsUserCanEdit()}
                        reloadMatchData={loadData}
                    />
                ) : (
                    loggedIn &&
                    match.competitionStyle === Core.Models.CompetitionStyle.HeadToHead &&
                    !within15MinutesOfStartTime() &&
                    !canEditSeason &&
                    !match.useSimplifiedCheckin && (
                        <div className="match-page__checkin-notice">
                            <p className="match-page__text match-page__text--checkin-notice">
                                * Cannot check-in until 15 minutes before {matchWord.toLowerCase()}.
                            </p>
                        </div>
                    )
                )}
                {(canEditSeason || (myMatch && !!match.matchLinks?.length)) && (
                    <div className="match-page__section">
                        <MatchLinks
                            canEditLinks={canEditSeason}
                            matchId={match.id}
                            matchLinks={match.matchLinks}
                            reloadData={loadData}
                        />
                    </div>
                )}
                {(canEditSeason || (myMatch && !!match.lobbyInstructions)) && (
                    <div className="match-page__section">
                        <MatchLobbyInstructions
                            canEditLobbyInstructions={canEditSeason}
                            lobbyInstructions={match.lobbyInstructions}
                            matchId={match.id}
                            matchWord={matchWord}
                            reloadData={loadData}
                        />
                    </div>
                )}
                {!!league?.enableChat && (canEditSeason || myMatch) && (
                    <div className="match-page_section">
                        <MatchLobby
                            canEditSeason={canEditSeason}
                            matchId={match.id}
                            matchWord={matchWord}
                            restrictChatUsers={restrictChatUsers}
                            seasonId={match.season.id}
                            threadId={match.threadId}
                        />
                    </div>
                )}

                <div className="match-page__section match-page__section--buttons justify-center">
                    {renderStartMatchPrompt()}
                    {renderUndoStartMatchPrompt()}
                    {shouldShowDisputeMatchButton() && (
                        <HollowButton as="button" color="destructive" onClick={() => setIsDisputingMatch(true)}>
                            Dispute results
                        </HollowButton>
                    )}
                    {shouldShowResolveDisputeButton() && (
                        <SolidButton as="button" color="secondary" onClick={() => setIsResolvingDispute(true)}>
                            Resolve dispute
                        </SolidButton>
                    )}
                </div>
                <MatchParticipants
                    addLocationStation={(s: Core.Models.LeagueLocationStation) => {
                        setLocation(
                            (previousLocation) =>
                                ({
                                    ...previousLocation,
                                    stations: orderBy(
                                        [...(previousLocation?.stations ?? []), s],
                                        (s: Core.Models.LeagueLocationStation) => s.name
                                    ),
                                }) as Core.Models.LeagueLocation
                        );
                    }}
                    canEditSeason={canEditSeason}
                    isMyMatch={myMatch}
                    location={location}
                    match={match}
                    participantsUserCanEdit={getParticipantsUserCanEdit()}
                    reloadMatchData={loadData}
                    within15MinutesOfStartTime={within15MinutesOfStartTime()}
                />
            </div>

            {isConfirmingStartMatch && (
                <ConfirmModal
                    onCancel={() => setIsConfirmingStartMatch(false)}
                    onConfirm={confirmStartMatch}
                    title={`Please confirm start ${matchWord.toLowerCase()}`}
                >
                    <p>This will start the {matchWord.toLowerCase()}.</p>
                </ConfirmModal>
            )}

            {isConfirmingUndoStartMatch && (
                <ConfirmModal
                    onCancel={() => setIsConfirmingUndoStartMatch(false)}
                    onConfirm={confirmUndoStartMatch}
                    title={`Please confirm undo start ${matchWord.toLowerCase()}`}
                >
                    <p>This will reset the {matchWord.toLowerCase()} to a not started state.</p>
                </ConfirmModal>
            )}

            <Modal
                isOpen={isEditingMatch}
                onClose={() => setIsEditingMatch(false)}
                title={`Edit ${matchWord.toLowerCase()}`}
            >
                <EditMatch
                    gameFeatureSet={match.game.featureSet}
                    match={match}
                    onCancel={() => setIsEditingMatch(false)}
                    onComplete={async () => {
                        setIsEditingMatch(false);
                        await loadData();
                    }}
                />
            </Modal>
            {isDisputingMatch && (
                <ConfirmModal
                    onCancel={() => setIsDisputingMatch(false)}
                    onConfirm={disputeMatch}
                    title={`Are you sure you want to dispute this ${matchWord.toLowerCase()}?`}
                >
                    <p>
                        This will only mark this {matchWord.toLowerCase()} as disputed - you still must contact your
                        league host to make your case.
                    </p>
                </ConfirmModal>
            )}
            {isResolvingDispute && (
                <ConfirmModal
                    onCancel={() => setIsResolvingDispute(false)}
                    onConfirm={resolveDispute}
                    title="Are you sure you want to resolve this dispute?"
                >
                    {match.currentState >= Core.Models.MatchState.IsComplete && (
                        <p>
                            You should make any changes to game results and the {matchWord.toLowerCase()} result before
                            resolving the dispute.
                        </p>
                    )}
                </ConfirmModal>
            )}
        </div>
    );
};

export default withLoading(MatchPage, {
    errorDisplayPageProps: { fullPage: true },
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

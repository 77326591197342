import { AxiosRequestConfig } from 'axios';

export enum ApiVersion {
	v1 = 'v1',
	v2 = 'v2',
}

export interface UrlParameters extends Record<string, any> {}

export interface RequestConfig extends AxiosRequestConfig {
	authTokenOverride?: string;
	globalErrorHandling?: boolean;
	isCrossLeague?: boolean;
	leagueIdOverride?: string;
	sendAuthentication?: boolean;
	sendLeagueId?: boolean;
}

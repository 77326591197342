import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import AuthenticatedLayoutRoute from './components/routing/authenticatedLayoutRoute';
import LayoutRoute from './components/routing/layoutRoute';
import EmptyLayout from './layouts/emptyLayout';
import MainLayout from './layouts/mainLayout';
import RegistrationLayout from './layouts/registrationLayout';
import CreateGamePage from './pages/createGame';
import CreateLeaguePage from './pages/createLeague';
import EditGamePage from './pages/editGame';
import EmailVerificationSentPage from './pages/emailVerificationSent';
import ForgotUsernamePage from './pages/forgotUsername';
import GamePage from './pages/game';
import { HandleAccountLink } from './pages/handleAccountLink';
import { LeagueSearch } from './pages/leagueSearch';
import LoginPage from './pages/login';
import LogoutPage from './pages/logout';
import NotFoundPage from './pages/notFound';
import LinkGenericOAuthAccount from './pages/oAuth/link/generic';
import ForgotPasswordPage from './pages/password/forgotPassword';
import ResetPasswordContainer from './pages/password/resetPassword';
import Redirector from './pages/redirector';
import { RegisterPage } from './pages/register';
import UserPage from './pages/user';
import { UserSearch } from './pages/userSearch';
import VerifyEmailPage from './pages/verifyEmail';

const PlatformApp = () => (
    <Switch>
        <Route exact path="/" render={() => <Redirect to="/search" />} />
        <AuthenticatedLayoutRoute path="/create-game" component={CreateGamePage} layout={MainLayout} />
        <AuthenticatedLayoutRoute path="/games/:id/edit" component={EditGamePage} layout={MainLayout} />
        <LayoutRoute path="/games/:id" component={GamePage} layout={MainLayout} />
        <AuthenticatedLayoutRoute path="/users/search" component={UserSearch} layout={MainLayout} />
        <LayoutRoute path="/create-league" component={CreateLeaguePage} layout={RegistrationLayout} />
        <LayoutRoute path="/forgotPassword" component={ForgotPasswordPage} layout={RegistrationLayout} />
        <LayoutRoute path="/forgotUsername" component={ForgotUsernamePage} layout={RegistrationLayout} />
        <LayoutRoute path="/login/:from?" component={LoginPage} layout={EmptyLayout} />
        <LayoutRoute path="/logout" component={LogoutPage} layout={EmptyLayout} />
        <LayoutRoute path="/oauth/link/:type" component={LinkGenericOAuthAccount} layout={EmptyLayout} />
        <LayoutRoute path="/resetPassword/:userId/:code" component={ResetPasswordContainer} layout={EmptyLayout} />
        <LayoutRoute path="/search" component={LeagueSearch} layout={MainLayout} />
        <LayoutRoute path="/users/:userId" component={UserPage} layout={MainLayout} />
        <LayoutRoute path="/register" component={RegisterPage} layout={RegistrationLayout} />
        <LayoutRoute path="/verify-email/:token" layout={RegistrationLayout} component={VerifyEmailPage} />
        <LayoutRoute path="/email-verification-sent" component={EmailVerificationSentPage} layout={MainLayout} />
        <AuthenticatedLayoutRoute
            path="/payments/account-link/:paymentGatewayId/:leagueId/:accountLinkType"
            component={HandleAccountLink}
            layout={MainLayout}
        />
        <Route path="/redirect" component={Redirector} />
        <LayoutRoute path="/" layout={EmptyLayout} component={NotFoundPage} />
    </Switch>
);

export default PlatformApp;

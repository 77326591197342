import React from 'react';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import { useVoteOnRescheduleRequest } from './hooks';
import { VotingMatchParticipant } from './types';
import { Button } from '../../../components/button';
import { Checkbox } from '../../../components/inputs';
import withLoading, { WithLoadingProps } from '../../../components/withLoading';
import { useConfirmModal } from '../../../hooks/confirmModal';
import { MatchService } from '../../../services/matchService';

import './styles.scss';

interface VoteOnRescheduleRequestProps extends WithLoadingProps {
    match: {
        currentState: Core.Models.MatchState;
        id: string;
        participants: VotingMatchParticipant[];
    };
    participantsUserCanEdit: { teamId?: string }[];
    reloadMatchData: () => Promise<void>;
}

const VoteOnRescheduleRequest = ({
    match,
    participantsUserCanEdit,
    reloadMatchData,
    setError,
    setIsLoading,
}: VoteOnRescheduleRequestProps): JSX.Element => {
    const {
        autoReject,
        loadData,
        pendingRescheduleRequest,
        proposedTime,
        setAutoReject,
        setVotingMatchParticipantId,
        teamIds,
        userPermissionService,
        votingMatchParticipantId,
    } = useVoteOnRescheduleRequest(match.id, participantsUserCanEdit, setError, setIsLoading);

    const [approveRescheduleModal, openApproveRescheduleModal, closeApproveRescheduleModal] = useConfirmModal(
        () => 'Approve Reschedule Request',
        () => (
            <p>
                Are you sure you want to approve the request to reschedule to{' '}
                <strong>{proposedTime?.format(Core.Constants.MATCH_DATE_TIME_FORMAT)}</strong>?
            </p>
        ),
        async () => {
            if (!pendingRescheduleRequest) return;
            if (!votingMatchParticipantId) return;

            try {
                await MatchService.voteOnRescheduleRequest({
                    isApproving: true,
                    rescheduleRequestId: pendingRescheduleRequest.id,
                    matchParticipantId: votingMatchParticipantId,
                });
                setVotingMatchParticipantId(undefined);
                closeApproveRescheduleModal();
                await loadData();
                await reloadMatchData();
            } catch (e) {
                toast.error('There was an issue voting on this reschedule request. Please try again.');
            }
        }
    );

    const [rejectRescheduleModal, openRejectRescheduleModal, closeRejectRescheduleModal] = useConfirmModal(
        () => 'Reject Reschedule Request',
        () => (
            <div className="reschedule-match__reject">
                <p>
                    Are you sure you want to reject the request to reschedule to{' '}
                    <strong>{proposedTime?.format(Core.Constants.MATCH_DATE_TIME_FORMAT)}</strong>?
                </p>
                <div className="reschedule-match__reject-auto-reject">
                    <Checkbox name="auto-reject" checked={autoReject} onChange={() => setAutoReject(!autoReject)} />
                    <label htmlFor="auto-reject" onClick={() => setAutoReject(!autoReject)}>
                        Auto-reject future requests
                    </label>
                </div>
            </div>
        ),
        async () => {
            if (!pendingRescheduleRequest) return;
            if (!votingMatchParticipantId) return;

            try {
                await MatchService.voteOnRescheduleRequest({
                    autoReject,
                    isApproving: false,
                    rescheduleRequestId: pendingRescheduleRequest.id,
                    matchParticipantId: votingMatchParticipantId,
                });
                setVotingMatchParticipantId(undefined);
                closeRejectRescheduleModal();
                await loadData();
                await reloadMatchData();
            } catch (e) {
                toast.error('There was an issue voting on this reschedule request. Please try again.');
            }
        }
    );

    if (match.currentState !== Core.Models.MatchState.RescheduleRequested || !pendingRescheduleRequest) return <></>;

    return (
        <>
            <div className="match-page__checkin-notice">
                <p className="match-page__text match-page__text--checkin-notice">
                    * A reschedule has been requested for {proposedTime!.format(Core.Constants.MATCH_DATE_TIME_FORMAT)}
                </p>
            </div>

            {participantsUserCanEdit.length > 0 && (
                <div className="reschedule-match">
                    <h5 className="reschedule-match__header">
                        Reschedule requested: {proposedTime!.format(Core.Constants.MATCH_DATE_TIME_FORMAT)}
                    </h5>
                    {match.participants.map((mp: { id: string; teamId?: string; name?: string }) => {
                        const approvers = pendingRescheduleRequest.votes
                            .filter(
                                (v: Core.Models.RescheduleRequestVote) =>
                                    v.matchParticipantId === mp.id &&
                                    v.response === Core.Models.RescheduleRequestVoteResponse.Approved
                            )
                            .map((v) => Core.Identity.renderMemberName(v.user));
                        const hasAlreadyVoted =
                            pendingRescheduleRequest.votes.filter(
                                (v: Core.Models.RescheduleRequestVote) =>
                                    v.response === Core.Models.RescheduleRequestVoteResponse.Approved &&
                                    v.userId === userPermissionService.getUserId() &&
                                    v.matchParticipantId === mp.id
                            ).length > 0;
                        return (
                            <div className="reschedule-match__team" key={mp.id}>
                                <div className="reschedule-match__team-approvers">
                                    <div>
                                        <div className="reschedule-match__team-approvers--team-name">{mp.name}: </div>
                                        <div className="reschedule-match__team-approvers--names">
                                            {approvers.length > 0
                                                ? `Approved by: ${approvers.join(', ')}`
                                                : 'Waiting for approval'}
                                        </div>
                                    </div>
                                </div>
                                <div className="reschedule-match__vote-buttons">
                                    {teamIds.find((id: string) => id === mp.teamId) && (
                                        <div>
                                            <Button
                                                className="reschedule-match__vote"
                                                onClick={() => {
                                                    setVotingMatchParticipantId(mp.id);
                                                    openApproveRescheduleModal();
                                                }}
                                                semiRound
                                                style={{
                                                    visibility: `${hasAlreadyVoted ? 'hidden' : 'visible'}` as
                                                        | 'hidden'
                                                        | 'visible',
                                                }} // using visibility to align approve/reject buttons
                                                styleType={Core.Models.StyleType.Primary}
                                                title={`Approve reschedule request`}
                                            >
                                                Approve
                                            </Button>
                                            <Button
                                                className="reschedule-match__vote"
                                                onClick={() => {
                                                    setVotingMatchParticipantId(mp.id);
                                                    openRejectRescheduleModal();
                                                }}
                                                semiRound
                                                styleType={Core.Models.StyleType.Secondary}
                                                title={`Reject reschedule request`}
                                            >
                                                Reject
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                    {approveRescheduleModal}
                    {rejectRescheduleModal}
                </div>
            )}
        </>
    );
};

export default withLoading(VoteOnRescheduleRequest, {
    loadingProps: { blockItem: true },
});

import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
    children?: React.ReactNode;
    elementId: string;
}

const Portal = ({ children, elementId }: PortalProps): JSX.Element => {
    const [element, setElement] = useState<HTMLElement | undefined>(undefined);

    useEffect(() => {
        const foundElement = document.getElementById(elementId);
        if (foundElement) {
            setElement(foundElement);
        } else {
            throw new Error('A Portal must exist in the DOM before using this component.');
        }
    }, [elementId]);

    return element ? createPortal(children, element) : <></>;
};

export default Portal;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { HollowButtonType } from './HollowButton.types';
import { BaseButton } from '../baseButton';

import './HollowButton.scss';

const HollowButton = (props: HollowButtonType) => {
    const { className, children, color = 'primary', layout = 'inline', pending, size = 'medium', ...rest } = props;

    const hollowButtonClasses = classNames(
        className,
        'ds-hollow-button',
        `ds-hollow-button--${color}`,
        `ds-hollow-button--${layout}`,
        `ds-hollow-button--${size}`,
        {
            'ds-hollow-button--disabled': props.as === 'button' && (props.disabled || pending),
            'ds-hollow-button--pending': props.as === 'button' && pending,
        }
    );

    return (
        <BaseButton
            className={hollowButtonClasses}
            {...(props.as === 'button' ? { disabled: props.disabled || pending } : null)}
            {...rest}
        >
            {props.as === 'button' && pending && (
                <FontAwesomeIcon icon={['fas', 'spinner']} size="1x" className="fa-spin-pulse" />
            )}
            {children}
        </BaseButton>
    );
};

export default HollowButton;

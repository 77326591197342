const logoText = `%c
############################################################
############################################################
############################################################
#############*                                  ############
#############*   ###########################    ############
#############*   ##    #########**********##    ############
#############*   ##    #########**********##    ############
#############*   ##    #########***###****##    ############
#############*   ##    #########***###****##    ############
#############*   ##    #########***#########    ############
#############*   ##    #########**********##    ############
#############*   ##    ###############/***##    ############
#############*   ##    ###    ##***###/***##    ############
#############*   ##    ###    ##***###/***##    ############
#############*   ##    ###    ##***###****##    ############
#############*   ####         ##********####    ############
##############      ####      ##*****####,     #############
#################     .####   ##**####*     ################
####################      #########*     ###################
#######################     ,###*     ######################
##########################         #########################
#############################   ############################
############################################################
############################################################`;

export default logoText;

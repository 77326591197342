import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { groupBy, isNumber, isUndefined, last, max, orderBy, sortBy, uniq } from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import ChessMatchGameLink from './chess/matchGameLink';
import ChooseDeckModal from './hearthstone/chooseDeckModal';
import DeckImage from './hearthstone/deckImage';
import LolMatchGameResults, { LolMatchGameResult } from './leagueOfLegends/matchGameResults';
import LolTournamentCode from './leagueOfLegends/tournamentCode';
import { ChessMatchStats, ValorantMatchStats } from './matchStats';
import MatchUser from './matchUserList/matchUser';
import SubmitMatchGameResult from './submitMatchGameResult';
import downloadIcon from '../../assets/images/icons/download.svg';
import { Button } from '../../components/button';
import { HollowButton, SolidButton, TertiaryButton } from '../../components/buttons-visuals';
import { ContentContainer } from '../../components/containers';
import InfoMessage from '../../components/infoMessage';
import Markdown from '../../components/markdown';
import Modal from '../../components/overlays/modal/Modal';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import useCountdown from '../../hooks/countdown';
import { useModal } from '../../hooks/modal';
import { useLeague } from '../../hooks/store';
import { MatchService } from '../../services/matchService';
import PlatformEventsService from '../../services/platformEventsService';

interface MatchGamesScoresProps extends WithLoadingProps {
    canEditSeason: boolean;
    canSubmitMatchGameResult: boolean;
    canViewResultDetails: boolean;
    isMyMatch: boolean;
    match: {
        bestOf: number;
        competitionStyle: Core.Models.CompetitionStyle;
        currentState: Core.Models.MatchState;
        game: {
            featureSet: Core.Models.FeatureSet;
            gameHandleSource?: Core.Models.GameHandleSource;
            minimumSeats: number;
            name: string;
            rankings: { [key: string]: number };
            rankingTerm: string;
            rankingType: Core.Models.RankingType;
            resultFileExtensions?: string[];
        };
        id: string;
        scoringType: Core.Models.ScoringType;
        season: {
            tennisStyle: boolean;
            tennisStyleSets?: number;
            titleConfiguration?: Core.Models.ChessConfiguration;
        };
        stageSettings: Core.Models.StageSettings;
        useSimplifiedCheckin: boolean;
    };
    matchParticipants: Core.Models.MatchParticipant[];
    matchWord: string;
    minParticipantSeats: number;
    participantsUserCanEdit: {
        id: string;
    }[];
    reloadMatchData: () => Promise<void>;
    reloadMatchParticipants: () => Promise<void>;
    requireResultScreenshots: boolean;
    setIsSubmittingMatchResults: (isSubmittingMatchResults: boolean) => void;
}

const getMatchGameResults = (matchGame: Core.Models.MatchGame, matchParticipants: Core.Models.MatchParticipant[]) => {
    if (matchParticipants.length !== 2 || !matchGame.isComplete) return undefined;

    const { isComplete, results } = matchGame;
    const scores = orderBy(results, (r: Core.Models.MatchGameResult) => r.submittedTimeUtc, 'desc')[0]?.scores || [];
    const highestScore = max(scores.map((mgrs: Core.Models.MatchGameResultScore) => mgrs.score));
    const isTie = isComplete && scores.every((mgrs: Core.Models.MatchGameResultScore) => mgrs.score === highestScore);
    if (isTie) return { isTie: true, winningMatchParticipantId: undefined };

    const winningMatchParticipantId = scores.filter(
        (mgrs: Core.Models.MatchGameResultScore) => mgrs.score === highestScore
    )[0]?.matchParticipantId;
    return { isTie: false, winningMatchParticipantId };
};

const MATCH_GAME_METADATA_UPDATED = 'MatchGameMetadataUpdated';
const MATCH_GAME_RESULTS_SUBMITTED = 'MatchGameResultsSubmitted';
const MATCH_RESULTS_SUBMITTED = 'MatchResultsSubmitted';

const MatchGamesScores = (props: MatchGamesScoresProps): JSX.Element => {
    const {
        canEditSeason,
        canSubmitMatchGameResult,
        canViewResultDetails,
        isMyMatch,
        match,
        matchParticipants,
        matchWord,
        minParticipantSeats,
        participantsUserCanEdit,
        reloadMatchData,
        reloadMatchParticipants,
        requireResultScreenshots,
        setError,
        setIsLoading,
        setIsSubmittingMatchResults,
    } = props;

    const [matchGames, setMatchGames] = useState<Core.Models.MatchGame[] | undefined>(undefined);

    const league = useLeague();

    const loadData = useCallback(async () => {
        try {
            const data = await MatchService.getMatchGames(match.id);
            setMatchGames(orderBy(data, (mg: Core.Models.MatchGame) => mg.sortOrder));
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [match.id, setError, setIsLoading]);

    useEffect(() => {
        (async () => {
            await loadData();
        })();
    }, [loadData]);

    useEffect(() => {
        if (!league?.id) return;
        let listenerId: string | null = null;
        (async () => {
            listenerId = await PlatformEventsService.startListening(
                [MATCH_GAME_METADATA_UPDATED, MATCH_GAME_RESULTS_SUBMITTED, MATCH_RESULTS_SUBMITTED],
                league.id
            );
        })();
        return () => {
            (async () => {
                if (!!listenerId)
                    await PlatformEventsService.stopListening(listenerId, [
                        MATCH_GAME_METADATA_UPDATED,
                        MATCH_GAME_RESULTS_SUBMITTED,
                        MATCH_RESULTS_SUBMITTED,
                    ]);
            })();
        };
    }, [league?.id]);

    const handleMatchGameMetadataUpdated = useCallback(
        async (matchId: string) => {
            if (matchId === match.id) {
                await reloadMatchParticipants();
            }
        },
        [match.id, reloadMatchParticipants]
    );

    const handleMatchGameResultsSubmitted = useCallback(
        async (matchId: string) => {
            if (matchId === match.id) {
                await reloadMatchParticipants();
                await loadData();
            }
        },
        [loadData, match.id, reloadMatchParticipants]
    );

    const handleMatchResultsSubmitted = useCallback(
        async (matchId: string) => {
            if (matchId === match.id) {
                await reloadMatchData();
                await loadData();
                await reloadMatchParticipants();
            }
        },
        [loadData, match.id, reloadMatchData, reloadMatchParticipants]
    );

    const routeDataReceived = useCallback(
        ({ eventName, data }: { eventName: string; data: any }) => {
            switch (eventName) {
                case MATCH_GAME_METADATA_UPDATED:
                    return handleMatchGameMetadataUpdated(data);
                case MATCH_GAME_RESULTS_SUBMITTED:
                    return handleMatchGameResultsSubmitted(data);
                case MATCH_RESULTS_SUBMITTED:
                    return handleMatchResultsSubmitted(data);
                default:
            }
        },
        [handleMatchGameMetadataUpdated, handleMatchGameResultsSubmitted, handleMatchResultsSubmitted]
    );

    useEffect(() => {
        const subscription = PlatformEventsService.dataReceived.subscribe(routeDataReceived);
        return () => subscription.unsubscribe();
    }, [routeDataReceived]);

    const minimumGamesRequired = useMemo(
        () => (match.stageSettings.usePointsBasedScoring ? match.bestOf : Math.floor(match.bestOf / 2) + 1),
        [match.bestOf, match.stageSettings.usePointsBasedScoring]
    );

    const showTiebreakerMessage = useMemo(() => {
        return (
            match.stageSettings.attemptPointsBasedScoringTiebreaker &&
            match.currentState >= Core.Models.MatchState.IsComplete &&
            Object.keys(groupBy(matchParticipants, (mp: Core.Models.MatchParticipant) => mp.score)).length === 1 &&
            matchParticipants.some((mp: Core.Models.MatchParticipant) => mp.isWinner)
        );
    }, [match.currentState, match.stageSettings.attemptPointsBasedScoringTiebreaker, matchParticipants]);

    const canSubmitMatchResult = useMemo(() => {
        // authorize
        if (!canSubmitMatchGameResult) return false;

        // verify that enough games have been played
        const totalGamesPlayed = matchGames?.filter((mg: Core.Models.MatchGame) => mg.isComplete).length ?? 0;

        // leaderboards can submit if there are any games played
        if (match.competitionStyle === Core.Models.CompetitionStyle.Leaderboard) return totalGamesPlayed > 0;

        // need to play at least minimum required games
        if (totalGamesPlayed < minimumGamesRequired) return false;

        const scores = matchParticipants.map((mp: Core.Models.MatchParticipant) => mp.score ?? 0);
        const regulationMatchesRemaining = Math.max(match.bestOf - totalGamesPlayed, 0);
        if (regulationMatchesRemaining > 0 && !match.stageSettings.usePointsBasedScoring) {
            const differential = Math.max(...scores) - Math.min(...scores);
            if (regulationMatchesRemaining >= differential) return false; // the losing team can at least tie the winning team in regulation
        }

        // determine if the match was a tie
        var isTie = uniq(scores).length === 1;
        if (isTie) {
            // if match is tied and more games are available, continue play
            if (totalGamesPlayed < match.bestOf) return false;

            if (!match.stageSettings.allowMatchTies && match.stageSettings.attemptPointsBasedScoringTiebreaker) {
                // determine if one team has won more games - only create more games if teams have won same number of games
                return (
                    Object.keys(groupBy(matchParticipants, (mp: Core.Models.MatchParticipant) => mp.gameWins)).length >
                    1
                );
            }

            // if no more games are available and ties are allowed, complete match
            return match.stageSettings.allowMatchTies;
        }

        // enough matches have played and a winner has been found
        return true;
    }, [
        canSubmitMatchGameResult,
        match.bestOf,
        match.competitionStyle,
        match.stageSettings.allowMatchTies,
        match.stageSettings.usePointsBasedScoring,
        matchGames,
        matchParticipants,
        minimumGamesRequired,
    ]);

    const matchParticipantGamesWon = useMemo(() => {
        if (match.game.featureSet !== Core.Models.FeatureSet.Hearthstone) return []; // not relevant for non-Hearthstone matches - don't even do the calcs
        return (matchGames || [])
            .map((mg: Core.Models.MatchGame) => ({
                matchGameId: mg.id,
                results: getMatchGameResults(mg, matchParticipants),
            }))
            .filter((mg) => !isUndefined(mg.results))
            .map((mg) => ({
                matchGameId: mg.matchGameId,
                winningMatchParticipantId: mg.results!.winningMatchParticipantId,
            }));
    }, [match.game.featureSet, matchGames, matchParticipants]);

    const latestIncompleteMatchGame = useMemo(() => {
        if (isUndefined(matchGames) || matchGames.length === 0) return undefined;
        return last(matchGames.filter((mg: Core.Models.MatchGame) => !mg.isComplete));
    }, [matchGames]);

    const groupedMatches = useMemo(() => {
        return groupBy(matchGames, (mg: Core.Models.MatchGame) => mg.tennisStyleSetNumber);
    }, [matchGames]);

    if (isUndefined(matchGames)) return <></>;
    return matchGames.length > 0 ? (
        <>
            {showTiebreakerMessage && (
                <InfoMessage
                    message={`A tiebreaker was applied to this ${matchWord.toLowerCase()} based on number of games won.`}
                    type="info"
                />
            )}
            {(canEditSeason || isMyMatch) && (
                <LolTournamentCode
                    gameFeatureSet={match.game.featureSet}
                    matchGame={latestIncompleteMatchGame}
                    matchState={match.currentState}
                />
            )}
            {(canEditSeason || isMyMatch) && match.game.featureSet === Core.Models.FeatureSet.Chess && (
                <ChessMatchGameLink
                    matchGame={latestIncompleteMatchGame}
                    matchGames={matchGames}
                    matchState={match.currentState}
                    {...{
                        canEditSeason,
                        matchParticipants,
                        participantsUserCanEdit,
                    }}
                    minimumSeats={minParticipantSeats}
                    seasonTitleConfiguration={match.season.titleConfiguration}
                    tennisStyle={match.season.tennisStyle}
                />
            )}

            {Object.values(groupedMatches).map((setMatchGames: Core.Models.MatchGame[], index: number) => {
                const setNumber = match.season.tennisStyle ? setMatchGames[0]?.tennisStyleSetNumber : undefined;
                const hasMultipleSets = match.season.tennisStyle && Object.keys(groupedMatches).length > 1;
                return React.createElement(hasMultipleSets ? ContentContainer : React.Fragment, {
                    key: index,
                    ...(hasMultipleSets && {
                        className: 'mb2x',
                        shade: Core.Models.Shades.Dark20,
                    }),
                    children: (
                        <>
                            {hasMultipleSets && (
                                <div className="font-montserrat-bold text-center text-xlarge mb2x">Set {setNumber}</div>
                            )}
                            {setMatchGames.map((matchGame: Core.Models.MatchGame) => (
                                <MatchGameScore
                                    allowMatchGameTies={match.stageSettings.allowMatchGameTies}
                                    competitionStyle={match.competitionStyle}
                                    game={match.game}
                                    key={matchGame.id}
                                    matchId={match.id}
                                    reloadMatchGames={loadData}
                                    resultFileExtensions={match.game.resultFileExtensions}
                                    scoringType={match.scoringType}
                                    season={match.season}
                                    statsOpen={!hasMultipleSets}
                                    useSimplifiedCheckin={match.useSimplifiedCheckin}
                                    {...{
                                        canEditSeason,
                                        canSubmitMatchGameResult,
                                        canViewResultDetails,
                                        matchGame,
                                        matchParticipantGamesWon,
                                        matchParticipants,
                                        minParticipantSeats,
                                        participantsUserCanEdit,
                                        reloadMatchParticipants,
                                        requireResultScreenshots,
                                    }}
                                />
                            ))}
                        </>
                    ),
                });
            })}

            {canSubmitMatchResult ? (
                <div className="match-page__score-submit-match-results-container">
                    <Button
                        semiRound
                        onClick={() => setIsSubmittingMatchResults(true)}
                        className="match-page__score-submit-match-results mb2x"
                    >
                        Submit {matchWord.toLowerCase()} results
                    </Button>
                </div>
            ) : (
                canSubmitMatchGameResult &&
                match.currentState === Core.Models.MatchState.InProgress && (
                    <div className="match-page__score-submit-match-results-container mb2x">
                        {match.stageSettings.usePointsBasedScoring ? (
                            <>
                                {minimumGamesRequired} {pluralize('game', minimumGamesRequired)} must be completed to
                                submit results.
                            </>
                        ) : (
                            !match.stageSettings.allowMatchTies && (
                                <>
                                    One team must win {minimumGamesRequired} {pluralize('game', minimumGamesRequired)}{' '}
                                    to submit results.
                                </>
                            )
                        )}
                    </div>
                )
            )}
        </>
    ) : (
        <>
            <div className="vr--x4" />
            <p className="match-page__text match-page__text--centered">
                There are no games {match.currentState < Core.Models.MatchState.IsComplete && 'yet'}
            </p>
        </>
    );
};

interface MatchGameScoreProps {
    allowMatchGameTies: boolean;
    canEditSeason: boolean;
    canSubmitMatchGameResult: boolean;
    canViewResultDetails: boolean;
    competitionStyle: Core.Models.CompetitionStyle;
    game: {
        featureSet: Core.Models.FeatureSet;
        gameHandleSource?: Core.Models.GameHandleSource;
        minimumSeats: number;
        name: string;
        rankings: { [key: string]: number };
        rankingTerm: string;
        rankingType: Core.Models.RankingType;
    };
    matchGame: Core.Models.MatchGame;
    matchId: string;
    matchParticipantGamesWon?: {
        matchGameId: string;
        winningMatchParticipantId: string | undefined;
    }[];
    matchParticipants: Core.Models.MatchParticipant[];
    minParticipantSeats: number;
    participantsUserCanEdit: {
        id: string;
    }[];
    reloadMatchGames: () => Promise<void>;
    reloadMatchParticipants: () => Promise<void>;
    requireResultScreenshots: boolean;
    resultFileExtensions?: string[];
    scoringType: Core.Models.ScoringType;
    season: {
        tennisStyle: boolean;
    };
    statsOpen: boolean;
    useSimplifiedCheckin: boolean;
}

interface ParticipantScore {
    id: string;
    name: string;
    score: string | number;
}

const MatchGameScore = ({
    allowMatchGameTies,
    canEditSeason,
    canSubmitMatchGameResult,
    canViewResultDetails,
    competitionStyle,
    game,
    matchGame,
    matchId,
    matchParticipantGamesWon,
    matchParticipants,
    minParticipantSeats,
    participantsUserCanEdit,
    reloadMatchGames,
    reloadMatchParticipants,
    requireResultScreenshots,
    resultFileExtensions,
    scoringType,
    season,
    statsOpen,
    useSimplifiedCheckin,
}: MatchGameScoreProps): JSX.Element => {
    const [isReprocessing, setIsReprocessing] = useState<boolean>(false);
    const [showStats, setShowStats] = useState<boolean>(statsOpen);
    const [submitScoresModalOpen, setSubmitScoresModalOpen] = useState<boolean>(false);
    const [teamSelectingMatchGameDeck, setTeamSelectingMatchGameDeck] = useState<
        Core.Models.MatchParticipant | undefined
    >(undefined);
    const [isChangingMatchGameDeck, setIsChangingMatchGameDeck] = useState<
        { decks: Core.Models.MatchHearthstoneDeck[]; matchGameMetadataId: string } | undefined
    >(undefined);

    const isChess = game.featureSet === Core.Models.FeatureSet.Chess && !!matchGame.scoringParsed;
    const isValorant = game.featureSet === Core.Models.FeatureSet.Valorant && !!matchGame.scoringParsed;
    const isLol =
        [Core.Models.FeatureSet.LeagueOfLegends, Core.Models.FeatureSet.LeagueOfLegendsAram].includes(
            game.featureSet
        ) && !!matchGame.scoringParsed;
    const hasCustomGameStats = isValorant || isLol || isChess;

    const name = useMemo(
        () =>
            hasCustomGameStats ? (
                <TertiaryButton
                    as="button"
                    aria-label={`Open game ${matchGame.sortOrder} stats dropdown`}
                    className="match-page__score-row__header__game-name"
                    onClick={() => setShowStats(!showStats)}
                >
                    Game {matchGame.sortOrder}
                    <FontAwesomeIcon
                        className={classNames('ml', showStats && 'flip-icon-horizontal')}
                        icon={['fas', 'caret-down']}
                    />
                </TertiaryButton>
            ) : (
                <div className="match-page__score-row__header__game-name">Game {matchGame.sortOrder}</div>
            ),
        [matchGame.sortOrder, showStats, hasCustomGameStats]
    );

    const { formattedTimer, secondsRemaining } = useCountdown('mm:ss', matchGame.scoringCanBeRequestedAtUtc);
    const supportsAutomatedScoring = useMemo(
        () =>
            [
                Core.Models.FeatureSet.Fortnite,
                Core.Models.FeatureSet.LeagueOfLegends,
                Core.Models.FeatureSet.LeagueOfLegendsAram,
            ].includes(game.featureSet),
        [game.featureSet]
    );

    const determineScore = useCallback(
        (matchParticipantId?: string) => {
            const lastResult = sortBy(matchGame.results, ['submittedTimeUtc']).pop();
            if (!!lastResult && !!matchParticipantId) {
                const scores = lastResult.scores.filter(
                    (resultScore: Core.Models.MatchGameResultScore) =>
                        resultScore.matchParticipantId === matchParticipantId
                );
                if (scores.length <= 0) return '-';
                return scores[0].score;
            } else {
                return '-';
            }
        },
        [matchGame]
    );

    const getCategoricalResult = useCallback(
        (matchParticipantId: string) => {
            const matchParticipant = matchParticipants.find(
                (mp: Core.Models.MatchParticipant) => mp.id === matchParticipantId
            );
            if (!matchParticipant) return <></>;

            const results = getMatchGameResults(matchGame, matchParticipants || []);

            if (!!results?.isTie) return <>T</>;

            const isNotTie = results?.isTie === false;
            const isWinner = isNotTie && matchParticipant.id === results?.winningMatchParticipantId;
            const isLoser = isNotTie && matchParticipant.id !== results?.winningMatchParticipantId;

            return isWinner ? <>W</> : isLoser ? <>L</> : <>-</>;
        },
        [matchGame, matchParticipants]
    );

    const getBinaryResult = useCallback((score: string | number) => {
        if (isNumber(score) && !!score) {
            return <FontAwesomeIcon icon={['fas', 'check']} />;
        }
        return <>-</>;
    }, []);

    const getHearthstoneDeck = useCallback(
        (matchParticipantId: string) => {
            const matchParticipant = matchParticipants.find(
                (mp: Core.Models.MatchParticipant) => mp.id === matchParticipantId
            );
            if (!matchParticipant) return <></>;
            if (!matchParticipant.hearthstoneDecks || matchParticipant.hearthstoneDecks.length === 0) return <></>;

            const results = getMatchGameResults(matchGame, matchParticipants);
            const isNotTie = results?.isTie === false;
            const isWinner = isNotTie && matchParticipant.id === results?.winningMatchParticipantId;
            const isLoser = isNotTie && matchParticipant.id !== results?.winningMatchParticipantId;

            const matchGameMetadata = matchParticipant.matchGameMetadata?.find(
                (mgm: Core.Models.MatchGameMetadata) => mgm.matchGameId === matchGame.id
            );
            if (!!matchGameMetadata) {
                const deck = matchParticipant.hearthstoneDecks.find(
                    (deck: Core.Models.HearthstoneDeck) =>
                        deck.code ===
                        (matchGameMetadata.value as Core.Models.HearthstoneMetadata | undefined)?.selectedDeckCode
                );
                if (!deck) return <></>;

                return (
                    <div
                        className={classNames('match-page__game-deck', {
                            'match-page__game-deck--is-winner': isWinner,
                            'match-page__game-deck--is-loser': isLoser,
                        })}
                    >
                        {canEditSeason ? (
                            <Button
                                onClick={() =>
                                    setIsChangingMatchGameDeck({
                                        decks: matchParticipant.hearthstoneDecks!,
                                        matchGameMetadataId: matchGameMetadata.id,
                                    })
                                }
                                semiRound
                                styleType={Core.Models.StyleType.Primary}
                                title={`Change Deck for Game ${matchGame.sortOrder} for ${matchParticipant.name}`}
                            >
                                <DeckImage name={deck.name} />
                            </Button>
                        ) : (
                            <DeckImage name={deck.name} />
                        )}
                    </div>
                );
            }

            if (participantsUserCanEdit.some((p) => p.id === matchParticipant.id)) {
                return (
                    <Button
                        className="match-page__submit-game-deck attention-light"
                        onClick={() => setTeamSelectingMatchGameDeck(matchParticipant)}
                        semiRound
                        styleType={Core.Models.StyleType.Primary}
                        title={`Select Deck for Game ${matchGame.sortOrder} for ${matchParticipant.name}`}
                    >
                        <FontAwesomeIcon icon={['fas', 'plus']} />
                    </Button>
                );
            }

            return isWinner ? <>W</> : isLoser ? <>L</> : <>-</>;
        },
        [canEditSeason, matchGame, matchParticipants, participantsUserCanEdit]
    );

    const getHearthstoneDecks = useCallback(() => {
        if (!teamSelectingMatchGameDeck) return [];

        const matchGamesWon = (matchParticipantGamesWon || [])
            .filter((results) => results.winningMatchParticipantId === teamSelectingMatchGameDeck.id)
            .map((results) => results.matchGameId);
        const decksUsedForWin = (teamSelectingMatchGameDeck.matchGameMetadata || [])
            .filter((mgm) => matchGamesWon.some((matchGameId: string) => matchGameId === mgm.matchGameId))
            .map((mgm) => (mgm.value as Core.Models.HearthstoneMetadata | undefined)?.selectedDeckCode);

        return teamSelectingMatchGameDeck.hearthstoneDecks!.filter(
            (deck: Core.Models.MatchHearthstoneDeck) => !deck.isBanned && !decksUsedForWin.some((d) => d === deck.code)
        );
    }, [matchParticipantGamesWon, teamSelectingMatchGameDeck]);

    const participantScores: ParticipantScore[] = useMemo(
        () =>
            matchParticipants.map((participant: Core.Models.MatchParticipant) => ({
                id: participant.id,
                name: participant.name,
                score: determineScore(participant.id),
            })) ?? [],
        [determineScore, matchParticipants]
    );

    const submitMatchGameScoreDisabled = useMemo(() => {
        if (game.featureSet !== Core.Models.FeatureSet.Hearthstone) return false;
        if (canEditSeason) return false;

        return !matchParticipants.every(
            (mp: Core.Models.MatchParticipant) =>
                !!mp.matchGameMetadata?.find((mgm: Core.Models.MatchGameMetadata) => mgm.matchGameId === matchGame.id)
        );
    }, [canEditSeason, game.featureSet, matchGame.id, matchParticipants]);

    const renderParticipantScores = useCallback((): JSX.Element[] | JSX.Element => {
        const maxNumericScore = max(
            participantScores
                .filter((ps: ParticipantScore) => isNumber(ps.score))
                .map((ps: ParticipantScore) => +ps.score)
        );

        if (competitionStyle === Core.Models.CompetitionStyle.Leaderboard) {
            const orderedParticipantScores = orderBy(
                participantScores,
                [(ps: ParticipantScore) => isNumber(ps.score), (ps: ParticipantScore) => ps.score],
                ['desc', 'desc']
            );

            return (
                <div className="match-page__score-row__header__score-leaderboard">
                    {orderedParticipantScores.map((participantScore: ParticipantScore) => (
                        <div className="disp-flex align-center m2x" key={participantScore.id}>
                            <div>{participantScore.name} - </div>
                            <div
                                className={classNames('match-page__score-row__header__score-leaderboard__score ml', {
                                    winner: participantScore.score === maxNumericScore,
                                })}
                            >
                                {scoringType === Core.Models.ScoringType.Binary
                                    ? getBinaryResult(participantScore.score)
                                    : participantScore.score}
                            </div>
                        </div>
                    ))}
                </div>
            );
        }

        return participantScores.map((participantScore: ParticipantScore, index: number) => (
            <div
                className={classNames(
                    'match-page__score-row__header__score',
                    `match-page__score-row__header__score--${index}`,
                    { 'match-page__score-row__header__score--valorant': isValorant }
                )}
                key={participantScore.id}
            >
                {game.featureSet === Core.Models.FeatureSet.Hearthstone ? (
                    getHearthstoneDeck(participantScore.id)
                ) : (
                    <div
                        className={classNames({
                            winner: participantScore.score === maxNumericScore,
                        })}
                    >
                        {scoringType === Core.Models.ScoringType.Categorical
                            ? getCategoricalResult(participantScore.id)
                            : scoringType === Core.Models.ScoringType.Binary
                            ? getBinaryResult(participantScore.score)
                            : participantScore.score}
                    </div>
                )}
            </div>
        ));
    }, [
        competitionStyle,
        game.featureSet,
        getBinaryResult,
        getCategoricalResult,
        getHearthstoneDeck,
        participantScores,
        scoringType,
    ]);

    const [gameDetailsModal, openGameDetailsModal, closeGameDetailsModal] = useModal(
        () => 'Game Details',
        () => (
            <>
                <ul className="match-page__game-details__results mb2x">
                    {orderBy(
                        matchGame.results,
                        (result: Core.Models.MatchGameResult) => moment(result.submittedTimeUtc).toISOString(),
                        'desc'
                    ).map((result: Core.Models.MatchGameResult) => (
                        <li key={result.id} className="match-page__game-details__result-item">
                            <div className="mb2x text-small">
                                At {moment(result.submittedTimeUtc).format(Core.Constants.TIMESTAMP_FORMAT)}
                                <span>
                                    , submitted by{' '}
                                    {!!result.submittedByUserId && !!result.submittedByFirstName ? (
                                        <Link to={`/users/${result.submittedByUserId}`}>
                                            {Core.Identity.renderMemberName({
                                                firstName: result.submittedByFirstName,
                                                lastName: result.submittedByLastName,
                                                pronouns: result.submittedByPronouns,
                                            })}
                                        </Link>
                                    ) : (
                                        <span>System</span>
                                    )}
                                </span>
                            </div>
                            {matchParticipants &&
                                matchParticipants.map((participant: Core.Models.MatchParticipant) => {
                                    const participantScore = result.scores.find(
                                        (resultScore: Core.Models.MatchGameResultScore) =>
                                            resultScore.matchParticipantId === participant.id
                                    );

                                    return (
                                        <div key={participant.id}>
                                            <span className="match-page__game-details__result-name">
                                                {participant.name}:{' '}
                                            </span>
                                            <strong
                                                className="match-page__game-details__result-score"
                                                aria-label="score"
                                            >
                                                {(participantScore && participantScore.score) || 0}
                                            </strong>
                                        </div>
                                    );
                                })}
                            <div className="match-page__game-details__result-screenshots">
                                {result.screenshots &&
                                    result.screenshots.map((screenshot: string, screenshotIndex: number) => {
                                        const fileExtension = resultFileExtensions?.find((ext) =>
                                            screenshot.endsWith(ext)
                                        );
                                        return (
                                            <React.Fragment key={screenshotIndex}>
                                                <a href={screenshot} target="_blank" rel="noopener noreferrer">
                                                    {screenshot.endsWith(`.${fileExtension}`) ? (
                                                        <>File</>
                                                    ) : (
                                                        'Screenshot'
                                                    )}{' '}
                                                    {screenshotIndex + 1}{' '}
                                                    {screenshot.endsWith(`.${fileExtension}`) ? (
                                                        <img src={downloadIcon} alt="Download" />
                                                    ) : null}
                                                </a>{' '}
                                            </React.Fragment>
                                        );
                                    })}
                            </div>
                        </li>
                    ))}
                </ul>
                {!!matchGame.scoringStatus && (
                    <div className="match-page__game-details__scoring-status">
                        <h3>Automation details:</h3>
                        <Markdown source={matchGame.scoringStatus} />
                    </div>
                )}
                <div className="match-page__game-details-modal-buttons">
                    {supportsAutomatedScoring && (
                        <HollowButton
                            as="button"
                            size="medium"
                            className="mr color-black"
                            disabled={secondsRemaining > 0 || isReprocessing}
                            onClick={async () => {
                                setIsReprocessing(true);

                                try {
                                    await MatchService.rescoreMatchGameResult({
                                        matchGameId: matchGame.id,
                                    });
                                    await reloadMatchGames();
                                    toast.success('Submitted request to reprocess match results');
                                    setIsReprocessing(false);
                                    closeGameDetailsModal();
                                } catch {
                                    toast.error('There was an issue reprocessing match results');
                                }
                            }}
                        >
                            {secondsRemaining > 0 ? `Reprocess in ${formattedTimer}` : 'Reprocess'}
                        </HollowButton>
                    )}
                    <SolidButton as="button" layout="full" onClick={() => closeGameDetailsModal()} size="medium">
                        Ok
                    </SolidButton>
                </div>
            </>
        )
    );

    const matchParticipantUsers = useMemo(() => {
        if (!matchGame?.tennisStyle) return [];

        return matchParticipants
            .map((mp: Core.Models.MatchParticipant) => {
                const matchParticipantUserId = (
                    mp.matchGameMetadata?.find((mgm) => mgm.matchGameId === matchGame.id)?.value as
                        | Core.Models.TennisStyleGameplayMetadata
                        | undefined
                )?.matchParticipantUserId;
                const user = mp.users.find((u) => u.id === matchParticipantUserId);

                return {
                    matchParticipantId: mp.id,
                    matchParticipantUserId,
                    user,
                };
            })
            .filter((mpu) => !!mpu.user);
    }, [matchGame, matchParticipants]);

    const renderMatchGameUsers = useCallback((): JSX.Element => {
        if (!matchGame?.tennisStyle) return <></>;
        return (
            <div className="match-page__score-row__users">
                {matchParticipantUsers.map((mpu, index: number) => (
                    <div className={`match-page__score-row__users--${index}`} key={mpu.matchParticipantUserId}>
                        <MatchUser game={game} hideRequirements key={mpu.matchParticipantUserId} user={mpu.user!} />
                    </div>
                ))}
            </div>
        );
    }, [game, matchGame, matchParticipants]);

    if (!matchGame) return <></>;

    return (
        <>
            <div className="match-page__score-row">
                <div
                    className={classNames('match-page__score-row__header', {
                        'match-page__score-row__header--leaderboard':
                            competitionStyle === Core.Models.CompetitionStyle.Leaderboard,
                    })}
                >
                    {name}

                    {!!teamSelectingMatchGameDeck && (
                        <ChooseDeckModal
                            decks={getHearthstoneDecks()}
                            isOpen={!!teamSelectingMatchGameDeck}
                            onClose={() => setTeamSelectingMatchGameDeck(undefined)}
                            onSubmit={async (value: string) => {
                                await MatchService.createMatchGameMetadata({
                                    matchGameId: matchGame.id,
                                    matchParticipantId: teamSelectingMatchGameDeck.id,
                                    value,
                                });
                                await reloadMatchParticipants();
                                setTeamSelectingMatchGameDeck(undefined);
                            }}
                            modalTitle={`Select a deck for Game ${matchGame.sortOrder}`}
                        />
                    )}

                    {!!isChangingMatchGameDeck && (
                        <ChooseDeckModal
                            decks={isChangingMatchGameDeck.decks.filter(
                                (deck: Core.Models.MatchHearthstoneDeck) => !deck.isBanned
                            )}
                            isOpen={!!isChangingMatchGameDeck}
                            onClose={() => setIsChangingMatchGameDeck(undefined)}
                            onSubmit={async (value: string) => {
                                await MatchService.editMatchGameMetadata({
                                    matchGameMetadataId: isChangingMatchGameDeck.matchGameMetadataId,
                                    value,
                                });
                                await reloadMatchParticipants();
                                setIsChangingMatchGameDeck(undefined);
                            }}
                            modalTitle={`Select a new deck for Game ${matchGame.sortOrder}`}
                        />
                    )}

                    {renderParticipantScores()}

                    {canSubmitMatchGameResult && (
                        <TertiaryButton
                            as="button"
                            aria-label={
                                submitMatchGameScoreDisabled
                                    ? 'Both teams must select a deck to submit scores'
                                    : `Submit scores for Game ${matchGame.sortOrder}`
                            }
                            className={classNames('match-page__score-row__header__submit-results', {
                                'color-primary': !matchGame.isComplete || !supportsAutomatedScoring,
                            })}
                            disabled={submitMatchGameScoreDisabled}
                            onClick={() => setSubmitScoresModalOpen(true)}
                        >
                            <FontAwesomeIcon className="mr" icon={['fas', 'file-arrow-up']} />
                            Submit scores
                        </TertiaryButton>
                    )}
                    {canViewResultDetails && (
                        <TertiaryButton
                            as="button"
                            aria-label={`View score submission details for Game ${matchGame.sortOrder}`}
                            className="match-page__score-row__header__game-details"
                            onClick={() => openGameDetailsModal()}
                        >
                            <FontAwesomeIcon
                                className={classNames('mr', {
                                    'color-error': !!matchGame.scoringError,
                                    'color-success': matchGame.hasScoringData && !matchGame.scoringError,
                                    'color-primary':
                                        supportsAutomatedScoring &&
                                        !matchGame.hasScoringData &&
                                        !matchGame.scoringError,
                                })}
                                icon={[
                                    'fas',
                                    !!matchGame.scoringError
                                        ? 'circle-exclamation'
                                        : supportsAutomatedScoring &&
                                          !matchGame.hasScoringData &&
                                          !matchGame.scoringError
                                        ? 'hourglass-half'
                                        : matchGame.hasScoringData && !matchGame.scoringError
                                        ? 'circle-check'
                                        : 'clipboard-list',
                                ]}
                            />
                            Score details
                        </TertiaryButton>
                    )}
                </div>

                {renderMatchGameUsers()}

                <Modal
                    title="Game results"
                    isOpen={submitScoresModalOpen}
                    onClose={() => setSubmitScoresModalOpen(false)}
                >
                    <SubmitMatchGameResult
                        {...{
                            allowMatchGameTies,
                            competitionStyle,
                            gameFeatureSet: game.featureSet,
                            gameMinimumSeats: game.minimumSeats,
                            gameName: game.name,
                            matchGame,
                            matchId,
                            matchParticipants,
                            matchParticipantUserIds: matchParticipantUsers.map(
                                (mpu) => mpu.matchParticipantUserId as string
                            ),
                            minParticipantSeats,
                            requireResultScreenshots,
                            resultFileExtensions,
                            scoringType,
                            tennisStyle: season.tennisStyle,
                            useSimplifiedCheckin,
                        }}
                        onCancel={() => setSubmitScoresModalOpen(false)}
                        onComplete={async () => {
                            await reloadMatchGames();
                            await reloadMatchParticipants();
                            setSubmitScoresModalOpen(false);
                        }}
                    />
                </Modal>
                {gameDetailsModal}

                {showStats && (
                    <>
                        {isValorant && (
                            <ValorantMatchStats
                                matchParticipantMapping={matchParticipants.reduce(
                                    (acc: any, mp: Core.Models.MatchParticipant) => {
                                        if (!mp.teamId) return acc;
                                        return {
                                            ...acc,
                                            [mp.id]: {
                                                id: mp.teamId,
                                                name: mp.name,
                                                avatarUrl: mp.avatarUrl,
                                                organizationIconUrl: mp.organizationLogoUrl,
                                            },
                                        };
                                    },
                                    {}
                                )}
                                scoringParsed={matchGame.scoringParsed}
                            />
                        )}

                        {isChess && matchGame.scoringParsed && (
                            <ChessMatchStats detailedView match={matchGame.scoringParsed} />
                        )}

                        {isLol && (
                            <LolMatchGameResults
                                gameNumber={matchGame.sortOrder}
                                matchGameResult={matchGame.scoringParsed as LolMatchGameResult}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default withLoading(MatchGamesScores, {
    loadingProps: { blockItem: true },
});

import * as React from 'react';
import { connect } from 'react-redux';

import * as Core from '../../core';
import { AppState } from '../../store';

import './index.scss';

interface ContactPageProps {
    league?: Core.Models.League;
}

const contactPageDescription =
    'Have a question or comment? How about something you really like or something that you might not like so much? Let us know. We are always open to your feedback.';

const ContactPage: React.FunctionComponent<ContactPageProps> = (props) => {
    const { league } = props;
    return (
        <div className="page contact-page">
            <h2 className="contact-page__title">Contact</h2>
            <div className="contact-page__description">{contactPageDescription}</div>
            <div className="contact-page__content">
                {league && (
                    <section className="contact-page__section">
                        <h4 className="contact-page__header">{league.name}</h4>
                        {league.email && (
                            <>
                                <h6 className="contact-page__subheader">Email</h6>
                                <a href={`mailto:${league.email}`} className="contact-page__info">
                                    {league.email}
                                </a>
                            </>
                        )}
                        {league.phoneNumber && (
                            <>
                                <h6 className="contact-page__subheader">Phone</h6>
                                <div className="contact-page__info">{league.phoneNumber}</div>
                            </>
                        )}
                        {league.discordUrl && (
                            <>
                                <h6 className="contact-page__subheader">Discord</h6>
                                <a
                                    href={league.discordUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="contact-page__info"
                                >
                                    {league.discordUrl}
                                </a>
                            </>
                        )}
                        {league.websiteUrl && (
                            <>
                                <h6 className="contact-page__subheader">Website</h6>
                                <a
                                    href={league.websiteUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="contact-page__info"
                                >
                                    {league.websiteUrl}
                                </a>
                            </>
                        )}
                    </section>
                )}
            </div>
        </div>
    );
};

function mapStateToProps({ leagueState }: AppState) {
    return {
        league: leagueState.league && leagueState.league.league,
    };
}

export default connect(mapStateToProps)(ContactPage);

import React, { useCallback, useEffect, useState } from 'react';
import { isNumber, uniqBy } from 'lodash';

import * as Core from '../../core';
import CreatePost from './createPost';
import Post from './post';
import useApiPagination from '../../hooks/apiPagination';
import { useModal } from '../../hooks/modal';
import { PostService } from '../../services/postService';
import { HollowButton, SolidButton } from '../buttons-visuals';
import Loading from '../loading';
import withLoading, { WithLoadingProps } from '../withLoading';

import './index.scss';

interface SocialFeedProps extends WithLoadingProps {
    gameId?: string;
    matchId?: string;
    organizationId?: string;
    seasonId?: string;
}

const SocialFeed = ({ gameId, matchId, organizationId, seasonId, setError, setIsLoading }: SocialFeedProps) => {
    const [posts, setPosts] = useState<Core.Models.Post[]>([]);
    const [isLoadingPosts, setIsLoadingPosts] = useState<boolean>(false);
    const [numberOfPosts, setNumberOfPosts] = useState<number | undefined>(undefined);

    const getPaginatedPostsAsync = useCallback(
        (page: number, pageSize: number, search: string): Promise<Core.Models.PaginatedResult<Core.Models.Post>> => {
            return PostService.getPosts({
                page,
                pageSize,
                search,
            });
        },
        []
    );

    // todo: consider using new useApiIncrementalLoader
    const { incrementPage, paginatedResults, selectPage } = useApiPagination(
        getPaginatedPostsAsync,
        setError,
        setIsLoading,
        Core.Constants.PAGINATION.SOCIAL_POSTS_PER_PAGE
    );

    useEffect(() => {
        if (!!paginatedResults?.results) {
            setPosts(uniqBy([...posts, ...paginatedResults.results], (c: Core.Models.Post) => c.id));
            setNumberOfPosts(paginatedResults.total);
        }
    }, [paginatedResults]);

    const [createPostModal, showCreatePostModal] = useModal(
        () => 'Create Post',
        (closeModal) => (
            <CreatePost
                gameId={gameId}
                matchId={matchId}
                onClose={closeModal}
                onSubmit={(post: Core.Models.Post) => {
                    setPosts([post, ...posts]);
                    if (!!numberOfPosts) setNumberOfPosts(numberOfPosts + 1);
                }}
                organizationId={organizationId}
                seasonId={seasonId}
            />
        )
    );

    if (!paginatedResults) return <></>;
    if (!isNumber(numberOfPosts)) return <></>;
    return (
        <div className="social-feed">
            <SolidButton as="button" onClick={showCreatePostModal} size="medium">
                Create a post
            </SolidButton>
            <h3>Feed</h3>
            {posts.length <= 0 ? (
                <p>No posts to show yet. Be the first!</p>
            ) : (
                <>
                    <>
                        {posts.map((post: Core.Models.Post) => (
                            <Post
                                key={post.id}
                                onDelete={(id: string) => {
                                    setPosts(posts.filter((p: Core.Models.Post) => p.id !== id));
                                    setNumberOfPosts(numberOfPosts - 1);

                                    if (posts.length == 1) {
                                        // if deleting the last visible post, reload
                                        selectPage(1);
                                    }
                                }}
                                post={post}
                            />
                        ))}
                    </>
                    <>
                        {isLoadingPosts ? (
                            <Loading blockItem />
                        ) : (
                            numberOfPosts > posts.length && (
                                <HollowButton
                                    as="button"
                                    onClick={async () => {
                                        setIsLoadingPosts(true);
                                        await incrementPage(1, false);
                                        setIsLoadingPosts(false);
                                    }}
                                    size="medium"
                                >
                                    View more
                                </HollowButton>
                            )
                        )}
                    </>
                </>
            )}
            {createPostModal}
        </div>
    );
};

export default withLoading(SocialFeed, { loadingProps: { blockItem: true } });

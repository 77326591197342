import React, { useEffect, useState, useMemo } from 'react';

import * as Core from '../../core';
import RegisterModal from './signupModal';
import { useIsPlatform, useLeague, useLoginState } from '../../hooks/store';
import { SeasonService } from '../../services/seasonService';
import { HollowButton, SolidButton } from '../buttons-visuals';

import './signup.scss';

interface RegisterProps {
    className?: string;
    hideIfNoSeasons?: boolean;
    layout?: 'full' | 'inline';
    styleType?: 'hollow' | 'solid';
    text: string;
}

const Signup = ({ className, hideIfNoSeasons, layout, styleType = 'hollow', text }: RegisterProps): JSX.Element => {
    const league = useLeague();
    const isPlatform = useIsPlatform();
    const { authenticated } = useLoginState();

    const [joinableSeasons, setJoinableSeasons] = useState<Core.Models.JoinableSeason[]>([]);
    const [isOpening, setIsOpening] = useState<boolean>(false);

    const noSeasons = useMemo(
        () => !!hideIfNoSeasons && joinableSeasons.length === 0,
        [hideIfNoSeasons, joinableSeasons.length]
    );

    useEffect(() => {
        (async () => {
            if (!isPlatform) {
                setJoinableSeasons(await SeasonService.getJoinableSeasons());
            }
        })();
    }, [isPlatform]);

    if (!league || authenticated || noSeasons) return <></>;

    return (
        <div className="signup disp-flex align-center">
            {joinableSeasons.length !== 1 ? (
                styleType === 'solid' ? (
                    <SolidButton
                        layout={layout}
                        size="large"
                        as="button"
                        className={className}
                        onClick={() => setIsOpening(true)}
                    >
                        {text}
                    </SolidButton>
                ) : (
                    <HollowButton
                        layout={layout}
                        size="large"
                        as="button"
                        className={className}
                        onClick={() => setIsOpening(true)}
                    >
                        {text}
                    </HollowButton>
                )
            ) : styleType === 'solid' ? (
                <SolidButton
                    layout={layout}
                    size="large"
                    as="link"
                    className={className}
                    to={`/seasons/join/${joinableSeasons[0].id}`}
                >
                    {text}
                </SolidButton>
            ) : (
                <HollowButton
                    layout={layout}
                    size="large"
                    as="link"
                    className={className}
                    to={`/seasons/join/${joinableSeasons[0].id}`}
                >
                    {text}
                </HollowButton>
            )}
            {isOpening && <RegisterModal {...{ joinableSeasons }} onClose={() => setIsOpening(false)} />}
        </div>
    );
};

export default Signup;

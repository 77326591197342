import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import * as Core from '../../../core';
import { Avatar } from '../../buttons-visuals';
import ParticipantLink from '../../participantLink';
import MatchParticipantStation from '../matchParticipantStation';

import './headToHeadItem.scss';

interface HeadToHeadItemProps {
    byeMatchup?: boolean;
    canEditSeason: boolean;
    currentState: Core.Models.MatchState;
    enableHighlight?: boolean;
    formatForForfeit: boolean;
    isMyParticipant?: (participant: { teamId?: string }) => boolean;
    isPreview: boolean;
    location?: Core.Models.LeagueLocation;
    onStationSelect?: (id: string) => void;
    participant: Core.Models.MatchParticipant;
    showScore?: boolean;
}

export const HeadToHeadItem = (props: HeadToHeadItemProps): JSX.Element => {
    const {
        byeMatchup,
        canEditSeason,
        currentState,
        enableHighlight,
        formatForForfeit,
        isMyParticipant,
        isPreview,
        location,
        onStationSelect,
        participant,
        showScore,
    } = props;

    const {
        avatarUrl,
        hasForfeited,
        id,
        isTie,
        isWinner,
        name,
        organizationLogoUrl,
        participantId,
        previousMatchId,
        score,
    } = participant;

    const myParticipant = isMyParticipant && isMyParticipant(participant);

    const parts = [
        participantId && (
            <Avatar
                className="head-to-head-item__avatar"
                fallback="team"
                size="medium"
                src={avatarUrl || organizationLogoUrl}
            />
        ),
        <div className="head-to-head-item__label">
            {previousMatchId && !participantId ? (
                <Link className="head-to-head-item__label__name" to={`/matches/${previousMatchId}`}>
                    TBD
                </Link>
            ) : (
                <ParticipantLink className="head-to-head-item__label__name" participant={participant} unstyled>
                    {!!id && (name || <em>Deleted team</em>)}
                </ParticipantLink>
            )}
            {!isPreview && (
                <MatchParticipantStation
                    {...{
                        canEditSeason,
                        competitionStyle: Core.Models.CompetitionStyle.HeadToHead,
                        currentState,
                        location,
                        onStationSelect,
                        participant,
                    }}
                />
            )}
        </div>,
        showScore && (
            <span className="head-to-head-item__score">{formatForForfeit ? (hasForfeited ? 'L' : 'W') : score}</span>
        ),
        byeMatchup && !!id && <span className="head-to-head-item__score">Bye</span>,
    ].map((i, ix) => <React.Fragment key={ix}>{i}</React.Fragment>);

    return (
        <div
            className={classNames('head-to-head-item', {
                'head-to-head-item--emphasize': enableHighlight && isWinner,
                'head-to-head-item--emphasize-light': enableHighlight && isTie,
                'head-to-head-item--bye': !participantId,
                'head-to-head-item--my-participant': myParticipant,
                'head-to-head-item--forfeited': hasForfeited,
            })}
        >
            {parts}
        </div>
    );
};

export default HeadToHeadItem;

import React, { useMemo } from 'react';
import { upperFirst } from 'lodash';

import * as Core from '../../../core';
import FormField from '../../../components/formField';

interface GameRankingFieldProps {
    disabled?: boolean;
    game?: {
        rankings: {
            [key: string]: number;
        };
        rankingTerm: string;
        rankingType: Core.Models.RankingType;
    };
    value?: string;
}

const GameRankingField = ({ disabled, game, value }: GameRankingFieldProps) => {
    const rankings = useMemo(() => Core.Competition.getRankings(game), [game]);
    const existingRankingIsValid = useMemo(
        () => !!value && Core.Competition.isValidRanking(value, game),
        [value, game]
    );
    const rankingTerm = useMemo(() => upperFirst(game?.rankingTerm || 'rank'), [game?.rankingTerm]);

    if (game?.rankingType === Core.Models.RankingType.Numeric)
        return <FormField description={rankingTerm} disabled={disabled} name="value" type="number" />;

    return (
        <FormField component="select" description={`Select a ${rankingTerm}`} disabled={disabled} name="value">
            {!value && (
                <option value="" hidden disabled>
                    --
                </option>
            )}
            {!!value && !existingRankingIsValid && (
                <option value={value} disabled hidden>
                    {value}
                </option>
            )}
            {rankings.map((ranking: Core.Models.Ranking) => (
                <option value={ranking.key} key={ranking.key}>
                    {ranking.key}
                </option>
            ))}
        </FormField>
    );
};

export default GameRankingField;

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import * as Core from '../../../../core';
import { colorOptions, defaultDesignations, DesignationEditorMode, iconOptions } from './constants';
import useClickOutside from '../../../../hooks/clickOutside';

const getNewDesignation = () => ({
    color: colorOptions[Math.floor(Math.random() * colorOptions.length)].hex,
    iconName: iconOptions[Math.floor(Math.random() * iconOptions.length)],
    name: '',
});

export const useDesignationEditor = (mode: DesignationEditorMode, designation?: Core.Models.LeagueDesignation) => {
    const [colorPickerOpen, setColorPickerOpen] = useState(false);

    const defaultDesignation = useMemo(() => getNewDesignation(), []);
    const [designationColor, setDesignationColor] = useState<string>((designation ?? defaultDesignation).color);
    const [designationIconName, setDesignationIconName] = useState<string | undefined>(
        (designation ?? defaultDesignation).iconName
    );
    const [designationName, setDesignationName] = useState<string>((designation ?? defaultDesignation).name);

    const designationNamePlaceholder = useMemo(
        () => defaultDesignations[Math.floor(Math.random() * defaultDesignations.length)],
        []
    );

    const inputRef = useRef<HTMLInputElement>(null);
    const colorPickerRef = useRef<HTMLDivElement>(null);
    useClickOutside(colorPickerRef, () => setColorPickerOpen(false));

    useEffect(() => {
        !!inputRef.current && mode === DesignationEditorMode.Edit && inputRef.current.focus();
    }, [mode]);

    const resetDesignationValues = useCallback(
        ({ color, iconName, name }: Core.Models.LeagueDesignation) => {
            setDesignationColor(color);
            setDesignationIconName(iconName);
            setDesignationName(name);
        },
        [setDesignationColor, setDesignationIconName, setDesignationName]
    );

    return {
        colorPickerOpen,
        designationColor,
        designationIconName,
        designationName,
        designationNamePlaceholder,
        inputRef,
        resetDesignationValues,
        setColorPickerOpen,
        setDesignationColor,
        setDesignationIconName,
        setDesignationName,
    };
};

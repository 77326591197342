import React, { useState } from 'react';
import classNames from 'classnames';
import { usePopper } from 'react-popper';

import { Placement } from '@popperjs/core';
import { IHasChildren } from '../../layouts/types';

import './index.scss';

interface TooltipProps extends IHasChildren {
    className?: string;
    content: React.ReactNode;
    decorationStyle?: 'not-good' | 'ok' | 'very-bad';
    hideTooltip?: boolean;
    placement: Placement;
}

/**
 * @deprecated Use `overlays/tooltip` or `overlays/ppopover` instead
 */

const Tooltip = (props: TooltipProps) => {
    const { children, className, content, decorationStyle, hideTooltip, placement } = props;
    const [open, setOpen] = useState<boolean>(false);
    const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
    const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [
            { name: 'arrow', options: { element: arrowElement } },
            {
                name: 'offset',
                options: {
                    offset: [0, 10],
                },
            },
        ],
    });

    if (hideTooltip) return <>{children}</>;

    return (
        <div
            className={classNames(className, 'tooltip-container')}
            onClick={() => setOpen(true)}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
        >
            <div className={classNames('tooltip-child', decorationStyle)} ref={setReferenceElement}>
                {children}
            </div>
            <div
                className={classNames('tooltip', { 'tooltip--hidden': !open })}
                role="tooltip"
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
            >
                <div className="content">{content}</div>
                <div className="arrow" ref={setArrowElement} style={styles.arrow}></div>
            </div>
        </div>
    );
};

export default Tooltip;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { orderBy } from 'lodash';

import * as Core from '../../../core';
import { ListItem, TabItem } from './types';
import { WithLoadingProps } from '../../../components/withLoading';
import { LeagueService } from '../../../services/leagueService';

export const useLocations = ({ setError, setIsLoading }: WithLoadingProps) => {
    const [activeItemIndex, setActiveItemIndex] = useState<number | undefined>(undefined);
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

    const [tabs, setTabs] = useState<TabItem[]>([]);
    useEffect(() => {
        (async () => {
            try {
                const { locations } = await LeagueService.getLeagueLocationsWithStations();
                setTabs(
                    orderBy(
                        locations.reduce((tabAcc: TabItem[], l: Core.Models.LeagueLocation) => {
                            if (l.deleted) return tabAcc;
                            const stations = orderBy(
                                l.stations.reduce((listAcc: ListItem[], s: Core.Models.LeagueLocationStation) => {
                                    if (s.deleted) return listAcc;
                                    return [
                                        ...listAcc,
                                        {
                                            stationDescription: s.description,
                                            stationId: s.id,
                                            stationName: s.name,
                                            systemName: s.systemName,
                                        },
                                    ];
                                }, []),
                                (item: ListItem) => item.stationName
                            );
                            return [
                                ...tabAcc,
                                {
                                    locationDescription: l.description,
                                    locationId: l.id,
                                    locationName: l.name,
                                    stations,
                                },
                            ];
                        }, []),
                        (item: TabItem) => item.locationName
                    )
                );
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [setError, setIsLoading]);
    const activeItems = useMemo(() => tabs[activeTabIndex]?.stations ?? [], [activeTabIndex, tabs]);

    const addLocationTab = useCallback(
        (location: Core.Models.LeagueLocation) =>
            setTabs((previousTabs) => {
                const newTabs = orderBy(
                    [
                        ...previousTabs,
                        {
                            locationDescription: location.description,
                            locationId: location.id,
                            locationName: location.name,
                            stations: orderBy(
                                location.stations.map((s: Core.Models.LeagueLocationStation) => ({
                                    stationDescription: s.description,
                                    stationId: s.id,
                                    stationName: s.name,
                                    systemName: s.systemName,
                                })),
                                (item) => item.stationName
                            ),
                        },
                    ],
                    (item: TabItem) => item.locationName
                );
                setActiveTabIndex(newTabs.findIndex((t: TabItem) => t.locationId === location.id));
                return newTabs;
            }),
        []
    );

    const removeLocationTab = useCallback((id: string) => {
        setTabs((previousTabs) => previousTabs.filter((t: TabItem) => t.locationId !== id));
        setActiveTabIndex(0);
    }, []);

    const updateLocationTab = useCallback(
        (location: Core.Models.LeagueLocation) =>
            setTabs((previousTabs) =>
                previousTabs.reduce(
                    (acc: TabItem[], curr: TabItem) => [
                        ...acc,
                        curr.locationId === location.id
                            ? {
                                  ...curr,
                                  locationDescription: location.description,
                                  locationId: location.id,
                                  locationName: location.name,
                              }
                            : curr,
                    ],
                    []
                )
            ),
        []
    );

    const addStationItem = useCallback(
        (station: Core.Models.LeagueLocationStation) =>
            setTabs((previousTabs) =>
                previousTabs.reduce(
                    (acc: TabItem[], curr: TabItem, ii: number) => [
                        ...acc,
                        activeTabIndex === ii
                            ? {
                                  ...curr,
                                  stations: orderBy(
                                      [
                                          ...curr.stations,
                                          {
                                              stationDescription: station.description,
                                              stationId: station.id,
                                              stationName: station.name,
                                              systemName: station.systemName,
                                          },
                                      ],
                                      (item: ListItem) => item.stationName
                                  ),
                              }
                            : curr,
                    ],
                    []
                )
            ),
        [activeTabIndex]
    );

    const removeStationItem = useCallback(
        (id: string) => {
            setTabs((previousTabs) =>
                previousTabs.reduce(
                    (acc: TabItem[], curr: TabItem, ii: number) => [
                        ...acc,
                        activeTabIndex === ii
                            ? {
                                  ...curr,
                                  stations: curr.stations.filter((li: ListItem) => li.stationId !== id),
                              }
                            : curr,
                    ],
                    []
                )
            );
            setActiveItemIndex(undefined);
        },
        [activeTabIndex]
    );

    const updateStationItem = useCallback(
        (station: Core.Models.LeagueLocationStation) =>
            setTabs((previousTabs) =>
                previousTabs.reduce(
                    (tabAcc: TabItem[], tabCurr: TabItem, ii: number) => [
                        ...tabAcc,
                        activeTabIndex === ii
                            ? {
                                  ...tabCurr,
                                  stations: tabCurr.stations.reduce(
                                      (listAcc: ListItem[], listCurr: ListItem) => [
                                          ...listAcc,
                                          listCurr.stationId === station.id
                                              ? {
                                                    stationDescription: station.description,
                                                    stationId: station.id,
                                                    stationName: station.name,
                                                    systemName: station.systemName,
                                                }
                                              : listCurr,
                                      ],
                                      []
                                  ),
                              }
                            : tabCurr,
                    ],
                    []
                )
            ),
        [activeTabIndex]
    );

    return {
        activeItemIndex,
        activeItems,
        activeTabIndex,
        addLocationTab,
        addStationItem,
        removeLocationTab,
        removeStationItem,
        setActiveItemIndex,
        setActiveTabIndex,
        tabs,
        updateLocationTab,
        updateStationItem,
    };
};

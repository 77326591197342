import * as Core from '../core';

const generateLolTournamentCode = async (request: { matchGameId: string }): Promise<void> => {
    const route = Core.API.ServerRoute.forAction('integrations/lol/generate-tournament-code');
    return await Core.API.post(route, request);
};

const getChessMatchHistory = async (
    matchId: string,
    username: string,
    year: string,
    month: string
): Promise<Core.Models.ChessMatchSummary[]> => {
    const route = Core.API.ServerRoute.forAction(
        `integrations/chess/${matchId}/${username}/${year}/${month}/match-history`
    );
    return await Core.API.get(route);
};

const getValorantMatchHistory = async (matchId: string, puuid: string): Promise<Core.Models.ValorantMatchHistory[]> => {
    const route = Core.API.ServerRoute.forAction(`integrations/valorant/${matchId}/${puuid}/match-history`);
    return await Core.API.get(route);
};

const getValorantMatchSummary = async (
    matchId: string,
    valorantMatchId: string
): Promise<Core.Models.ValorantMatchSummary> => {
    const route = Core.API.ServerRoute.forAction(`integrations/valorant/${matchId}/${valorantMatchId}`);
    return await Core.API.get(route);
};

const IntegrationService = {
    generateLolTournamentCode,
    getChessMatchHistory,
    getValorantMatchHistory,
    getValorantMatchSummary,
};

export default IntegrationService;

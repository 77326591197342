import { MutableRefObject, useCallback, useEffect, useState } from 'react';

const useClickOutside = (ref: MutableRefObject<any>, onClickOutside: () => void) => {
    const [initialTarget, setInitialTarget] = useState<EventTarget | null>(null);

    const handleMouseDown = useCallback((event: MouseEvent) => {
        setInitialTarget(event.target);
    }, []);

    const handleMouseUp = useCallback(
        (event: MouseEvent) => {
            if (event.target === initialTarget && !ref.current?.contains(event.target)) {
                onClickOutside();
            }
        },
        [initialTarget, onClickOutside, ref]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [handleMouseDown, handleMouseUp]);
};

export default useClickOutside;

import React, { useRef, useState, useMemo, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { DropdownProps } from './Dropdown.types';
import useClickOutside from '../../../hooks/clickOutside';
import { IconButton } from '../../buttons-visuals';
import './Dropdown.scss';

const Dropdown = ({
    backgroundColor = 'theme',
    button,
    children,
    className,
    position,
    onClose,
    open = false,
    ...rest
}: DropdownProps) => {
    const [isOpen, setIsOpen] = useState(open);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
        onClose?.();
    };

    const handleToggle = () => {
        if (isOpen) {
            handleClose();
        } else {
            handleOpen();
        }
    };

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const dropdownContentClasses = useMemo(
        () =>
            classNames(
                'ds-dropdown__content',
                `ds-dropdown__content--${backgroundColor}`,
                `ds-dropdown__content--${position}`,
                {
                    'ds-dropdown__content--open': isOpen,
                }
            ),
        [backgroundColor, position, isOpen]
    );

    const modifiedButton = React.cloneElement(button, {
        'aria-expanded': isOpen,
        'aria-haspopup': true,
        onClick: (event: React.MouseEvent<any>) => {
            handleToggle();
            if (button.props.onClick) {
                button.props.onClick(event);
            }
        },
    });

    useClickOutside(dropdownRef, handleClose);

    return (
        <div className={classNames(className, 'ds-dropdown')} ref={dropdownRef} {...rest}>
            {modifiedButton}
            <div className={dropdownContentClasses}>
                {children}
                <IconButton
                    as="button"
                    buttonLabel="Close dialog"
                    buttonSize="medium"
                    className="ds-dropdown__content__close"
                    onClick={handleClose}
                    tabIndex={0}
                >
                    <FontAwesomeIcon icon={['fas', 'xmark']} />
                </IconButton>
            </div>
        </div>
    );
};

export default Dropdown;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconButton } from '../../../components/buttons-visuals';
import { Popover } from '../../../components/overlays';

import './index.scss';

interface ChessLinksTooltipProps {
    rosterSize: number;
}

const ChessLinksTooltip = ({ rosterSize }: ChessLinksTooltipProps): JSX.Element => (
    <Popover
        button={
            <IconButton as="button" buttonLabel="Challenge links more info" buttonSize="small">
                <FontAwesomeIcon icon={['fas', 'circle-info']} />
            </IconButton>
        }
        className="chess-links-tooltip ml"
        title="Configuration:"
    >
        {rosterSize === 1 ? (
            <ul className="configuration-list">
                <li>
                    Higher seed gets white the first game, then players alternate colors until there is a winner or all
                    games are played.
                </li>
                <li>Base time control is 10 minutes, with a 2 second increment (10+2).</li>
                <li>
                    If one or more tiebreaker games are needed:
                    <ul className="configuration-sub-list mt">
                        <li>
                            The first tiebreaker game will have a base time control of 5 minutes with a 2 second
                            increment (5+2). All subsequent tiebreaker games will have a base time control of 1 minute
                            with a 2 second increment (1+2).
                        </li>
                    </ul>
                </li>
            </ul>
        ) : rosterSize == 4 ? (
            <ul className="configuration-list">
                <li>Base time control is 5 minutes, with a 2 second increment (5+2).</li>
                <li>
                    If one or more tiebreaker games are needed:
                    <ul className="configuration-sub-list mt">
                        <li>
                            The first tiebreaker game will have a base time control of 3 minutes with a 2 second
                            increment (3+2). All subsequent tiebreaker games will have a base time control of 1 minute
                            with a 2 second increment (1+2).
                        </li>
                    </ul>
                </li>
            </ul>
        ) : (
            <ul className="configuration-list">
                <li>Base time control is 10 minutes, with a 2 second increment (10+2).</li>
                <li>
                    If one or more tiebreaker games are needed:
                    <ul className="configuration-sub-list mt">
                        <li>
                            The first tiebreaker game will have a base time control of 5 minutes with a 2 second
                            increment (5+2). All subsequent tiebreaker games will have a base time control of 1 minute
                            with a 2 second increment (1+2).
                        </li>
                    </ul>
                </li>
            </ul>
        )}
        <p>These options are currently static, but we plan to make these options configurable in the future.</p>
        <p>
            Note: All players from both teams will need to have their Chess.com handle added to their profile in order
            for links to be automatically generated.
        </p>
    </Popover>
);

export default ChessLinksTooltip;

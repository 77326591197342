import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { DebounceInput } from 'react-debounce-input';

import './Filter.scss';

interface FilterProps extends React.InputHTMLAttributes<HTMLInputElement> {
    debounceMs?: number;
    setValue: (value: string) => void;
    label: string;
    value: string;
}

const Filter = (props: FilterProps): JSX.Element => {
    const { className, debounceMs, setValue, id, label, ...rest } = props;

    return (
        <div className={classNames('ds-filter-input', className)}>
            <div className="ds-filter-input__wrapper">
                <DebounceInput
                    className={classNames('ds-filter-input__wrapper__input', className)}
                    debounceTimeout={debounceMs ?? 0}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
                    placeholder=" "
                    type="text"
                    {...rest}
                />
                <label htmlFor={id} className="ds-filter-input__wrapper__label">
                    {label}
                </label>
                <FontAwesomeIcon
                    className="ds-filter-input__wrapper__icon"
                    icon={['fas', 'magnifying-glass']}
                    size="1x"
                />
            </div>
        </div>
    );
};

export default Filter;

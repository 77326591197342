import { useCallback, useMemo } from 'react';
import { isNumber, some } from 'lodash';

import * as Core from '../../core';
import {
    useHasAnyLeagueMembershipRole,
    useLeagueConfiguration,
    useProfile,
    useUserPermissionService,
} from '../../hooks/store';

const useCanJoinSeason = () => {
    const profile = useProfile();
    const userPermissionService = useUserPermissionService();
    const leagueConfiguration = useLeagueConfiguration();
    const membersCanCreateTeams = useMemo(
        () => !!leagueConfiguration?.league?.membersCanCreateTeams,
        [leagueConfiguration?.league?.membersCanCreateTeams]
    );
    const isLeagueMember = useHasAnyLeagueMembershipRole(Core.Models.PermissionLevel.ListSpecific);

    const alreadyInSeason = useCallback(
        (season: Core.Models.Season) => {
            return !!profile?.activeSeasons.some((s: Core.Models.Season) => s.id === season.id);
        },
        [profile]
    );

    const canJoinSeason = useCallback(
        (season: Core.Models.Season) => {
            if (alreadyInSeason(season)) return false;

            // check if season has already started
            if (season.currentState !== Core.Models.CompetitionState.NotStarted) return false;

            // check if season has space
            if (
                (!!season.maxParticipants || season.maxParticipants === 0) &&
                season.participants.filter((p: Core.Models.Participant) => p.isParticipating).length >=
                    season.maxParticipants
            )
                return false;

            // check if members can create teams, and if they have teams already
            if (
                !membersCanCreateTeams &&
                !some(
                    profile?.teams,
                    (t: Core.Models.Team) =>
                        t.gameId === season.game?.id &&
                        userPermissionService.hasTeamAccess(Core.Models.PermissionLevel.Edit, t.id)
                )
            )
                return false;

            // check age
            if (isNumber(season.ageMax) && (!profile?.age || profile.age > season.ageMax)) return false;
            if (isNumber(season.ageMin) && (!profile?.age || profile.age < season.ageMin)) return false;

            // is logged in and has an organization role
            return !!profile?.organizationId;
        },
        [alreadyInSeason, membersCanCreateTeams, profile, userPermissionService]
    );

    const canRegisterForSeason = useCallback(
        (season: Core.Models.Season) => {
            // check if user is authenticated
            if (isLeagueMember) return false;

            // check if season has public registration enabled
            if (!season.enablePublicRegistration) return false;

            // check if season has already started
            if (season.currentState !== Core.Models.CompetitionState.NotStarted) return false;

            // check if season can accept new teams (if it doesn't accept free agents/joining teams, e.g. roster size of 1)
            const isFull =
                isNumber(season.maxParticipants) &&
                season.participants.filter((p: Core.Models.Participant) => p.isParticipating).length >=
                    season.maxParticipants;
            if (season.game.minimumSeats === 1 && (!membersCanCreateTeams || isFull)) return false;

            return true;
        },
        [isLeagueMember, membersCanCreateTeams]
    );

    return { alreadyInSeason, canJoinSeason, canRegisterForSeason };
};

export default useCanJoinSeason;

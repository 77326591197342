import * as React from 'react';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import { orderBy } from 'lodash';
import * as Yup from 'yup';

import * as Core from '../../core';
import GameRankingField from './gameRanking/gameRankingField';
import { Button } from '../../components/button';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';
import Loading from '../../components/loading';

interface AddGameRankingProps {
    games: Core.Models.Game[];
    onSubmit: (gameId: string, value: string) => Promise<void>;
}

interface AddNewRankingValues {
    gameId: string;
    value: string;
}

const schema = Yup.object().shape({
    gameId: Yup.string().required('Game is required.'),
    value: Yup.string().required('Rank is required.'),
});

const AddGameRanking: React.FunctionComponent<AddGameRankingProps> = (props) => {
    const { games, onSubmit } = props;

    const saveChanges = async (values: AddNewRankingValues, actions: FormikActions<AddNewRankingValues>) => {
        try {
            const { gameId, value } = values;
            await onSubmit(gameId, value);
        } catch (error) {
            const message = Core.API.getErrorMessage(error);
            actions.setStatus(message);
        } finally {
            actions.setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={{ gameId: '', value: '' }}
            onSubmit={saveChanges}
            validationSchema={schema}
            render={(props: FormikProps<AddNewRankingValues>) => {
                const selectedGame = games.find((g: Core.Models.Game) => g.id === props.values.gameId);
                return (
                    <Form>
                        <fieldset className="form-group">
                            <FormField component="select" name="gameId" description="Select a Game">
                                <option value="" hidden disabled>
                                    --
                                </option>
                                {orderBy(games, (g: Core.Models.Game) => g.name).map((game: Core.Models.Game) => (
                                    <option value={game.id} key={game.id}>
                                        {game.name}
                                    </option>
                                ))}
                            </FormField>
                            <GameRankingField disabled={!props.values.gameId} game={selectedGame} />
                        </fieldset>

                        {props.status && <InfoMessage message={props.status} type="error" />}
                        <InfoMessage filter={props.touched} message={props.errors} type="error" />

                        <fieldset className="form-group form-group--undecorated">
                            {props.isSubmitting && <Loading buttonLoader />}
                            <Button onClick={props.submitForm} disabled={props.isSubmitting} round wide>
                                Save
                            </Button>
                        </fieldset>
                    </Form>
                );
            }}
        />
    );
};

export default AddGameRanking;

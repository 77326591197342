import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteComponentProps, Link } from 'react-router-dom';

import * as Core from '../../../core';
import InfoMessage from '../../../components/infoMessage';
import withLoading, { WithLoadingProps } from '../../../components/withLoading';
import useAbortablePromise from '../../../hooks/abortablePromise';
import { useAlternateSeasonName } from '../../../hooks/store';
import { SeasonService } from '../../../services/seasonService';
import SeasonParticipants from '../seasonParticipants';

import './index.scss';

interface EditSeasonParticipantsProps extends RouteComponentProps<{ seasonId: string }>, WithLoadingProps {}

export const EditSeasonParticipants = (props: EditSeasonParticipantsProps) => {
    const {
        match: {
            params: { seasonId },
        },
        setError,
        setIsLoading,
    } = props;
    const [season, setSeason] = useState<Core.Models.Season | undefined>(undefined);
    const [seasonEligibleTeams, setSeasonEligibleTeams] = useState<Core.Models.SeasonEligibleTeam[]>([]);
    const seasonAlternateName = useAlternateSeasonName();

    const load = useCallback(async () => {
        try {
            const [seasonResponse, seasonEligibleTeamsResponse] = await Promise.all([
                SeasonService.getById(seasonId),
                SeasonService.getSeasonEligibleTeams(seasonId),
            ]);
            setSeason(seasonResponse);
            setSeasonEligibleTeams(seasonEligibleTeamsResponse);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [seasonId, setError, setIsLoading]);
    useAbortablePromise(load);

    if (!season) return <></>;
    return (
        <div className="page edit-season-participants-page">
            <div className="edit-season-participants">
                <h2 className="edit-season-participants__page-header">
                    {!!season.payable ? 'Review' : 'Edit'} participants for {season.name}
                </h2>

                {season.currentState !== Core.Models.CompetitionState.NotStarted && (
                    <div>
                        <InfoMessage
                            message={`Cannot edit teams within any ${seasonAlternateName} that has been started`}
                            type="error"
                        />
                        <Link to={`/seasons/${season.id}`}>Return to {season.name}</Link>
                    </div>
                )}
                {season.currentState === Core.Models.CompetitionState.NotStarted && (
                    <>
                        <div className="edit-season-participants__page-sub-header">
                            <div className="edit-season-participants__instructions mb2x">
                                {!!season.payable ? (
                                    <>
                                        Teams marked with <FontAwesomeIcon icon={['fas', 'square-check']} /> have joined
                                        this {seasonAlternateName.toLowerCase()} and have paid all applicable entry
                                        fees.
                                    </>
                                ) : (
                                    <>
                                        Please select the teams that you would like to participate in this{' '}
                                        {seasonAlternateName.toLowerCase()}.
                                    </>
                                )}
                            </div>
                        </div>
                        <SeasonParticipants
                            season={season}
                            seasonTeams={seasonEligibleTeams}
                            showControls={!season.payable}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default withLoading(EditSeasonParticipants, {
    errorDisplayPageProps: { fullPage: true },
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

import React from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import * as Core from '../../core';
import { RadiusSizes } from '@leaguespot/platform-js/src/models';
import { MenuProps } from '../../components/menu';
import { getTextColor } from '../themeContainer';

import './index.scss';

interface TeamDesignationProps {
    designation?: Core.Models.LeagueDesignation;
    radius?: Core.Models.RadiusSizes;
    menu?: React.ComponentElement<MenuProps, any>;
    inline?: boolean
}

const TeamDesignation = ({ designation, menu, radius = RadiusSizes.Medium, inline }: TeamDesignationProps) => {
    const { color, iconName, name } = designation ?? {
        color: '#00000000', // transparent black, a value is required for hexRgb
        name: '',
    };
    return (
        <div
            className={classNames({'disp-flex': !inline}, 'align-center team-designation', getTextColor(color), `radius-${radius}`)}
            style={{ backgroundColor: color, borderTopLeftRadius: '', display: !!inline ? 'inline-block' : '', marginLeft : !!inline ? 5 : 0 }}
        >
            {!!iconName && iconName !== 'ban' && (
                <FontAwesomeIcon icon={['fas', iconName as IconName]} size="sm" className="mr" />
            )}
            <span className="weight-700">{name}</span>
            {menu}
        </div>
    );
};

export default TeamDesignation;

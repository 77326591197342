import * as React from 'react';

import { IHasChildren } from './types';
import PoweredByLeagueSpot from '../components/footer/poweredByLeagueSpot';

import './widgetLayout.scss';

interface WidgetLayoutProps extends IHasChildren {}

const WidgetLayout = (props: WidgetLayoutProps): JSX.Element => {
    const { children } = props;

    return (
        <div className="widget-layout">
            {children}
            <footer className="footer">
                <PoweredByLeagueSpot />
            </footer>
        </div>
    );
};

export default WidgetLayout;

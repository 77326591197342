import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Formik, Form, FormikActions, FormikProps } from 'formik';
import { orderBy, upperFirst } from 'lodash';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import * as Core from '../../core';
import ErrorIcon from '../../assets/images/icon-error.svg';
import FormField from '../../components/formField';
import Markdown from '../../components/markdown';
import { useAlternateSeasonName, useHasLeagueAccess } from '../../hooks/store';
import { TeamService } from '../../services/teamService';
import { HollowButton, SolidButton } from '../buttons-visuals';
import InfoMessage from '../infoMessage';
import { Select } from '../inputs';
import ReviewPayable from '../payments/reviewPayable';
import withLoading, { WithLoadingProps } from '../withLoading';

interface SetTeamSeasonFormProps extends WithLoadingProps {
    filterOnSeasonId?: string;
    fulfillments: Core.Models.PayableFulfillment[];
    initialValues?: Partial<SetTeamSeasonFormValues>;
    leagueDesignationId?: string;
    onAddFulfillment: (fulfillment: Core.Models.PayableFulfillment) => void;
    onNotParticipateInSeason: (participantSeason: Core.Models.ParticipantSeason) => void;
    onRemoveSeason: () => Promise<void>;
    onSetSeason: (
        values: SetTeamSeasonFormValues,
        teamMemberRankings: Core.Models.TeamMemberRanking[]
    ) => Promise<void>;
    teamId: string;
}

export interface SetTeamSeasonFormValues {
    agreedToSeasonTerms: boolean;
    seasonId: string;
}

const SetTeamSeasonForm = ({
    filterOnSeasonId,
    fulfillments,
    initialValues,
    leagueDesignationId,
    onAddFulfillment,
    onNotParticipateInSeason,
    onRemoveSeason,
    onSetSeason,
    setError,
    setIsLoading,
    teamId,
}: SetTeamSeasonFormProps): JSX.Element => {
    const seasonAlternateName = useAlternateSeasonName();

    const [teamMemberRankings, setTeamMemberRankings] = React.useState<Core.Models.TeamMemberRanking[]>([]);
    const canEditLeague = useHasLeagueAccess(Core.Models.PermissionLevel.Edit); // Host+

    const [eligibleSeasonsResult, setEligibleSeasonsResult] = useState<
        Core.Models.TeamEligibleSeasonResult | undefined
    >(undefined);

    useEffect(() => {
        (async () => {
            try {
                const data = await TeamService.getEligibleSeasons(teamId);
                if (filterOnSeasonId) {
                    data.eligibleSeasons = data.eligibleSeasons.filter(
                        (season: Core.Models.Season) => season.id === filterOnSeasonId
                    );
                }
                setEligibleSeasonsResult(data);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [filterOnSeasonId, setError, setIsLoading, teamId]);

    const onRankingChange = (rankingValue: string, userId?: string) => {
        var teamMemberRanking = teamMemberRankings.filter(
            (tmr: Core.Models.TeamMemberRanking) => tmr.userId === userId
        )[0];
        if (!teamMemberRanking) return;

        teamMemberRanking.ranking = rankingValue;
        setTeamMemberRankings([...teamMemberRankings]);
    };

    const onSeasonChange = (seasonId: string) => {
        if (!eligibleSeasonsResult) return;
        const selectedSeason = eligibleSeasonsResult.eligibleSeasons.find((s: Core.Models.Season) => s.id === seasonId);

        if (selectedSeason?.requireGameRanks) {
            const newTeamMemberRankings = eligibleSeasonsResult.members
                .filter((member: Core.Models.UserWithRankings) => member.roleId !== Core.Models.TeamRoleId.Coach)
                .map((member: Core.Models.UserWithRankings) => {
                    const userGameRanking = member.rankings.filter(
                        (r: Core.Models.UserGameMetadata) =>
                            r.type === Core.Models.UserGameMetadataType.Ranking && r.gameId === selectedSeason.game.id
                    )[0];
                    const ranking = userGameRanking?.value || '';
                    return { userId: member.userId, ranking };
                });

            setTeamMemberRankings(newTeamMemberRankings);
        }
    };

    if (!eligibleSeasonsResult) return <></>;
    if (!!eligibleSeasonsResult.activeParticipantSeasons && eligibleSeasonsResult.activeParticipantSeasons.length > 0) {
        const canLeaveSeason =
            eligibleSeasonsResult.activeParticipantSeasons.filter(
                (activeSeason: Core.Models.ParticipantSeason) => !activeSeason.canLeaveSeason
            ).length <= 0;

        return (
            <div className="set-team-season__message">
                {!canLeaveSeason && <>You cannot change {seasonAlternateName.toLowerCase()}s at this time. </>}
                <span>
                    This team is a member of the following active {seasonAlternateName.toLowerCase()}s:
                    <ul className="set-team-season__active-seasons">
                        {eligibleSeasonsResult.activeParticipantSeasons.map((i, ix) => (
                            <li key={ix} className="set-team-season__active-season">
                                <div className="set-team-season__active-season-name">
                                    <Link to={`/seasons/${i.season.id}`}>{i.season.name}</Link>
                                </div>
                                {(canLeaveSeason || canEditLeague) && (
                                    <div className="set-team-season__active-season-buttons">
                                        <SolidButton
                                            as="button"
                                            color="destructive"
                                            onClick={() => onNotParticipateInSeason(i)}
                                        >
                                            Leave {seasonAlternateName.toLowerCase()}(s)
                                        </SolidButton>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </span>
            </div>
        );
    }

    if (eligibleSeasonsResult.eligibleSeasons.length <= 0) {
        return (
            <div className="set-team-season__message">
                There are no {pluralize(seasonAlternateName.toLowerCase())} this team can be added to. For more
                information, <Link to="/contact">contact your league host.</Link>
            </div>
        );
    }

    return (
        <Formik<SetTeamSeasonFormValues>
            initialValues={Object.assign(
                {
                    /** anything not specified here won't show an error message after an attempted submit */
                    agreedToSeasonTerms: false,
                    seasonId:
                        eligibleSeasonsResult.eligibleSeasons.length === 1
                            ? eligibleSeasonsResult.eligibleSeasons[0].id
                            : '',
                },
                initialValues || {}
            )}
            validationSchema={Yup.object().shape({
                agreedToSeasonTerms: Yup.boolean(),
                seasonId: Yup.string().required(`${seasonAlternateName} is required`),
            })}
            onSubmit={async (values: SetTeamSeasonFormValues, actions: FormikActions<SetTeamSeasonFormValues>) => {
                actions.setStatus(undefined);
                try {
                    const selectedSeason = eligibleSeasonsResult.eligibleSeasons.find(
                        (s: Core.Models.Season) => s.id === values.seasonId
                    );
                    if (!!selectedSeason?.seasonTerms && !values.agreedToSeasonTerms && !canEditLeague) {
                        actions.setStatus(
                            `Your consent to this ${seasonAlternateName.toLowerCase()}'s terms is required.`
                        );
                        actions.setSubmitting(false);
                        return;
                    }
                    await onSetSeason(values, teamMemberRankings);
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(formProps: FormikProps<SetTeamSeasonFormValues>) => {
                const remove = async () => {
                    formProps.setStatus(undefined);
                    formProps.setSubmitting(true);
                    try {
                        await onRemoveSeason();
                    } catch (e) {
                        const message = Core.API.getErrorMessage(e);
                        formProps.setStatus(message);
                    } finally {
                        formProps.setSubmitting(false);
                    }
                };
                const removeButton = initialValues?.seasonId && (
                    <HollowButton as="button" color="destructive" onClick={remove} pending={formProps.isSubmitting}>
                        Leave {seasonAlternateName.toLowerCase()}
                    </HollowButton>
                );
                const selectedSeason = eligibleSeasonsResult.eligibleSeasons.find(
                    (s: Core.Models.Season) => s.id === formProps.values.seasonId
                );
                const payable = selectedSeason?.payable;
                const hasFulfilledPayable =
                    !!payable &&
                    !!fulfillments.find(
                        (fulfillment: Core.Models.PayableFulfillment) =>
                            fulfillment.complete && !fulfillment.refunded && fulfillment.payableId === payable.id
                    );
                const canBypassPayable = !!payable?.exemptLeagueDesignations?.some(
                    (ld: Core.Models.LeagueDesignation) => leagueDesignationId === ld.id
                );
                const requireHandleForCheckin = !!selectedSeason?.requireHandleForCheckin;
                const gameHandleSourceName = selectedSeason?.game.gameHandleSource?.name;
                const gameRankings = Core.Competition.getRankings(selectedSeason?.game);
                const isMissingMembers =
                    !!selectedSeason?.enforceMinimumRoster &&
                    eligibleSeasonsResult.members.filter(
                        (m: Core.Models.UserWithRankings) => m.roleId !== Core.Models.TeamRoleId.Coach
                    ).length < selectedSeason.game.minimumSeats;
                const isMissingRankings =
                    !!selectedSeason?.requireGameRanks &&
                    teamMemberRankings.some(
                        (member: Core.Models.TeamMemberRanking) =>
                            !member.userId ||
                            !member.ranking ||
                            !Core.Competition.isValidRanking(member.ranking, selectedSeason.game)
                    );

                if (
                    !!formProps.initialValues.seasonId &&
                    eligibleSeasonsResult.members.filter(
                        (member: Core.Models.UserWithRankings) => member.roleId !== Core.Models.TeamRoleId.Coach
                    ).length > 0 &&
                    teamMemberRankings.length === 0
                ) {
                    onSeasonChange(formProps.initialValues.seasonId);
                }

                return (
                    <Form className="form">
                        <fieldset className="form-group">
                            <FormField
                                component="select"
                                name="seasonId"
                                description={`Choose a ${seasonAlternateName.toLowerCase()}`}
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onSeasonChange(e.target.value)}
                                disabled={eligibleSeasonsResult.eligibleSeasons.length === 1}
                            >
                                {eligibleSeasonsResult.eligibleSeasons.length > 1 && (
                                    <>
                                        <option value="" hidden disabled>
                                            --
                                        </option>
                                        {orderBy(
                                            eligibleSeasonsResult.eligibleSeasons,
                                            (season: Core.Models.Season) => season.name
                                        ).map((season: Core.Models.Season) => (
                                            <option key={season.id} value={season.id}>
                                                {season.name}
                                            </option>
                                        ))}
                                    </>
                                )}
                                {eligibleSeasonsResult.eligibleSeasons.length === 1 && (
                                    <>
                                        <option value={eligibleSeasonsResult.eligibleSeasons[0].id} disabled>
                                            {eligibleSeasonsResult.eligibleSeasons[0].name}
                                        </option>
                                    </>
                                )}
                            </FormField>
                        </fieldset>

                        {!!requireHandleForCheckin && !!gameHandleSourceName && (
                            <div className="set-team-season__require-message">
                                Note: Team members are required to submit their {gameHandleSourceName} before they can
                                participate in any events.
                            </div>
                        )}

                        {!!selectedSeason?.requireGameRanks && (
                            <>
                                <div className="set-team-season__require-message">
                                    Note: All team members are required to submit their {selectedSeason.game.name}{' '}
                                    {selectedSeason.game.rankingTerm} before this team can join this{' '}
                                    {seasonAlternateName.toLowerCase()}.
                                </div>
                                <div className="set-team-season__members-list">
                                    {eligibleSeasonsResult.members.filter(
                                        (member: Core.Models.UserWithRankings) =>
                                            member.roleId !== Core.Models.TeamRoleId.Coach
                                    ).length === 0 ? (
                                        <div className="set-team-season__members-list--empty">
                                            There are no users on this team. Please ensure that any users added to this
                                            team have their {selectedSeason.game.name} {selectedSeason.game.rankingTerm}{' '}
                                            added.
                                        </div>
                                    ) : (
                                        <>
                                            <div className="set-team-season__members-list__header">
                                                <div>Name</div>
                                                <div>{upperFirst(selectedSeason.game.rankingTerm)}</div>
                                            </div>
                                            {eligibleSeasonsResult.members
                                                .filter(
                                                    (member: Core.Models.UserWithRankings) =>
                                                        member.roleId !== Core.Models.TeamRoleId.Coach
                                                )
                                                .map((member: Core.Models.UserWithRankings, index: number) => {
                                                    const ranking = teamMemberRankings.filter(
                                                        (tmr: Core.Models.TeamMemberRanking) =>
                                                            tmr.userId === member.userId
                                                    )[0]?.ranking;
                                                    const existingRankingIsValid =
                                                        !!ranking &&
                                                        Core.Competition.isValidRanking(ranking, selectedSeason.game);
                                                    return member.userId ? (
                                                        <div
                                                            key={index}
                                                            className="set-team-season__members-list__item"
                                                        >
                                                            <div className="member-name">
                                                                {Core.Identity.renderMemberName(member)}
                                                            </div>
                                                            <div>
                                                                {selectedSeason.game.rankingType ===
                                                                Core.Models.RankingType.Numeric ? (
                                                                    <input
                                                                        className={classNames('rankings-number-input', {
                                                                            'rankings-number-input--required':
                                                                                !ranking || !existingRankingIsValid,
                                                                        })}
                                                                        onChange={(
                                                                            e: React.ChangeEvent<HTMLInputElement>
                                                                        ) =>
                                                                            onRankingChange(
                                                                                e.target.value,
                                                                                member.userId
                                                                            )
                                                                        }
                                                                        type="number"
                                                                        value={ranking}
                                                                    />
                                                                ) : (
                                                                    <Select
                                                                        className="mb0"
                                                                        containerClassName={classNames(
                                                                            'rankings-select',
                                                                            {
                                                                                'rankings-select--required':
                                                                                    !ranking || !existingRankingIsValid,
                                                                            }
                                                                        )}
                                                                        onChange={(
                                                                            e: React.ChangeEvent<HTMLSelectElement>
                                                                        ) =>
                                                                            onRankingChange(
                                                                                e.target.value,
                                                                                member.userId
                                                                            )
                                                                        }
                                                                        label={`Select ${selectedSeason.game.rankingTerm}`}
                                                                        value={ranking}
                                                                    >
                                                                        {!!ranking && !existingRankingIsValid ? (
                                                                            <option value={ranking}>{ranking}</option>
                                                                        ) : (
                                                                            <option value="" hidden disabled>
                                                                                --
                                                                            </option>
                                                                        )}
                                                                        {gameRankings.map(
                                                                            (ranking: Core.Models.Ranking) => (
                                                                                <option
                                                                                    value={ranking.key}
                                                                                    key={ranking.key}
                                                                                >
                                                                                    {ranking.key}
                                                                                </option>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            key={index}
                                                            className="set-team-season__members-list__item"
                                                        >
                                                            <div>Unregistered User</div>
                                                            <img src={ErrorIcon} alt="error" />
                                                        </div>
                                                    );
                                                })}
                                        </>
                                    )}
                                </div>
                            </>
                        )}

                        {!!payable && (
                            <ReviewPayable
                                {...{
                                    canBypassPayable,
                                    hasFulfilledPayable,
                                    onAddFulfillment,
                                    payable,
                                    teamId,
                                }}
                            />
                        )}

                        {!!selectedSeason?.seasonTerms && (
                            <div className="set-team-season__terms">
                                <h4>{seasonAlternateName} Terms</h4>
                                <Markdown source={selectedSeason.seasonTerms} />
                                <fieldset className="form-group form-group--undecorated">
                                    <FormField
                                        type="checkbox"
                                        name="agreedToSeasonTerms"
                                        description={`I agree to the terms of this ${seasonAlternateName.toLowerCase()}.`}
                                    />
                                </fieldset>
                            </div>
                        )}

                        {!!selectedSeason && isMissingRankings && (
                            <InfoMessage
                                message={`All members of this team must be registered and have their ${
                                    selectedSeason.game.name
                                } ${
                                    selectedSeason.game.rankingTerm
                                } entered to join this ${seasonAlternateName.toLowerCase()}.`}
                                type="error"
                            />
                        )}

                        {!!selectedSeason && isMissingMembers && (
                            <InfoMessage
                                message={`This ${seasonAlternateName.toLowerCase()} requires a minimum roster of ${
                                    selectedSeason.game.minimumSeats
                                } registered users`}
                                type="error"
                            />
                        )}

                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                        <InfoMessage message={formProps.errors} type="error" filter={formProps.touched} />

                        {!!formProps.values.seasonId && (!payable || hasFulfilledPayable || canBypassPayable) && (
                            <fieldset className="form-group form-group--undecorated">
                                <SolidButton
                                    as="button"
                                    disabled={
                                        (!!formProps.values.seasonId &&
                                            (formProps.isSubmitting ||
                                                (!!payable && !hasFulfilledPayable && !canBypassPayable))) ||
                                        isMissingRankings ||
                                        isMissingMembers
                                    }
                                    layout="full"
                                    onClick={formProps.submitForm}
                                    pending={formProps.isSubmitting}
                                >
                                    Join {seasonAlternateName.toLowerCase()}
                                </SolidButton>
                            </fieldset>
                        )}

                        {removeButton && (
                            <fieldset className="form-group form-group--undecorated">{removeButton}</fieldset>
                        )}
                    </Form>
                );
            }}
        />
    );
};

export default withLoading(SetTeamSeasonForm);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { orderBy } from 'lodash';

import * as Core from '../../core';
import Group from './group';
import PreScheduledDescription from './preScheduledDescription';
import { Select } from '../../components/inputs';
import Loading from '../../components/loading';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { StageService } from '../../services/stageService';

import './groups.scss';

interface StageGroupsProps extends WithLoadingProps {
    locations: Core.Models.LeagueLocation[];
    matchWord: string;
    stage: Core.Models.Stage;
    tennisStyle: boolean;
}

const StageGroups = ({ locations, matchWord, setError, setIsLoading, stage, tennisStyle }: StageGroupsProps) => {
    const [groups, setGroups] = useState<Core.Models.Group[]>([]);
    const [currentGroupId, setCurrentGroupId] = useState<string>('');

    const load = useCallback(async () => {
        try {
            const response = await StageService.getGroupsWithMatches(stage.id);
            setGroups(response);
            if (!currentGroupId) setCurrentGroupId(response[0]?.id || '');
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [currentGroupId, setError, setIsLoading, stage.id]);

    useEffect(() => {
        (async () => {
            await load();
        })();
    }, [load]);

    const orderedGroups = useMemo(
        () => orderBy(groups, [(g: Core.Models.Group) => g.sortOrder, (g: Core.Models.Group) => g.id]),
        [groups]
    );
    const group = useMemo(
        () => orderedGroups.find((g: Core.Models.Group) => g.id === currentGroupId),
        [currentGroupId, orderedGroups]
    );
    const rounds = useMemo(
        () =>
            stage.rounds.filter(
                (r: Core.Models.Round) => group?.matches?.some((m: Core.Models.Match) => m.roundId === r.id)
            ),
        [group?.matches, stage.rounds]
    );

    const groupSelector = (
        <Select
            className="mb0"
            containerClassName="groups__selector"
            label="Choose a group"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setCurrentGroupId(e.target.value)}
            value={currentGroupId}
        >
            <option hidden disabled value="">
                Choose a group
            </option>
            {orderedGroups.map((g: Core.Models.Group) => (
                <option key={g.id} value={g.id}>
                    {g.name}
                </option>
            ))}
        </Select>
    );

    if (orderedGroups.length === 0) return <PreScheduledDescription value={stage.settings.preScheduledDescription} />;
    if (!group || !group.matches) return <Loading blockItem />;
    return <Group {...{ group, groupSelector, locations, matchWord, reloadData: load, rounds, stage, tennisStyle }} />;
};

export default withLoading(StageGroups, {
    loadingProps: { blockItem: true },
});

import React, { Fragment, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { upperFirst } from 'lodash';
import { Link } from 'react-router-dom';

import * as Core from '../../../core';
import { Avatar } from '../../../components/buttons-visuals';
import { Checkbox } from '../../../components/inputs/checkbox';
import { ToolTip } from '../../../components/overlays';
import { useAlternateSeasonName } from '../../../hooks/store';

import './index.scss';

function getIsCaptain(member: Partial<Core.Models.MatchParticipantUser>) {
    return member.teamRoleId === Core.Models.TeamRoleId.Captain;
}

function getIsCoach(member: Partial<Core.Models.MatchParticipantUser>) {
    return member.teamRoleId === Core.Models.TeamRoleId.Coach;
}

function getIsIneligible(member: Partial<Core.Models.MatchParticipantUser>) {
    return member.isEligible === false;
}

interface MatchUserProps {
    canEdit?: boolean;
    checkedIn?: boolean;
    clickable?: boolean | undefined;
    game?: {
        gameHandleSource?: Core.Models.GameHandleSource;
        name: string;
        rankings: { [key: string]: number };
        rankingTerm: string;
        rankingType: Core.Models.RankingType;
    };
    handleUserClick?: (userId: string, checked: boolean) => void;
    hideRequirements?: boolean;
    requireGameRanks?: boolean;
    requireHandleForCheckin?: boolean;
    selected?: boolean;
    showCheckbox?: boolean;
    user: Core.Models.MatchParticipantUser;
}

const MatchUser = (props: MatchUserProps): JSX.Element => {
    const {
        canEdit,
        checkedIn,
        clickable,
        game,
        handleUserClick,
        hideRequirements,
        requireGameRanks,
        requireHandleForCheckin,
        selected,
        showCheckbox,
        user,
    } = props;

    const {
        avatarUrl,
        discordHandle,
        firstName,
        gameHandle,
        gameRanking,
        gamerHandle,
        isUnder13,
        lastName,
        pronouns,
        userId,
    } = user;

    const isCaptain = getIsCaptain(user);
    const isCoach = getIsCoach(user);
    const isIneligible = getIsIneligible(user);

    const alternateSeasonName = useAlternateSeasonName();

    const actionVerb = useMemo(() => (!!gameHandle?.oAuthProviderId ? 'link' : 'enter'), [gameHandle]);
    const showCheckin = canEdit && !checkedIn && showCheckbox;

    const onUserClick = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (clickable) {
                !!handleUserClick && !!user.userId && handleUserClick(user.userId, event.target.checked);
            }
        },
        [clickable, handleUserClick, user.userId]
    );

    const requirementsList = useMemo(
        () =>
            user.competitionReadyPendingItems?.reduce((acc: string[], curr: Core.Models.CompetitionReadyItemType) => {
                // Only declaring cases that are *possibly* critical
                switch (curr) {
                    case Core.Models.CompetitionReadyItemType.AddHandle:
                        return [...acc, 'Must add in-game name'];
                    case Core.Models.CompetitionReadyItemType.LinkDiscord:
                        return [...acc, 'Must link Discord account'];
                    case Core.Models.CompetitionReadyItemType.LinkHandle:
                        return [...acc, 'Must link in-game account'];
                    case Core.Models.CompetitionReadyItemType.VerifyEmail:
                        return [...acc, 'Must verify email'];
                    case Core.Models.CompetitionReadyItemType.VerifyIdentity:
                        return [...acc, 'Must verify identity'];
                    case Core.Models.CompetitionReadyItemType.VerifyParentConsent:
                        return [...acc, 'Must receive parent consent'];
                    case Core.Models.CompetitionReadyItemType.OutstandingLeagueFee:
                        return [...acc, 'Must pay league fee'];
                    default:
                        return acc;
                }
            }, [] as string[]) ?? [],
        [user.competitionReadyPendingItems]
    );

    return (
        <div
            className={classNames('match-user', {
                'match-user--checkin': showCheckin,
            })}
        >
            <div className="match-user__details">
                <Avatar
                    captain={isCaptain}
                    className="match-user__details__avatar"
                    coach={isCoach}
                    fallback="user"
                    ineligible={isIneligible}
                    isUnder13={isUnder13}
                    size="small"
                    src={avatarUrl}
                />
                <div className="match-user__details__user">
                    {!!gameHandle && !!game?.gameHandleSource && (
                        <div className="match-user__details__user__handle">
                            {!!game.gameHandleSource.iconSvgUrl && (
                                <div className="match-user__details__user__handle-image mr">
                                    <img src={game.gameHandleSource.iconSvgUrl} alt={game.gameHandleSource.name} />
                                </div>
                            )}
                            <ToolTip
                                hide={!gameHandle.requiresRelink && !(requireHandleForCheckin && !gameHandle?.handle)}
                                trigger={
                                    <div>
                                        <span
                                            className={classNames(
                                                'decoration',
                                                `decoration--${gameHandle.requiresRelink ? 'not-good' : 'very-bad'}`
                                            )}
                                        >
                                            {gameHandle.handle || <i>No {game.gameHandleSource.name}</i>}
                                        </span>
                                        {requireHandleForCheckin && !gameHandle?.handle && (
                                            <FontAwesomeIcon
                                                className="color-error ml"
                                                icon={['fas', 'circle-exclamation']}
                                                size="1x"
                                            />
                                        )}
                                        {gameHandle.requiresRelink && (
                                            <FontAwesomeIcon
                                                className="color-warning ml"
                                                icon={['fas', 'exclamation-triangle']}
                                                size="1x"
                                            />
                                        )}
                                        {!!gameHandle.oAuthProviderId &&
                                            !gameHandle.requiresRelink &&
                                            !!gameHandle?.handle && (
                                                <FontAwesomeIcon
                                                    className="color-success ml"
                                                    icon={['fas', 'circle-check']}
                                                    size="1x"
                                                />
                                            )}
                                    </div>
                                }
                            >
                                <p>
                                    {gameHandle.requiresRelink ? (
                                        <>
                                            This user's {gameHandle.handleSourceName} account needs to be relinked in
                                            order to verify their in-game identity. Only this user can relink the
                                            account from their profile.
                                        </>
                                    ) : (
                                        <>
                                            This {alternateSeasonName.toLowerCase()} requires all participants to{' '}
                                            {actionVerb} their in-game names in order to be checked in. Only this user
                                            can {actionVerb} the account from their profile.
                                        </>
                                    )}
                                </p>
                            </ToolTip>
                        </div>
                    )}
                    {discordHandle && (
                        <div className="match-user__details__user__handle">
                            <div className="match-user__details__user__handle-image mr">
                                <FontAwesomeIcon icon={['fab', 'discord']} size="xs" className="color-white" />
                            </div>
                            {discordHandle}
                        </div>
                    )}
                    <div className="match-user__details__user__handle">
                        <div className="match-user__details__user__handle-image mr">
                            {/* wrapping tooltip with condition here: hideTooltip prop is incompatible with <img /> child  */}
                            {!hideRequirements && requirementsList.length > 0 ? (
                                <ToolTip
                                    trigger={
                                        <FontAwesomeIcon
                                            icon={['fas', 'circle-exclamation']}
                                            size="1x"
                                            className="color-error"
                                        />
                                    }
                                >
                                    <p>
                                        This user's account does not meet this league's competition requirements:
                                        <br />
                                        {requirementsList.map((r: string) => (
                                            <Fragment key={r}>
                                                {`- ${r}`}
                                                <br />
                                            </Fragment>
                                        ))}
                                    </p>
                                </ToolTip>
                            ) : (
                                <FontAwesomeIcon icon={['fas', 'circle-check']} size="1x" className="color-success" />
                            )}
                        </div>

                        {!!userId ? (
                            <Link className="match-user__name" to={`/users/${userId}`}>
                                {gamerHandle ??
                                    Core.Identity.renderMemberName({ firstName, lastName, pronouns }) ??
                                    'Anonymous'}
                            </Link>
                        ) : (
                            <span className="match-user__name">Anonymous</span>
                        )}
                    </div>
                    {!!game && !!Core.Competition.hasRankings(game) && (
                        <div className="match-user__details__user__handle">
                            {!!gameRanking && Core.Competition.isValidRanking(gameRanking.value, gameRanking.game) ? (
                                <span>
                                    {upperFirst(game.rankingTerm)}: {gameRanking.value}
                                </span>
                            ) : (
                                <ToolTip
                                    hide={!requireGameRanks}
                                    trigger={
                                        <span className="decoration decoration--not-good">
                                            <i>No {game.rankingTerm} provided</i>
                                        </span>
                                    }
                                >
                                    <p>
                                        {game.name} {game.rankingTerm} is required for competitors in this{' '}
                                        {alternateSeasonName.toLowerCase()}.
                                    </p>
                                </ToolTip>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {showCheckin && (
                <div className="match-user__checkin">
                    <Checkbox
                        checked={selected}
                        className="mb0"
                        disabled={!clickable}
                        label="check in"
                        name={Core.Identity.renderMemberName(user)}
                        onChange={onUserClick}
                    />
                </div>
            )}
        </div>
    );
};

export default MatchUser;

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Core from '../../core';
import { IconButton } from '../../components/buttons-visuals';
import { CopyTextField } from '../../components/inputs';
import Modal from '../../components/modal';

interface CalendarProps {
    entity: Core.Models.NamedObject;
    entityType: 'organization' | 'team';
}

const Calendar = ({ entity, entityType }: CalendarProps) => {
    const [isAdding, setIsAdding] = useState<boolean>(false);

    return (
        <>
            <div style={{ display: 'inline-block', position: 'relative' }}>
                <IconButton
                    as="button"
                    buttonLabel="Subscribe to calendar"
                    buttonSize="medium"
                    onClick={() => setIsAdding(true)}
                    title={`Add ${entity.name} to your calendar`}
                >
                    <FontAwesomeIcon icon={['fas', 'calendar-days']} />
                </IconButton>
            </div>

            {isAdding && (
                <Modal onClose={() => setIsAdding(false)} title={`Add ${entity.name} to your calendar`}>
                    <div className="subscribe-to-calendar">
                        <div className="disp-flex flex-dir-column align-center">
                            <p className="mb2x">
                                Stay up to date by subscribing to upcoming events on your personal calendar!
                            </p>
                            <p className="mb2x">
                                <strong>Copy this link</strong> and follow the instructions below for your calendar
                                client:
                            </p>
                            <fieldset className="form-group mb2x full-width">
                                <CopyTextField
                                    id="subscribeToCalendarLink"
                                    label="Calendar link"
                                    value={`${Core.Configuration.api}api/v1/${entityType}s/${entity.id}.ics`}
                                />
                            </fieldset>
                            <p className="mb2x">
                                Please choose your calendar client for instructions on how to subscribe. If your client
                                is not listed, try searching the internet for instructions.
                            </p>
                            <div className="disp-flex align-center justify-center mb2x">
                                <a
                                    className="mr2x"
                                    href="https://support.microsoft.com/en-us/office/import-calendars-into-outlook-8e8364e1-400e-4c0f-a573-fe76b5a2d379"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    title="Microsoft Outlook"
                                >
                                    <img
                                        width="40"
                                        src="https://upload.wikimedia.org/wikipedia/commons/9/90/Outlook.com_icon_%282012-2019%29.svg"
                                        alt="Microsoft Outlook"
                                    />
                                </a>
                                <a
                                    className="mr2x"
                                    href="https://support.google.com/calendar/answer/37100"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    title="Google Calendar"
                                >
                                    <img
                                        width="40"
                                        src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Google_Calendar_icon_%282020%29.svg"
                                        alt="Google Calendar"
                                    />
                                </a>
                                <a
                                    href="https://support.apple.com/guide/calendar/subscribe-to-calendars-icl1022/mac"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    title="Apple Calendar"
                                >
                                    <img
                                        width="40"
                                        src="https://upload.wikimedia.org/wikipedia/commons/5/5e/Apple_Calendar_%28iOS%29.svg"
                                        alt="Apple Calendar"
                                    />
                                </a>
                            </div>
                            <p className="mb2x">
                                Note: once you subscribe to this calendar, any changes should automatically appear in
                                your calendar within 8-12 hours.
                            </p>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export { Calendar };

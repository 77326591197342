import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';

import * as Core from '../../core';
import Loading from '../../components/loading';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useIsAdmin } from '../../hooks/store';
import { GameService } from '../../services/gameService';
import CreateGame from '../createGame';

interface EditGamePageRouteProps {
    id: string;
}

interface EditGamePageProps extends WithLoadingProps, RouteComponentProps<EditGamePageRouteProps> {}

const EditGamePage = ({
    match: {
        params: { id },
    },
    setError,
    setIsLoading,
}: EditGamePageProps) => {
    const [game, setGame] = useState<Core.Models.Game | undefined>(undefined);
    const isAdmin = useIsAdmin();

    const load = useCallback(async () => {
        try {
            const response = await GameService.get(id);
            setGame(response);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [setError, setIsLoading]);

    useEffect(() => {
        (async () => {
            await load();
        })();
    }, [load]);

    if (!isAdmin) return <Redirect to="/" />;
    if (!game) return <Loading blockItem />;
    return (
        <div className="page edit-game">
            <CreateGame isCreate={false} game={game} />
        </div>
    );
};

export default withLoading(EditGamePage, {
    errorDisplayPageProps: { fullPage: false },
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

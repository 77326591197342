import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import pluralize from 'pluralize';

import * as Core from '../../core';
import { useConfirmModal } from '../../hooks/confirmModal';
import { useCanEditSeason } from '../../hooks/store';
import { StageService } from '../../services/stageService';
import TiebreakerService from '../../services/tiebreakerService';
import { SolidButton } from '../buttons-visuals';
import ConfirmCompleteStage from '../confirmCompleteStage';
import InfoMessage from '../infoMessage';

interface CompleteStageButtonProps {
    matchWord: string;
    reloadStage: () => Promise<void>;
    showTiebreakerWarning: boolean;
    stage: Core.Models.Stage;
}

export const CompleteStageButton: React.FunctionComponent<CompleteStageButtonProps> = (props) => {
    const { matchWord, reloadStage, showTiebreakerWarning, stage } = props;
    const canEditStage = useCanEditSeason(stage.seasonId);

    const [completeStageNode, openCompleteStage] = useConfirmModal(
        () => `Complete Stage`,
        () => <ConfirmCompleteStage stage={stage} />,
        async (closeModal) => {
            await StageService.complete(stage.id);
            await reloadStage();
            closeModal();
        }
    );

    const showCompleteStage = useMemo(
        () =>
            canEditStage &&
            stage.currentState === Core.Models.StageState.InProgress &&
            stage.rounds.every((r: Core.Models.Round) => r.currentState === Core.Models.CompetitionState.IsComplete),
        [canEditStage, stage]
    );

    const groupNeedsTieBreak = useMemo(
        () =>
            stage.groups.filter((g: Core.Models.Group) =>
                TiebreakerService.tiebreakersRequired(g.groupParticipants!)
            )[0],
        [stage]
    );

    const hasAppliedTiebreakers = useMemo(
        () =>
            stage.groups.some((g: Core.Models.Group) =>
                TiebreakerService.tiebreakersApplied(
                    g.groupParticipants!,
                    stage.appliedAutoTiebreakers,
                    showTiebreakerWarning
                )
            ),
        [stage.appliedAutoTiebreakers, stage.groups, showTiebreakerWarning]
    );

    const enableCompleteStage = useMemo(
        () => showCompleteStage && !groupNeedsTieBreak && stage.allMatchesAreComplete,
        [groupNeedsTieBreak, showCompleteStage, stage.allMatchesAreComplete]
    );

    return (
        <>
            {showCompleteStage && (
                <div className="disp-flex align-center">
                    <SolidButton
                        as="button"
                        size="small"
                        className={classNames(enableCompleteStage && 'attention')}
                        onClick={openCompleteStage}
                        disabled={!enableCompleteStage}
                        title={
                            !stage.allMatchesAreComplete
                                ? `Not all ${pluralize(matchWord.toLowerCase())}/disputes are complete`
                                : !!groupNeedsTieBreak
                                ? `${groupNeedsTieBreak.name} needs tie(s) resolved`
                                : 'Complete Stage'
                        }
                    >
                        <FontAwesomeIcon icon={['fas', 'check']} className="mr" />
                        {!stage.allMatchesAreComplete
                            ? `Complete ${pluralize(matchWord)}/Disputes to Complete Stage`
                            : !!groupNeedsTieBreak
                            ? 'Break Tie(s) to Complete Stage'
                            : 'Complete Stage'}
                    </SolidButton>
                    {showTiebreakerWarning && !!groupNeedsTieBreak && stage.allMatchesAreComplete ? (
                        <InfoMessage className="ml2x mb0" type="error" layoutInline message="Tiebreaker(s) Required" />
                    ) : (
                        hasAppliedTiebreakers && (
                            <InfoMessage
                                className="ml2x mb0"
                                type="info"
                                layoutInline
                                message="Auto-Tiebreakers Applied"
                            />
                        )
                    )}
                </div>
            )}
            {completeStageNode}
        </>
    );
};

export default CompleteStageButton;

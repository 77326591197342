import * as React from 'react';
import { connect } from 'react-redux';

import * as Core from '../../core';
import { HollowButton } from '../../components/buttons-visuals';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { withLoadDataDefaultConfig } from '../../components/loadData';
import ManageDiscordServer from '../../components/manageDiscordServer';
import { OAuthService } from '../../services/oAuthService';
import { AppState } from '../../store';
import { getLeague } from '../../store/league/actions';

interface DiscordServerProps extends Core.Models.ReloadDataProps, Core.Models.GetLeagueProps {
    discordGuildId?: string;
    discordRequiresRelink?: boolean;
    discordUser?: Core.Models.DiscordUser;
}

const DiscordServer: React.FunctionComponent<DiscordServerProps> = (props) => {
    const { discordGuildId, discordRequiresRelink, discordUser, reloadData } = props;

    return (
        <section className="global-container-centered league-settings-page__manage-discord-container">
            <ManageDiscordServer
                discordGuildId={discordGuildId}
                discordRequiresRelink={discordRequiresRelink}
                discordUser={discordUser}
                enableSubmit={true}
                reloadData={() => reloadData(false, true)}
                showLeagues={true}
            />

            <HollowButton
                as="a"
                href="https://discord.com/api/oauth2/authorize?client_id=712718136004050954&permissions=2415937536&scope=bot%20applications.commands"
                rel="noopener noreferrer"
                size="medium"
                layout="full"
                target="_blank"
            >
                Add {Core.Constants.Company} bot to your server
            </HollowButton>
        </section>
    );
};

export default connect(
    (state: AppState) => {
        const league = state.leagueState.league!.league;
        return { discordGuildId: league && league.discordGuildId };
    },
    { getLeague }
)(
    withLoadDataDefaultConfig(
        errorLoadingWrapperHOC<DiscordServerProps>(DiscordServer, { loadingOptions: { blockItem: true } }) as any,
        (props: any) => props,
        async (dataLoadProps, props: any, reload: boolean) => {
            if (reload) await props.getLeague(false);

            const discordUserResponse = await OAuthService.getDiscordUserAndGuilds();
            let discordRequiresRelink: boolean = false;
            if (!discordUserResponse.success && discordUserResponse.statusCode === 403) {
                discordRequiresRelink = true;
            }

            return {
                ...props,
                discordRequiresRelink,
                discordUser: discordUserResponse.result,
            };
        }
    )
);

import React from 'react';

import * as Core from '../core';
import { IHasChildren } from './types';
import Footer from '../components/footer';

import './registrationLayout.scss';

interface RegistrationLayoutProps extends IHasChildren {}

const RegistrationLayout = (props: RegistrationLayoutProps): JSX.Element => {
    const { children } = props;

    return (
        <div className="app">
            <div id="portal" />
            {Core.Configuration.enableBuildNumberInUI && (
                <div className="buildVersion">{Core.Configuration.version}</div>
            )}
            <main className="registration-content global-container-centered">{children}</main>
            <Footer />
        </div>
    );
};

export default RegistrationLayout;

import React, { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { Link } from 'react-router-dom';

import * as Core from '../../core';
import TeamList from '../../components/teamList';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useLeague, useOrganizationTerm, useProfile, useUserPermissionService } from '../../hooks/store';
import { UserService } from '../../services/userService';

interface MyTeamsProps extends WithLoadingProps {}

const MyTeams = (props: MyTeamsProps) => {
    const { setError, setIsLoading } = props;
    const [teams, setTeams] = useState<Core.Models.Team[]>([]);
    const profile = useProfile();
    const userPermissionService = useUserPermissionService();
    const userId = userPermissionService.getUserId();
    const organizationTerm = useOrganizationTerm({ lowercase: true });

    const league = useLeague();
    const requiredTeamCreationPrivilege = !!league?.membersCanCreateTeams
        ? Core.Models.PermissionLevel.Contribute
        : Core.Models.PermissionLevel.Edit;
    const canCreateTeam = userPermissionService.hasOrganizationAccess(
        requiredTeamCreationPrivilege,
        profile?.organizationId
    );

    useEffect(() => {
        (async () => {
            try {
                if (!userId) return;
                const response = await UserService.getTeams(userId);
                const orderedTeams = orderBy(response, (t: Core.Models.Team) => [t.game?.name, t.name]);
                setTeams(orderedTeams);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [setError, setIsLoading, userId]);

    return (
        <div className="my-teams">
            <h2>
                My <span className="theme__text__alternate">Teams</span>
            </h2>
            {teams.length > 0 ? (
                <TeamList readonly showGameName teams={teams} />
            ) : (
                <p>
                    <span>Looks like you aren't a part of any teams yet. </span>
                    {canCreateTeam && (
                        <span>
                            Head over to your{' '}
                            <Link to={`/organizations/${profile!.organizationId!}`}>{organizationTerm} page</Link> to
                            create your first team.
                        </span>
                    )}
                </p>
            )}
        </div>
    );
};

export default withLoading(MyTeams, {
    loadingProps: { blockItem: true },
});

import React from 'react';
import { orderBy } from 'lodash';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import * as Core from '../../core';
import { ReactComponent as InProgressIcon } from '../../assets/images/icons/sand-clock.svg';
import usePagination from '../../hooks/pagination';
import { useTimezone } from '../../hooks/store';
import CompetitionMatchup from '../competitionMatchup';
import GameIcon from '../gameIcon';
import Paginator from '../paginator';

import './index.scss';

interface MatchScheduleProps {
    matches: Core.Models.Match[];
    isPrimary?: (mp: Core.Models.MatchParticipant) => boolean;
}

const MatchSchedule = (props: MatchScheduleProps): JSX.Element => {
    const { isPrimary, matches } = props;
    const paginatedMatches = usePagination(matches, Core.Constants.PAGINATION.MATCHES_PER_PAGE);
    const timezone = useTimezone();

    return (
        <>
            <div className="match-schedule">
                {paginatedMatches.currentData().map((m: Core.Models.Match) => (
                    <MatchScheduleItem key={m.id} match={m} timezone={timezone} isPrimary={isPrimary} />
                ))}
            </div>
            {matches.length > Core.Constants.PAGINATION.MATCHES_PER_PAGE && (
                <Paginator
                    onIncrementPage={paginatedMatches.onIncrementPage}
                    onSelectPage={paginatedMatches.onSelectPage}
                    page={paginatedMatches.page}
                    pageCount={paginatedMatches.maxPage}
                    pageSize={Core.Constants.PAGINATION.MATCHES_PER_PAGE}
                    totalCount={matches.length}
                />
            )}
            {(!matches || matches.length <= 0) && <p>Nothing to show here yet.</p>}
        </>
    );
};

interface MatchScheduleItemProps {
    isPrimary?: (mp: Core.Models.MatchParticipant) => boolean;
    match: Core.Models.Match;
    timezone: string;
}
const MatchScheduleItem = React.memo((props: MatchScheduleItemProps): JSX.Element => {
    const { match, timezone, isPrimary } = props;
    const time = moment.tz(match.startTimeUtc, timezone);
    const stage = match.round!.stage!;
    const game = stage.season!.game;
    const matchParticipants = orderBy(match.matchParticipants, (mp: Core.Models.MatchParticipant) => mp.sortOrder);
    const primary = !!isPrimary ? matchParticipants.filter(isPrimary)[0] : undefined;

    // true - win, false - loss, undefined - in progress
    const isWinner = matchParticipants.some((mp: Core.Models.MatchParticipant) => mp.isWinner)
        ? primary?.isWinner
        : undefined;
    const isBye =
        matchParticipants.length <= 1 && !matchParticipants.some((mp: Core.Models.MatchParticipant) => mp.hasForfeited);
    const isTie = matchParticipants.some((mp: Core.Models.MatchParticipant) => mp.isTie);
    const inProgress = match.currentState === Core.Models.MatchState.InProgress;
    const isDoubleForfeit = matchParticipants.every((mp: Core.Models.MatchParticipant) => !!mp.hasForfeited);

    return (
        <div className="match-schedule__match">
            <div className="match-schedule__match__header">
                <span className="match-schedule__match__game-icon">
                    <GameIcon game={game} />
                </span>
                <span className="match-schedule__match__badge-container">
                    {match.competitionStyle === Core.Models.CompetitionStyle.HeadToHead && (
                        <MatchScheduleItemBadge {...{ inProgress, isBye, isDoubleForfeit, isTie, isWinner }} />
                    )}
                </span>
                <Link to={`/matches/${match.id}`} className="match-schedule__match__stage-time">
                    <span className="match-schedule__match__stage">{stage.name}</span>
                    {time.isValid() && (
                        <>
                            <span
                                className="match-schedule__match__time"
                                title={time.format(Core.Constants.MATCH_DATE_FORMAT)}
                            >
                                {time.format(Core.Constants.MATCH_DATE_FORMAT)}
                            </span>
                            <span
                                className="match-schedule__match__time"
                                title={time.format(Core.Constants.MATCH_TIME_FORMAT)}
                            >
                                {time.format(Core.Constants.MATCH_TIME_FORMAT)}
                            </span>
                        </>
                    )}
                </Link>
            </div>
            <CompetitionMatchup
                {...{
                    className: 'match-schedule__match__competition-matchup',
                    isPreview: true,
                    match: {
                        ...match,
                        season: {
                            id: stage.seasonId,
                        },
                    },
                    matchParticipants,
                }}
            />
        </div>
    );
});

interface MatchScheduleItemBadgeProps {
    inProgress: boolean;
    isBye: boolean;
    isDoubleForfeit: boolean;
    isTie?: boolean;
    isWinner?: boolean;
}

const MatchScheduleItemBadge = (props: MatchScheduleItemBadgeProps): JSX.Element | null => {
    const { inProgress, isBye, isDoubleForfeit, isTie, isWinner } = props;
    if (isBye) {
        return <span className="badge match-schedule__match__badge match-schedule__match__badge--bye">B</span>;
    } else if (isTie) {
        return <span className="badge match-schedule__match__badge match-schedule__match__badge--tie">T</span>;
    } else if (isWinner === true) {
        return <span className="badge match-schedule__match__badge match-schedule__match__badge--win">W</span>;
    } else if (isWinner === false || isDoubleForfeit === true) {
        return <span className="badge match-schedule__match__badge match-schedule__match__badge--loss">L</span>;
    } else if (inProgress) {
        return (
            <span className="badge match-schedule__match__badge match-schedule__match__badge--in-progress">
                <InProgressIcon />
            </span>
        );
    } else {
        return null;
    }
};

export default MatchSchedule;

import React from 'react';
import { Redirect } from 'react-router-dom';

import { canAccessDashboard } from './dashboard';
import Loading from '../components/loading';
import DynamicLoader from '../dynamicLoader';
import { useLeague, useProfile, useUserPermissionService } from '../hooks/store';
import { AuthenticationService } from '../services/authenticationService';

const Switchboard = () => {
    const isAuthenticated = AuthenticationService.isAuthenticated();
    const league = useLeague();
    const profile = useProfile();
    const userPermissionService = useUserPermissionService();

    if (isAuthenticated) {
        if (!league || !profile) return <Loading blockItem />;
        if (canAccessDashboard({ league, profile, userPermissionService })) return <Redirect to="/dashboard" />;
    } else if (!!league?.landingPageFilePath) {
        return <DynamicLoader component={league?.landingPageFilePath} />;
    }

    return <Redirect to="/league" />;
};

export default Switchboard;

import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { isNumber, max, orderBy } from 'lodash';
import pluralize from 'pluralize';
import Select, { OptionsType, SelectOption } from 'react-select';
import * as Yup from 'yup';

import * as Core from '../../../core';
import { Button } from '../../../components/button';
import InfoMessage from '../../../components/infoMessage';
import Loading from '../../../components/loading';
import withLoading, { WithLoadingProps } from '../../../components/withLoading';
import { MatchService } from '../../../services/matchService';

interface AddMatchParticipantProps extends WithLoadingProps {
    match: {
        game: {
            maximumLobbySize?: number;
        };
        id: string;
        participants: {}[];
    };
    matchWord: string;
    onSubmit: (groupParticipantIds: string[]) => Promise<void>;
}

interface AddMatchParticipantValues {
    groupParticipantIds: string[];
}

const AddMatchParticipant = (props: AddMatchParticipantProps): JSX.Element => {
    const { match, matchWord, onSubmit, setError, setIsLoading } = props;
    const [groupParticipants, setGroupParticipants] = useState<Core.Models.GroupParticipant[]>([]);

    const maxNumParticipants = useMemo(
        () =>
            isNumber(match.game.maximumLobbySize)
                ? max([match.game.maximumLobbySize - match.participants.length, 0])
                : undefined,
        [match.game.maximumLobbySize, match.participants.length]
    );

    useEffect(() => {
        (async () => {
            try {
                const response = await MatchService.getEligibleGroupParticipants(match.id);
                const ordered = orderBy(response, (gp: Core.Models.GroupParticipant) => [gp.name]);
                setGroupParticipants(ordered);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [match.id, setError, setIsLoading]);

    return (
        <Formik
            initialValues={Object.assign({
                /** anything not specified here won't show an error message after an attempted submit */
                groupParticipantIds: [],
            } as Partial<AddMatchParticipantValues>)}
            validationSchema={Yup.object().shape({
                groupParticipantIds: Yup.array()
                    .of(Yup.string())
                    .min(1)
                    .test(
                        'max-length',
                        `Must include ${maxNumParticipants} teams or fewer`,
                        (groupParticipantIds: (string | undefined)[] | undefined) => {
                            return !maxNumParticipants || (groupParticipantIds || []).length <= maxNumParticipants;
                        }
                    )
                    .required(),
            })}
            onSubmit={async (values, actions) => {
                actions.setStatus(undefined);
                try {
                    await onSubmit(values.groupParticipantIds);
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(formProps: FormikProps<AddMatchParticipantValues>) => {
                const { groupParticipantIds } = formProps.values;
                const options = groupParticipants
                    .filter((gp: Core.Models.GroupParticipant) => !formProps.values.groupParticipantIds.includes(gp.id))
                    .map((gp: Core.Models.GroupParticipant) => ({ label: gp.name, value: gp.id }) as SelectOption);
                const participantLimitReached =
                    !!maxNumParticipants && !!groupParticipantIds && groupParticipantIds.length >= maxNumParticipants;
                return (
                    <Form className="form">
                        <fieldset className="form-group mb4x">
                            <Select
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isMulti
                                isOptionDisabled={() => participantLimitReached}
                                onChange={(selectedOptions: OptionsType<SelectOption>) => {
                                    formProps.setFieldValue(
                                        'groupParticipantIds',
                                        selectedOptions.map((option: SelectOption) => option.value)
                                    );
                                }}
                                options={options}
                                name="groupParticipantId"
                                placeholder="Select team"
                                styles={{ menu: (provided: any) => ({ ...provided, zIndex: 2 }) }}
                            />
                        </fieldset>

                        <p className="mb4x">
                            This will add the selected {pluralize('team', groupParticipantIds.length)} to this{' '}
                            {matchWord.toLowerCase()}.
                        </p>

                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                        <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                        <fieldset className="form-group form-group--undecorated">
                            {formProps.isSubmitting && <Loading buttonLoader />}
                            <Button onClick={formProps.submitForm} disabled={formProps.isSubmitting} wide round>
                                Add {pluralize('team', groupParticipantIds.length)}
                            </Button>
                        </fieldset>
                    </Form>
                );
            }}
        />
    );
};

export default withLoading(AddMatchParticipant, { loadingProps: { blockItem: true } });

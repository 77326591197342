import React from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../../core';
import { SolidButton } from '../../../components/buttons-visuals';
import FormField from '../../../components/formField';
import InfoMessage from '../../../components/infoMessage';
import { TextInput } from '../../../components/inputs';
import PaymentGatewayService from '../../../services/paymentGatewayService';

interface ManageBankAccountProps {
    bankAccount?: Core.Models.BankAccount;
    canEdit: boolean;
    paymentGatewayId: string;
    refresh: () => Promise<void>;
}

interface CreateAccountAchFormValues {
    accountNumber: string;
    routingNumber: string;
}

const ManageBankAccount = ({ bankAccount, canEdit, paymentGatewayId, refresh }: ManageBankAccountProps) => {
    const stripe = useStripe()!;

    if (!canEdit && !bankAccount) return <></>; // no reason to show this component if it's not editable and there's no bank account
    return (
        <div className="mb2x">
            <Formik<CreateAccountAchFormValues>
                initialValues={{
                    accountNumber: '',
                    routingNumber: '',
                }}
                onSubmit={async (
                    values: CreateAccountAchFormValues,
                    actions: FormikActions<CreateAccountAchFormValues>
                ) => {
                    actions.setStatus(undefined);

                    try {
                        const tokenResult = await stripe.createToken('bank_account', {
                            account_holder_type: 'company',
                            account_number: values.accountNumber,
                            country: 'US',
                            currency: 'usd',
                            routing_number: values.routingNumber,
                        });

                        if (!!tokenResult.error) {
                            actions.setStatus(tokenResult.error.message);
                        } else {
                            await PaymentGatewayService.attachGatewayBankAccount({
                                bankAccountToken: tokenResult.token.id,
                                paymentGatewayId,
                            });

                            await refresh();
                        }
                    } catch (error) {
                        const message = Core.API.getErrorMessage(error);
                        toast.error(`Something went wrong: ${message}`);
                    } finally {
                        actions.setSubmitting(false);
                    }
                }}
                render={(formProps: FormikProps<CreateAccountAchFormValues>) => (
                    <Form>
                        <fieldset className="form-group">
                            {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                            <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                            {!!bankAccount ? (
                                <TextInput
                                    disabled={true}
                                    id="bankAccount"
                                    label="Bank account"
                                    placeholder="Bank account"
                                    type="text"
                                    value={`${bankAccount.bankName} ${bankAccount.last4}`}
                                />
                            ) : (
                                <div>
                                    <FormField
                                        description="Account number"
                                        name="accountNumber"
                                        placeholder="Account number"
                                        type="text"
                                    />
                                    <div className="grid-columns grid-columns--2">
                                        <FormField
                                            description="Routing number"
                                            name="routingNumber"
                                            placeholder="Routing number"
                                            type="text"
                                        />
                                        <SolidButton
                                            as="button"
                                            className="mb2x"
                                            onClick={formProps.submitForm}
                                            pending={formProps.isSubmitting}
                                            size="medium"
                                        >
                                            Submit
                                        </SolidButton>
                                    </div>
                                </div>
                            )}
                        </fieldset>
                    </Form>
                )}
                validationSchema={Yup.object().shape({
                    accountNumber: Yup.string()
                        .required('Account number is required')
                        .min(6, 'Account number must be at least 6 characters')
                        .max(17, 'Account number must be at most 17 characters'), // bank accounts can be 6-17 digits long
                    routingNumber: Yup.string()
                        .required('Routing number is required')
                        .length(9, 'Routing number must be exactly 9 characters'), // routing numbers must be exactly 9 characters long
                })}
            />
        </div>
    );
};

export default ManageBankAccount;

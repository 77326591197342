import React from 'react';
import { RouteComponentProps } from 'react-router';

import OrganizationStandings from '../../pages/organization/standings';

interface OrganizationStandingsWidgetRouteProps {
    organizationId?: string;
}

interface OrganizationStandingsWidgetProps extends RouteComponentProps<OrganizationStandingsWidgetRouteProps> {}

// this component wraps the `OrganizationStandings` into a widget layout
const OrganizationStandingsWidget = ({
    match: {
        params: { organizationId },
    },
}: OrganizationStandingsWidgetProps): JSX.Element => {
    if (!organizationId) return <></>;
    return <OrganizationStandings {...{ organizationId }} />;
};

export default OrganizationStandingsWidget;

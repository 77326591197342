import { useState } from 'react';

const usePagination = (data: any, itemsPerPage: number) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(1);

    const maxPage = Math.max(Math.ceil(data.length / itemsPerPage), 1);

    const currentData = () => {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        return data.slice(begin, end);
    };

    const jumpTo = (page: number) => {
        const pageNumber = Math.max(1, page);
        setCurrentPage(Math.min(pageNumber, maxPage));
    };

    const onIncrementPage = (incrementPage: number) => {
        setPage(page + incrementPage);
        jumpTo(page + incrementPage);
    };

    const onSelectPage = (pageNumber: number) => {
        setPage(pageNumber);
        jumpTo(pageNumber);
    };

    return {
        currentData,
        jumpTo,
        onIncrementPage,
        onSelectPage,
        currentPage,
        maxPage,
        page,
    };
};

export default usePagination;

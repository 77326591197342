import React, { useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isUndefined } from 'lodash';

import * as Core from '../../core';
import { IconButton } from '../../components/buttons-visuals';
import ConfirmModal from '../../components/confirmModal';
import { ContentContainer } from '../../components/containers';
import ImageUploader from '../../components/imageUploader';
import { useIsLite } from '../../hooks/store';
import { LeagueService } from '../../services/leagueService';

interface LeagueImageUploaderProps {
    aspectRatio?: number;
    contentType: string;
    header: string;
    imageType: Core.Models.LeagueImageType;
    reloadData: () => void;
    text: string;
    url?: string;
    width?: number;
}

const LeagueImageUploader = (props: LeagueImageUploaderProps): JSX.Element => {
    const { aspectRatio, contentType, header, imageType, reloadData, text, url, width } = props;
    const [imageTypeBeingDeleted, setImageTypeBeingDeleted] = useState<Core.Models.LeagueImageType | undefined>(
        undefined
    );
    const isLite = useIsLite();

    const maxFileSize = useMemo(
        () =>
            imageType === Core.Models.LeagueImageType.Banner ? Core.Constants.IMAGES.MAX_LEAGUE_IMAGE_SIZE : undefined,
        [imageType]
    );

    const deleteImage = useCallback(
        async (it: Core.Models.LeagueImageType) => {
            await LeagueService.deleteImage(it);
            await reloadData();
        },
        [reloadData]
    );

    const uploadImage = useCallback(
        async (imageData: string) => {
            await LeagueService.uploadImage({ imageData, imageType });
            await reloadData();
        },
        [imageType, reloadData]
    );

    return (
        <section>
            <ContentContainer shade={Core.Models.Shades.Dark40} className="league-image-uploader">
                <h3 className="heading-3">{header}</h3>
                {!!url && <img className="league-image-uploader__image" src={url} style={{ width }} alt="" />}
                <div className="disp-flex align-center justify-start">
                    <ImageUploader
                        {...{
                            aspectRatio,
                            contentType,
                            className: 'league-image-uploader__image-uploader mr',
                            disabled: isLite && imageType !== Core.Models.LeagueImageType.Banner,
                            flush: true,
                            maxFileSize,
                            maxImageWidth: width,
                            icon: 'upload',
                            text,
                            uploadImage,
                        }}
                    />
                    {!!url && (
                        <IconButton
                            as="button"
                            buttonLabel="Delete image"
                            buttonSize="medium"
                            color="destructive"
                            onClick={() => setImageTypeBeingDeleted(imageType)}
                        >
                            <FontAwesomeIcon icon={['fas', 'trash']} />
                        </IconButton>
                    )}
                </div>
            </ContentContainer>

            {!isUndefined(imageTypeBeingDeleted) && (
                <ConfirmModal
                    confirmText="Yes"
                    onCancel={() => setImageTypeBeingDeleted(undefined)}
                    onConfirm={async () => {
                        deleteImage(imageTypeBeingDeleted);
                        setImageTypeBeingDeleted(undefined);
                    }}
                    title="Are you sure"
                >
                    <p>Are you sure you want to delete this image?</p>
                </ConfirmModal>
            )}
        </section>
    );
};

export default LeagueImageUploader;

import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { omitBy, isUndefined } from 'lodash';

import { BaseCore, Models } from '@leaguespot/platform-js';
import { Configuration } from './configuration';
import AnalyticsService from '../services/analyticsService';
import { AuthenticationService } from '../services/authenticationService';
import history from '../services/history';
import { store } from '../store';
import { logout } from '../store/login/actions';

// League ID

let leagueId: string | undefined = undefined;

function setLeagueId(id: string) {
    leagueId = id;
}

function getLeagueId() {
    return leagueId;
}

// Web Axios configuration

const createDefaultHeaders = (config: BaseCore.Communication.Types.RequestConfig): string[][] => {
    const token: string = AuthenticationService.getToken() || '';
    const defaultHeaders: any = {
        'Content-Type': 'application/json',
        'X-App': 'web',
        'X-Version': Configuration.version,
    };
    if (config.authTokenOverride) {
        defaultHeaders['Authorization'] = `Bearer ${config.authTokenOverride}`;
    } else if (token && config.sendAuthentication !== false) {
        defaultHeaders['Authorization'] = `Bearer ${token}`;
    }
    if (config.leagueIdOverride) {
        defaultHeaders['X-League-Id'] = config.leagueIdOverride;
    } else if (leagueId && config.sendLeagueId !== false) {
        defaultHeaders['X-League-Id'] = leagueId;
    }

    return defaultHeaders;
};

BaseCore.Communication.Http.instance.interceptors.request.use((config: BaseCore.Communication.Types.RequestConfig) => {
    const defaultHeaders = createDefaultHeaders(config);

    return Object.assign({}, config, {
        headers: Object.assign({}, defaultHeaders, config.headers || {}),
    });
});

BaseCore.Communication.Http.instance.interceptors.response.use(undefined, async (result) => {
    const { response, config } = result;

    if (config && config.globalErrorHandling === false) {
        throw result;
    }

    AnalyticsService.exception(result);

    if (response && response.status === 401) {
        if (history.location.pathname.indexOf('/login') !== 0 && history.location.pathname.indexOf('/join') !== 0) {
            store.dispatch(logout() as any);
        }
    }

    throw result;
});

const createSignalRConnection = (hubRoute: string): HubConnection => {
    const url = `${Configuration.api}${hubRoute}`;

    return new HubConnectionBuilder()
        .withUrl(url, {
            accessTokenFactory: () => AuthenticationService.getToken()!,
        })
        .configureLogging(process.env.NODE_ENV === 'development' ? LogLevel.Debug : LogLevel.Warning)
        .withAutomaticReconnect()
        .build();
};

// HTTP methods

const makeUrl = (
    routeData: BaseCore.Communication.ServerRoute,
    version: BaseCore.Communication.Types.ApiVersion = BaseCore.Communication.Types.ApiVersion.v1,
    urlParameters?: BaseCore.Communication.Types.UrlParameters
): string => {
    const baseUrl: string = Configuration.api;
    const query = !!urlParameters ? `?${new URLSearchParams(omitBy(urlParameters, isUndefined)).toString()}` : '';
    return `${baseUrl}api/${version}${routeData.url()}${query}`;
};

function deleteMethod<TResult>(
    routeData: BaseCore.Communication.ServerRoute,
    version?: BaseCore.Communication.Types.ApiVersion,
    config?: BaseCore.Communication.Types.RequestConfig
): Promise<TResult> {
    return BaseCore.Communication.Http.deleteMethod<TResult>(makeUrl(routeData, version), config);
}

function get<TResult>(
    routeData: BaseCore.Communication.ServerRoute,
    version?: BaseCore.Communication.Types.ApiVersion,
    urlParameters?: BaseCore.Communication.Types.UrlParameters,
    config?: BaseCore.Communication.Types.RequestConfig
): Promise<TResult> {
    return BaseCore.Communication.Http.get<TResult>(makeUrl(routeData, version, urlParameters), config);
}

function getAnonymous<TResult>(
    routeData: BaseCore.Communication.ServerRoute,
    version?: BaseCore.Communication.Types.ApiVersion,
    urlParameters?: BaseCore.Communication.Types.UrlParameters,
    config?: BaseCore.Communication.Types.RequestConfig
): Promise<TResult> {
    return BaseCore.Communication.Http.getAnonymous<TResult>(makeUrl(routeData, version, urlParameters), config);
}

async function getFile(
    routeData: BaseCore.Communication.ServerRoute,
    version?: BaseCore.Communication.Types.ApiVersion,
    urlParameters?: BaseCore.Communication.Types.UrlParameters,
    config?: BaseCore.Communication.Types.RequestConfig
): Promise<Models.File> {
    const headers = createDefaultHeaders(config || {}) as HeadersInit;
    const response = await fetch(makeUrl(routeData, version, urlParameters), {
        cache: 'no-cache',
        credentials: 'omit',
        headers,
        method: 'GET',
        mode: 'cors',
    });
    if (!response.ok) {
        const message = await response.text();
        throw Error(`Unable to download file. Please try again.${!!message ? ` (${message})` : ''}`);
    }
    const blob = (await response.blob()) as Blob;
    if (blob.size <= 0) throw Error('Unable to download file. Please reach out to support.');
    const contentDisposition: string | null = response.headers.get('content-disposition');
    if (!contentDisposition) {
        console.warn("Wasn't able to get Content-Disposition header - maybe an issue with CORS?");
    }
    // https://stackoverflow.com/a/23054920
    const match = contentDisposition && contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
    const filename = match ? match[1] : undefined;
    return { blob, filename };
}

function getWithResponseDate<TResult>(
    routeData: BaseCore.Communication.ServerRoute,
    version?: BaseCore.Communication.Types.ApiVersion,
    urlParameters?: BaseCore.Communication.Types.UrlParameters,
    config?: BaseCore.Communication.Types.RequestConfig
): Promise<[TResult, Date]> {
    return BaseCore.Communication.Http.getWithResponseDate(makeUrl(routeData, version, urlParameters), config);
}

function post<TResult>(
    routeData: BaseCore.Communication.ServerRoute,
    payload: any,
    version?: BaseCore.Communication.Types.ApiVersion,
    config?: BaseCore.Communication.Types.RequestConfig
): Promise<TResult> {
    return BaseCore.Communication.Http.post(makeUrl(routeData, version), payload, config);
}

function postAnonymous<TResult>(
    routeData: BaseCore.Communication.ServerRoute,
    payload: any,
    version?: BaseCore.Communication.Types.ApiVersion,
    config?: BaseCore.Communication.Types.RequestConfig
): Promise<TResult> {
    return BaseCore.Communication.Http.postAnonymous<TResult>(makeUrl(routeData, version), payload, config);
}

function postWithResponseDate<TResult>(
    routeData: BaseCore.Communication.ServerRoute,
    payload: any,
    version?: BaseCore.Communication.Types.ApiVersion,
    config?: BaseCore.Communication.Types.RequestConfig
): Promise<[TResult, Date]> {
    return BaseCore.Communication.Http.postWithResponseDate<TResult>(makeUrl(routeData, version), payload, config);
}

function patch<TResult>(
    routeData: BaseCore.Communication.ServerRoute,
    payload: any,
    version?: BaseCore.Communication.Types.ApiVersion,
    config?: BaseCore.Communication.Types.RequestConfig
): Promise<TResult> {
    return BaseCore.Communication.Http.patch<TResult>(makeUrl(routeData, version), payload, config);
}

function patchAnonymous<TResult>(
    routeData: BaseCore.Communication.ServerRoute,
    payload: any,
    version?: BaseCore.Communication.Types.ApiVersion,
    config?: BaseCore.Communication.Types.RequestConfig
): Promise<TResult> {
    return BaseCore.Communication.Http.patchAnonymous<TResult>(makeUrl(routeData, version), payload, config);
}

function patchWithResponseDate<TResult>(
    routeData: BaseCore.Communication.ServerRoute,
    payload: any,
    version?: BaseCore.Communication.Types.ApiVersion,
    config?: BaseCore.Communication.Types.RequestConfig
): Promise<[TResult, Date]> {
    return BaseCore.Communication.Http.patchWithResponseDate<TResult>(makeUrl(routeData, version), payload, config);
}

function put<TResult>(
    routeData: BaseCore.Communication.ServerRoute,
    payload: any,
    version?: BaseCore.Communication.Types.ApiVersion,
    config?: BaseCore.Communication.Types.RequestConfig
): Promise<TResult> {
    return BaseCore.Communication.Http.put<TResult>(makeUrl(routeData, version), payload, config);
}

const API = {
    ApiVersion: BaseCore.Communication.Types.ApiVersion,
    axios: BaseCore.Communication.Http.instance,
    createSignalRConnection,
    delete: deleteMethod,
    get,
    getAnonymous,
    getErrorMessage: BaseCore.Communication.Http.getErrorMessage,
    getFile,
    getLeagueId,
    getStatus: BaseCore.Communication.Http.getStatus,
    getWithResponseDate,
    patch,
    patchAnonymous,
    patchWithResponseDate,
    post,
    postAnonymous,
    postWithResponseDate,
    put,
    ServerRoute: BaseCore.Communication.ServerRoute,
    setLeagueId,
};

export default API;

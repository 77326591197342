import React, { HTMLAttributes, useMemo } from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import './StatusIndicator.scss';

export interface StatusIndicatorProps extends HTMLAttributes<HTMLDivElement> {
    color?: 'primary' | 'secondary' | 'accent1' | 'accent2' | 'info' | 'warning' | 'success' | 'error' | 'default';
    iconName?: string;
    outlineOnly?: boolean;
}

const StatusIndicator = ({
    children,
    className,
    color = 'default',
    iconName,
    outlineOnly = false,
    ...rest
}: StatusIndicatorProps) => {
    const statusIndicatorClasses = classNames(className, 'ds-status-indicator', `ds-status-indicator--${color}`);

    const icon = useMemo(() => {
        if (!!iconName) return iconName;

        if (!outlineOnly) {
            switch (color) {
                case 'error':
                case 'warning':
                    return 'exclamation';
                case 'success':
                    return 'check';
                case 'info':
                    return 'info';
                default:
                    return undefined;
            }
        }
    }, [color, iconName, outlineOnly]);

    const statusIndicatorContentClasses = classNames('ds-status-indicator__content', {
        'ds-status-indicator__content--has-icon': icon,
    });

    return (
        <div className={statusIndicatorClasses}>
            {icon && (
                <div className="ds-status-indicator__icon">
                    <FontAwesomeIcon icon={['fas', icon as IconName]} size="xs" />
                </div>
            )}
            <div className={statusIndicatorContentClasses} {...rest}>
                {children}
            </div>
        </div>
    );
};

export default StatusIndicator;

import React, { useState } from 'react';
import { Formik, Form, FormikProps, FormikActions } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import * as Core from '../../core';
import { Button } from '../../components/button';
import ErrorMessage from '../../components/errorMessage';
import FormField from '../../components/formField';
import CustomRegistrationFormField from '../../components/formField/customRegistrationFormField';
import Loading from '../../components/loading';
import { LeagueService } from '../../services/leagueService';

interface EditRegistrationFieldProps {
    customRegistrationField: Core.Models.CustomRegistrationField;
    reloadData: () => Promise<void>;
}

interface IAmStringIndexable {
    [key: string]: any;
}
interface EditRegistrationFieldFormValues extends Core.Models.CustomRegistrationField, IAmStringIndexable {
    optionsString?: string; // pipe-delimited representation of `options`
}

const EditRegistrationField: React.FunctionComponent<EditRegistrationFieldProps> = (props) => {
    const { customRegistrationField } = props;

    // use state to track `formValues` as a clone (via `Object.assign`) from customRegistrationField. must clone
    // to avoid auto-updating parent values from uncommitted edit form
    const [formValues, setFormValues] = useState<EditRegistrationFieldFormValues>(
        Object.assign({}, customRegistrationField)
    );

    // this function will always return a `string[]` even if empty
    const parseOptionsField = (value: string[] | string | undefined): string[] => {
        if (!value) return [];

        return _.chain(value)
            .split(/,|\||;|:|\n|\r/) // split up the input by comma, pipe, semicolon, colon or carriage return
            .map((option: string) => option.trim())
            .compact()
            .value();
    };

    let setFieldValue = (field: string, value: any) => {};
    const handleChangeDebounced = _.debounce((name, value) => {
        // set state
        if (Array.isArray(formValues[name])) {
            // special handling for arrays since we need to parse the input
            formValues[name] = parseOptionsField(value);
        } else if (typeof value !== 'boolean' && !isNaN(value)) {
            // check to see if the value parses to numberic but isn't boolean
            formValues[name] = +value;
        } else {
            formValues[name] = value;
        }

        setFormValues(formValues);

        // set preview form field. see hack notes below.
        setFieldValue(`customRegistrationFields[0].${name}`, formValues[name]);
    }, Core.Constants.FORM_DEBOUNCE_TIME_MS);

    const handleChange = (e: any) => {
        if (e.type !== 'change') return;

        const { name } = e.target;
        let { value } = e.target;

        // override checkbox functionality as it doesn't use `value`
        if (e.target.type === 'checkbox') value = !!e.target.checked;

        handleChangeDebounced(name, value);
    };

    return (
        <div>
            <Formik
                initialValues={Object.assign(
                    {},
                    {
                        // underlying required values
                        entityType: formValues.entityType || Core.Models.CustomRegistrationFieldEntityType.User,
                        fieldType: formValues.fieldType || Core.Models.CustomRegistrationFieldType.Textbox,
                        id: formValues.id, // allow null

                        // primary values
                        name: formValues.name || '',
                        required: formValues.required || false,

                        // checkbox
                        details: formValues.details || '',
                        label: formValues.label || '',

                        // textbox
                        isTextArea: formValues.isTextArea || false,
                        maxLength: formValues.maxLength || 50,
                        minLength: formValues.minLength || 3,
                        placeholderText: formValues.placeholderText || '',

                        // select
                        options: formValues.options || [],
                    }
                )}
                validationSchema={Yup.object().shape(
                    Object.assign(
                        {},
                        {
                            name: Yup.string().max(50).required('Name is required'),
                            required: Yup.boolean().required('Required is required'),

                            // checkbox field requirements
                            ...(customRegistrationField.fieldType ===
                                Core.Models.CustomRegistrationFieldType.Checkbox && {
                                details: Yup.string().max(Core.Constants.MARKDOWN_MAX_LENGTH).nullable().notRequired(),
                                label: Yup.string().max(256).required('Label is required'),
                            }),

                            // textbox field requirements
                            ...(customRegistrationField.fieldType ===
                                Core.Models.CustomRegistrationFieldType.Textbox && {
                                isTextArea: Yup.boolean().required(),
                                maxLength: Yup.number().min(1).max(2000).required(),
                                minLength: Yup.number().min(1).max(2000).required(),
                                placeholderText: Yup.string().max(256).nullable().notRequired(),
                            }),

                            // select field requirements
                            ...(customRegistrationField.fieldType ===
                                Core.Models.CustomRegistrationFieldType.Select && {
                                options: Yup.string().required('Options field is required'),
                            }),
                        }
                    )
                )}
                onSubmit={async (
                    values: EditRegistrationFieldFormValues,
                    actions: FormikActions<EditRegistrationFieldFormValues>
                ) => {
                    actions.setStatus(undefined);
                    try {
                        const command = {
                            ...values,
                            options: parseOptionsField(values.options),
                        };
                        if (command.id) {
                            await LeagueService.editLeagueCustomRegistrationField(command);
                        } else {
                            await LeagueService.createLeagueCustomRegistrationField(command);
                        }
                        props.reloadData();
                    } catch (e) {
                        const message = Core.API.getErrorMessage(e);
                        actions.setStatus(message);
                    }
                    actions.setSubmitting(false);
                }}
                render={(formProps: FormikProps<EditRegistrationFieldFormValues>) => (
                    <Form className="form mb4x">
                        {!formProps.values.id && ( // only show the select here for new fields. cannot change the type on existing fields
                            <FormField
                                component="select"
                                description="Field type"
                                label="Field type"
                                name="fieldType"
                                onChange={handleChange}
                            >
                                <option key={-1} value={''} disabled>
                                    Field type
                                </option>
                                <option value={Core.Models.CustomRegistrationFieldType.Checkbox}>Checkbox</option>
                                <option value={Core.Models.CustomRegistrationFieldType.Textbox}>Textbox</option>
                                <option value={Core.Models.CustomRegistrationFieldType.Select}>Select</option>
                            </FormField>
                        )}

                        {/* all common fields */}
                        <FormField type="text" name="name" label="Name" placeholder="Name" onChange={handleChange} />
                        <FormField
                            type="checkbox"
                            name="required"
                            label="Required"
                            placeholder="Required"
                            onChange={handleChange}
                        />

                        {+formProps.values.fieldType === Core.Models.CustomRegistrationFieldType.Checkbox && ( // `+` since the select sets `fieldType` value as string
                            <>
                                <FormField
                                    label="Label"
                                    name="label"
                                    onChange={handleChange}
                                    placeholder="Label"
                                    type="text"
                                />
                                <FormField
                                    component="textarea"
                                    label="Details"
                                    name="details"
                                    onChange={handleChange}
                                    placeholder="Optionally add more details shown below the checkbox label. This field allows markdown formatting."
                                />
                            </>
                        )}
                        {+formProps.values.fieldType === Core.Models.CustomRegistrationFieldType.Textbox && ( // `+` since the select sets `fieldType` value as string
                            <>
                                <FormField
                                    type="text"
                                    name="placeholderText"
                                    label="Placeholder"
                                    placeholder="Placeholder"
                                    onChange={handleChange}
                                />
                                <FormField
                                    type="checkbox"
                                    name="isTextArea"
                                    label="Multiline"
                                    placeholder="Multiline"
                                    onChange={handleChange}
                                />
                                <FormField
                                    type="number"
                                    name="minLength"
                                    label="Minimum length"
                                    placeholder="Minimum length"
                                    onChange={handleChange}
                                />
                                <FormField
                                    type="number"
                                    name="maxLength"
                                    label="Maximum length"
                                    placeholder="Maximum length"
                                    onChange={handleChange}
                                />
                            </>
                        )}
                        {+formProps.values.fieldType === Core.Models.CustomRegistrationFieldType.Select && ( // `+` since the select sets `fieldType` value as string
                            <>
                                <FormField
                                    component="textarea"
                                    name="options"
                                    label="Select options"
                                    placeholder="Select options"
                                    onChange={handleChange}
                                />
                            </>
                        )}

                        {formProps.status && <ErrorMessage error={formProps.status} />}
                        <ErrorMessage error={formProps.errors} filter={formProps.touched} />

                        {formProps.isSubmitting && <Loading buttonLoader />}
                        <Button onClick={formProps.submitForm} disabled={formProps.isSubmitting} wide>
                            Save
                        </Button>
                    </Form>
                )}
            />

            <p>Preview:</p>
            <Formik
                initialValues={{
                    customRegistrationFields: [{ value: '' }],
                }}
                onSubmit={() => {}}
                render={(formProps) => {
                    // hack - this provides the parent component with a reference to the `formProps.setFieldValue` method. when the parent component
                    // calls this method, it updates this form directly via reference.
                    setFieldValue = formProps.setFieldValue;
                    return (
                        <Form className="form">
                            <CustomRegistrationFormField
                                fieldArrayName={`customRegistrationFields`}
                                fieldSubmissionResponse={
                                    new Core.Models.CustomRegistrationFieldSubmissionResponse(formValues, '')
                                }
                                index={0}
                            />
                        </Form>
                    );
                }}
            />
        </div>
    );
};

export default EditRegistrationField;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';

import * as Core from '../../core';
import { IconButton } from '../../components/buttons-visuals';
import ConfirmModal from '../../components/confirmModal';
import { ContentContainer } from '../../components/containers';
import InviteLeagueRole from '../../components/inviteLeagueRole';
import Modal from '../../components/modal';
import { ToolTip } from '../../components/overlays';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useAlternateSeasonName, useOrganizationTerm } from '../../hooks/store';
import { LeagueService } from '../../services/leagueService';

interface LeagueMembersProps extends WithLoadingProps {
    leagueRoleId: Core.Models.LeagueRoleId;
}

const LeagueMembers = (props: LeagueMembersProps) => {
    const { leagueRoleId, setError, setIsLoading } = props;
    const [leagueMembers, setLeagueMembers] = useState<Core.Models.LeagueMember[]>([]);
    const [isAddingLeagueMember, setIsAddingLeagueMember] = useState(false);
    const [leagueMemberBeingDeleted, setLeagueMemberBeingDeleted] = useState<Core.Models.LeagueMember | undefined>(
        undefined
    );
    const organizationTerm = useOrganizationTerm({ lowercase: true });
    const seasonAlternateName = useAlternateSeasonName();
    const leagueRoleName = useMemo(() => {
        if (leagueRoleId === Core.Models.LeagueRoleId.Host) return 'Host';
        if (leagueRoleId === Core.Models.LeagueRoleId.LeagueAdmin) return 'Admin';
        if (leagueRoleId === Core.Models.LeagueRoleId.SeasonOperator) return `${seasonAlternateName} Operator`;
        return '';
    }, [leagueRoleId, seasonAlternateName]);

    const load = useCallback(async () => {
        try {
            const leagueMembersResponse = await LeagueService.getLeagueUsersByRole(leagueRoleId);
            setLeagueMembers(leagueMembersResponse);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [leagueRoleId, setError, setIsLoading]);

    useEffect(() => {
        (async () => {
            await load();
        })();
    }, [load]);

    return (
        <ContentContainer className="global-container-centered mb2x" shade={Core.Models.Shades.Dark40}>
            <div className="disp-flex align-center justify-between mb">
                <div className="disp-flex align-center">
                    <h4 className="heading-3 mr mb0">{`${leagueRoleName}(s)`}</h4>
                    <ToolTip trigger={<FontAwesomeIcon icon={['fas', 'circle-info']} size="1x" />}>
                        <>
                            {leagueRoleId === Core.Models.LeagueRoleId.LeagueAdmin && (
                                <p>
                                    League Admins are {Core.Constants.Company} employees who have elevated access to
                                    specific leagues. They are very similar to Hosts but are designated as Admins to
                                    differentiate {Core.Constants.Company} employees. This role may have a few elevated
                                    privileges above a Host.
                                </p>
                            )}
                            {leagueRoleId === Core.Models.LeagueRoleId.Host && (
                                <p>
                                    Hosts have complete control over all data within this league. They can customize the
                                    league, create/schedule/administer {pluralize(seasonAlternateName.toLowerCase())},
                                    edit membership in {pluralize(organizationTerm)}/teams and much more.
                                </p>
                            )}
                            {leagueRoleId === Core.Models.LeagueRoleId.SeasonOperator && (
                                <p>
                                    {seasonAlternateName} Operators have limited Host-level privileges solely within the{' '}
                                    {pluralize(seasonAlternateName.toLowerCase())} they have been assigned to.
                                </p>
                            )}
                        </>
                    </ToolTip>
                </div>
                <IconButton
                    as="button"
                    buttonLabel={`Add ${pluralize(leagueRoleName)}`}
                    buttonSize="medium"
                    disabled={isAddingLeagueMember}
                    onClick={() => setIsAddingLeagueMember(true)}
                >
                    <FontAwesomeIcon icon={['fas', 'circle-plus']} className="color-success" />
                </IconButton>
            </div>

            <div>
                {leagueMembers.map((member: Core.Models.LeagueMember) => {
                    return (
                        <div key={member.userLeagueRoleId} className="disp-flex justify-between align-center mb">
                            <div>
                                {!!member.userId ? (
                                    <Link to={`/users/${member.userId}`}>
                                        <span>{Core.Identity.renderMemberName(member) || member.email}</span>
                                    </Link>
                                ) : (
                                    <span>{member.email}</span>
                                )}
                            </div>
                            <IconButton
                                as="button"
                                buttonLabel="Delete"
                                buttonSize="small"
                                onClick={() => setLeagueMemberBeingDeleted(member)}
                            >
                                <FontAwesomeIcon className="color-error" icon={['fas', 'circle-minus']} />
                            </IconButton>
                        </div>
                    );
                })}
                {leagueMembers.length <= 0 && <p>No {leagueRoleName}s found for this league.</p>}
            </div>

            {isAddingLeagueMember && (
                <Modal onClose={() => setIsAddingLeagueMember(false)} title={`Invite ${leagueRoleName} to your league`}>
                    <InviteLeagueRole
                        leagueRoleId={leagueRoleId}
                        onComplete={async () => {
                            setIsAddingLeagueMember(false);
                            await load();
                        }}
                    />
                </Modal>
            )}

            {leagueMemberBeingDeleted && (
                <ConfirmModal
                    confirmText="Yes"
                    onCancel={() => setLeagueMemberBeingDeleted(undefined)}
                    onConfirm={async () => {
                        await LeagueService.deleteUserLeagueRole(leagueMemberBeingDeleted!.userLeagueRoleId);
                        setLeagueMemberBeingDeleted(undefined);
                        await load();
                    }}
                    title="Are you sure"
                >
                    <p>
                        Are you sure you want to delete{' '}
                        <em>
                            {leagueMemberBeingDeleted.userId
                                ? Core.Identity.renderMemberName(leagueMemberBeingDeleted)
                                : leagueMemberBeingDeleted.email}
                        </em>
                        ?
                    </p>
                </ConfirmModal>
            )}
        </ContentContainer>
    );
};

export default withLoading(LeagueMembers, { loadingProps: { blockItem: true } });

import * as React from 'react';
import classNames from 'classnames';

import './index.scss';

export interface LoadingProps {
    blockItem?: boolean;
    buttonLoader?: boolean;
    invertColors?: boolean;
}

const Loading = ({ blockItem, buttonLoader, invertColors }: LoadingProps) => {
    return (
        <div
            className={classNames('loading', {
                block: blockItem,
                btn_loading: buttonLoader,
                invert_colors: invertColors,
            })}
        >
            <div className="loading_bubble" />
            <div className="loading_bubble" />
            <div className="loading_bubble" />
        </div>
    );
};

export default Loading;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import * as Core from '../../../core';
import GameTeams from './gameTeams';
import { HollowButton } from '../../../components/buttons-visuals';
import { Select } from '../../../components/inputs';
import Modal from '../../../components/modal';
import CreateTeam from '../../../components/teamList/createTeam';
import withLoading, { WithLoadingProps } from '../../../components/withLoading';
import { useLeague, useUserPermissionService } from '../../../hooks/store';
import { OrganizationService } from '../../../services/organizationService';
import { getPermissions } from '../../../store/permissions/actions';
import { loadProfile } from '../../../store/profile/actions';

import './index.scss';

interface OrganizationTeamsProps extends WithLoadingProps {
    hideTeamControls?: boolean;
    hideTeamDetails?: boolean;
    organizationId: string;
}

const OrganizationTeams = (props: OrganizationTeamsProps): JSX.Element => {
    const { hideTeamControls, hideTeamDetails, organizationId, setError, setIsLoading } = props;

    const dispatch = useDispatch();
    const league = useLeague();
    const userPermissionService = useUserPermissionService();

    const [isCreatingTeam, setIsCreatingTeam] = useState<boolean>(false);
    const [organizationGames, setOrganizationGames] = useState<Core.Models.OrganizationGame[] | undefined>(undefined);
    const [selectedGame, setSelectedGame] = useState<Core.Models.NamedObject | undefined>(undefined);

    const load = useCallback(
        async (gameId?: string) => {
            setIsLoading(true);
            try {
                const response = await OrganizationService.getOrganizationGames(organizationId);
                setOrganizationGames(response);
                if (response.length > 0) {
                    var game = response.find((g: Core.Models.OrganizationGame) => g.gameId === gameId) ?? response[0];
                    setSelectedGame({
                        id: game.gameId,
                        name: game.gameName,
                    });
                }
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        },
        [organizationId, setError, setIsLoading, setOrganizationGames]
    );

    useEffect(() => {
        (async () => {
            await load();
        })();
    }, [load]);

    const requiredTeamCreationPrivilege = useMemo(
        () =>
            !!league?.membersCanCreateTeams ? Core.Models.PermissionLevel.Contribute : Core.Models.PermissionLevel.Edit,
        [league?.membersCanCreateTeams]
    );
    const canCreateTeam = useMemo(
        () => userPermissionService.hasOrganizationAccess(requiredTeamCreationPrivilege, organizationId),
        [organizationId, requiredTeamCreationPrivilege, userPermissionService]
    );
    const allowRoleSelection = useMemo(
        () => !userPermissionService.hasOrganizationAccess(Core.Models.PermissionLevel.Edit, organizationId),
        [organizationId, userPermissionService]
    );

    const createTeamComplete = useCallback(
        async (gameId: string) => {
            setIsCreatingTeam(false);
            await load(gameId);
            dispatch(getPermissions());
            dispatch(loadProfile());
        },
        [dispatch, load, setSelectedGame, setIsCreatingTeam]
    );

    return (
        <div className="organization-teams">
            <div className="organization-teams__header">
                <h4 className="heading-2">Teams</h4>

                {canCreateTeam && !hideTeamControls && (
                    <div className="organization-teams__header__controls">
                        <HollowButton
                            as="button"
                            className="organization-teams__create-team"
                            color="primary"
                            onClick={() => setIsCreatingTeam(true)}
                        >
                            <FontAwesomeIcon className="mr" icon={['fas', 'circle-plus']} size="1x" />
                            <span>Add Team</span>
                        </HollowButton>
                    </div>
                )}
            </div>

            {!!organizationGames && organizationGames.length > 0 ? (
                <Select
                    id="gameId"
                    label="Select a game to filter"
                    layoutInline
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        const gameId = e.target.value;
                        const game = organizationGames.find((g) => g.gameId === gameId)!;
                        setSelectedGame({
                            id: game.gameId,
                            name: game.gameName,
                        });
                    }}
                    value={selectedGame?.id}
                >
                    {organizationGames.map((game: { gameId: string; gameName: string }) => (
                        <option key={game.gameId} value={game.gameId}>
                            {game.gameName}
                        </option>
                    ))}
                </Select>
            ) : (
                <div>No teams</div>
            )}

            {!!selectedGame && (
                <GameTeams
                    game={selectedGame}
                    {...{
                        hideTeamControls,
                        hideTeamDetails,
                        organizationId,
                    }}
                />
            )}

            {isCreatingTeam && canCreateTeam && (
                <Modal onClose={() => setIsCreatingTeam(false)} title={'Create New Team'}>
                    <CreateTeam
                        onSubmit={createTeamComplete}
                        {...{
                            allowRoleSelection,
                            organizationId,
                        }}
                    />
                </Modal>
            )}
        </div>
    );
};

export default withLoading(OrganizationTeams, { loadingProps: { blockItem: true } });

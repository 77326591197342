import * as Redux from 'redux';

import * as Core from './../../core';
import {
    LOGIN_FAILURE,
    LOGIN_INPROGRESS,
    LOGIN_REQUIRES_MFA,
    LOGIN_SUCCESS,
    LoginActionTypes,
    LoginState,
    LOGOUT_SUCCESS,
    TokenResult,
} from './types';
import { AuthenticationService } from '../../services/authenticationService';

const initialState: LoginState = {
    authenticated: false,
    decodedToken: undefined,
    error: '',
    isLoading: false,
    requiresMfa: false,
    token: '',
};

const loginReducer: Redux.Reducer<LoginState> = (state = initialState, action: LoginActionTypes): LoginState => {
    switch (action.type) {
        case LOGIN_INPROGRESS:
            return {
                ...state,
                authenticated: false,
                isLoading: true,
                error: '',
            };
        case LOGIN_REQUIRES_MFA:
            return {
                ...state,
                authenticated: false,
                error: '',
                isLoading: false,
                requiresMfa: true,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                authenticated: true,
                isLoading: false,
                token: ((action.payload || {}) as TokenResult).token,
                decodedToken: AuthenticationService.decodeToken(((action.payload || {}) as TokenResult).token),
                error: '',
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                authenticated: false,
                isLoading: false,
                error: Core.API.getErrorMessage((action.payload || {}) as Error),
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                authenticated: false,
                isLoading: false,
                token: '',
                decodedToken: undefined,
                error: '',
            };
        default:
            return state;
    }
};

export default loginReducer;

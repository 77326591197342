import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../components/button';

import './styles.scss';

interface IdentityVerificationItemProps {
    birthdate?: string;
    discordHandle: string;
    email: string;
    firstName: string;
    lastName: string;
    userId: string;
    onClick?: () => void;
}

export const IdentityVerificationItem = ({
    birthdate,
    discordHandle,
    email,
    firstName,
    lastName,
    userId,
    onClick,
}: IdentityVerificationItemProps) => (
    <li className="identity-verification__item">
        <Link className="match-user__name" to={`/users/${userId}`}>
            {firstName}
        </Link>
        <Link className="match-user__name" to={`/users/${userId}`}>
            {lastName}
        </Link>
        <span>{birthdate}</span>
        <span>{email}</span>
        <span>{discordHandle}</span>
        <Button onClick={() => onClick?.()} semiRound>
            Review
        </Button>
    </li>
);

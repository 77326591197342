import React from 'react';
import classnames from 'classnames';

import * as Core from '../../../core';

import './rule.scss';

interface RuleProps {
    className?: string;
    gutterPull?: boolean;
    shade?: Core.Models.Shades;
    type: 'horizontal' | 'vertical';
}

export const Rule = (props: RuleProps) => {
    const { className, gutterPull, shade, type } = props;
    const ruleClasses = classnames(className, 'ds-rule', `ds-rule--${type}`, `ds-rule--${shade}`, {
        'ds-rule--pull': gutterPull && type === 'horizontal',
    });
    return <div className={ruleClasses} />;
};

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Scrolls down to element with ID if URL contains an anchor.
const useHashLinks = () => {
    const { pathname, hash, key } = useLocation();
    useEffect(() => {
        if (hash === '') {
            window.scrollTo(0, 0);
            return;
        }
        const timeout = setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            const header = document.getElementById('header');
            if (!!element && !!header) {
                const y = element.getBoundingClientRect().top - header.getBoundingClientRect().height + window.scrollY;
                window.scrollTo({ top: y, behavior: 'smooth' });
            }
        }, 0);
        return () => clearTimeout(timeout);
    }, [pathname, hash, key]); // `pathname`, `key` updates should rerun effect
};

export default useHashLinks;

import { AppAction } from '..';

export interface CookiesState extends CookiePreferences {
    lastUpdatedUtc?: number;
}

export interface CookiePreferences {
    performance: boolean;
    strictlyNecessary: boolean;
}

export const COOKIES_SUCCESS = 'COOKIES_SUCCESS';

export interface CookiesSuccess extends AppAction<CookiePreferences, typeof COOKIES_SUCCESS> {
    payload: CookiePreferences;
    type: typeof COOKIES_SUCCESS;
}

export type CookiesActionTypes = CookiesSuccess;

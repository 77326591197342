import React, { useMemo } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { Button } from '../../../../components/button';
import FormField from '../../../../components/formField';
import InfoMessage from '../../../../components/infoMessage';
import Loading from '../../../../components/loading';

interface AdminAgreementProps {
    email?: string;
    onSubmit: () => void;
}

interface AdminAgreementValues {
    hasRequestedEmail: boolean;
    isReputableSource: boolean;
}

const AdminAgreement = (props: AdminAgreementProps): JSX.Element => {
    const { email, onSubmit } = props;
    const code = useMemo(() => Math.floor(100000 + Math.random() * 900000), []);

    return (
        <div className="admin-agreement">
            <Formik<AdminAgreementValues>
                initialValues={{
                    hasRequestedEmail: false,
                    isReputableSource: false,
                }}
                onSubmit={onSubmit}
                validationSchema={Yup.object().shape({
                    isReputableSource: Yup.boolean().oneOf([true], 'You must agree that the source is reputable.'),
                    hasRequestedEmail: Yup.boolean().oneOf(
                        [true],
                        'You must agree that you confirmed ownership of this email address.'
                    ),
                })}
                render={(formProps: FormikProps<AdminAgreementValues>) => (
                    <Form>
                        <FormField
                            description="I confirm that this request comes from a reputable source and/or does not appear fraudulent."
                            name="isReputableSource"
                            type="checkbox"
                        />
                        <FormField
                            description={`I have requested and received an email from ${
                                email || 'this user'
                            } with the code ${code}, which confirms their ownership of this email address.`}
                            name="hasRequestedEmail"
                            type="checkbox"
                        />

                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                        <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                        <fieldset className="form-group form-group--undecorated">
                            {formProps.isSubmitting && <Loading buttonLoader />}
                            <Button onClick={formProps.submitForm} disabled={formProps.isSubmitting} wide round>
                                Continue
                            </Button>
                        </fieldset>
                    </Form>
                )}
            />
        </div>
    );
};

export default AdminAgreement;

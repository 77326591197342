import React, { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { isNumber, sortBy } from 'lodash';
import { Link } from 'react-router-dom';

import * as Core from '../../../core';
import MatchUser from '../../../pages/match/matchUserList/matchUser';
import { sortMembers } from '../../../pages/match/matchUserList/matchUserList';
import { Avatar } from '../../buttons-visuals';
import Menu from '../../menu';
import ParticipantLink from '../../participantLink';
import MatchParticipantStation from '../matchParticipantStation';

import './competitionGroupItem.scss';

interface CompetitionGroupItemProps {
    canEdit: boolean;
    canEditSeason: boolean;
    enableHighlight?: boolean;
    game?: {
        gameHandleSource?: Core.Models.GameHandleSource;
        name: string;
        rankings: { [key: string]: number };
        rankingTerm: string;
        rankingType: Core.Models.RankingType;
    };
    isMyParticipant?: (participant: { teamId?: string }) => boolean;
    isPreview: boolean;
    location?: Core.Models.LeagueLocation;
    matchCurrentState: Core.Models.MatchState;
    onForfeit?: (participant: Core.Models.MatchParticipant) => void;
    onRemove?: (participant: Core.Models.MatchParticipant) => void;
    onStationSelect?: (id: string) => void;
    participant: Core.Models.MatchParticipant;
    scoringType: Core.Models.ScoringType;
    showScore?: boolean;
}

export const CompetitionGroupItem = (props: CompetitionGroupItemProps): JSX.Element => {
    const {
        canEdit,
        canEditSeason,
        enableHighlight,
        game,
        isMyParticipant,
        isPreview,
        location,
        matchCurrentState,
        onForfeit,
        onRemove,
        onStationSelect,
        participant,
        scoringType,
        showScore,
    } = props;

    const myParticipant = isMyParticipant && isMyParticipant(participant);

    const renderMenu = useCallback((): JSX.Element | null => {
        if (!canEditSeason) return null;
        if (isPreview) return null;
        // TODO switch with PullContainer
        return (
            <Menu className="competition-group-item__menu">
                {onForfeit && participant.isParticipating && (
                    <button onClick={() => onForfeit(participant)}>
                        {participant.hasForfeited ? 'Undo forfeit' : 'Forfeit'}
                    </button>
                )}
                {onRemove && matchCurrentState < Core.Models.MatchState.InProgress && (
                    <button onClick={() => onRemove(participant)}>Remove</button>
                )}
            </Menu>
        );
    }, [canEditSeason, isPreview, matchCurrentState, onForfeit, onRemove, participant]);

    const parts = useMemo(
        () =>
            [
                participant.participantId && (
                    <Avatar
                        className="competition-group-item__avatar"
                        fallback="team"
                        size="medium"
                        src={participant.avatarUrl || participant.organizationLogoUrl}
                    />
                ),
                <div className="competition-group-item__label">
                    {participant.previousMatchId && !participant.participantId ? (
                        <Link
                            className="competition-group-item__label__name"
                            to={`/matches/${participant.previousMatchId}`}
                        >
                            TBD
                        </Link>
                    ) : (
                        <ParticipantLink
                            className="competition-group-item__label__name"
                            participant={participant}
                            unstyled
                        >
                            {!participant.id ? 'Bye' : participant.name ?? <em>Deleted team</em>}
                        </ParticipantLink>
                    )}
                    {!isPreview && (
                        <MatchParticipantStation
                            {...{
                                canEditSeason,
                                competitionStyle: Core.Models.CompetitionStyle.Leaderboard,
                                currentState: matchCurrentState,
                                location,
                                onStationSelect,
                                participant,
                            }}
                        />
                    )}
                </div>,
                showScore && (
                    <div className="competition-group-item__score">
                        {participant.hasForfeited || !isNumber(participant.score) ? (
                            '-'
                        ) : scoringType === Core.Models.ScoringType.Binary ? (
                            <FontAwesomeIcon icon={['fas', 'check']} />
                        ) : (
                            participant.score
                        )}
                    </div>
                ),
                renderMenu(),
            ].map((i, ix) => <React.Fragment key={ix}>{i}</React.Fragment>),
        [participant, renderMenu, scoringType, showScore]
    );

    return (
        <>
            <div
                className={classNames('competition-group-item', {
                    'competition-group-item--emphasize': enableHighlight && participant.isWinner,
                    'competition-group-item--bye': !participant.participantId,
                    'competition-group-item--my-participant': myParticipant,
                    'competition-group-item--forfeited': participant.hasForfeited,
                    'competition-group-item--user-list': participant.users.length > 0,
                })}
            >
                {parts}
            </div>
            {participant.users.length > 0 && (
                <div className="competition-group-item__users">
                    {sortBy(participant.users, sortMembers).map(
                        (user: Core.Models.MatchParticipantUser, index: number) => (
                            <MatchUser
                                canEdit={canEdit}
                                checkedIn={false}
                                clickable={false}
                                game={game}
                                handleUserClick={() => undefined}
                                key={index}
                                requireGameRanks={false}
                                requireHandleForCheckin={!!game?.gameHandleSource?.oAuthProviderId}
                                selected={false}
                                showCheckbox={false}
                                user={user}
                            />
                        )
                    )}
                </div>
            )}
        </>
    );
};

export default CompetitionGroupItem;

import * as React from 'react';
import classNames from 'classnames';
import { chain, fromPairs, orderBy, pullAt } from 'lodash';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DraggingStyle,
    NotDraggingStyle,
    DropResult,
} from 'react-beautiful-dnd';
import { RouteComponentProps, Redirect } from 'react-router';

import * as Core from '../../core';
import { Button } from '../../components/button';
import { Avatar } from '../../components/buttons-visuals';
import ConfirmModal from '../../components/confirmModal';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import InfoMessage from '../../components/infoMessage';
import { withLoadDataDefaultConfig } from '../../components/loadData';
import Loading from '../../components/loading';
import ParticipantLink from '../../components/participantLink';
import { StageService } from '../../services/stageService';

import './index.scss';

interface StartStagePageRouteProps {
    id: string;
}

interface StartStagePageProps extends RouteComponentProps<StartStagePageRouteProps> {
    stage: Core.Models.Stage;
    groups: Core.Models.Group[];
}
interface StartStatePageState {
    doRedirect: boolean;
    elimMaxParticipantsExceeded: boolean;
    groups: Core.Models.Group[];
    isConfirming: boolean;
    isSubmitting: boolean;
    submitError?: string;
}

// the cross-group, drag-and-drop solution was influenced by this example: https://codesandbox.io/s/ql08j35j3q?file=/index.js
class StartStagePage extends React.Component<StartStagePageProps, StartStatePageState> {
    constructor(props: StartStagePageProps) {
        super(props);

        const { groups } = props;
        this.state = {
            doRedirect: false,
            elimMaxParticipantsExceeded: this.isElimMaxParticipantsExceeded(groups),
            groups,
            isConfirming: false,
            isSubmitting: false,
        };
    }

    isElimMaxParticipantsExceeded(groups: Core.Models.Group[]) {
        return (
            this.props.stage.stageTypeId === Core.Models.StageTypeId.DoubleElimination &&
            !groups.every(
                (g: Core.Models.Group) =>
                    (g.groupParticipants?.length ?? 0) <= Core.Constants.DOUBLE_ELIM_GROUP_MAX_LENGTH
            )
        );
    }

    onDragEnd(result: DropResult) {
        const { source, destination } = result;

        // dropped outside the `Droppable` items
        if (!destination) return;

        // dropped in the source slot, so nothing has changed
        if (source.droppableId === destination.droppableId && source.index === destination.index) return;

        // NOTE: to make this work, we must mutate `groups` collection based on drop destination
        // remove source participant from source group
        const sourceParticipants = pullAt(this.state.groups[+source.droppableId].groupParticipants!, source.index); // plural due to `pullAt` API. it will only be an array of one
        // insert source participant into destination group in intended position
        this.state.groups[+destination.droppableId].groupParticipants!.splice(
            destination.index,
            0,
            ...sourceParticipants
        );

        this.reRankParticipants(this.state.groups);
        this.setState({
            elimMaxParticipantsExceeded: this.isElimMaxParticipantsExceeded(this.state.groups),
            groups: this.state.groups,
        });
    }

    reRankParticipants(groups: Core.Models.Group[]) {
        // recalculate `stageInputRank`s
        let rank = 1;
        chain(groups)
            .flatMap((group, groupIdx) =>
                group.groupParticipants!.map((groupParticipant, groupParticipantIdx) => ({
                    groupParticipant,
                    groupParticipantIdx,
                    groupIdx,
                }))
            )
            .orderBy([
                (x) => x.groupIdx,
                (x) => (x.groupIdx % 2 === 0 ? x.groupParticipantIdx : -1 * x.groupParticipantIdx),
            ])
            .orderBy([
                (x) => x.groupParticipantIdx,
                (x) => (x.groupParticipantIdx % 2 === 0 ? x.groupIdx : -1 * x.groupIdx),
            ])
            .forEach((x) => (x.groupParticipant.stageInputRank = rank++))
            .value();
    }

    async startStage() {
        const { stage } = this.props;

        this.setState({ isSubmitting: true, isConfirming: false, submitError: undefined });
        try {
            await StageService.start({
                id: stage.id,
                // this is how data gets submitted, so we need to at least make sure we recalculate `group` entities
                // in memory to ensure that the grouping and order is persisted to the database. the primary operations are
                // moving `groupParticipants` between groups and then setting their `stageInputRank`
                groupedRankedParticipants: this.state.groups.map((group) => {
                    const groupParticipants = orderBy(
                        group.groupParticipants!,
                        (groupParticipant) => groupParticipant.stageInputRank
                    );
                    return fromPairs(
                        groupParticipants.map((groupParticipant) => [
                            groupParticipant.participantId,
                            groupParticipant.stageInputRank,
                        ])
                    );
                }),
            });

            this.setState({ doRedirect: true });
        } catch (e) {
            this.setState({ submitError: Core.API.getErrorMessage(e) });
        } finally {
            this.setState({ isSubmitting: false });
        }
    }

    render(): JSX.Element {
        const { stage } = this.props;

        if (this.state.doRedirect) {
            return <Redirect to={`/seasons/${stage.seasonId}/${stage.id}`} />;
        }

        if (stage.currentState > Core.Models.StageState.CheckingIn) {
            return (
                <div className="page start-stage">
                    <h2 className="start-stage__header">Starting {stage.name}</h2>
                    <div className="start-stage__instructions">
                        <p>This stage has already been started.</p>
                        <Button
                            onClick={() => this.setState({ doRedirect: true })}
                            styleType={Core.Models.StyleType.Primary}
                            outline
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            );
        }

        const getListStyle = (isDraggingOver: boolean) => ({});

        const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
            // styles we need to apply on draggables
            ...draggableStyle,
        });

        // IMPORTANT: call this first because we can't trust server values for `stageInputRank`
        this.reRankParticipants(this.state.groups);

        const singleGroup = this.state.groups.length === 1;
        return (
            <div className="page start-stage">
                <h2 className="start-stage__header">Start {stage.name}</h2>
                <p className="start-stage__instructions">
                    Make sure the correct participants are configured for the stage and that they are ranked properly.
                    Once the stage is started, you cannot make any changes to the participants or their ranks.{' '}
                    {singleGroup
                        ? 'One group will be created with the participants ranked as follows:'
                        : 'The following groups will be created:'}
                </p>
                <div className={classNames({ 'start-stage__groups': !singleGroup })}>
                    <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                        {this.state.groups.map((group: Core.Models.Group, groupIdx: number) => (
                            <div key={groupIdx} className="start-stage__group">
                                {!singleGroup && <div className="start-stage__group-name">{group.name}</div>}
                                <div className="start-stage__participant-header">
                                    <span className="start-stage__participant-header-group-rank">
                                        {!singleGroup && 'Group '}Rank
                                    </span>
                                    {!singleGroup && (
                                        <span className="start-stage__participant-header-stage-rank">Stage Rank</span>
                                    )}
                                    <span className="start-stage__participant-header-name">Participant</span>
                                    {(group.groupParticipants?.filter(
                                        (gp: Core.Models.GroupParticipant) => !!gp.suggestedInputRank
                                    )?.length ?? 0) > 0 && (
                                        <span className="start-stage__participant-header-suggested-rank">
                                            Suggested Rank
                                        </span>
                                    )}
                                </div>

                                <Droppable droppableId={groupIdx.toString()}>
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                            {group.groupParticipants &&
                                                group.groupParticipants.map((participant, participantIdx) => (
                                                    <Draggable
                                                        key={participant.participantId}
                                                        draggableId={participant.participantId}
                                                        index={participantIdx}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <div
                                                                    className="start-stage__participant"
                                                                    key={participantIdx}
                                                                >
                                                                    <div
                                                                        className="start-stage__participant__handle"
                                                                        aria-hidden="true"
                                                                    ></div>
                                                                    <span className="start-stage__participant-group-rank">
                                                                        {participantIdx + 1}
                                                                    </span>
                                                                    {!singleGroup && (
                                                                        <span className="start-stage__participant-stage-rank">
                                                                            {participant.stageInputRank}
                                                                        </span>
                                                                    )}
                                                                    <span className="start-stage__participant-avatar">
                                                                        <Avatar
                                                                            className="mr2x"
                                                                            fallback="team"
                                                                            size="xsmall"
                                                                            src={
                                                                                participant.avatarUrl ||
                                                                                participant.organizationLogoUrl
                                                                            }
                                                                        />
                                                                    </span>
                                                                    <ParticipantLink
                                                                        participant={participant}
                                                                        className="start-stage__participant-name"
                                                                    >
                                                                        {participant.name}
                                                                    </ParticipantLink>
                                                                    {group.groupParticipants!.filter(
                                                                        (gp: Core.Models.GroupParticipant) =>
                                                                            !!gp.suggestedInputRank
                                                                    ).length > 0 && (
                                                                        <span className="start-stage__participant-suggested-rank">
                                                                            {participant.suggestedInputRank}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        ))}
                    </DragDropContext>
                </div>
                {this.state.submitError && <InfoMessage message={this.state.submitError} type="error" />}
                <p className="start-stage__button-instructions">
                    Click 'start stage' below to lock these participants and start the stage.
                </p>
                <div className="form-group form-group--undecorated">
                    {this.state.isSubmitting && <Loading buttonLoader />}
                </div>
                {this.state.elimMaxParticipantsExceeded && (
                    <InfoMessage
                        message={`Double Elimination groups only support ${Core.Constants.DOUBLE_ELIM_GROUP_MAX_LENGTH} participants or fewer. Either add more groups to the Stage or rearrange group participants.`}
                        type="error"
                    />
                )}
                <div className="form-group form-group--undecorated start-stage__buttons">
                    <Button
                        onClick={() => this.setState({ isConfirming: true })}
                        disabled={
                            this.state.isSubmitting || this.state.isConfirming || this.state.elimMaxParticipantsExceeded
                        }
                        styleType={Core.Models.StyleType.Primary}
                    >
                        Start stage
                    </Button>
                    <Button
                        onClick={() => this.setState({ doRedirect: true })}
                        outline
                        styleType={Core.Models.StyleType.Primary}
                    >
                        Cancel
                    </Button>
                </div>
                {this.state.isConfirming && (
                    <ConfirmModal
                        onCancel={() => this.setState({ isConfirming: false })}
                        onConfirm={this.startStage.bind(this)}
                        title="Are you sure?"
                    >
                        <p>
                            Are you sure you want to start <strong>{stage.name}</strong>?
                        </p>
                    </ConfirmModal>
                )}
            </div>
        );
    }
}

export default withLoadDataDefaultConfig(
    errorLoadingWrapperHOC<StartStagePageProps>(StartStagePage, {
        errorDisplayPageOptions: { fullPage: true },
        loadingOptions: { blockItem: true },
    }),
    (props: StartStagePageProps) => props.match.params.id,
    async (id) => {
        const [stage, groups] = await Promise.all([StageService.getById(id), StageService.prepare(id)]);
        // Only return groups that contain participants.
        // Prevents Win/Finale brackets from displaying to person when stage is Double Elimination.
        return {
            stage,
            groups: chain(groups)
                .filter((i) => typeof i.groupParticipants !== 'undefined' && i.groupParticipants.length > 0)
                .orderBy((x) => x.sortOrder)
                .value(),
        };
    }
);

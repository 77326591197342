import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteComponentProps } from 'react-router';

import * as Core from '../../core';
import { SolidButton } from '../../components/buttons-visuals';
import { ContentContainer } from '../../components/containers';
import InfoMessage from '../../components/infoMessage';
import Loading from '../../components/loading';
import PaymentGatewayService from '../../services/paymentGatewayService';

interface HandleAccountLinkRouteProps {
    accountLinkType: string;
    leagueId: string;
    paymentGatewayId: string;
}

interface HandleAccountLinkProps extends RouteComponentProps<HandleAccountLinkRouteProps> {}

const HandleAccountLink = ({
    match: {
        params: { accountLinkType, leagueId, paymentGatewayId },
    },
}: HandleAccountLinkProps): JSX.Element => {
    const [error, setError] = useState<string | undefined>(undefined);

    const getAccountLink = useCallback(async () => {
        try {
            const accountLink = await PaymentGatewayService.createAccountLink({
                accountLinkType: +accountLinkType,
                leagueId,
                paymentGatewayId,
            });

            window.location.href = accountLink.url;
        } catch (e) {
            const message = Core.API.getErrorMessage(e);
            setError(message);
        }
    }, [accountLinkType, leagueId, paymentGatewayId, setError]);

    useEffect(() => {
        (async () => {
            await getAccountLink();
        })();
    }, []);

    return (
        <ContentContainer
            className="global-container-centered"
            radius={Core.Models.RadiusSizes.Large}
            shade={Core.Models.Shades.Dark40}
            style={{ marginTop: '10rem' }}
        >
            <div className="pt2x text-center">
                {!!error ? (
                    <FontAwesomeIcon className="color-error" icon={['fas', 'circle-exclamation']} size="4x" />
                ) : (
                    <Loading blockItem />
                )}
                <h3 className="heading-3 mt4x text-center ">Sending you to Stripe</h3>
            </div>

            {!!error && (
                <div>
                    <InfoMessage message={error} type="error" />

                    <SolidButton
                        as="button"
                        layout="full"
                        onClick={async () => {
                            setError(undefined); // clearing error shows loading state
                            await getAccountLink();
                        }}
                    >
                        Try again
                    </SolidButton>
                </div>
            )}
        </ContentContainer>
    );
};

export default HandleAccountLink;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pluralize from 'pluralize';

import { useLocations } from './hooks';
import { ListItem, TabItem } from './types';
import { AddButton, IconButton } from '../../../components/buttons-visuals';
import {
    useCreateLocationModal,
    useDeleteLocationModal,
    useUpdateLocationModal,
} from '../../../components/modifyLocationModal';
import {
    useCreateLocationStationModal,
    useDeleteLocationStationModal,
    useUpdateLocationStationModal,
} from '../../../components/modifyLocationStationModal';
import TabNav from '../../../components/tabNav';
import withLoading, { WithLoadingProps } from '../../../components/withLoading';

import './index.scss';

const Locations = (props: WithLoadingProps): JSX.Element => {
    const {
        activeItemIndex,
        activeItems,
        activeTabIndex,
        addLocationTab,
        addStationItem,
        removeLocationTab,
        removeStationItem,
        setActiveItemIndex,
        setActiveTabIndex,
        tabs,
        updateLocationTab,
        updateStationItem,
    } = useLocations(props);

    const [createLocationModal, showCreateLocation] = useCreateLocationModal({
        onSubmitted: addLocationTab,
    });

    const [createStationModal, showCreateStation] = useCreateLocationStationModal({
        locationId: tabs[activeTabIndex]?.locationId ?? null,
        onSubmitted: addStationItem,
    });

    const [removeLocationModal, showRemoveLocation] = useDeleteLocationModal({
        locationId: tabs[activeTabIndex]?.locationId ?? null,
        onDeleted: removeLocationTab,
    });

    const [removeStationModal, showRemoveStation] = useDeleteLocationStationModal({
        stationId: activeItems[activeItemIndex ?? 0]?.stationId ?? null,
        onDeleted: removeStationItem,
    });

    const [editLocationModal, showEditLocation] = useUpdateLocationModal({
        locationId: tabs[activeTabIndex]?.locationId ?? null,
        onDelete: showRemoveLocation,
        onSubmitted: updateLocationTab,
        values: {
            description: tabs[activeTabIndex]?.locationDescription ?? '',
            name: tabs[activeTabIndex]?.locationName ?? '',
        },
    });

    const [editStationModal, showEditStation] = useUpdateLocationStationModal({
        stationId: activeItems[activeItemIndex ?? 0]?.stationId ?? null,
        onDelete: showRemoveStation,
        onSubmitted: updateStationItem,
        values: {
            description: activeItems[activeItemIndex ?? 0]?.stationDescription ?? '',
            name: activeItems[activeItemIndex ?? 0]?.stationName ?? '',
            systemName: activeItems[activeItemIndex ?? 0]?.systemName ?? '',
        },
    });

    return (
        <section className="settings-page__locations">
            <AddButton
                as="button"
                buttonSize="medium"
                buttonStyle="solid"
                className="m-auto mb4x"
                layoutInline
                onClick={() => showCreateLocation()}
            >
                Add Location
            </AddButton>
            <div className="settings-page__locations__tabs">
                <TabNav
                    items={[
                        ...tabs.map((tabItem: TabItem, index: number) => ({
                            id: tabItem.locationId,
                            contents: (
                                <div className="settings-page__locations__tabs__tab">
                                    <span className="settings-page__locations__tabs__tab__heading">
                                        {tabItem.locationName}
                                    </span>
                                    <span className="settings-page__locations__tabs__tab__sub-heading">
                                        {tabItem.stations.length
                                            ? `${tabItem.stations.length} ${pluralize(
                                                  'Station',
                                                  tabItem.stations.length
                                              )}`
                                            : 'Empty'}
                                    </span>
                                    {activeTabIndex === index && (
                                        <IconButton
                                            as="button"
                                            buttonLabel="Edit location"
                                            buttonSize="small"
                                            className="settings-page__locations__tabs__tab__edit"
                                            onClick={showEditLocation}
                                        >
                                            <FontAwesomeIcon icon={['fas', 'edit']} />
                                        </IconButton>
                                    )}
                                </div>
                            ),
                            onClick: () => setActiveTabIndex(index),
                            name: tabItem.locationName,
                        })),
                    ]}
                    className="mb2x"
                    current={activeTabIndex}
                />
            </div>
            <ul className="settings-page__locations__stations">
                {activeItems.map(({ stationId, stationName }: ListItem, index: number) => (
                    <li
                        key={stationId}
                        className="settings-page__locations__stations__item settings-page__locations__stations__item--location"
                    >
                        <FontAwesomeIcon className="mr" icon={['fas', 'tv']} />
                        <span>{stationName}</span>
                        <IconButton
                            as="button"
                            buttonLabel="Edit Station"
                            buttonSize="small"
                            className="settings-page__locations__stations__item__edit"
                            onClick={() => {
                                showEditStation();
                                setActiveItemIndex(index);
                            }}
                        >
                            <FontAwesomeIcon icon={['fas', 'edit']} />
                        </IconButton>
                    </li>
                ))}
                {!!tabs.length && (
                    <li>
                        <AddButton
                            as="button"
                            buttonStyle="hollow"
                            className="settings-page__locations__stations__item settings-page__locations__stations__item--new"
                            onClick={showCreateStation}
                            iconSize="lg"
                        >
                            Add Station
                        </AddButton>
                    </li>
                )}
            </ul>

            {createLocationModal}
            {createStationModal}
            {editLocationModal}
            {editStationModal}
            {removeLocationModal}
            {removeStationModal}
        </section>
    );
};

export default withLoading(Locations, { loadingProps: { blockItem: true } });

import React, { forwardRef, useImperativeHandle, useRef, useMemo } from 'react';
import classNames from 'classnames';
import { uniqueId } from 'lodash';

import InfoMessage from '../../../components/infoMessage';
import { InputProps } from '../Inputs.types';

import './TextInput.scss';

const TextInput = forwardRef((props: InputProps, forwardedRef) => {
    const { className, error, errorMessage, id, label, layoutInline, limit, placeholder, value, ...rest } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(forwardedRef, () => inputRef.current);
    const focusInput = () => {
        inputRef.current?.focus();
    };

    const inputId = useMemo(() => id || uniqueId('text-input-'), [id]);
    const detailsId = useMemo(() => uniqueId('text-input-details-'), []);

    const fieldClassName = classNames(className, 'ds-text-field', { error: error, 'fit-content': layoutInline });
    const FieldWrapperClassName = classNames('ds-text-field__wrapper', {
        'ds-text-field__wrapper--error': error || errorMessage,
    });
    const InputClassName = classNames('ds-text-field__wrapper__input');

    return (
        <div className={fieldClassName} onClick={focusInput}>
            <div className={FieldWrapperClassName}>
                <input
                    {...rest}
                    aria-describedby={`${detailsId} ${inputId}`}
                    className={InputClassName}
                    form={undefined}
                    id={inputId}
                    placeholder=" "
                    ref={inputRef}
                    value={value}
                />
                <label htmlFor={inputId} className="ds-text-field__wrapper__label">
                    {label}
                </label>
                {!!limit && (
                    <div
                        className={classNames(
                            'ds-text-field__wrapper__limit',
                            (value?.toString().length ?? 0) > limit && 'max'
                        )}
                    >
                        {value?.toString().length.toLocaleString() ?? 0} / {limit.toLocaleString()}
                    </div>
                )}
            </div>
            {props['aria-describedby'] && (
                <p className="ds-text-field__message ds-text-field__message--helpText" id={detailsId}>
                    {props['aria-describedby']}
                </p>
            )}
            {errorMessage && (
                <InfoMessage
                    className="ds-text-field__message ds-text-field__message--error"
                    message={errorMessage}
                    type="error"
                />
            )}
        </div>
    );
});

export default TextInput;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Core from '../../core';
import Counter from './counter';
import { useChatContext } from '../../contexts/chatContext';
import { useHasAnyLeagueMembershipRole, useLeague } from '../../hooks/store';
import { IconButton } from '../buttons-visuals';

const Chat = (): JSX.Element => {
    const league = useLeague();
    const { unreadThreads } = useChatContext();
    const canAccessChat = useHasAnyLeagueMembershipRole(Core.Models.PermissionLevel.ListSpecific);
    const chatEnabled = !!league?.enableChat && canAccessChat;

    const totalUnreadThreads = !chatEnabled ? 0 : unreadThreads.length;

    if (!chatEnabled) return <></>;
    return (
        <div className="position-relative">
            <IconButton as="link" buttonLabel="Chat button" buttonSize="large" to={`/chat`}>
                <FontAwesomeIcon icon={['fas', 'comment']} />
            </IconButton>
            {totalUnreadThreads > 0 && <Counter content={totalUnreadThreads} />}
        </div>
    );
};

export default Chat;

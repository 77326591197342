import React from 'react';

import * as Core from '../../core';
import Bracket from './bracket';
import { useGroupEliminationBracket } from './hooks';
import { LinkButton } from '../button';
import GroupHeader from '../groupHeader';

interface GroupEliminationBracketProps {
    externalLinks: boolean;
    group: Core.Models.Group;
    showGroupName?: boolean;
    showStageName?: boolean;
    stage: Core.Models.Stage;
}

const GroupEliminationBracket: React.FunctionComponent<GroupEliminationBracketProps> = ({
    externalLinks,
    group,
    showGroupName,
    showStageName,
    stage,
}) => {
    const { brackets, groupMatches, seasonAlternateName } = useGroupEliminationBracket(group);
    return brackets.length > 0 ? (
        <div className="group-elimination-bracket">
            <GroupHeader
                className="group-elimination-bracket__name"
                {...{ group, showGroupName, showStageName, stage }}
            />
            {brackets.map((bracket: Core.Models.Bracket) => (
                <Bracket key={bracket} bracket={bracket} groupMatches={groupMatches} stageRounds={stage.rounds} />
            ))}
            <div className="vr--x2" />
            <LinkButton
                styleType={Core.Models.StyleType.OutlineOnly}
                to={`/seasons/${stage.seasonId}/${stage.id}`}
                target={externalLinks ? '_blank' : '_self'}
                rel={externalLinks ? 'noopener noreferrer' : undefined}
            >
                View {seasonAlternateName}
            </LinkButton>
        </div>
    ) : (
        <></>
    );
};

export default GroupEliminationBracket;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Formik, Form, FormikProps, FormikActions } from 'formik';
import { isNumber, orderBy } from 'lodash';
import moment from 'moment-timezone';
import pluralize from 'pluralize';
import Select, { OptionsType, SelectOption } from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../core';
import { SolidButton, TextLink } from '../../components/buttons-visuals';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';
import LiteLimitationMessage from '../../components/liteLimitationMessage';
import MarkdownPreview from '../../components/markdown/markdownPreview';
import { ToolTip } from '../../components/overlays/tooltips';
import UpsertPayable from '../../components/payments/upsertPayable';
import { TennisStylePopover, TennisStyleSetsPopover } from '../../components/tennisStylePopover';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useAlternateSeasonName, useIsLite, useLeague, useTimezone } from '../../hooks/store';
import history from '../../services/history';
import { LeagueService } from '../../services/leagueService';
import PaymentGatewayService from '../../services/paymentGatewayService';
import { SeasonService } from '../../services/seasonService';
import ChessLinksTooltip from '../season/chessLinksTooltip';
import { useDesignations } from '../leagueSettings/designations/hooks';

interface CreateSeasonProps extends WithLoadingProps {
    initialValues?: Partial<CreateSeasonFormValues>;
}

export interface CreateSeasonFormValues {
    ageMax?: number;
    ageMin?: number;
    allowFreeAgents: boolean;
    allowedTeamDesignations: string[];
    enablePublicRegistration: boolean;
    enforceMinimumRoster: boolean;
    gameId: string;
    generateChessDotComLinks: boolean;
    maxParticipants?: number;
    metadata?: string;
    name: string;
    payableId?: string;
    requireGameRanks: boolean;
    requireHandleForCheckin: boolean;
    seasonCategoryId: string;
    seasonTerms?: string;
    skillLevel: Core.Models.SkillLevel;
    startDate?: string;
    tennisStyle: boolean;
    tennisStyleSets?: number;
    timingDescription?: string;
    userLeagueRoleIds: string[];
}

export const skillLevels: number[] = Object.values(Core.Models.SkillLevel)
    .filter((value: number | string) => isNumber(value))
    .map((value: number | string) => +value);

const CreateSeason = (props: CreateSeasonProps): JSX.Element => {
    const { initialValues, setError, setIsLoading } = props;
    const { enablePaymentFeatures, enableSeasonAgeRestrictions } = useLeague()!;
    const timezone = useTimezone();

    const [games, setGames] = useState<Core.Models.Game[]>([]);
    const [payables, setPayables] = useState<Core.Models.Payable[]>([]);
    const [seasonCategories, setSeasonCategories] = useState<Core.Models.SeasonCategory[]>([]);
    const [seasonOperators, setSeasonOperators] = useState<Core.Models.LeagueMember[]>([]);
    const seasonAlternateName = useAlternateSeasonName();
    const isLite = useIsLite();

    const { designations } = useDesignations();
    const designationOptions = useMemo(
        () => designations.map((designation: Core.Models.LeagueDesignation) => ({
            label: designation.name,
            value: designation.id
        })),
        [designations]
    );

    const getMaxParticipants = useCallback(
        (minimumSeats: number) => {
            if (isLite) return Math.ceil(Core.Constants.PLANS.LITE.LIMITATIONS.MAX_PLAYERS_PER_SEASON / minimumSeats);
            return undefined;
        },
        [isLite]
    );

    useEffect(() => {
        (async () => {
            try {
                const gamesData = await LeagueService.getLeagueApprovedGames();
                setGames(gamesData);

                const seasonCategoriesData = await SeasonService.getAllSeasonCategories();
                setSeasonCategories(seasonCategoriesData);

                const payablesData = await PaymentGatewayService.getPayables(Core.Models.PayableEntityType.Season);
                setPayables(payablesData);

                const seasonOperatorsResponse = await LeagueService.getLeagueUsersByRole(
                    Core.Models.LeagueRoleId.SeasonOperator
                );
                setSeasonOperators(seasonOperatorsResponse);
            } catch (error) {
                toast.error('There was an issue loading this form. Please refresh and try again.');
                setError(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [setError, setIsLoading]);

    const schema = Yup.object().shape({
        allowFreeAgents: Yup.boolean(),
        enablePublicRegistration: Yup.boolean(),
        enforceMinimumRoster: Yup.boolean(),
        gameId: Yup.string().required('Game is required'),
        generateChessDotComLinks: Yup.boolean(),
        maxParticipants: Yup.number()
            .positive('Maximum number of teams must be positive')
            .test('lite-max', (maxParticipants: number | undefined, context) => {
                const selectedGame = games.find((g: Core.Models.Game) => g.id === context.parent.gameId);
                if (!!selectedGame) {
                    const maxParticipantsLimit = getMaxParticipants(selectedGame.minimumSeats);
                    if (
                        !!maxParticipantsLimit &&
                        (!isNumber(maxParticipants) || maxParticipants! > maxParticipantsLimit)
                    ) {
                        return context.createError({
                            message: `${Core.Constants.PLANS.LITE.NAME} only allows a maximum of ${Core.Constants.PLANS.LITE.LIMITATIONS.MAX_PLAYERS_PER_SEASON
                                } players per ${seasonAlternateName.toLowerCase()} (${maxParticipantsLimit} teams for ${selectedGame.name
                                }).`,
                            path: 'maxParticipants',
                        });
                    }
                }
                return true;
            })
            .nullable(),
        metadata: Yup.string().nullable().notRequired(),
        name: Yup.string().required('Name is required'),
        requireGameRanks: Yup.boolean(),
        requireHandleForCheckin: Yup.boolean(),
        seasonCategoryId: Yup.string().required(`${seasonAlternateName} Category is required`),
        seasonTerms: Yup.string().nullable(),
        skillLevel: Yup.number()
            .min(skillLevels[0], 'Skill level is required')
            .max(skillLevels[skillLevels.length - 1], 'Skill level is required'),
        startDate: Yup.string().nullable(),
        tennisStyle: Yup.boolean().required(),
        tennisStyleSets: Yup.number()
            .test(
                'is-required',
                'Number of tennis style sets is required if tennis style play is enabled',
                (tennisStyleSets: number | undefined, context) => {
                    return !context.parent.tennisStyle || !!tennisStyleSets;
                }
            )
            .min(1, 'Number of tennis style sets must be positive')
            .nullable(),
        timingDescription: Yup.string().nullable().required('Timing is required'),
        userLeagueRoleIds: Yup.array().of(Yup.string()),
        ...(enablePaymentFeatures && {
            payableId: Yup.string().notRequired().notOneOf(['create'], 'Choose or create an entry fee'),
        }),
        ...(enableSeasonAgeRestrictions && {
            ageMax: Yup.number()
                .min(8, 'Maximum age must be greater than or equal to 8')
                .test(
                    'greater-than-min',
                    'Maximum age must be larger than minimum age',
                    (ageMax: number | undefined, context) => {
                        const ageMin = context.parent.ageMin;
                        if (isNumber(ageMax) && isNumber(ageMin) && ageMax < ageMin) return false;
                        return true;
                    }
                )
                .nullable(),
            ageMin: Yup.number().min(8, 'Minimum age must be greater than or equal to 8').nullable(),
        }),
        allowedTeamDesignations: Yup.array().of(Yup.string()),
    });

    return (
        <Formik<CreateSeasonFormValues>
            initialValues={Object.assign(
                {
                    allowFreeAgents: false,
                    allowedTeamDesignations: [],
                    enablePublicRegistration: false,
                    enforceMinimumRoster: true,
                    gameId: games.length === 1 ? games[0].id : '',
                    generateChessDotComLinks: false,
                    metadata: '',
                    name: '',
                    payableId: '',
                    requireGameRanks: false,
                    requireHandleForCheckin: false,
                    seasonCategoryId: seasonCategories.length === 1 ? seasonCategories[0].id : '',
                    skillLevel: Core.Models.SkillLevel.All,
                    startDate: '',
                    tennisStyle: false,
                    tennisStyleSets: undefined,
                    timingDescription: '',
                    userLeagueRoleIds: [],
                },
                initialValues
            )}
            validationSchema={schema}
            onSubmit={async (values: CreateSeasonFormValues, actions: FormikActions<CreateSeasonFormValues>) => {
                actions.setStatus(undefined);
                try {
                    const selectedGame = games.find((g: Core.Models.Game) => g.id === values.gameId);

                    if (values.requireGameRanks && !Core.Competition.hasRankings(selectedGame)) {
                        values.requireGameRanks = false;
                    }

                    let titleConfiguration: Core.Models.ChessConfiguration | undefined = undefined;
                    if (!!selectedGame) {
                        switch (selectedGame.featureSet) {
                            case Core.Models.FeatureSet.Chess:
                                titleConfiguration = { generateChessDotComLinks: values.generateChessDotComLinks };
                                break;
                        }
                    }

                    const season = await SeasonService.create({
                        ...values,
                        startDate: !!values.startDate
                            ? moment.tz(values.startDate, timezone).format(Core.Time.getFormat())
                            : undefined,
                        titleConfiguration,
                    });
                    history.push(`/seasons/${season.id}`);
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(formProps: FormikProps<CreateSeasonFormValues>) => {
                const { gameId, userLeagueRoleIds } = formProps.values;
                const options = seasonOperators
                    .filter((lm: Core.Models.LeagueMember) => !userLeagueRoleIds.includes(lm.userLeagueRoleId))
                    .map(
                        (lm: Core.Models.LeagueMember) =>
                            ({
                                label: lm.userId ? Core.Identity.renderMemberName(lm) : lm.email,
                                value: lm.userLeagueRoleId,
                            }) as SelectOption
                    );
                const selectedGame = games.find((g: Core.Models.Game) => g.id === gameId);
                const selectedGameHandleSource = selectedGame?.gameHandleSource;
                const teamWord = !!selectedGame && selectedGame.minimumSeats > 1 ? 'Team' : 'Player';

                return (
                    <Form className="form league-page__create-season">
                        <fieldset className="form-group">
                            <FormField type="text" name="name" description="Name" />
                            <FormField
                                component="date"
                                label={`Start Date (${Core.Time.getFormat()})`}
                                name="startDate"
                            />
                            <FormField
                                component="select"
                                name="seasonCategoryId"
                                label={`${seasonAlternateName} Category`}
                            >
                                <option value="" hidden disabled>
                                    --
                                </option>
                                {orderBy(seasonCategories, (sc: Core.Models.SeasonCategory) => sc.sortOrder).map(
                                    (sc: Core.Models.SeasonCategory) => (
                                        <option key={sc.id} value={sc.id}>
                                            {sc.name}
                                        </option>
                                    )
                                )}
                            </FormField>
                            <FormField component="select" description="Skill Level" name="skillLevel">
                                <option value={Core.Models.SkillLevel.All}>
                                    {Core.Models.SKILL_LEVEL_STRINGS[Core.Models.SkillLevel.All]}
                                </option>
                                <option value={Core.Models.SkillLevel.Beginner}>
                                    {Core.Models.SKILL_LEVEL_STRINGS[Core.Models.SkillLevel.Beginner]}
                                </option>
                                <option value={Core.Models.SkillLevel.Intermediate}>
                                    {Core.Models.SKILL_LEVEL_STRINGS[Core.Models.SkillLevel.Intermediate]}
                                </option>
                                <option value={Core.Models.SkillLevel.Advanced}>
                                    {Core.Models.SKILL_LEVEL_STRINGS[Core.Models.SkillLevel.Advanced]}
                                </option>
                                <option value={Core.Models.SkillLevel.SuperAdvanced}>
                                    {Core.Models.SKILL_LEVEL_STRINGS[Core.Models.SkillLevel.SuperAdvanced]}
                                </option>
                            </FormField>
                            {enableSeasonAgeRestrictions && (
                                <>
                                    <FormField
                                        type="number"
                                        name="ageMin"
                                        description="Min Age (Optional)"
                                        className="form-field--split"
                                    />
                                    <FormField
                                        type="number"
                                        name="ageMax"
                                        description="Max Age (Optional)"
                                        className="form-field--split"
                                    />
                                </>
                            )}
                            <FormField
                                description="Timing"
                                name="timingDescription"
                                aria-describedby={`e.g., 'Mondays at 5pm' or 'September 10th'`}
                                type="text"
                            />
                            <FormField
                                component="select"
                                label="Game"
                                name="gameId"
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    if (!isLite) return;
                                    const selectedGame = games.find((g: Core.Models.Game) => g.id === e.target.value);
                                    if (!!selectedGame) {
                                        const maxParticipantsLimit = getMaxParticipants(selectedGame.minimumSeats);
                                        const maxParticipants = formProps.values.maxParticipants;
                                        if (
                                            !!maxParticipantsLimit &&
                                            (!maxParticipants || maxParticipants > maxParticipantsLimit)
                                        ) {
                                            formProps.setFieldValue('maxParticipants', maxParticipantsLimit);
                                        }
                                    }
                                }}
                            >
                                <option value="" hidden disabled>
                                    --
                                </option>
                                {orderBy(games, (game: Core.Models.Game) => game.name).map((game: Core.Models.Game) => (
                                    <option key={game.id} value={game.id}>
                                        {game.name}
                                    </option>
                                ))}
                            </FormField>
                            {!!selectedGame?.supportsTennisStyle && (
                                <>
                                    <div className="disp-flex">
                                        <FormField
                                            description="Use tennis-style gameplay"
                                            name="tennisStyle"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                formProps.setFieldValue(
                                                    'tennisStyleSets',
                                                    e.target.checked ? 1 : undefined
                                                );
                                            }}
                                            type="checkbox"
                                        />
                                        <TennisStylePopover showSeasonStartedWarning />
                                    </div>
                                    {formProps.values.tennisStyle && (
                                        <div className="disp-flex">
                                            <FormField
                                                className="mb0 mr"
                                                description="Number of sets"
                                                name="tennisStyleSets"
                                                type="number"
                                            />
                                            <TennisStyleSetsPopover showSeasonStartedWarning />
                                        </div>
                                    )}
                                </>
                            )}
                            <div className="text-small mb3x">
                                Note: go to{' '}
                                <TextLink
                                    to="/league-settings/games"
                                    className={classNames('text-small', games.length <= 0 && 'attention')}
                                >
                                    league settings
                                </TextLink>{' '}
                                to add games
                            </div>
                        </fieldset>

                        {enablePaymentFeatures && (
                            <fieldset className="form-group">
                                <FormField component="select" name="payableId" description="Choose Entry Fee">
                                    <option key="0" value="">
                                        Free
                                    </option>
                                    {orderBy(payables, (payable: Core.Models.Payable) => payable.name).map(
                                        (payable: Core.Models.Payable) => (
                                            <option key={payable.id} value={payable.id}>
                                                {payable.name} - {Core.Currency.convertNumberToCurrency(payable.fee)}
                                            </option>
                                        )
                                    )}
                                    <option key="-1" value="create">
                                        Create new
                                    </option>
                                </FormField>
                            </fieldset>
                        )}
                        {formProps.values.payableId === 'create' && (
                            <div className="p2x">
                                <UpsertPayable
                                    onCompleted={async (payable: Core.Models.Payable) => {
                                        // add to dropdown
                                        setPayables(
                                            orderBy(
                                                [...payables, payable],
                                                (payable: Core.Models.Payable) => payable.name
                                            )
                                        );

                                        // switch value to newly created payable, which will close creation component
                                        formProps.setFieldValue('payableId', payable.id);
                                    }}
                                />
                            </div>
                        )}

                        {isLite && (
                            <LiteLimitationMessage
                                message={`${Core.Constants.PLANS.LITE.NAME
                                    } only allows for a maximum of 50 players per season${!!selectedGame && selectedGame.minimumSeats > 1
                                        ? ` (${getMaxParticipants(selectedGame.minimumSeats)} teams for ${selectedGame.name
                                        })`
                                        : ''
                                    }.`}
                            />
                        )}
                        <fieldset className="form-group">
                            <FormField
                                description={`Maximum Number of ${pluralize(teamWord)}${isLite ? '' : ' (optional)'}`}
                                name="maxParticipants"
                                type="number"
                            />
                        </fieldset>

                        <fieldset className="form-group">
                            <FormField
                                component="textarea"
                                name="seasonTerms"
                                description={`${seasonAlternateName} Terms`}
                                placeholder={`Optionally add terms that users must agree to in order to join this ${seasonAlternateName.toLowerCase()}. This field allows markdown formatting.`}
                            />
                        </fieldset>

                        <MarkdownPreview
                            fieldName={`${seasonAlternateName} Terms`}
                            source={formProps.values.seasonTerms}
                        />

                        <fieldset className="form-group">
                            <FormField
                                component="textarea"
                                name="metadata"
                                description={`${seasonAlternateName} Metadata`}
                                placeholder="Enter information about schedule, format, timing, etc. here. This field allows markdown formatting."
                            />
                        </fieldset>

                        <MarkdownPreview
                            fieldName={`${seasonAlternateName} Metadata`}
                            source={formProps.values.metadata}
                        />

                        {seasonOperators.length > 0 && (
                            <fieldset className="form-group">
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isMulti
                                    onChange={(selectedOptions: OptionsType<SelectOption>) => {
                                        formProps.setFieldValue(
                                            'userLeagueRoleIds',
                                            selectedOptions.map((option: SelectOption) => option.value)
                                        );
                                    }}
                                    options={options}
                                    name="userLeagueRoleIds"
                                    placeholder={`${seasonAlternateName} Operator(s) (optional)`}
                                    styles={{ menu: (provided: any) => ({ ...provided, zIndex: 2 }) }}
                                />
                            </fieldset>
                        )}

                        {!!selectedGame && Core.Competition.hasRankings(selectedGame) && (
                            <fieldset className="form-group form-group--undecorated">
                                <FormField
                                    className="form-field-type-checkbox--styled-label form-field-type-checkbox__season"
                                    description={`Require users to provide their ${selectedGame.rankingTerm
                                        } to join this ${seasonAlternateName.toLowerCase()}`}
                                    name="requireGameRanks"
                                    type="checkbox"
                                />
                            </fieldset>
                        )}

                        {!!selectedGameHandleSource && (
                            <fieldset className="form-group form-group--undecorated">
                                <FormField
                                    className="form-field-type-checkbox--styled-label form-field-type-checkbox__season"
                                    description={`Require users to enter ${selectedGameHandleSource.name} to check-in`}
                                    name="requireHandleForCheckin"
                                    type="checkbox"
                                />
                            </fieldset>
                        )}

                        {selectedGame?.featureSet === Core.Models.FeatureSet.Chess &&
                            !!selectedGame.gameHandleSource && (
                                <fieldset className="form-group form-group--undecorated">
                                    <FormField
                                        className="form-field-type-checkbox--styled-label form-field-type-checkbox__season"
                                        label={
                                            <>
                                                <span>Generate Chess.com challenge links for each game</span>
                                                <ChessLinksTooltip rosterSize={selectedGame.minimumSeats} />
                                            </>
                                        }
                                        name="generateChessDotComLinks"
                                        type="checkbox"
                                    />
                                </fieldset>
                            )}

                        <fieldset className="form-group form-group--undecorated">
                            <FormField
                                description={`Allow free agents to sign up for this ${seasonAlternateName.toLowerCase()}`}
                                name="allowFreeAgents"
                                type="checkbox"
                            />
                        </fieldset>

                        {!!selectedGame && (
                            <fieldset className="form-group form-group--undecorated">
                                <ToolTip
                                    hide={!formProps.values.enablePublicRegistration || selectedGame.minimumSeats === 1}
                                    trigger={
                                        <FormField
                                            className="form-field-type-checkbox--styled-label form-field-type-checkbox__season decoration decoration--ok"
                                            description={`Enforce minimum roster of ${selectedGame.minimumSeats} to join season`}
                                            disabled={
                                                !!formProps.values.enablePublicRegistration &&
                                                selectedGame.minimumSeats > 1
                                            }
                                            name="enforceMinimumRoster"
                                            type="checkbox"
                                        />
                                    }
                                >
                                    <p>
                                        Due to the public {seasonAlternateName.toLowerCase()} registration form allowing
                                        for team creation within the form, there will only be 1 player on the team upon
                                        joining the {seasonAlternateName.toLowerCase()}.
                                    </p>
                                </ToolTip>
                            </fieldset>
                        )}

                        <fieldset className="form-group form-group--undecorated">
                            <FormField
                                className="form-field-type-checkbox--styled-label form-field-type-checkbox__season"
                                description={`Allow the public to join this ${seasonAlternateName.toLowerCase()}`}
                                name="enablePublicRegistration"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e.target.checked && (!selectedGame || selectedGame.minimumSeats > 1))
                                        formProps.setFieldValue('enforceMinimumRoster', false);
                                }}
                                type="checkbox"
                            />
                        </fieldset>

                        {!!designations &&
                            <Select
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isMulti
                                onChange={(selectedOptions: OptionsType<SelectOption>) => {
                                    formProps.setFieldValue(
                                        'allowedTeamDesignations',
                                        selectedOptions.map((option: SelectOption) => option.value)
                                    );
                                }}
                                options={designationOptions}
                                name="allowedTeamDesignations"
                                placeholder="Select allowed team designations to join"
                                styles={{
                                    control: (provided: any) => ({
                                        ...provided,
                                        border: 'none',
                                        minHeight: '5.6rem',
                                    }),
                                    menu: (provided: any) => ({ ...provided, zIndex: 2 }),
                                }}
                                defaultValue={options?.filter((o) =>
                                    formProps.values.allowedTeamDesignations.includes(o.value)
                                )}
                            />}

                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                        <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                        <SolidButton
                            as="button"
                            layout="full"
                            onClick={formProps.submitForm}
                            pending={formProps.isSubmitting}
                            size="large"
                        >
                            Create {seasonAlternateName}
                        </SolidButton>
                    </Form>
                );
            }}
        />
    );
};

export default withLoading(CreateSeason, {
    loadingProps: { blockItem: true },
});

import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import ManageBankAccount from './manageBankAccount';
import { SolidButton } from '../../../components/buttons-visuals';
import { ContentContainer } from '../../../components/containers';
import { TextInput } from '../../../components/inputs';
import withLoading, { WithLoadingProps } from '../../../components/withLoading';
import { useLeague } from '../../../hooks/store';
import PaymentGatewayService from '../../../services/paymentGatewayService';

interface ManageGatewayAccountProps extends WithLoadingProps {
    paymentGatewayId: string;
}

const ManageGatewayAccount = ({ paymentGatewayId, setError, setIsLoading }: ManageGatewayAccountProps): JSX.Element => {
    const league = useLeague();

    const [account, setAccount] = useState<Core.Models.GatewayAccount | undefined>(undefined);

    useEffect(() => {
        (async () => {
            await loadStripeAccount();
        })();
    }, []);

    const loadStripeAccount = useCallback(async (): Promise<void> => {
        setIsLoading(true);

        try {
            const data = await PaymentGatewayService.getStripeAccount(paymentGatewayId);
            setAccount(data);
        } catch (e) {
            const message = Core.API.getErrorMessage(e);
            setError(message);
        } finally {
            setIsLoading(false);
        }
    }, [paymentGatewayId, setAccount, setError, setIsLoading]);

    const createGatewayAccount = useCallback(async () => {
        setIsLoading(true);

        try {
            await PaymentGatewayService.createGatewayAccount({
                paymentGatewayId: Core.Models.PaymentGateway.Stripe,
            });

            await loadStripeAccount();
        } catch (error) {
            const message = Core.API.getErrorMessage(error);
            toast.error(message);
        } finally {
            setIsLoading(false);
        }
    }, [loadStripeAccount, setIsLoading]);

    if (!league) return <></>;
    return (
        <ContentContainer className="global-container-centered mb2x" shade={Core.Models.Shades.Dark40}>
            <p className="mb4x">
                In order to take payments, you need to set up and verify a payment account. This wizard will walk you
                through the steps to set up your account.
            </p>

            {renderSetupWizardItem(
                'Account created',
                true,
                !!account?.id,
                <SolidButton as="button" onClick={createGatewayAccount} size="small">
                    Create account
                </SolidButton>
            )}
            {renderSetupWizardItem(
                'Account setup completed',
                true,
                !!account?.business?.name,
                !!account?.id && (
                    <SolidButton
                        as="a"
                        href={new URL(
                            `/payments/account-link/${paymentGatewayId}/${league.id}/${+Core.Models.AccountLinkType
                                .Onboarding}`,
                            Core.Configuration.platformUrl
                        ).toString()}
                        size="small"
                    >
                        Set up account
                    </SolidButton>
                )
            )}
            {!!account?.business?.name && (
                <TextInput
                    disabled={true}
                    id="businessName"
                    label="Business name"
                    placeholder="Business name"
                    type="text"
                    value={`${account.business.name}`}
                />
            )}
            {renderSetupWizardItem('Bank account connected', true, !!account?.bankAccount, false)}
            {!!account?.business?.name && (
                <ManageBankAccount
                    bankAccount={account.bankAccount}
                    canEdit={paymentGatewayId !== Core.Models.PaymentGateway.Stripe} // Stripe bank accounts are created on the Stripe platform
                    paymentGatewayId={paymentGatewayId}
                    refresh={loadStripeAccount}
                />
            )}

            {renderSetupWizardItem('Payouts enabled', true, !!account?.payoutsEnabled, false)}

            {renderSetupWizardItem(
                'Requires action',
                !!account?.business?.name && !!account.requiresAction,
                !account?.requiresAction,
                <SolidButton
                    as="a"
                    href={new URL(
                        `/payments/account-link/${paymentGatewayId}/${league.id}/${+Core.Models.AccountLinkType
                            .Update}`,
                        Core.Configuration.platformUrl
                    ).toString()}
                    size="small"
                >
                    Update account{' '}
                    <FontAwesomeIcon className="ml" icon={['fas', 'arrow-up-right-from-square']} size="1x" />
                </SolidButton>
            )}

            {!!account?.business?.name && !account.requiresAction && (
                <SolidButton
                    as="a"
                    className="mt4x"
                    href={new URL(
                        `/payments/account-link/${paymentGatewayId}/${league.id}/${+Core.Models.AccountLinkType
                            .Update}`,
                        Core.Configuration.platformUrl
                    ).toString()}
                    size="small"
                    layout="full"
                >
                    Update account{' '}
                    <FontAwesomeIcon className="ml" icon={['fas', 'arrow-up-right-from-square']} size="1x" />
                </SolidButton>
            )}
        </ContentContainer>
    );
};

const renderSetupWizardItem = (
    name: string,
    showStep: boolean,
    stepCompleted: boolean,
    action: JSX.Element | false
): JSX.Element => {
    if (!showStep) return <></>;

    return (
        <div className="mb2x grid-columns grid-columns--2 align-center">
            <div>{name}</div>
            <div className="text-center">
                {stepCompleted ? (
                    <FontAwesomeIcon className="color-success" icon={['fas', 'circle-check']} size="1x" />
                ) : (
                    action || <FontAwesomeIcon className="color-error" icon={['fas', 'circle-xmark']} size="1x" />
                )}
            </div>
        </div>
    );
};

export default withLoading(ManageGatewayAccount, { loadingProps: { blockItem: true } });

import * as Core from './../core';

export default abstract class DiscordService {
    public static generateFullAuthorizeUrl = (authorizeUrl: string, userId: string | undefined): string =>
        Core.Utils.generateFullAuthorizeUrl(
            window.location.href,
            Core.Constants.OAUTH_DISCORD_PROVIDER_ID,
            authorizeUrl,
            userId
        );
}

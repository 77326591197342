import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { isNumber, orderBy, pick } from 'lodash';
import moment from 'moment-timezone';
import pluralize from 'pluralize';
import Select, { OptionsType, SelectOption } from 'react-select';
import * as Yup from 'yup';

import * as Core from '../../core';
import ChessLinksTooltip from './chessLinksTooltip';
import { HollowButton, SolidButton, TertiaryButton } from '../../components/buttons-visuals';
import { Rule } from '../../components/buttons-visuals/rule';
import { ContentContainer } from '../../components/containers';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';
import { FieldSet } from '../../components/inputs';
import LiteLimitationMessage from '../../components/liteLimitationMessage';
import MarkdownPreview from '../../components/markdown/markdownPreview';
import { TennisStylePopover, TennisStyleSetsPopover } from '../../components/tennisStylePopover';
import Tooltip from '../../components/tooltip';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useConfirmModal } from '../../hooks/confirmModal';
import { useAlternateSeasonName, useIsLite, useTimezone } from '../../hooks/store';
import { skillLevels } from '../../pages/league/createSeason';
import history from '../../services/history';
import { LeagueService } from '../../services/leagueService';
import { SeasonService } from '../../services/seasonService';

import './editSeason.scss';

interface EditSeasonProps extends WithLoadingProps {
    onComplete: () => Promise<void>;
    season: Core.Models.Season;
    seasonId: string;
}

export interface EditSeasonValues {
    allowFreeAgents: boolean;
    description?: string;
    enablePublicRegistration: boolean;
    enforceMinimumRoster: boolean;
    gameId: string;
    generateChessDotComLinks: boolean;
    isVisible: boolean;
    maxParticipants?: number;
    metadata?: string;
    name: string;
    requireGameRanks: boolean;
    requireHandleForCheckin: boolean;
    seasonTerms?: string;
    skillLevel: Core.Models.SkillLevel;
    startDate?: string;
    tennisStyle: boolean;
    tennisStyleSets?: number;
    timingDescription?: string;
    userLeagueRoleIds: string[];
}

const EditSeason = (props: EditSeasonProps) => {
    const { isLoading, onComplete, season, seasonId, setError, setIsLoading } = props;
    const [games, setGames] = useState<Core.Models.Game[]>([]);
    const [seasonOperators, setSeasonOperators] = useState<Core.Models.LeagueMember[]>([]);

    const timezone = useTimezone();
    const seasonAlternateName = useAlternateSeasonName();
    const isLite = useIsLite();

    const hasInitialDescription = useMemo(() => !!season.description, [season.description]);
    const numParticipants = useMemo(
        () => season.participants.filter((p: Core.Models.Participant) => p.isParticipating).length,
        [season.participants]
    );

    const getMaxParticipants = useCallback(
        (minimumSeats: number) => {
            if (isLite) return Math.ceil(Core.Constants.PLANS.LITE.LIMITATIONS.MAX_PLAYERS_PER_SEASON / minimumSeats);
            return undefined;
        },
        [isLite]
    );

    useEffect(() => {
        (async () => {
            try {
                const [gamesResponse, seasonOperatorsResponse] = await Promise.all([
                    LeagueService.getLeagueApprovedGames(),
                    LeagueService.getLeagueUsersByRole(Core.Models.LeagueRoleId.SeasonOperator, season.id),
                ]);
                setGames(gamesResponse);
                setSeasonOperators(seasonOperatorsResponse);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [season.id, setError, setIsLoading]);

    const schema = Yup.object().shape({
        allowFreeAgents: Yup.boolean(),
        description: Yup.string().nullable().notRequired(),
        enablePublicRegistration: Yup.boolean(),
        enforceMinimumRoster: Yup.boolean(),
        gameId: Yup.string().required('Game is required'),
        generateChessDotComLinks: Yup.boolean(),
        isVisible: Yup.boolean().required('Visible is required'),
        maxParticipants: Yup.number()
            .min(
                numParticipants,
                numParticipants === 0
                    ? 'Maximum number of teams must be positive'
                    : `There are already ${numParticipants} teams participating in this ${seasonAlternateName.toLowerCase()}`
            )
            .test('lite-max', (maxParticipants: number | undefined, context) => {
                const selectedGame = games.find((g: Core.Models.Game) => g.id === context.parent.gameId);
                if (!!selectedGame) {
                    const maxParticipantsLimit = getMaxParticipants(selectedGame.minimumSeats);
                    if (
                        !!maxParticipantsLimit &&
                        (!isNumber(maxParticipants) || maxParticipants! > maxParticipantsLimit)
                    ) {
                        return context.createError({
                            message: `${Core.Constants.PLANS.LITE.NAME} only allows a maximum of ${
                                Core.Constants.PLANS.LITE.LIMITATIONS.MAX_PLAYERS_PER_SEASON
                            } players per ${seasonAlternateName.toLowerCase()} (${maxParticipantsLimit} teams for ${
                                selectedGame.name
                            }).`,
                            path: 'maxParticipants',
                        });
                    }
                }
                return true;
            })
            .nullable(),
        metadata: Yup.string().nullable().notRequired(),
        name: Yup.string().required('Name is required'),
        requireGameRanks: Yup.boolean(),
        requireHandleForCheckin: Yup.boolean(),
        seasonTerms: Yup.string().nullable(),
        skillLevel: Yup.number()
            .min(skillLevels[0], 'Skill level is required')
            .max(skillLevels[skillLevels.length - 1], 'Skill level is required'),
        startDate: Yup.string().nullable(),
        tennisStyle: Yup.boolean().required(),
        tennisStyleSets: Yup.number()
            .test(
                'is-required',
                'Number of tennis style sets is required if tennis style play is enabled',
                (tennisStyleSets: number | undefined, context) => {
                    return !context.parent.tennisStyle || !!tennisStyleSets;
                }
            )
            .min(1, 'Number of tennis style sets must be positive')
            .nullable(),
        timingDescription: Yup.string().nullable().required('Timing is required'),
    });

    const [deleteSeasonNode, openDeleteSeason] = useConfirmModal(
        () => `Delete ${seasonAlternateName}`,
        () => (
            <p>
                Are you sure you want to delete <strong>{season.name}</strong>?
            </p>
        ),
        async () => {
            await SeasonService.delete(season.id);
            history.push('/');
        }
    );

    if (isLoading) return <></>;
    return (
        <Formik<EditSeasonValues>
            initialValues={{
                ...pick(season, [
                    'allowFreeAgents',
                    'description',
                    'enablePublicRegistration',
                    'enforceMinimumRoster',
                    'isVisible',
                    'maxParticipants',
                    'metadata',
                    'name',
                    'requireGameRanks',
                    'requireHandleForCheckin',
                    'seasonTerms',
                    'skillLevel',
                    'startDate',
                    'tennisStyle',
                    'tennisStyleSets',
                    'timingDescription',
                ]),
                gameId: season.game.id,
                generateChessDotComLinks: season.titleConfiguration?.generateChessDotComLinks || false,
                userLeagueRoleIds: seasonOperators
                    .filter((lm: Core.Models.LeagueMember) => lm.seasonIds.includes(season.id))
                    .map((lm: Core.Models.LeagueMember) => lm.userLeagueRoleId),
            }}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
                actions.setStatus(undefined);
                try {
                    const selectedGame = games.find((g: Core.Models.Game) => g.id === values.gameId);

                    if (values.requireGameRanks && !Core.Competition.hasRankings(selectedGame)) {
                        values.requireGameRanks = false;
                    }

                    let titleConfiguration: Core.Models.ChessConfiguration | undefined = undefined;
                    if (!!selectedGame) {
                        switch (selectedGame.featureSet) {
                            case Core.Models.FeatureSet.Chess:
                                titleConfiguration = {
                                    generateChessDotComLinks: values.generateChessDotComLinks,
                                };
                                break;
                        }
                    }

                    await SeasonService.edit({
                        ...values,
                        id: season.id,
                        startDate: !!values.startDate
                            ? moment.tz(values.startDate, timezone).format(Core.Time.getFormat())
                            : undefined,
                        titleConfiguration,
                    });
                    await onComplete();
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(formProps: FormikProps<EditSeasonValues>) => {
                const { gameId, userLeagueRoleIds } = formProps.values;
                const selectedOptions = seasonOperators
                    .filter((lm: Core.Models.LeagueMember) => userLeagueRoleIds.includes(lm.userLeagueRoleId))
                    .map(
                        (lm: Core.Models.LeagueMember) =>
                            ({
                                label: lm.userId ? Core.Identity.renderMemberName(lm) : lm.email,
                                value: lm.userLeagueRoleId,
                            }) as SelectOption
                    );
                const options = seasonOperators
                    .filter((lm: Core.Models.LeagueMember) => !userLeagueRoleIds.includes(lm.userLeagueRoleId))
                    .map(
                        (lm: Core.Models.LeagueMember) =>
                            ({
                                label: lm.userId ? Core.Identity.renderMemberName(lm) : lm.email,
                                value: lm.userLeagueRoleId,
                            }) as SelectOption
                    );
                const selectedGame = games.find((g: Core.Models.Game) => g.id === gameId)!;
                const minimumSeats = selectedGame.minimumSeats;
                const teamWord = season.game.minimumSeats > 1 ? 'Team' : 'Player';

                const formMessaging = (
                    <>
                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                        <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />
                    </>
                );

                return (
                    <>
                        <div className="disp-flex align-center justify-between mb2x">
                            <h1 className="heading-1 text-center mb0">{`Edit ${seasonAlternateName}`}</h1>
                            <div>
                                <TertiaryButton as="link" className="mr" to={`/seasons/${seasonId}`}>
                                    Cancel
                                </TertiaryButton>
                                <SolidButton
                                    as="button"
                                    onClick={formProps.submitForm}
                                    pending={formProps.isSubmitting}
                                    size="small"
                                >
                                    Save
                                </SolidButton>
                            </div>
                        </div>
                        {formMessaging}
                        <ContentContainer shade={Core.Models.Shades.Dark40}>
                            <Form className="form">
                                <FieldSet legend={`${seasonAlternateName} Settings`} className="edit-season__settings">
                                    <FormField type="text" name="name" description="Name" />
                                    <FormField
                                        component="date"
                                        label={`Start Date (${Core.Time.getFormat()})`}
                                        name="startDate"
                                    />
                                    <FormField
                                        component="select"
                                        description="Game"
                                        name="gameId"
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            if (!isLite) return;
                                            const selectedGame = games.find(
                                                (g: Core.Models.Game) => g.id === e.target.value
                                            );
                                            if (!!selectedGame) {
                                                const maxParticipantsLimit = getMaxParticipants(
                                                    selectedGame.minimumSeats
                                                );
                                                const maxParticipants = formProps.values.maxParticipants;
                                                if (
                                                    !!maxParticipantsLimit &&
                                                    (!maxParticipants || maxParticipants > maxParticipantsLimit)
                                                ) {
                                                    formProps.setFieldValue('maxParticipants', maxParticipantsLimit);
                                                }
                                            }
                                        }}
                                    >
                                        <option value="" hidden disabled>
                                            --
                                        </option>
                                        {orderBy(games, (g: Core.Models.Game) => g.name).map((g: Core.Models.Game) => (
                                            <option key={g.id} value={g.id}>
                                                {g.name}
                                            </option>
                                        ))}
                                    </FormField>
                                    <FormField component="select" description="Skill Level" name="skillLevel">
                                        <option value={-1} hidden disabled>
                                            Select a skill level
                                        </option>
                                        <option value={Core.Models.SkillLevel.All}>
                                            {Core.Models.SKILL_LEVEL_STRINGS[Core.Models.SkillLevel.All]}
                                        </option>
                                        <option value={Core.Models.SkillLevel.Beginner}>
                                            {Core.Models.SKILL_LEVEL_STRINGS[Core.Models.SkillLevel.Beginner]}
                                        </option>
                                        <option value={Core.Models.SkillLevel.Intermediate}>
                                            {Core.Models.SKILL_LEVEL_STRINGS[Core.Models.SkillLevel.Intermediate]}
                                        </option>
                                        <option value={Core.Models.SkillLevel.Advanced}>
                                            {Core.Models.SKILL_LEVEL_STRINGS[Core.Models.SkillLevel.Advanced]}
                                        </option>
                                        <option value={Core.Models.SkillLevel.SuperAdvanced}>
                                            {Core.Models.SKILL_LEVEL_STRINGS[Core.Models.SkillLevel.SuperAdvanced]}
                                        </option>
                                    </FormField>
                                    <FormField
                                        description="Timing"
                                        name="timingDescription"
                                        aria-describedby="e.g., 'Mondays @ 5pm' or 'September 10th'"
                                        label="Timing Description"
                                        type="text"
                                    />
                                    {seasonOperators.length > 0 && (
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isMulti
                                            onChange={(so: OptionsType<SelectOption>) => {
                                                formProps.setFieldValue(
                                                    'userLeagueRoleIds',
                                                    so.map((option: SelectOption) => option.value)
                                                );
                                            }}
                                            options={options}
                                            name="userLeagueRoleIds"
                                            placeholder={`${seasonAlternateName} Operator(s) (optional)`}
                                            styles={{
                                                menu: (provided: any) => ({
                                                    ...provided,
                                                    zIndex: 2,
                                                }),
                                            }}
                                            value={selectedOptions}
                                        />
                                    )}
                                    <div>
                                        <FormField
                                            description={`Maximum Number of ${pluralize(teamWord)}${
                                                isLite ? '' : ' (optional)'
                                            }`}
                                            name="maxParticipants"
                                            type="number"
                                        />
                                        {isLite && (
                                            <LiteLimitationMessage
                                                message={`${
                                                    Core.Constants.PLANS.LITE.NAME
                                                } only allows for a maximum of 50 players per season${
                                                    !!selectedGame && selectedGame.minimumSeats > 1
                                                        ? ` (${getMaxParticipants(
                                                              selectedGame.minimumSeats
                                                          )} teams for ${selectedGame.name})`
                                                        : ''
                                                }.`}
                                            />
                                        )}
                                    </div>
                                </FieldSet>

                                <Rule type="horizontal" className="mb3x" shade={Core.Models.Shades.Dark20} />
                                <FieldSet legend="Custom Settings" className="edit-season__custom">
                                    {hasInitialDescription && (
                                        <>
                                            <FormField
                                                component="textarea"
                                                description="Basic pre-season info"
                                                name="description"
                                                placeholder="(Obsolete) Enter information about schedule, format, timing, etc. here to be displayed before the season begins. This field allows markdown formatting."
                                            />

                                            <MarkdownPreview
                                                fieldName="Description"
                                                source={formProps.values.description}
                                            />
                                        </>
                                    )}

                                    <FormField
                                        component="textarea"
                                        description={`${seasonAlternateName} Terms`}
                                        name="seasonTerms"
                                        placeholder={`Optionally add terms that users must agree to in order to join this ${seasonAlternateName.toLowerCase()}. This field allows markdown formatting.`}
                                    />
                                    <MarkdownPreview
                                        fieldName={`${seasonAlternateName} Terms`}
                                        source={formProps.values.seasonTerms}
                                    />

                                    <FormField
                                        component="textarea"
                                        description={`${seasonAlternateName} Metadata`}
                                        name="metadata"
                                        placeholder="Enter information about schedule, format, timing, etc. here. This field allows markdown formatting."
                                    />
                                    <MarkdownPreview
                                        fieldName={`${seasonAlternateName} Metadata`}
                                        source={formProps.values.metadata}
                                    />
                                </FieldSet>

                                <Rule type="horizontal" className="mb3x" shade={Core.Models.Shades.Dark20} />
                                <FieldSet legend="Advanced Settings" className="edit-season__advanced">
                                    {!!selectedGame.supportsTennisStyle && (
                                        <>
                                            <div className="disp-flex">
                                                <FormField
                                                    description="Use tennis-style gameplay"
                                                    disabled={
                                                        season.currentState > Core.Models.CompetitionState.NotStarted
                                                    }
                                                    name="tennisStyle"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        formProps.setFieldValue(
                                                            'tennisStyleSets',
                                                            e.target.checked ? 1 : undefined
                                                        );
                                                    }}
                                                    type="checkbox"
                                                />
                                                <TennisStylePopover showSeasonStartedWarning />
                                            </div>
                                            {formProps.values.tennisStyle && (
                                                <div className="disp-flex">
                                                    <FormField
                                                        className="mb0 mr"
                                                        description="Number of sets"
                                                        disabled={
                                                            season.currentState >
                                                            Core.Models.CompetitionState.NotStarted
                                                        }
                                                        name="tennisStyleSets"
                                                        type="number"
                                                    />
                                                    <TennisStyleSetsPopover showSeasonStartedWarning />
                                                </div>
                                            )}
                                        </>
                                    )}

                                    {!!selectedGame && Core.Competition.hasRankings(selectedGame) && (
                                        <FormField
                                            description={`Require users to provide their ${
                                                selectedGame.rankingTerm
                                            } to join this ${seasonAlternateName.toLowerCase()}`}
                                            name="requireGameRanks"
                                            type="checkbox"
                                        />
                                    )}

                                    {!!selectedGame?.gameHandleSource && (
                                        <FormField
                                            description={`Require users to enter ${selectedGame.gameHandleSource.name} to check-in`}
                                            name="requireHandleForCheckin"
                                            type="checkbox"
                                        />
                                    )}

                                    {selectedGame?.featureSet === Core.Models.FeatureSet.Chess &&
                                        !!selectedGame.gameHandleSource && (
                                            <div className="disp-flex">
                                                <FormField
                                                    label="Generate Chess.com challenge links for each game"
                                                    name="generateChessDotComLinks"
                                                    type="checkbox"
                                                />
                                                <ChessLinksTooltip rosterSize={selectedGame.minimumSeats} />
                                            </div>
                                        )}

                                    <FormField
                                        description={`Allow free agents to sign up for this ${seasonAlternateName.toLowerCase()}`}
                                        name="allowFreeAgents"
                                        type="checkbox"
                                    />

                                    {!!selectedGame && (
                                        <Tooltip
                                            content={
                                                <p>
                                                    Due to the public {seasonAlternateName.toLowerCase()} registration
                                                    form allowing for team creation within the form, there will only be
                                                    1 player on the team upon joining the{' '}
                                                    {seasonAlternateName.toLowerCase()}.
                                                </p>
                                            }
                                            decorationStyle="ok"
                                            hideTooltip={
                                                !formProps.values.enablePublicRegistration ||
                                                selectedGame.minimumSeats === 1
                                            }
                                            placement="top"
                                        >
                                            <FormField
                                                description={`Enforce minimum roster of ${selectedGame.minimumSeats} to join season`}
                                                disabled={
                                                    !!formProps.values.enablePublicRegistration &&
                                                    selectedGame.minimumSeats > 1
                                                }
                                                name="enforceMinimumRoster"
                                                type="checkbox"
                                            />
                                        </Tooltip>
                                    )}

                                    <FormField
                                        description={`Allow the public to join this ${seasonAlternateName.toLowerCase()}`}
                                        name="enablePublicRegistration"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            if (e.target.checked && (!selectedGame || selectedGame.minimumSeats > 1))
                                                formProps.setFieldValue('enforceMinimumRoster', false);
                                        }}
                                        type="checkbox"
                                    />

                                    <FormField
                                        description={`Allow competitors to see this ${seasonAlternateName.toLowerCase()}`}
                                        name="isVisible"
                                        type="checkbox"
                                    />
                                </FieldSet>

                                {formMessaging}

                                <div className="grid-columns grid-columns--2 grid-columns--for-large mt4x">
                                    <HollowButton
                                        as="button"
                                        color="destructive"
                                        disabled={formProps.isSubmitting}
                                        layout="full"
                                        onClick={openDeleteSeason}
                                        size="large"
                                    >
                                        Delete {seasonAlternateName}
                                    </HollowButton>
                                    <SolidButton
                                        as="button"
                                        layout="full"
                                        onClick={formProps.submitForm}
                                        pending={formProps.isSubmitting}
                                        size="large"
                                    >
                                        Save {seasonAlternateName}
                                    </SolidButton>
                                </div>
                                {deleteSeasonNode}
                            </Form>
                        </ContentContainer>
                    </>
                );
            }}
        />
    );
};

export default withLoading(EditSeason, {
    loadingProps: { blockItem: true },
});

import styled from 'styled-components';

interface BackgroundImageProps {
    src: string;
}

const BackgroundImage = styled.div<BackgroundImageProps>`
    background-image: url('${(props) => props.src}');
`;

export default BackgroundImage;

import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, FormikProps, Form } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import * as Core from '../../core';
import leagueSpotLogoType from '../../assets/images/logotype.png';
import { IconButton, SolidButton } from '../../components/buttons-visuals';
import { ContentContainer } from '../../components/containers';
import { ForgotPasswordLink } from '../../components/forgotPasswordLink';
import { ForgotUsernameLink } from '../../components/forgotUsernameLink';
import FormField from '../../components/formField';
import Signup from '../../components/header/signup';
import InfoMessage from '../../components/infoMessage';
import { FieldSet } from '../../components/inputs';
import MfaCodeInput from '../../components/inputs/authCodeInput';
import { Popover } from '../../components/overlays';
import { useLoginState } from '../../hooks/store';

interface LoginProps {
    league?: Core.Models.League;
    onSubmit: (values: Core.Models.LoginUserCommand) => Promise<void>;
}

interface LoginFormValues {
    extendExpiration: boolean;
    otpCode?: string;
    password: string;
    username: string;
}

const Login = (props: LoginProps): JSX.Element => {
    const { league, onSubmit } = props;

    const leagueName = useMemo(() => league?.name || Core.Constants.Company, [league]);
    const login = useLoginState();

    return (
        <div className="page login">
            <Formik<LoginFormValues>
                initialValues={Object.assign({
                    extendExpiration: false,
                    otpCode: '',
                    password: '',
                    username: '',
                })}
                validationSchema={Yup.object().shape(
                    Object.assign(
                        {},
                        {
                            password: Yup.string().required('Password is required'),
                            username: Yup.string().required('Username is required'),
                            ...(!!login.requiresMfa && {
                                otpCode: Yup.string().required(
                                    'Authenticator code is required for accounts that have two-factor authentication enabled'
                                ),
                            }),
                        }
                    )
                )}
                onSubmit={async (values, actions) => {
                    actions.setStatus(undefined);
                    try {
                        await onSubmit({
                            extendExpiration: values.extendExpiration ?? false,
                            otpCode: values.otpCode,
                            password: values.password,
                            username: values.username?.trim(),
                        });
                    } catch (error) {
                        const status = Core.API.getStatus(error);
                        switch (status) {
                            case 401:
                                const message = error.response?.data
                                    ? Core.API.getErrorMessage(error)
                                    : 'Username and password combination is invalid.';
                                actions.setStatus(message);
                                break;
                            case 403:
                                actions.setStatus(
                                    'We found your account but there is something wrong. Please contact your league host.'
                                );
                                break;
                            default: {
                                const message = Core.API.getErrorMessage(error);
                                if (message === Core.Constants.USER_DOES_NOT_HAVE_ROLE_IN_THIS_LEAGUE) {
                                    actions.setStatus(
                                        <span>
                                            You don't have an account in this league - confirm you're on the correct
                                            league, or <Link to="/">continue without logging in.</Link>
                                        </span>
                                    );
                                } else {
                                    actions.setStatus(message);
                                }
                            }
                        }
                    }
                    actions.setSubmitting(false);
                }}
                render={(formProps: FormikProps<LoginFormValues>) => (
                    <div className="global-container-centered">
                        <div className="login__header mb4x">
                            <div className="login__header__logo">
                                <img src={league?.logoUrl || leagueSpotLogoType} alt="" />
                            </div>
                            <div className="login__header__title">{leagueName}</div>
                        </div>
                        <ContentContainer shade={Core.Models.Shades.Dark40}>
                            <Form id="login-form">
                                <FieldSet legend={`Login to ${leagueName}`}>
                                    {!login.requiresMfa && (
                                        <>
                                            <div className="login__username">
                                                <FormField
                                                    className="mb0"
                                                    description="Username"
                                                    name="username"
                                                    type="text"
                                                />
                                                <Popover
                                                    button={
                                                        <IconButton
                                                            as="button"
                                                            buttonLabel="More info"
                                                            buttonSize="small"
                                                        >
                                                            <FontAwesomeIcon icon={['fas', 'circle-info']} />
                                                        </IconButton>
                                                    }
                                                >
                                                    If you haven't created a username, try using your email address, or
                                                    request a forgot username email below. After logging in, you can
                                                    edit your username on your profile page.
                                                </Popover>
                                            </div>
                                            <FormField
                                                className="mt2x"
                                                type="password"
                                                name="password"
                                                description="Password"
                                            />
                                            <FormField
                                                type="checkbox"
                                                name="extendExpiration"
                                                description="Keep me logged in for 30 days"
                                            />
                                        </>
                                    )}
                                    {login.requiresMfa && (
                                        <MfaCodeInput
                                            autoFocus
                                            isError={!!formProps.errors.otpCode}
                                            setValue={(value: string) => formProps.setFieldValue('otpCode', value)}
                                        />
                                    )}
                                </FieldSet>

                                {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                                <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                                <SolidButton
                                    as="button"
                                    className="mb2x"
                                    pending={formProps.isSubmitting}
                                    layout="full"
                                    size="large"
                                    type="submit"
                                >
                                    {login.requiresMfa ? 'Submit Code' : 'Log In'}
                                </SolidButton>
                                {!!league && !login.requiresMfa && (
                                    <Signup className="login-button" layout="full" text="Sign Up" />
                                )}

                                {!login.requiresMfa && (
                                    <>
                                        <ForgotUsernameLink />
                                        <ForgotPasswordLink />
                                    </>
                                )}
                            </Form>
                        </ContentContainer>
                    </div>
                )}
            />
        </div>
    );
};

export default Login;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { SolidButton } from '../../components/buttons-visuals';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useLeague } from '../../hooks/store';
import { LeagueService } from '../../services/leagueService';
import { getLeague } from '../../store/league/actions';

interface GetLogoColorsProps extends WithLoadingProps {}

const GetLogoColors = ({ setError, setIsLoading }: GetLogoColorsProps) => {
    const dispatch = useDispatch();
    const league = useLeague();

    const getLogoColors = useCallback(async () => {
        setIsLoading(true);

        try {
            await LeagueService.extractLeagueLogoColors();
            dispatch(getLeague(false));
            toast.success('Loaded colors from league logo');
        } catch (e) {
            setError(e);
            toast.error('Unable to load colors from league logo');
        } finally {
            setIsLoading(false);
        }
    }, [dispatch, setError, setIsLoading]);

    if (!!league?.logoColors && league.logoColors.length > 0) return <></>;
    return (
        <SolidButton as="button" onClick={getLogoColors} size="medium">
            Get Colors
        </SolidButton>
    );
};

export default withLoading(GetLogoColors, { isLoadingByDefault: false });

import React, { useEffect, useMemo, useState } from 'react';
import indefinite from 'indefinite';
import { Link } from 'react-router-dom';

import * as Core from '../../core';
import { useAlternateSeasonName, useLeague, useOrganizationTerm } from '../../hooks/store';
import { OrganizationService } from '../../services/organizationService';
import { SolidButton } from '../buttons-visuals';
import { Select } from '../inputs';
import Modal from '../modal';

import './signup.scss';

interface RegisterModalProps {
    joinableSeasons: Core.Models.JoinableSeason[];
    onClose: () => void;
}

const RegisterModal = ({ joinableSeasons, onClose }: RegisterModalProps): JSX.Element => {
    const league = useLeague();
    const isClubLeague = useMemo(() => league?.edition === Core.Models.LeagueEdition.Club, [league]);
    const seasonAlternateName = useAlternateSeasonName();
    const organizationTerm = useOrganizationTerm({ lowercase: true });

    const [joinableOrganizations, setJoinableOrganizations] = useState<Core.Models.JoinableOrganization[]>([]);
    const [organizationToJoin, setOrganizationToJoin] = useState<string | undefined>(undefined);
    const [seasonToJoin, setSeasonToJoin] = useState<string | undefined>(joinableSeasons[0]?.id);

    const showOrganizations = useMemo(
        () => league?.edition !== Core.Models.LeagueEdition.Club && joinableOrganizations.length > 0,
        [joinableOrganizations.length, league?.edition]
    );

    useEffect(() => {
        (async () => {
            const organizations = await OrganizationService.getJoinableOrganizations();
            setJoinableOrganizations(organizations);
            setOrganizationToJoin(organizations[0]?.joinCode);
        })();
    }, []);

    if (!league) return <></>;
    return (
        <Modal className="register-modal" onClose={onClose} title="Don't have an account?">
            <>
                {joinableSeasons.length > 0 && (
                    <div className="register-modal__direct-join">
                        <p>
                            You can create an account and join {indefinite(seasonAlternateName.toLowerCase())} in one
                            step:
                        </p>
                        <Select
                            id="seasonToJoin"
                            label={`Join ${indefinite(seasonAlternateName.toLowerCase())}`}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSeasonToJoin(e.target.value)}
                            value={seasonToJoin}
                        >
                            <option value="" disabled hidden>
                                --
                            </option>
                            {joinableSeasons.map((season: Core.Models.JoinableSeason) => (
                                <option key={season.id} value={season.id}>
                                    {season.name}
                                </option>
                            ))}
                        </Select>
                        {!!seasonToJoin && (
                            <SolidButton
                                as="link"
                                size="medium"
                                onClick={onClose}
                                to={`/seasons/join/${seasonToJoin}`}
                                shadow={false}
                            >
                                Join
                            </SolidButton>
                        )}
                    </div>
                )}

                {showOrganizations && (
                    <div className="register-modal__direct-join">
                        <p>These {organizationTerm}s allow you to join directly:</p>
                        <Select
                            id="organizationToJoin"
                            label={`Join ${indefinite(organizationTerm)}`}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                setOrganizationToJoin(e.target.value)
                            }
                            value={organizationToJoin}
                        >
                            <option value="" hidden disabled>
                                --
                            </option>
                            {joinableOrganizations.map((organization: Core.Models.JoinableOrganization) => (
                                <option key={organization.id} value={organization.joinCode}>
                                    {organization.name}
                                </option>
                            ))}
                        </Select>
                        {!!organizationToJoin && (
                            <SolidButton
                                as="link"
                                size="medium"
                                onClick={onClose}
                                to={`/organizations/join/${organizationToJoin}`}
                                shadow={false}
                            >
                                Join
                            </SolidButton>
                        )}
                    </div>
                )}

                <p className="signup__message">
                    {(joinableSeasons.length > 0 || showOrganizations) && `Can't find what you're looking for? `}
                    Please contact your {!isClubLeague && `${organizationTerm} manager or`}{' '}
                    <Link onClick={onClose} to="/contact">
                        league host
                    </Link>{' '}
                    for more information.
                </p>
            </>
        </Modal>
    );
};

export default RegisterModal;

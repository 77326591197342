import React, { useMemo } from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';

import * as Core from '../../core';
import {
    useHasAnyLeagueMembershipRole,
    useIsPermissionsLoading,
    useIsPlatform,
    useUserPermissionService,
} from '../../hooks/store';
import ForbiddenPage from '../../pages/forbidden';
import { LoginRedirectService } from '../../services/loginRedirectService';
import Loading from '../loading';

export interface AuthenticatedLayoutRouteProps extends RouteProps {
    component?: React.ComponentType<any>;
    layout: React.ComponentType<any>;
    render?: (props: RouteComponentProps<any>) => React.ReactNode;
}

/**
 * Wraps `<Route/>` from `react-router-dom` to ensure several things before rendering the component.
 * If the user isn't authenticated, they will be redirected to `/login`.
 */
export const AuthenticatedLayoutRouteComponent = ({
    component: Component,
    layout: Layout,
    render,
    ...rest
}: AuthenticatedLayoutRouteProps) => {
    const isPermissionsLoading = useIsPermissionsLoading();
    const isLeagueMember = useHasAnyLeagueMembershipRole(Core.Models.PermissionLevel.ListSpecific);
    const userPermissionService = useUserPermissionService();
    const isPlatform = useIsPlatform();
    const isAuthenticated = !!userPermissionService.getUserId();

    const canViewPage = useMemo(
        () => (isPlatform ? isAuthenticated : isLeagueMember),
        [isAuthenticated, isLeagueMember, isPlatform]
    );

    if (isPermissionsLoading) return <Loading blockItem />;
    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isAuthenticated) return <Redirect to={LoginRedirectService.buildLoginUrl()} />;
                return canViewPage ? (
                    <Layout>{Component ? <Component {...props} /> : render ? render(props) : null}</Layout>
                ) : (
                    <Layout>
                        <ForbiddenPage />
                    </Layout>
                );
            }}
        />
    );
};

export default AuthenticatedLayoutRouteComponent;

import React from 'react';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import { ModifyLocationStationForm } from './modifyLocationStationForm';
import {
    CreateLocationStationModalProps,
    DeleteLocationStationModalProps,
    ModifyLocationStationFormActions,
    ModifyLocationStationFormValues,
    UpdateLocationStationModalProps,
} from './types';
import { useConfirmModal } from '../../hooks/confirmModal';
import { useModal } from '../../hooks/modal';
import { LeagueService } from '../../services/leagueService';

export const useCreateLocationStationModal = ({ locationId, onSubmitted }: CreateLocationStationModalProps) => {
    const modal = useModal(
        () => 'Create Station',
        (close) => (
            <ModifyLocationStationForm
                onSubmit={async (
                    values: ModifyLocationStationFormValues,
                    actions: ModifyLocationStationFormActions
                ) => {
                    if (!locationId) return;
                    try {
                        actions.setStatus(undefined);
                        const { station } = await LeagueService.createLeagueLocationStation({
                            ...values,
                            locationId,
                        });
                        onSubmitted?.(station);
                        close();
                    } catch (e) {
                        const message = Core.API.getErrorMessage(e);
                        actions.setStatus(message);
                    } finally {
                        actions.setSubmitting(false);
                    }
                }}
            />
        )
    );
    return modal;
};

export const useDeleteLocationStationModal = ({ onDeleted, stationId }: DeleteLocationStationModalProps) => {
    const modal = useConfirmModal(
        () => 'Remove Station',
        () => <p>Are you sure you want to remove this station?</p>,
        async (close) => {
            if (!stationId) return;
            try {
                await LeagueService.deleteLeagueLocationStation(stationId);
                onDeleted?.(stationId);
                close();
            } catch (e) {
                const message = Core.API.getErrorMessage(e);
                toast.error(message);
            }
        },
        {
            cancelText: 'Nevermind',
            confirmText: 'Yes',
        }
    );
    return modal;
};

export const useUpdateLocationStationModal = ({
    onDelete,
    onSubmitted,
    stationId,
    values,
}: UpdateLocationStationModalProps) => {
    const modal = useModal(
        () => 'Edit Station',
        (close) => (
            <ModifyLocationStationForm
                onDelete={() => {
                    close();
                    !!stationId && onDelete(stationId);
                }}
                onSubmit={async (
                    values: ModifyLocationStationFormValues,
                    actions: ModifyLocationStationFormActions
                ) => {
                    if (!stationId) return;
                    try {
                        actions.setStatus(undefined);
                        const { station } = await LeagueService.updateLeagueLocationStation({
                            ...values,
                            stationId,
                        });
                        onSubmitted?.(station);
                        close();
                    } catch (e) {
                        const message = Core.API.getErrorMessage(e);
                        actions.setStatus(message);
                    } finally {
                        actions.setSubmitting(false);
                    }
                }}
                values={values}
            />
        )
    );
    return modal;
};

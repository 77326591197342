import * as Redux from 'redux';

import { ConsentActionTypes, ConsentState, CONSENT_SUCCESS } from './types';

const initialState: ConsentState = {
    lastUpdatedUtc: undefined,
    twitch: false,
};

const consentReducer: Redux.Reducer<ConsentState, ConsentActionTypes> = (
    state = initialState,
    action: ConsentActionTypes
): ConsentState => {
    switch (action.type) {
        case CONSENT_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default consentReducer;

import React, { useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import moment from 'moment-timezone';
import { FileUploader } from 'react-drag-drop-files';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../../core';
import { Button } from '../../../components/button';
import { HollowButton, StatusIndicator } from '../../../components/buttons-visuals';
import { Rule } from '../../../components/buttons-visuals/rule';
import FormField from '../../../components/formField';
import InfoMessage from '../../../components/infoMessage';
import Loading from '../../../components/loading';
import Markdown from '../../../components/markdown';
import Modal from '../../../components/modal';
import { ToolTip } from '../../../components/overlays';
import { useHasLeagueAccess, useLeague } from '../../../hooks/store';
import { UserService } from '../../../services/userService';
import { IdentityVerificationModal } from '../../identityVerification';

import './index.scss';

interface IdentityVerificationProps {
    profile: Core.Models.UserProfile;
    reloadData: () => Promise<void>;
}

interface IdentityVerificationValues {
    imageString: string;
    notes: string;
}

const toHumanReadableFileSize = (size: number) => {
    const index = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return Number((size / Math.pow(1024, index)).toFixed(1)) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][index];
};

const IdentityVerification = (props: IdentityVerificationProps) => {
    const {
        profile: { identityVerificationSubmission, organizationId, userId },
        reloadData,
    } = props;
    const [fileName, setFileName] = useState<string>('');
    const [fileSize, setFileSize] = useState<string>('');
    const [identityVerificationImageError, setIdentityVerificationImageError] = useState<string | undefined>(undefined);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [notes, setNotes] = useState<string>('');
    const [submitModalIsOpen, setSubmitModalIsOpen] = useState<boolean>(false);
    const [verifyModalIsOpen, setVerifyModalIsOpen] = useState<boolean>(false);
    const [viewModalIsOpen, setViewModalIsOpen] = useState<boolean>(false);
    const league = useLeague();
    const canEditLeague = useHasLeagueAccess(Core.Models.PermissionLevel.Edit);

    const handleChange = useCallback((file: File, setImageString: (imageString: string) => void) => {
        if (file.size > Core.Constants.IMAGES.MAX_IDENTITY_VERIFICATION_IMAGE_SIZE) {
            toast.error('Image file size too large. Please use an image 10 MB or smaller.');
        } else if (file.type.startsWith('image/')) {
            // declare file reader / onload method
            const reader = new FileReader();
            reader.onload = async (loadedEvent: any) => {
                setImageString(loadedEvent.target.result.split(',')[1]);
                setFileName(file.name);
                setFileSize(toHumanReadableFileSize(file.size));
            };

            // read the file for the image string
            reader.readAsDataURL(file);
        } else {
            toast.error('Invalid image file');
        }
    }, []);

    const closeSubmitModal = useCallback(() => {
        setSubmitModalIsOpen(false);
        setFileName('');
        setIsSuccess(false);
    }, []);

    const isPending = useMemo(
        () => identityVerificationSubmission?.state === Core.Models.UserIdentityVerificationSubmissionState.Submitted,
        [identityVerificationSubmission?.state]
    );

    const isUnverified = useMemo(
        () =>
            !identityVerificationSubmission ||
            [
                Core.Models.UserIdentityVerificationSubmissionState.MoreInformationRequired,
                Core.Models.UserIdentityVerificationSubmissionState.Rejected,
            ].includes(identityVerificationSubmission.state),
        [identityVerificationSubmission]
    );

    const isNeedMoreVerification = useMemo(
        () =>
            identityVerificationSubmission?.state ===
            Core.Models.UserIdentityVerificationSubmissionState.MoreInformationRequired,
        [identityVerificationSubmission?.state]
    );

    const canSubmit = useMemo(
        () =>
            !identityVerificationSubmission ||
            identityVerificationSubmission.state ===
                Core.Models.UserIdentityVerificationSubmissionState.MoreInformationRequired,
        [identityVerificationSubmission]
    );

    const canView = useMemo(
        () =>
            !!identityVerificationSubmission &&
            moment(identityVerificationSubmission.expirationTimeUtc) > moment() &&
            identityVerificationSubmission.state !== Core.Models.UserIdentityVerificationSubmissionState.Approved,
        [identityVerificationSubmission]
    );

    const currentState = useMemo(() => {
        if (isNeedMoreVerification)
            return {
                label: 'Need more information',
                description: 'Your submission has been deemed insufficient. Please resubmit to verify your identity.',
            };
        if (isUnverified)
            return {
                label: 'Unverified',
                description: 'This league requires that you verify your identity in order to participate.',
            };
        if (isPending)
            return {
                label: 'Approval pending',
                description: 'Your verification status is currently pending a league admin review.',
            };
        return { label: 'Verified', description: undefined };
    }, [isNeedMoreVerification, isPending, isUnverified]);

    if (!league?.requireIdentityVerification) return <></>;
    if (!organizationId) return <></>;
    return (
        <div className="identitiy-verification">
            <h4
                className={classNames('heading-4', {
                    invalid: canSubmit,
                })}
            >
                Identity
            </h4>
            <div className="disp-flex align-start justify-between mb4x" id="identity_verification">
                <ToolTip
                    trigger={
                        <StatusIndicator
                            color={isUnverified ? 'warning' : isNeedMoreVerification ? 'error' : 'success'}
                        >
                            {currentState.label}
                        </StatusIndicator>
                    }
                >
                    {currentState.description}
                </ToolTip>
                <div className="disp-flex flex-dir-column flex-gap">
                    {canSubmit && (
                        <HollowButton
                            as="button"
                            size="small"
                            className="identity-verification-button"
                            onClick={() => setSubmitModalIsOpen(true)}
                        >
                            Submit verification
                        </HollowButton>
                    )}

                    {!!canView && (
                        <HollowButton
                            as="button"
                            size="small"
                            className="identity-verification-button"
                            onClick={() => setViewModalIsOpen(true)}
                        >
                            View submission
                        </HollowButton>
                    )}

                    {!!canView && canEditLeague && (
                        <HollowButton
                            as="button"
                            size="small"
                            className="identity-verification-button"
                            onClick={() => setVerifyModalIsOpen(true)}
                        >
                            Verify Submission
                        </HollowButton>
                    )}
                </div>
            </div>
            <Rule type="horizontal" shade={Core.Models.Shades.Dark20} />
            {submitModalIsOpen && (
                <Modal
                    className="identity-verification-modal"
                    onClose={closeSubmitModal}
                    title={isSuccess ? 'Verification Submitted' : 'Identity Verification'}
                >
                    <p className="identity-verification-modal__subtitle">
                        {isSuccess ? (
                            <>Your uploaded media will be reviewed by our authorized league admin.</>
                        ) : (
                            <> Gain access to this league by verifying your identity.</>
                        )}
                    </p>
                    {!!league.identityVerificationInstructions && !isSuccess && (
                        <div className="identity-verification-modal__league-markdown-container">
                            <Markdown source={league.identityVerificationInstructions} />
                        </div>
                    )}
                    {isSuccess ? (
                        <>
                            <p className="identity-verification-modal__field-label">File uploaded</p>
                            <div className="identity-verification-modal__field-submitted file-upload">
                                <div className="left-container">
                                    <span className="percentage-circle">100%</span>
                                    <div className="file-info">
                                        <span className="file-name">{fileName}</span>
                                        <span className="file-size">{fileSize}</span>
                                    </div>
                                </div>
                                <FontAwesomeIcon icon={['fas', 'check']} className="color-success" />
                            </div>
                            {!!notes && (
                                <>
                                    <p className="identity-verification-modal__field-label">Additional info</p>
                                    <div className="identity-verification-modal__field-submitted additional-notes">
                                        {notes}
                                    </div>
                                </>
                            )}
                            <div className="identity-verification-modal__close-button-container">
                                <Button
                                    className="identity-verification-modal__close-button"
                                    onClick={closeSubmitModal}
                                    styleType={Core.Models.StyleType.LinkLike}
                                >
                                    Close
                                </Button>
                            </div>
                        </>
                    ) : (
                        <Formik<IdentityVerificationValues>
                            initialValues={{
                                imageString: '',
                                notes: '',
                            }}
                            validationSchema={Yup.object().shape({
                                imageString: Yup.string().required('Image is required'),
                                notes: Yup.string().max(Core.Constants.MARKDOWN_MAX_LENGTH),
                            })}
                            onSubmit={async (
                                values: IdentityVerificationValues,
                                actions: FormikActions<IdentityVerificationValues>
                            ) => {
                                actions.setStatus(undefined);
                                try {
                                    await UserService.submitIdentityVerification({ userId, ...values });
                                    await reloadData();
                                    setNotes(values.notes);
                                    setIsSuccess(true);
                                } catch (e) {
                                    toast.error('Identity verification submission failed. Please try again.');
                                    const message = Core.API.getErrorMessage(e);
                                    actions.setStatus(message);
                                } finally {
                                    actions.setSubmitting(false);
                                }
                            }}
                            render={(formProps: FormikProps<IdentityVerificationValues>) => (
                                <Form>
                                    <label className="identity-verification-modal__field-label">File upload</label>
                                    <FileUploader
                                        handleChange={(file: File) =>
                                            handleChange(file, (imageString: string) =>
                                                formProps.setFieldValue('imageString', imageString)
                                            )
                                        }
                                        types={['JPG', 'JPEG', 'PNG', 'HEIC']}
                                    >
                                        <div className="identity-verification-modal__file-uploader">
                                            <FontAwesomeIcon icon={['fas', 'cloud-arrow-up']} />
                                            <p className="identity-verification-modal__file-uploader--title">
                                                Upload Image
                                            </p>
                                            <p className="identity-verification-modal__file-uploader--supported-file-types">
                                                {!!fileName ? (
                                                    <>
                                                        <FontAwesomeIcon icon={['fas', 'check']} className="mr" />
                                                        <span>{fileName}</span>
                                                    </>
                                                ) : (
                                                    <>(JPG, PNG, HEIC)</>
                                                )}
                                            </p>
                                            <p>
                                                Drag and drop or <span className="underline">browse</span> your files
                                            </p>
                                        </div>
                                    </FileUploader>
                                    <FormField
                                        className="identity-verification-notes-text-area"
                                        component="textarea"
                                        name="notes"
                                        label="Additional info"
                                    />

                                    {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                                    <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                                    <fieldset className="form-group form-group--undecorated">
                                        {formProps.isSubmitting && <Loading buttonLoader />}
                                        <Button
                                            onClick={formProps.submitForm}
                                            disabled={formProps.isSubmitting}
                                            wide
                                            round
                                        >
                                            Submit Verification
                                        </Button>
                                    </fieldset>
                                </Form>
                            )}
                        />
                    )}
                </Modal>
            )}
            {verifyModalIsOpen && canEditLeague && (
                <IdentityVerificationModal
                    onClose={async () => {
                        setVerifyModalIsOpen(false);
                        await reloadData();
                    }}
                    queue={{ currentId: userId }}
                />
            )}
            {viewModalIsOpen && (
                <Modal
                    className="identity-verification-modal"
                    onClose={() => setViewModalIsOpen(false)}
                    title={'Identity Verification Submission'}
                >
                    <p className="identity-verification-modal__field-label">File uploaded</p>
                    {!!identityVerificationImageError ? (
                        <InfoMessage message={identityVerificationImageError} type="error" />
                    ) : (
                        <img
                            alt="identity verification"
                            className="identity-verification-modal__submitted-image"
                            onError={() =>
                                setIdentityVerificationImageError(
                                    'Image has expired. Please refresh the page to try again.'
                                )
                            }
                            src={identityVerificationSubmission!.imageUrl}
                        />
                    )}
                    {!!identityVerificationSubmission?.userNotes && (
                        <>
                            <p className="identity-verification-modal__field-label">Additional info</p>
                            <div className="identity-verification-modal__field-submitted additional-notes">
                                {identityVerificationSubmission?.userNotes}
                            </div>
                        </>
                    )}
                    <div className="identity-verification-modal__close-button-container">
                        <Button
                            className="identity-verification-modal__close-button"
                            onClick={() => setViewModalIsOpen(false)}
                            styleType={Core.Models.StyleType.LinkLike}
                        >
                            Close
                        </Button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default IdentityVerification;

import React, { useCallback, useEffect, useState } from 'react';
import { MainContainer, Sidebar } from '@chatscope/chat-ui-kit-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import { IconButton, SolidButton } from '../../../components/buttons-visuals';
import { ToolTip } from '../../../components/overlays';
import { NEW_MATCH_THREAD_CREATED, useChatContext } from '../../../contexts/chatContext';
import { useChatDisabled, useHasLeagueAccess } from '../../../hooks/store';
import ChatService from '../../../services/chatService';
import PlatformEventsService from '../../../services/platformEventsService';
import ThreadParticipantList from '../../chat/participantList';
import Thread from '../../chat/thread';

import './index.scss';

interface MatchLobbyProps {
    canEditSeason: boolean;
    matchId: string;
    matchWord: string;
    restrictChatUsers?: boolean;
    seasonId: string;
    threadId?: string;
}

const MatchLobby = ({
    canEditSeason,
    matchId,
    matchWord,
    restrictChatUsers,
    seasonId,
    threadId,
}: MatchLobbyProps): JSX.Element => {
    const [thread, setThread] = useState<Core.Models.ChatThread | undefined>(undefined);
    const chatDisabled = useChatDisabled();
    const canEditLeague = useHasLeagueAccess(Core.Models.PermissionLevel.Edit);
    const { setLatestMessageReadId } = useChatContext();

    const handleNewMatchThreadCreated = useCallback(
        (newMatchThread: Core.Models.ChatThread) => {
            if (newMatchThread.matchId !== matchId) return;
            setThread(newMatchThread);
        },
        [matchId]
    );

    const routeDataReceived = useCallback(
        ({ eventName, data }: { eventName: string; data: any }) => {
            switch (eventName) {
                case NEW_MATCH_THREAD_CREATED:
                    return handleNewMatchThreadCreated(data);
                default:
            }
        },
        [handleNewMatchThreadCreated]
    );

    // this should only run once - the subscription shouldn't change between rerenders
    useEffect(() => {
        const subscription = PlatformEventsService.dataReceived.subscribe(routeDataReceived);
        return () => subscription.unsubscribe();
    }, [routeDataReceived]);

    useEffect(() => {
        if (!threadId) return;
        if (!matchId) return;
        if (chatDisabled) return;

        (async () => {
            try {
                const threadData = await ChatService.getMatchThread(matchId);
                setThread(threadData);
            } catch (err) {
                toast.error(`Could not open chat`);
            }
        })();
    }, [chatDisabled, matchId, threadId]);

    const createMatchThread = async (): Promise<void> => {
        try {
            await ChatService.createMatchThread({ matchId });
        } catch (err) {
            toast.error(`Could not start and open chat`);
        }
    };

    if (chatDisabled) return <></>;

    return (
        <div className="match-lobby">
            {(!thread || restrictChatUsers) && (
                <div className="match-lobby__create">
                    {/* hidden element for alignment */}
                    {canEditLeague && <div className="ml5x invisible"></div>}
                    <ToolTip
                        hide={!restrictChatUsers}
                        trigger={
                            <SolidButton
                                as="button"
                                disabled={restrictChatUsers}
                                onClick={async () => createMatchThread()}
                            >
                                {restrictChatUsers
                                    ? `${matchWord} chat restricted`
                                    : `Open ${matchWord.toLowerCase()} chat`}
                            </SolidButton>
                        }
                    >
                        <>
                            Due to league restrictions, competitors are not allowed to join {matchWord.toLowerCase()}{' '}
                            chat.
                        </>
                    </ToolTip>
                    {canEditLeague && (
                        <IconButton
                            as="a"
                            buttonLabel="Chat restriction league setting"
                            buttonSize="medium"
                            href="/league-settings/basic-settings#restrictChatUsers"
                        >
                            <FontAwesomeIcon icon={['fas', 'gear']} />
                        </IconButton>
                    )}
                </div>
            )}

            {!!thread && !restrictChatUsers && (
                <>
                    <div style={{ height: '600px' }}>
                        <MainContainer>
                            <Thread selectedThread={thread} {...{ setLatestMessageReadId }} />
                            {thread.participants.filter((p: Core.Models.ChatThreadParticipant) => !p.removedTimeUtc)
                                .length > 0 && (
                                <Sidebar position="right">
                                    <ThreadParticipantList
                                        canEdit={canEditSeason}
                                        participants={thread.participants.filter(
                                            (p: Core.Models.ChatThreadParticipant) => !p.removedTimeUtc
                                        )}
                                        seasonId={seasonId}
                                    />
                                </Sidebar>
                            )}
                        </MainContainer>
                    </div>
                    <p className="match-lobby__disclaimer">
                        Note: Try asking Spot for a coin flip by saying "!coinflip".
                    </p>
                </>
            )}
        </div>
    );
};

export default MatchLobby;

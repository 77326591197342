import React, { useEffect, useMemo, useState } from 'react';
import { orderBy } from 'lodash';

import * as Core from '../../../core';
import FulfillPayableModal from './fulfillPayableModal';
import FulfillmentRow from './fulfillmentRow';
import { SolidButton } from '../../../components/buttons-visuals';
import { ContentContainer } from '../../../components/containers';
import { useLeague, useUserPermissionService } from '../../../hooks/store';

import './index.scss';

interface FulfillmentsListProps {
    fulfillments: Core.Models.PayableFulfillment[];
    leagueDesignationId?: string;
    reloadData: (showLoading: boolean) => Promise<void>;
    teamId?: string;
    organizationId?: string;
}

const FulfillmentsList = (props: FulfillmentsListProps): JSX.Element => {
    const { fulfillments: inputFulfillments, leagueDesignationId, reloadData, teamId, organizationId } = props;

    const league = useLeague();
    const userPermissionService = useUserPermissionService();

    const [fulfillments, setFulfillments] = useState<Core.Models.PayableFulfillment[]>(inputFulfillments);
    const [isFulfillingPayables, setIsFulfillingPayables] = useState<boolean>(false);

    const canRefund = useMemo(
        () => userPermissionService.hasLeagueAccess(Core.Models.PermissionLevel.Edit, league),
        [league]
    );

    useEffect(() => setFulfillments(inputFulfillments), [inputFulfillments]); // this ensures that the list is updated when switching between users

    if (!league?.enablePaymentFeatures) return <></>;
    return (
        <div className="fulfillments-list">
            <h3 className="heading-2">Entry fees paid</h3>

            <div className="fulfillments-list__items">
                <div className="fulfillments-list__items__header">
                    <div>Date</div>
                    <div>Entry fee</div>
                    <div>On behalf of</div>
                    <div>Amount (USD)</div>
                    <div></div>
                </div>
                {fulfillments.length <= 0 ? (
                    <p className="fulfillments-list__items__empty">No paid entry fees</p>
                ) : (
                    orderBy(
                        fulfillments,
                        (fulfillment: Core.Models.PayableFulfillment) => fulfillment.fulfilledDateUtc,
                        'desc'
                    ).map((fulfillment: Core.Models.PayableFulfillment) => (
                        <FulfillmentRow
                            key={fulfillment.id}
                            {...{
                                canRefund,
                                fulfillment,
                                reloadData,
                                setFulfillments,
                            }}
                        />
                    ))
                )}
            </div>

            {!!teamId && ( // can only do this directly on the team itself
                <SolidButton
                    as="button"
                    className="fulfillments-list__add disp-block m-auto mt2x"
                    onClick={() => setIsFulfillingPayables(true)}
                >
                    Pay entry fee
                </SolidButton>
            )}

            {isFulfillingPayables && (
                <FulfillPayableModal
                    payableEntityType={Core.Models.PayableEntityType.Season}
                    onCompleted={() => setIsFulfillingPayables(false)}
                    {...{
                        fulfillments,
                        leagueDesignationId,
                        reloadData,
                        setFulfillments,
                        teamId,
                    }}
                    organizationId={organizationId}
                />
            )}
        </div>
    );
};

export default FulfillmentsList;

import * as React from 'react';
import { orderBy, sortedUniqBy } from 'lodash';

import * as Core from '../../core';
import { ContentContainer } from '../../components/containers';
import { Select } from '../../components/inputs';
import MatchSchedule from '../../components/matchSchedule';
import { useAlternateSeasonName } from '../../hooks/store';

interface TeamMatchScheduleProps {
    headerText: string;
    matches: Core.Models.MatchHistory[];
    team: Core.Models.Team;
}

const TeamMatchSchedule = (props: TeamMatchScheduleProps): JSX.Element => {
    const { headerText, matches } = props;
    const seasonNames = matches.length
        ? sortedUniqBy(
              orderBy(
                  matches.map((m: Core.Models.MatchHistory) => {
                      return {
                          id: m.seasonId,
                          name: m.seasonName,
                      };
                  }),
                  (season) => season.name
              ),
              'id'
          )
        : null;
    const [seasonToFilter, setSeasonToFilter] = React.useState(matches.length ? matches[0].seasonId : '');
    const seasonAlternateName = useAlternateSeasonName();

    return (
        <ContentContainer shade={Core.Models.Shades.Dark40} className="team-match-schedule mb4x">
            <h4 className="heading-2">{headerText}</h4>
            {seasonNames && (
                <Select
                    id="season"
                    label={`Select a ${seasonAlternateName.toLowerCase()} to show`}
                    layoutInline
                    name="season"
                    onChange={(event) => setSeasonToFilter(event.target.value)}
                    style={{ minWidth: '20rem' }}
                    value={seasonToFilter}
                >
                    {/* no empty option provided because we want to default to first season in the schedule */}
                    {seasonNames.map((season) => (
                        <option key={season.id} value={season.id}>
                            {season.name}
                        </option>
                    ))}
                </Select>
            )}
            <MatchSchedule
                matches={matches
                    .filter((m: Core.Models.MatchHistory) => {
                        if (!seasonToFilter) return true;
                        return m.seasonId === seasonToFilter;
                    })
                    .map((m: Core.Models.MatchHistory) => {
                        return m.match;
                    })}
                isPrimary={(mp: Core.Models.MatchParticipant) => mp.teamId === props.team.id}
            />
        </ContentContainer>
    );
};

export default TeamMatchSchedule;

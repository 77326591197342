import React, { useCallback, useEffect, useState } from 'react';
import { Formik, FormikProps, FormikActions, Form } from 'formik';
import { isNumber, orderBy } from 'lodash';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../core';
import { SolidButton } from '../../components/buttons-visuals';
import FormField from '../../components/formField';
import ImageUploader from '../../components/imageUploader';
import InfoMessage from '../../components/infoMessage';
import { TennisStylePopover } from '../../components/tennisStylePopover';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useIsAdmin } from '../../hooks/store';
import { GameHandleSourceService } from '../../services/gameHandleSourceService';
import { GameService } from '../../services/gameService';
import history from '../../services/history';
import GameRankingField from '../user/gameRanking/gameRankingField';

interface CreateGamePageProps extends WithLoadingProps {
    game?: Core.Models.Game;
    isCreate?: boolean;
}

interface CreateGamePageFormValues {
    cardImageData: string;
    competitionStyle: Core.Models.CompetitionStyleSelection;
    disablePublic: boolean;
    esrbRating: Core.Models.EsrbRating;
    eventType: Core.Models.EventType;
    gameHandleSourceId: string;
    heroImageData: string;
    iconImageData: string;
    maximumLobbySize?: number;
    maximumSeats: number;
    minimumSeats: number;
    name: string;
    rankings: { [key: string]: number };
    rankingTerm: string;
    rankingType: Core.Models.RankingType;
    scoringType: Core.Models.ScoringTypeSelection;
    supportsTennisStyle: boolean;
    supportsTies: boolean;
}

const competitionStyles: number[] = Object.values(Core.Models.CompetitionStyleSelection)
    .filter((value: number | string) => isNumber(value))
    .map((value: number | string) => +value);

const scoringTypes: number[] = Object.values(Core.Models.ScoringTypeSelection)
    .filter((value: number | string) => isNumber(value))
    .map((value: number | string) => +value);

const CreateGamePage = ({ isCreate = true, game, setError, setIsLoading }: CreateGamePageProps) => {
    const [gameHandleSources, setGameHandleSources] = useState<Core.Models.GameHandleSource[]>([]);
    const isAdmin = useIsAdmin();

    const load = useCallback(async () => {
        try {
            const response = await GameHandleSourceService.getAll();
            setGameHandleSources(response);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [setError, setIsLoading]);

    useEffect(() => {
        (async () => await load())();
    }, [load]);

    const handleSubmit = async (values: CreateGamePageFormValues, actions: FormikActions<CreateGamePageFormValues>) => {
        try {
            if (isCreate) {
                await GameService.create(values);
                toast.success(`Successfully created ${values.name}`);
                actions.resetForm();
                window.scrollTo(0, 0);
            } else {
                const id = game!.id;
                await GameService.edit({ ...values, id });
                history.push(`/games/${id}`);
            }
        } catch (error) {
            const message = Core.API.getErrorMessage(error);
            actions.setStatus(message);
        }
        actions.setSubmitting(false);
    };

    if (!isAdmin) return <Redirect to="/" />;
    return (
        <div className="page generic-page global-container-centered">
            <h2 className="generic-page__title m-auto mb6x">{isCreate ? 'Create Game' : `Edit ${game?.name}`}</h2>
            <div className="mb2x">
                <Formik
                    initialValues={Object.assign(
                        {
                            cardImageData: '',
                            competitionStyle: Core.Models.CompetitionStyleSelection.Unselected,
                            disablePublic: false,
                            esrbRating: Core.Models.EsrbRating.None,
                            gameHandleSourceId: '',
                            heroImageData: '',
                            iconImageData: '',
                            maximumLobbySize: undefined,
                            maximumSeats: 1,
                            minimumSeats: 1,
                            name: '',
                            scoringType: Core.Models.ScoringTypeSelection.Unselected,
                            supportsTennisStyle: false,
                            supportsTies: false,
                        },
                        game,
                        { resultFileExtensions: game?.resultFileExtensions?.join(',') }
                    )}
                    onSubmit={handleSubmit}
                    validationSchema={Yup.object().shape({
                        disablePublic: Yup.boolean(),
                        esrbRating: Yup.number()
                            .oneOf(Core.Utils.getEnumAsIntArray(Core.Models.EsrbRating), 'Unrecognized ESRB Rating')
                            .required('ESRB Rating is required'),
                        gameHandleSourceId: Yup.string().nullable(),
                        maximumLobbySize: Yup.number()
                            .nullable()
                            .test(
                                'is-valid',
                                'Maximum lobby size is required for leaderboard games',
                                (maximumLobbySize: number | null | undefined, context) => {
                                    const competitionStyle = context.parent.competitionStyle;
                                    return (
                                        competitionStyle !== Core.Models.CompetitionStyle.Leaderboard ||
                                        isNumber(maximumLobbySize)
                                    );
                                }
                            ),
                        maximumSeats: Yup.number()
                            .required('Maximum seats is required')
                            .min(1, 'Maximum seats must be 1 or greater')
                            .max(100, 'Maximum seats must be 100 or fewer')
                            .integer(),
                        minimumSeats: Yup.number()
                            .required('Minimum seats is required')
                            .min(1, 'Minimum seats must be 1 or greater')
                            .max(50, 'Minimum seats must be 50 or fewer')
                            .integer(),
                        name: Yup.string()
                            .required('Name is required')
                            .max(
                                Core.Constants.NAME_MAX_LENGTH,
                                `Name must be ${Core.Constants.NAME_MAX_LENGTH} characters or fewer`
                            ),
                        scoringType: Yup.number()
                            .min(scoringTypes[0], 'Scoring type is required')
                            .max(scoringTypes[scoringTypes.length - 1], 'Scoring type is required'),
                        supportsTennisStyle: Yup.boolean().required(),
                        supportsTies: Yup.boolean(),
                        ...(isCreate && {
                            competitionStyle: Yup.number()
                                .min(competitionStyles[0], 'Competition style is required')
                                .max(competitionStyles[competitionStyles.length - 1], 'Competition style is required'),
                        }),
                        ...(!isCreate && {
                            eventType: Yup.number()
                                .oneOf(Core.Utils.getEnumAsIntArray(Core.Models.EventType), 'Unrecognized event type')
                                .required('Event type is required'),
                            rankingTerm: Yup.string()
                                .required('Ranking term is required.')
                                .max(
                                    Core.Constants.NAME_MAX_LENGTH,
                                    `Ranking term is too long. (${Core.Constants.NAME_MAX_LENGTH} character maximum)`
                                ),
                            rankingType: Yup.number()
                                .oneOf(
                                    Core.Utils.getEnumAsIntArray(Core.Models.RankingType),
                                    'Unrecognized ranking type'
                                )
                                .required('Ranking type is required'),
                            resultFileExtensions: Yup.string()
                                .nullable()
                                .notRequired()
                                .max(
                                    Core.Constants.MARKDOWN_MAX_LENGTH,
                                    `Result file extensions is too long. (${Core.Constants.NAME_MAX_LENGTH} character maximum)`
                                ),
                        }),
                    })}
                    render={(formProps: FormikProps<CreateGamePageFormValues>) => {
                        const competitionStyle = formProps.values.competitionStyle.toString();
                        const isHeadToHead = competitionStyle === `${Core.Models.CompetitionStyle.HeadToHead}`;
                        const isLeaderboard = competitionStyle === `${Core.Models.CompetitionStyle.Leaderboard}`;

                        return (
                            <Form className="form">
                                <FormField description="Name" name="name" placeholder="Name" type="text" />
                                {isCreate && (
                                    <FormField
                                        component="select"
                                        description="Competition style"
                                        name="competitionStyle"
                                    >
                                        <option disabled hidden value={-1}>
                                            Select a competition style
                                        </option>
                                        <option value={Core.Models.CompetitionStyle.HeadToHead}>Head to Head</option>
                                        <option value={Core.Models.CompetitionStyle.Leaderboard}>Leaderboard</option>
                                    </FormField>
                                )}
                                <FormField component="select" description="ESRB Rating" name="esrbRating">
                                    <option value={Core.Models.EsrbRating.None}>None</option>
                                    <option value={Core.Models.EsrbRating.E}>Everyone</option>
                                    <option value={Core.Models.EsrbRating.E10Plus}>Everyone 10+</option>
                                    <option value={Core.Models.EsrbRating.T}>Teen</option>
                                    <option value={Core.Models.EsrbRating.M}>Mature 17+</option>
                                    <option value={Core.Models.EsrbRating.AO}>Adults Only 18+</option>
                                    <option value={Core.Models.EsrbRating.RP}>Rating Pending</option>
                                    <option value={Core.Models.EsrbRating.RP17Plus}>Rating Pending 17+</option>
                                </FormField>
                                <FormField
                                    description="Minimum Seats"
                                    max="50"
                                    min="1"
                                    name="minimumSeats"
                                    placeholder="Minimum Seats"
                                    type="number"
                                />
                                <FormField
                                    description="Maximum Seats"
                                    max="100"
                                    min="1"
                                    name="maximumSeats"
                                    placeholder="Maximum Seats"
                                    type="number"
                                />
                                <FormField
                                    component="select"
                                    description="Game Handle Source"
                                    name="gameHandleSourceId"
                                >
                                    <option value={''}>No game handle source</option>
                                    {orderBy(gameHandleSources, (ghs: Core.Models.GameHandleSource) => ghs.name).map(
                                        (gameHandleSource: Core.Models.GameHandleSource) => (
                                            <option key={gameHandleSource.id} value={gameHandleSource.id}>
                                                {gameHandleSource.name}
                                            </option>
                                        )
                                    )}
                                </FormField>
                                <FormField component="select" description="Match game scoring type" name="scoringType">
                                    <option disabled hidden value={Core.Models.ScoringTypeSelection.Unselected}>
                                        Select a scoring type
                                    </option>
                                    <option value={Core.Models.ScoringTypeSelection.Discrete}>
                                        Discrete (most common, numerical score input, e.g. 8-4)
                                    </option>
                                    {!isLeaderboard && (
                                        <option value={Core.Models.ScoringTypeSelection.Categorical}>
                                            Categorical (win/loss{formProps.values.supportsTies && '/tie'} only)
                                        </option>
                                    )}
                                    {!isHeadToHead && (
                                        <option value={Core.Models.ScoringTypeSelection.Binary}>
                                            Binary (checkbox, e.g. attendance for classes)
                                        </option>
                                    )}
                                </FormField>
                                <FormField
                                    description="Disable public league host use of this game"
                                    name="disablePublic"
                                    type="checkbox"
                                />
                                <FormField description="This game supports ties" name="supportsTies" type="checkbox" />
                                {isLeaderboard ? (
                                    <FormField description="Maximum lobby size" name="maximumLobbySize" type="number" />
                                ) : (
                                    <div className="disp-flex">
                                        <FormField
                                            description="This game supports tennis-style gameplay"
                                            name="supportsTennisStyle"
                                            type="checkbox"
                                        />
                                        <TennisStylePopover />
                                    </div>
                                )}
                                {!isCreate && (
                                    <>
                                        <FormField component="select" description="Event type" name="eventType">
                                            <option disabled hidden value={-1}>
                                                Select an event type
                                            </option>
                                            <option value={Core.Models.EventType.Competition}>Competition</option>
                                            <option value={Core.Models.EventType.Class}>Class</option>
                                        </FormField>
                                        <FormField
                                            aria-describedby="Comma separated list, e.g. replay,fileType2"
                                            description="Result File Extensions"
                                            name="resultFileExtensions"
                                            placeholder="Rank Term"
                                            type="text"
                                        />
                                        <FormField component="select" description="Ranking type" name="rankingType">
                                            <option disabled hidden value={-1}>
                                                Select a ranking type
                                            </option>
                                            <option value={Core.Models.RankingType.Dictionary}>Dictionary</option>
                                            <option value={Core.Models.RankingType.Numeric}>Numeric</option>
                                        </FormField>
                                        <FormField
                                            description="Rank Term"
                                            name="rankingTerm"
                                            placeholder="Rank Term"
                                            type="text"
                                        />
                                        <p className="text-small mb">Game Ranking Preview:</p>
                                        <GameRankingField
                                            game={{ ...formProps.values, rankingType: +formProps.values.rankingType }}
                                        />
                                    </>
                                )}

                                <ImageUploader
                                    aspectRatio={600 / 240}
                                    className="mt2x"
                                    contentType={Core.Constants.CONTENT_TYPE_PNG}
                                    disableCrop
                                    flush
                                    text="Click to upload card (600 x 240)"
                                    uploadImage={async (data: string) => formProps.setFieldValue('cardImageData', data)}
                                />
                                {renderImagePreview('Card', '100%', formProps.values.cardImageData, game?.cardUrl)}

                                <ImageUploader
                                    aspectRatio={undefined}
                                    className="mt2x"
                                    contentType={Core.Constants.CONTENT_TYPE_PNG}
                                    flush
                                    ignoreMaxSize
                                    text="Click to upload hero (No dimension limit)"
                                    uploadImage={async (data: string) => formProps.setFieldValue('heroImageData', data)}
                                />
                                {renderImagePreview('Hero', '100%', formProps.values.heroImageData, game?.heroUrl)}

                                <ImageUploader
                                    aspectRatio={35 / 35}
                                    className="mt2x"
                                    contentType={Core.Constants.CONTENT_TYPE_PNG}
                                    disableCrop
                                    flush
                                    text="Click to upload icon (35 x 35)"
                                    uploadImage={async (data: string) => formProps.setFieldValue('iconImageData', data)}
                                />
                                <div className="text-center mb4x">
                                    {renderImagePreview('Icon', '10%', formProps.values.iconImageData, game?.iconUrl)}
                                </div>

                                {!isCreate && (
                                    <InfoMessage
                                        message="Editing this game will alter how it is used/appears in all live instances. Please ensure your changes are intentional and will not break any existing behavior on the platform."
                                        type="info"
                                    />
                                )}

                                {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                                <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />
                                <SolidButton
                                    as="button"
                                    className="mb4x mt4x disp-flex justify-center"
                                    layout="full"
                                    onClick={formProps.submitForm}
                                    pending={formProps.isSubmitting}
                                    size="medium"
                                >
                                    {isCreate ? 'Create game' : 'Save'}
                                </SolidButton>
                            </Form>
                        );
                    }}
                />
            </div>
        </div>
    );
};

const renderImagePreview = (name: string, width: string, uploadedImage?: string, existingImage?: string) => {
    if (!!uploadedImage) return <img alt={name} src={`data:image/jpeg;base64,${uploadedImage}`} style={{ width }} />;
    if (!!existingImage) return <img alt={name} src={existingImage} style={{ width }} />;
    return <></>;
};

export default withLoading(CreateGamePage, {
    loadingProps: { blockItem: true },
});

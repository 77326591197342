import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { every, isNumber, orderBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import SeasonWizard from './seasonWizard';
import StartSeason from './startSeason';
import BackgroundImage from '../../components/backgroundImage';
import { HollowButton, SolidButton, TertiaryButton } from '../../components/buttons-visuals';
import { ContentContainer, PullContainer } from '../../components/containers';
import EsrbRating from '../../components/esrbRating';
import GameIcon from '../../components/gameIcon';
import InfoMessage from '../../components/infoMessage';
import Markdown from '../../components/markdown';
import { ToolTip } from '../../components/overlays';
import { useConfirmModal } from '../../hooks/confirmModal';
import { useModal } from '../../hooks/modal';
import { useAlternateSeasonName, useCanEditSeason, useHasLeagueAccess, useProfile } from '../../hooks/store';
import history from '../../services/history';
import { SeasonService } from '../../services/seasonService';
import { loadProfile } from '../../store/profile/actions';
import TeamDesignation from '../../components/teamDesignation'

import './details.scss';

interface SeasonDetailsProps {
    season: Core.Models.Season;
    reloadSeason: () => Promise<void>;
}

export const SeasonDetails: React.FunctionComponent<SeasonDetailsProps> = (props) => {
    const { season, reloadSeason } = props;
    const seasonAlternateName = useAlternateSeasonName();
    const profile = useProfile();
    const dispatch = useDispatch();

    const [startSeasonNode, openStartSeason, closeStartSeason] = useModal(
        () => `Start ${seasonAlternateName}`,
        () => (
            <StartSeason
                onCancel={() => closeStartSeason()}
                onConfirm={async () => {
                    await reloadSeason();
                    closeStartSeason();
                }}
                season={season}
            />
        )
    );

    const [unstartSeasonNode, openUnstartSeason, closeUnstartSeason] = useConfirmModal(
        () => `Undo Start ${seasonAlternateName}`,
        () => (
            <p>
                Are you sure you want to undo starting <strong>{season.name}</strong>? You will need to lock in teams
                again to begin.
            </p>
        ),
        async () => {
            await SeasonService.unstart(season.id);
            await reloadSeason();
            closeUnstartSeason();
        }
    );

    const [completeSeasonNode, openCompleteSeason, closeCompleteSeason] = useConfirmModal(
        () => `Complete ${seasonAlternateName}`,
        () => (
            <p>
                Are you sure you want to complete <strong>{season.name}</strong>? You cannot undo this action.
            </p>
        ),
        async () => {
            await SeasonService.complete(season.id);
            await reloadSeason();
            closeCompleteSeason();
        }
    );

    const [deleteSeasonNode, openDeleteSeason] = useConfirmModal(
        () => `Delete ${seasonAlternateName}`,
        () => (
            <p>
                Are you sure you want to delete <strong>{season.name}</strong>?
            </p>
        ),
        async () => {
            await SeasonService.delete(season.id);
            history.push('/league');
        }
    );

    const [joinAsFreeAgentNode, openJoinAsFreeAgent, closeJoinAsFreeAgent] = useConfirmModal(
        () => `Join ${seasonAlternateName} as a free agent`,
        () => (
            <p>
                Are you sure you want to join <strong>{season.name}</strong> as a free agent?
            </p>
        ),
        async () => {
            await SeasonService.joinAsFreeAgent({ seasonId: season.id });
            dispatch(loadProfile());
            toast.success(`Successfully joined ${seasonAlternateName} as a free agent`);
            closeJoinAsFreeAgent();
        }
    );

    const [leaveAsFreeAgentNode, openLeaveAsFreeAgent, closeLeaveAsFreeAgent] = useConfirmModal(
        () => `Leave ${seasonAlternateName} as a free agent`,
        () => (
            <p>
                Are you sure you want to remove yourself as a free agent for <strong>{season.name}</strong>?
            </p>
        ),
        async () => {
            const seasonFreeAgentId = profile?.freeAgents.filter(
                (fa: Core.Models.SeasonFreeAgent) => fa.seasonId === season.id && fa.isActive
            )[0].id;
            await SeasonService.leaveAsFreeAgent(seasonFreeAgentId!);
            dispatch(loadProfile());
            toast.success(`Successfully left ${seasonAlternateName} as a free agent`);
            closeLeaveAsFreeAgent();
        }
    );

    const hasStages = season.stages.length > 0;
    const hasParticipants = season.participants.length > 0;
    const hasActiveFreeAgents = season.freeAgents?.some((fa: Core.Models.SeasonFreeAgent) => fa.isActive);
    const seasonIsFull =
        isNumber(season.maxParticipants) &&
        season.participants.filter((p: Core.Models.Participant) => p.isParticipating).length >= season.maxParticipants;

    const canEditSeason = useCanEditSeason(season.id);
    const canEditLeague = useHasLeagueAccess(Core.Models.PermissionLevel.Edit);
    const incompleteStage = orderBy(season.stages, (s: Core.Models.Stage) => s.sortOrder).filter(
        (i) => i.currentState < Core.Models.StageState.IsComplete
    )[0];
    const hasNoStartedStages = every(
        season.stages,
        (stage: Core.Models.Stage) => stage.currentState <= Core.Models.StageState.NotStarted
    );

    const canJoinAsFreeAgent = useMemo(
        () =>
            season.allowFreeAgents &&
            season.currentState <= Core.Models.CompetitionState.NotStarted &&
            !season.participants.some(
                (p: Core.Models.Participant) => profile?.teams.some((t: Core.Models.Team) => t.id === p.teamId)
            ) &&
            !!profile?.organizationId &&
            !!profile?.isEligible &&
            !profile?.freeAgents.some((fa: Core.Models.SeasonFreeAgent) => fa.seasonId === season.id && fa.isActive) &&
            (!isNumber(season.ageMax) || (profile?.age && profile?.age <= season.ageMax)) &&
            (!isNumber(season.ageMin) || (profile?.age && profile?.age >= season.ageMin)),
        [profile, season]
    );

    const canLeaveAsFreeAgent = useMemo(
        () =>
            season.currentState <= Core.Models.CompetitionState.NotStarted &&
            profile?.freeAgents.some((fa: Core.Models.SeasonFreeAgent) => fa.seasonId === season.id && fa.isActive),
        [profile, season]
    );

    return (
        <div className="season-details">
            <div
                className={classNames('page__section page__section--details', {
                    'page__section--details-has-bg-img': season.game.heroUrl,
                })}
            >
                {season.game.heroUrl && <BackgroundImage className="page__hero-img" src={season.game.heroUrl} />}
                <div className="global-container">
                    <ContentContainer className="season-details__wrap shadow-small p0">
                        <div className="p2x">
                            <div className="disp-flex align-center flex-flow-wrap">
                                <h1 className="heading-1 mb0 mr4x">{season.name}</h1>
                            </div>

                            {/* Game/Date Meta */}
                            <div className="season-details__meta">
                                <div className="mb2x">
                                    <div className="disp-flex align-center">
                                        {season.game.esrbRating !== Core.Models.EsrbRating.None && (
                                            <div className="game__title__esrb-rating mr">
                                                <EsrbRating rating={season.game.esrbRating} />
                                            </div>
                                        )}
                                        <GameIcon game={season.game} />
                                        <h2 className="heading-3 mb0 mr2x">{season.game.name}</h2>
                                    </div>
                                </div>
                                <div className="mb2x grid-columns grid-columns--3 grid-columns--for-large text-small">
                                    {!!season.startDate && (
                                        <div>
                                            <FontAwesomeIcon icon={['fas', 'calendar-days']} className="mr" size="sm" />
                                            Starts: {season.startDate}
                                        </div>
                                    )}
                                    {season.participants.filter((p: Core.Models.Participant) => p.isParticipating)
                                        .length > 0 && (
                                            <div>
                                                <FontAwesomeIcon icon={['fas', 'people-group']} className="mr" size="sm" />
                                                {
                                                    season.participants.filter(
                                                        (p: Core.Models.Participant) => p.isParticipating
                                                    ).length
                                                }{' '}
                                                {!!season.maxParticipants && <>/ {season.maxParticipants} </>}
                                                teams
                                            </div>
                                        )}
                                    {season.ageMin && season.ageMax && (
                                        <div>
                                            <FontAwesomeIcon icon={['fas', 'cake-candles']} className="mr" size="sm" />
                                            Age limited: {season.ageMin} to {season.ageMax}
                                        </div>
                                    )}
                                    {season.timingDescription && (
                                        <div>
                                            <FontAwesomeIcon icon={['fas', 'clock']} className="mr" size="sm" />
                                            {season.timingDescription}
                                        </div>
                                    )}
                                    {season.skillLevel >= 0 && (
                                        <div>
                                            <FontAwesomeIcon icon={['fas', 'star']} className="mr" size="sm" />
                                            {Core.Competition.renderCompetitionFormat(season.game)} / Skill level:{' '}
                                            {Core.Models.SKILL_LEVEL_STRINGS[season.skillLevel]}
                                        </div>
                                    )}
                                    {season.enforceMinimumRoster && (
                                        <div>
                                            <FontAwesomeIcon icon={['fas', 'gear']} className="mr" size="sm" />
                                            Min roster enforced
                                        </div>
                                    )}
                                    {season.tennisStyle && (
                                        <div>
                                            <FontAwesomeIcon
                                                icon={['fas', 'table-tennis-paddle-ball']}
                                                className="mr"
                                                size="sm"
                                            />
                                            Tennis style
                                        </div>
                                    )}
                                </div>
                                {!!season.allowedTeamDesignations && 
                                    <>
                                        Allowed Designations:
                                        {season.allowedTeamDesignations.map((designation: Core.Models.LeagueDesignation) => {
                                            return <TeamDesignation designation={designation} inline={true}/>
                                        })}
                                    </>
                                }
                            </div>

                            {/* Can Join */}
                            {canJoinAsFreeAgent && (
                                <>
                                    <SolidButton as="button" className="mt2x mr" onClick={openJoinAsFreeAgent}>
                                        Join as Free Agent
                                    </SolidButton>
                                    {joinAsFreeAgentNode}
                                </>
                            )}
                            {canLeaveAsFreeAgent && (
                                <>
                                    <HollowButton
                                        as="button"
                                        className="mt2x"
                                        color="secondary"
                                        onClick={openLeaveAsFreeAgent}
                                    // tabIndex={editSeasonOpen ? 0 : -1}
                                    >
                                        Leave as Free Agent
                                    </HollowButton>
                                    {leaveAsFreeAgentNode}
                                </>
                            )}
                        </div>

                        {/* Status */}
                        {canEditSeason && !season.isVisible && (
                            <InfoMessage
                                type="info"
                                message={`Competitors cannot see this ${seasonAlternateName.toLowerCase()} yet`}
                            />
                        )}
                        {!!season.payable && (
                            <InfoMessage
                                type="info"
                                message={
                                    <>
                                        This {seasonAlternateName.toLowerCase()} has a fee
                                        {(season.payable.exemptLeagueDesignations?.length ?? 0) > 0 && '*'}
                                        <ToolTip
                                            className="ml"
                                            trigger={
                                                <FontAwesomeIcon
                                                    className="color-secondary-dark-40"
                                                    icon={['fas', 'circle-info']}
                                                    size="sm"
                                                />
                                            }
                                        >
                                            {(season.payable.exemptLeagueDesignations?.length ?? 0) > 0 ? (
                                                <div>
                                                    <p>These team types do not have to pay to enter:</p>
                                                    <ul>
                                                        {season.payable.exemptLeagueDesignations?.map(
                                                            (ld: Core.Models.LeagueDesignation) => (
                                                                <li key={ld.id}>{ld.name}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            ) : (
                                                <span>All teams must pay to enter</span>
                                            )}
                                        </ToolTip>
                                    </>
                                }
                            />
                        )}
                        {season.currentState === Core.Models.CompetitionState.IsComplete && (
                            <InfoMessage type="success" message="Completed" />
                        )}

                        {/* Menu */}
                        {canEditSeason && (
                            <PullContainer className="season-details__options">
                                <>
                                    <TertiaryButton as="link" to={`/seasons/${season.id}/settings`}>
                                        <FontAwesomeIcon icon={['fas', 'pen']} className="mr" size="xs" />
                                        Edit {seasonAlternateName}
                                    </TertiaryButton>
                                    {season.currentState === Core.Models.CompetitionState.NotStarted && (
                                        <>
                                            <TertiaryButton
                                                as="link"
                                                className={classNames(
                                                    hasStages &&
                                                    !hasParticipants &&
                                                    !season.payable && // don't highlight this if season has entry fee since they can't add teams anyway
                                                    'attention'
                                                )}
                                                to={`/seasons/${season.id}/editParticipants`}
                                            >
                                                {!!season.payable ? (
                                                    <>
                                                        <FontAwesomeIcon
                                                            icon={['fas', 'people-group']}
                                                            className="mr"
                                                            size="sm"
                                                        />
                                                        Review Teams
                                                    </>
                                                ) : (
                                                    <>
                                                        <FontAwesomeIcon
                                                            icon={['fas', 'circle-plus']}
                                                            className="mr"
                                                            size="sm"
                                                        />
                                                        Add Teams
                                                    </>
                                                )}
                                            </TertiaryButton>
                                            {season.allowFreeAgents && (
                                                <TertiaryButton
                                                    as="link"
                                                    className={classNames(
                                                        hasStages &&
                                                        hasParticipants &&
                                                        hasActiveFreeAgents &&
                                                        !seasonIsFull &&
                                                        'attention'
                                                    )}
                                                    to={`/seasons/${season.id}/assignFreeAgents`}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={['fas', 'circle-plus']}
                                                        className="mr"
                                                        size="sm"
                                                    />
                                                    Assign Free Agents
                                                </TertiaryButton>
                                            )}

                                            <TertiaryButton
                                                as="button"
                                                onClick={openStartSeason}
                                                className={classNames(
                                                    hasStages &&
                                                    hasParticipants &&
                                                    (!season.allowFreeAgents ||
                                                        !hasActiveFreeAgents ||
                                                        seasonIsFull) &&
                                                    'attention'
                                                )}
                                            >
                                                <FontAwesomeIcon icon={['fas', 'lock']} className="mr" size="sm" />
                                                Lock In Teams
                                            </TertiaryButton>
                                            {startSeasonNode}
                                        </>
                                    )}
                                    {season.currentState === Core.Models.CompetitionState.InProgress && (
                                        <>
                                            {hasNoStartedStages ? (
                                                <>
                                                    <TertiaryButton
                                                        as="button"
                                                        onClick={openUnstartSeason}
                                                        title={`Undo Start ${seasonAlternateName}`}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={['fas', 'arrow-rotate-left']}
                                                            className="mr"
                                                            size="sm"
                                                        />
                                                        <span>Undo Start {seasonAlternateName}</span>
                                                    </TertiaryButton>
                                                    {unstartSeasonNode}
                                                </>
                                            ) : (
                                                <>
                                                    <TertiaryButton
                                                        as="button"
                                                        onClick={openCompleteSeason}
                                                        disabled={!!incompleteStage}
                                                        className={classNames(!incompleteStage && 'attention')}
                                                        title={
                                                            incompleteStage
                                                                ? `Stage ${incompleteStage.name} is not complete`
                                                                : undefined
                                                        }
                                                    >
                                                        {incompleteStage ? (
                                                            <span>
                                                                Complete all stages before completing{' '}
                                                                {seasonAlternateName.toLowerCase()}
                                                            </span>
                                                        ) : (
                                                            <>
                                                                <FontAwesomeIcon
                                                                    icon={['fas', 'check']}
                                                                    className="mr"
                                                                    size="sm"
                                                                />
                                                                <span>Complete {seasonAlternateName}</span>
                                                            </>
                                                        )}
                                                    </TertiaryButton>
                                                </>
                                            )}
                                        </>
                                    )}
                                    {canEditLeague && (
                                        <TertiaryButton as="button" onClick={openDeleteSeason}>
                                            <FontAwesomeIcon icon={['fas', 'trash']} className="mr" size="sm" />
                                            Delete {seasonAlternateName}
                                        </TertiaryButton>
                                    )}
                                </>
                            </PullContainer>
                        )}
                        {canEditSeason && <SeasonWizard season={season} reloadData={reloadSeason} show="steps" />}
                    </ContentContainer>
                </div>
                {completeSeasonNode}
                {deleteSeasonNode}
            </div>
            {canEditSeason && <SeasonWizard season={season} reloadData={reloadSeason} show="recommedations" />}
            <div className="global-container">{!!season.metadata && <Markdown source={season.metadata} />}</div>
        </div>
    );
};

export default SeasonDetails;

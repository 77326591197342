import * as React from 'react';

import ErrorDisplayPage, { ErrorDisplayPageProps } from '../error/errorDisplayPage';
import ErrorHandler from '../error/errorHandler';
import Loading, { LoadingProps } from '../loading';

export interface ErrorLoadingWrapperProps {
    isLoading?: boolean;
    isErrored?: boolean;
    errorTitle?: string;
    errorMessage?: string;
    loadingOptions?: LoadingProps;
    errorDisplayPageOptions?: ErrorDisplayPageProps;
    render: () => JSX.Element;
}

export default class ErrorLoadingWrapper extends React.PureComponent<ErrorLoadingWrapperProps> {
    public render() {
        const { isLoading, isErrored, errorTitle, errorMessage, render, loadingOptions, errorDisplayPageOptions } =
            this.props;

        if (isErrored) {
            return <ErrorDisplayPage {...errorDisplayPageOptions} title={errorTitle} message={errorMessage} />;
        }
        if (isLoading) {
            return <Loading {...loadingOptions} />;
        }
        return <ErrorHandler {...errorDisplayPageOptions} render={render} />;
    }
}

import React from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../core';
import InfoMessage from '../../components/infoMessage';
import { PostService } from '../../services/postService';
import { SolidButton } from '../buttons-visuals';
import FormField from '../formField';

import './index.scss';

interface CreatePostProps {
    gameId?: string;
    matchId?: string;
    onClose: () => void;
    onSubmit: (post: Core.Models.Post) => void;
    organizationId?: string;
    seasonId?: string;
}

export interface CreatePostValues {
    content: string;
    gameId?: string;
    matchId?: string;
    organizationId?: string;
    seasonId?: string;
}

const schema = Yup.object().shape({
    content: Yup.string()
        .max(
            Core.Constants.SOCIAL_POST_MAX_LENGTH,
            `Post length is too long. (${Core.Constants.SOCIAL_POST_MAX_LENGTH} character maximum)`
        )
        .required(),
    gameId: Yup.string().nullable(),
    matchId: Yup.string().nullable(),
    organizationId: Yup.string().nullable(),
    seasonId: Yup.string().nullable(),
});

const CreatePost = (props: CreatePostProps): JSX.Element => {
    const { gameId, matchId, onClose, onSubmit, organizationId, seasonId } = props;

    return (
        <Formik
            initialValues={Object.assign({
                content: '',
                gameId,
                matchId,
                organizationId,
                seasonId,
            } as Partial<CreatePostValues>)}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
                actions.setStatus(undefined);
                try {
                    const post = await PostService.createPost(values);
                    onSubmit(post);
                    onClose();
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(formProps: FormikProps<CreatePostValues>) => {
                return (
                    <Form className="form">
                        <input type="hidden" name="gameId" />
                        <input type="hidden" name="matchId" />
                        <input type="hidden" name="organizationId" />
                        <input type="hidden" name="seasonId" />
                        <FormField
                            aria-describedby={`${formProps.values.content.length} / ${Core.Constants.SOCIAL_POST_MAX_LENGTH}`}
                            component="textarea"
                            label="Content"
                            name="content"
                        />

                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                        <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                        <SolidButton
                            as="button"
                            layout="full"
                            onClick={formProps.submitForm}
                            pending={formProps.isSubmitting}
                            size="medium"
                        >
                            Post
                        </SolidButton>
                    </Form>
                );
            }}
        />
    );
};

export default CreatePost;

import React from 'react';
import { difference } from 'lodash';

interface MultiSelectorProps {
    allIds: string[];
    selectedIds: string[];
    setSelectedIds: (ids: string[]) => void;
}

const MultiSelector = ({ allIds, selectedIds, setSelectedIds }: MultiSelectorProps): JSX.Element => {
    const anySelected = selectedIds.length > 0;
    if (!anySelected) return renderMultiSelector(() => setSelectedIds(allIds));

    const allSelected = difference(allIds, selectedIds).length <= 0;
    if (allSelected) return renderMultiSelector(() => setSelectedIds([]), '--checked');

    return renderMultiSelector(() => setSelectedIds([]), '--checked minused');
};

const renderMultiSelector = (onClick: () => void, classSuffix?: string) => (
    <div className="form-field form-field--type-checkbox form-field--touched">
        <div className="ds-checkbox">
            <input
                checked={false}
                className="ds-checkbox__input"
                name="multi-selector"
                onClick={onClick}
                type="checkbox"
            />
            <div className={`ds-checkbox__box ds-checkbox__box${classSuffix}`}></div>
            <label className="text-italic" htmlFor="multi-selector">
                Select all
            </label>
        </div>
    </div>
);

export default MultiSelector;

import React from 'react';

import { useAutoRejectParticipantList } from './hooks';
import { AutoRejectGame, AutoRejectMatchParticipant } from './types';

interface AutoRejectParticipantsListProps {
    game: AutoRejectGame;
    matchParticipants: AutoRejectMatchParticipant[];
}

const AutoRejectParticipantsList = ({ game, matchParticipants }: AutoRejectParticipantsListProps) => {
    const { header, autoRejectParticipants } = useAutoRejectParticipantList(game, matchParticipants);

    if (autoRejectParticipants.length <= 0) return <></>;
    return (
        <div className="auto-reject-participant-list__container">
            <h4 className="auto-reject-participant-list__header">{header}</h4>
            {autoRejectParticipants.map((p: AutoRejectMatchParticipant) => (
                <p className="auto-reject-participant-list__text">{p.name || 'Deleted team'}</p>
            ))}
        </div>
    );
};

export default AutoRejectParticipantsList;

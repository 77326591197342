import * as Core from './../../core';
import { AppAction } from '..';

export interface PermissionState {
    isLoading: boolean;
    loadingError?: Core.Models.AppError;
    permissions?: Core.Models.Permissions;
}

export const PERMISSIONS_LOADING = 'PERMISSIONS_LOADING';
export const PERMISSIONS_SUCCESS = 'PERMISSIONS_SUCCESS';
export const PERMISSIONS_FAILURE = 'PERMISSIONS_FAILURE';

interface PermissionsLoading extends AppAction<void, typeof PERMISSIONS_LOADING> {
    type: typeof PERMISSIONS_LOADING;
}

export interface PermissionsSuccess extends AppAction<Core.Models.AssignedRoles, typeof PERMISSIONS_SUCCESS> {
    type: typeof PERMISSIONS_SUCCESS;
    payload: Core.Models.AssignedRoles;
}

interface PermissionsFailure extends AppAction<Core.Models.AppError, typeof PERMISSIONS_FAILURE> {
    type: typeof PERMISSIONS_FAILURE;
    payload: Core.Models.AppError;
}

export type PermissionActionTypes = PermissionsLoading | PermissionsSuccess | PermissionsFailure;

import React, { useRef, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import _ from 'lodash';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import * as Core from '../../core';
import EditLeagueSeasonCategory from './editLeagueSeasonCategory';
import { AddButton, HollowButton, SolidButton } from '../../components/buttons-visuals';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';
import { withLoadDataDefaultConfig } from '../../components/loadData';
import Modal from '../../components/modal';
import { useConfirmModal } from '../../hooks/confirmModal';
import useForceUpdate from '../../hooks/forceUpdate';
import { useAlternateSeasonName } from '../../hooks/store';
import { SeasonService } from '../../services/seasonService';

interface EditLeagueSeasonCategoriesProps extends Core.Models.ReloadDataProps {
    seasonCategories: Core.Models.SeasonCategory[];
}

interface AddSCFormValues {
    name: string;
}

const EditLeagueSeasonCategories: React.FunctionComponent<EditLeagueSeasonCategoriesProps> = (props) => {
    const { seasonCategories, reloadData } = props;

    // allows us to force a component to rerender
    const forceUpdate = useForceUpdate();

    const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState<boolean>(false);
    const [isAddingSC, setIsAddingSC] = React.useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [submitError, setSubmitError] = React.useState<string | undefined>();
    const seasonAlternateName = useAlternateSeasonName();

    const scrollThumbRef = useRef<HTMLDivElement>(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollContainerRef.current && scrollThumbRef.current) {
                const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
                const scrollThumbWidthPercent = (clientWidth / scrollWidth) * 100;
                const scrollThumbWidthPx = (scrollThumbWidthPercent / 100) * clientWidth;

                const percentScrolled = (scrollLeft / scrollWidth) * 100;
                const left = (percentScrolled / 100) * clientWidth;

                scrollThumbRef.current.style.left = `${left}px`;
                scrollThumbRef.current.style.width = `${scrollThumbWidthPx}px`;
            }
        };

        handleScroll();
        scrollContainerRef.current?.addEventListener('scroll', handleScroll);
        return () => {
            scrollContainerRef.current?.removeEventListener('scroll', handleScroll);
        };
    }, [scrollContainerRef]);

    const submitNewSC = async (values: AddSCFormValues, actions: FormikActions<AddSCFormValues>) => {
        try {
            await SeasonService.createSeasonCategory(values.name);
            setHasUnsavedChanges(false);
            await reloadData(false, true);
        } catch (error) {
            const message = Core.API.getErrorMessage(error);
            actions.setStatus(message);
        } finally {
            actions.setSubmitting(false);
            setIsAddingSC(false);
        }
    };

    // Submit the new order to the server
    const submitNewOrder = async () => {
        const categories = seasonCategories.map((sc) => {
            return {
                id: sc.id,
                seasonIds: sc.seasons.map((s) => s.id),
            } as Core.Models.SeasonCategoryBasic;
        });

        setIsSubmitting(true);
        try {
            await SeasonService.setSeasonCategoryOrder(categories);
            setSubmitError(undefined);
            await reloadData(false, true);
        } catch (error) {
            setSubmitError(`Error submitting new ${seasonAlternateName.toLowerCase()} sort order.`);
        }

        setIsSubmitting(false);
    };

    // Schema for adding season category
    const addSCSchema = Yup.object().shape({
        name: Yup.string().required('Name is required').max(50),
    });

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;

        if (!destination) return;
        if (source.droppableId === destination.droppableId && source.index === destination.index) return;

        // Grab source category in model
        const sourceCategory = _.find(seasonCategories, (sc) => sc.id === source.droppableId);
        const destCategory = _.find(seasonCategories, (sc) => sc.id === destination.droppableId);

        if (!sourceCategory || !destCategory) return; // Needed for ts compilation

        // remove source season from source category
        const sourceSeason = _.pullAt(sourceCategory.seasons, source.index);

        // insert source participant into destination group in intended position
        destCategory.seasons.splice(destination.index, 0, ...sourceSeason);

        setHasUnsavedChanges(true);
    };

    // Swap category with one to its left or right
    const moveCategory = (categoryIndex: number, direction: number) => {
        if (![-1, 1].includes(direction)) throw Error(`Direction must be -1 or 1 (left or right)`);

        const newInd = categoryIndex + direction;

        if (typeof seasonCategories[newInd] === 'undefined') throw Error(`Can't move a category that's at either end`);

        const category = seasonCategories.splice(categoryIndex, 1)[0];
        seasonCategories.splice(newInd, 0, category);

        forceUpdate();

        setHasUnsavedChanges(true);
    };

    // Category delete
    const [categoryBeingDeleted, setCategoryBeingDeleted] = React.useState<Core.Models.SeasonCategory | undefined>();
    const [deleteCategoryModal, openDeleteCategoryModal, closeDeleteCategoryModal] = useConfirmModal(
        () => `Delete ${seasonAlternateName} Category`,
        () => (
            <p>
                Are you sure you want to delete <strong>{categoryBeingDeleted && categoryBeingDeleted.name}</strong>?
            </p>
        ),
        async () => {
            if (typeof categoryBeingDeleted === 'undefined') return;

            await SeasonService.deleteSeasonCategory(categoryBeingDeleted.id);

            closeDeleteCategoryModal();
            await reloadData(false, true);
        }
    );

    const editButtons = useMemo(
        () => (
            <>
                <div className="disp-flex flex-gap">
                    <SolidButton
                        as="button"
                        disabled={!hasUnsavedChanges}
                        onClick={submitNewOrder}
                        pending={isSubmitting}
                        size="medium"
                        type="submit"
                    >
                        Save
                    </SolidButton>
                    <AddButton
                        as="button"
                        buttonStyle="solid"
                        buttonSize="medium"
                        layoutInline
                        onClick={() => setIsAddingSC(true)}
                    >
                        Add {seasonAlternateName} Category
                    </AddButton>
                </div>
                {submitError && <InfoMessage type="error" message={submitError}></InfoMessage>}
            </>
        ),
        [hasUnsavedChanges, isSubmitting, submitNewOrder, submitError, seasonAlternateName]
    );

    return (
        <section>
            <div className="global-container-centered text-center">
                <p className="mb4x">
                    Drag &amp; drop {seasonAlternateName.toLowerCase()}s to put them in categories. Only categories with
                    no {seasonAlternateName.toLowerCase()}s may be deleted. Please save changes after moving{' '}
                    {seasonAlternateName.toLowerCase()}s around to enable category deletion.
                </p>
            </div>

            {editButtons}

            {isAddingSC && (
                <Modal onClose={() => setIsAddingSC(false)} title={`Add ${seasonAlternateName.toLowerCase()} category`}>
                    <Formik
                        initialValues={{ name: '' }}
                        onSubmit={submitNewSC}
                        validationSchema={addSCSchema}
                        render={(props: FormikProps<AddSCFormValues>) => (
                            <Form>
                                <fieldset className="form-group">
                                    <FormField
                                        type="text"
                                        name="name"
                                        description={`${seasonAlternateName} Category Name`}
                                        placeholder={`${seasonAlternateName} Category Name`}
                                        className="invite-host__text-input"
                                    />
                                </fieldset>

                                {props.status && <InfoMessage type="error" message={props.status} />}
                                <InfoMessage type="error" message={props.errors} filter={props.touched} />

                                <fieldset className="form-group form-group--undecorated">
                                    <SolidButton
                                        as="button"
                                        onClick={props.submitForm}
                                        layout="full"
                                        pending={props.isSubmitting}
                                        size="medium"
                                    >
                                        Add {seasonAlternateName} Category
                                    </SolidButton>
                                </fieldset>
                            </Form>
                        )}
                    />
                </Modal>
            )}

            <div className="settings-page__season-ordering-horizontal-scroll mt4x">
                <div className="settings-page__season-ordering-horizontal-scroll__scrollthumb" ref={scrollThumbRef} />
            </div>
            <div className="settings-page__season-ordering-container">
                <div className="settings-page__season-ordering" ref={scrollContainerRef}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        {seasonCategories.map((seasonCategory, scix) => (
                            <div key={scix} className="settings-page__season-ordering__category">
                                <h5 className="heading-5">{seasonCategory.name}</h5>
                                <div className="settings-page__season-ordering__category__controls">
                                    <HollowButton
                                        as="button"
                                        className="mr"
                                        color="primary"
                                        disabled={scix === 0 || isSubmitting}
                                        onClick={() => moveCategory(scix, -1)}
                                        size="medium"
                                    >
                                        <FontAwesomeIcon icon={['fas', 'arrow-right']} flip="horizontal" />
                                    </HollowButton>
                                    <HollowButton
                                        as="button"
                                        className="mr"
                                        color="primary"
                                        disabled={scix === seasonCategories.length - 1 || isSubmitting}
                                        onClick={() => moveCategory(scix, 1)}
                                        size="medium"
                                    >
                                        <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                                    </HollowButton>
                                    <HollowButton
                                        as="button"
                                        color="destructive"
                                        disabled={
                                            isSubmitting ||
                                            seasonCategories.length === 1 ||
                                            hasUnsavedChanges ||
                                            seasonCategory.seasons.length > 0
                                        }
                                        onClick={() => {
                                            setCategoryBeingDeleted(seasonCategory);
                                            openDeleteCategoryModal();
                                        }}
                                        size="medium"
                                    >
                                        <FontAwesomeIcon icon={['fas', 'xmark']} />
                                    </HollowButton>
                                </div>
                                {/* need to always show this because it's a landing zone */}
                                <EditLeagueSeasonCategory
                                    seasonCategoryId={seasonCategory.id}
                                    seasons={seasonCategory.seasons}
                                />
                            </div>
                        ))}
                    </DragDropContext>
                </div>
            </div>

            {editButtons}

            {deleteCategoryModal}
        </section>
    );
};

export default connect(
    () => ({}),
    {}
)(
    withLoadDataDefaultConfig(
        errorLoadingWrapperHOC<EditLeagueSeasonCategoriesProps>(EditLeagueSeasonCategories, {
            loadingOptions: { blockItem: true },
        }),
        () => {},
        async () => {
            const seasonCategories = await SeasonService.getAllSeasonCategories();

            return { seasonCategories };
        }
    )
);

import React, { useMemo } from 'react';

import Markdown from '../../components/markdown';

interface PreScheduledDescriptionProps {
    value?: string;
}

export const PreScheduledDescription = ({ value }: PreScheduledDescriptionProps) => {
    const description = useMemo(
        () => value || 'This stage has not been started yet - try an earlier stage or check back later.',
        [value]
    );

    return (
        <div className="stage__preScheduledDescription">
            <Markdown source={description} />
        </div>
    );
};

export default PreScheduledDescription;

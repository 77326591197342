import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orderBy } from 'lodash';
import pluralize from 'pluralize';
import { NavLink } from 'react-router-dom';

import * as Core from '../../core';
import {
    useAlternateSeasonName,
    useHelpfulLinks,
    useIsPlatform,
    useLeague,
    useLeagueConfiguration,
    useOrganizationTerm,
    useProfile,
    useUserPermissionService,
} from '../../hooks/store';
import { canAccessDashboard } from '../../pages/dashboard';
import { NavList, NavListItem } from '../navigationComponents';

import './index.scss';

const NUM_AFFILIATE_LEAGUES_TO_SHOW = 5;

interface NavigationProps {
    toggleActive?: () => void;
}

export const Navigation = (props: NavigationProps): JSX.Element => {
    const isPlatform = useIsPlatform();
    const userPermissionService = useUserPermissionService();
    const profile = useProfile();
    const league = useLeague();
    const leagueConfiguration = useLeagueConfiguration();
    const isAdmin = userPermissionService.getIsAdmin();
    const canEditLeague = userPermissionService.hasLeagueAccess(Core.Models.PermissionLevel.Edit, league);
    const seasonAlternateName = pluralize(useAlternateSeasonName());
    const organizationTerm = useOrganizationTerm();
    const childLeagues = useMemo(
        () => orderBy(leagueConfiguration?.childLeagues, (l: Core.Models.BasicLeague) => l.name),
        [leagueConfiguration?.childLeagues]
    );
    const helpfulLinks = useHelpfulLinks();

    return (
        <NavList>
            <>
                {isPlatform && (
                    <NavListItem>
                        <NavLink onClick={props.toggleActive} activeClassName="active" to={`/search`}>
                            Search
                        </NavLink>
                    </NavListItem>
                )}
                {isPlatform && isAdmin && (
                    <NavListItem>
                        <NavLink onClick={props.toggleActive} activeClassName="active" to={`/users/search`}>
                            User search
                        </NavLink>
                    </NavListItem>
                )}
                {isPlatform && isAdmin && (
                    <NavListItem>
                        <NavLink onClick={props.toggleActive} activeClassName="active" to={`/create-game`}>
                            Create Game
                        </NavLink>
                    </NavListItem>
                )}
                {!isPlatform && !!league?.landingPageFilePath && (
                    <NavListItem>
                        <NavLink onClick={props.toggleActive} activeClassName="active" to={`/home`}>
                            Welcome
                        </NavLink>
                    </NavListItem>
                )}
                {!isPlatform &&
                    !!league &&
                    !!profile &&
                    canAccessDashboard({ league, profile, userPermissionService }) && (
                        <NavListItem>
                            <NavLink onClick={props.toggleActive} activeClassName="active" exact to={`/`}>
                                Dashboard
                            </NavLink>
                        </NavListItem>
                    )}
                {!isPlatform && !!league && (
                    <NavListItem>
                        <NavLink onClick={props.toggleActive} activeClassName="active" to={`/league`}>
                            League Home
                        </NavLink>
                    </NavListItem>
                )}
                {!isPlatform && canEditLeague && (
                    <NavListItem>
                        <NavLink onClick={props.toggleActive} activeClassName="active" to={`/league-settings`}>
                            League Settings
                        </NavLink>
                    </NavListItem>
                )}
                {!isPlatform && league?.requireIdentityVerification && canEditLeague && (
                    <NavListItem>
                        <NavLink onClick={props.toggleActive} activeClassName="active" to={`/identity-verification`}>
                            Player Identity Verification
                        </NavLink>
                    </NavListItem>
                )}
                {!isPlatform && !!leagueConfiguration?.parentLeague && (
                    <NavListItem subItemsTitle="Parent League">
                        <a href={leagueConfiguration.parentLeague.domain} onClick={props.toggleActive}>
                            {leagueConfiguration.parentLeague.name}
                        </a>
                    </NavListItem>
                )}
                {!isPlatform && !!leagueConfiguration?.clubOrganization && (
                    <NavListItem>
                        <NavLink
                            activeClassName="active"
                            onClick={props.toggleActive}
                            to={`/organizations/${leagueConfiguration.clubOrganization.id}`}
                        >
                            All Teams
                        </NavLink>
                    </NavListItem>
                )}
                {!isPlatform && !!profile?.organizationId && !leagueConfiguration?.clubOrganization && (
                    <NavListItem>
                        <NavLink
                            onClick={props.toggleActive}
                            activeClassName="active"
                            to={`/organizations/${profile.organizationId}`}
                        >
                            My {organizationTerm}
                        </NavLink>
                    </NavListItem>
                )}
                {!isPlatform && !!profile?.teams && profile.teams.length > 0 && (
                    <NavListItem subItemsTitle="My Teams">
                        {profile.teams.map((t: Core.Models.Team) => (
                            <NavLink key={t.id} onClick={props.toggleActive} to={`/teams/${t.id}`}>
                                {t.name}
                            </NavLink>
                        ))}
                    </NavListItem>
                )}
                {!isPlatform && !!profile?.activeSeasons && profile.activeSeasons.length > 0 && (
                    <NavListItem subItemsTitle={`My ${seasonAlternateName}`}>
                        {profile.activeSeasons.map((s: Core.Models.Season) => (
                            <NavLink key={s.id} onClick={props.toggleActive} to={`/seasons/${s.id}`}>
                                {s.name}
                            </NavLink>
                        ))}
                    </NavListItem>
                )}
                {!isPlatform &&
                    helpfulLinks.map((hl: Core.Models.HelpfulLink) => (
                        <NavListItem key={hl.id}>
                            <a href={hl.url} rel="noopener noreferrer" target="_blank" onClick={props.toggleActive}>
                                {hl.name}
                                {hl.disablePublic && (
                                    <FontAwesomeIcon className="ml" icon={['fas', 'lock']} size="1x" />
                                )}
                            </a>
                        </NavListItem>
                    ))}
                {!isPlatform && !!childLeagues && childLeagues.length > 0 && (
                    <NavListItem subItemsTitle="Affiliate Leagues">
                        <>
                            {childLeagues
                                .slice(0, NUM_AFFILIATE_LEAGUES_TO_SHOW)
                                .map((childLeague: Core.Models.BasicLeague) => (
                                    <a key={childLeague.id} href={childLeague.domain} onClick={props.toggleActive}>
                                        {childLeague.name}
                                    </a>
                                ))}
                            {childLeagues.length > NUM_AFFILIATE_LEAGUES_TO_SHOW && (
                                <NavLink onClick={props.toggleActive} activeClassName="active" to="/affiliate-leagues">
                                    All Affiliate Leagues
                                </NavLink>
                            )}
                        </>
                    </NavListItem>
                )}
            </>
        </NavList>
    );
};

export default Navigation;

import React from 'react';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import pluralize from 'pluralize';
import * as Yup from 'yup';

import * as Core from '../../../core';
import { useCreateRescheduleRequestForm } from './hooks';
import { Button } from '../../../components/button';
import FormField from '../../../components/formField';
import InfoMessage from '../../../components/infoMessage';
import Loading from '../../../components/loading';
import { MatchService } from '../../../services/matchService';

import './styles.scss';

interface CreateRescheduleRequestFormProps {
    match: {
        id: string;
        rescheduleCutoffUtc?: string;
        startTimeUtc?: string;
    };
    matchWord: string;
    onClose: () => Promise<void>;
}

interface CreateRescheduleRequestFormValues {
    proposedTimeUtc: string;
}

const CreateRescheduleRequestForm = (props: CreateRescheduleRequestFormProps): JSX.Element => {
    const { match, matchWord, onClose } = props;
    const { maxRescheduleCutoff } = useCreateRescheduleRequestForm(match.rescheduleCutoffUtc);
    return (
        <Formik<CreateRescheduleRequestFormValues>
            initialValues={Object.assign({
                proposedTimeUtc: match.startTimeUtc || '',
            })}
            onSubmit={async (
                values: CreateRescheduleRequestFormValues,
                actions: FormikActions<CreateRescheduleRequestFormValues>
            ) => {
                actions.setStatus(undefined);
                try {
                    await MatchService.createRescheduleRequest({
                        matchId: match.id,
                        proposedTimeUtc: values.proposedTimeUtc,
                    });
                    await onClose();
                } catch (error) {
                    const message = Core.API.getErrorMessage(error);
                    actions.setStatus(message);
                } finally {
                    actions.setSubmitting(false);
                }
            }}
            render={(formProps: FormikProps<CreateRescheduleRequestFormValues>) => (
                <Form>
                    <fieldset className="form-group">
                        <FormField component="datetime" name="proposedTimeUtc" label="Proposed time" />
                    </fieldset>

                    <p className="create-reschedule-request-form__note">
                        {!!match.rescheduleCutoffUtc && (
                            <div className="create-reschedule-request-form__note__cutoff">
                                {pluralize(matchWord)} in this round cannot be rescheduled after{' '}
                                <strong>{maxRescheduleCutoff}</strong>
                            </div>
                        )}
                        This will create a reschedule request. Both teams will need to approve the request before this
                        event will be rescheduled.
                    </p>
                    {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                    <InfoMessage message={formProps.errors} filter={formProps.touched} type="error" />
                    <fieldset className="form-group form-group--undecorated">
                        {formProps.isSubmitting && <Loading buttonLoader />}
                        <Button disabled={formProps.isSubmitting} onClick={formProps.submitForm} round wide>
                            Submit Reschedule Request
                        </Button>
                    </fieldset>
                </Form>
            )}
            validationSchema={Yup.object().shape({
                proposedTimeUtc: Yup.date()
                    .required('A proposed time is required')
                    .min(new Date(), 'Event cannot be rescheduled to a time that is in the past')
                    .max(
                        match.rescheduleCutoffUtc ?? Core.Constants.MAX_DATE,
                        `Event cannot be rescheduled after ${maxRescheduleCutoff}`
                    ),
            })}
        />
    );
};

export default CreateRescheduleRequestForm;

import React from 'react';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import { isNumber } from 'lodash';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../../core';
import { Button } from '../../../components/button';
import ErrorMessage from '../../../components/errorMessage';
import FormField from '../../../components/formField';
import Loading from '../../../components/loading';
import { MatchService } from '../../../services/matchService';

interface AddLinkProps {
    availableMatchLinkTypes: number[];
    matchId: string;
    onClose: () => void;
    reloadData: (showLoading: boolean) => Promise<void>;
}

interface AddLinkValues {
    matchId: string;
    type: Core.Models.MatchLinkType;
    url: string;
}

export const linkTypes: number[] = Object.values(Core.Models.MatchLinkType)
    .filter((value: number | string) => isNumber(value))
    .map((value: number | string) => +value);
const linkTypeValues: { [key: number]: string } = {
    [Core.Models.MatchLinkType.TeamsMeeting]: 'Teams Meeting',
};

const AddLink = (props: AddLinkProps): JSX.Element => {
    const { availableMatchLinkTypes, matchId, onClose, reloadData } = props;

    return (
        <Formik<AddLinkValues>
            initialValues={{
                matchId,
                type: Core.Models.MatchLinkType.TeamsMeeting,
                url: '',
            }}
            validationSchema={Yup.object().shape({
                type: Yup.number()
                    .min(linkTypes[0], 'Link type is required')
                    .max(linkTypes[linkTypes.length - 1], 'Link type is required'),
                url: Yup.string().url('URL is not valid').required('URL is required'),
            })}
            onSubmit={async (values: AddLinkValues, actions: FormikActions<AddLinkValues>) => {
                actions.setStatus(undefined);

                try {
                    await MatchService.addMatchLink(values);
                    toast.success('Added new match link');
                    await reloadData(false);
                    onClose();
                } catch (e) {
                    toast.error('Unable to add new match link');
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                } finally {
                    actions.setSubmitting(false);
                }
            }}
            render={(formProps: FormikProps<AddLinkValues>) => (
                <Form>
                    <fieldset className="form-group">
                        <input type="hidden" name="matchId" />

                        <FormField component="select" description="Link type" name="type">
                            {availableMatchLinkTypes.map((type: number) => (
                                <option key={type} value={type}>
                                    {linkTypeValues[type]}
                                </option>
                            ))}
                        </FormField>

                        <FormField type="url" name="url" description="URL" placeholder="URL" />
                    </fieldset>

                    {formProps.status && <ErrorMessage error={formProps.status} />}
                    <ErrorMessage error={formProps.errors} filter={formProps.touched} />

                    <fieldset className="form-group form-group--undecorated">
                        {formProps.isSubmitting && <Loading buttonLoader />}
                        <Button onClick={formProps.submitForm} disabled={formProps.isSubmitting} wide round>
                            Add link
                        </Button>
                    </fieldset>
                </Form>
            )}
        />
    );
};

export default AddLink;

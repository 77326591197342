import React, { useCallback, useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';

import * as Core from '../../core';
import FormField from '../../components/formField';
import RegistrationForm, { RegistrationFormValues } from '../../components/registrationForm';
import RegistrationHeader from '../../components/registrationHeader';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useLeague, useLeagueConfiguration, useOrganizationTerm } from '../../hooks/store';
import { OrganizationService } from '../../services/organizationService';
import { UserService } from '../../services/userService';

interface CreateOrganizationPageProps extends WithLoadingProps, RouteComponentProps<{ inviteCode: string }> {}

export interface CreateOrganizationFormValues extends RegistrationFormValues {
    availableOrganizationId?: string;
    inviteCode: string;
    organizationName?: string;
}

const CreateOrganizationPage = (props: CreateOrganizationPageProps): JSX.Element => {
    const {
        match: {
            params: { inviteCode },
        },
        setError,
        setIsLoading,
    } = props;

    const league = useLeague();
    const organizationTerm = useOrganizationTerm();
    const leagueConfiguration = useLeagueConfiguration();

    const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | undefined>(undefined);
    const [organizationOptions, setOrganizationOptions] = useState<Core.Models.AvailableOrganization[] | undefined>(
        undefined
    );

    useEffect(() => {
        (async () => {
            try {
                const response = await OrganizationService.getOrganizationsForInviteCode(inviteCode);
                setOrganizationOptions(response);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [inviteCode, setError, setIsLoading]);

    const renderOrganizationFieldGroup = useCallback((): JSX.Element => {
        if (!league) return <></>;

        const contactUs = (!!league.phoneNumber || !!league.email) && (
            <>
                Contact us at {league.phoneNumber && <em>{league.phoneNumber}</em>}
                {league.phoneNumber && league.email && ' or '}
                {league.email && (
                    <em>
                        <a href={`mailto:${league.email}`}>{league.email}</a>
                    </em>
                )}
            </>
        );

        const showAddress = (org: Core.Models.AvailableOrganization): boolean => {
            if (!org.streetAddress && !org.city && !org.state && !org.postalCode && !org.phoneNumber) return false;
            return true;
        };

        if (!!organizationOptions && organizationOptions.length) {
            const selectedOrganization = selectedOrganizationId
                ? organizationOptions.filter((o) => o.id === selectedOrganizationId)[0]
                : undefined;
            return (
                <>
                    <fieldset className="form-group form-group create-organization">
                        <FormField
                            className="create-organization__organization"
                            component="select"
                            description={`Select ${organizationTerm}`}
                            name="availableOrganizationId"
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                setSelectedOrganizationId(e.target.value)
                            }
                        >
                            <option key={-1} value={''} disabled>
                                Select {organizationTerm}
                            </option>
                            {orderBy(organizationOptions, (i) => i.name).map(
                                (organization: Core.Models.AvailableOrganization, index: number) => {
                                    return (
                                        <option key={index} value={organization.id}>
                                            {organization.name}
                                        </option>
                                    );
                                }
                            )}
                        </FormField>
                        {selectedOrganization && showAddress(selectedOrganization) && (
                            <div className="create-organization__selected-organization">
                                <p className="create-organization__selected-organization__line1">
                                    {selectedOrganization.streetAddress}, {selectedOrganization.city},{' '}
                                    {selectedOrganization.state} {selectedOrganization.postalCode}
                                </p>
                                {!!selectedOrganization.phoneNumber && (
                                    <p className="create-organization__selected-organization__line2">
                                        {selectedOrganization.phoneNumber}
                                    </p>
                                )}
                            </div>
                        )}
                    </fieldset>
                    <div className="create-organization__info-wrong">
                        {!!selectedOrganization
                            ? contactUs && (
                                  <p>
                                      {organizationTerm} information incorrect, or need help registering? {contactUs}.
                                  </p>
                              )
                            : contactUs && (
                                  <p>
                                      Don't see your {organizationTerm.toLowerCase()}, or need help registering?{' '}
                                      {contactUs}.
                                  </p>
                              )}
                    </div>
                </>
            );
        } else {
            return (
                <fieldset className="form-group">
                    <FormField type="text" description={`${organizationTerm} Name`} name="organizationName" />
                </fieldset>
            );
        }
    }, [league, organizationOptions, organizationTerm, selectedOrganizationId]);

    // form should be unavailable for Club Edition leagues
    if (league?.edition === Core.Models.LeagueEdition.Club) {
        return <Redirect to="/" />;
    }

    if (!league) return <></>;
    if (!organizationOptions) return <></>;
    return (
        <div>
            <RegistrationForm<CreateOrganizationFormValues>
                acceptEmail={true}
                header={
                    <>
                        <RegistrationHeader
                            formInstructions="All fields are required."
                            message={`Participate in ${
                                league.name
                            }'s official esports league! Register your ${organizationTerm.toLowerCase()} today by filling in the information below.`}
                            welcome={`Welcome to ${league.name}`}
                        />
                        {renderOrganizationFieldGroup()}
                    </>
                }
                initialValues={{
                    availableOrganizationId: '',
                    inviteCode,
                    organizationName: '',
                }}
                leagueFee={leagueConfiguration?.leagueFee}
                register={async (values: CreateOrganizationFormValues) => {
                    const payload = {
                        ...values,
                        availableOrganizationId: !!values.availableOrganizationId
                            ? values.availableOrganizationId
                            : undefined,
                        organizationName: !!values.organizationName ? values.organizationName : undefined,
                    };

                    return await UserService.createOrganization(payload);
                }}
                registrationAction={Core.Models.RegistrationAction.CreateOrganization}
                schema={{
                    availableOrganizationId: Yup.string().when('organizationName', {
                        is: (organizationName?: string) => !organizationName,
                        then: Yup.string().required(`${organizationTerm} is required`),
                        otherwise: Yup.string(),
                    }),
                    inviteCode: Yup.string().required('Invite code is required'),
                    organizationName: Yup.string().when('availableOrganizationId', {
                        is: (availableOrganizationId?: string) => !availableOrganizationId,
                        then: Yup.string().required(`${organizationTerm} is required`),
                        otherwise: Yup.string(),
                    }),
                }}
                schemaDependentFields={['availableOrganizationId', 'organizationName']}
                submitText={`Create ${organizationTerm}`}
            />
        </div>
    );
};

export default withLoading(CreateOrganizationPage, {
    errorDisplayPageProps: { fullPage: false },
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});

import * as React from 'react';
import { createPortal } from 'react-dom';

import ConfirmModal from '../components/confirmModal';

// this is intended to make it easier to use a <ConfirmModal> from a functional component.
// It will track whether the modal is open and provides the element to render and functions to call to open/close it from the containing component.
// The first argument returned is the `React.ReactNode` to render, followed by the functions to call to open and close the modal.
export function useConfirmModal(
    title: () => React.ReactNode,
    modalContent: () => React.ReactNode,
    onConfirm: (closeModal: () => void) => Promise<any>,
    modalProperties?: {
        cancelText?: string;
        confirmText?: string;
    }
): [React.ReactNode, () => void, () => void] {
    const [isShowing, setIsShowing] = React.useState(false);
    const close = React.useCallback(() => setIsShowing(false), [setIsShowing]);
    const open = React.useCallback(() => setIsShowing(true), [setIsShowing]);
    return [
        isShowing ? (
            createPortal(
                <ConfirmModal
                    cancelText={modalProperties?.cancelText}
                    confirmText={modalProperties?.confirmText}
                    onCancel={close}
                    onConfirm={async () => await onConfirm(close)}
                    title={title()}
                >
                    {modalContent()}
                </ConfirmModal>,
                document.body
            )
        ) : (
            <></>
        ),
        open,
        close,
    ];
}

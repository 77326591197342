import * as ReduxThunk from 'redux-thunk';

import * as Core from '../../core';
import { LEAGUE_LOADING, LEAGUE_SUCCESS, LEAGUE_FAILURE } from './types';
import { AppAction, AppState } from '..';
import { LeagueService } from '../../services/leagueService';

// action creators
export const leagueLoadAction = () => {
    return {
        type: LEAGUE_LOADING,
    };
};

export const leagueSuccessAction = (league: Core.Models.LeagueConfiguration) => {
    return {
        type: LEAGUE_SUCCESS,
        payload: league,
    };
};

export const leagueFailureAction = (error: Core.Models.AppError) => {
    return {
        type: LEAGUE_FAILURE,
        payload: error,
        error: true,
    };
};

// thunk actions
export const getLeague =
    (showLoading: boolean = true): ReduxThunk.ThunkAction<Promise<void>, AppState, null, AppAction> =>
    async (dispatch) => {
        if (showLoading) dispatch(leagueLoadAction());

        try {
            const config = await LeagueService.getLeagueConfigurationForUrl(window.location.href);
            Core.API.setLeagueId(config.league.id);
            dispatch(leagueSuccessAction(config));
        } catch (error) {
            dispatch(leagueFailureAction(error));
        }
    };

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

import * as Core from '../../../core';
import { RadiusSizes, Shades } from '@leaguespot/platform-js/src/models';
import { AddButton, HollowButton, SolidButton, StatusIndicator } from '../../../components/buttons-visuals';
import { ContentContainer } from '../../../components/containers/contentContainer';
import ThemeVariables from '../../../components/themeContainer/themeVariables';

import './index.scss';

interface LeagueThemePreviewProps {
    colors: Core.Models.ThemeColors;
}

const LeagueThemePreview = (props: LeagueThemePreviewProps): JSX.Element => {
    const { colors } = props;

    const { ref, inView, entry } = useInView({
        threshold: 0,
        rootMargin: '-64px 0px 0px 0px',
    });

    return (
        <>
            <div className="league-theme-preview">
                <div ref={ref} className="observer-target" style={{ height: '2px' }} />
                <h5 className="heading-2 league-theme-preview__heading">Preview</h5>
                <div
                    className={classNames('league-theme-preview__container', {
                        'league-theme-preview__container--sticky': !inView,
                    })}
                >
                    <div className="mb2x">
                        <h6 className="heading-6">Primary Text</h6>
                        <p>
                            This section is where you can preview any color changes you want to make to your league.
                            Below you will find a preview of the background color, buttons, badges, and more. These
                            changes won't take effect until you save them.
                        </p>
                    </div>

                    <div className="mb2x">
                        <h6 className="heading-6">Background Shades</h6>
                        <ContentContainer
                            shade={Shades.Dark40}
                            className="full-width background-color-container text-small text-center p"
                            radius={RadiusSizes.XL}
                        >
                            <p className="mb">Background Dark</p>
                            <ContentContainer className="p" shade={Shades.Dark20} radius={RadiusSizes.Large}>
                                <p className="mb">Background Medium</p>
                                <ContentContainer className="p" radius={RadiusSizes.Medium}>
                                    <p className="mb0">Background Base</p>
                                </ContentContainer>
                            </ContentContainer>
                        </ContentContainer>
                    </div>

                    <div className="league-theme-preview__container__grid">
                        <ContentContainer shade={Shades.Dark40}>
                            <h6 className="heading-6">Primary</h6>
                            <div className="disp-flex align-center flex-gap flex-wrap">
                                <SolidButton as="button" color="primary" size="small">
                                    Solid Button
                                </SolidButton>
                                <HollowButton as="button" color="primary" size="small">
                                    Hollow Button
                                </HollowButton>
                                {/* <InfoMessage message="Informational message" type="info" /> */}
                                <StatusIndicator color="primary" iconName="info">
                                    Status Indicator
                                </StatusIndicator>
                            </div>
                        </ContentContainer>
                        <ContentContainer shade={Shades.Dark40}>
                            <h6 className="heading-6">Secondary</h6>
                            <div className="disp-flex align-center flex-gap flex-wrap">
                                <SolidButton as="button" color="secondary" size="small">
                                    Solid Button
                                </SolidButton>
                                <StatusIndicator color="secondary" iconName="info">
                                    Status Indicator
                                </StatusIndicator>
                            </div>
                        </ContentContainer>
                        <ContentContainer shade={Shades.Dark40}>
                            <h6 className="heading-6">Success</h6>
                            <div className="disp-flex align-center flex-gap flex-wrap">
                                <StatusIndicator color="success">Status Indicator</StatusIndicator>
                                <AddButton as="button" buttonStyle="solid" buttonSize="small" layoutInline>
                                    Add Button
                                </AddButton>
                                <FontAwesomeIcon icon={['fas', 'circle-check']} className="color-success" size="xl" />
                            </div>
                        </ContentContainer>
                        <ContentContainer shade={Shades.Dark40}>
                            <h6 className="heading-6">Error</h6>
                            <div className="disp-flex align-center flex-gap flex-wrap">
                                <SolidButton as="button" color="destructive" size="small">
                                    Solid Button
                                </SolidButton>
                                <HollowButton as="button" color="destructive" size="small">
                                    Hollow Button
                                </HollowButton>
                                <StatusIndicator color="error">Status Indicator</StatusIndicator>
                            </div>
                        </ContentContainer>
                        <ContentContainer shade={Shades.Dark40}>
                            <h6 className="heading-6">Accent 1</h6>
                            <div className="disp-flex align-center flex-gap">
                                <StatusIndicator color="accent1" iconName="meteor">
                                    Status Indicator
                                </StatusIndicator>
                            </div>
                        </ContentContainer>
                        <ContentContainer shade={Shades.Dark40}>
                            <h6 className="heading-6">Accent 2</h6>
                            <div className="disp-flex align-center">
                                <StatusIndicator color="accent2" iconName="jedi">
                                    Status Indicator
                                </StatusIndicator>
                            </div>
                        </ContentContainer>
                    </div>
                </div>
            </div>
            <ThemeVariables className="league-theme-preview__container" themeColors={colors} />
        </>
    );
};

export default LeagueThemePreview;

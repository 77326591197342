import React, { useEffect, useState } from 'react';

import * as Core from '../../core';
import { ContentContainer } from '../../components/containers';
import MatchSchedule from '../../components/matchSchedule';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { UserService } from '../../services/userService';

interface UserMatchListProps extends WithLoadingProps {
    headerText?: string;
    type: Core.Models.UserMatchListType;
    userId: string;
}

const UserMatchList = ({ headerText, setError, setIsLoading, type, userId }: UserMatchListProps): JSX.Element => {
    const [matches, setMatches] = useState<Core.Models.UserMatches>({ matches: [], participantIds: [] });

    useEffect(() => {
        (async () => {
            try {
                const response = await UserService.getMatches(userId, type);
                setMatches(response);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        <ContentContainer className="global-container mb4x" shade={Core.Models.Shades.Dark40}>
            {headerText && <h3 className="heading-2">{headerText}</h3>}
            <MatchSchedule
                matches={matches.matches}
                isPrimary={(mp: Core.Models.MatchParticipant) => matches.participantIds.includes(mp.participantId)}
            />
        </ContentContainer>
    );
};

export default withLoading(UserMatchList, { loadingProps: { blockItem: true } });

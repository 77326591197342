import React from 'react';
import ColorContrastChecker from 'color-contrast-checker';
import { Form, Formik, FormikProps } from 'formik';
import hexToHsl from 'hex-to-hsl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../../core';
import LeagueThemePreview from './leagueThemePreview';
import ThemeColor from './themeColor';
import { SolidButton } from '../../../components/buttons-visuals';
import InfoMessage from '../../../components/infoMessage';
import LiteLimitationMessage from '../../../components/liteLimitationMessage';
import { ToolTip } from '../../../components/overlays';
import { genericThemeColors, useThemeColors } from '../../../components/themeContainer';
import { useIsLite, useLeague } from '../../../hooks/store';
import { LeagueService } from '../../../services/leagueService';
import { getLeague } from '../../../store/league/actions';
import GetLogoColors from '../getLogoColors';

import './index.scss';

export interface ThemeColorsValues extends Core.Models.ThemeColors {}

function nameof<T>(name: keyof T) {
    return name;
}

const colors = [
    {
        defaultColor: genericThemeColors.primaryTextColor,
        description: 'Main font color across the entire site',
        name: nameof<Core.Models.ThemeColors>('primaryTextColor'),
        label: 'Primary Text',
    },
    {
        defaultColor: genericThemeColors.backgroundColor,
        description: 'Main background color for pages and content containers',
        name: nameof<Core.Models.ThemeColors>('backgroundColor'),
        label: 'Background',
    },
    {
        defaultColor: genericThemeColors.primaryColor,
        description: 'Primary button color and first level hierarchy text/heading highlighting',
        name: nameof<Core.Models.ThemeColors>('primaryColor'),
        label: 'Primary',
    },
    {
        defaultColor: genericThemeColors.primaryButtonTextColor,
        description: 'Text color for solid primary buttons and badges',
        name: nameof<Core.Models.ThemeColors>('primaryButtonTextColor'),
        label: 'Primary Button Text',
    },
    {
        defaultColor: genericThemeColors.secondaryColor,
        description: 'Secondary button and second level hierarchy text/heading highlighting',
        name: nameof<Core.Models.ThemeColors>('secondaryColor'),
        label: 'Secondary',
    },
    {
        defaultColor: genericThemeColors.secondaryButtonTextColor,
        description: 'Text color for solid secondary buttons',
        name: nameof<Core.Models.ThemeColors>('secondaryButtonTextColor'),
        label: 'Secondary Button Text',
    },
    {
        defaultColor: genericThemeColors.success,
        description: 'Signals an item is in a success state',
        name: nameof<Core.Models.ThemeColors>('success'),
        label: 'Success',
    },
    {
        defaultColor: genericThemeColors.error,
        description: 'Signals an item is in an error state',
        name: nameof<Core.Models.ThemeColors>('error'),
        label: 'Error',
    },
    {
        defaultColor: genericThemeColors.accent1,
        description: 'Small non-critical highlighting or status',
        name: nameof<Core.Models.ThemeColors>('accent1'),
        label: 'Accent 1',
    },
    {
        defaultColor: genericThemeColors.accent2,
        description: 'Small non-critical highlighting or status',
        name: nameof<Core.Models.ThemeColors>('accent2'),
        label: 'Accent 2',
    },
];

const hexCodeRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
const contrastChecker = new ColorContrastChecker();
const isValidContrast = (color1: string, color2: string): boolean => {
    if (!color1 || !color2) return true;
    if ([color1, color2].some((color) => !hexCodeRegex.test(color))) return true;
    return contrastChecker.isLevelAA(color1, color2, 14);
};

const getSchema = () => {
    const schema = colors.reduce(
        (accumulator, color: { name: string; label: string }) => ({
            ...accumulator,
            [color.name]: Yup.string()
                .required(`${color.label} is required.`)
                .test(
                    'is-valid-hex',
                    `${color.label} must be a valid hex code (begin with a '#' prefix and contain either 3 or 6 characters to denote the hex value)`,
                    (value: string | undefined) => {
                        if (!value) return false;
                        return hexCodeRegex.test(value);
                    }
                ),
        }),
        {}
    );

    return Yup.object().shape(schema);
};

const WcagLink = (): JSX.Element => (
    <>
        <span className="mr">
            In an effort to make content more accessible to all users, consider using colors with higher contrast.
        </span>
        <a href="https://www.w3.org/TR/WCAG20" rel="noopener noreferrer" target="_blank">
            Learn more
        </a>
    </>
);

const ThemeColors = (): JSX.Element => {
    const leagueThemeColors = useThemeColors();
    const dispatch = useDispatch();
    const league = useLeague();
    const isLite = useIsLite();

    return (
        <section className="global-container">
            {isLite && (
                <LiteLimitationMessage
                    className="fit-content m-auto mb2x"
                    message={`${Core.Constants.PLANS.LITE.NAME} instances do not have access to this feature.`}
                />
            )}
            {!!league?.logoUrl && (league.logoColors?.length ?? 0) <= 0 && (
                <InfoMessage
                    message={
                        <div className="m2x">
                            <div className="mb2x text-large">
                                You can now automatically load your league colors from your logo.
                            </div>
                            <GetLogoColors />
                        </div>
                    }
                    type="info"
                />
            )}
            <Formik<ThemeColorsValues>
                initialValues={leagueThemeColors}
                validationSchema={getSchema()}
                onSubmit={async (values, actions) => {
                    actions.setStatus(undefined);
                    try {
                        await LeagueService.updateLeagueThemeColors(values);
                        dispatch(getLeague(false));
                        toast.success('Successfully updated theme colors');
                    } catch (e) {
                        const message = Core.API.getErrorMessage(e);
                        actions.setStatus(message);
                    } finally {
                        actions.setSubmitting(false);
                    }
                }}
                render={(formProps: FormikProps<ThemeColorsValues>) => {
                    const isInvalidTextContrast = !isValidContrast(
                        formProps.values.primaryTextColor,
                        formProps.values.backgroundColor
                    );
                    const isInvalidPrimaryContrast = !isValidContrast(
                        formProps.values.primaryColor,
                        formProps.values.primaryButtonTextColor
                    );
                    const isInvalidSecondaryContrast = !isValidContrast(
                        formProps.values.secondaryColor,
                        formProps.values.secondaryButtonTextColor
                    );
                    const isInvalidBackgroundLightness =
                        !!formProps.values.backgroundColor &&
                        hexCodeRegex.test(formProps.values.backgroundColor) &&
                        hexToHsl(formProps.values.backgroundColor)[2] < 15;

                    return (
                        <Form className="league-settings__theme-colors">
                            <div className="league-settings__theme-colors__container">
                                <div className="league-settings__theme-colors__container__color-grid">
                                    <ToolTip
                                        className="full-width"
                                        hide={!isLite}
                                        trigger={
                                            <SolidButton
                                                as="button"
                                                className="mb2x"
                                                disabled={formProps.isSubmitting || isLite}
                                                layout="full"
                                                pending={formProps.isSubmitting}
                                                onClick={formProps.submitForm}
                                                size="medium"
                                            >
                                                Save
                                            </SolidButton>
                                        }
                                    >
                                        <span>This action is not available in {Core.Constants.PLANS.LITE.NAME}.</span>
                                    </ToolTip>
                                    {colors.map(({ defaultColor, description, name, label }) => (
                                        <ThemeColor
                                            {...{
                                                backgroundColor: leagueThemeColors.backgroundColor,
                                                defaultColor,
                                                description,
                                                key: name,
                                                label,
                                                name,
                                                onChange: (value: string) => formProps.setFieldValue(name, value),
                                                value: (formProps.values as any)[name],
                                            }}
                                        />
                                    ))}
                                </div>
                                <LeagueThemePreview colors={formProps.values} />
                            </div>

                            {isInvalidTextContrast && (
                                <InfoMessage
                                    message={
                                        <>
                                            <span className="mr">
                                                Primary Text color has insufficient contrast with Background color
                                                according to WCAG 2.0 Level AA guidelines.
                                            </span>
                                            <WcagLink />
                                        </>
                                    }
                                    type="warning"
                                />
                            )}
                            {isInvalidPrimaryContrast && (
                                <InfoMessage
                                    message={
                                        <>
                                            <span className="mr">
                                                Primary color has insufficient contrast with Primary Button Text color
                                                according to WCAG 2.0 Level AA guidelines.
                                            </span>
                                            <WcagLink />
                                        </>
                                    }
                                    type="warning"
                                />
                            )}
                            {isInvalidSecondaryContrast && (
                                <InfoMessage
                                    message={
                                        <>
                                            <span className="mr">
                                                Secondary color has insufficient contrast with Secondary Button Text
                                                color according to WCAG 2.0 Level AA guidelines.
                                            </span>
                                            <WcagLink />
                                        </>
                                    }
                                    type="warning"
                                />
                            )}
                            {isInvalidBackgroundLightness && (
                                <InfoMessage
                                    message={`Background color is too dark to ensure proper shading.  We darken the Background color in various places across the site to provide contrasting backgrounds between components.  If your Background color is too dark, it won't provide enough contrast between components in some locations.`}
                                    type="warning"
                                />
                            )}
                            {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                            <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                            <ToolTip
                                className="full-width"
                                hide={!isLite}
                                trigger={
                                    <SolidButton
                                        as="button"
                                        disabled={formProps.isSubmitting || isLite}
                                        layout="full"
                                        pending={formProps.isSubmitting}
                                        onClick={formProps.submitForm}
                                        size="medium"
                                    >
                                        Save
                                    </SolidButton>
                                }
                            >
                                <span>This action is not available in {Core.Constants.PLANS.LITE.NAME}.</span>
                            </ToolTip>
                        </Form>
                    );
                }}
            />
        </section>
    );
};

export default ThemeColors;

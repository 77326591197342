import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import * as Core from '../../core';
import PoweredByLeagueSpot from './poweredByLeagueSpot';
import { useIsPlatform } from '../../hooks/store';

import './index.scss';

interface FooterProps {
    className?: string;
}

const Footer = (props: FooterProps): JSX.Element => {
    const { className } = props;
    const isPlatform = useIsPlatform();

    // we only show LeagueSpot branding on URLs of format *.leaguespot.gg
    const { showLeagueSpotBranding } = Core.Configuration;

    return (
        <footer className={classNames('footer', className)}>
            <div className="footer__privacy-terms">
                <a href={Core.Constants.PRIVACY_POLICY_URL} rel="noopener noreferrer" target="_blank">
                    Privacy Policy
                </a>
                <a href={Core.Constants.TERMS_OF_SERVICE_URL} rel="noopener noreferrer" target="_blank">
                    Terms of Service
                </a>
                {!isPlatform && <Link to={`/contact`}>Contact</Link>}
            </div>

            {showLeagueSpotBranding && (
                <>
                    <PoweredByLeagueSpot />

                    <p className="mb0">
                        {new Date().getFullYear()} &copy; Copyright Legacy Esports Inc. All Rights Reserved.
                    </p>
                </>
            )}
        </footer>
    );
};

export default Footer;

import React from 'react';
import classNames from 'classnames';

import { BaseButton, BaseButtonTypes } from '../baseButton';

import './TertiaryButton.scss';

const TertiaryButton = ({ children, className, ...rest }: BaseButtonTypes) => {
    const tertiaryButtonClasses = classNames(className, 'ds-tertiary-button');

    return (
        <BaseButton className={tertiaryButtonClasses} {...rest}>
            {children}
        </BaseButton>
    );
};

export default TertiaryButton;

import React, { ChangeEvent } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../core';
import { actions } from './constants';
import { useIdentityVerificationModal } from './hooks';
import { IdentityVerificationValues, ModalAction, SubmissionUserIdQueue } from './types';
import { Button } from '../../components/button';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';
import Modal, { ModalProps } from '../../components/modal';

import './styles.scss';

interface IdentityVerificationModalProps extends ModalProps {
    calculateSubmissionQueue?: (userId: string) => void;
    queue: SubmissionUserIdQueue;
}

export const IdentityVerificationModal = ({
    calculateSubmissionQueue,
    queue,
    ...rest
}: IdentityVerificationModalProps) => {
    const {
        adminNotesButtonText,
        autoSave,
        currentState,
        currentStateText,
        isLoading,
        setCurrentState,
        setShowAdminNotes,
        showAdminNotes,
        submission,
        updateVerificationSubmission,
    } = useIdentityVerificationModal(queue.currentId);

    if (!submission) return <></>;
    return (
        <Modal className="identity-verification__modal" title="Update Verification Status" {...rest}>
            {!isLoading && (
                <Formik<IdentityVerificationValues>
                    initialValues={{
                        notes: submission.adminNotes || '',
                    }}
                    onSubmit={updateVerificationSubmission}
                    render={({
                        errors,
                        isSubmitting,
                        status,
                        submitForm,
                        touched,
                        values,
                    }: FormikProps<IdentityVerificationValues>) => (
                        <Form className="identity-verification__modal__form">
                            <div className="identity-verification__modal__form__meta">
                                <div className="identity-verification__modal__form__meta__image__container">
                                    {submission.state ===
                                    Core.Models.UserIdentityVerificationSubmissionState.Approved ? (
                                        <p>
                                            This submission has been approved, and the associated image has been
                                            deleted.
                                        </p>
                                    ) : (
                                        <img
                                            alt="identity verification"
                                            className="identity-verification__modal__form__meta__image"
                                            src={submission.imageUrl}
                                        />
                                    )}
                                </div>
                                {!!submission.userNotes && (
                                    <p className="identity-verification__modal__form__meta__user-notes">
                                        {submission.userNotes}
                                    </p>
                                )}
                                <ul className="identity-verification__modal__form__meta__text">
                                    <li>{submission.firstName}</li>
                                    <li>{submission.lastName}</li>
                                    <li>{submission.birthdate}</li>
                                    <li>{submission.discordHandle}</li>
                                    <li>{submission.email}</li>
                                </ul>
                                {currentState !== Core.Models.UserIdentityVerificationSubmissionState.Approved && (
                                    <Button
                                        semiRound
                                        styleType={Core.Models.StyleType.TextOnly}
                                        onClick={() => {
                                            setShowAdminNotes(!showAdminNotes);
                                        }}
                                    >
                                        {adminNotesButtonText}
                                    </Button>
                                )}
                                {showAdminNotes && (
                                    <FormField
                                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                            autoSave({
                                                ...values,
                                                notes: e.target?.value ?? '',
                                            });
                                        }}
                                        disabled={
                                            currentState ===
                                            Core.Models.UserIdentityVerificationSubmissionState.Approved
                                        }
                                        component="textarea"
                                        name="notes"
                                        description="Additional information"
                                        placeholder="Add notes here"
                                    />
                                )}
                            </div>
                            <fieldset className="identity-verification__modal__form__actions">
                                <span className="identity-verification__modal__form__actions__state">
                                    {currentStateText}
                                </span>
                                {(!!queue.previousId || !!queue.nextId) && (
                                    <div className="identity-verification__modal__form__actions--pair">
                                        <Button
                                            disabled={!queue.previousId || isSubmitting || isLoading}
                                            semiRound
                                            outline
                                            onClick={() =>
                                                !!queue.previousId && calculateSubmissionQueue?.(queue.previousId)
                                            }
                                        >
                                            Prev
                                        </Button>
                                        <Button
                                            disabled={!queue.nextId || isSubmitting || isLoading}
                                            semiRound
                                            outline
                                            onClick={() => !!queue.nextId && calculateSubmissionQueue?.(queue.nextId)}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                )}
                                {actions.map(({ buttonStyle, name, newState, primaryAction }: ModalAction) => (
                                    <Button
                                        className={`identity-verification__modal__form__actions__action${
                                            !!primaryAction ? '--primary' : ''
                                        }`}
                                        styleType={buttonStyle.type}
                                        disabled={
                                            currentState ===
                                                Core.Models.UserIdentityVerificationSubmissionState.Approved ||
                                            isSubmitting ||
                                            isLoading
                                        }
                                        key={name}
                                        onClick={() => {
                                            setCurrentState(newState);
                                            submitForm();
                                        }}
                                        outline={buttonStyle.outline ?? false}
                                        semiRound
                                    >
                                        {name}
                                    </Button>
                                ))}
                                {status && <InfoMessage message={status} type="error" />}
                                <InfoMessage message={errors} filter={touched} type="error" />
                            </fieldset>
                        </Form>
                    )}
                    validationSchema={Yup.object().shape({
                        notes: Yup.string(),
                    })}
                />
            )}
        </Modal>
    );
};

import React from 'react';
import classNames from 'classnames';

import { NavListItemProps, ListItemType } from './index';

function NavListItem({ className, children, subItemsTitle, type = 'link' }: NavListItemProps) {
    const navListItemClasses = classNames(className, 'ds-nav-list-item', `ds-nav-list-item--${type}`);

    return (
        <li className={navListItemClasses}>
            {subItemsTitle ? (
                <>
                    <p className="ds-nav-list-item__header mb0">{subItemsTitle}</p>
                    <ul className="ds-nav-list-item__sub-items list-unstyled">
                        {new Array<ListItemType>().concat(children).map((child: ListItemType, index: number) => {
                            return <li key={index}>{child}</li>;
                        })}
                    </ul>
                </>
            ) : (
                children
            )}
        </li>
    );
}

export default NavListItem;

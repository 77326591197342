import * as React from 'react';
import { Formik, Form, FormikProps } from 'formik';
import moment from 'moment-timezone';
import * as Yup from 'yup';

import * as Core from '../../core';
import { Button } from '../../components/button';
import ErrorMessage from '../../components/errorMessage';
import FormField from '../../components/formField';
import Loading from '../../components/loading';
import { useAlternateSeasonName, useTimezone } from '../../hooks/store';

import './index.scss';

interface AddBirthdateProps {
    onSubmit: (birthdate: string) => Promise<void>;
}

interface AddBirthdateValues {
    birthdate: string;
}

const schema = Yup.object().shape({
    birthdate: Yup.string()
        .required('Date of birth is required')
        .test('is-valid', 'Please enter a valid date of birth', (birthdate: string | undefined) => {
            if (!birthdate) return false;
            const { birthdateIsValid } = Core.Time.checkBirthdateStringUnder13(birthdate);
            return birthdateIsValid;
        }),
});

const AddBirthdate = (props: AddBirthdateProps): JSX.Element => {
    const { onSubmit } = props;
    const timezone = useTimezone();
    const seasonAlternateName = useAlternateSeasonName();

    return (
        <Formik
            initialValues={Object.assign({
                birthdate: '',
            } as Partial<AddBirthdateValues>)}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
                actions.setStatus(undefined);
                try {
                    await onSubmit(moment.tz(values.birthdate, timezone).format(Core.Time.getFormat()));
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                } finally {
                    actions.setSubmitting(false);
                }
            }}
            render={(formProps: FormikProps<AddBirthdateValues>) => {
                return (
                    <Form className="form add-birthdate">
                        <p className="add-birthdate__info">
                            Without a birthdate on your profile, you may not be able to join some{' '}
                            {seasonAlternateName.toLowerCase()}s due to age restrictions.
                        </p>
                        <fieldset className="form-group">
                            <FormField
                                component="date"
                                contextualProps={{ hidePicker: true }}
                                label={`Date of birth (${Core.Time.getFormat()})`}
                                name="birthdate"
                            />
                        </fieldset>

                        {formProps.status && <ErrorMessage error={formProps.status} />}
                        <ErrorMessage error={formProps.errors} filter={formProps.touched} />

                        <fieldset className="form-group form-group--undecorated">
                            {formProps.isSubmitting && <Loading buttonLoader />}
                            <Button
                                onClick={formProps.submitForm}
                                disabled={formProps.isSubmitting}
                                styleType={Core.Models.StyleType.Primary}
                                wide
                                round
                            >
                                Submit
                            </Button>
                        </fieldset>
                    </Form>
                );
            }}
        />
    );
};

export default AddBirthdate;

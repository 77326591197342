import React, { ChangeEvent } from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { HexColorPicker } from 'react-colorful';

import * as Core from '../../../../core';
import { RadiusSizes } from '@leaguespot/platform-js/src/models';
import { DesignationEditorMode, NEW_DESIGNATION_ID, colorOptions, iconOptions } from './constants';
import { useDesignationEditor } from './hooks';
import { ColorOption } from './types';
import { BaseButton, IconButton, TertiaryButton } from '../../../../components/buttons-visuals';
import { ContentContainer } from '../../../../components/containers/contentContainer';
import { TextInput } from '../../../../components/inputs/textinput';
import TeamDesignation from '../../../../components/teamDesignation';

import './designationEditor.scss';

interface DesignationEditorProps {
    designation?: Core.Models.LeagueDesignation;
    mode: DesignationEditorMode;
    onCreate?: () => void;
    onDelete?: (id: string) => Promise<void>;
    onModeChange?: (id: string, mode: DesignationEditorMode) => void;
    onSave?: (designation: Core.Models.LeagueDesignation) => Promise<void>;
}

export const DesignationEditor = ({
    designation: passedDesignation,
    mode,
    onCreate,
    onDelete,
    onModeChange,
    onSave,
}: DesignationEditorProps) => {
    const {
        colorPickerOpen,
        designationColor,
        designationIconName,
        designationName,
        designationNamePlaceholder,
        inputRef,
        resetDesignationValues,
        setColorPickerOpen,
        setDesignationColor,
        setDesignationIconName,
        setDesignationName,
    } = useDesignationEditor(mode, passedDesignation);
    return (
        <li
            className={classNames('designation-editor', {
                'designation-editor--new': mode === DesignationEditorMode.New,
                'designation-editor--edit': mode === DesignationEditorMode.Edit,
                'designation-editor--delete': mode === DesignationEditorMode.Delete,
            })}
        >
            {mode !== DesignationEditorMode.New ? (
                <>
                    {!!passedDesignation && (
                        <>
                            <TeamDesignation
                                radius={RadiusSizes.Large}
                                designation={
                                    {
                                        color: designationColor,
                                        iconName: designationIconName,
                                        name: designationName,
                                    } as Core.Models.LeagueDesignation
                                }
                            />
                            {/* create designation section */}
                            <ContentContainer radius={RadiusSizes.Large} className="designation-editor__designation">
                                <TextInput
                                    className={classNames('designation-editor__designation__input mb0', {
                                        'designation-editor__designation__input--display':
                                            mode !== DesignationEditorMode.Edit,
                                    })}
                                    disabled={mode !== DesignationEditorMode.Edit}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setDesignationName(e.target.value)}
                                    placeholder={designationNamePlaceholder}
                                    value={designationName}
                                    ref={inputRef}
                                    label="Team Designation"
                                />
                            </ContentContainer>
                            {/* icons section */}
                            <ContentContainer radius={RadiusSizes.Large} className="designation-editor__symbol">
                                <p className="designation-editor__section-title text-small mb">Team Symbol</p>
                                <div className="designation-editor__symbol__grid">
                                    {iconOptions.map((option: string) => (
                                        <IconButton
                                            as="button"
                                            buttonLabel={`Choose ${option} icon`}
                                            buttonSize="xlarge"
                                            className={classNames('radius-medium', {
                                                active: option === designationIconName,
                                            })}
                                            key={option}
                                            onClick={() => setDesignationIconName(option)}
                                            {...(mode !== DesignationEditorMode.Edit && { tabIndex: -1 })}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', option as IconName]}
                                                size="xl"
                                                className="designation-editor__symbol__grid__icon"
                                            />
                                        </IconButton>
                                    ))}
                                    {mode === DesignationEditorMode.Edit && (
                                        <IconButton
                                            as="button"
                                            buttonLabel="Remove team icon"
                                            buttonSize="xlarge"
                                            className="radius-medium"
                                            onClick={() => setDesignationIconName('ban')}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'ban']}
                                                size="xl"
                                                className="designation-editor__symbol__grid__icon"
                                            />
                                        </IconButton>
                                    )}
                                </div>
                            </ContentContainer>
                            {/* controls section */}
                            <div className="designation-editor__controls">
                                {mode === DesignationEditorMode.Edit ? (
                                    <>
                                        <IconButton
                                            buttonLabel="Close edit mode"
                                            buttonSize="small"
                                            as="button"
                                            onClick={async () => {
                                                if (passedDesignation.id === NEW_DESIGNATION_ID) {
                                                    await onDelete?.(passedDesignation.id);
                                                } else {
                                                    resetDesignationValues(passedDesignation);
                                                }

                                                onModeChange?.(passedDesignation.id, DesignationEditorMode.Display);
                                            }}
                                        >
                                            <FontAwesomeIcon className="color-error" icon={['fas', 'square-xmark']} />
                                        </IconButton>
                                        <IconButton
                                            disabled={!designationColor || !designationName}
                                            buttonLabel="Save changes"
                                            buttonSize="small"
                                            as="button"
                                            onClick={async () => {
                                                onModeChange?.(passedDesignation.id, DesignationEditorMode.Display);
                                                await onSave?.({
                                                    ...passedDesignation,
                                                    color: designationColor,
                                                    iconName: designationIconName,
                                                    name: designationName,
                                                });
                                            }}
                                        >
                                            <FontAwesomeIcon className="color-success" icon={['fas', 'square-check']} />
                                        </IconButton>
                                    </>
                                ) : (
                                    <>
                                        <IconButton
                                            buttonLabel="Enter edit mode"
                                            buttonSize="small"
                                            as="button"
                                            onClick={() =>
                                                onModeChange?.(passedDesignation.id, DesignationEditorMode.Edit)
                                            }
                                        >
                                            <FontAwesomeIcon icon={['fas', 'pen']} />
                                        </IconButton>
                                        <IconButton
                                            buttonLabel="Delete designation"
                                            buttonSize="small"
                                            as="button"
                                            onClick={() =>
                                                onModeChange?.(passedDesignation.id, DesignationEditorMode.Delete)
                                            }
                                        >
                                            <FontAwesomeIcon icon={['fas', 'trash']} />
                                        </IconButton>
                                    </>
                                )}
                            </div>
                            {/* color selection */}
                            <ContentContainer radius={RadiusSizes.Large} className="designation-editor__color">
                                <p className="designation-editor__section-title text-small mb">Color</p>
                                <ul className="designation-editor__color-options">
                                    {colorOptions.map((option: ColorOption) => (
                                        <li key={option.name}>
                                            <BaseButton
                                                as="button"
                                                className={classNames(
                                                    'designation-editor__color-option',
                                                    `designation-editor__color-option--${option.name}`,
                                                    {
                                                        'designation-editor__color-option--active':
                                                            option.hex === designationColor,
                                                    }
                                                )}
                                                onClick={() => setDesignationColor(option.hex)}
                                                {...(mode !== DesignationEditorMode.Edit && { tabIndex: -1 })}
                                            />
                                        </li>
                                    ))}
                                    <li className="position-relative">
                                        <IconButton
                                            as="button"
                                            buttonLabel="Select color"
                                            buttonSize="medium"
                                            className="designation-editor__color-option-palette"
                                            onClick={() => setColorPickerOpen(!colorPickerOpen)}
                                            {...(mode !== DesignationEditorMode.Edit && { tabIndex: -1 })}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'palette']}
                                                style={{ color: designationColor }}
                                            />
                                        </IconButton>
                                        {colorPickerOpen && (
                                            <HexColorPicker
                                                color={designationColor}
                                                onChange={(color: string) => setDesignationColor(color)}
                                            />
                                        )}
                                    </li>
                                </ul>
                            </ContentContainer>
                            {/* delete confirm overlay*/}
                            {mode === DesignationEditorMode.Delete && (
                                <div className="designation-editor__confirmation-overlay text-center">
                                    <p style={{ fontSize: '56px' }}>Delete?</p>
                                    <div className="mt4x">
                                        <TertiaryButton
                                            as="button"
                                            className="text-large"
                                            onClick={async () => await onDelete?.(passedDesignation.id)}
                                        >
                                            Yes
                                        </TertiaryButton>
                                        <TertiaryButton
                                            as="button"
                                            className="text-large"
                                            onClick={() =>
                                                onModeChange?.(passedDesignation.id, DesignationEditorMode.Display)
                                            }
                                        >
                                            No
                                        </TertiaryButton>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {!passedDesignation && (
                        <div className="designation-editor__confirmation-overlay text-center">
                            <p style={{ fontSize: '24px' }}>Cannot find designation</p>
                        </div>
                    )}
                </>
            ) : (
                <BaseButton as="button" className="designation-editor__add-designation" onClick={onCreate}>
                    <FontAwesomeIcon
                        className="designation-editor__add-designation__icon"
                        icon={['fas', 'circle-plus']}
                    />
                    Add Designation
                </BaseButton>
            )}
        </li>
    );
};

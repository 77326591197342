import React, { lazy, Suspense } from 'react';

const DynamicLoader = (props: { component?: string }): JSX.Element => {
    const { component } = props;

    const LazyComponent = lazy(() => import(`${component}`).catch(() => ({ default: <div>Oops!</div> })));

    return (
        <Suspense fallback={<></>}>
            <LazyComponent />
        </Suspense>
    );
};

export default DynamicLoader;

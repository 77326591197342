import React, { useCallback } from 'react';

import * as Core from '../../core';
import MatchSchedule from '../../components/matchSchedule';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import useApiPagination from '../../hooks/apiPagination';
import { useOrganizationTerm, useProfile } from '../../hooks/store';
import { OrganizationService } from '../../services/organizationService';

interface OrganizationUpcomingMatchesProps extends WithLoadingProps {}

const OrganizationUpcomingMatches = (props: OrganizationUpcomingMatchesProps) => {
    const { setError, setIsLoading } = props;
    const organizationTerm = useOrganizationTerm();
    const profile = useProfile();

    const getPaginatedOrganizationMatchesAsync = useCallback(
        (page: number, pageSize: number): Promise<Core.Models.PaginatedResult<Core.Models.Match>> | undefined => {
            if (!profile?.organizationId) return undefined;
            return OrganizationService.getUpcomingMatches(profile.organizationId, { page, pageSize });
        },
        [profile]
    );

    const { paginatedResults, Paginator } = useApiPagination(
        getPaginatedOrganizationMatchesAsync,
        setError,
        setIsLoading,
        Core.Constants.PAGINATION.MATCHES_PER_PAGE
    );

    return (
        <div className="upcoming-matches">
            <h2>
                Upcoming Events for{' '}
                <span className="theme__text__alternate">
                    {profile?.organizationName || `your ${organizationTerm.toLowerCase()}`}
                </span>
            </h2>
            {!!paginatedResults?.results.length ? (
                <MatchSchedule matches={paginatedResults.results} />
            ) : (
                <p>Nothing to show here yet.</p>
            )}
            {Paginator}
        </div>
    );
};

export default withLoading(OrganizationUpcomingMatches);

export abstract class CacheService {
    public static get<T>(cacheKey: string): T | null {
        let result: T | null = null;

        // attempt to get the localStorage object
        const cacheJson: string | null = localStorage.getItem(cacheKey);
        if (cacheJson) {
            // if found parse it
            const cacheValue = JSON.parse(cacheJson);

            // check expiry
            const expiry: Date = new Date(cacheValue.expiry);
            if (expiry > new Date()) {
                result = cacheValue.value as T;
            } else {
                localStorage.removeItem(cacheKey);
            }
        }

        return result;
    }

    public static set(cacheKey: string, obj: any, expiryMinutes: number): { expiry: Date; value: any } {
        // get expiry
        const expiry: Date = new Date();
        expiry.setMinutes(expiry.getMinutes() + expiryMinutes);

        const item = {
            expiry,
            value: obj || '',
        };
        localStorage.setItem(cacheKey, JSON.stringify(item));

        return item;
    }

    public static remove(cacheKey: string): void {
        localStorage.removeItem(cacheKey);
    }
}

import * as React from 'react';

import * as Core from '../../core';
import SetTeamSeasonForm, { SetTeamSeasonFormValues } from './form';
import { useAlternateSeasonName } from '../../hooks/store';
import { ParticipantService } from '../../services/participantService';
import { TeamService } from '../../services/teamService';
import ConfirmModal from '../confirmModal';
import Modal from '../modal';

import './index.scss';

interface SetTeamSeasonProps {
    hasEntryFee: boolean;
    onAddFulfillment: (fulfillment: Core.Models.PayableFulfillment) => void;
    onCancel: () => void;
    onComplete: () => Promise<void>;
    seasonId?: string;
    team: Core.Models.Team;
}

const SetTeamSeason: React.FunctionComponent<SetTeamSeasonProps> = (props) => {
    const { hasEntryFee, onAddFulfillment, onCancel, onComplete, team } = props;

    const seasonAlternateName = useAlternateSeasonName();

    const [notParticipatingParticipantSeason, setNotParticipatingParticipantSeason] = React.useState<
        Core.Models.ParticipantSeason | undefined
    >(undefined);

    const onNotParticipateInSeason = async () => {
        await ParticipantService.setParticipantIsParticipating({
            id: notParticipatingParticipantSeason!.id,
            isParticipating: false,
        });
        await onComplete();
    };
    return (
        <>
            {notParticipatingParticipantSeason ? (
                <ConfirmModal
                    onCancel={() => setNotParticipatingParticipantSeason(undefined)}
                    onConfirm={onNotParticipateInSeason}
                    title={`Leave ${seasonAlternateName.toLowerCase()}`}
                >
                    <p>
                        Are you sure you want to remove this team from{' '}
                        <strong>{notParticipatingParticipantSeason.season.name}</strong>? This action cannot be undone.{' '}
                        {hasEntryFee && 'Entry fees will not be refunded.'}
                    </p>
                </ConfirmModal>
            ) : (
                <Modal onClose={onCancel} title={`Choose ${seasonAlternateName} for ${team.name}`}>
                    <SetTeamSeasonForm
                        fulfillments={team.fulfillments}
                        leagueDesignationId={team.designationId}
                        onAddFulfillment={onAddFulfillment}
                        onNotParticipateInSeason={(participantSeason: Core.Models.ParticipantSeason) =>
                            setNotParticipatingParticipantSeason(participantSeason)
                        }
                        onRemoveSeason={async () => {
                            await TeamService.setSeason({
                                teamId: team.id,
                            });
                            await onComplete();
                        }}
                        onSetSeason={async (
                            values: SetTeamSeasonFormValues,
                            teamMemberRankings: Core.Models.TeamMemberRanking[]
                        ) => {
                            await TeamService.setSeason({
                                teamId: team.id,
                                teamMemberRankings,
                                ...values,
                            });
                            await onComplete();
                        }}
                        teamId={team.id}
                    />
                </Modal>
            )}
        </>
    );
};

export default SetTeamSeason;

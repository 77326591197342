import React from 'react';
import classNames from 'classnames';

import * as Core from '../../../core';
import HeadToHeadItem from './headToHeadItem';
import { CompetitionMatchupProps } from '..';
import { useCanEditSeason } from '../../../hooks/store';

import './index.scss';

export const HeadToHead = (props: CompetitionMatchupProps): JSX.Element => {
    const {
        className,
        enableHighlight,
        location,
        isMyParticipant,
        isPreview,
        match,
        matchParticipants,
        onStationSelect,
    } = props;

    const canEditSeason = useCanEditSeason(match.season.id);

    const formatForForfeit = matchParticipants.some(
        (participant: Core.Models.MatchParticipant) => participant.hasForfeited
    );
    const showScore = matchParticipants.length > 1 && match.currentState >= Core.Models.MatchState.InProgress;
    const byeMatchup = matchParticipants.length <= 1;

    return (
        <div
            className={classNames('head-to-head', className, {
                'head-to-head--has-stations': !!location && !isPreview,
            })}
        >
            <HeadToHeadItem
                {...{
                    byeMatchup,
                    canEditSeason,
                    currentState: match.currentState,
                    enableHighlight,
                    formatForForfeit,
                    isMyParticipant,
                    isPreview: !!isPreview,
                    location,
                    onStationSelect,
                    participant: matchParticipants[0] || {}, // handle match errors
                    showScore,
                }}
            />
            <HeadToHeadItem
                {...{
                    byeMatchup,
                    canEditSeason,
                    currentState: match.currentState,
                    enableHighlight,
                    formatForForfeit,
                    isMyParticipant,
                    isPreview: !!isPreview,
                    location,
                    onStationSelect,
                    participant: matchParticipants[1] || {}, // handle byes
                    showScore,
                }}
            />
        </div>
    );
};

export default HeadToHead;

import * as Core from '../core';

export default abstract class ChatService {
    public static async cancelModeratorRequest(command: { threadId: string }): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`chat`, `threads/${command.threadId}/cancelModeratorRequest`);
        return await Core.API.patch(route, command);
    }

    public static async createThread(command: { content: string; userIds: string[] }): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`chat`, `threads`);
        await Core.API.post(route, command);
    }

    public static async getAllChatUsers(): Promise<Core.Models.ChatThreadParticipant[]> {
        const route = Core.API.ServerRoute.forAction(`chat`, `users`);
        return await Core.API.get(route);
    }

    public static async getMatchThread(matchId: string): Promise<Core.Models.ChatThread> {
        const route = Core.API.ServerRoute.forAction(`chat`, `threads/match/${matchId}`);
        return await Core.API.get(route);
    }

    public static async createMatchThread(command: { matchId: string }): Promise<Core.Models.ChatThreadMessage> {
        const route = Core.API.ServerRoute.forAction(`chat`, `threads/match`);
        return await Core.API.post(route, command);
    }

    public static async createMatchThreadParticipant(command: { matchId: string }): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`chat`, `threads/match/${command.matchId}/participants`);
        return await Core.API.post(route, command);
    }

    public static async createThreadParticipant(command: { threadId: string; userId: string }): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`chat`, `threads/${command.threadId}/participants`);
        return await Core.API.post(route, command);
    }

    public static async getThreadMessages(threadId: string): Promise<Core.Models.ChatThreadMessage[]> {
        const route = Core.API.ServerRoute.forAction(`chat`, `threads/${threadId}/messages`);
        return await Core.API.get(route);
    }

    public static async getThreads(): Promise<Core.Models.ChatThread[]> {
        const route = Core.API.ServerRoute.forAction(`chat`, `threads`);
        return await Core.API.get(route);
    }

    public static async getIncremental(
        incrementalOptions: Core.Models.IncrementalOptions
    ): Promise<Core.Models.IncrementalResult<Core.Models.ChatThread>> {
        const route = Core.API.ServerRoute.forAction('chat', 'threads');
        return Core.API.get(route, Core.API.ApiVersion.v2, incrementalOptions);
    }

    public static async getUnreadThreads(): Promise<Core.Models.UnreadThread[]> {
        const route = Core.API.ServerRoute.forAction(`chat`, `threads/unread`);
        return await Core.API.get(route);
    }

    public static async removeThreadParticipant(threadId: string, userId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`chat`, `threads/${threadId}/participants/${userId}`);
        return await Core.API.delete(route);
    }

    public static async requestModerator(command: { threadId: string }): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`chat`, `threads/${command.threadId}/requestModerator`);
        return await Core.API.patch(route, command);
    }

    public static async sendMessage(command: { content: string; threadId: string }): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`chat`, `threads/${command.threadId}/messages`);
        await Core.API.post(route, command);
    }

    public static async setMessageAsRead(messageId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`chat`, `messages/${messageId}/setAsRead`);
        await Core.API.patch(route, { messageId });
    }

    public static async updateMessage(command: { messageId: string; redacted: boolean }): Promise<void> {
        const route = Core.API.ServerRoute.forAction(`chat`, `messages/${command.messageId}`);
        await Core.API.patch(route, command);
    }
}

import { useEffect, useRef } from 'react';

// Auto-reject promise if they're in progress when component dismounts.
const useAbortablePromise = <TResult>(promise: () => Promise<TResult>) => {
    const abortControllerRef = useRef<AbortController | null>(null);
    useEffect(() => {
        if (!abortControllerRef.current) abortControllerRef.current = new AbortController();
    }, []);

    useEffect(() => {
        // Does nothing
        const abort = () => {};
        const controller = abortControllerRef?.current;
        if (!!controller) controller.signal.addEventListener('abort', abort);

        promise().then(
            () => controller?.signal.removeEventListener('abort', abort),
            () => controller?.signal.removeEventListener('abort', abort)
        );

        return () => {
            if (!!controller) {
                controller.abort();
                controller.signal.removeEventListener('abort', abort);

                abortControllerRef.current = null;
            }
        };
    }, [promise]);
};

export default useAbortablePromise;

import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import NotifierImageOne from '../../assets/images/notifier-image-one.png';
import NotifierImageThree from '../../assets/images/notifier-image-three.png';
import NotifierImageTwo from '../../assets/images/notifier-image-two.png';
import { Button } from '../../components/button';
import { useLeague, useProfile } from '../../hooks/store';
import { AuthenticationService } from '../../services/authenticationService';
import DiscordService from '../../services/discordService';
import { UserService } from '../../services/userService';
import ConfirmModal from '../confirmModal';

import './index.scss';

const DismissibleNotifier = (): JSX.Element => {
    const [discordInfo, setDiscordInfo] = useState<{
        authorizeUrl: string | undefined;
        discordStatus: Core.Models.DiscordStatus;
    }>({
        authorizeUrl: undefined,
        discordStatus: Core.Models.DiscordStatus.Linked,
    });
    const [isConfirmingDismissingNotifier, setIsConfirmingDismissingNotifier] = useState<boolean>(false);

    const league = useLeague();
    const profile = useProfile();

    useEffect(() => {
        (async function () {
            try {
                // only run if profile is loaded and user is not under 13
                if (!profile || !!profile?.isUnder13) return;

                // only run if last dismissed notifier is after the last notifier was created
                if (Date.parse(profile.lastDismissedNotifierUtc || '') > Date.UTC(2023, 0, 9)) return;

                // only run if the League has a Discord
                if (!league || !league.discordUrl) return;

                // only run if there is a user ID
                const userId = AuthenticationService.getUserId();
                if (!userId) return;

                // only run if the user's discord is not linked
                const response = await UserService.getMyDiscordStatus();
                if (response.status === Core.Models.DiscordStatus.Linked) return;

                const authUrl = DiscordService.generateFullAuthorizeUrl(response.authorizeUrl, userId);

                setDiscordInfo({
                    authorizeUrl: authUrl,
                    discordStatus: response.status,
                });
            } catch {} // ignore errors
        })();
    }, [league, profile]);

    const dismissNotifier = useCallback(async () => {
        const userId = AuthenticationService.getUserId();
        if (!userId) return;

        try {
            await UserService.dismissNotifier({
                userId,
            });
            setIsConfirmingDismissingNotifier(false);
        } catch (err) {
            toast.error(`Unable to dismiss notifier`);
        }
    }, [setIsConfirmingDismissingNotifier]);

    const renderDiscordCtaCopy = (): JSX.Element => {
        switch (discordInfo.discordStatus) {
            case Core.Models.DiscordStatus.NotLinked:
                return <span className="dismissible-notifier__content__label">This league is using Discord</span>;
            case Core.Models.DiscordStatus.RequiresRelink:
                return (
                    <span className="dismissible-notifier__content__label">We need additional Discord permissions</span>
                );
            case Core.Models.DiscordStatus.Expired:
                return <span className="dismissible-notifier__content__label">Your Discord session has expired</span>;
            default:
                return <></>;
        }
    };

    if (discordInfo.discordStatus === Core.Models.DiscordStatus.Linked) return <></>;

    return (
        <>
            <div className="dismissible-notifier">
                <h2>Become a verified member on Discord</h2>
                <div className="dismissible-notifier__content">
                    <div className="dismissible-notifier__content__img_grid">
                        <span className="dismissible-notifier__content__label">Unlock server</span>
                        <img src={NotifierImageOne} alt="Notifier Example" />
                    </div>
                    <div className="dismissible-notifier__content__img_grid">
                        <span className="dismissible-notifier__content__label">Access alerts</span>
                        <img src={NotifierImageTwo} alt="Notifier Example" />
                    </div>
                    <div className="dismissible-notifier__content__img_grid">
                        <span className="dismissible-notifier__content__label">Stay ready for play</span>
                        <img src={NotifierImageThree} alt="Notifier Example" />
                    </div>
                </div>
                <div className="dismissible-notifier__cta">
                    <FontAwesomeIcon icon={['fab', 'discord']} size="2x" className="mb2x" />
                    {renderDiscordCtaCopy()}
                </div>
                <div className="dismissible-notifier__cta">
                    <a
                        className="link-button link-button--primary"
                        href={discordInfo.authorizeUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Link Account
                    </a>
                </div>

                <div className="dismissible-notifier__dismiss_cta">
                    <Button
                        onClick={() => setIsConfirmingDismissingNotifier(true)}
                        styleType={Core.Models.StyleType.TextOnly}
                        wide
                        round
                    >
                        Dismiss
                    </Button>
                </div>

                {isConfirmingDismissingNotifier && (
                    <ConfirmModal
                        onCancel={() => setIsConfirmingDismissingNotifier(false)}
                        onConfirm={dismissNotifier}
                        title="Are you sure you want to dismiss this notifier?"
                    >
                        <p>Once you have dismissed this notifier, you will not be able to see it again.</p>
                    </ConfirmModal>
                )}
            </div>
        </>
    );
};

export default DismissibleNotifier;

import { toast } from 'react-toastify';

export const copyToClipboard = (value: string | undefined) => {
    if (!value) {
        toast.error('Nothing to copy');
        return;
    }

    navigator.clipboard.writeText(value);
    toast.success('Copied to clipboard');
};

import React from 'react';
import { Link } from 'react-router-dom';

interface ForgotPasswordLinkProps {
    linkText?: string;
}

export const ForgotPasswordLink = (props: ForgotPasswordLinkProps) => {
    return (
        <div className="forgot-password-link">
            <Link to="/forgotPassword">{props.linkText || 'Forgot your password?'}</Link>
        </div>
    );
};

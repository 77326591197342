import React, { useCallback, useMemo, useState } from 'react';

import * as Core from '../../core';
import SeasonRosterExport from './seasonRosterExport';
import { HollowButton } from '../../components/buttons-visuals';
import InfoMessage from '../../components/infoMessage';
import LiteLimitationMessage from '../../components/liteLimitationMessage';
import { ToolTip } from '../../components/overlays';
import { useModal } from '../../hooks/modal';
import { useAlternateSeasonName, useIsLite } from '../../hooks/store';
import { ExportService } from '../../services/exportService';

enum ExportType {
    LeagueRoster = 1,
    SeasonRoster = 2,
    Teams = 3,
}

const ExportData = (): JSX.Element => {
    const [error, setError] = useState<string | undefined>(undefined);
    const [isProcessing, setIsProcessing] = useState<ExportType | undefined>(undefined);
    const seasonAlternateName = useAlternateSeasonName();
    const isLite = useIsLite();

    const download = useCallback(async (getter: Promise<Core.Models.File>, type: ExportType): Promise<void> => {
        setError(undefined);
        setIsProcessing(type);

        try {
            const file = await getter;
            ExportService.downloadBlob(file.blob, file.filename || 'export.xlsx');
        } catch (e) {
            const message = Core.API.getErrorMessage(e);
            setError(message);
        } finally {
            setIsProcessing(undefined);
        }
    }, []);

    const downloadLeagueRoster = useCallback(
        async (): Promise<void> => download(ExportService.roster(), ExportType.LeagueRoster),
        [download]
    );
    const downloadSeasonRoster = useCallback(
        async (seasonId: string): Promise<void> =>
            download(ExportService.seasonRoster(seasonId), ExportType.SeasonRoster),
        [download]
    );
    const downloadTeams = useCallback(
        async (): Promise<void> => download(ExportService.teams(), ExportType.Teams),
        [download]
    );

    const [seasonRosterNode, openSeasonRosterModal, closeSeasonRosterModal] = useModal(
        () => `Download ${seasonAlternateName} Roster Export`,
        () => (
            <SeasonRosterExport
                onSubmit={async (seasonId: string) => {
                    await downloadSeasonRoster(seasonId);
                    closeSeasonRosterModal();
                }}
                seasonAlternateName={seasonAlternateName}
            />
        )
    );

    const buttons = useMemo(
        () => [
            { label: 'Download League Roster', onClick: downloadLeagueRoster, type: ExportType.LeagueRoster },
            {
                label: `Download ${seasonAlternateName} Roster`,
                onClick: openSeasonRosterModal,
                type: ExportType.SeasonRoster,
            },
            { label: 'Download Teams', onClick: downloadTeams, type: ExportType.Teams },
        ],
        [downloadLeagueRoster, downloadTeams, openSeasonRosterModal, seasonAlternateName]
    );

    return (
        <section className="settings-page__export-data global-container-centered text-center">
            {isLite && (
                <LiteLimitationMessage
                    className="fit-content m-auto mb2x"
                    message={`${Core.Constants.PLANS.LITE.NAME} instances do not have access to this feature.`}
                />
            )}
            <p>
                Note: this feature allows you to download potentially sensitive information. You are responsible for the
                file and its data once it has been downloaded to your device.
            </p>

            <div className="disp-flex flex-dir-column align-center">
                {buttons.map(({ label, onClick, type }) => (
                    <ToolTip
                        hide={!isLite}
                        key={label}
                        trigger={
                            <HollowButton
                                as="button"
                                className="settings-page__export-data__button mb2x text-large"
                                color="secondary"
                                disabled={isLite}
                                pending={isProcessing === type}
                                onClick={onClick}
                                size="small"
                            >
                                {label}
                            </HollowButton>
                        }
                    >
                        <span>This action is not available in {Core.Constants.PLANS.LITE.NAME}.</span>
                    </ToolTip>
                ))}
            </div>

            {error && <InfoMessage message={error} type="error" />}
            {seasonRosterNode}
        </section>
    );
};

export default ExportData;

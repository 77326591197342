import * as React from 'react';
import * as Yup from 'yup';

import * as Core from '../../core';
import FormField from '.';
import { useLeague } from '../../hooks/store';

export const buildTermsFieldsValidationSchema = (league?: Core.Models.League) => ({
    agreedToLicenseAgreementAtRegistration: Yup.boolean().oneOf(
        [true],
        'Your consent to the Terms of Service is required.'
    ),
    ...(!!league?.legalUrl && {
        agreedToLegalTerms: Yup.boolean().oneOf([true], `Your consent is needed for the league's legal terms.`),
    }),
});

const TermsFormFields: React.FunctionComponent = () => {
    const league = useLeague();

    return (
        <>
            <FormField
                type="checkbox"
                name="agreedToLicenseAgreementAtRegistration"
                description="I consent to the Terms of Service"
                label={
                    <>
                        I consent to the{' '}
                        <a href={Core.Constants.TERMS_OF_SERVICE_URL} target="_blank" rel="noopener noreferrer">
                            Terms of Service
                        </a>
                        .
                    </>
                }
            />
            {!!league?.legalUrl && (
                <FormField
                    type="checkbox"
                    name="agreedToLegalTerms"
                    description="Legal terms"
                    label={
                        <>
                            I consent to the{' '}
                            <a href={league.legalUrl} target="_blank" rel="noopener noreferrer">
                                {league.name} legal terms
                            </a>
                            .
                        </>
                    }
                />
            )}
        </>
    );
};
export default TermsFormFields;

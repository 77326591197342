export const renderMemberName = (member: {
	firstName?: string;
	lastName?: string;
	pronouns?: string;
}): string | undefined => {
	if (!member.firstName) return undefined;
	const fullName = `${member.firstName} ${member.lastName}`.trim();
	const addlPronouns = !!member.pronouns ? ` (${member.pronouns})` : '';
	return fullName + addlPronouns;
};

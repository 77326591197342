import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orderBy } from 'lodash';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import EditAnnouncement from './editAnnouncement';
import { HollowButton, TertiaryButton, TextLink } from '../../components/buttons-visuals';
import ConfirmModal from '../../components/confirmModal';
import { ContentContainer } from '../../components/containers';
import Modal from '../../components/modal';
import { useTimezone } from '../../hooks/store';
import { StageService } from '../../services/stageService';

interface AnnouncementsProps {
    announcements: Core.Models.StageAnnouncement[];
    canEditSeason: boolean;
    reloadStage: () => Promise<void>;
}

const Announcements = ({ announcements, canEditSeason, reloadStage }: AnnouncementsProps): JSX.Element => {
    const timezone = useTimezone();

    const [announcementToDelete, setAnnouncementToDelete] = useState<string | undefined>(undefined);
    const [announcementToEdit, setAnnouncementToEdit] = useState<Core.Models.StageAnnouncement | undefined>(undefined);
    const [showAnnouncementsCount, setShowAnnouncementsCount] = useState<number>(1);

    const sortedAnnouncements = useMemo(
        () => orderBy(announcements, (a: Core.Models.StageAnnouncement) => a.createdAtUtc, 'desc'),
        [announcements]
    );

    if (sortedAnnouncements.length <= 0) return <></>;
    return (
        <ContentContainer className="mb4x" shade={Core.Models.Shades.Dark40} radius={Core.Models.RadiusSizes.Large}>
            <div className="p2x">
                <h3>
                    <FontAwesomeIcon icon={['fas', 'bullhorn']} className="mr" size="sm" /> Announcements
                </h3>

                <div>
                    {sortedAnnouncements
                        .filter((_, index) => index < showAnnouncementsCount)
                        .map((a: Core.Models.StageAnnouncement) => (
                            <div key={a.id} className="announcement">
                                <div className="color-gray text-xsmall">
                                    {moment.tz(a.createdAtUtc, timezone).format(Core.Time.getFormat(true))}
                                </div>
                                <div className="announcement__body">{a.message}</div>
                                <TextLink className="announcement__sender" to={`/users/${a.sender.id}`}>
                                    {Core.Identity.renderMemberName({
                                        firstName: a.sender.firstName,
                                        lastName: a.sender.lastName,
                                        pronouns: a.sender.pronouns,
                                    })}
                                </TextLink>
                                {canEditSeason && (
                                    <div className="mt2x">
                                        <HollowButton
                                            as="button"
                                            color="secondary"
                                            onClick={() => setAnnouncementToEdit(a)}
                                            size="small"
                                        >
                                            Edit
                                        </HollowButton>
                                        <HollowButton
                                            as="button"
                                            className="ml"
                                            color="destructive"
                                            onClick={() => setAnnouncementToDelete(a.id)}
                                            size="small"
                                        >
                                            Delete
                                        </HollowButton>
                                    </div>
                                )}
                            </div>
                        ))}
                </div>

                {sortedAnnouncements.length > showAnnouncementsCount && (
                    <TertiaryButton
                        as="button"
                        className="mt3x"
                        onClick={() => setShowAnnouncementsCount(showAnnouncementsCount + 2)}
                    >
                        Show more <FontAwesomeIcon icon={['fas', 'chevron-down']} className="ml" />
                    </TertiaryButton>
                )}

                {announcementToEdit && (
                    <Modal onClose={() => setAnnouncementToEdit(undefined)} title="Edit announcement">
                        <EditAnnouncement
                            announcement={announcementToEdit}
                            onSubmit={async (values: Core.Models.EditStageAnnouncementRequest) => {
                                try {
                                    await StageService.editAnnouncement(values);
                                    toast.success('Edited announcement');
                                    await reloadStage();
                                    setAnnouncementToEdit(undefined);
                                } catch (error) {
                                    toast.error(error);
                                }
                            }}
                        />
                    </Modal>
                )}

                {announcementToDelete && (
                    <ConfirmModal
                        confirmText="Yes"
                        onCancel={() => setAnnouncementToDelete(undefined)}
                        onConfirm={async () => {
                            try {
                                await StageService.deleteAnnouncement(announcementToDelete);
                                toast.success('Deleted announcement');
                                await reloadStage();
                                setAnnouncementToDelete(undefined);
                            } catch (error) {
                                toast.error(error);
                            }
                        }}
                        title="Are you sure"
                    >
                        <p>Are you sure you want to delete this announcement?</p>
                    </ConfirmModal>
                )}
            </div>
        </ContentContainer>
    );
};

export default Announcements;

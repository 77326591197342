import React, { useCallback, useEffect, useState } from 'react';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../core';
import { Button } from '../../components/button';
import FormField from '../../components/formField';
import Loading from '../../components/loading';
import { useLeagueConfiguration, useOrganizationTerm } from '../../hooks/store';
import { OrganizationService } from '../../services/organizationService';
import ConfirmModal from '../confirmModal';
import InfoMessage from '../infoMessage';
import { CopyTextField } from '../inputs';
import withLoading, { WithLoadingProps } from '../withLoading';

import './index.scss';

interface InvitePlayersProps extends WithLoadingProps {
    onComplete: () => Promise<void>;
    organizationId: string;
}

export interface InvitePlayersFormValues {
    delimitedEmails: string;
}

const InvitePlayers = (props: InvitePlayersProps) => {
    const { onComplete, organizationId, setError, setIsLoading } = props;
    const [joinUrl, setJoinUrl] = useState<string | undefined>(undefined);
    const [isRegenerating, setIsRegenerating] = useState<boolean>(false);
    const leagueConfiguration = useLeagueConfiguration();
    const organizationTerm = useOrganizationTerm({ lowercase: true });

    const formatJoinOrganizationUrl = useCallback(
        (joinOrganizationCode: string | undefined) => {
            if (!joinOrganizationCode || !leagueConfiguration) return undefined;
            return `${leagueConfiguration.domain}/organizations/join/${joinOrganizationCode}`;
        },
        [leagueConfiguration]
    );

    const load = useCallback(async () => {
        try {
            const response = await OrganizationService.getJoinOrganizationCode(organizationId);
            setJoinUrl(formatJoinOrganizationUrl(response));
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, [formatJoinOrganizationUrl, organizationId, setError, setIsLoading]);

    useEffect(() => {
        (async () => {
            await load();
        })();
    }, [load]);

    const getAddresses = (addresses: string) => {
        return addresses.split(/,| |;|:|\n|\r/).filter((address) => !!address);
    };

    const handleSubmit = async (values: InvitePlayersFormValues, actions: FormikActions<InvitePlayersFormValues>) => {
        try {
            await OrganizationService.inviteUsers({
                organizationId: organizationId,
                roleId: Core.Models.OrganizationRoleId.Member,
                users: getAddresses(values.delimitedEmails).map((email: string) => ({ email })),
            });
            await onComplete();
        } catch (error) {
            const message = Core.API.getErrorMessage(error);
            actions.setFieldError('delimitedEmails', message ? message : '');
            actions.setSubmitting(false);
        }
    };

    const valueValidation = async (values: InvitePlayersFormValues) => {
        const CheckEmail = Yup.object().shape({
            emails: Yup.array().of(Yup.string().email()),
        });
        const validEmails = await CheckEmail.isValid({ emails: getAddresses(values.delimitedEmails) });
        if (!validEmails) {
            const error = { delimitedEmails: 'One or more of the emails you entered is not valid' };
            throw error;
        }
    };

    const createJoinOrganizationCode = async () => {
        const generatedJoinCode = await OrganizationService.createJoinOrganizationCode({
            organizationId: organizationId,
        });
        setJoinUrl(formatJoinOrganizationUrl(generatedJoinCode));
        toast.success(`Generated new join URL for this ${organizationTerm}`);
    };

    return (
        <div className="invite-players">
            <p className="invite-players__info">Share this invite link:</p>
            {joinUrl ? (
                <>
                    <fieldset className="form-group">
                        <CopyTextField id="joinUrl" label="Join URL" value={joinUrl} />
                    </fieldset>
                    <Button className="mb2x" onClick={() => setIsRegenerating(true)} outline round wide>
                        Regenerate join link
                    </Button>
                </>
            ) : (
                <Button className="mb2x" onClick={createJoinOrganizationCode} round wide>
                    Create join link
                </Button>
            )}
            <p className="invite-players__info">or, enter multiple email addresses separated by commas:</p>
            <Formik
                initialValues={{
                    delimitedEmails: '',
                }}
                onSubmit={handleSubmit}
                validate={(values: InvitePlayersFormValues) => valueValidation(values)}
                render={(formProps: FormikProps<InvitePlayersFormValues>) => (
                    <Form>
                        <fieldset className="form-group">
                            <FormField
                                type="email"
                                name="delimitedEmails"
                                description="Player Emails"
                                component="textarea"
                                placeholder="Player email addresses"
                                className="invite-players__text-input"
                            />
                        </fieldset>

                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                        <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                        <fieldset className="form-group form-group--undecorated">
                            {formProps.isSubmitting && <Loading buttonLoader />}
                            <Button onClick={formProps.submitForm} disabled={formProps.isSubmitting} wide round>
                                Send Invites
                            </Button>
                        </fieldset>
                    </Form>
                )}
            />
            {isRegenerating && (
                <ConfirmModal
                    confirmText="Yes"
                    onCancel={() => setIsRegenerating(false)}
                    onConfirm={async () => {
                        await createJoinOrganizationCode();
                        setIsRegenerating(false);
                    }}
                    title="Are you sure"
                >
                    <p>Are you sure you want to regenerate this join link? The current link will stop working.</p>
                </ConfirmModal>
            )}
        </div>
    );
};

export default withLoading(InvitePlayers, {
    loadingProps: { blockItem: true },
});

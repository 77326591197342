import React from 'react';

import Chat from './chat';
import CurrentUser from './currentUser';
import DiscordLink from './discordLink';
import Hamburger from './hamburger';
import HeaderLogo from './headerLogo';
import Notifications from './notifications';
import Signup from './signup';
import { useIsPlatform } from '../../hooks/store';
import { Header as NewHeader, HeaderLeft, HeaderRight } from '../navigationComponents';
import SiteSearch from '../siteSearch';

import './index.scss';

const Header = (): JSX.Element => {
    const isPlatform = useIsPlatform();

    return (
        <NewHeader id="header">
            <HeaderLeft>
                <Hamburger />
                <HeaderLogo />
            </HeaderLeft>
            <HeaderRight>
                {!isPlatform && <SiteSearch />}
                <DiscordLink />
                <Chat />
                <Notifications />
                <Signup text="Sign Up" />
                <CurrentUser />
            </HeaderRight>
        </NewHeader>
    );
};

export default Header;

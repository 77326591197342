import decode from 'jwt-decode';

import * as Core from './../core';
import { DecodedToken } from '@leaguespot/platform-js/src/models';
import { CacheService } from './cacheService';

export abstract class AuthenticationService {
    // these min/max values are to help us deal with the client's clock being off and hence not trustworthy when comparing to nbf/exp from the token
    private static readonly tokenTimeoutMinimumMinutes: number = 1;
    private static readonly tokenRefreshMinimumMinutes: number = 1;
    private static readonly tokenRefreshSafetyMarginMinutes: number = 1; // number of minutes before expiration to do the refresh
    private static readonly tokenRefreshMaximumMinutes: number = 60; // try to refresh at least once an hour
    private static readonly originalTokenTimeoutMinutes: number = 60;
    private static authWindow: HTMLIFrameElement | null = null;
    private static refreshInterval: NodeJS.Timeout;

    public static setAuthWindow(ref: HTMLIFrameElement): void {
        this.authWindow = ref;
    }

    public static isAuthenticated(): boolean {
        const token = AuthenticationService.getToken();
        return (token || Core.Constants.EMPTY_STRING).length > 0;
    }

    public static getToken(): string | null {
        const token = CacheService.get<string>(Core.Constants.CACHE_TOKEN);
        return token;
    }

    public static decodeToken(token: string): DecodedToken | undefined {
        if (!token) {
            return undefined;
        }
        try {
            return decode(token) as DecodedToken;
        } catch {}

        return undefined;
    }

    public static getDecodedToken(): DecodedToken | undefined {
        const token = AuthenticationService.getToken();
        if (!token) {
            return undefined;
        }

        return AuthenticationService.decodeToken(token);
    }

    public static getUserId(): string | undefined {
        return (AuthenticationService.getDecodedToken() || { sub: undefined }).sub;
    }

    public static setToken(token: string, sendToAuthWindow: boolean = true): void {
        const decoded = AuthenticationService.decodeToken(token);

        // set the token locally
        const storedItem = CacheService.set(
            Core.Constants.CACHE_TOKEN,
            token,
            AuthenticationService.getTokenTimeoutMinutes(decoded)
        );

        // send to iframe unless directed otherwise
        if (sendToAuthWindow)
            this.authWindow?.contentWindow?.postMessage?.(
                { type: 'auth', command: 'setJwt', message: storedItem },
                Core.Configuration.platformUrl
            );

        // clear the refresh
        if (AuthenticationService.refreshInterval) {
            clearInterval(AuthenticationService.refreshInterval);
        }

        // start the timer again
        this.setRefreshInterval(decoded);
    }

    public static clearToken(): void {
        // set the token
        CacheService.remove(Core.Constants.CACHE_TOKEN);

        // clear shared auth token
        this.authWindow?.contentWindow?.postMessage?.(
            { type: 'auth', command: 'clearJwt' },
            Core.Configuration.platformUrl
        );

        // clear the refresh
        if (AuthenticationService.refreshInterval) {
            clearInterval(AuthenticationService.refreshInterval);
        }
    }

    private static getTokenRemainingMinutes(token?: DecodedToken): number {
        if (!token) {
            return 0;
        }
        return (token.exp - Math.max(token.nbf, new Date().getTime() / 1000)) / 60;
    }

    private static getTokenTimeoutMinutes(token?: DecodedToken): number {
        return Math.max(
            AuthenticationService.getTokenRemainingMinutes(token),
            AuthenticationService.tokenTimeoutMinimumMinutes
        );
    }

    private static getTokenRefreshMinutes(token?: DecodedToken): number {
        return Math.max(
            Math.min(
                AuthenticationService.getTokenRemainingMinutes(token) -
                    AuthenticationService.tokenRefreshSafetyMarginMinutes, // refresh a bit before it expires
                AuthenticationService.tokenRefreshMaximumMinutes
            ),
            AuthenticationService.tokenRefreshMinimumMinutes
        );
    }

    private static setRefreshInterval(decodedToken?: DecodedToken): void {
        if (Core.Configuration.disableAutomaticTokenRefresh) {
            return;
        }
        const interval = AuthenticationService.getTokenRefreshMinutes(decodedToken) * 60 * 1000;
        AuthenticationService.refreshInterval = setInterval(async () => {
            if (!decodedToken?.expires_in) return;

            const route = Core.API.ServerRoute.forAction('users', 'token');
            const token: string = await Core.API.post<string>(route, {
                expirationHours: decodedToken.expires_in / 3600,
            } as Core.Models.RefreshTokenCommand);

            // if we don't get a token back (but we got here, which means we didn't get a 401/403 error),
            //   we must be using a non-renewable token - we'll just use it until it starts giving us errors
            if (token) {
                // store token
                AuthenticationService.setToken(token);
            }
        }, interval);
    }

    public static stashToken() {
        const token = CacheService.get<string>(Core.Constants.CACHE_TOKEN);
        CacheService.set(Core.Constants.CACHE_TOKEN_ORIGINAL, token, AuthenticationService.originalTokenTimeoutMinutes);
        AuthenticationService.clearToken();
    }

    public static restoreToken() {
        const token = CacheService.get<string>(Core.Constants.CACHE_TOKEN_ORIGINAL);
        if (token) {
            AuthenticationService.setToken(token);
        } else {
            AuthenticationService.clearToken();
        }
        CacheService.remove(Core.Constants.CACHE_TOKEN_ORIGINAL);
        return token;
    }
}

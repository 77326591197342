import * as React from 'react';

import * as Core from '../../core';
import ErrorLoadingWrapper from '.';
import NotFoundPage from '../../pages/notFound';
import { ErrorDisplayPageProps } from '../error/errorDisplayPage';
import { LoadingProps } from '../loading';

export interface ErrorLoadingWrapperProps {
    isLoading: boolean;
    loadingError?: Core.Models.AppError | string;
}

export interface ErrorLoadingWrapperSettings<TIncomingProps> {
    loadingOptions?: LoadingProps;
    showNotFoundFor404?: boolean;
    hasRequiredProps?: (props: TIncomingProps) => boolean;
    showMessage?: (message: string, error: Core.Models.AppError) => boolean | string | undefined;
    errorDisplayPageOptions?: ErrorDisplayPageProps;
}

/**
 * @deprecated Use `withLoading` instead
 * Wraps a given component in a @see ErrorLoadingWrapper with the isLoading and loadingError component props (and the result of hasRequiredProps) used to determine the loading/error states.
 * @param WrappedComponent The component to render if this is not loading or errored
 * @param hasRequiredProps Should return true if all the properties required to render the component are populated (useful when a parent object may momentarily render this component with isLoading: false and null/undefined objects, like on initial render, before componentDidMount runs to start data loads)
 */
export default function errorLoadingWrapperHOC<
    TComponentProps,
    TIncomingProps extends TComponentProps = TComponentProps,
>(WrappedComponent: React.ComponentType<TComponentProps>, settings?: ErrorLoadingWrapperSettings<TIncomingProps>) {
    return class ErrorHandlerHOC extends React.Component<TIncomingProps & ErrorLoadingWrapperProps> {
        public render() {
            const { isLoading, loadingError, ...rest } = this.props;
            const error = loadingError as Core.Models.AppError;
            const status = Core.API.getStatus(error);
            if (status === 404 && settings && settings.showNotFoundFor404) {
                return <NotFoundPage />;
            }
            const message = error && Core.API.getErrorMessage(error);
            const showMessageResult =
                message && settings && settings.showMessage && settings.showMessage(message, error);
            const messageToShow =
                showMessageResult === false ? undefined : showMessageResult === true ? message : showMessageResult;
            if (process.env.NODE_ENV === 'development') {
                if (error && !messageToShow) {
                    console.warn('Not showing message', {
                        message,
                        showMessageResult,
                        error,
                    });
                }
            }
            return (
                <ErrorLoadingWrapper
                    isLoading={
                        isLoading ||
                        (settings &&
                            settings.hasRequiredProps &&
                            !settings.hasRequiredProps(rest as unknown as TIncomingProps))
                    }
                    isErrored={!!loadingError}
                    errorMessage={messageToShow}
                    loadingOptions={settings && settings.loadingOptions}
                    errorDisplayPageOptions={settings && settings.errorDisplayPageOptions}
                    render={() => <WrappedComponent {...(rest as unknown as any)} />}
                />
            );
        }
    };
}

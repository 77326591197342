import React, { HTMLAttributes, useRef, useEffect, useState } from 'react';
import classNames from 'classnames';

import { setOverflowPosition } from '../../setOverflowPosition';

import './ToolTip.scss';

interface ToolTipProps extends HTMLAttributes<HTMLDivElement> {
    decorationStyle?: 'warning' | 'info' | 'error';
    children?: React.ReactElement<HTMLElement> | string;
    hide?: boolean;
    trigger: React.ReactElement;
}

const TRANSITION_DURATION = 150; // Keep in sync with CSS

const ToolTip = ({ className, children, decorationStyle, hide, trigger, ...rest }: ToolTipProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isPositioned, setIsPositioned] = useState<boolean>(false); // prevent the ToolTip from showing before it's positioned
    const [placementH, setPlacementH] = useState<string>('center');
    const [placementV, setPlacementV] = useState<string>('top');
    const bubbleRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLDivElement>(null);
    const toolTipClasses = classNames(className, 'ds-tooltip');
    const bubbleClasses = classNames(
        'ds-tooltip__bubble',
        `ds-tooltip__bubble--${placementV}`,
        `ds-tooltip__bubble--${placementH}`,
        {
            'ds-tooltip__bubble--open': isOpen && isPositioned,
        }
    );

    const handleOpen = () => {
        if (!isOpen && !isPositioned) {
            setIsOpen(true);
        }
    };
    const handleClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        if (isOpen) {
            if (bubbleRef.current) {
                const hPos = setOverflowPosition(bubbleRef, 'top')?.hPlacement;
                const vPos = setOverflowPosition(bubbleRef, 'top')?.vPlacement === 'top' ? 'top' : 'bottom';
                setPlacementH((p) => (hPos ? hPos : p));
                setPlacementV(vPos ? vPos : 'top');
                setIsPositioned(true);
            }
        } else {
            // Using a setTimeout ensures we always correctly reset the ToolTip's positioning.
            timer = setTimeout(() => {
                setPlacementH('center');
                setPlacementV('top');
                setIsPositioned(false);
            }, TRANSITION_DURATION);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [isOpen]);

    if (hide) return trigger;

    return (
        <div
            className={toolTipClasses}
            onFocus={handleOpen}
            onBlur={handleClose}
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
            onTouchStart={handleOpen}
            ref={triggerRef}
            {...rest}
        >
            {React.cloneElement(trigger as React.ReactElement, {
                ...trigger.props,
                className: classNames(
                    trigger.props.className,
                    'ds-tooltip__trigger',
                    `ds-tooltip__trigger--${decorationStyle}`
                ),
            })}
            <div className={bubbleClasses} ref={bubbleRef} role="status">
                {(isOpen || isPositioned) && (
                    <div
                        className="ds-tooltip__bubble-content"
                        {...(isPositioned && !isOpen ? { 'aria-hidden': 'true' } : {})}
                    >
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ToolTip;

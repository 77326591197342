import React, { CSSProperties } from 'react';
import classNames from 'classnames';

import './counter.scss';

interface CounterProps {
    content: number | string;
    critical?: boolean;
    style?: CSSProperties | undefined;
}

const Counter = ({ content, critical, style }: CounterProps): JSX.Element => (
    <span className={classNames('counter', critical && 'counter--critical')} style={style}>
        {content}
    </span>
);

export default Counter;

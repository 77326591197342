import React, { useMemo } from 'react';
import { orderBy } from 'lodash';

import * as Core from '../../core';
import CreateRound from './createRound';
import PreScheduledDescription from './preScheduledDescription';
import Round from './round';
import ErrorDisplayPage from '../../components/error/errorDisplayPage';
import Select from '../../components/inputs/select/Select';
import { useModal } from '../../hooks/modal';
import { useCanEditSeason } from '../../hooks/store';
import { RoundService } from '../../services/roundService';

import './rounds.scss';

export interface StageRoundsProps {
    locations: Core.Models.LeagueLocation[];
    reloadStage: () => Promise<void>;
    season: Core.Models.Season;
    stage: Core.Models.Stage;
}

const CREATE_ROUND_OPTION_VALUE = 'add_round';

const StageRounds: React.FunctionComponent<StageRoundsProps> = (props) => {
    const { locations, reloadStage, season, stage } = props;

    const matchWord = useMemo(() => Core.Competition.getMatchWord(stage.season?.game?.eventType), [stage]);

    const rounds = orderBy(stage.rounds, [(i) => i.sortOrder, (i) => i.id]);
    let [currentRoundIx, setCurrentRoundIx] = React.useState(
        Math.max(
            rounds.findIndex((i) => i.id === stage.currentRoundId),
            0
        )
    );
    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === CREATE_ROUND_OPTION_VALUE) {
            openCreateRound();
        } else {
            setCurrentRoundIx(parseInt(e.target.value));
        }
    };

    const newRoundNum = rounds.length > 0 ? rounds[rounds.length - 1].sortOrder + 1 : 1;
    const [createRoundNode, openCreateRound, closeCreateRound] = useModal(
        () => 'Create Round',
        () => {
            return (
                <CreateRound
                    allowRescheduleRequests={stage.settings.allowRescheduleRequests}
                    autoStartRounds={!!stage.settings.autoStartRounds}
                    hideBestOf={
                        season.game.featureSet === Core.Models.FeatureSet.Hearthstone ||
                        season.tennisStyle ||
                        stage.stageTypeId === Core.Models.StageTypeId.Leaderboard
                    }
                    initialValues={{ name: `Round ${newRoundNum}`, bestOf: stage.bestOf }}
                    matchWord={matchWord}
                    onSubmit={async (values) => {
                        await RoundService.create({
                            stageId: stage.id,
                            ...values,
                        });
                        await reloadStage();
                        closeCreateRound();
                    }}
                />
            );
        }
    );

    const canEditSeason = useCanEditSeason(season.id);
    const createRoundStageTypeIds = [
        Core.Models.StageTypeId.AutoMatcher,
        Core.Models.StageTypeId.DoubleRoundRobin,
        Core.Models.StageTypeId.RoundRobin,
        Core.Models.StageTypeId.Swiss,
    ];
    const canCreateRound =
        canEditSeason &&
        stage.currentState < Core.Models.StageState.IsComplete &&
        createRoundStageTypeIds.filter((id) => id === stage.stageTypeId).length > 0;

    if (rounds.length === 0) {
        return <PreScheduledDescription value={stage.settings.preScheduledDescription} />;
    }

    if (!rounds[currentRoundIx]) currentRoundIx = rounds.length - 1;

    const round = rounds[currentRoundIx];
    // Displays error if round is not found; this shouldn't be possible.
    if (!round) return <ErrorDisplayPage />;

    const incrementRound = () => setCurrentRoundIx(++currentRoundIx);

    const roundSelector = (
        <Select
            className="mb0"
            value={currentRoundIx}
            onChange={onChange}
            containerClassName="rounds__selector"
            label="Select round"
        >
            <option hidden disabled value="">
                Choose a round
            </option>
            {rounds.map((round: Core.Models.Round, ix: number) => (
                <option key={round.id} value={ix}>
                    {round.name}
                </option>
            ))}
            {canCreateRound && <option value={CREATE_ROUND_OPTION_VALUE}>+ Add round</option>}
        </Select>
    );

    return (
        <>
            <Round {...{ locations, season, stage, round, roundSelector, reloadStage, incrementRound }} />
            {createRoundNode}
        </>
    );
};

export default StageRounds;

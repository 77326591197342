import * as Core from '../core';
import { CreatePostValues } from '../components/socialFeed/createPost';

export abstract class PostService {
    public static async getPost(id: string): Promise<Core.Models.Post[]> {
        const route = Core.API.ServerRoute.forAction('posts', id);
        return await Core.API.get(route);
    }

    public static async getPosts(
        paginatedOptions: Core.Models.PaginatedOptions
    ): Promise<Core.Models.PaginatedResult<Core.Models.Post>> {
        const route = Core.API.ServerRoute.forAction('posts');
        return await Core.API.get(route, Core.API.ApiVersion.v1, paginatedOptions);
    }

    public static async createPost(command: CreatePostValues): Promise<Core.Models.Post> {
        const route = Core.API.ServerRoute.forAction('posts');
        return await Core.API.post(route, command);
    }

    public static async deletePost(id: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('posts', id);
        return await Core.API.delete(route);
    }

    public static async getComments(
        postId: string,
        paginatedOptions: Core.Models.PaginatedOptions
    ): Promise<Core.Models.PaginatedResult<Core.Models.Comment>> {
        const route = Core.API.ServerRoute.forAction('posts', `${postId}/comments`);
        return Core.API.get(route, Core.API.ApiVersion.v1, paginatedOptions);
    }

    public static async createComment(command: CreatePostValues): Promise<Core.Models.Comment> {
        const route = Core.API.ServerRoute.forAction('posts/comments');
        return await Core.API.post(route, command);
    }

    public static async deleteComment(id: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('posts/comments', id);
        return await Core.API.delete(route);
    }

    public static async updateReaction(command: Core.Models.UpdateReactionRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('posts', 'reactions');
        return await Core.API.post(route, command);
    }
}

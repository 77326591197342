import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import CreatePaymentMethod from './createPaymentMethod';
import PaymentGatewayService from '../../services/paymentGatewayService';
import { AddButton, HollowButton } from '../buttons-visuals';
import ConfirmModal from '../confirmModal';
import CreditCard from '../creditCard/creditCard';
import Modal from '../modal';
import withLoading, { WithLoadingProps } from '../withLoading';

interface ManagePaymentMethodsProps extends WithLoadingProps {}

const ManagePaymentMethods = ({ setError, setIsLoading }: ManagePaymentMethodsProps): JSX.Element => {
    const [isAddingPaymentMethod, setIsAddingPaymentMethod] = useState<boolean>(false);
    const [paymentMethods, setPaymentMethods] = useState<Core.Models.PaymentMethod[]>([]);
    const [paymentMethodToDelete, setPaymentMethodToDelete] = useState<Core.Models.PaymentMethod | undefined>(
        undefined
    );

    const load = useCallback(async () => {
        setIsLoading(true);

        try {
            const data = await PaymentGatewayService.getPaymentMethods(Core.Models.PaymentGateway.Stripe);
            setPaymentMethods(data);
        } catch (e) {
            const message = Core.API.getErrorMessage(e);
            setError(`There was an issue loading your existing payment methods: ${message}`);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        (async () => {
            await load();
        })();
    }, [load]);

    return (
        <>
            <h3 className="heading-2">Saved payment methods</h3>
            {paymentMethods.length > 0 ? (
                <ul>
                    {paymentMethods.map((pm: Core.Models.PaymentMethod) => (
                        <li
                            key={pm.paymentMethodId}
                            className="payment-method__item disp-flex align-center justify-between mb2x"
                        >
                            <CreditCard paymentMethod={pm} />
                            <HollowButton
                                as="button"
                                className="ml2x"
                                color="destructive"
                                onClick={() => setPaymentMethodToDelete(pm)}
                                size="small"
                            >
                                Delete
                            </HollowButton>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No payment methods</p>
            )}

            <AddButton
                as="button"
                buttonSize="medium"
                buttonStyle="solid"
                onClick={() => setIsAddingPaymentMethod(true)}
            >
                Add payment method
            </AddButton>

            {isAddingPaymentMethod && (
                <Modal onClose={() => setIsAddingPaymentMethod(false)} title="Add payment method">
                    <CreatePaymentMethod
                        forceSave
                        onPaymentMethodCreated={async () => {
                            setIsAddingPaymentMethod(false);
                            await load();
                        }}
                        paymentGatewayId={Core.Models.PaymentGateway.Stripe}
                    />
                </Modal>
            )}

            {!!paymentMethodToDelete && (
                <ConfirmModal
                    onCancel={() => setPaymentMethodToDelete(undefined)}
                    onConfirm={async () => {
                        try {
                            await PaymentGatewayService.deletePaymentMethod(paymentMethodToDelete.id);
                            await load();
                            setPaymentMethodToDelete(undefined);
                        } catch (e) {
                            const message = Core.API.getErrorMessage(e);
                            toast.error(`There was an issue deleting that payment method: ${message}`);
                        }
                    }}
                    title="Are you sure you want to delete this payment method?"
                >
                    <p>
                        You are deleting the card ending in <strong>{paymentMethodToDelete.last4}</strong>
                    </p>
                </ConfirmModal>
            )}
        </>
    );
};

export default withLoading(ManagePaymentMethods);

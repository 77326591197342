import React from 'react';

import * as Core from '../../core';
import logo from '../../assets/images/icon-leaguespot-footer.svg';

const PoweredByLeagueSpot = (): JSX.Element => (
    <a
        className="disp-flex flex-dir-row align-center mt3x mb3x"
        href={Core.Constants.WEBSITE_URL}
        rel="noopener noreferrer"
        target="_blank"
    >
        Powered By
        <img alt={`Link to ${Core.Constants.Company} website`} className="mr2x ml2x" src={logo} width="40px" />
        {Core.Constants.Company}
    </a>
);

export default PoweredByLeagueSpot;

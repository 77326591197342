import * as React from 'react';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import * as Yup from 'yup';

import * as Core from '../../core';
import { useLeague } from '../../hooks/store';
import { LeagueService } from '../../services/leagueService';
import { SolidButton } from '../buttons-visuals';
import ErrorMessage from '../errorMessage';
import FormField from '../formField';

interface InviteLeagueRoleProps {
    leagueRoleId: Core.Models.LeagueRoleId;
    onComplete: () => Promise<void>;
}

export interface InviteLeagueRoleFormValues {
    email: string;
}

const schema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be formatted like an email address'),
});

const InviteLeagueRole = (props: InviteLeagueRoleProps) => {
    const { leagueRoleId, onComplete } = props;
    const league = useLeague();

    const handleSubmit = async (
        values: InviteLeagueRoleFormValues,
        actions: FormikActions<InviteLeagueRoleFormValues>
    ) => {
        try {
            await LeagueService.inviteUsers({
                leagueId: league!.id,
                roleId: leagueRoleId,
                emails: [values.email],
            });
            await onComplete();
        } catch (error) {
            const message = Core.API.getErrorMessage(error);
            actions.setStatus(message);
        }
        actions.setSubmitting(false);
    };

    return (
        <div className="invite-league-role">
            <Formik
                initialValues={{ email: '' }}
                onSubmit={handleSubmit}
                validationSchema={schema}
                render={(props: FormikProps<InviteLeagueRoleFormValues>) => (
                    <Form>
                        <fieldset className="form-group">
                            <FormField
                                type="email"
                                name="email"
                                description="Email"
                                placeholder="Email address"
                                className="invite-league-role__text-input"
                            />
                        </fieldset>

                        {props.status && <ErrorMessage error={props.status} />}
                        <ErrorMessage error={props.errors} filter={props.touched} />

                        <fieldset className="form-group form-group--undecorated">
                            <SolidButton
                                as="button"
                                layout="full"
                                onClick={props.submitForm}
                                pending={props.isSubmitting}
                                size="medium"
                            >
                                Send Invites
                            </SolidButton>
                        </fieldset>
                    </Form>
                )}
            />
        </div>
    );
};

export default InviteLeagueRole;

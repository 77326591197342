import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import Select, { OptionsType, SelectOption } from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../../core';
import { Button } from '../../../components/button';
import ErrorMessage from '../../../components/errorMessage';
import FormField from '../../../components/formField';
import Loading from '../../../components/loading';
import Modal from '../../../components/modal';
import ChatService from '../../../services/chatService';

import './index.scss';

interface CreateThreadModalProps {
    onClose: () => void;
}

export interface CreateThreadFormValues {
    content: string;
    userIds: string[];
}

const schema = Yup.object().shape({
    content: Yup.string().required('Please enter your message'),
    userIds: Yup.array().min(1, 'Please select at least one other user to message').required(),
});

const CreateThreadModal = (props: CreateThreadModalProps): JSX.Element => {
    const { onClose } = props;

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [users, setUsers] = React.useState<Core.Models.ChatThreadParticipant[]>([]);

    React.useEffect(() => {
        setIsLoading(true);

        (async () => {
            try {
                const usersData = await ChatService.getAllChatUsers();

                setUsers(usersData);
            } catch (err) {
                toast.error('Unable to get users. Please try again in a moment.');
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        <div className="create-thread-modal">
            <Modal className="chat__create-thread-modal" title="Create new conversation" {...{ onClose }}>
                <Formik
                    initialValues={Object.assign(
                        {},
                        {
                            content: '',
                            userIds: [],
                        }
                    )}
                    validationSchema={schema}
                    onSubmit={async (values, actions) => {
                        actions.setStatus(undefined);
                        try {
                            const { content, userIds } = values;
                            await ChatService.createThread({
                                content,
                                userIds,
                            });
                            onClose();
                        } catch (e) {
                            const message = Core.API.getErrorMessage(e);
                            actions.setStatus(message);
                        }
                        actions.setSubmitting(false);
                    }}
                    render={(props: FormikProps<CreateThreadFormValues>) => {
                        if (isLoading) return <></>;

                        const options = users
                            .filter(
                                (user: Core.Models.ChatThreadParticipant) => !props.values.userIds.includes(user.userId)
                            )
                            .map(
                                (user: Core.Models.ChatThreadParticipant) =>
                                    ({
                                        label: user.name,
                                        value: user.userId,
                                    }) as SelectOption
                            );

                        return (
                            <Form className="form">
                                {users.length > 0 ? (
                                    <>
                                        <fieldset className="form-group">
                                            <Select
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                isMulti
                                                name="userIds"
                                                onChange={(selectedOptions: OptionsType<SelectOption>) => {
                                                    props.setFieldValue(
                                                        'userIds',
                                                        selectedOptions.map((option: SelectOption) => option.value)
                                                    );
                                                }}
                                                options={options}
                                                placeholder="Select recipients"
                                                styles={{
                                                    control: (provided: any) => ({ ...provided, border: 'none' }),
                                                    menu: (provided: any) => ({ ...provided, zIndex: 2 }), // otherwise this pops under "Type message here"
                                                }}
                                            />
                                        </fieldset>
                                        <fieldset className="form-group">
                                            <FormField
                                                component="textarea"
                                                description="Type message here"
                                                name="content"
                                            />
                                        </fieldset>
                                        <fieldset className="form-group form-group--undecorated">
                                            {props.isSubmitting && <Loading buttonLoader />}
                                            <Button wide onClick={props.submitForm} disabled={props.isSubmitting}>
                                                Create conversation
                                            </Button>
                                        </fieldset>
                                        {props.status && <ErrorMessage error={props.status} />}
                                        <ErrorMessage error={props.errors} filter={props.touched} />
                                    </>
                                ) : (
                                    <ErrorMessage error="There's nobody here." />
                                )}
                            </Form>
                        );
                    }}
                />
            </Modal>
        </div>
    );
};

export default CreateThreadModal;

import * as React from 'react';
import { Field } from 'formik';

import * as Core from '../../core';
import FormField from '../../components/formField';
import { useModal } from '../../hooks/modal';
import { SolidButton, TextButton } from '../buttons-visuals';
import Markdown from '../markdown';

interface CustomRegistrationFormFieldProps {
    fieldArrayName: string;
    fieldSubmissionResponse: Core.Models.CustomRegistrationFieldSubmissionResponse;
    index: number;
}

const CustomRegistrationFormField = (props: CustomRegistrationFormFieldProps) => {
    const { fieldArrayName, fieldSubmissionResponse, index } = props;

    const [detailsModal, openDetailsModal, closeDetailsModal] = useModal(
        () => `Details`,
        () => {
            if (
                fieldSubmissionResponse.field.fieldType !== Core.Models.CustomRegistrationFieldType.Checkbox ||
                !fieldSubmissionResponse.field.details
            )
                return <></>;

            return (
                <>
                    <Markdown source={fieldSubmissionResponse.field.details} />
                    <SolidButton
                        as="button"
                        className="mt2x full-width"
                        onClick={() => closeDetailsModal()}
                        size="medium"
                    >
                        Close
                    </SolidButton>
                </>
            );
        }
    );

    const renderField = () => {
        switch (fieldSubmissionResponse.field.fieldType) {
            case Core.Models.CustomRegistrationFieldType.Checkbox:
                return (
                    <div key={index}>
                        <Field
                            type={'hidden'}
                            name={`${fieldArrayName}[${index}].id`}
                            value={fieldSubmissionResponse.field.id}
                        />
                        <Field
                            type={'hidden'}
                            name={`${fieldArrayName}[${index}].name`}
                            value={fieldSubmissionResponse.field.name}
                        />

                        <FormField
                            label={
                                <>
                                    <span className="mr">{fieldSubmissionResponse.field.label}</span>
                                    {!!fieldSubmissionResponse.field.details && (
                                        <>
                                            <TextButton className="m0" onClick={openDetailsModal} type="button">
                                                View Details
                                            </TextButton>
                                            {detailsModal}
                                        </>
                                    )}
                                </>
                            }
                            name={`${fieldArrayName}[${index}].value`}
                            type="checkbox"
                            value={fieldSubmissionResponse.value}
                        />
                    </div>
                );
            case Core.Models.CustomRegistrationFieldType.Textbox:
                return (
                    <div key={index}>
                        <Field
                            type={'hidden'}
                            name={`${fieldArrayName}[${index}].id`}
                            value={fieldSubmissionResponse.field.id}
                        />
                        <Field
                            type={'hidden'}
                            name={`${fieldArrayName}[${index}].name`}
                            value={fieldSubmissionResponse.field.name}
                        />
                        <FormField
                            component={fieldSubmissionResponse.field.isTextArea ? 'textarea' : 'textbox'}
                            type="text"
                            name={`${fieldArrayName}[${index}].value`}
                            label={fieldSubmissionResponse.field.name}
                            placeholder={
                                fieldSubmissionResponse.field.placeholderText || fieldSubmissionResponse.field.name
                            }
                            value={fieldSubmissionResponse.value}
                        />
                    </div>
                );
            case Core.Models.CustomRegistrationFieldType.Select:
                return (
                    <div key={index}>
                        <Field
                            type={'hidden'}
                            name={`${fieldArrayName}[${index}].id`}
                            value={fieldSubmissionResponse.field.id}
                        />
                        <Field
                            type={'hidden'}
                            name={`${fieldArrayName}[${index}].name`}
                            value={fieldSubmissionResponse.field.name}
                        />
                        <FormField
                            className="create-organization__organization"
                            component="select"
                            description={
                                fieldSubmissionResponse.field.placeholderText || fieldSubmissionResponse.field.name
                            }
                            label={fieldSubmissionResponse.field.name}
                            name={`${fieldArrayName}[${index}].value`}
                            value={fieldSubmissionResponse.value}
                        >
                            <option key={-1} value={''}>
                                Select {fieldSubmissionResponse.field.name}
                            </option>
                            {fieldSubmissionResponse.field.options &&
                                fieldSubmissionResponse.field.options.map((option: string, i: number) => (
                                    <option key={i}>{option}</option>
                                ))}
                        </FormField>
                    </div>
                );
            default:
                throw Error('Not implemented');
        }
    };

    return renderField();
};

export default CustomRegistrationFormField;

import * as React from 'react';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import * as Core from '../../../core';
import { Button } from '../../../components/button';
import ErrorMessage from '../../../components/errorMessage';
import FormField from '../../../components/formField';
import Loading from '../../../components/loading';
import RegistrationHeader from '../../../components/registrationHeader';
import { UserService } from '../../../services/userService';

interface ForgotPasswordFormValues {
    username: string;
}

const validSchema = Yup.object().shape({
    username: Yup.string().required('This field is required'),
});

export const ForgotPassword = () => {
    const [username, setUsername] = React.useState<string | undefined>(undefined);

    const handleSubmit = async (values: ForgotPasswordFormValues, actions: FormikActions<ForgotPasswordFormValues>) => {
        try {
            const usernameValue = values.username.trim();
            await UserService.forgotPassword(usernameValue);
            setUsername(usernameValue);
        } catch (error) {
            const message = Core.API.getErrorMessage(error);
            actions.setStatus(message);
        }
        actions.setSubmitting(false);
    };

    return !!username ? (
        <div className="form">
            <RegistrationHeader
                message={
                    <>
                        A password reset has been requested for {username}. If we can find an account with the email
                        associated with this username, we'll send an email with instructions. If you don't get an email,
                        check that you entered the correct username, or have your manager send you an invite. In the
                        meantime, you can still <Link to="/">browse the league</Link> without logging in.
                    </>
                }
            />
        </div>
    ) : (
        <Formik
            initialValues={{ username: '' }}
            onSubmit={handleSubmit}
            validationSchema={validSchema}
            render={(props: FormikProps<ForgotPasswordFormValues>) => (
                <Form className="form">
                    <RegistrationHeader message="Please enter your username to be sent a password reset link." />
                    <fieldset className="form-group">
                        <FormField
                            type="username"
                            name="username"
                            description="Username"
                            placeholder="Username"
                            className="reset-password-request__text-input"
                        />
                    </fieldset>

                    {props.status && <ErrorMessage error={props.status} />}
                    <ErrorMessage error={props.errors} filter={props.touched} />
                    <fieldset className="form-group form-group--undecorated">
                        {props.isSubmitting && <Loading buttonLoader />}
                        <Button onClick={props.submitForm} disabled={props.isSubmitting} wide round>
                            Send Password Reset Link
                        </Button>
                    </fieldset>
                </Form>
            )}
        />
    );
};

export default ForgotPassword;

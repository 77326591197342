import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { HexColorPicker } from 'react-colorful';

import { IconButton } from '../../../components/buttons-visuals';
import FormField from '../../../components/formField';
import { FieldSet } from '../../../components/inputs';
import { getTextColor } from '../../../components/themeContainer';
import useClickOutside from '../../../hooks/clickOutside';
import { useLeague } from '../../../hooks/store';

import './index.scss';

interface ThemeColorProps {
    backgroundColor: string;
    defaultColor: string;
    description: string;
    label: string;
    name: string;
    onChange: (value: string) => void;
    value: string;
}

const ThemeColor = ({
    backgroundColor,
    defaultColor,
    description,
    label,
    name,
    onChange,
    value,
}: ThemeColorProps): JSX.Element => {
    const league = useLeague();

    const [colorPickerOpen, setColorPickerOpen] = useState<boolean>(false);

    const colorPickerRef = useRef<HTMLDivElement>(null);

    useClickOutside(colorPickerRef, () => {
        if (colorPickerOpen) {
            setColorPickerOpen(false);
        }
    });

    return (
        <div className="theme-color mb4x">
            <FieldSet legend={label} description={description} className="theme-color__fieldset">
                <div className="position-relative">
                    <FormField description={label} name={name} type="text" />
                    <div className="theme-color__customize" ref={colorPickerRef}>
                        <button
                            aria-label={`Customize ${label}`}
                            onClick={() => setColorPickerOpen(true)}
                            style={{ backgroundColor: value }}
                            type="button"
                        >
                            <FontAwesomeIcon className={classNames(getTextColor(value))} icon={['fas', 'pen']} />
                        </button>
                        {colorPickerOpen && (
                            <HexColorPicker
                                color={value}
                                onChange={debounce((value: string) => onChange(value), 200)}
                            />
                        )}
                    </div>
                </div>
            </FieldSet>
            <div className="quick-select">
                <IconButton
                    as="button"
                    buttonLabel={`Reset ${label} to default`}
                    buttonSize="small"
                    onClick={debounce(() => onChange(defaultColor), 200)}
                    style={{ backgroundColor: defaultColor }}
                >
                    <FontAwesomeIcon
                        className={classNames(getTextColor(defaultColor))}
                        icon={['fas', 'arrow-rotate-left']}
                    />
                </IconButton>
                {league?.logoColors?.map((color: string) => (
                    <IconButton
                        as="button"
                        buttonLabel={`Set ${label} to ${color}`}
                        buttonSize="small"
                        key={color}
                        onClick={debounce(() => onChange(color), 200)}
                        style={{ backgroundColor: color }}
                    >
                        <FontAwesomeIcon className={classNames(getTextColor(color))} icon={['fas', 'droplet']} />
                    </IconButton>
                ))}
            </div>
        </div>
    );
};

export default ThemeColor;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAlternateSeasonName } from '../../hooks/store';
import { IconButton } from '../buttons-visuals';
import { Popover } from '../overlays/popovers';

interface TennisStylePopoverProps {
    showSeasonStartedWarning?: boolean;
}

const TennisStylePopover = ({ showSeasonStartedWarning }: TennisStylePopoverProps) => {
    const seasonAlternateName = useAlternateSeasonName({ lowercase: true });

    return (
        <Popover
            button={
                <IconButton as="button" buttonLabel="More info" buttonSize="small">
                    <FontAwesomeIcon icon={['fas', 'circle-info']} />
                </IconButton>
            }
        >
            <p className="p text-small">
                Tennis-style gameplay is a competition type where players on a team will face opponents on the enemy
                team in separate 1v1 games, as opposed to a full team playing another team. This is great for team
                matches in games that are otherwise played 1v1.
            </p>

            {!!showSeasonStartedWarning && (
                <p className="p text-small weight-700">
                    This setting cannot be changed after the {seasonAlternateName} has started.
                </p>
            )}
        </Popover>
    );
};

export default TennisStylePopover;

import { BaseCore } from '@leaguespot/platform-js';

export const Configuration = {
    api: process.env.REACT_APP_API || BaseCore.Constants.EMPTY_STRING,
    disableAnalytics: false,
    disableAutomaticTokenRefresh: false,
    enableBuildNumberInUI:
        (process.env.REACT_APP_ENABLE_BUILD_NUMBER_IN_UI || BaseCore.Constants.EMPTY_STRING).toLowerCase() === 'true',
    platformUrl: process.env.REACT_APP_PLATFORM_URL || BaseCore.Constants.EMPTY_STRING,
    recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY || BaseCore.Constants.EMPTY_STRING,
    showLeagueSpotBranding: window.location.hostname.includes('leaguespot'),
    stripePublishableKey: process.env.REACT_APP_STRIPE_KEY || BaseCore.Constants.EMPTY_STRING,
    version: process.env.REACT_APP_VERSION || BaseCore.Constants.EMPTY_STRING,
};

export default Configuration;

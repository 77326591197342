import React, { useMemo } from 'react';
import classNames from 'classnames';
import { chunk, flatMap, max } from 'lodash';
import moment from 'moment-timezone';

import './matchGameResults.scss';

interface LolMatchGameResultsProps {
    gameNumber: number;
    matchGameResult: LolMatchGameResult;
}

export interface LolMatchGameResult {
    durationInSeconds?: string;
    teams: LolMatchGameResultTeam[];
}

interface LolMatchGameResultTeam {
    assists: number;
    damage: number;
    deaths: number;
    kills: number;
    participants: LolMatchGameResultParticipant[];
    side: LolMatchGameResultSide;
    winner: boolean;
}

interface LolMatchGameResultParticipant {
    assists: number;
    champion: LolMatchGameResultMediaItem;
    cs: number;
    csPerMinute: number;
    damage: number;
    deaths: number;
    items: LolMatchGameResultMediaItem[];
    kda: number;
    kills: number;
    level: number;
    pctOfTeamDamage: number;
    runes: LolMatchGameResultMediaItem[];
    spells: LolMatchGameResultMediaItem[];
    summonerName: string;
    totalGold: number;
}

interface LolMatchGameResultMediaItem {
    imageUrl: string;
    name: string;
}

enum LolMatchGameResultSide {
    Blue = 100,
    Red = 200,
}

const LolMatchGameResults = (props: LolMatchGameResultsProps) => {
    const { matchGameResult } = props;

    const maxDamage = useMemo(
        () =>
            max(
                flatMap(matchGameResult.teams.map((team) => team.participants)).map((participant) => participant.damage)
            ) ?? 0,
        [matchGameResult]
    );

    const duration = useMemo(() => {
        if (!matchGameResult.durationInSeconds) return null;

        return moment(parseInt(matchGameResult.durationInSeconds) * 1000).format('mm:ss');
    }, [matchGameResult]);

    return (
        <table className="match-page__score-table mt">
            <tbody>
                <tr className="lol-stats-row">
                    <td colSpan={4}>
                        <div className="lol-stats-row__content">
                            <div className="lol-stats-row__content__game">
                                {!!duration && <p>Duration: {duration}</p>}
                            </div>

                            {matchGameResult.teams.map((team: LolMatchGameResultTeam) => (
                                <div
                                    className={classNames('lol-stats-row__content__side', {
                                        blue: team.side === LolMatchGameResultSide.Blue,
                                        red: team.side === LolMatchGameResultSide.Red,
                                    })}
                                    key={team.side}
                                >
                                    <div className="lol-stats-row__content__side__summary">
                                        <p>
                                            {team.winner && (
                                                <>
                                                    <span className="highlight">WINNER</span> -{' '}
                                                </>
                                            )}
                                            {team.kills}/{team.deaths}/{team.assists}
                                        </p>
                                    </div>
                                    <table className="lol-stats-row__content__side__participants">
                                        <thead>
                                            <tr>
                                                <th>Player</th>
                                                <th>Perf</th>
                                                <th>Income</th>
                                                <th>Items</th>
                                                <th>Damage</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {team.participants.map((participant: LolMatchGameResultParticipant) => (
                                                <tr
                                                    className="lol-stats-row__content__side__participants__participant"
                                                    key={participant.summonerName}
                                                >
                                                    <td>
                                                        <div className="icon-grid">
                                                            <div className="icon-grid__column">
                                                                <div className="icon-grid__column__icon champion">
                                                                    <img
                                                                        alt={participant.champion.name}
                                                                        src={participant.champion.imageUrl}
                                                                        title={participant.champion.name}
                                                                    />
                                                                    <div className="level">{participant.level}</div>
                                                                </div>
                                                            </div>
                                                            <div className="icon-grid__column">
                                                                {participant.spells.map(
                                                                    (
                                                                        spell: LolMatchGameResultMediaItem,
                                                                        index: number
                                                                    ) => (
                                                                        <div
                                                                            className={classNames(
                                                                                'icon-grid__column__icon',
                                                                                {
                                                                                    empty: !spell,
                                                                                }
                                                                            )}
                                                                            key={index}
                                                                        >
                                                                            {!!spell && (
                                                                                <img
                                                                                    alt={spell.name}
                                                                                    src={spell.imageUrl}
                                                                                    title={spell.name}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                            <div className="icon-grid__column">
                                                                {participant.runes.map(
                                                                    (
                                                                        rune: LolMatchGameResultMediaItem,
                                                                        index: number
                                                                    ) => (
                                                                        <div
                                                                            className={classNames(
                                                                                'icon-grid__column__icon',
                                                                                {
                                                                                    empty: !rune,
                                                                                    secondary: index === 1, // second item is secondary
                                                                                }
                                                                            )}
                                                                            key={index}
                                                                        >
                                                                            {!!rune && (
                                                                                <img
                                                                                    alt={rune.name}
                                                                                    src={rune.imageUrl}
                                                                                    title={rune.name}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p title={participant.summonerName}>
                                                            <span className="highlight">
                                                                {participant.summonerName}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span className="highlight">{participant.kills}</span> /{' '}
                                                            <span className="highlight">{participant.deaths}</span> /{' '}
                                                            <span className="highlight">{participant.assists}</span>
                                                        </p>
                                                        <p>
                                                            <span className="highlight">
                                                                {participant.kda.toLocaleString(undefined, {
                                                                    maximumFractionDigits: 1,
                                                                    minimumFractionDigits: 0,
                                                                    useGrouping: true,
                                                                })}
                                                            </span>{' '}
                                                            KDA
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span className="highlight">{participant.cs}</span> CS
                                                        </p>
                                                        <p>
                                                            <span className="highlight">
                                                                {participant.csPerMinute.toLocaleString(undefined, {
                                                                    maximumFractionDigits: 1,
                                                                    minimumFractionDigits: 1,
                                                                    useGrouping: true,
                                                                })}
                                                            </span>{' '}
                                                            CS/min
                                                        </p>
                                                        <p>
                                                            <span className="highlight">
                                                                {participant.totalGold.toLocaleString('en', {
                                                                    useGrouping: true,
                                                                })}
                                                            </span>{' '}
                                                            Gold
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div className="icon-grid">
                                                            {chunk(participant.items, 2).map(
                                                                (
                                                                    chunk: LolMatchGameResultMediaItem[],
                                                                    chunkIndex: number
                                                                ) => (
                                                                    <div className="icon-grid__column" key={chunkIndex}>
                                                                        {chunk.map(
                                                                            (
                                                                                item: LolMatchGameResultMediaItem,
                                                                                itemIndex: number
                                                                            ) => (
                                                                                <div
                                                                                    className={classNames(
                                                                                        'icon-grid__column__icon',
                                                                                        { empty: !item }
                                                                                    )}
                                                                                    key={itemIndex}
                                                                                >
                                                                                    {!!item && (
                                                                                        <img
                                                                                            alt={item.name}
                                                                                            src={item.imageUrl}
                                                                                            title={item.name}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span className="highlight">
                                                                {participant.damage.toLocaleString('en', {
                                                                    useGrouping: true,
                                                                })}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span className="highlight">
                                                                {participant.pctOfTeamDamage.toLocaleString(undefined, {
                                                                    maximumFractionDigits: 1,
                                                                    minimumFractionDigits: 0,
                                                                    style: 'percent',
                                                                })}
                                                            </span>{' '}
                                                            of Team Damage
                                                        </p>
                                                        <div className="progress-bar">
                                                            <div
                                                                className="inner"
                                                                style={{
                                                                    width:
                                                                        (maxDamage > 0
                                                                            ? participant.damage / maxDamage
                                                                            : 0) *
                                                                            100 +
                                                                        '%',
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ))}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default LolMatchGameResults;

import React, { useEffect, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import * as Core from '../../core';
import Ach from './ach';
import ApprovedGames from './approvedGames';
import BasicSettings from './basicSettings';
import Designations from './designations';
import DiscordServer from './discordServer';
import EditLeagueSeasonCategories from './editLeagueSeasonCategories';
import ExportData from './exportData';
import HelpfulLinks from './helpfulLinks';
import LeagueFees from './leagueFees';
import LeagueImages from './leagueImages';
import LeagueMembers from './leagueMembers';
import Locations from './locations';
import OrganizationInviteUrl from './organizationInviteUrl';
import RegistrationFields from './registrationFields';
import ThemeColors from './themeColors';
import StreamSettings from '../../components/stream/streamSettings';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import {
    useAlternateSeasonName,
    useIsAdmin,
    useIsPermissionsLoading,
    useLeague,
    useOrganizationTerm,
    useUserPermissionService,
} from '../../hooks/store';
import history from '../../services/history';
import { LeagueService } from '../../services/leagueService';

import './index.scss';

interface Section {
    content: JSX.Element;
    name: string;
    path: string;
}

interface LeagueSettingsPageProps extends WithLoadingProps {}

const LeagueSettingsPage = ({ setIsLoading }: LeagueSettingsPageProps): JSX.Element => {
    const isAdmin = useIsAdmin();
    const league = useLeague();
    const organizationTerm = useOrganizationTerm();
    const seasonAlternateName = useAlternateSeasonName();
    const userPermissionService = useUserPermissionService();
    const canEditLeague = userPermissionService.hasLeagueAccess(Core.Models.PermissionLevel.Edit, league);
    const isPermissionsLoading = useIsPermissionsLoading();
    const { path } = useRouteMatch();

    useEffect(() => {
        if (!isPermissionsLoading) setIsLoading(false);
    }, [isPermissionsLoading]);

    const sections = useMemo(
        () =>
            [
                {
                    name: 'Basic Settings',
                    content: <BasicSettings />,
                    path: `${path}/basic-settings`,
                },
                {
                    name: `${seasonAlternateName} Ordering`,
                    content: <EditLeagueSeasonCategories />,
                    path: `${path}/season-ordering`,
                },
                {
                    name: `Theme Colors`,
                    content: <ThemeColors />,
                    path: `${path}/theme`,
                },
                {
                    name: 'Team Designations',
                    content: <Designations />,
                    path: `${path}/designations`,
                },
                {
                    name: 'Discord Server',
                    content: <DiscordServer />,
                    path: `${path}/discord`,
                },
                {
                    name: 'League Images',
                    content: <LeagueImages />,
                    path: `${path}/images`,
                },
                // hide org create url if Club since 1. an org is auto-created, and 2. Club can't handle more than one org
                ...(league?.edition !== Core.Models.LeagueEdition.Club
                    ? [
                          {
                              name: `Create ${organizationTerm} URL`,
                              content: <OrganizationInviteUrl />,
                              path: `${path}/invite`,
                          },
                      ]
                    : []),
                {
                    name: 'League Members',
                    content: (
                        <>
                            {isAdmin && <LeagueMembers leagueRoleId={Core.Models.LeagueRoleId.LeagueAdmin} />}
                            <LeagueMembers leagueRoleId={Core.Models.LeagueRoleId.Host} />
                            <LeagueMembers leagueRoleId={Core.Models.LeagueRoleId.SeasonOperator} />
                        </>
                    ),
                    path: `${path}/members`,
                },
                {
                    name: 'Approved Games',
                    content: <ApprovedGames />,
                    path: `${path}/games`,
                },
                {
                    name: 'Helpful Links',
                    content: <HelpfulLinks />,
                    path: `${path}/links`,
                },
                {
                    name: 'Locations',
                    content: <Locations />,
                    path: `${path}/locations`,
                },
                {
                    name: 'Registration Fields',
                    content: <RegistrationFields />,
                    path: `${path}/registration`,
                },
                {
                    name: 'Export Data',
                    content: <ExportData />,
                    path: `${path}/export`,
                },
                {
                    name: 'League Streams',
                    content: (
                        <StreamSettings
                            className="settings-page__streams global-container-centered"
                            loadStreams={LeagueService.getLeagueStreams}
                            onAddStream={(request: Core.Models.CreateLeagueStreamRequest) =>
                                LeagueService.createLeagueStream(request)
                            }
                            onDeleteStream={(streamId: string) => LeagueService.deleteLeagueStream(streamId)}
                            onEditStream={(request: Core.Models.EditStreamRequest) =>
                                LeagueService.editLeagueStream(request)
                            }
                            onEnableStream={(request: Core.Models.EnableStreamRequest) =>
                                LeagueService.updateEnableStream(request)
                            }
                            streamEntityName="League"
                        />
                    ),
                    path: `${path}/streams`,
                },
                ...(!!league?.enablePaymentFeatures
                    ? [
                          { name: 'League Payment Account', content: <Ach />, path: `${path}/payment` },
                          { name: 'League Fees', content: <LeagueFees />, path: `${path}/fees` },
                      ]
                    : []),
            ] as Section[],
        [isAdmin, league?.edition, league?.enablePaymentFeatures, organizationTerm, seasonAlternateName]
    );

    if (!league) return <></>;
    if (!isPermissionsLoading && !canEditLeague) return <Redirect to="/" />;

    return (
        <div className="global-container settings-page pt4x pb8x">
            <h1 className="heading-1 text-center mb0">League Settings</h1>
            <div className="settings-page__select">
                <select
                    className="settings-page__select__input"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => history.push(e.target.value)}
                    value={window.location.pathname}
                >
                    {sections.map((section: Section) => (
                        <option key={section.path} value={section.path}>
                            {section.name}
                        </option>
                    ))}
                </select>
                <FontAwesomeIcon
                    icon={['fas', 'chevron-down']}
                    className="settings-page__select-icon color-gray ml2x"
                />
            </div>

            <Switch>
                {sections.map((section: Section) => (
                    <Route key={section.path} path={section.path}>
                        {section.content}
                    </Route>
                ))}
                <Route path="*">
                    <Redirect to={sections[0].path} />
                </Route>
            </Switch>
        </div>
    );
};

export default withLoading(LeagueSettingsPage, {
    loadingProps: { blockItem: true },
});

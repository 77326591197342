import * as React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import useClickOutside from '../../hooks/clickOutside';
import { useIsPlatform, useProfile, useUserPermissionService } from '../../hooks/store';
import { logout } from '../../store/login/actions';
import { Avatar } from '../buttons-visuals';
import { BaseButton } from '../buttons-visuals';
import { TertiaryButton } from '../buttons-visuals';
import LoginButton from '../loginButton';
import { Dropdown } from '../navigationComponents';
import { NavList, NavListItem } from '../navigationComponents';

import './currentUser.scss';

const CurrentUser = (): JSX.Element => {
    const dispatch = useDispatch();
    const isPlatform = useIsPlatform();
    const profile = useProfile();
    const userPermissionService = useUserPermissionService();
    const userId = userPermissionService.getUserId();

    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const dropdownRef = React.useRef<HTMLDivElement>(null);

    useClickOutside(dropdownRef, () => {
        if (isOpen) {
            setIsOpen(false);
        }
    });

    return (
        <div
            className={classNames('current-user', {
                'current-user-open': isOpen,
                'current-user-has-user': !!profile,
            })}
            ref={dropdownRef}
        >
            {userId ? (
                <nav aria-label="current user navigation" style={{ height: '100%' }}>
                    <Dropdown
                        button={
                            <TertiaryButton
                                as="button"
                                className="current-user__dropdown-trigger disp-flex align-center"
                                onClick={() => setIsOpen(!isOpen)}
                                style={{ cursor: 'pointer', height: '100%' }}
                            >
                                <Avatar
                                    fallback="user"
                                    className="current-user__static__avatar"
                                    isUnder13={!!profile?.isUnder13}
                                    src={profile?.avatarUrl}
                                    size="small"
                                />
                                <div className="current-user__static__name-container">
                                    {!!profile?.firstName && (
                                        <div className="current-user__static__name">{profile.firstName}</div>
                                    )}
                                    {!!profile?.organizationName && (
                                        <div className="current-user__static__organization-name">
                                            {profile.organizationName}
                                        </div>
                                    )}
                                </div>
                            </TertiaryButton>
                        }
                        position="right"
                        open={isOpen}
                        style={{ height: '100%' }}
                    >
                        <NavList>
                            <>
                                <NavListItem>
                                    <BaseButton as="link" to={`/users/${userId}`} onClick={() => setIsOpen(!isOpen)}>
                                        Profile
                                    </BaseButton>
                                </NavListItem>
                                {!isPlatform && (
                                    <NavListItem>
                                        <BaseButton as="link" to="/contact" onClick={() => setIsOpen(!isOpen)}>
                                            Contact Us
                                        </BaseButton>
                                    </NavListItem>
                                )}
                                <NavListItem>
                                    <BaseButton as="button" onClick={() => dispatch(logout())}>
                                        Logout
                                    </BaseButton>
                                </NavListItem>
                            </>
                        </NavList>
                    </Dropdown>
                </nav>
            ) : (
                <LoginButton size="large">Login</LoginButton>
            )}
        </div>
    );
};

export default CurrentUser;

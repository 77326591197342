import React from 'react';
import classNames from 'classnames';

import { ContentContainerProps } from './ContentContainer.types';

import './ContentContainer.scss';

const ContentContainer = (props: ContentContainerProps) => {
    const { children, className, overflowHidden, radius = 'medium', shade = 'base', ...rest } = props;

    const contentContainerClasses = classNames(
        className,
        'ds-content-container',
        `background-${shade}`,
        `radius-${radius}`,
        { 'overflow-hidden': overflowHidden }
    );

    return (
        <div className={contentContainerClasses} {...rest}>
            {children}
        </div>
    );
};

export default ContentContainer;

import { Buffer } from 'buffer';

import { GenericOAuthState } from '../models';

export const generateFullAuthorizeUrl = (
    returnUrl: string,
    oAuthProviderId: string,
    authorizeUrl: string,
    userId: string | undefined
) => {
    const state: GenericOAuthState = {
        oAuthProviderId,
        returnUrl,
        userId,
    };

    // not encoding because it does weird things with the URL, especially the `%20` which is required between scope values
    const encodedState = Buffer.from(JSON.stringify(state)).toString('base64');
    const indexOf = authorizeUrl.indexOf('{state}');
    const url =
        indexOf >= 0
            ? authorizeUrl.replace('{state}', encodedState) // some URLs require a very specific placement of the `state` param
            : authorizeUrl + `&state=${encodedState}`;
    return url;
};

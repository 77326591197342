import * as Core from '../core';

export abstract class SeasonService {
    public static async getAllSeasonCategories(): Promise<Core.Models.SeasonCategory[]> {
        const route = Core.API.ServerRoute.forAction('seasons');
        return await Core.API.get(route);
    }
    public static async getJoinableSeasons(): Promise<Core.Models.JoinableSeason[]> {
        const route = Core.API.ServerRoute.forAction('seasons/joinable');
        return await Core.API.get(route);
    }
    public static async getById(seasonId: string): Promise<Core.Models.Season> {
        const route = Core.API.ServerRoute.forAction('seasons', seasonId);
        return await Core.API.get(route);
    }
    public static async getMySeasons(): Promise<Core.Models.Season[]> {
        const route = Core.API.ServerRoute.forAction('seasons', `me`);
        return await Core.API.get(route);
    }
    public static async getSuggestedSeasons(): Promise<Core.Models.Season[]> {
        const route = Core.API.ServerRoute.forAction('seasons', `me/suggested`);
        return await Core.API.get(route);
    }
    public static async getParticipants(seasonId: string): Promise<Core.Models.Participant[]> {
        const route = Core.API.ServerRoute.forAction('seasons', `${seasonId}/participants`);
        return await Core.API.get(route);
    }
    public static async addLateParticipant(command: Core.Models.AddLateSeasonParticipantCommand): Promise<void> {
        const route = Core.API.ServerRoute.forAction('seasons', 'addLateParticipant');
        return await Core.API.post(route, command);
    }
    public static async create(command: Core.Models.CreateSeasonRequest): Promise<Core.Models.Season> {
        const route = Core.API.ServerRoute.forAction('seasons');
        return await Core.API.post(route, command);
    }
    public static async edit(command: Core.Models.EditSeasonRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('seasons');
        return await Core.API.patch(route, command);
    }
    public static async delete(seasonId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('seasons', seasonId);
        return await Core.API.delete(route);
    }
    public static async start(id: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('seasons', `${id}/start`);
        return await Core.API.post(route, {});
    }
    public static async unstart(id: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('seasons', `${id}/unstart`);
        return await Core.API.post(route, {});
    }
    public static async complete(id: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('seasons', `${id}/complete`);
        return await Core.API.post(route, {});
    }
    public static async createSeasonCategory(name: string): Promise<Core.Models.SeasonCategory> {
        const route = Core.API.ServerRoute.forAction('seasons', 'seasonCategories');
        return await Core.API.post(route, { name: name });
    }
    public static async deleteSeasonCategory(id: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('seasons', `seasonCategories/${id}`);
        return await Core.API.delete(route);
    }
    public static async setSeasonCategoryOrder(categories: Core.Models.SeasonCategoryBasic[]): Promise<void> {
        const route = Core.API.ServerRoute.forAction('seasons', 'SetSeasonCategoryOrder');
        return await Core.API.put(route, { seasonCategories: categories });
    }
    public static async getSeasonEligibleTeams(seasonId: string): Promise<Core.Models.SeasonEligibleTeam[]> {
        const route = Core.API.ServerRoute.forAction('seasons', `${seasonId}/eligibleTeams`);
        return await Core.API.get(route);
    }
    public static async getFreeAgencyDetails(seasonId: string): Promise<Core.Models.SeasonFreeAgencyDetails> {
        const route = Core.API.ServerRoute.forAction('seasons', `${seasonId}/freeAgencyDetails`);
        return await Core.API.get(route);
    }
    public static async setSeasonTeams(command: Core.Models.SetSeasonTeamsDto): Promise<void> {
        const route = Core.API.ServerRoute.forAction('seasons', `teams`);
        return await Core.API.put(route, command);
    }
    public static async getSeasonFreeAgents(
        seasonId: string,
        paginatedOptions: Core.Models.PaginatedOptions
    ): Promise<Core.Models.PaginatedResult<Core.Models.SeasonFreeAgent>> {
        const route = Core.API.ServerRoute.forAction('seasons', `${seasonId}/freeAgents`);
        return await Core.API.get(route, undefined, paginatedOptions);
    }
    public static async joinAsFreeAgent(request: Core.Models.CreateSeasonFreeAgentRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('seasons', `freeAgent`);
        return await Core.API.post(route, request);
    }
    public static async leaveAsFreeAgent(seasonFreeAgentId: string): Promise<void> {
        const route = Core.API.ServerRoute.forAction('seasons', `freeAgent/${seasonFreeAgentId}`);
        return await Core.API.delete(route);
    }
    public static async setFreeAgentTeams(request: Core.Models.SetFreeAgentTeamsRequest): Promise<void> {
        const route = Core.API.ServerRoute.forAction('seasons', `freeAgentTeams`);
        return await Core.API.post(route, request);
    }
    public static async getJoinSeasonDetails(seasonId: string): Promise<Core.Models.JoinSeasonDetails> {
        const route = Core.API.ServerRoute.forAction('seasons', `join/${seasonId}`);
        return await Core.API.get(route);
    }
}

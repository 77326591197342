import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { IconButton } from '../../../buttons-visuals';
import { setOverflowPosition } from '../../setOverflowPosition';
import { Bubble } from '../Bubble';
import { BubbleArrowLocation, PopoverProps } from '../Popover.types';

import './Popover.scss';

const TRANSITION_DURATION = 150; // Keep in sync with CSS

const Popover = ({ button, className, children, open, title, ...rest }: PopoverProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(!!open);
    const [isPositioned, setIsPositioned] = useState<boolean>(false); // prevent the Popover from showing before it's positioned
    const [placementH, setPlacementH] = useState<string>('center');
    const [placementV, setPlacementV] = useState<string>('top');
    const bubbleRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);
    const popoverClasses = classNames(className, 'ds-popover');
    const bubbleClasses = classNames(
        'ds-popover__bubble',
        `ds-popover__bubble--${placementV}`,
        `ds-popover__bubble--${placementH}`,
        {
            'ds-popover__bubble--open': isOpen && isPositioned,
        }
    );
    const arrowV = { top: 'bottom', bottom: 'top' }[placementV];
    const arrowLocation = `${arrowV}-${placementH}` as BubbleArrowLocation;

    const handleOpen = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isOpen && !isPositioned) {
            setIsOpen(true);
        }
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;

        const handleClickOutside = (event: MouseEvent) => {
            if (bubbleRef.current && !bubbleRef.current.contains(event.target as Node)) {
                handleClose();
            }
        };

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                handleClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleKeyDown);
            if (bubbleRef.current) {
                const hPos = setOverflowPosition(bubbleRef, 'top')?.hPlacement;
                const vPos = setOverflowPosition(bubbleRef, 'top')?.vPlacement === 'top' ? 'top' : 'bottom';
                setPlacementH((p) => (hPos ? hPos : p));
                setPlacementV(vPos ? vPos : 'top');
                setIsPositioned(true);
            }
        } else {
            // setTimeout ensures we always correctly reset the Popover's positioning.
            timer = setTimeout(() => {
                setPlacementH('center');
                setPlacementV('top');
                setIsPositioned(false);
            }, TRANSITION_DURATION);
        }
        return () => {
            clearTimeout(timer);
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen]);

    return (
        <div className={popoverClasses} {...rest}>
            <div className="ds-popover__button" onClick={handleOpen} ref={buttonRef}>
                {button}
            </div>
            <div className={bubbleClasses} ref={bubbleRef} role="status">
                {(isOpen || isPositioned) && (
                    <Bubble
                        {...(isPositioned && !isOpen ? { 'aria-hidden': 'true' } : {})}
                        arrowLocation={arrowLocation}
                    >
                        {!!title && <div className="weight-700 mb">{title}</div>}
                        {children}
                        <IconButton
                            as="button"
                            buttonLabel="Popover close"
                            buttonSize="small"
                            className="ds-popover__bubble-close"
                            onClick={handleClose}
                        >
                            <FontAwesomeIcon icon={['fas', 'xmark']} />
                        </IconButton>
                    </Bubble>
                )}
            </div>
        </div>
    );
};

export default Popover;
